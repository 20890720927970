import React from "react";
import Blank from "./Blank";

const BlanksContainer = (props) => {
  return (
    <React.Fragment>
      <Blank id={props.children[0]} />
    </React.Fragment>
  );
};

export default BlanksContainer;
