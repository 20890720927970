import React from "react";
import Wrapper from "./NodeWrapper";
import { Box } from "@mui/material";
import Handle from "./Handle";
import { PrettoSlider } from "./Slider";
import { checkNear } from "./Canvas";
import toast from "react-hot-toast";

export const FULL_STORAGE = 82;
export const FUNCTION_STORAGE = 133;

export default function SliderNode({ data }) {
  const {
    label = "",
    min = 0,
    max = 1,
    fullChange = () => {},
    funcChange = () => {},
    fullValue = 1,
    funcValue = 0,
    calculatorData,
  } = data;

  const calculateTotalStorage = () =>
    fullValue * FULL_STORAGE + funcValue * FUNCTION_STORAGE;

  const handleFullKeys = (value) => {
    const {
      contractDeployed,
      contractSize,
      funcKeys,
      storageUsed,
      storage_amount_per_byte,
      near,
    } = calculatorData;

    if (value === 0) {
      toast.error(
        `An account needs atleast one full-access key to make changes to it`,
        {
          id: "fullAccessKey",
        }
      );
    } else {
      let checkNearAmt = checkNear(
        contractDeployed,
        contractSize,
        funcKeys,
        value,
        storageUsed,
        near,
        storage_amount_per_byte
      );
      if (checkNearAmt.nearAvailable > 0) {
        fullChange(value);
      } else {
        toast.error(
          `Cannot add Full Access Key as there are not enough NEAR Tokens Ⓝ to cover for ${checkNearAmt.totalStorage} B of data`,
          {
            id: "fullAccess",
          }
        );
      }
    }
  };

  const handleFuncKeys = (value) => {
    const {
      contractDeployed,
      contractSize,

      fullKeys,
      storageUsed,
      storage_amount_per_byte,
      near,
    } = calculatorData;

    let checkNearAmt = checkNear(
      contractDeployed,
      contractSize,
      value,
      fullKeys,
      storageUsed,
      near,
      storage_amount_per_byte
    );
    if (checkNearAmt.nearAvailable > 0) {
      funcChange(value);
    } else {
      toast.error(
        `Cannot add Function Call Access Key as there are not enough NEAR Tokens Ⓝ to cover for ${checkNearAmt.totalStorage} B of data`,
        {
          id: "funcAccess",
        }
      );
    }
  };

  return (
    <Wrapper label={label}>
      <Handle type="source" position="right" />
      <Box style={{ textAlign: "center", width: "130px" }}>
        <div style={{ textAlign: "center" }}>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              margin: "4px auto",
              color: "#47307f",
            }}
          >
            {calculateTotalStorage()} B
          </span>
        </div>
        <span style={{ fontWeight: "bold", fontSize: "12px" }}>
          {fullValue} Full Access Keys
        </span>
        <PrettoSlider
          size="small"
          value={fullValue}
          valueLabelDisplay="auto"
          onChange={(evt) => {
            handleFullKeys(evt.target.value);
          }}
          step={1}
          min={min}
          max={max}
        />
        <span style={{ fontWeight: "bold", fontSize: "12px" }}>
          {funcValue} Function Call Access Keys
        </span>
        <PrettoSlider
          size="small"
          value={funcValue}
          valueLabelDisplay="auto"
          onChange={(evt) => {
            handleFuncKeys(evt.target.value);
          }}
          step={1}
          min={min}
          max={max}
        />
      </Box>
    </Wrapper>
  );
}
