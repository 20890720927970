import { OutputFactory, Outputs } from "javascript-terminal";
import { CustomOutputFactory } from "../outputs";
import { arraysEqual, getURL, getTxId } from "./utils";
import { providers } from "near-api-js";
import bs58 from "bs58";

const helpText = `
**Usage**: &&near&& [[tx-status]] @@<txHash@@ @@--accountId@@ **<accountId>** or &&near&& [[tx-status]]  **<accountId>**:@@<txHash@@

    ^^Displays transaction status details for given transaction hash and accountId.^^


**Required Arguments**

    &&<txHash>&&
        ^^Base58-encoded hash [string]^^

    &&<accountId>&&
        ^^AccountId ^^



**Example:**

    &&near&& [[tx-status]] @@near-example.testnet:6hnVD3n4LvQbUrWofSjxbN2uwLLANnkXLqRYPnMbsP3j@@

    OR

    &&near&& [[tx-status]] @@6hnVD3n4LvQbUrWofSjxbN2uwLLANnkXLqRYPnMbsP3j@@ **--accountId** @@near-example.testnet@@
     
`;

const generateErrors = (errors) => {
  let mappedText = errors.reduce((acc, curr) => {
    return acc + `\n\t&&` + " * " + curr + `&&`;
  }, ``);
  return `**Encountered Following Errors**
${mappedText}

**Usage**: &&near&& [[tx-status]] @@<txHash>@@ @@--accountId@@ **<accountId>**

    For more info type:
        &&near&& [[tx-status]] @@--help@@ 

`;
};

const getErrorMessage = (res) => {
  const message = `&&${res.type}&&: An error occured.
  '${res.message}'
   `;
  return message;
};

const getSuccessMessage = (res) => {
  const message = `
[['${res}']]
 `;

  return message;
};

const wasmFileNotFound = `Specified Wasm file not found`;
const invalidCommandUsage = `Invalid Command Usage`;
const missingMaster = `Missing Master Account`;
const missingWasmFilePath = `Missing Wasm file path`;
const missingAccountID = `Please specify account id, either as part of transaction hash or using --accountId flag.`;
const invalidAccountId = `Invalid Account ID provided`;
const endWithTestnet = `Master account must end with testnet`;
const accEndWithTestnet = `Account Id must end with ".testnet"`;
const notLongEnough = `AccountId should be greater than 32 characters`;
const tooLong = `AccountId should be smaller than 64 characters`;
const invalidMasterAccount = `Invalid Master Account Id provided`;
const missingMethodName = `Missing Method name of the contract`;
const missingContractName = `Missing Contract name`;
const missingTx = `Missing Transaction Hash`;
const invalidTxId = `Unexpected transaction hash format`;

const updateState = (type, message, setCustomState, setAcceptInput) => {
  if (type === "error") {
    setCustomState((prevState) => {
      const errorMessage = message.type
        ? getErrorMessage(message)
        : `&&${message}&&`;
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(`${errorMessage}`)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  } else if (type === "success") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(message)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  }

  setAcceptInput(true);
};

const getErrors = (args) => {
  switch (args.length) {
    case 0:
      return [missingContractName, missingMethodName];
    case 1:
      return [missingContractName, missingMethodName];
    case 2:
      return [missingMethodName];
  }
};

// Test

// near view test1.siddharthkanungo.testnet helloWorld

// near view test1.siddharthkanungo.testnet greet '{"names": "Siddharth"}'

export const txStatusCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  const { setAcceptInput, setCustomState } = stateChangeProps;
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(helpText),
    };
  } else {
    if (args.length === 1) {
      const errors = [missingTx, missingAccountID];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if (args.length === 2) {
      const [_, txHash] = args;
      const hashParts = txHash.split(":");
      let hash, accountId;
      if (hashParts.length == 2) {
        [accountId, hash] = hashParts;
      } else if (hashParts.length == 1) {
        [hash] = hashParts;
      } else {
        const errors = [invalidTxId];
        const errorText = generateErrors(errors);
        return {
          output: CustomOutputFactory.createColoredRecord(errorText),
        };
      }

      accountId = accountId || options.accountId || options.masterAccount;

      if (!accountId) {
        const errors = [missingAccountID];
        const errorText = generateErrors(errors);
        return {
          output: CustomOutputFactory.createColoredRecord(errorText),
        };
      }

      setAcceptInput(false);

      let decodedHash;
      try {
        decodedHash = bs58.decode(hash);
      } catch (e) {
        const errors = [invalidTxId];
        const errorText = generateErrors(errors);
        return {
          output: CustomOutputFactory.createColoredRecord(errorText),
        };
      }

      context
        .getTxStatus(decodedHash, accountId)
        .then((res) => {
          let type = res.type ? res.type : "success";
          const message = JSON.stringify(res, null, 4);

          updateState(type, message, setCustomState, setAcceptInput);
        })
        .catch((e) => {
          let type = "error";
          updateState(type, e, setCustomState, setAcceptInput);
        });

      const message = `Transaction [[${accountId}]]:[[${hash}]]`;

      return {
        output: CustomOutputFactory.createColoredRecord(message),
      };
    } else {
      const errors = [invalidCommandUsage];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    }
  }
};
