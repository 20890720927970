const wouldLikeToKnowPrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "I don't live by many rules, but one of my rule is... ",
        "no curious question should ever go unanswered",
        "Let's take a look why we use difficulty.",
      ],
      primerAvatar: "love",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "WhyDifficulty",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "In this topic, we have learned quite a lot about blocks.",
        "Now, in the next topic, let's take a look at how these blocks are connected together.",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "good-bye",
    },
  },
];

const laterIsFinePrimers = [
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "In this topic, we have learned quite a lot about blocks.",
        "Now, in the next topic, let's take a look at how these blocks are connected together.",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "good-bye",
    },
  },
];

const nowOrLaterButtons = [
  {
    text: "Would Like to Know",
    userReply: {
      reply: [
        "I would definitely like to know...",
        "... as to what's the use of difficulty?",
      ],
      userAvatar: "Happy",
    },
    primers: wouldLikeToKnowPrimers,
  },
  {
    text: "Later is Fine",
    userReply: {
      reply: [
        "Well, I think...",
        "... I would prefer to know later when it will make better sense",
      ],
      userAvatar: "Cheeks",
    },
    primers: laterIsFinePrimers,
  },
];

export const nonceOnwardsPrimers = [
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "NonceOnwardsInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "DifficultyQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Since the generated hash value is a 64-length sequence of completely random digit...",
        "...it's easier to understand each digit being assigned by a 16-sided dice...",
        "...with each side being a hexadecimal digit",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DifficultyInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "GlossaryMatchQuiz",
    },
  },

  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "In this topic, we have learned quite a lot about blocks.",
        "Now, in the next topic, let's take a look at how these blocks are connected together.",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "good-bye",
    },
  },

  // {
  //   type: "CHAT",
  //   reply: "BUTTON",
  //   props: {
  //     chats: [
  //       "{{user}}, now you might be wondering why use difficulty at all?",
  //       "I can definitely answer you right now, but it would make better sense, when we put everything together",
  //       "Would you like to know now or maybe later when the right time comes?",
  //     ],
  //     buttonOptions: nowOrLaterButtons,
  //     userReply: { reply: null, userAvatar: null },
  //     primerAvatar: "gentleman",
  //   },
  // },
];

// Was thinking of adding a bit of explaining in this topic
// But then it's taking too much time so skipped.
