// Infos

import ApiInfo from "./PrimerComponents/ApiInfo.mdx";
import CounterInfo from "./PrimerComponents/CounterInfo.mdx";
import CounterContractInfo from "./PrimerComponents/CounterContractInfo.mdx";
import CounterContractInfo2 from "./PrimerComponents/CounterContractInfo2.mdx";

// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";

// Quiz
import WalletMCQ from "./Quiz/WalletMCQ";
import WalletSelectorMCQ from "./Quiz/WalletSelectorMCQ";
import ViewMethodMCQ from "./Quiz/ViewMethodMCQ";

// Primers
import { start } from "./Primers/Start";

const primers = [...start];

const primerComponents = {
  ApiInfo: ApiInfo,
  WalletMCQ: WalletMCQ,
  CounterInfo: CounterInfo,
  WalletSelectorMCQ: WalletSelectorMCQ,
  CounterContractInfo: CounterContractInfo,
  ViewMethodMCQ: ViewMethodMCQ,
  CounterContractInfo2: CounterContractInfo2,
  NextLesson: NextLesson,
};

const imageComponents = [];

const quizList = ["WalletMCQ", "WalletSelectorMCQ", "ViewMethodMCQ"];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
