export const blockPrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Correct, {{user}}. Primarily, blockchains hold 'data'",
        "To understand how exactly blockchains hold 'data', let's start with the fundamental unit of a blockchain.",
        "A single block of data",
      ],
      primerAvatar: "box",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "BlockInfo",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "{{user}}, let's suppose for some reason we are interested to know if both the blocks contain the same data",
        "Can you think of a way to compare if the data in the two blocks are equal?",
      ],
      primerAvatar: "confused",

      textReply: { que: "How to check if the two data are equal?" },
      userReply: { reply: null, userAvatar: "Wink" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Since, data is simply a sequence of symbols...",
        "..we can compare symbols at their respective positions.",
        "And if the symbols are equal in the corresponding positions, we can say both the data are equal.",
      ],
      primerAvatar: "point",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "VisualiseInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "UniqueMCQQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, the previous quiz might have surprised you a little bit.",
        "Along with fingerprints, the gait, which is the manner in which someone walks and the shape of an ear are unique to every individual.",
        "Similarly, we can generate an unique identifier or fingerprint for a particular piece of data using a process called Hashing.",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "HashInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "HashingMCQQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, similar to chopping up data to make it easier to search or lookup...",
        "..we can also chop up the data and create an unique fingerprint of the data. ",
        "This is called creating an hash of the data.",
      ],
      primerAvatar: "cook",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "HashingDataInfo",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "{{user}}, now that we have come across hash functions...",
        "... can you think of a way to compare two pieces of data?",
      ],
      primerAvatar: "question",
      textReply: {
        que: "How to check if the two data are equal using hash value?",
      },
      userReply: { reply: null, userAvatar: "Sleepy" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "We can say that if two pieces of data are equal if their hash value are same",
        "Let's see this in action",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "CompareHashInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "Hash2MCQQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      content: "NextLessonInfo",
      chats: [
        "{{user}}, hash functions forms the basis of blockchains as we know it.",
        "In the next lesson, we will start taking look at how blockchains make use of hash values",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "wave",
    },
  },
];
