import { remapQuiz } from "../../utils";

// Smart Contracts using Javascript
import introduction from "./ContractJS/Introduction";
import gasRuntime from "./ContractJS/GasRuntime";
import firstContract from "./ContractJS/FirstContract";
import contractState from "./ContractJS/ContractState";
import dataStructures from "./ContractJS/DataStructures";
import crossContract from "./ContractJS/CrossContract";

export const primerComponents = {
  introduction: introduction.primerComponents,
  "gas-transactions": gasRuntime.primerComponents,
  "first-contract": firstContract.primerComponents,
  "contract-state": contractState.primerComponents,
  "data-structures": dataStructures.primerComponents,
  "smart-contract-actions": crossContract.primerComponents,
};

export const quizList = {
  introduction: remapQuiz(introduction.quizList),
  "gas-transactions": remapQuiz(gasRuntime.quizList),
  "first-contract": remapQuiz(firstContract.quizList),
  "contract-state": remapQuiz(contractState.quizList),
  "data-structures": remapQuiz(dataStructures.quizList),
  "smart-contract-actions": remapQuiz(crossContract.quizList),
};

export const imageComponents = {
  introduction: introduction.imageComponents,
  "gas-transactions": gasRuntime.imageComponents,
  "first-contract": firstContract.imageComponents,
  "contract-state": contractState.imageComponents,
  "data-structures": dataStructures.imageComponents,
  "smart-contract-actions": crossContract.imageComponents,
};

const main = {
  primerComponents,
  imageComponents,
  quizList,
};

export default main;

export const smartContract = [
  {
    group: 1,
    key: "js",
    name: "Smart Contract using Javascript",
    topicOrder: [[1], [2, 3], [4, 5, 6]],
    background: "wheat",
    topics: [
      {
        id: 1,
        title: "Introduction",
        stars: "0",
        image: "smartContract",
        slug: "introduction",
        progress: 0,
        wrong: 0,
        primers: introduction.primers,
      },
      {
        id: 2,
        title: "Gas & Transactions",
        stars: "0",
        image: "smartContract",
        slug: "gas-transactions",
        progress: 0,
        wrong: 0,
        primers: gasRuntime.primers,
      },

      {
        id: 3,

        title: "First Contract",
        stars: "0",
        image: "smartContract",
        slug: "first-contract",
        progress: 0,
        wrong: 0,
        primers: firstContract.primers,
      },
      {
        id: 4,

        title: "Contract State",
        stars: "0",
        image: "smartContract",
        slug: "contract-state",
        progress: 0,
        wrong: 0,
        primers: contractState.primers,
      },
      {
        id: 5,

        title: "Data Structures",
        stars: "0",
        image: "smartContract",
        slug: "data-structures",
        progress: 0,
        wrong: 0,
        primers: dataStructures.primers,
      },
      {
        id: 6,
        title: "Smart Contract Actions",
        stars: "0",
        image: "smartContract",
        slug: "smart-contract-actions",
        progress: 0,
        wrong: 0,
        primers: crossContract.primers,
      },
    ],
  },
];
