export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        '{{user}}, in this quest, we will learn how to create applications called "Smart Contract" that can run on top of NEAR Protocol Platform',
        "And we will learn to write them using Javascript Programming Langauge",
        "Let's get started with a simple example",
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Hello",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ChargeMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "View methods can be called for free by everyone",
        "You don't require even a NEAR account to call them.",
        "Before talking a look at the view methods, let's recap what we have learned about accounts on NEAR Protocol.",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "StateInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "StateMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Operations which read from the stored state of the account are essentially free on NEAR Protocol",
        "We can also state this as operations which don't change account states are free of cost",
        "Let's take a closer look at View Methods now.",
      ],
      primerAvatar: "patient",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "ViewMethods",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ViewCallMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        'Methods that change the state of an account are called "Call Methods"',
        "Call Methods are usually executed slower than those of View Methods",
        "{{user}}, can you think of any reason why?",
      ],
      primerAvatar: "puzzle",
      textReply: { que: "Why do Call Methods are executed slower?" },
      userReply: { reply: null, userAvatar: "Cheeks" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "In a View Method, any node can execute the method without any further assitance from the network",
        'While, call methods can "potentially" change the state and hence are sent to the network for verification hence are more accurate "Change Methods"',
        'Let\'s take a look at "Change Methods" in detail.',
      ],
      primerAvatar: "showcase",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "CallMethods",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ChangeMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'To invoke a change method, an account needs to pay for the "gas" required to execute the method.',
        "This is done by signing the transaction with an account using your private key",
        'Let\'s call the change method using the "near call" command in the NEAR-CLI',
      ],
      primerAvatar: "showcase",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "ChangeMethod",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "TrxMatch",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        'Measuring the computational cost of executing a transaction is done using "Gas"',
        "In the next section, we will learn about Gas and how transactions are executed in detail",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "wave",
    },
  },
];
