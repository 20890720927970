import React from "react";
import DashboardAppBar from "./DashboardAppBar";
import QuestList from "./QuestList";
import { useAppContext } from "../../AppContext";
import { Button } from "@mui/material";

const src = `https://assets.primerlabs.io/PrimerAvatar150/wave.png`;
const openLink = (url) => window.open(url, "_blank")?.focus();

const Dashboard = () => {
  const context = useAppContext();
  React.useEffect(() => {
    document.title = "NEAR Protocol Primer";
  }, []);

  return (
    <React.Fragment>
      <DashboardAppBar context={context} />
      <div className="App-Dashboard">
        <div
          style={{
            fontWeight: "bold",
            background: "darkslateblue",
            textAlign: "center",
            color: "floralwhite",
            display: "flex",
            justifyContent: "space-around",
            padding: "20px",
          }}
        >
          <div>
            <img src={src} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <div style={{ margin: "5px" }}>
                  Welcome{" "}
                  <span style={{ color: "salmon" }}>{context.name}</span> to
                  <span style={{ color: "salmon", marginLeft: "5px" }}>
                    {" "}
                    NEAR Protocol Primer
                  </span>
                </div>
                <p>
                  To know more details about the platform, you can read our
                  introductory post.{" "}
                </p>
                <Button
                  onClick={() => openLink("/introduction")}
                  className="LessonBtn"
                  style={{
                    cursor: "pointer",
                    boxShadow: "none",
                    borderBottom: "15px",
                  }}
                  variant="contained"
                >
                  Read Introduction
                </Button>
              </div>
            </div>
          </div>
        </div>
        <QuestList />
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
