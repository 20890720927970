import React from "react";

export const getInitialState = (context) => {
  const loggedIn = context.walletAccountId !== null;
  const welcomeText = loggedIn
    ? `Welcome to [[NEAR Protocol]] **Command Line Interface**
Logged in using [[${context.walletAccountId}]] wallet account
  
Type [["near help"]] to get a list of commands
  
  `
    : `Welcome to [[NEAR Protocol]] Command Line Interface
Type [["near help"]] to get a list of commands
  
  `;

  return { welcomeText, walletContent };
};

export const walletContent = (
  <React.Fragment>
    You will require a <u>near testnet wallet</u> to interact with NEAR testnet
    Blockchain which you can create for free using by clicking this link
  </React.Fragment>
);
