import React from "react";
import { sha256 } from "js-sha256";
import { HashTextField, CssTextField } from "./styledComponents";
import { Paper } from "@mui/material";

const Hash = ({ userData = null }) => {
  const [data, setData] = React.useState(
    userData
      ? userData
      : "Hi There! Change this text to see the hash value below change."
  );

  return (
    <div style={{ maxWidth: "500px", margin: "30px auto" }}>
      <Paper
        elevation={3}
        sx={{ padding: "30px", display: "flex", flexDirection: "column" }}
      >
        <CssTextField
          label="Data"
          multiline
          rows={4}
          value={data}
          onChange={(evt) => setData(evt.target.value)}
        />
        <br />
        <HashTextField label="Hash Value" value={sha256(data)} />
      </Paper>
    </div>
  );
};

export default Hash;
