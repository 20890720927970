import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate
} from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import Welcome from './components/Welcome/Welcome'
import AppContextProvider, { useAppContext } from './AppContext'
import Dashboard from './components/Dashboard/Dashboard'
import NoMatch from './NoMatch'
import Quest from './components/Quest/Quest'
import Explorable from './components/Explorables/Explorable'
import Dapp from './components/Explorables/Common/OneOff/DappSandbox/DappPage'
import Guide from './components/ShortPrimers/Guide'
import { Toaster } from 'react-hot-toast'
import WallOfLove from './components/Explorables/Common/OneOff/DappSandbox/WallOfLovePage'
import PostMessage from './components/Explorables/Common/OneOff/DappSandbox/PostMessagePage'
// import ReactGA from 'react-ga4'
import Introduction from './components/Introduction/Introduction'
import Lesson from './components/Lesson/Lesson'
import './App.css'
import 'csshake'
import '@code-hike/mdx/dist/index.css'
import 'katex/dist/katex.min.css'

// const TRACKING_ID = 'G-90JSE57N2E'
// ReactGA.initialize(TRACKING_ID)

class App extends React.Component {
  // componentDidMount () {
  //   ReactGA.send({
  //     hitType: 'pageview',
  //     page: window.location.pathname + window.location.search
  //   })
  // }

  render () {
    return (
      <BrowserRouter>
        <AppContextProvider>
          <ThemeProvider theme={theme}>
            <Routes>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />

              <Route
                path='quest/*'
                element={
                  <ProtectedRoute>
                    <Quest />
                  </ProtectedRoute>
                }
              />

              <Route
                path='explorable/*'
                element={
                  <ProtectedRoute>
                    <Explorable />
                  </ProtectedRoute>
                }
              />

              <Route
                path='wall-of-love'
                element={
                  <ProtectedRoute>
                    <WallOfLove />
                  </ProtectedRoute>
                }
              />
              <Route
                path='post-message'
                element={
                  <ProtectedRoute>
                    <PostMessage />
                  </ProtectedRoute>
                }
              />

              <Route
                path='dapp/*'
                element={
                  <ProtectedRoute>
                    <Dapp />
                  </ProtectedRoute>
                }
              />

              <Route
                path='guides/*'
                element={
                  <ProtectedRoute>
                    <Guide />
                  </ProtectedRoute>
                }
              />

              <Route path='introduction' element={<Introduction />} />

              <Route
                path='quest/:questId/l/*'
                element={
                  <ProtectedRoute>
                    <Lesson quest={true} />
                  </ProtectedRoute>
                }
              />

              <Route path='welcome' element={<Welcome />} />
              <Route path='*' element={<NoMatch />} />
            </Routes>
          </ThemeProvider>
          <Toaster
            position='bottom-left'
            toastOptions={{
              style: {
                fontWeight: 'bold'
              }
            }}
          />
        </AppContextProvider>
      </BrowserRouter>
    )
  }
}

export default App

const ProtectedRoute = ({ children }) => {
  const { name } = useAppContext()
  const location = useLocation()

  React.useEffect(() => {
    document.title = 'NEAR Protocol Primer'
  }, [])

  if (name.length === 0) {
    return <Navigate to='/welcome' replace state={{ from: location }} />
  }

  return children
}
