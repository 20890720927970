import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Alice's Public Key",
    "Bob's Private Key",
    "Bob's Public Key",
    "Alice's Private Key",
  ];
  const correctOption = "Bob's Public Key";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, with which key <b>Alice</b> should encrypt a message of{" "}
          <b>Bob</b>, so that only he can read it?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
