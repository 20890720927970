// Info
import AddingAccessKeyInfo from "./PrimerComponents/AddingAccessKeyInfo.mdx";
import AddKeyInfo from "./PrimerComponents/AddKeyInfo.mdx";
import DeletingKeyInfo from "./PrimerComponents/DeletingKeyInfo.mdx";
import AccessKeyRotationsInfo from "./PrimerComponents/AccessKeyRotationsInfo.mdx";
import ConvertingImplicitInfo from "./PrimerComponents/ConvertingImplicitInfo.mdx";
import ImplicitAccountsInfo from "./PrimerComponents/ImplicitAccountsInfo.mdx";
import NextLesson from "./PrimerComponents/NextLesson";

// Quizzes
import LoginQuiz from "./Quiz/LoginQuiz";
import ArgumentQuiz from "./Quiz/ArgumentQuiz";
import NoAccessQuiz from "./Quiz/NoAccessQuiz";
import AccountQuiz from "./Quiz/AccountQuiz";
import AccessMatchQuiz from "./Quiz/AccessMatchQuiz";

// Primers
import { start } from "./Primers/Start";

const primers = [...start];

const primerComponents = {
  AddingAccessKeyInfo: AddingAccessKeyInfo,
  AddKeyInfo: AddKeyInfo,
  DeletingKeyInfo: DeletingKeyInfo,
  AccessKeyRotationsInfo: AccessKeyRotationsInfo,
  ConvertingImplicitInfo: ConvertingImplicitInfo,
  ImplicitAccountsInfo: ImplicitAccountsInfo,
  LoginQuiz: LoginQuiz,
  ArgumentQuiz: ArgumentQuiz,
  NoAccessQuiz: NoAccessQuiz,
  AccessMatchQuiz: AccessMatchQuiz,
  AccountQuiz: AccountQuiz,
  NextLesson: NextLesson,
};

const imageComponents = [];

const quizList = [
  "LoginQuiz",
  "ArgumentQuiz",
  "NoAccessQuiz",
  "AccountQuiz",
  "AccessMatchQuiz",
];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
