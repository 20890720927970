import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Smart contract's methods cannot be called unless the state has been initialized",
    'Only call decorated-methods can be called using "near call" command',
    "View commands can also modify the account state",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, which of the following statements regarding{" "}
            <code>smart contracts</code> on <b>NEAR Protocol</b> are{" "}
            <b>false</b>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
