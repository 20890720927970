import {
  StyledTableRow,
  StyledTableCell,
} from "../../../../../components/Common/Table.jsx";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";

const BlockTimeTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "300px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell
              sx={{
                color: "floralwhite",
                textAlign: "center",
              }}
              variant="body"
            >
              Book ID
            </StyledTableCell>
            <StyledTableCell
              sx={{ color: "floralwhite", textAlign: "center" }}
              variant="body"
            >
              Book Title
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              1
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Dracula
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              2
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Emma
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              3
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Hamlet
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              4
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Walden
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              5
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Othello
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlockTimeTable;
