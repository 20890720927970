import { Paper } from "@mui/material";
import Serializer from "../../../../../components/Explorables/Common/OneOff/Serializer/Serializer";

import ShortPrimer from "../../../../../components/ShortPrimers/ShortPrimer";

const Text = () => (
  <p>
    You can checkout our short guide on{" "}
    <span style={{ color: "indigo" }}>serialization and deserialization</span>{" "}
    to get better idea about how it is useful.
  </p>
);

const Text1 = () => (
  <p>
    You can checkout our short guide on making use of
    <span style={{ color: "indigo" }}> execution environment </span> to get
    better idea about how we can use them in our{" "}
    <span style={{ color: "indigo" }}>smart contracts</span> .
  </p>
);

const Text2 = () => (
  <p>
    You can checkout our short guide on making use of different
    <span style={{ color: "indigo" }}> SDK Collections </span> offered by{" "}
    <code>near-sdk-js</code> to understand better about how we can use them in
    our <span style={{ color: "indigo" }}>smart contracts</span> .
  </p>
);

export const SerialPrimer = () => (
  <ShortPrimer text={Text} type="serialization" />
);

export const EnvironmentPrimer = () => (
  <ShortPrimer avatar="gentleman" text={Text1} type="execution-environment" />
);

export const CollectionsPrimer = () => (
  <ShortPrimer avatar="showcase" text={Text2} type="sdk-collections" />
);

export const SerializerExplorable = () => <Serializer />;

export const SerializerInfo = () => (
  <Paper
    className="Indi"
    style={{
      background: "indigo",
      padding: "20px",
      margin: "10px auto",
      color: "floralwhite",
    }}
  >
    <div
      style={{
        margin: "0px 25px",
        lineHeight: "1.5rem",
        textAlign: "center",
        fontWeight: "bold",
      }}
    >
      <h2 style={{ textAlign: "center", fontSize: "1.5rem" }}>Serialization</h2>
      This process of translating{" "}
      <b style={{ color: "salmon" }}>complex objects</b> into{" "}
      <b style={{ color: "salmon" }}>simpler single-value representations</b> is
      called <b style={{ color: "salmon" }}>serialization</b>.
    </div>
  </Paper>
);

export const ExecutionEnvironmentInfo = () => (
  <Paper
    className="Indi"
    style={{
      background: "indigo",
      padding: "20px",
      margin: "10px auto",
      color: "floralwhite",
    }}
  >
    <div
      style={{
        margin: "0px 25px",
        lineHeight: "1.5rem",
        fontWeight: "bold",
      }}
    >
      <h2 style={{ textAlign: "center" }}>Execution Environment Information</h2>
      <ul>
        <li>
          Who <strong>called</strong> the method?
        </li>
        <li>
          How much <strong>money</strong> is attached to the call?
        </li>
        <li>
          How many <strong>computational resources</strong> are available?
        </li>
        <li>
          What is the <strong>current time</strong>?
        </li>
      </ul>
    </div>
  </Paper>
);
