import {
  StyledTableRow,
  StyledTableCell,
} from "../../../../../components/Common/Table.jsx";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";

const BlockTimeTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "900px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell
              sx={{
                color: "floralwhite",
                textAlign: "center",
              }}
              variant="body"
            >
              Method/Attribute
            </StyledTableCell>
            <StyledTableCell
              sx={{ color: "floralwhite", textAlign: "center" }}
              variant="body"
            >
              Type
            </StyledTableCell>
            <StyledTableCell
              sx={{ color: "floralwhite", textAlign: "center" }}
              variant="body"
            >
              Description
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>book_shelf</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>UnorderedMap</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              An <code>UnorderedMap</code> collection object where will will
              insert out books at the time of initialization.
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>init</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>Initialization</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              The <code>initialization</code> method where will insert created
              books instances from the model.ts
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>list_shelf</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>View</code> Method
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              A <code>view</code> method that lists down all the stored books in
              our <code>book_shelf</code> and their <b>average rating</b> and
              the <b>number of reviewers</b>.
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>add_rating</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>Call</code> Method
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              A <code>call</code> method or <code>change</code> method where the{" "}
              <code>smart contract caller</code> can add their{" "}
              <code>rating</code> for the book.
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>get_book_detail</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>View</code> Method
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              A <code>view</code> method which returns the details of a given
              <b>book</b>.
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlockTimeTable;
