// Infos
import DataStructures from "./PrimerComponents/DataStructures.mdx";
import Structures from "./PrimerComponents/Structures.mdx";
import SDKContracts from "./PrimerComponents/SDKContracts.mdx";
import Environment from "./PrimerComponents/Environment.mdx";
import GuestBook from "./PrimerComponents/GuestBook.mdx";
import RatingContract from "./PrimerComponents/RatingContract.mdx";
import RatingContract2 from "./PrimerComponents/RatingContract2.mdx";
import RatingContract3 from "./PrimerComponents/RatingContract3.mdx";

// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";

// Quiz
import SerializeMCQ from "./Quiz/SerializeMCQ";
import SDKMCQ from "./Quiz/SDKMCQ";
import TrieMCQ from "./Quiz/TrieMCQ";
import EnvMCQ from "./Quiz/EnvMCQ";
import MapMCQ from "./Quiz/MapMCQ";
import UnorderedMCQ from "./Quiz/UnorderedMCQ";
import SignerMCQ from "./Quiz/SignerMCQ";

// Primers
import { start } from "./Primers/Start";

const primers = [...start];

const primerComponents = {
  DataStructures: DataStructures,
  Structures: Structures,
  SDKContracts: SDKContracts,
  SerializeMCQ: SerializeMCQ,
  Environment: Environment,
  GuestBook: GuestBook,
  EnvMCQ: EnvMCQ,
  NextLesson: NextLesson,
  SDKMCQ: SDKMCQ,
  TrieMCQ: TrieMCQ,
  MapMCQ: MapMCQ,
  RatingContract: RatingContract,
  UnorderedMCQ: UnorderedMCQ,
  RatingContract2: RatingContract2,
  SignerMCQ: SignerMCQ,
  RatingContract3: RatingContract3,
};

const imageComponents = [];

const quizList = [
  "SerializeMCQ",
  "SDKMCQ",
  "TrieMCQ",
  "EnvMCQ",
  "MapMCQ",
  "UnorderedMCQ",
];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
