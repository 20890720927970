import React from "react";
import Content from "./Content.mdx";
import "./Introduction.css";
import { Link } from "react-router-dom";

const Introduction = () => {
  return (
    <div
      className="IntroductionPost"
      style={{
        maxWidth: "800px",
        margin: "auto",
      }}
    >
      <Link to="/">
        <h1
          style={{
            textAlign: "center",
            background: "floralwhite",
            padding: "10px",
            borderRadius: "15px",
            border: "2px solid indigo",
          }}
        >
          <span style={{ color: "indigo" }}>NEAR Protocol</span> Primer
        </h1>
      </Link>

      <Content />
      <div style={{ height: "300px" }}></div>
    </div>
  );
};

export default Introduction;
