import React from "react";
import AliceCarousel from "react-alice-carousel";
import { sha256 } from "js-sha256";
import PlainBlock from "./FriendsPlainBlock";
import "react-alice-carousel/lib/alice-carousel.css";
import { AntTab, AntTabs } from "./styledComponents";
import { ledgerData } from "./friendsData";

export const LedgerChainContext = React.createContext();

const peers = ["A", "B", "C", "D", "E"];

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 2 },
};

const peerItemsValue = peers.reduce(
  (acc, curr) => ({ ...acc, [curr]: ledgerData }),
  {}
);

const calculateHash = (chain) => {
  let hash = chain.reduce((acc, block) => {
    const blockHash = `block${block.blockNumber}nonce${
      block.nonceValue
    }data${calculateTransaction(block.tx)}prev${findPreviousHash(
      block.prev,
      chain
    )}hash`;
    acc[block.id] = sha256(blockHash);
    return acc;
  }, {});
  return hash;
};

export const calculateTransaction = (tx) => {
  let finaltx = tx.reduce((acc, tran) => {
    acc = acc + tran.id + tran.amt + tran.from + tran.to;
    return acc;
  }, "");
  return sha256(finaltx);
};

const findPreviousHash = (prev, chain) => {
  if (prev === null) {
    return "0".repeat(64);
  } else {
    let prevBlock = chain.find((a) => parseInt(a.id) === prev);
    let prevBlockHashPrev = findPreviousHash(prevBlock.prev, chain);
    let prevBlockHash = `block${prevBlock.blockNumber}nonce${
      prevBlock.nonceValue
    }data${calculateTransaction(prevBlock.tx)}prev${prevBlockHashPrev}hash`;
    let hash = sha256(prevBlockHash);
    return hash;
  }
};

const peerHashValue = peers.reduce(
  (acc, curr) => ({ ...acc, [curr]: calculateHash(ledgerData) }),
  {}
);

// const items = [
//   <PlainBlock />,
//   <PlainBlock />,
//   <PlainBlock />,
//   <PlainBlock />,
//   <PlainBlock />,
// ];

const generateBlocks = (chain) => {
  return chain.map((x, idx) => (
    <PlainBlock key={`item-${x.id}`} id={x.id} prev={x.prev} />
  ));
};

const LedgerBlockchain = () => {
  const [peerChain, setPeerChain] = React.useState(peerItemsValue);
  const [peer, setPeer] = React.useState(0);
  const [peerHash, setPeerHash] = React.useState(peerHashValue);
  const [items] = React.useState(generateBlocks(ledgerData));

  React.useEffect(() => {
    setPeerHash((peerHash) => ({
      ...peerHash,
      [peers[peer]]: calculateHash(peerChain[peers[peer]]),
    }));
  }, [peerChain[peers[peer]]]);

  const chainValues = {
    peerChain,
    setPeerChain,
    peerHash,
    setPeerHash,
    peer,
    peers,
  };

  const handleChangeTab = (event, newValue) => setPeer(newValue);

  function a11yProps(index) {
    return {
      id: `peer-${index}`,
      "aria-controls": `peer-${index}`,
    };
  }

  // React.useEffect(() => {
  //   console.log(peerChain);
  // }, [peerChain]);

  return (
    <LedgerChainContext.Provider value={chainValues}>
      <AntTabs
        value={peer}
        onChange={handleChangeTab}
        aria-label="friendly ledger"
        centered
      >
        <AntTab label="Alice's Ledger" {...a11yProps("A")} />
        <AntTab label="Bob's Ledger" {...a11yProps("B")} />
        <AntTab label="Cavill's Ledger" {...a11yProps("C")} />
        <AntTab label="David's Ledger" {...a11yProps("D")} />
        <AntTab label="Elise's Ledger" {...a11yProps("E")} />
      </AntTabs>
      <AliceCarousel mouseTracking items={items} responsive={responsive} />
    </LedgerChainContext.Provider>
  );
};

export default LedgerBlockchain;
