import React from "react";
import { eccrypto } from "eccrypto";
import { CenteredLayout } from "../../../../Quiz/layouts/Layout";
import { Paper, Button } from "@mui/material";
import { HashTextField, NormalTextField } from "../../../styledComponents";
import { sha256 } from "js-sha256";

const Explorable = ({
  type,
  generateKeyPair,
  keyPair,
  setEditPubKey,
  editPubKey,
  encyptedMessage,
  setEncryptedMessage,
  encryptMessage,
  setEditPrivKey,
  editPrivKey,
  decryptMessage,
  decryptedMessage,
  message,
  setMessage,
  signPrivKey,
  setSignPrivKey,
  signPubKey,
  setSignPubKey,
  signMessage,
  signature,
  verifyMessage,
  setDecryptedMessage,
  messageVerified,
}) => {
  const { alice, bob } = keyPair;
  const encryptedMessageText = encyptedMessage.ciphertext.toString("hex");
  if (type === "GENERATE") {
    return (
      <CenteredLayout>
        <Paper
          style={{
            maxWidth: "700px",
            margin: "30px auto",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Paper
            elevation={0}
            style={{
              maxWidth: "600px",
              margin: "5px auto",
              width: "100%",
              padding: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <NormalTextField
              label="Alice's Private Key"
              value={alice.privateKey.toString("hex")}
              style={{ margin: "10px" }}
              multiline
              rows={3}
            />
            <NormalTextField
              label="Alice's Public Key"
              value={alice.publicKey}
              style={{ margin: "10px" }}
              multiline
              rows={5}
            />
          </Paper>
          <Paper
            elevation={0}
            style={{
              maxWidth: "600px",
              width: "100%",
              margin: "5px auto",
              padding: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <NormalTextField
              label="Bob's Private Key"
              value={bob.privateKey.toString("hex")}
              style={{ margin: "10px" }}
              multiline
              rows={3}
            />
            <NormalTextField
              label="Bob's Public Key"
              value={bob.publicKey}
              style={{ margin: "10px" }}
              multiline
              rows={5}
            />
          </Paper>

          <div style={{ margin: "auto" }}>
            <Button
              onClick={generateKeyPair}
              variant="outlined"
              sx={{ height: "3rem", fontSize: "1rem", margin: "10px auto" }}
            >
              Generate Key Pair
            </Button>
          </div>
        </Paper>
      </CenteredLayout>
    );
  } else if (type === "ENCRYPT") {
    return (
      <CenteredLayout>
        <Paper
          style={{
            maxWidth: "400px",
            margin: "30px auto",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <NormalTextField
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={{ margin: "10px", fontSize: "small" }}
            multiline
            rows={3}
            readOnly
            maxLength={200}
          />
          <NormalTextField
            label="Public Key"
            value={editPubKey}
            onChange={(e) => setEditPubKey(e.target.value)}
            style={{ margin: "10px" }}
            multiline
            rows={5}
          />

          <div style={{ margin: "auto" }}>
            <Button
              onClick={encryptMessage}
              variant="outlined"
              sx={{ height: "3rem", fontSize: "1rem", margin: "10px auto" }}
            >
              Encrypt Message
            </Button>
          </div>
          <NormalTextField
            label="Encrypted Message"
            value={encryptedMessageText}
            style={{ margin: "10px" }}
            multiline
            rows={5}
          />
        </Paper>
      </CenteredLayout>
    );
  } else if (type === "SIGN") {
    return (
      <CenteredLayout>
        <Paper
          style={{
            maxWidth: "400px",
            margin: "30px auto",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <NormalTextField
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={{ margin: "10px", fontSize: "small" }}
            multiline
            rows={3}
            readOnly
            maxLength={200}
          />
          <NormalTextField
            label="Private Key"
            value={signPrivKey}
            onChange={(e) => setSignPrivKey(e.target.value)}
            style={{ margin: "10px" }}
            multiline
            rows={5}
          />
          <HashTextField
            label="Message Hash"
            value={sha256(message)}
            style={{ margin: "10px" }}
          />

          <div style={{ margin: "auto" }}>
            <Button
              onClick={signMessage}
              variant="outlined"
              sx={{ height: "3rem", fontSize: "1rem", margin: "10px auto" }}
            >
              Sign Message
            </Button>
          </div>
          <HashTextField
            label="Digital Signature"
            value={signature}
            style={{ margin: "10px" }}
          />
        </Paper>
      </CenteredLayout>
    );
  } else if (type === "DECRYPT") {
    return (
      <CenteredLayout>
        <Paper
          style={{
            maxWidth: "400px",
            margin: "30px auto",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <NormalTextField
            label="Encrypted Message"
            value={encryptedMessageText}
            style={{ margin: "10px" }}
            multiline
            rows={5}
          />

          <NormalTextField
            label="Private Key"
            value={editPrivKey}
            onChange={(e) => setEditPrivKey(e.target.value)}
            style={{ margin: "10px" }}
            multiline
            rows={5}
          />

          <div style={{ margin: "auto" }}>
            <Button
              onClick={decryptMessage}
              variant="outlined"
              sx={{ height: "3rem", fontSize: "1rem", margin: "10px auto" }}
            >
              Decrypt Message
            </Button>
          </div>
          <NormalTextField
            label="Decrypted Message"
            value={decryptedMessage}
            style={{ margin: "10px", fontSize: "small" }}
            multiline
            rows={5}
          />
        </Paper>
      </CenteredLayout>
    );
  } else if (type === "VERIFY") {
    return (
      <CenteredLayout>
        <Paper
          style={{
            maxWidth: "400px",
            margin: "30px auto",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <NormalTextField
            label="Digital Signature"
            value={signature}
            style={{ margin: "10px" }}
            multiline
            rows={5}
          />
          <NormalTextField
            label="Decrypted Message"
            value={decryptedMessage}
            onChange={(e) => setDecryptedMessage(e.target.value)}
            style={{ margin: "10px", fontSize: "small" }}
            multiline
            rows={5}
          />
          <HashTextField
            label="Message Hash"
            value={sha256(decryptedMessage)}
            style={{ margin: "10px" }}
          />
          <NormalTextField
            label="Public Key"
            value={signPubKey}
            onChange={(e) => setSignPubKey(e.target.value)}
            style={{ margin: "10px" }}
            multiline
            rows={5}
          />

          <div style={{ margin: "auto" }}>
            <Button
              onClick={verifyMessage}
              variant="outlined"
              sx={{ height: "3rem", fontSize: "1rem", margin: "10px auto" }}
            >
              Verify Message
            </Button>
          </div>
          {messageVerified ? (
            <h2 style={{ color: "darkgreen", textAlign: "center" }}>
              Message Verified
            </h2>
          ) : (
            <h2 style={{ color: "red", textAlign: "center" }}>
              Message Not Verified
            </h2>
          )}
        </Paper>
      </CenteredLayout>
    );
  }
};

export default Explorable;
