import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactFlow, {
  Background,
  Controls,
  useNodesState,
  useEdgesState,
  addEdge,
} from "react-flow-renderer";

import StorageNode from "./StorageNode";
import AccountNode from "./AccountNode";
import AccessKeyNode from "./AccessKeyNode";
import ContractNode from "./ContractNode";
import CalculatorNode from "./CalculatorNode";
import CustomEdge from "./CustomEdge";
import { intialEdges } from "./data";
import { FUNCTION_STORAGE, FULL_STORAGE } from "./AccessKeyNode";
import toast from "react-hot-toast";

const nodeTypes = {
  storage: StorageNode,
  account: AccountNode,
  calculator: CalculatorNode,
  accessKey: AccessKeyNode,
  contract: ContractNode,
};

const edgeTypes = {};

const defaultEdges = [];

const defaultNodes = [];

export const checkNear = (
  contractDeployedMain,
  contractSizeMain,
  funcKeysMain,
  fullKeysMain,
  storageUsedMain,
  nearMain,
  storage_amount_per_byte
) => {
  const finalContractSize = contractDeployedMain ? contractSizeMain : 0;
  const totalStorage =
    100 +
    funcKeysMain * FUNCTION_STORAGE +
    fullKeysMain * FULL_STORAGE +
    finalContractSize * 1000 +
    storageUsedMain * 1000;
  const nearLocked = (totalStorage * storage_amount_per_byte) / 10 ** 24;
  const nearAvailable = nearMain - nearLocked;
  return { nearAvailable, nearLocked, totalStorage };
};

const STORAGE_AMOUNT_PER_BYTE = "10000000000000000000";

function Flow({ storage_amount_per_byte = STORAGE_AMOUNT_PER_BYTE }) {
  const reactFlowRef = useRef(null);

  const [nodes, setNodes, onNodesChange] = useNodesState(defaultNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(defaultEdges);
  const [near, setNearMain] = useState(5);
  const [fullKeys, setFullKeysMain] = useState(1);
  const [funcKeys, setFuncKeysMain] = useState(0);
  const [contractDeployed, setContractDeployedMain] = useState(false);
  const [contractSize, setContractSizeMain] = useState(10);
  const [storageUsed, setStorageUsedMain] = useState(0);

  const setNear = (value) => {
    toast.dismiss();
    setNearMain(value);
  };

  const setContractSize = (value) => {
    toast.dismiss();
    setContractSizeMain(value);
  };

  const setContractDeployed = (value) => {
    toast.dismiss();
    setContractDeployedMain(value);
  };

  const setFullKeys = (value) => {
    toast.dismiss();
    setFullKeysMain(value);
  };

  const setFuncKeys = (value) => {
    toast.dismiss();
    setFuncKeysMain(value);
  };

  const setStorageUsed = (value) => {
    toast.dismiss();
    setStorageUsedMain(value);
  };

  const calculatorData = {
    near,
    fullKeys,
    funcKeys,
    contractDeployed,
    contractSize,
    storageUsed,
    storage_amount_per_byte,
  };

  useEffect(() => {
    setEdges(intialEdges);
    setNodes([
      {
        id: "account",
        type: "account",
        position: { x: 0, y: -100 },
        draggable: false,
        data: {
          value: near,
          min: 0,
          max: 40,
          onChange: setNear,
          label: "Account State",
        },
      },
      {
        id: "accessKey",
        type: "accessKey",
        draggable: false,
        position: { x: -50, y: 100 },
        data: {
          label: "Access Keys",
          fullValue: fullKeys,
          funcValue: funcKeys,
          min: 0,
          max: 20,
          fullChange: setFullKeys,
          funcChange: setFuncKeys,
        },
      },

      {
        id: "contract",
        type: "contract",
        draggable: false,
        position: { x: 140, y: 250 },
        data: {
          setContractSize: setContractSize,
          contractSize: contractSize,
          contractDeployed: contractDeployed,
          setContractDeployed: setContractDeployed,
          min: 10,
          max: 2000,
          label: "Smart Contract",
        },
      },
      {
        id: "storage",
        type: "storage",
        draggable: false,
        position: { x: 340, y: 300 },
        data: {
          label: "Storage",
          min: 0,
          max: 2000,
          storageUsed: storageUsed,
          setStorageUsed: setStorageUsed,
        },
      },

      {
        id: "calculator",
        type: "calculator",
        draggable: false,
        position: { x: 350, y: 0 },
        data: { label: "Calculator", ...calculatorData },
      },
    ]);
  }, []);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((n) => {
        if (n.id === "account") {
          n.data = { ...n.data, value: near, calculatorData };
        }
        if (n.id === "calculator") {
          n.data = { ...n.data, near };
        } else {
          n.data = { ...n.data, calculatorData };
        }
        return n;
      })
    );
  }, [near]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((n) => {
        if (n.id === "accessKey") {
          n.data = {
            ...n.data,
            fullValue: fullKeys,
            funcValue: funcKeys,
            calculatorData,
          };
        }
        if (n.id === "calculator") {
          n.data = { ...n.data, fullKeys, funcKeys };
        } else {
          n.data = { ...n.data, calculatorData };
        }
        return n;
      })
    );
  }, [fullKeys, funcKeys]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((n) => {
        if (n.id === "contract") {
          n.data = {
            ...n.data,
            contractSize: contractSize,
            contractDeployed: contractDeployed,
            calculatorData,
          };
        }
        if (n.id === "calculator") {
          n.data = { ...n.data, contractSize, contractDeployed };
        } else {
          n.data = { ...n.data, calculatorData };
        }
        return n;
      })
    );

    setEdges((nds) =>
      nds.map((n) => {
        if (n.id === "contract->calculator") {
          if (!contractDeployed) {
            n.style = {
              stroke: "none",
            };
          } else if (contractDeployed) {
            n.style = {
              stroke: "#A3ADB8",
              strokeWidth: 1.5,
            };
          }
        }
        return n;
      })
    );
  }, [contractSize, contractDeployed]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((n) => {
        if (n.id === "storage") {
          n.data = {
            ...n.data,
            storageUsed: storageUsed,
            calculatorData,
          };
        }
        if (n.id === "calculator") {
          n.data = { ...n.data, storageUsed: storageUsed };
        } else {
          n.data = { ...n.data, calculatorData };
        }
        return n;
      })
    );

    setEdges((nds) =>
      nds.map((n) => {
        if (n.id === "storage->calculator") {
          if (!storageUsed) {
            n.style = {
              stroke: "none",
            };
          } else if (storageUsed) {
            n.style = {
              stroke: "#A3ADB8",
              strokeWidth: 1.5,
            };
          }
        }
        return n;
      })
    );
  }, [storageUsed]);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );
  return (
    <React.Fragment>
      <div>
        Let's assume that each <code>function call access key</code> takes{" "}
        <code>133 bytes</code>, while each <code>full access key</code> takes{" "}
        <code>82 bytes</code>.
        <div style={{ textAlign: "center" }}>
          <code>Storage Price</code>:{" "}
          <code>{parseInt(storage_amount_per_byte) / 10 ** 19}</code> <b>Ⓝ</b>{" "}
          per <b>100 KB</b>.
        </div>
      </div>
      <div
        style={{
          margin: "10px auto",
          padding: "10px",
          width: "700px",
          height: "600px",
        }}
      >
        <ReactFlow
          zoomOnScroll={false}
          preventScrolling={false}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          proOptions={{ hideAttribution: true, account: "paid-sponsor" }}
          nodes={nodes}
          edges={edges}
          ref={reactFlowRef}
          fitView
        >
          <Background />
          <Controls showInteractive={false} />
        </ReactFlow>
      </div>
    </React.Fragment>
  );
}

export default Flow;
