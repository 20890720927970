export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, in the previous lesson, we looked at how Bitcoin uses Proof-of-Work to solve the problem of Consensus",
        "We also looked at various issues that arises from employing the Proof-of-Work Consensus Algorithm",
        "Let's take a look at another consensus algorithm, Proof of Stake, which is designed to solves the downsides of Proof of Work",
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "POS",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "POSMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, Proof-of-Stake allows the blockchains to finalise transactions much faster than Proof-of-Work",
        "However, that's not the only advantage over Proof-of-Work",
        "Let's take a look at the advantages of Proof-of-Stake in detail",
      ],
      primerAvatar: "super-robot",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Advantages",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "POSFillQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, hope you have started to get an idea about Proof of Stake works",
        "Earlier, we looked at various downsides of using Bitcoin",
        "Let's take a look at how NEAR Protocol handles them",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "NEAR",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "{{user}}, you have successfully completed the quest on the Introduction to Blockchain",
        "Congratulations for making this far",
        "See you in the next lesson.",
      ],
      primerAvatar: "celebrate",
    },
  },
];

/*
1. PoS vs PoS
2. Usability and Carbon Negative 
3. Sharding Basics 
4. NEAR Protocol is much more than just recording transactions, it's a platform that opens up new blockchain to occur
*/
