// Info
import Consensus from "./PrimerComponents/Consensus.mdx";
import ProofOfWork from "./PrimerComponents/ProofOfWork.mdx";
import Work from "./PrimerComponents/Work.mdx";
import Difficulty from "./PrimerComponents/Difficulty.mdx";
import DoubleSpending from "./PrimerComponents/DoubleSpending.mdx";
import Issues from "./PrimerComponents/Issues.mdx";

// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";

// Quiz

import BitcoinFillQuiz from "./Quiz/BitcoinFillQuiz";
import BitcoinMatchQuiz from "./Quiz/BitcoinMatchQuiz";
import DifficultyFillQuiz from "./Quiz/DifficultyFillQuiz";
import DiffMCQ from "./Quiz/DiffMCQ";
import DoubleMCQ from "./Quiz/DoubleMCQ";

// Primers
import { start } from "./Primers/Start";

const primers = [...start];

const primerComponents = {
  BitcoinFillQuiz: BitcoinFillQuiz,
  ProofOfWork: ProofOfWork,
  Consensus: Consensus,
  BitcoinMatchQuiz: BitcoinMatchQuiz,
  DifficultyFillQuiz: DifficultyFillQuiz,
  Work: Work,
  Difficulty: Difficulty,
  DiffMCQ: DiffMCQ,
  DoubleSpending: DoubleSpending,
  Issues: Issues,
  DoubleMCQ: DoubleMCQ,
  NextLesson: NextLesson,
};

const imageComponents = [];

const quizList = [
  "BitcoinFillQuiz",
  "BitcoinMatchQuiz",
  "DifficultyFillQuiz",
  "DiffMCQ",
  "DoubleMCQ",
];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;

/*
1. Double Spending
2. Issues with Bitcoin Ledger
*/
