export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, in the previous lesson, we learned about few types of transactions",
        "In this lesson, we will continue learning about transactions related to adding and deleting access keys",
        "Let's start with how we can generate and add an access key to an account",
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "AddingAccessKeyInfo",
    },
  },

  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "LoginQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, once you generate a keypair, you can add it to your account. ",
        "Let's take a look at how we can add a newly generated key to our account",
      ],
      primerAvatar: "security-2",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "AddKeyInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ArgumentQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Your access keys are quite important part of your account. ",
        "And often, it is required to delete old keys in case they are compromised or just for safety precautions.",
        "Next, let's take a look at how we can delete our access keys which are added to the account. ",
      ],
      primerAvatar: "security-1",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DeletingKeyInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "NoAccessQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "If an account doesn't have any access keys, it cannot be modified in any way and no transactions are allowed apart from the account receiving NEAR Tokens",
        "These accounts are called locked accounts and we will cover them in the later part of the course. ",
        "For now, let's take a look at a method to keep your account secure by rotating your access keys",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "AccessKeyRotationsInfo",
    },
  },

  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "AccountQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'The account with name "abc.d" is not a valid account name as the parent account "d" is too small',
        "While, the 64 characters-length hexadecimal strings are completely valid and are special types of account called implicit accounts",
        "Let's take a look at implicit accounts next. ",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "ImplicitAccountsInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "AccessMatchQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "We can also convert our implicit account we just created to named account.",
        "We do this by calling the respective contract on each network",
        "Let's take a look at how to convert implicit accounts into named accounts next",
      ],
      primerAvatar: "patient",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "ConvertingImplicitInfo",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "{{user}}, that brings us to the end of this lesson. ",
        "In the next lesson, we will take a look at how we can deploy smart contracts into our near accounts. ",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "good-bye",
    },
  },
];
