import { OptionParser, OutputFactory } from "javascript-terminal";
import { helpMenuText } from "../NEARTextContent/HelpMenuText";
import { CustomOutputFactory } from "../outputs";

export const optDef = {};

export default (state, commandOptions) => {
  //   const context = React.useContext(AppContext);

  const { parseOptions } = OptionParser;
  const { argv } = parseOptions(commandOptions, optDef);

  if (argv.length === 0) {
    return {
      output: CustomOutputFactory.createColoredRecord(helpMenuText),
    };
  } else if (argv.length === 1) {
    // console.log(context);
    return renderCommands(argv[0], state);
  }

  return {
    output: OutputFactory.makeTextOutput("So this is working"),
  };
};

const helpCommand = (state) => {
  console.log(state);
  console.log("Helping Command");
  return {
    output: OutputFactory.makeTextOutput("Helping function"),
  };
};

const renderCommands = (arg, state = {}) =>
  ({
    help: helpCommand(state),
  }[arg]);
