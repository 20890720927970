import React, { useRef, useEffect } from "react";
import { CssTextField } from "./styledComponents";
import { Paper, Typography } from "@mui/material";
import anime from "animejs";
import { userAvatars } from "./Avatar";
import { CurrencyRuble } from "@mui/icons-material";

const UserReply = ({ chats = [], send, currentlyActive, avatar, ...props }) => {
  if (!avatar) {
    console.log("UserAvatar is not defined for ", chats);
  }
  const avatarText = userAvatars.find((x) => x.text === avatar);
  const userSrc = avatarText
    ? `https://assets.primerlabs.io/kawaii/${avatarText.value}`
    : `https://assets.primerlabs.io/kawaii/${userAvatars[3].value}`;
  const userAvatar = useRef();
  const userLabel = useRef();
  const animation = useRef();
  const userMessageCard = useRef();

  useEffect(() => {
    if (currentlyActive) {
      const userMessageCards =
        userMessageCard.current.querySelectorAll(".UserMessage");
      animation.current = anime.timeline({
        loop: false,
        autoplay: true,
      });

      animation.current.add({
        targets: [userAvatar.current, userLabel.current],
        scale: [0, 1],
        opacity: [0, 1],
        begin: () => {
          userMessageCard.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        },
      });

      animation.current.add({
        targets: userMessageCards,
        opacity: [0, 1],
        translateY: [25, 0],

        delay: function (el, i, l) {
          return i * 300;
        },
        complete: function (anim) {
          send("NEXT");
        },
      });
    }
  }, []);

  return (
    <Paper
      style={{
        margin: "0 20px 0 auto",
        padding: "15px 30px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",
        backgroundColor: "aliceblue",
        width: "fit-content",
      }}
    >
      <div
        ref={userMessageCard}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          justifyContent: "center",
        }}
      >
        {chats.map((x, id) => (
          <Paper
            className="UserMessage"
            key={`chatid-${id}`}
            elevation={3}
            sx={{
              padding: "10px",
              margin: "10px",
              width: "fit-content",
              background: "cornflowerblue",
              textAlign: "right",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Nunito",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {x}
            </Typography>
          </Paper>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "150px",
          textAlign: "center",
          padding: "10px",
          borderRadius: "12px",
        }}
      >
        <div style={{ padding: "10px" }}>
          <img
            ref={userAvatar}
            alt={userAvatars[1].text}
            src={userSrc}
            style={{ height: "130px" }}
          />
        </div>
        <div
          ref={userLabel}
          style={{
            background: "cornflowerblue",
            color: "floralwhite",
            borderRadius: "4px",
            margin: "0px 10px",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: "0.8rem",
              verticalAlign: "center",
            }}
          >
            {props.name}
          </span>
        </div>
      </div>
    </Paper>
  );
};

export default UserReply;
