import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Querying the account balance for each of the three accounts",
    "Store the balance on our smart contract and query it",
  ];
  const correctOption =
    "Querying the account balance for each of the three accounts";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, in our mockup for <b>Donations DApp</b>, we would like
            to display the balance of the three <b>charity accounts</b>. What do
            you think is the best way to do that?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
