export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        '{{user}}, in the previous lesson, we stumbled upon a problem of creating a time-stamp service in the absence of "generally trusted third-parties"',
        "Let's reframe our problem statment to understand it better",
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "TrustedThirdParty",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "HashingMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "When we link the blocks together with their respective hashes...",
        "...any attempt of tampering the blocks becomes evident",
        "Let's start designing our trustless time-stamping service using this important property",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "TrustlessTSA",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        'A trustless TSA has to publish "aggregate hash value" at a place...',
        '...where there are lot of "random witnessess" and which cannot be modified at a later point',
        "Can you think of such a place?",
      ],
      primerAvatar: "puzzle",

      textReply: { que: "Can you think of such a place?" },
      userReply: { reply: null, userAvatar: "Cheeks" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      primerAvatar: "notepad",
      chats: [
        'There are many such internet forums, where the TSA can regularly publish the "weekly aggregate hash value" of the universal registry',
        "However, being digital they are also susceptible to tampering",
        "An easier option would be to publish it in a national daily newspaper with fairly high circulation",
      ],
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "ChainValidationInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "HistoryMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      primerAvatar: "attention",
      chats: [
        'Our particular TSA can very well modify data stored inside it\'s "universal registry".',
        'However, once the "computed values" and "published values" are out of sync, it will lose it\'s credibility and it\'s "trustless" status.',
        "Therefore, it will no longer be used",
        'However, our "Trustless TSA" does have some other problems',
      ],
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "CentralisedTSAInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "GlossaryMatchQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "{{user}}, we learned that our trustless TSA is centralised, therefore, not just anyone could store their data into their blockchain",
        'The opposite of centralised is "de-centralised"',
        'What do you think a "de-centralised entity" mean? ',
      ],
      primerAvatar: "showcase",

      textReply: { que: "What do you think de-centralised entity mean" },
      userReply: { reply: null, userAvatar: "Dreamer" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      primerAvatar: "patient",
      chats: [
        'A "de-centralised entity" has no single authority',
        "It refers to the transfer of control and decision-making from a centralised entity to a distributed group of entities",
        'Let\'s take a look at how we can build a "Decentralised Trustless Time-Stamping Authority" ',
      ],
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DistributedTSAInfo",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "Before, we start figuring out the solution to the above posed challenges, let me ask you a question.",
        'As you noticed that the "blockchain" was invented way earlier than 2009, when Satoshi Nakamoto first published his paper on Bitcoin.',
        "{{user}}, then what do you think Satoshi Nakamoto invented? ",
      ],
      primerAvatar: "attention",

      textReply: { que: "What do you think Satoshi Invented?" },
      userReply: { reply: null, userAvatar: "Cute" },
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "SatoshiInfo",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "{{user}}, we have thus come to the end of this section.",
        "Before moving on to the next section, let's revisit the very first question I asked you.",
        "Now that you have learned quite a deal about blockchains...",
        "... {{user}}, what do you think is a blockchain? ",
      ],
      primerAvatar: "normal",
      textReply: { que: "What do you think is a blockchain?" },
      userReply: { reply: null, userAvatar: "Wink" },
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      primerAvatar: "wave",
      chats: [
        "We can define the blockchain as...",
        '...."an immutable database where each block is cryptographically linked to it\'s previous one in a way that makes any proof of tampering clearly evident"',
        "And in the next section, we will build upon this definition to create a global payment system without trusted third-party",
        "See you in the next section, {{user}}",
      ],
    },
  },
];
