import { Paper } from "@mui/material";

export const StepsForDevAccounts = () => (
  <Paper
    style={{ padding: "30px", background: "#47307f", color: "floralwhite" }}
  >
    <h2 style={{ textAlign: "center" }}>Dev-Deploy</h2>
    <ol style={{ fontWeight: "bold" }}>
      <li style={{ margin: "10px 0" }}>Generate a local keypair.</li>
      <li style={{ margin: "10px 0" }}>
        Creates a new testnet account with a name like{" "}
        <code style={{ color: "salmon" }}>
          dev-1626793583587-89195915741581
        </code>
      </li>
      <li style={{ margin: "10px 0" }}>
        Stores this account name in a{" "}
        <code style={{ color: "salmon" }}>neardev</code> folder within the
        project
      </li>
      <li style={{ margin: "10px 0" }}>
        Stores the private key for this account in the{" "}
        <code style={{ color: "salmon" }}>~/.near-credentials</code> folder
      </li>
      <li style={{ margin: "10px 0" }}>
        Deploys your contract code to this newly created{" "}
        <code style={{ color: "salmon" }}>dev</code> account
      </li>
    </ol>
  </Paper>
);
