import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "new functionCall access key is created",
    "An access key is created in the user' account state",
    "User's account storage expands due to the newly created key",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, what do you think happens when the user{" "}
            <b>signs in</b> using their preferred <b>wallet</b> into our{" "}
            <b>application</b>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
