import React from "react";

const TextNone = ({ send }) => {
  React.useEffect(() => {
    send("NONE");
  }, []);

  return <React.Fragment></React.Fragment>;
};

export default TextNone;
