import React from "react";
import Wrapper from "./NodeWrapper";
import { Box } from "@mui/material";
import Handle from "./Handle";
import { PrettoSlider } from "./Slider";
import Switch from "@mui/material/Switch";
import { checkNear } from "./Canvas";
import toast from "react-hot-toast";

export default function SliderNode({ data }) {
  const {
    label = "",
    min = 0,
    max = 1,
    contractDeployed = false,
    contractSize = 50,
    setContractSize = () => {},
    setContractDeployed = () => {},
    calculatorData,
  } = data;

  const setContractDeployedMain = (value) => {
    const {
      contractSize,
      funcKeys,
      fullKeys,
      storageUsed,
      storage_amount_per_byte,
      near,
    } = calculatorData;

    let checkNearAmt = checkNear(
      value,
      contractSize,
      funcKeys,
      fullKeys,
      storageUsed,
      near,
      storage_amount_per_byte
    );
    if (checkNearAmt.nearAvailable > 0) {
      setContractDeployed(value);
    } else {
      toast.error(
        `Cannot deploy Smart Contract as there not enough NEAR tokens cover for ${checkNearAmt.totalStorage} B of data`,
        {
          id: "contractDeployed",
        }
      );
    }
  };

  const setContractSizeMain = (value) => {
    const {
      contractDeployed,
      funcKeys,
      fullKeys,
      storageUsed,
      storage_amount_per_byte,
      near,
    } = calculatorData;

    let checkNearAmt = checkNear(
      contractDeployed,
      value,
      funcKeys,
      fullKeys,
      storageUsed,
      near,
      storage_amount_per_byte
    );
    if (checkNearAmt.nearAvailable > 0) {
      setContractSize(value);
    } else {
      toast.error(
        `Not enough NEAR tokens to cover for smart contract size of ${value} B`,
        {
          id: "contractSize",
        }
      );
    }
  };

  return (
    <Wrapper label={label}>
      <Handle type="source" position="top" />
      <Box style={{ width: "140px" }}>
        <div style={{ textAlign: "center" }}>
          <span style={{ fontWeight: "bold", fontSize: "12px" }}>
            Smart Contract Deployed?
          </span>
          <br />
          <Switch
            checked={contractDeployed}
            onChange={(evt) => {
              setContractDeployedMain(evt.target.checked);
            }}
          />

          <br />
          {contractDeployed && (
            <span
              style={{
                fontWeight: "bold",
                fontSize: "12px",
                margin: "4px auto",
              }}
            >
              Contract Size: {contractSize} KB
            </span>
          )}
          {contractDeployed && (
            <React.Fragment>
              <PrettoSlider
                size="small"
                value={contractSize}
                valueLabelDisplay="auto"
                onChange={(evt) => {
                  setContractSizeMain(evt.target.value);
                }}
                step={50}
                min={min}
                max={max}
              />
              <div style={{ textAlign: "center" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    margin: "4px auto",
                    color: "#47307f",
                  }}
                >
                  {contractSize} KB
                </span>
              </div>
            </React.Fragment>
          )}
        </div>

        <br />
      </Box>
    </Wrapper>
  );
}
