import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["Fingerprint", "Gait", "Ears", "All of the above"];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      header={
        <React.Fragment>
          {props.name}, which of the following is unique for every individual?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
