import Spectrum from "../../../../../components/Explorables/Common/OneOff/DecentralisationSpectrum.jsx";
import { Paper } from "@mui/material";

export const NearDesign = () => (
  <Paper
    style={{
      background: "indigo",
      padding: "20px",
      color: "floralwhite",
      textAlign: "center",
    }}
  >
    You can learn more about <b>NEAR Protocol's design</b> by reading their{" "}
    <a
      style={{ color: "salmon" }}
      href="https://near.org/papers/the-official-near-white-paper/"
      target="_blank"
    >
      whitepaper
    </a>
    .
  </Paper>
);

export const Decentral = () => (
  <Paper
    style={{
      background: "indigo",
      padding: "20px",
      color: "floralwhite",
      textAlign: "center",
    }}
  >
    <b>Decentralization</b> is most important in cases where the{" "}
    <span style={{ color: "salmon", fontWeight: "bold" }}>
      data being stored is highly sensitive or vulnerable to censorship, theft,
      corruption or other forms of modification.
    </span>
  </Paper>
);

export const DigitalMoney = () => (
  <Paper
    style={{
      background: "floralwhite",
      padding: "20px",
      color: "indigo",
    }}
  >
    <h2>Earlier attempts in the creation of Anonymous Transactions</h2>
    <ul>
      <li>
        <a href="https://en.wikipedia.org/wiki/DigiCash" target="_blank">
          Chaumian's Blinded Cash
        </a>
      </li>
      <li>
        <a href="https://en.wikipedia.org/wiki/E-gold" target="_blank">
          E-Gold
        </a>
      </li>
      <li>
        <a href="https://nakamotoinstitute.org/bit-gold/" target="_blank">
          Bit Gold
        </a>
      </li>
      <li>
        <a href="https://en.bitcoin.it/wiki/B-money" target="_blank">
          B-Money
        </a>
      </li>
      <li>
        <a href="https://en.bitcoin.it/wiki/Hashcash" target="_blank">
          HashCash
        </a>
      </li>
    </ul>
  </Paper>
);

export default Spectrum;
