import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Data cannot be modified",
    "Any modifcation in the ledger is easily noticable",
    "Easy to maintain",
    "All of the above",
  ];
  const correctOption = "Any modifcation in the ledger is easily noticable";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, What's the benefit of maintaining a ledger in{" "}
          <b>the form of a decentralised blockchain</b>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
