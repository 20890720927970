// Infos
import BookRating from "./PrimerComponents/BookRating.mdx";
import BookRatingDapp from "./PrimerComponents/BookRatingDapp.mdx";
import BookRatingDapp2 from "./PrimerComponents/BookRatingDapp2.mdx";
import BookRatingDapp3 from "./PrimerComponents/BookRatingDapp3.mdx";
import BookRatingDapp4 from "./PrimerComponents/BookRatingDapp4.mdx";

// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";

// Quiz
import WalletMCQ from "./Quiz/WalletMCQ";
import IndexFill from "./Quiz/IndexFill";
import WalletSignInMCQ from "./Quiz/WalletSignInMCQ";
// Primers
import { start } from "./Primers/Start";

const primers = [...start];

const primerComponents = {
  BookRating: BookRating,
  BookRatingDapp: BookRatingDapp,
  BookRatingDapp2: BookRatingDapp2,
  BookRatingDapp3: BookRatingDapp3,
  BookRatingDapp4: BookRatingDapp4,
  WalletMCQ: WalletMCQ,
  IndexFill: IndexFill,
  WalletSignInMCQ: WalletSignInMCQ,
  NextLesson: NextLesson,
};

const imageComponents = [];

const quizList = ["WalletMCQ", "IndexFill", "WalletSignInMCQ"];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
