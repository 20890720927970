export const versionData = "0.18";

export const mainCourseData = [
  {
    id: 1,
    title: "Introduction to Blockchain",
    topics: "9",
    image: "/images/background/f1.png",
    backgroundColor: "beige",
    slug: "blockchain",
  },
  {
    id: 2,
    title: "The NEAR Protocol",
    topics: "8",
    image: "/images/background/f2.png",
    backgroundColor: "beige",
    slug: "near-protocol",
  },
  {
    id: 3,
    title: "Writing Smart Contracts",
    topics: "6",
    image: "/images/background/f3.png",
    backgroundColor: "beige",
    slug: "smart-contracts",
  },
  {
    id: 4,
    title: "Creating Decentralised Applications",
    topics: "3",
    image: "/images/background/f4.jpeg",
    backgroundColor: "beige",
    slug: "dapp",
  },
];

export const sideQuestData = [
  // {
  //   id: 1,
  //   title: "History of Cypherpunks",
  //   topics: "16",
  //   image: "cypherpunk",
  //   backgroundColor: "beige",
  //   slug: "cypherpunks",
  // },
  // {
  //   id: 2,
  //   title: "Bitcoin",
  //   topics: "16",
  //   image: "bitcoin",
  //   backgroundColor: "beige",
  //   slug: "bitcoin",
  // },
  // {
  //   id: 3,
  //   title: "Ethereum",
  //   topics: "16",
  //   image: "eth",
  //   backgroundColor: "beige",
  //   slug: "eth",
  // },
  {
    id: 4,
    title: "Assemblyscript",
    topics: "16",
    image: "as",
    backgroundColor: "beige",
    slug: "assemblyscript",
  },
  {
    id: 5,
    title: "Testing Smart Contracts",
    topics: "16",
    image: "test_contract",
    backgroundColor: "beige",
    slug: "testing-contracts",
  },
  // {
  //   id: 6,
  //   title: "Web3",
  //   topics: "16",
  //   image: "web3",
  //   backgroundColor: "beige",
  //   slug: "web3",
  // },
  // {
  //   id: 7,
  //   title: "Decentralised Finance",
  //   topics: "16",
  //   image: "defi2",
  //   backgroundColor: "beige",
  //   slug: "defi",
  // },
  // {
  //   id: 8,
  //   title: "Decentralised Storage",
  //   topics: "16",
  //   image: "destorage",
  //   backgroundColor: "beige",
  //   slug: "storage",
  // },
  {
    id: 9,
    title: "Non-fungible Token",
    topics: "16",
    image: "nft",
    backgroundColor: "beige",
    slug: "nft",
  },
  // {
  //   id: 10,
  //   title: "Decentralised Organisation",
  //   topics: "16",
  //   image: "dao",
  //   backgroundColor: "beige",
  //   slug: "dao",
  // },
];
