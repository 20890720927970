import React from "react";
import FillQuiz from "../../../../../components/Quiz/FillQuiz";

const FillQuizTemplate = (props) => {
  const options = [
    "Wallet",
    "isSignedIn",
    "contractId",
    "sign",
    "nodes",
    "contract",
    "async",
    "startUp",
    "address",
  ];

  return (
    <FillQuiz
      header={
        <React.Fragment>
          {" "}
          <b>{props.name}</b>, can you fill in the blanks about how do we
          instantiate our <code>wallet</code> object in the{" "}
          <code>index.js</code> file in our project?
        </React.Fragment>
      }
      send={props.send}
      options={options}
      num={7}
      {...props}
    >
      First, we import the [Wallet|1] (class) from our helper file. Then we
      store our **smart contract** [address|2] in a variable. Then we
      instantiate our wallet object by passing the stored [contract|3] address
      in the (createAccessKeyFor) property. Then we modify our (render) method
      to load [async|4] functions. We call the [startUp|5] method of the
      (wallet) object and store it's return value in the [isSignedIn|6]
      variable. Finally, we pass (isSignedIn), [contractId|7] and the (wallet)
      to our (App component.)
    </FillQuiz>
  );
};

export default FillQuizTemplate;
