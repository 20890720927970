import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["7", "14", "21", "4"];
  const correctOption = "4";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, we encoded a message twice by using two different
          shifts. The second shift was <b>14</b> and the final resulting message
          was: <br />
          <blockquote>
            <code>A zghw qgm mfvwjklggv Uswkwj'k kzaxl !</code>
          </blockquote>
          Can you figure out the <b>first shift</b>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
