import React, { useMemo } from "react";
import { CenteredLayout } from "../Quiz/layouts/Layout";
import PrimerChat from "./component/PrimerChat";
import PrimerInfo from "./component/PrimerInfo";
import PrimerQuiz from "./component/PrimerQuiz";
import TextReply from "./component/TextReply";
import { useMachine } from "@xstate/react";
import { createMachine, assign } from "xstate";
import TextButton from "./component/TextButton";
import TextNext from "./component/TextNext";
import TextNone from "./component/TextNone";
import useImagePreloader from "../../hooks/useImagePreloader";

function insert(arr, index, ...items) {
  return [...arr.slice(0, index), ...items, ...arr.slice(index)];
}
const primerMachine = createMachine(
  {
    id: "primer",
    initial: "start",
    context: {
      primers: [],
      progress: 0,
      replyType: null,
    },
    states: {
      start: {
        initial: "loading",
        states: {
          loading: {
            id: "loading",
            on: {
              NEXT: "loaded",
            },
          },
          loaded: {
            entry: "checkReplyType",
            initial: "unknown",
            states: {
              unknown: {
                on: {
                  TEXT: {
                    target: "TEXT",
                  },
                  BUTTON: {
                    target: "BUTTON",
                  },
                  CONTINUE: {
                    target: "CONTINUE",
                  },
                  QUIZ: {
                    target: "QUIZ",
                  },
                  NONE: {
                    target: "NONE",
                  },
                },
              },

              NONE: {
                always: {
                  target: "#loading",
                  actions: assign({
                    progress: (context) => context.progress + 1,
                  }),
                },
              },

              QUIZ: {
                on: {
                  NEXT: {
                    target: "#loading",
                    actions: assign({
                      progress: (context) => context.progress + 1,
                    }),
                  },
                },
              },
              TEXT: {
                on: {
                  NEXT: {
                    target: "#userReplyLoading",
                    actions: assign({
                      replyType: (context) => null,
                      primers: (context, event) => {
                        const newPrimers = context.primers.map((x, i) =>
                          i === context.progress
                            ? {
                                ...x,
                                props: {
                                  ...x.props,
                                  userReply: {
                                    ...x.props.userReply,
                                    reply: [event.reply],
                                  },
                                },
                              }
                            : x
                        );

                        return newPrimers;
                      },
                    }),
                  },
                },
              },
              BUTTON: {
                on: {
                  NEXT: {
                    target: "#userReplyLoading",
                    actions: assign({
                      replyType: (context) => null,
                      primers: (context, event) => {
                        const newPrimers = context.primers.map((x, i) =>
                          i === context.progress
                            ? {
                                ...x,
                                props: {
                                  ...x.props,
                                  userReply: {
                                    ...x.props.userReply,
                                    reply: event.reply,
                                    userAvatar: event.userAvatar,
                                  },
                                },
                              }
                            : x
                        );

                        if (event.primers) {
                          const lastPrimerButton =
                            event.primers[event.primers.length - 1].reply ===
                            "BUTTON";
                          if (lastPrimerButton) {
                            const lastPrimerButtonOptions =
                              event.primers[event.primers.length - 1].props
                                .buttonOptions.length;

                            // Check if the last Primer has it's own button options
                            if (lastPrimerButtonOptions) {
                              // If already contains buttonOptions

                              const updatedPrimers = insert(
                                newPrimers,
                                context.progress + 1,
                                ...event.primers
                              );
                              return updatedPrimers;
                            } else {
                              // If blank or undefined
                              const updatedButtonOptions = context.primers[
                                context.progress
                              ].props.buttonOptions.filter(
                                (x) => x.text !== event.btn
                              );

                              const novelPrimers = event.primers.map((x, idx) =>
                                idx === event.primers.length - 1
                                  ? {
                                      ...x,
                                      props: {
                                        ...x.props,
                                        buttonOptions: updatedButtonOptions,
                                      },
                                    }
                                  : x
                              );

                              const updatedPrimers = insert(
                                newPrimers,
                                context.progress + 1,
                                ...novelPrimers
                              );

                              return updatedPrimers;
                            }
                          } else {
                            const updatedPrimers = insert(
                              newPrimers,
                              context.progress + 1,
                              ...event.primers
                            );
                            return updatedPrimers;
                          }
                        } else {
                          return newPrimers;
                        }
                      },
                    }),
                  },
                },
              },
              CONTINUE: {
                on: {
                  NEXT: {
                    target: "#loading",
                    actions: assign({
                      progress: (context) => context.progress + 1,
                      replyType: (context) => null,
                    }),
                  },
                },
              },
            },
          },
          userReplyLoading: {
            id: "userReplyLoading",
            on: {
              NEXT: {
                target: "loading",
                actions: assign({
                  progress: (context) => context.progress + 1,
                }),
              },
            },
          },
        },
      },
      on: {
        NEXT: "two",
      },
    },
    two: {
      on: {
        NEXT: "three",
        PREV: "start",
      },
    },
    three: {
      id: "final",
      type: "final",
    },
  },
  {
    actions: {
      checkReplyType: assign({
        replyType: (context) => context.primers[context.progress].reply,
      }),
      removeReply: assign({
        replyType: (context) => null,
      }),
    },

    guards: {
      isText: (context) => context.primers[context.progress].reply === "TEXT",
      isButton: (context) =>
        context.primers[context.progress].reply === "BUTTON",
    },
  }
);

const renderItem = (type, props) =>
  ({
    CHAT: <PrimerChat avatar={props.primerAvatar} {...props} />,
    INFO: <PrimerInfo {...props} />,
    QUIZ: <PrimerQuiz {...props} />,
  }[type]);

const renderReply = (type, props) =>
  ({
    TEXT: <TextReply {...props} />,
    BUTTON: <TextButton {...props} />,
    NEXT: <TextNext {...props} />,
    NONE: <TextNone {...props} />,
  }[type]);

const Interaction = (props) => {
  const { progress, primers, title } = props.topic;
  const { imagesPreloaded } = useImagePreloader(props.preloadSrcList);

  const dynamicPrimerMachine = useMemo(() => {
    return primerMachine.withContext({
      primers: primers,
      progress: progress,
    });
  }, [title]);

  const [current, send] = useMachine(dynamicPrimerMachine);
  const { context } = current;

  React.useEffect(() => {
    if (context.primers) {
      props.context.setTopics((topics) => {
        const { groupIndex, topicIndex, questId } = props;
        topics[questId][groupIndex].topics[topicIndex].primers =
          context.primers;
        return topics;
      });
    }
  }, [context.primers]);

  React.useEffect(() => {
    if (context.progress) {
      props.context.setTopics((topics) => {
        const { groupIndex, topicIndex, questId } = props;
        topics[questId][groupIndex].topics[topicIndex].progress =
          context.progress;
        return topics;
      });
    }
  }, [context.progress]);

  const currentPrimer = current.context.primers[current.context.progress];

  React.useEffect(() => {
    const questName = props.context.quests.find(
      (x) => x.slug === props.questId
    ).title;
    document.title = `${props.topic.title} - ${questName} | NEAR Protocol Primer`;
  }, []);

  // React.useEffect(() => {
  //   console.log(context.progress);
  //   console.log(current.value);
  // }, [current]);

  if (!imagesPreloaded) {
    return <p>Preloading Assets</p>;
  }

  return (
    <CenteredLayout>
      {current.context.primers
        .slice(0, current.context.progress + 1)
        .map((x, idx) => (
          <React.Fragment key={`item-${idx}`}>
            <div>
              {renderItem(x.type, {
                send,
                replyType: context.replyType,
                name: props.context.name,
                contextProgress: context.progress,
                index: idx,
                questId: props.questId,
                lessonId: props.lessonId,
                setProgressState: props.setProgressState,
                context: props.context,
                ...x.props,
              })}
            </div>
          </React.Fragment>
        ))}
      {renderReply(context.replyType, {
        send,
        currentPrimer,
        questId: props.questId,
        lessonId: props.lessonId,
      })}
    </CenteredLayout>
  );
};

export default Interaction;
