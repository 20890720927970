import React from "react";
import Code from "./components/codeblock";
import { resizeMarkdown } from "./utils";
import { createOptions } from "./utils";
import "./styles";

export const AppContext = React.createContext();

const getFileList = (file) => {
  let fileNums, name;
  [fileNums, name] = file.split("-");
  const listOfFiles = Array.from({ length: fileNums }, (_, i) => i + 1).reduce(
    (acc, i) => {
      acc[`${name}-${i}`] = `/flows/${fileNums}-${name}/${name}-${i}.md`;
      return acc;
    },
    {}
  );
  return { [name]: listOfFiles };
};

class CodeBlanks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFlowState: null,
      flowMark: {},
      selectedFlow: "intro",
      flowMarkdown: {},
      flowState: {},
      blanks: [],
      codeOptions: [],
      info: "Rearrange",
      error: false,
      validate: false,
      optionDetails: [],
      correctOptions: [],
      blankSelected: null,
      handleBlanks: this.handleBlanks,
      handleContext: this.handleContext,
      handleFlow: this.handleFlow,
    };
  }

  handleFlowStateChange = (value) => {
    const newState = `${this.state.selectedFlow}-${value}`;
    this.setState({
      // flows: this.state.flows.map((flow) =>
      //   flow.id === this.state.selectedFlow
      //     ? { ...flow, state: newState }
      //     : flow
      // ),
      currentFlowState: newState,
    });
  };

  handleBlanks = (id, value) => {
    // console.log(id, value)
    this.setState({
      blanks: this.state.blanks.map((blk) =>
        blk.id === id ? { ...blk, value: value } : blk
      ),
    });
  };

  handleContext = (key, value) => {
    if (key === "reset") {
      this.setState(
        {
          currentFlowState: `${this.state.selectedFlow}-1`,
        },
        () =>
          this.setState({
            blanks: createOptions(this.state.codeOptions),
          })
      );
    }

    if (key === "optionDetails") {
      // console.log(key, value);
      this.setState({
        [key]: value,
      });
    }

    if (key === "increase") {
      const regex = new RegExp(/([a-zA-Z]+)-(\d+)/, "g");
      const match = regex.exec(this.state.currentFlowState);

      if (match) {
        this.handleFlowStateChange(parseInt(match[2]) + 1);
      }
    } else {
      this.setState({
        [key]: value,
      });
    }
  };

  handleFlow = (flow) =>
    this.setState({
      selectedFlow: flow,
    });

  async componentDidMount() {
    const fileList = getFileList(this.props.flow);
    const fileListArray = Object.keys(fileList);
    this.setState({
      currentFlowState: `${fileListArray[0]}-1`,
    });

    for (let i = 0; i < fileListArray.length; i++) {
      const file = fileListArray[i];
      await Promise.all(
        Object.keys(fileList[file]).map((fp) => {
          const filePath = fileList[file][fp];
          const result = fetch(filePath)
            .then((res) => res.text())
            .then((text) => text);
          return result;
        })
      )
        .then((val) => {
          this.setState({
            selectedFlow: fileListArray[0],
            flowMarkdown: {
              ...this.state.flowMarkdown,
              [file]: resizeMarkdown(val),
            },
          });
        })
        .catch((err) => console.log(err, "Error Occured"));
    }
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        <Code
          handleContext={this.handleContext}
          handleBlanks={this.handleBlanks}
          handleFlowStateChange={this.handleFlowStateChange}
          context={this.state}
          flows={this.state.flows}
          currentFlowState={this.state.currentFlowState}
          selectedFlow={this.state.selectedFlow}
          flowMarkdown={this.state.flowMarkdown}
        />
      </AppContext.Provider>
    );
  }
}

export default CodeBlanks;

// function FlowButtons({ flows, setFlow, selectedFlow }) {
//   return (
//     <React.Fragment>
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         {flows.map((element, idx) => (
//           <button
//             className={selectedFlow === element.id ? "active" : ""}
//             onClick={() => setFlow(element.id)}
//             key={idx}
//           >
//             {element.file}
//           </button>
//         ))}
//       </div>
//       <hr />
//     </React.Fragment>
//   );
// }

{
  /* <FlowButtons
flows={this.state.flows}
setFlow={this.handleFlow}
selectedFlow={this.state.selectedFlow}
/> */
}
