import React from "react";
import MatchQuiz from "../../../../../components/Quiz/MatchQuiz";

const MatchTemplate = (props) => {
  const matchOptions = {
    "Validator Node": "Produces and Validates Blocks",
    "NEAR Token": "Digital Asset",
    "RPC Node": "Used to communicate with the blockchain network",
    "Account State": "Information associated with a given account",
    "Peer-to-Peer Network":
      "networks in which each computer can act as a server for others,",
  };
  return (
    <MatchQuiz
      large={true}
      header={
        <React.Fragment>
          {props.name}, can you match the entities and their usage?
        </React.Fragment>
      }
      matchOptions={matchOptions}
      {...props}
    />
  );
};

export default MatchTemplate;
