import React from "react";
import { TextField, Button, Box, Slide } from "@mui/material";
import { useAppContext } from "../../AppContext";

const Welcome = () => {
  const context = useAppContext();
  const updateName = (evt) => {
    context.setName(evt.target.value);
  };

  React.useEffect(() => {
    document.title = "Welcome | NEAR Protocol Primer";
  }, []);

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      if (context.name.length === 0 || checkName()) {
        return;
      } else {
        context.handleWelcome();
      }
    }
  };

  const checkName = () => {
    const { name } = context;
    if (name.length === 0) {
      return false;
    } else {
      const pattern = /^[A-Za-z]+$/;
      return !pattern.test(name);
    }
  };

  const nameIncorrect = checkName();

  if (context.name.length !== 0) {
  }

  return (
    <header className="App-header">
      <Box
        sx={{
          padding: "20px",
          textAlign: "center",
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <img src="/images/near-logo.svg" style={{ height: "150px" }} />
        <h1 className="App-title">NEAR Protocol Primer</h1>
        <TextField
          error={checkName()}
          onChange={updateName}
          helperText={
            nameIncorrect
              ? "Only use alphabets with no space"
              : "Please enter your first name"
          }
          id="outlined-basic"
          size="medium"
          label={<span>What should I call you, hooman?</span>}
          variant="outlined"
          color="neutral"
          onKeyPress={handleKeypress}
        />
        <div>
          <Button
            type="submit"
            onClick={() => context.handleWelcome()}
            disabled={context.name.length === 0 || nameIncorrect ? true : false}
            sx={{ margin: "10px" }}
            variant="contained"
            size="large"
          >
            Let's Go !
          </Button>
        </div>
      </Box>
    </header>
  );
};

export default Welcome;
