import React from "react";
import DashboardAppBar from "../../../../Dashboard/DashboardAppBar";
import { Route, Routes, useParams } from "react-router-dom";
import { useAppContext } from "../../../../../AppContext";
import { dappData } from "../../../ExplorableData";

const Dapp = (props) => {
  const context = useAppContext();
  return (
    <div>
      <Routes>
        <Route path=":dappId" element={<DappPage context={context} />} />
      </Routes>
    </div>
  );
};

export default Dapp;

export const DappPage = (props) => {
  const { dappId } = useParams();
  const { context } = props;

  const checkDapp = dappData.find((x) => x.slug === dappId);

  React.useEffect(() => {
    if (checkDapp) {
      document.title = `${checkDapp.title} | NEAR Protocol Primer`;
    } else {
      document.title = `No DApp Found | NEAR Protocol Primer`;
    }
  }, []);

  if (!checkDapp) {
    return (
      <React.Fragment>
        <DashboardAppBar context={context} />
        <div style={{ maxWidth: "900px", margin: "10px auto" }}>
          <h1>No DApp Found</h1>
        </div>
      </React.Fragment>
    );
  }

  if (checkDapp) {
    const Component = checkDapp.component;
    return (
      <React.Fragment>
        <DashboardAppBar context={context} />
        <div style={{ maxWidth: "900px", margin: "10px auto" }}>
          <div style={{ textAlign: "center" }}>
            <h1 style={{ textAlign: "center" }}>{checkDapp.title}</h1>
          </div>
          <Component />
        </div>
      </React.Fragment>
    );
  }
};
