import React from "react";
import { Prompt, Env } from "./common";

const getMessages = (type, message) =>
  ({
    invalidAccountID: (
      <span style={{ color: "salmon", fontWeight: "bold" }}>
        Account ID seems invalid
      </span>
    ),
    invalidPublicKey: (
      <span style={{ color: "salmon", fontWeight: "bold" }}>
        Public Key seems invalid
      </span>
    ),
    NoAccountAccessKeyFound: (
      <span>
        Cannot sign transactions for account{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {message.accountId}
        </span>{" "}
        on network{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {message.network}
        </span>
        ,{" "}
        <span style={{ color: "salmon", fontWeight: "bold" }}>
          no matching key pair found
        </span>
      </span>
    ),
    NoMatchingAccessKeyFound: (
      <span>
        Cannot sign transactions for account{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {message.accountId}
        </span>{" "}
        on network{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {message.network}
        </span>
        ,{" "}
        <span style={{ color: "salmon", fontWeight: "bold" }}>
          no matching key pair found
        </span>
      </span>
    ),
    AlreadyExists: (
      <span>
        The public key{" "}
        <span style={{ color: "salmon", fontWeight: "bold" }}>
          {message.publicKey}
        </span>{" "}
        is already used for an existing access key
      </span>
    ),
    NewAccessKeyAdded: (
      <span>
        New access Key added to the{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {message.accountId}
        </span>{" "}
        on network{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {message.network}
        </span>
      </span>
    ),
  }[type]);

const addMessage = ({
  accountId,
  testnet,
  publicKeyText,
  keyTypeFull,
  contractId,
  allowance,
  methods,
  message,
}) => {
  return (
    <div>
      <Prompt />
      {!testnet && <Env />} near add-key{" "}
      {accountId.trim().length === 0 ? (
        <span style={{ color: "gold" }}>&lt;accountId&gt; </span>
      ) : (
        <span style={{ color: "gold", fontWeight: "bold" }}>{accountId}</span>
      )}{" "}
      {publicKeyText.trim().length === 0 ? (
        <span style={{ color: "lightblue" }}>&lt;public-key&gt; </span>
      ) : (
        <span style={{ color: "lightblue", fontWeight: "bold" }}>
          {publicKeyText}
        </span>
      )}
      {!keyTypeFull ? (
        <FunctionSpan
          contractId={contractId}
          allowance={allowance}
          methods={methods}
        />
      ) : (
        ""
      )}
      <br />
      {message !== null && getMessages(message.type, message)}
    </div>
  );
};

export default addMessage;

const FunctionSpan = ({ contractId, allowance, methods }) => {
  return (
    <React.Fragment>
      <span style={{ color: "salmon" }}> --contract-id </span>
      {contractId.trim().length === 0 ? (
        <span style={{ color: "lightblue" }}>&lt;contract-id&gt; </span>
      ) : (
        <span style={{ color: "gold", fontWeight: "bold" }}>{contractId}</span>
      )}

      {methods.trim().length === 0 ? (
        <React.Fragment>
          <span style={{ color: "salmon" }}> --method-names </span>
          <span style={{ color: "lightblue" }}>&lt;method-list&gt; </span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <span style={{ color: "salmon" }}> --method-names </span>
          <span style={{ color: "gold", fontWeight: "bold" }}>"{methods}"</span>
        </React.Fragment>
      )}
      {allowance.trim().length === 0 ? (
        <React.Fragment>
          <span style={{ color: "salmon" }}>--allowance </span>
          <span style={{ color: "lightblue" }}>&lt;allowance&gt; </span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <span style={{ color: "salmon" }}>--allowance </span>
          <span style={{ color: "gold", fontWeight: "bold" }}>{allowance}</span>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
