import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AntTabs, AntTab } from "./Tabs";
{
  /* <span style={{ fontSize: "3rem" }}>{"\u201C"}</span>
 <span style={{ fontSize: "3rem" }}>{"\u201D"}</span> */
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography sx={{ color: "#47307f", display: "flex" }}>
            <span style={{ fontSize: "80px", margin: "0px", padding: "0px" }}>
              {"\u201C"}
            </span>
            <blockquote style={{ paddingTop: "10px", fontSize: "small" }}>
              {children}
            </blockquote>
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabPanelStyles = {
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  color: "#47307f",
};

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        display: "inline-block",
        bgcolor: "background.paper",
        border: "none",
      }}
    >
      <AntTabs
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderBottom: "none",
          borderRight: 1,
          borderColor: "divider",
          minWidth: "250px",
        }}
      >
        <AntTab label="Specification" {...a11yProps(0)} />
        <AntTab label="Infrastructure" {...a11yProps(1)} />
        <AntTab label="Applications" {...a11yProps(2)} />
        <AntTab label="Ecosystem" {...a11yProps(3)} />
      </AntTabs>
      <TabPanel style={tabPanelStyles} value={value} index={0}>
        First of all, at it's heart, <b>NEAR Protocol</b> is simply a{" "}
        <b>protocol</b>, a specification of how communities can come together
        and maintain a{" "}
        <b>distributed scalable cloud-computing infrastructure</b>.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={1}>
        Since, <b>NEAR Protocol</b> is a specification, the{" "}
        <b>core development team</b> (
        <a href="https://www.pagoda.co/" target="_blank">
          Pagoda
        </a>
        ) is tasked with creating and maintaining{" "}
        <b>reference implementations</b> or software that implement the
        specification.
        <br />
        This is to make it easy for anyone to run{" "}
        <b>NEAR Protocol Implementation</b> or a <b>client node</b>. The{" "}
        <b>core team</b> does a lot of things such as fixing bugs, managing
        upgrades to the specification etc. Since, the <b>protocol</b> is
        community operated, any change must go through a governance process and
        accepted by a majority of stakeholders for it to be accepted by the
        community.
        <br />
        Several participants who stake their <b>NEAR</b> tokens and run{" "}
        <b>NEAR Protocol Validator Nodes</b> provide the necessary
        infrastructure for the{" "}
        <b>community-operated cloud-computing platform</b>.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={2}>
        Now, since, the infrastructure is set, <b>NEAR Protocol</b> also creates
        tooling for developers to write applications that can be run and
        deployed on the <b>NEAR Protocol Cloud-Computing Infrastructure</b>. For
        instance, they write and maintain tools such as:
        <ul style={{ fontWeight: "bold", fontVariant: "all-small-caps" }}>
          <li>NEAR CLI</li>
          <li>NEAR API JS</li>
          <li>NEAR SDK JS</li>
          <li>NEAR SDK RS</li>
        </ul>
        These tools makes it's easier for developers to create applications on{" "}
        <b>NEAR</b>.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={3}>
        Finally, to foster creation of an ecosystem surrounding{" "}
        <b>NEAR Protocol Infrastructure</b>, <b>NEAR Protocol Foundation</b>{" "}
        also gives <b>grants</b> and <b>invests</b> in startups and projects
        that are useful for the ecosystem in whole.
      </TabPanel>
    </Box>
  );
}
