import { timeStampPrimers } from "./TimeStampPrimer";
const teamPrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      primerAvatar: "thumbs-up",
      chats: [
        "Well, the modern consensus might disagree with you, {{user}}",
        "Today, the scientific community feels that both men independently created calculus.",
        "This controversy also implies an important problem in the field of intellectual property",
        'Who gets the "priority" in a claim?',
      ],
      userReply: {
        reply: null,
        userAvatar: null,
      },
    },
  },
];

const independentPrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      primerAvatar: "thumbs-up",
      chats: [
        "And modern consensus, agrees with you, {{user}}.",
        "Today, the scientific community feels that both men independently created calculus.",
        "This controversy also implies an important problem in the field of intellectual property",
        'Who gets the "priority" in a claim?',
      ],
      userReply: {
        reply: null,
        userAvatar: null,
      },
    },
  },
];

const teamButtonOptions = [
  {
    text: "Team Newton",
    userReply: {
      reply: [
        "I am team Newton",
        "I believe Sir Isaac Newton is the inventor of calculus",
      ],
      userAvatar: "Smirk",
    },
    primers: [...teamPrimers, ...timeStampPrimers],
  },
  {
    text: "Team Leibniz",
    userReply: {
      reply: [
        "I am team Leibniz",
        "I believe Leibniz is the rightful inventor of calculus",
      ],
      userAvatar: "Wink",
    },
    primers: [...teamPrimers, ...timeStampPrimers],
  },
  {
    text: "Both of them",
    userReply: {
      reply: ["I think both of them discovered calculus independently"],
      userAvatar: "Happy",
    },
    primers: [...independentPrimers, ...timeStampPrimers],
  },
];

export const hashPrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      primerAvatar: "thumbs-up",
      chats: [
        "Correct, {{user}}. A block's hash value is a fingerprint of the entire block after all.",
        "Making our hash value dependent on previous block's hash will enable us to make each block dependent to it's predecessor",
        "Let me show you how.",
      ],
      userReply: {
        reply: null,
        userAvatar: null,
      },
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "PrevHashInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ValidBlockMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      primerAvatar: "gentleman",
      chats: [
        "{{user}}, let's take a break from learning about blockchains.",
        "And instead, let's go to Europe in the later part of 1600s... when a great controversy was brewing.",
        "The controversy of who invented Calculus. ",
      ],
      userReply: {
        reply: null,
        userAvatar: null,
      },
    },
  },

  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "CalculusInfo",
    },
  },
  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      primerAvatar: "attention",
      chats: [
        "{{user}}, so far, with what little information you have been presented...",
        "...which side you are on, in this matters of controversy?",
      ],
      userReply: {
        reply: null,
        userAvatar: null,
      },
      buttonOptions: teamButtonOptions,
    },
  },

  // Quiz MCQ
];

/*
    1. Using hash chains we made our systems noticable to any change of the data in the system.
    2.  
    2. But at this point, it doesn't make sense how does the blockchain locks the data
    3. Or if there is any actual use of this kind of system. 
    4. To understand that let's take a made up scenario. 
    5. Let's say there are five friends 
        1. They don't want to reveal their goals to their friends obviously. 
        2. 

  */

// {
//     type: "CHAT",
//     reply: "NEXT",
//     props: {
//       primerAvatar: "sorry",
//       chats: [
//         "{{user}}, I have a confession to make.",
//         "In my excitement to teach you blockchain, I have not yet mentioned what's the use of a blockchain.",
//         'Why do we even want to "lock" up the data inside a blockchain?',
//         'To make sense of "blockchain" as whole, let\'s first look at why blockchain was created in the first place.',
//         "Let's look at the world's first and longest running blockchain...",
//         "... and, no it's not bitcoin.",
//       ],
//       userReply: {
//         reply: null,
//         userAvatar: null,
//       },
//     },
//   },
