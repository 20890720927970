import React, { useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { toTitleCase } from "./utils";
import {
  mainCourseData,
  sideQuestData,
  versionData,
} from "./AppContextData/QuestData";
import { topicData, topicOrder } from "./AppContextData/TopicData";

import useLocalStorageState from "use-local-storage-state";
import {
  signIn,
  wallet,
  near,
  formatUtils,
  createAccount,
  getStateAccount,
  deleteAccount,
  getAccountKeys,
  mergeKeystore,
  generateKey,
  addAccessKey,
  deleteAccessKey,
  deployContract,
  devDeployContract,
  createDevAccount,
  callContract,
  callViewFunction,
  callViewStateFunction,
  getTxStatus,
  sendNear,
} from "./NearWallet";

export const AppContext = React.createContext();

const AppContextProvider = ({ children }) => {
  let location = useLocation();
  let navigate = useNavigate();
  const [name, setName] = useLocalStorageState("name", {
    defaultValue: "",
  });

  const [walletAccountId, setWalletAccountId] = useLocalStorageState(
    "walletAccountId",
    {
      defaultValue: null,
    }
  );

  const [walletAccountObj, setWalletAccountObj] = useLocalStorageState(
    "walletAccountObj",
    {
      defaultValue: null,
    }
  );

  const [accountDetails, setAccountDetails] = React.useState(null);
  const [accountBalance, setAccountBalance] = React.useState(null);

  const [quests, setQuests] = useLocalStorageState("quests", {
    defaultValue: mainCourseData,
  });
  const [sideQuests, setSideQuests] = useLocalStorageState("sideQuests", {
    defaultValue: sideQuestData,
  });

  const [topics, setTopics] = useLocalStorageState("topicData", {
    defaultValue: topicData,
  });

  const [version, setVersion] = useLocalStorageState("version", {
    defaultValue: versionData,
  });

  const handleWelcome = () => {
    const origin = location.state?.from?.pathname || "/";

    setName(toTitleCase(name));
    navigate(origin);
  };

  const resetTopicProgress = (isQuest, questId, lessonId) => {
    if (isQuest) {
      const groupIndex = topics[questId].findIndex((x) =>
        x.topics.some((y) => y.slug === lessonId)
      );

      const topicIndex = topics[questId][groupIndex].topics.findIndex(
        (x) => x.slug === lessonId
      );

      setTopics((topics) => {
        topics[questId][groupIndex].topics[topicIndex] =
          topicData[questId][groupIndex].topics[topicIndex];
        return topics;
      });

      window.location.reload();
    }
  };

  const resetProgress = () => {
    //name

    setName("");

    // walletAccountId
    // walletAccountObj

    // accountDetails
    setAccountDetails(null);
    // accountBalance
    setAccountBalance(null);

    // quests
    setQuests(mainCourseData);
    // sideQuests

    setSideQuests(sideQuestData);

    // topics
    setTopics(topicData);

    // version
    setVersion(versionData);

    window.location.reload();

    // const nearKeys = Object.keys(localStorage).filter((x) =>
    //   x.startsWith("near-api-js")
    // );
    // const nearObject = nearKeys.reduce(
    //   (acc, curr) => ({ ...acc, [curr]: localStorage[curr] }),
    //   {}
    // );

    // localStorage.clear();

    // mergeKeystore(nearKeys, nearObject).then((res) => {
    //   console.log(res);
    //   // window.location.reload();
    // });

    // fetchNearKeys.forEach((element) => {
    //   localStorage.setItem(element, createNearObject[element]);
    // });
  };

  const getAccountDetails = async (walletAccId) => {
    if (accountBalance === null) {
      const account = await near.account(walletAccId);
      const accBalance = await account.getAccountBalance();
      const accDetails = await account.getAccountDetails();
      const remappedBalance = Object.keys(accBalance).reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: formatUtils.format.formatNearAmount(accBalance[curr]),
        }),
        {}
      );
      setAccountDetails(accDetails);
      setAccountBalance(remappedBalance);
    }
  };

  const checkSignIn = () => {
    if (wallet.isSignedIn()) {
      const signedInAcc = wallet.getAccountId();
      const checkAccess = window.localStorage.getItem(
        `cli-near-api-js:keystore:${signedInAcc}:testnet`
      );

      if (checkAccess) {
        const walletAccountId = signedInAcc;
        setWalletAccountId(walletAccountId);
        // returns account object for transaction signing
        const walletAccountObj = wallet.account();
        setWalletAccountObj(walletAccountObj);
        getAccountDetails(walletAccountId);
      } else {
        setWalletAccountId(null);
        setWalletAccountObj(null);
        setAccountDetails(null);
        setAccountBalance(null);
      }
      // returns account Id as string
    } else {
      setWalletAccountId(null);
      setWalletAccountObj(null);
      setAccountDetails(null);
      setAccountBalance(null);
    }
  };

  const handleSignOut = () => {
    wallet.signOut();
    checkSignIn();
  };

  React.useEffect(() => {
    checkVersion();
    checkSignIn();
  }, []);

  const checkVersion = () => {
    const prevVersion = JSON.parse(localStorage.getItem("version"));
    if (prevVersion !== versionData) {
      setQuests(mainCourseData);
      setSideQuests(sideQuestData);
      setTopics(topicData);
      setVersion(versionData);
    }
  };

  const value = {
    name,
    setName,
    handleWelcome,
    quests,
    sideQuests,
    topics,
    setTopics,
    resetProgress,
    resetTopicProgress,
    signIn,
    createAccount,
    getStateAccount,
    deleteAccount,
    getAccountKeys,
    generateKey,
    addAccessKey,
    sendNear,
    deleteAccessKey,
    deployContract,
    devDeployContract,
    createDevAccount,
    callContract,
    callViewFunction,
    callViewStateFunction,
    getTxStatus,
    walletAccountId,
    setWalletAccountId,
    walletAccountObj,
    handleSignOut,
    accountDetails,
    accountBalance,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  return React.useContext(AppContext);
};

export default AppContextProvider;

// function useLocalStorageState(
//   key,
//   defaultValue = "",
//   { serialize = JSON.stringify, deserialize = JSON.parse } = {}
// ) {
//   const [state, setState] = React.useState(() => {
//     const valueInLocalStorage = window.localStorage.getItem(key);
//     if (valueInLocalStorage) {
//       try {
//         return deserialize(valueInLocalStorage);
//       } catch (error) {
//         window.localStorage.removeItem(key);
//       }
//     }
//     return typeof defaultValue === "function" ? defaultValue() : defaultValue;
//   });

//   const prevKeyRef = React.useRef(key);

//   React.useEffect(() => {
//     const prevKey = prevKeyRef.current;
//     if (prevKey !== key) {
//       window.localStorage.removeItem(prevKey);
//     }
//     prevKeyRef.current = key;
//     window.localStorage.setItem(key, serialize(state));
//   }, [key, state, serialize]);

//   return [state, setState];
// }
