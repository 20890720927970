import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";
import Code from "./Code.mdx";

const MCQ = (props) => {
  const options = ["2", "1", "0", "3"];
  const correctOption = "2";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, how many methods exist on the <code>hello.ts</code> file
          shown below?
          <div style={{ textAlign: "left" }}>
            <Code />
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
