import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Easier to calculate profit and loss at the end of year",
    "Makes it easier for outsiders to decide while giving a loan",
    "The ledger cannot be forged or faked",
    "Serves as a comprehensive record of the business",
  ];
  const correctOption = "The ledger cannot be forged or faked";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which of the following is <b>not</b> an advantage of the{" "}
          <b>double-entry system based ledger</b>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
