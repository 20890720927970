import React from "react";
import FillQuiz from "../../../../../components/Quiz/FillQuiz";

const FillQuizTemplate = (props) => {
  const options = [
    "private",
    "block",
    "gas",
    "accountID",
    "set_last_caller",
    "view",
    "change",
    "get_last_caller",
    "attribute",
    "last_caller",
  ];

  return (
    <FillQuiz
      header={
        <React.Fragment>
          {" "}
          <b>{props.name}</b>, can you fill in the blanks about how does our{" "}
          <code>HelloNEAR</code> <b>smart contract</b> works?
        </React.Fragment>
      }
      send={props.send}
      options={options}
      num={8}
      {...props}
    >
      When the smart contract is deployed to an account for the first time,
      initialises the class attribute [last_caller|1] to the (ID) of the account
      where it's deployed. Anyone can check the (last_caller) [attribute|2] by
      invoking the [get_last_caller|3] function, which is a [view|4] method. Any
      account which invokes the [change|5] method [set_last_caller|6], has it's
      [accountID|7] set as the (last_caller) attribute. Since, (set_last_caller)
      is a (change) method, it requires [gas|8] to be provided to execute the
      method, which is supplied by account that invokes the method.
    </FillQuiz>
  );
};

export default FillQuizTemplate;
