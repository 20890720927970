import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import ResetIcon from "@mui/icons-material/Undo";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import ResetConfirmationDialog from "./ResetConfirmationDialog";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 15,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 15,
  },
}));

const LessonProgress = ({
  context,
  overClose,
  setOverClose,
  navigate,
  questId,
  progress,
  progressState,
  primers = [],
  showReset = false,
  lessonId,
  isQuest,
}) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [overReset, setOverReset] = React.useState(false);

  const dialogProps = {
    openDialog,
    setOpenDialog,
    context,
    questId,
    lessonId,
    isQuest,
  };

  return (
    <AppBar
      position="static"
      style={{
        background: "floralwhite",
        display: "block",
        boxShadow: "none",
      }}
    >
      <div style={{ maxWidth: "900px", margin: "20px auto" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CloseIcon
            onMouseOver={() => setOverClose(true)}
            onMouseOut={() => setOverClose(false)}
            onClick={() => navigate(`/quest/${questId}`)}
            style={{
              transition: "0.5s",
              cursor: "pointer",
              fontSize: "35px",
              color: overClose ? "darkgray" : "lightgray",
              margin: "10px",
            }}
          />
          <div style={{ width: "90%", padding: "10px" }}>
            <BorderLinearProgress
              color={
                progressState === null
                  ? progress === primers.length
                    ? "success"
                    : "primary"
                  : progressState === "error"
                  ? "error"
                  : "success"
              }
              variant="determinate"
              value={(progress / primers.length) * 100}
            />
          </div>
          {showReset && (
            <ResetIcon
              onMouseOver={() => setOverReset(true)}
              onMouseOut={() => setOverReset(false)}
              onClick={() => setOpenDialog(true)}
              style={{
                transition: "0.5s",
                cursor: "pointer",
                fontSize: "35px",
                color: overReset ? "darkgray" : "lightgray",
                margin: "10px",
              }}
            />
          )}
        </div>
      </div>
      {showReset && <ResetConfirmationDialog {...dialogProps} />}
    </AppBar>
  );
};

export default LessonProgress;
