import { StyledTableCell, StyledTableRow } from "../../../Common/Table.jsx";

import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";

const string1 = `// LookupSet(1) {"Primer"}`;

const string2 = `// LookupSet(3) {'Primer', 15, 'JP'}`;

const string3 = `// LookupSet(2) {'Primer', 15}`;

const string4 = `// LookupMap(1){'age' => 25}`;

const BlockTimeTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "900px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell
              sx={{
                color: "floralwhite",
                textAlign: "center",
                minWidth: "100px",
              }}
              variant="body"
            >
              Method
            </StyledTableCell>
            <StyledTableCell
              sx={{
                color: "floralwhite",
                textAlign: "center",
                minWidth: "100px",
              }}
              variant="body"
            >
              Usage
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Initialize a <code>LookupSet</code> collection
              <br />
              <code>new LookupSet('unique_prefix')</code>
            </StyledTableCell>

            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>this.l = new LookupSet('lookupset1')</code>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Insert <code>value</code> to the <code>lookupSet</code>{" "}
              collection.
              <br />
              <code>
                this.l.set(<span style={{ color: "darkgray" }}>value</span>)
              </code>
            </StyledTableCell>

            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>this.l.set('Primer')</code>
              <br />
              <pre>
                <code style={{ color: "darkgray" }}>{string1}</code>
              </pre>
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell
              variant="body"
              style={{ textAlign: "center", maxWidth: "120px" }}
            >
              Batch insert or extend the current collection with the passed in
              array of elements.
              <br />
              <code>
                this.l.extend(
                <span style={{ color: "darkgray" }}>[item1, item2, ...]</span>)
              </code>
            </StyledTableCell>

            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>this.l.extend([15, 'JP'])</code>
              <br />
              <code style={{ color: "darkgray" }}>{string2}</code>
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell
              variant="body"
              style={{ textAlign: "center", maxWidth: "120px" }}
            >
              Removes the <code>value</code> from the <code>lookupSet</code>{" "}
              collections object
              <br />
              <code>
                this.l.remove(<span style={{ color: "darkgray" }}>'value'</span>
                )
              </code>
            </StyledTableCell>

            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>this.l.remove('JP')</code>
              <br />
              <code style={{ color: "darkgray" }}>{string3}</code>
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell
              variant="body"
              style={{ textAlign: "center", maxWidth: "120px" }}
            >
              Checks whether the collection contains the <code>value</code>.
              <br />
              <code>
                this.l.contains(
                <span style={{ color: "darkgray" }}>'value'</span>)
              </code>
            </StyledTableCell>

            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>this.l.contains(15)</code> outputs <code>true</code>
              <br />
              <code style={{ color: "darkgray" }}>{string3}</code>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlockTimeTable;
