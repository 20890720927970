import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["Block 5", "Block 3", "Block 4", "All blocks will change"];
  const correctOption = "All blocks will change";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      header={
        <React.Fragment>
          <div style={{ fontSize: "1.5rem" }}>
            Let's say we have a blockchain consisting of five{" "}
            <b>valid blocks</b>.
          </div>
          <div style={{ textAlign: "center" }}>
            <img
              style={{ width: "100%", maxWidth: "1000px" }}
              src="/images/topics/blockchain/fiveBlocks.png"
            />
          </div>
          {props.name}, which of the following blocks will not change due to{" "}
          <b>Block 1</b> becoming invalid?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
