import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["Public Key", "Private Key", "Mnemonic Passphrase"];
  const correctOption = "Public Key";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which of the following part of the access key is stored
          on the <b>NEAR Protocol blockchain</b>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
