import React from "react";
import Terminal from "./Common/Terminal";
import StorageStaking from "./Common/Canvas/StorageCanvas/Canvas";
import StoragePriceCalculator from "./Common/OneOff/StoragePriceCalculator";
import SeedPhrase from "./Blockchain/SeedPhrase";
import GenerateKeypairs from "./Common/OneOff/AccessExercises/GenerateKeypairs/GenerateKeypairs";
import AddingKeyPair from "./Common/OneOff/AccessExercises/AddingKeys/AddingKeys";
import DeletingKeys from "./Common/OneOff/AccessExercises/DeletingKeys/DeletingKeys";
import AccessKeysRotation from "./Common/OneOff/AccessExercises/AccessKeyRotation/AccessKeyRotation";
import ImplicitExplorable from "./Common/OneOff/ImplicitExplorable";
import ComparingHash from "./Blockchain/ComparingHash";
import BlockDifficulty from "./Blockchain/BlockDifficulty";
import BlockWithSound from "./Blockchain/BlockWithSound";
import Blockchain from "./Blockchain/DistributedBlockchain";

import DigitalSignature from "./Common/OneOff/EncryptMessage/DigitalSignature";
import EncryptMessage from "./Common/OneOff/EncryptMessage/EncryptMessage";
import Caeser from "./Blockchain/Caeser";
import SignedTxBlockchain from "./Blockchain/SignedTxBlockchain";
import GasFeesExplorable from "./Common/OneOff/GasFeesExplorable";

import Counter from "./Common/OneOff/DappSandbox/CounterContainer";
import BookRating from "./Common/OneOff/DappSandbox/BookRatingContainer";
import DonationsApp from "./Common/OneOff/DappSandbox/DonationsContainer";

export const guideData = {
  smartContract: "Smart Contract",
  decorator: "Decorators",
  class: "Javascript Classes",
  counterContract: "Counter Smart Contract",
  storagePrimer: "Storage",
  serialization: "Serialization",
  "environment-table": "Environment",
  "execution-environment": "Execution Environment",
  "sdk-collections": "SDK Collection",
  "vector-primer": "Vector Collection",
  "lookupmap-primer": "Lookup Map Collection",
  "lookupset-primer": "Lookup Set Collection",
  "unorderedmap-primer": "Unordered Map Collection",
  "unorderedset-primer": "Unordered Set Collection",
  "transactions-primer": "Transactions",
  "wallet-primer": "Wallets",
};

export const guideDataPopover = {
  smartContract: "Smart Contract Execution Guide",
  decorator: "Decorator in Javascript Guide",
  class: "Classes in Javascript Guide",
  counterContract: "Counter Smart Contract Walkthrough",
  storagePrimer: "A Guide to Storage Staking",
  serialization: "A Guide to Serialization and Deserialization in Javascript",
  "environment-table": "Environment Methods available in the JS-SDK",
  "execution-environment": "A Guide on using Execution Environment Methods",
  "sdk-collections": "A Guide to SDK Collections in near-sdk-js",
  "vector-primer":
    "A Guide on using Vector Collections Object from near-sdk-js",
  "lookupmap-primer":
    "A Guide on using LookupMap Collections Object from near-sdk-js",
  "lookupset-primer":
    "A Guide on using LookupSet Collections Object from near-sdk-js",
  "unorderedmap-primer":
    "A Guide on using UnorderedMap Collections Object from near-sdk-js",
  "unorderedset-primer":
    "A Guide on using UnorderedSet Collections Object from near-sdk-js",
  "transactions-primer": "A Guide on Transactions and Actions",
  "wallet-primer": "Types of Wallet",
};

export const dappData = [
  {
    id: 1,
    slug: "counter",
    card_title: "Counter",
    title: "Counter Decentralised Application",
    component: Counter,
  },
  {
    id: 2,
    slug: "books-rating",
    card_title: "Books Rating",
    title: "Books Rating Decentralised Application",
    component: BookRating,
  },
  {
    id: 3,
    slug: "donations",
    card_title: "Donations",
    title: "Donations Decentralised Application",
    component: DonationsApp,
  },
];

export const explorableData = [
  {
    id: 1,
    slug: "near-cli",
    title: "Command Line Interface",
    component: Terminal,
    description: (
      <React.Fragment>
        Get familiar with the <code>NEAR-CLI</code> using our web-based
        interactive CLI
      </React.Fragment>
    ),
  },
  {
    id: 2,
    slug: "storage-staking",
    title: "Storage Staking",
    component: StorageStaking,
    description: (
      <React.Fragment>
        An interactive explorable to understand how <u>storage staking</u> works
        on NEAR Protocol
      </React.Fragment>
    ),
  },
  {
    id: 3,
    slug: "storage-price",
    title: "Storage Price Estimator",
    component: StoragePriceCalculator,
    description: (
      <React.Fragment>
        Estimate the price of storing data on NEAR Protocol blockchain
      </React.Fragment>
    ),
  },
  {
    id: 4,
    slug: "seedphrase",
    title: "Mnenomic Keypair",
    component: SeedPhrase,
    description: (
      <React.Fragment>
        Create a <u>Mnemoic Seedphrase</u> or Recover Access key from a
        Seedphrase
      </React.Fragment>
    ),
  },
  {
    id: 5,
    slug: "access-key-generator",
    title: "Access Key Generator",
    component: GenerateKeypairs,
    description: (
      <React.Fragment>
        Learn how can to generate <u>access keys</u> on NEAR Protocol
      </React.Fragment>
    ),
  },
  {
    id: 6,
    slug: "adding-keypair",
    title: "Adding Keypair",
    component: AddingKeyPair,
    description: (
      <React.Fragment>
        Learn how can to add an <u>access keys</u> to an account on NEAR
        Protocol
      </React.Fragment>
    ),
  },
  {
    id: 7,
    slug: "deleting-keypair",
    title: "Deleting Keypair",
    component: DeletingKeys,
    description: (
      <React.Fragment>
        Learn how can to delete an <u>access keys</u> from an account on NEAR
        Protocol
      </React.Fragment>
    ),
  },
  {
    id: 8,
    slug: "access-key-rotation",
    title: "Access Key Rotation",
    component: AccessKeysRotation,
    description: (
      <React.Fragment>
        Learn how can to replace an <u>access key</u> from an account on NEAR
        Protocol
      </React.Fragment>
    ),
  },
  {
    id: 9,
    slug: "implicit-account",
    title: "Implicit Account",
    component: ImplicitExplorable,
    description: (
      <React.Fragment>
        Derive <u>Public key</u> from an <u>Implicit Account ID</u>
      </React.Fragment>
    ),
  },
  {
    id: 10,
    slug: "hash",
    title: "SHA 256",
    component: ComparingHash,
    description: (
      <React.Fragment>
        Check out how <u>SHA-256</u> generates hash values
      </React.Fragment>
    ),
  },
  {
    id: 11,
    slug: "block",
    title: "Block Mining",
    component: BlockWithSound,
    description: (
      <React.Fragment>
        Explorable demonstrating how we <u>mine a single block</u>
      </React.Fragment>
    ),
  },
  {
    id: 12,
    slug: "difficulty",
    title: "Mining Difficulty",
    component: BlockDifficulty,
    description: (
      <React.Fragment>
        Explorable demonstrating how we can control the <u>difficulty</u> to
        affect the <u>mining time</u> of a block
      </React.Fragment>
    ),
  },
  {
    id: 13,
    slug: "simple-blockchain",
    title: "Simple Blockchain",
    component: Blockchain,
    description: (
      <React.Fragment>
        Explorable demonstrating how <u>a simple blockchain</u> functions
      </React.Fragment>
    ),
  },
  {
    id: 14,
    slug: "bitcoin-blockchain",
    title: "Bitcoin Blockchain",
    component: SignedTxBlockchain,
    description: (
      <React.Fragment>
        Explorable demonstrating how <u>signed transaction</u> are stored on a
        blockchain
      </React.Fragment>
    ),
  },
  {
    id: 15,
    slug: "caeser-shit",
    title: "Caeser Shift",
    component: Caeser,
    description: (
      <React.Fragment>
        Learn how <u>caeserian shift</u> works in cryptography
      </React.Fragment>
    ),
  },
  {
    id: 16,
    slug: "public-key-cryptography",
    title: "Encryption",
    component: EncryptMessage,
    description: (
      <React.Fragment>
        Get familiar with the <code>Public-key Cryptography</code> using our
        explorable
      </React.Fragment>
    ),
  },
  {
    id: 17,
    slug: "digital-signature",
    title: "Digital Signature",
    component: DigitalSignature,
    description: (
      <React.Fragment>
        Get familiar with the <code>Digital Signatures</code> using our
        explorable
      </React.Fragment>
    ),
  },
  {
    id: 18,
    slug: "smart-contract-rewards",
    title: "Smart Contract Rewards",
    component: GasFeesExplorable,
    description: (
      <React.Fragment>
        Learn how much a <u>smart contract developer</u> can earn using NEAR
        Rewards Program
      </React.Fragment>
    ),
  },
];
