import React from "react";
import Wrapper from "./NodeWrapper";
import { Box } from "@mui/material";
import Handle from "./Handle";
import { PrettoSlider } from "./Slider";
import { checkNear } from "./Canvas";
import toast from "react-hot-toast";

export default function SliderNode({ data }) {
  const {
    label = "",
    min = 0,
    max = 1,
    setStorageUsed = () => {},
    value = 0.5,
    storageUsed,
    calculatorData,
  } = data;

  const handleStorageUsed = (value) => {
    const {
      contractDeployed,
      contractSize,
      funcKeys,
      fullKeys,
      near,
      storage_amount_per_byte,
    } = calculatorData;

    let checkNearAmt = checkNear(
      contractDeployed,
      contractSize,
      funcKeys,
      fullKeys,
      value,
      near,
      storage_amount_per_byte
    );
    if (checkNearAmt.nearAvailable > 0) {
      setStorageUsed(value);
    } else {
      toast.error(
        `Not enough NEAR Tokens Ⓝ to cover for ${checkNearAmt.totalStorage} B of data`,
        {
          id: "storage",
        }
      );
    }
  };

  return (
    <Wrapper label={label}>
      <Handle type="source" position="top" />
      <Box style={{ width: "120px" }}>
        <div style={{ textAlign: "center" }}>
          <span
            style={{ fontWeight: "bold", fontSize: "12px", margin: "4px auto" }}
          >
            Storage used by Smart Contract: {storageUsed} KB
          </span>
          <PrettoSlider
            size="small"
            value={storageUsed}
            valueLabelDisplay="auto"
            onChange={(evt) => {
              handleStorageUsed(evt.target.value);
            }}
            step={1}
            min={min}
            max={max}
          />
          <div style={{ textAlign: "center" }}>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                margin: "4px auto",
                color: "#47307f",
              }}
            >
              {storageUsed} KB
            </span>
          </div>
        </div>
      </Box>
    </Wrapper>
  );
}
