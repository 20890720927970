import { OutputFactory, Outputs } from "javascript-terminal";
import { CustomOutputFactory } from "../outputs";
import { arraysEqual, getTxId } from "./utils";

const helpText = `
**Usage**: &&near&& [[delete-key]] @@<accountId>@@ @@<accessKey>@@

    ^^delete access key^^
    
    For e.g., if you would like to delete an access key of an account &&nearprotocolprimer.testnet&&. You can do so by using following command: 
        
        &&near&& [[delete-key]] @@nearprotocolprimer.testnet@@ @@<accessKey>@@


**Notes**:
    1. You must have the appropriate access key to the account to delete its access-key
     
`;

const generateErrors = (errors) => {
  let mappedText = errors.reduce((acc, curr) => {
    return acc + `\n\t&&` + " * " + curr + `&&`;
  }, ``);
  return `**Encountered Following Errors**
${mappedText}

**Usage**: &&near&& [[delete-key]] @@<accountId>@@ @@<accessKey>@@

    For more info type:
        &&near&& [[delete-key] @@--help@@ 

`;
};

const incorrectCommandUsage = `Incorrect Command Usage`;
const missingAccountID = `Missing Account ID`;
const missingPublicKey = `Missing Access Key`;

const updateState = (type, message, setCustomState, setAcceptInput) => {
  if (type === "error") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(`&&${message}&&`)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  } else if (type === "success") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(message)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  }

  setAcceptInput(true);
};

const getSuccessMessage = (res) => {
  const hash = getTxId(res);
  const message = `
        Transaction Id ${hash}
        To see the transaction in the transaction explorer, please open this url in your browser (((https://explorer.testnet.near.org/transactions/${hash})))`;

  return message;
};

export const deleteKeyCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(helpText),
    };
  } else {
    if (args.length === 1) {
      const errors = [missingAccountID, missingPublicKey];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if (args.length === 2) {
      const errors = [missingPublicKey];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if (args.length === 3) {
      const [_, accountId, accessKey] = args;
      const { setAcceptInput, setCustomState } = stateChangeProps;

      setAcceptInput(false);

      context
        .deleteAccessKey(accountId, accessKey)
        .then((res) => {
          let type = res.type ? res.type : "success";
          const message = getTxId(res)
            ? getSuccessMessage(res, accountId)
            : JSON.stringify(res, null, 4);

          updateState(type, message, setCustomState, setAcceptInput);
        })
        .catch((e) => {
          let type = "error";
          let message = e.message;
          updateState(type, message, setCustomState, setAcceptInput);
        });

      return {
        output: CustomOutputFactory.createColoredRecord(
          `Deleting key [[${accessKey}]] on [[${accountId}]]...`
        ),
      };
    } else {
      const errors = [incorrectCommandUsage];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    }
  }
};
