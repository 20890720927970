export const accountStatePrimer = [
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "AccountStateInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "TransactionMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "The validators process the transaction that are sent to the network and apply the updates to an account state.",
        "To process the transactions, it is required that the all the validators collectively have the state of the entire list of accounts.",
        'Let\'s refer to the collective state of the entire list of accounts on a NEAR Protocol Network as the "world state"',
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "WorldStateInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "AccountReorderQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Now we have a fair bit of idea of how the state of the account changes using transititons.",
        'Let\'s proceed to think about accounts run execute the apps also known as "smart contracts" on the community-cloud. ',
      ],
      primerAvatar: "patient",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "SmartContractsInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ValidatorQuiz",
    },
  },

  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "{{user}}, as you can see validators play quite an important in the NEAR Protocol Platform.",
        'How do you think "NEAR Protocol" ensures that these validators perform their functions in good faith?',
      ],
      primerAvatar: "search",

      textReply: { que: "What keeps validators in check?" },
      userReply: { reply: null, userAvatar: "Cheeks" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Since, NEAR Protocol is Proof-of-Stake based blockchain, all the validator need to stake a certain amount of NEAR tokens to participate in the maintaining the platform",
        'One of the primary incentive for validators to participate in the maintaining the NEAR Protocol is the "Staking Reward"',
        "Let's understand the staking mechanism to understand this better.",
      ],
      primerAvatar: "search",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "StakingInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "StakingQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "{{user}}, when an user calls a method on a deployed smart contract, a validator has to execute the deploy smart code.",
        "But if someone deploys a code that runs indefinitely, then it can potentially hijack and block a lot of computational resources",
        "How do you think NEAR Protocol deals with code that runs indefinitely such as infinite loop?.",
      ],
      primerAvatar: "question",

      textReply: {
        que: "How does NEAR Protocol guard against code running indefinitely?",
      },
      userReply: { reply: null, userAvatar: "Smirk" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "When an user calls a function that requires some computation, they have to attach certain amount of the gas fees.",
        "A validator executes the method according to the gas fees provided by the user.",
        "Once the gas runs out, the validator stops executing the smart contract.",
      ],
      primerAvatar: "showcase",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "GasInfo",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "It's still not clear why should someone use the smart contracts on NEAR Protocol to create web applications.",
        "In the next lesson, we will learn more about the advantages of the creating applications on NEAR Protocol.",
        "See you in the next lesson, {{user}}.",
      ],
      primerAvatar: "wave",
    },
  },
];

export default accountStatePrimer;
