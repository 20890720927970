import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "States when the access key was created",
    "States how many times the access key was used",
    "Both of the above",
    "None of the above",
  ];
  const correctOption = "Both of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, what do you think is the use of the field{" "}
          <code>nonce</code> on the
          <code> access_key</code>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
