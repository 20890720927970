import React from "react";
import { Paper } from "@mui/material";

export const blockStyle = {
  color: "#47307f",
  fontWeight: "bolder",
  textDecoration: "2px solid underline",
};

export const Thought1 = ({ block = false }) => (
  <React.Fragment>
    <Paper
      style={{
        fontWeight: "bolder",
        background: "lightgreen",
        padding: "20px",
        maxWidth: "400px",
        lineHeight: "1.5rem",
        fontSize: "small",
      }}
    >
      Our goal in creating a blockchain is to ensure that the data inside each
      block that are added to the chain{" "}
      <span style={{ color: "#47307f", textDecoration: "underline" }}>
        cannot be easily tampered with
      </span>{" "}
      and in case they are somehow tampered, it should{" "}
      <span style={{ color: "#47307f", textDecoration: "underline" }}>
        clearly noticable
      </span>
      .
    </Paper>
  </React.Fragment>
);

export const GoalThought = ({}) => (
  <React.Fragment>
    <Paper
      style={{
        background: "indigo",
        padding: "20px",
        color: "floralwhite",
        maxWidth: "600px",
        fontSize: "small",
        lineHeight: "1.5rem",
      }}
    >
      <h2 style={{ textAlign: "center" }}>
        De-Centralised Trustless Time-Stamping Authority
      </h2>
      <ul>
        <li>
          The <b>universal registry blockchain</b> should be publicly available
          for just about anyone to record their entries into the{" "}
          <b>blockchain</b>
        </li>
        <li>
          <b>"De-Centralised Time-Stamping Authority"</b> should not be allowed
          to gatekeep <b>who can or who cannot</b> record their entries to the
          <b> universal registry</b>
        </li>
        <li>
          It should be <b>resistant to any government's laws and regulations</b>
        </li>
        <li>
          And no government should be able to <b>shut down it's operations</b>
        </li>
      </ul>
    </Paper>
  </React.Fragment>
);

export const ChallengesThought = ({}) => (
  <React.Fragment>
    <Paper
      style={{
        background: "lightgreen",
        padding: "20px",
        fontWeight: "bold",
        maxWidth: "600px",
        fontSize: "small",
        lineHeight: "1.5rem",
      }}
    >
      <h2 style={{ textAlign: "center" }}>
        De-Centralised Distributed Blockchain Challenges
      </h2>
      <ul>
        <li>
          How do the peers decide{" "}
          <span style={blockStyle}>what data to add</span> to the blocks?
        </li>
        <li>
          Suppose, there is a conflict in the copies stored chain amongst the
          peers, how do they decide which chain is the{" "}
          <span style={blockStyle}>valid one</span>?
        </li>

        <li>
          As our requirement is basically anyone could add data into the chain,
          what is the <span style={blockStyle}>incentive</span> for the{" "}
          <span style={blockStyle}>peers</span> to store the data on their
          behalf?
        </li>

        <li>
          And the most important challenge is that what if the{" "}
          <span style={blockStyle}>peers themselves cannot be trusted</span> and
          they{" "}
          <span style={blockStyle}>conspire together to tamper the data</span>.
          What then?
        </li>
      </ul>
    </Paper>
  </React.Fragment>
);
