import React from "react";
import Typography from "@mui/material/Typography";
import Reorder from "./components/Reorder";
import Dialog from "./components/ReorderDialog";
import DialogPrimer from "./components/ReorderDialogPrimer";
import { CenteredLayout } from "./layouts/Layout";
import { shuffleArray } from "../../utils";
import { motion } from "framer-motion";
import useSound from "use-sound";

const itemsList = [
  "First the user broadcasts the transactions",
  "Then miner picks up transaction and puts it in the block",
  "Then there is global competitive race to find the nonce for which the hash is correct",
  "Whoever has the nonce, gets the reward from the coinbase transaction",
  "Each block within couple of minutes averaging out in 10 minutes",
  "The difficulty is adjusted to see if the block finding mechanism is too easy or hard",
  "Then finally the block is added to the transaction",
];

const ReorderQuizExample = (props) => (
  <ReorderQuiz
    itemsList={itemsList}
    header={<React.Fragment>Can you reorder the following?</React.Fragment>}
    {...props}
  />
);

const getItems = (arr) =>
  arr.map((item, idx) => ({ id: `item-${idx}`, content: item }));

export const ReorderQuiz = ({
  itemsList,
  progress,
  progressIndex,
  setProgress,
  setProgressIndex,
  setProgressState,
  send,
  currentlyActive,
  primers,
  ...props
}) => {
  const [showDialog, setShowDialog] = React.useState(false || props.showDialog);

  const [completed, setCompleted] = React.useState(
    currentlyActive || showDialog ? false : true
  );
  const [items, setItems] = React.useState(
    currentlyActive || showDialog
      ? shuffleArray(getItems(itemsList))
      : getItems(itemsList)
  );
  const [checking, setChecking] = React.useState(
    currentlyActive || showDialog ? false : true
  );
  const [skip, setSkip] = React.useState(false);

  const [playLevelCompleted] = useSound("/sounds/level_completed2.wav", {
    volume: 0.1,
  });
  const [playWrong] = useSound("/sounds/wrong.mp3", { volume: 0.1 });

  const checkItems = (arr) =>
    arr.every((element, id) => `item-${id}` === element.id);

  React.useEffect(() => {
    if (!skip) {
      if (checkItems(items) && checking) {
        setCompleted(true);
        playLevelCompleted();
        setProgressState("success");
      } else {
        if (completed & currentlyActive) {
          setCompleted(false);
          setProgressState("success");
        }
      }
    }
  }, [items, checking]);

  const ReorderProps = {
    items,
    setItems,
    checking,
    setChecking,
    completed,
    skip,
    checkItems,
    setCompleted,
    playLevelCompleted,
  };

  const DialogProps = {
    progress,
    progressIndex,
    setProgress,
    setProgressIndex,
    completed,
    checking,
    setChecking,
    skip,
    setSkip,
    playWrong,
    setProgressState,
    primers,
  };

  const DialogPrimerProps = {
    progress,
    progressIndex,
    setProgress,
    setProgressIndex,
    completed,
    checking,
    setChecking,
    skip,
    setSkip,
    playWrong,
    setProgressState,
    send,
  };

  return (
    <React.Fragment>
      <CenteredLayout>
        <div style={{ padding: "20px" }}>
          <Typography
            variant="h4"
            component="div"
            style={{ textAlign: "center" }}
          >
            {props.header}
          </Typography>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Reorder {...ReorderProps} />
          </div>
        </div>
        <div>{currentlyActive && <DialogPrimer {...DialogPrimerProps} />}</div>
      </CenteredLayout>
      {showDialog && (
        <motion.div
          animate={{ opacity: [0, 0, 0, 1] }}
          transition={{ duration: 1 }}
        >
          <Dialog {...DialogProps} />
        </motion.div>
      )}
    </React.Fragment>
  );
};

export default ReorderQuizExample;
