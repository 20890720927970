import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Lottie from "lottie-react";

const initialData = {
  1: null,
  2: null,
  3: null,
  4: null,
};

const asset_url = Object.keys(initialData).map(
  (ele) =>
    `https://primer-assets-s3-limited.s3.amazonaws.com/banner_cards/LoginStep${ele}.json`
);

export const AnimationLottie = ({ index = 1 }) => {
  const lottieRef = React.useRef();

  const [steps, setSteps] = React.useState(initialData);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const steps = await Promise.all(
        asset_url.map((url) => fetch(url).then((res) => res.json()))
      ).catch((err) => {
        setError(true);
      });
      setSteps(steps);
    };
    fetchData();
  }, []);

  if (!error && steps[index - 1] === null) {
    return <div>Loading</div>;
  } else if (error) {
    return <div>Error fetching animation</div>;
  } else {
    return (
      <div>
        <div
          onMouseEnter={() => lottieRef.current.pause()}
          onMouseLeave={() => lottieRef.current.play()}
          style={{
            width: "100%",
            display: "block",
          }}
        >
          <Lottie
            style={{
              width: "100%",
              margin: "auto",
              borderRadius: "100px",
            }}
            lottieRef={lottieRef}
            loop={true}
            animationData={steps[index - 1]}
          />
        </div>
      </div>
    );
  }
};

const getStepDetails = (index) =>
  ({
    1: (
      <p>
        When an user logins on a <b>WEB3 Application</b>, the application
        generates a new keypair
      </p>
    ),
    2: (
      <p>
        The <b>WEB3 Application</b> creates a <b>transaction</b> for adding the
        generated
        <code>access_key</code> and redirects the user to the <b>wallet</b> to
        sign the transaction
      </p>
    ),
    3: (
      <p>
        <b>NEAR Wallet</b> sends the <b>signed transaction</b> to a{" "}
        <b>RPC Node</b> which relays it to <b>validators</b>. After sometime,
        the <b>signed transaction</b> gets added to the blockchain and the{" "}
        <b>NEAR Wallet</b> gets confirmation.
      </p>
    ),
    4: (
      <p>
        Once, the <b>WEB3 application</b> gets confirmation from the{" "}
        <b>wallet</b>, the
        <code>access_key</code> is stored in the browser's localstorage and the
        user is <b>logged in</b>
      </p>
    ),
  }[index]);

const steps = [
  "Login Request",
  "Transaction",
  "Blockchain",
  "User is Logged In",
];

export default function HorizontalNonLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        <React.Fragment>
          <AnimationLottie index={activeStep + 1} />
          <div style={{ textAlign: "center", color: "#47307f" }}>
            {getStepDetails(activeStep + 1)}
          </div>

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              variant="outlined"
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button variant="outlined" onClick={handleNext} sx={{ mr: 1 }}>
              Next
            </Button>
          </Box>
        </React.Fragment>
      </div>
    </Box>
  );
}

// {activeStep !== steps.length &&
//     (completed[activeStep] ? (
//       <Typography
//         variant="caption"
//         sx={{ display: "inline-block" }}
//       >
//         Step {activeStep + 1} already completed
//       </Typography>
//     ) : (
//       <Button variant="outlined" onClick={handleComplete}>
//         {completedSteps() === totalSteps() - 1
//           ? "Finish"
//           : "Complete Step"}
//       </Button>
//     ))}
