import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Will no longer be used as it will lose trust and credibility",
    "It is impossible for the Trustless TSA to change the data",
    "Nothing will happen",
  ];
  const correctOption =
    "Will no longer be used as it will lose trust and credibility";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, what will happen if the{" "}
          <b>Trustless Time Stamping Authority</b> does alter the data contained
          within it's <b>universal registry chain</b> whose values are
          published?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
