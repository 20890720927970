import React from "react";
import { Paper, Box, Button } from "@mui/material";
import { sha256 } from "js-sha256";
import { CssTextField, HashTextField, StyledTooltip } from "./styledComponents";
import LoadingButton from "@mui/lab/LoadingButton";
import { useWorker, WORKER_STATUS } from "@koale/useworker";
import { chainTSAMineNonce } from "./workers";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { ChainContext } from "./PSABlockchain";

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      sx={{ color: "black", fontWeight: "bold" }}
      {...props}
    />
  );
});

const Block = React.memo(({ prev, id }) => {
  //   const [data, setData] = React.useState("Hi there!");
  //   const [block, setBlock] = React.useState(1);
  //   const [nonce, setNonce] = React.useState(452);

  const { chain, setHash, hash, setChain } = React.useContext(ChainContext);
  const blockNumber = chain.find((x) => x.id === id).blockNumber;
  const nonceValue = chain.find((x) => x.id === id).nonceValue;
  const dataValue = chain.find((X) => X.id === id).dataValue;
  const timeStampValue = chain.find((x) => x.id === id).timeStamp;

  const [error, setError] = React.useState(false);
  const [valid, setValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  let blockhash = `block${blockNumber}nonce${nonceValue}timestamp${timeStampValue}data${dataValue}prev${
    prev ? hash[prev] : "0".repeat(64)
  }hash`;
  //   let blockhash = `block${blockNumber}nonce${nonceValue}data${dataValue}hash`;
  const [mineWorker] = useWorker(chainTSAMineNonce, {
    remoteDependencies: [
      "https://cdnjs.cloudflare.com/ajax/libs/js-sha256/0.9.0/sha256.min.js",
    ],
  });

  const checkValidity = (difficulty = 4) => {
    let pattern = "0".repeat(difficulty);
    return sha256(blockhash).substring(0, difficulty) === pattern;
  };

  const setBlock = (val) => {
    setChain((chain) =>
      chain.map((x) => (x.id === id ? { ...x, blockNumber: parseInt(val) } : x))
    );
  };

  const setData = (val) => {
    setChain((chain) =>
      chain.map((x) => (x.id === id ? { ...x, dataValue: val } : x))
    );
  };

  const setTime = (val) => {
    setChain((chain) =>
      chain.map((x) => (x.id === id ? { ...x, timeStamp: val } : x))
    );
  };

  const setNonce = (val) => {
    setChain((chain) =>
      chain.map((x) => (x.id === id ? { ...x, nonceValue: val } : x))
    );
  };

  React.useEffect(() => {
    if (checkValidity()) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [blockNumber, nonceValue, dataValue, hash, timeStampValue]);

  //   React.useEffect(() => {
  //     if (checkValidity()) {
  //       setValid(true);
  //     } else {
  //       setValid(false);
  //     }
  //   }, [block, nonce, data]);

  const runMining = async () => {
    setLoading(true);
    const prevHash = prev ? hash[prev] : "0".repeat(64);
    const result = await mineWorker(
      blockNumber,
      dataValue,
      timeStampValue,
      prevHash,
      4
    );
    if (result !== -1) {
      setNonce(result);
      setLoading(false);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <div style={{ maxWidth: "500px", margin: "30px auto" }}>
      <Paper
        elevation={3}
        sx={{
          padding: "30px",
          display: "flex",
          flexDirection: "column",
          background: valid ? "lightgreen" : "#ff7f5061",
          margin: "10px",
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        > */}
        <HashTextField
          type="number"
          label="Block"
          value={blockNumber}
          onChange={(evt) => setBlock(evt.target.value)}
        />

        {/* <HashTextField
            label="Nonce"
            value={nonceValue}
            onChange={(evt) => setNonce(evt.target.value)}
          /> */}
        {/* </Box> */}
        <br />
        <HashTextField
          label="Time Stamp"
          value={timeStampValue}
          onChange={(evt) => setTime(evt.target.value)}
        />
        <br />
        <HashTextField
          label="Document Hash"
          value={dataValue}
          onChange={(evt) => setData(evt.target.value)}
        />
        <br />

        <HashTextField
          label="Previous Hash Value"
          value={prev ? hash[prev] : "0".repeat(64)}
        />
        <br />

        <HashTextField label="Hash Value" value={hash[id]} />
        {/* <div style={{ padding: "10px", textAlign: "center" }}>
          <LoadingButton
            onClick={runMining}
            sx={{ height: "3rem" }}
            variant="outlined"
            loading={loading}
            loadingIndicator="Mining..."
          >
            Mine
          </LoadingButton>
        </div> */}
      </Paper>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          sx={{
            width: "100%",
            color: "black",
            fontWeight: "bold",
            fontFamily: "Nunito",
          }}
        >
          Unable to find the nonce for a valid block. Try changing some data.
        </Alert>
      </Snackbar>
    </div>
  );
});

export default Block;
