import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "blockTimestamp",
    "blockIndex",
    "storageUsage",
    "attachedDeposit",
  ];
  const correctOption = "blockIndex";
  return (
    <MCQQuiz
      code={true}
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, which of the following <b>environment attribute</b> we
            can use to find the <b>block height</b> at which the{" "}
            <b>current transaction</b> will added in the{" "}
            <b>NEAR Protocol Blockchain</b>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
