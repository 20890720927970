import { Prompt, Env } from "./common";

const generateMessage = ({
  testnet,
  useAccount,
  accountId,
  useSeedphrase,
  seedphrase,
  message,
}) => {
  return (
    <div>
      <Prompt />
      {!testnet && <Env />}
      near generate-key{" "}
      {useAccount ? (
        accountId.trim().length === 0 ? (
          <span style={{ color: "gold" }}>&lt;accountId&gt; </span>
        ) : (
          <span style={{ color: "gold", fontWeight: "bold" }}>{accountId}</span>
        )
      ) : (
        ""
      )}{" "}
      {useSeedphrase ? (
        <span style={{ color: "salmon" }}> --seedPhrase </span>
      ) : (
        ""
      )}
      {useSeedphrase ? (
        seedphrase.trim().length === 0 ? (
          <span style={{ color: "gold" }}> &lt;seedphrase&gt; </span>
        ) : (
          <span style={{ color: "gold", fontWeight: "bold" }}>
            "{seedphrase}"
          </span>
        )
      ) : (
        ""
      )}
      <br />
      {message !== null ? (
        message.type !== "overwrite" ? (
          <span>
            {" "}
            Key pair with{" "}
            <span style={{ color: "gold", fontWeight: "bold" }}>
              {message.publicKey}
            </span>{" "}
            public key for an account "
            <span style={{ color: "gold", fontWeight: "bold" }}>
              {message.account}
            </span>
            "
          </span>
        ) : (
          <span>
            {" "}
            Account has existing key pair with{" "}
            <span style={{ color: "gold", fontWeight: "bold" }}>
              {message.publicKey}
            </span>{" "}
            public key
          </span>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default generateMessage;
