import React from "react";
import Slider from "@mui/material/Slider";
import { LEVEL_0, LEVEL_1, LEVEL_2, LEVEL_3, LEVEL_4 } from "./CharacterStory";
import CourseInformation from "./CourseInformation";
const powerScaling = {
  0: "Witty Wanderer",
  10: "Enigmatic Explorer",
  20: "Fearless Adventurer",
  30: "Mischievous Maverick",
  40: "Lurking Legend",
};

const marks = [
  {
    value: 0,
    label: (
      <span style={{ fontVariant: "all-small-caps", letterSpacing: "1px" }}>
        <b>Wanderer</b>
      </span>
    ),
  },
  {
    value: 10,
    label: (
      <span style={{ fontVariant: "all-small-caps", letterSpacing: "1px" }}>
        <b>Explorer</b>
      </span>
    ),
  },
  {
    value: 20,
    label: (
      <span style={{ fontVariant: "all-small-caps", letterSpacing: "1px" }}>
        <b>Adventurer</b>
      </span>
    ),
  },
  {
    value: 30,
    label: (
      <span style={{ fontVariant: "all-small-caps", letterSpacing: "1px" }}>
        <b>Maverick</b>
      </span>
    ),
  },
  {
    value: 40,
    label: (
      <span style={{ fontVariant: "all-small-caps", letterSpacing: "1px" }}>
        <b>Legend</b>
      </span>
    ),
  },
];

function valueLabelFormat(value) {
  return powerScaling[value];
}

function valuetext(value) {
  return `${powerScaling[value]}`;
}

const character_story = {
  0: <LEVEL_0 />,
  10: <LEVEL_1 />,
  20: <LEVEL_2 />,
  30: <LEVEL_3 />,
  40: <LEVEL_4 />,
};

const LearnerProfile = () => {
  const [prof, setProf] = React.useState(0);

  return (
    <div
      style={{
        padding: "20px",
      }}
    >
      <h2 style={{ textAlign: "center", fontFamily: "Nunito" }}>
        Set Your Character Level
      </h2>
      <div
        style={{
          display: "flex",
          background: "floralwhite",
          padding: "20px",
        }}
      >
        <div style={{ height: "100%", color: "indigo" }}>
          <h3 style={{ textAlign: "center" }}>
            Level {prof / 10} : {powerScaling[prof]}
          </h3>
          <h4>Who are you?</h4>
          {character_story[prof]}
          <div
            style={{
              padding: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Slider
              value={prof}
              valueLabelDisplay="auto"
              valueLabelFormat={valueLabelFormat}
              getAriaValueText={valuetext}
              step={10}
              marks={marks}
              min={0}
              max={40}
              onChange={(evt, val) => setProf(val)}
            />
          </div>
        </div>
      </div>
      <div>
        <CourseInformation level={prof} />
      </div>
    </div>
  );
};

export default LearnerProfile;
