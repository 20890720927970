export const start = [
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "{{user}}, in this quest, we will learn in detail about NEAR Protocol...",
        "However, before moving on learn about NEAR Protocol, let me ask you a quick question.",
        'What do you think is a "network" in the context of computers?',
      ],
      primerAvatar: "wave",

      textReply: { que: "What is a Network?" },
      userReply: { reply: null, userAvatar: "Dreamer" },
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "A network consists of two or more linked computers that are linked in order to share resources (such as printers), exchange files, or allow electronic communications.",
        "Now, many of the computers or devices are quite different from each other",
        "{{user}}, how do you think they are able to communicate with each other?",
      ],
      primerAvatar: "cable",
      textReply: {
        que: "How do different computers communicate in a network?",
      },
      userReply: { reply: null, userAvatar: "Happy" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "In any given network, if a device wants to communicate with other devices, it has to follow a set of pre-defined rules and standards",
        "The established rules and standards allow any devices to join a network and start communicating with others",
        'These set of standards are called "Network Protocol"',
      ],
      primerAvatar: "patient",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "ProtocolInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "SMTPMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "The Post Office Protocol (POP3) is what allows you to receive mails",
        "While the Simple Email Transport Protocol (SMTP) allows you to send mails",
        "Now, let' take a look at another protocol which is of interest to us",
      ],
      primerAvatar: "delivery-3",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "BittorrentInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ProtocolMatchQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "{{user}}, we have taken a look at a number of protocols and their usage so far",
        "Now, let's come back to our main topic: NEAR Protocol",
        "Can you guess what the NEAR Protocol Specification is used for?",
      ],
      primerAvatar: "puzzle",

      textReply: {
        que: "Guess what does the NEAR Protocol Specification is used for?",
      },
      userReply: { reply: null, userAvatar: "Smirk" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "The NEAR Protocol Specifications describe how a group of computers can come together,",
        '...create and securely maintain a "community-owned cloud computing infrastructure."',
        "Now, let's understand this in a bit detail",
      ],
      primerAvatar: "umbrella",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "CloudCommputingInfo",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        'Alright, {{user}}, NEAR Protocol is a "De-centralised Cloud-Computing Infrastructure"',
        "However, running a community-operated computing infrastructure must have many challenges.",
        "Can you think of any particular challenge while running a de-centralised computing platform?",
      ],
      primerAvatar: "question",
      textReply: {
        que: "Guess any challenge running a de-centralised computing platform?",
      },
      userReply: { reply: null, userAvatar: "Dreamer" },
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "ChallengesInfo",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'As you can see, {{user}}, there are several challenges associated with running a "Decentralised Cloud-Computing Platform"',
        "Before, we will address how NEAR Protocol solves these challenges, let's take a quick quiz.",
      ],
      primerAvatar: "puzzle",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "BlockchainQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, a blockchain can be used to create to build a trustless time-stamping service, an immutable database and a public ledger.",
        'NEAR Protocol solves a lot of challenges involved in building "de-centralised cloud-computing infrastructure" by building it on top of a publicly accessible blockchain...',
        "... The NEAR Protocol Blockchain",
      ],
      primerAvatar: "book",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "BlockchainInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ValidatorsQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, let's take a look at a high level overview of the NEAR Protocol",
        "In order to do that, we will have to first understand various elements of the NEAR Protocol Platform",
      ],
      primerAvatar: "normal",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "NearInfo",
    },
  },

  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "AccountStateQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "NEAR Tokens, Nodes and Accounts are the basic elements of the NEAR Protocol Platform",
        "In the next lesson,  we will start learning out how these three elements help us create a computing platform",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "patient",
    },
  },
];
