import React from "react";
import MatchQuiz from "../../../../../components/Quiz/MatchQuiz";

const MatchTemplate = (props) => {
  const matchOptions = {
    "Wallet-selector": "Allows users to sign in using their preferred wallet",
    "NEAR-API-JS":
      "Javascript Library to communicate with the NEAR Protocol Blockchain",
    Explorer: "UI-friendly Interface to see block details on NEAR Protocol",
    "Node-URL": "URL where RPC Node is running",
    NetworkID: "The NEAR Protocol network type",
  };
  return (
    <MatchQuiz
      large={true}
      header={
        <React.Fragment>
          {props.name}, can you match the following terms and their usage while
          building the <b>frontend</b> for a <b>smart contract</b> on{" "}
          <b>NEAR Protocol</b>?
        </React.Fragment>
      }
      matchOptions={matchOptions}
      {...props}
    />
  );
};

export default MatchTemplate;
