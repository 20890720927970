// Informations
import NodesInfo from "./PrimerComponents/NodesInfo.mdx";
import PublicInfo from "./PrimerComponents/PublicInfo.mdx";
import PublicKeyEncription from "./PrimerComponents/PublicKeyEncription.mdx";
import DigitalSignatures from "./PrimerComponents/DigitalSignatures.mdx";
import BitcoinTrx from "./PrimerComponents/BitcoinTrx.mdx";
import WalletInfo from "./PrimerComponents/WalletInfo.mdx";
import GlobalLedger from "./PrimerComponents/GlobalLedger.mdx";

// Quizzes
import BankMCQ from "./Quiz/BankMCQ";
import CaeserMCQ from "./Quiz/CaeserMCQ";
import KeyMCQ from "./Quiz/KeyMCQ";
import SignaturesMCQ from "./Quiz/SignaturesMCQ";
import BitcoinAccQuiz from "./Quiz/BitcoinAccQuiz";
import WalletQuiz from "./Quiz/WalletQuiz";

// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";

// Primers
import { start } from "./Primers/Start";

const primers = [...start];

const primerComponents = {
  NodesInfo: NodesInfo,
  PublicInfo: PublicInfo,
  BankMCQ: BankMCQ,
  CaeserMCQ: CaeserMCQ,
  PublicKeyEncription: PublicKeyEncription,
  DigitalSignatures: DigitalSignatures,
  KeyMCQ: KeyMCQ,
  SignaturesMCQ: SignaturesMCQ,
  BitcoinTrx: BitcoinTrx,
  BitcoinAccQuiz: BitcoinAccQuiz,
  WalletInfo: WalletInfo,
  GlobalLedger: GlobalLedger,
  NextLesson: NextLesson,
  WalletQuiz: WalletQuiz,
};

const imageComponents = [];

const quizList = [
  "BankMCQ",
  "CaeserMCQ",
  "KeyMCQ",
  "SignaturesMCQ",
  "BitcoinAccQuiz",
  "WalletQuiz",
];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
