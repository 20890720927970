import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import { Link, useNavigate } from "react-router-dom";
/*

Props:
    image-name
    name of the topic
    number of topics
    background-colour
*/

export default function MediaCard(props) {
  const [active, setActive] = React.useState(false);
  let navigate = useNavigate();

  return (
    <Card
      className={`HoverImage MediaCard-${props.index + 1}`}
      to={`/quest/${props.slug}`}
      component={Link}
      raised={active ? true : false}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      sx={{
        width: "300px",
        minHeight: "400px",
        borderRadius: "35px",
        margin: "10px",
        transition: "0.5s",
        filter: active ? "brightness(100%)" : "brightness(95%)",
        cursor: "pointer",
        textDecoration: "none",
      }}
      style={{
        overflow: "hidden",
        height: "300px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        style={{
          objectFit: "cover",
          objectPosition: "center",
          width: "100%",
          height: "100%",
        }}
        src={props.image}
      />
      <CardContent
        style={{
          position: "absolute",
          inset: "0",
          backgroundColor: active
            ? "rgb(75 0 130 / 30%)"
            : "rgb(75 0 130 / 50%)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            opacity: 1,
            fontWeight: "800",
            fontFamily: "Corben",
            textAlign: "center",
            margin: "auto",
            color: "white",
            transition: "1s",
            fontSize: "28px",
          }}
        >
          {props.title}
        </div>
        <div
          style={{
            fontVariant: "all-small-caps",
            fontWeight: "800",
            textAlign: "center",
            fontSize: "24px",
            color: "floralwhite",
            letterSpacing: "2px",
          }}
        >
          {props.topics} topics
        </div>
      </CardContent>
    </Card>
  );
}

{
  /* <CardMedia
component="img"
sx={{
  transform: active ? "scale(0.8)" : "scale(0.6)",
  transition: "1s",
}}
image={`/images/${props.image}.svg`}
alt={props.title}
/> */
}
