import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Eight Five zeroes in front of hash",
    "Six zeroes in front of hash",
    "Seven zeroes in front of hash",
    "Ten zeroes in front of hash",
  ];
  const correctOption = "Ten zeroes in front of hash";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which of the following pattern <b>will most probably</b>{" "}
          take longest time to find a valid <code>nonce</code>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
