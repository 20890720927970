import React from "react";
import MatchQuiz from "../../../../../components/Quiz/MatchQuiz";

const MatchTemplate = (props) => {
  const matchOptions = {
    createAccount: "Creates a sub-account of the contract account",
    transfer: "Transfer Ⓝ NEAR tokens",
    addFullAccessKey: "Adds full access key",
    deleteAccount: "Deletes Account",
    add_access_key:
      "Adds a key that can only only call specific methods on a contract",
  };
  return (
    <MatchQuiz
      large={true}
      header={
        <React.Fragment>
          {props.name}, can you match the following <code>action</code> and
          their <code>usage</code> a transaction on <b>NEAR Protocol</b>?
        </React.Fragment>
      }
      matchOptions={matchOptions}
      {...props}
    />
  );
};

export default MatchTemplate;
