import React, { useState } from "react";
import { sha256 } from "js-sha256";
import { CenteredLayout } from "../../Quiz/layouts/Layout";
import { Paper, Button } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const validAccounts = [
  "primer",
  "ok",
  "bowen",
  "ek-2",
  "ek.near",
  "com",
  "google.com",
  "bowen.google.com",
  "near",
  "primer-accounts.near",
  "primer_99.near",
  "100",
  "near2022",
  "over.9000",
  "a.bro",
];

const invalidAccounts = {
  "not ok": "Whitespace characters are not allowed",
  a: "Too short",
  "100-": "Suffix separator",
  pri__mer: "Two separators in a row",
  _primer: "Account name cannot start with a separator",
  ".near": "Account ID cannot start with a dot separator",
  "near.": "Account ID cannot end with a dot separator",
  "a..near": "Two dot separators in a row",
  $$$: "Non alphanumeric characters are not allowed",
  WAT: "Non lowercase characters are not allowed",
  "me@google.com": "@ is not allowed (it was allowed in the past)",
  system: "system is a special account on near",
};

const SeedPhrase = () => {
  const [accountName, setAccountName] = useState(validAccounts[0]);

  const listOfAccounts = [...validAccounts, ...Object.keys(invalidAccounts)];

  const checkValid = (accId) => validAccounts.includes(accId);

  return (
    <CenteredLayout>
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          margin: "10px auto",
          justifyContent: "center",
        }}
      >
        <div>
          <h1
            style={{
              textAlign: "center",
              color: checkValid(accountName) ? "indigo" : "salmon",
            }}
          >
            {accountName}
          </h1>
          <h2
            style={{
              textAlign: "center",
              fontVariant: "all-small-caps",
              letterSpacing: "2px",
              color: checkValid(accountName) ? "indigo" : "darksalmon",
            }}
          >
            {checkValid(accountName)
              ? "Valid Account ID"
              : "Invalid Account ID"}
          </h2>
          {!checkValid(accountName) && (
            <h3
              style={{
                textAlign: "center",
                color: checkValid(accountName) ? "indigo" : "darksalmon",
              }}
            >
              {invalidAccounts[accountName]}
            </h3>
          )}
        </div>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="flex-end"
          spacing={2}
          flexWrap="wrap"
        >
          {listOfAccounts.map((accId, idx) =>
            checkValid(accId) ? (
              <Chip
                style={{
                  margin: "5px",
                }}
                onClick={() => setAccountName(accId)}
                key={accId}
                size="small"
                color="primary"
                label={accId}
                variant={accountName === accId ? "contained" : "outlined"}
                sx={{ fontWeight: "bold" }}
              />
            ) : (
              <Chip
                style={{
                  margin: "5px",
                }}
                key={accId}
                size="small"
                label={accId}
                color="warning"
                onClick={() => setAccountName(accId)}
                variant={accountName === accId ? "contained" : "outlined"}
                sx={{ fontWeight: "bold" }}
              />
            )
          )}
        </Stack>
      </Paper>
    </CenteredLayout>
  );
};

export default SeedPhrase;
