export const FullExample = ({ url }) => (
  <div
    style={{
      textAlign: "center",
      fontVariant: "all-small-caps",
      fontWeight: "bold",
    }}
  >
    <a href={url} target="_blank">
      See full example on Github
    </a>
  </div>
);
