import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Gas units are fixed for every function",
    "Gas units vary depending on the function",
    "Gas units are not used for function execution",
  ];
  const correctOption = "Gas units vary depending on the function";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            Common actions on <code>NEAR Protocol</code> have a fixed number of{" "}
            <b>gas units</b>. {props.name}, what about <b>gas units</b>{" "}
            associated with executing a <b>change method</b> on a
            <b> smart-contract</b>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
