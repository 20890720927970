import { Routes, Route } from "react-router-dom";
import { useAppContext } from "../../AppContext";
import LessonQuiz from "./LessonQuiz";
import LessonPage from "./LessonPage";
import "./Lesson.css";

const Lesson = (props) => {
  const context = useAppContext();
  return (
    <div>
      <Routes>
        <Route
          path=":lessonId"
          element={<LessonPage context={context} quest={props.quest} />}
        />
        <Route
          path=":lessonId/quiz"
          element={<LessonQuiz context={context} quest={props.quest} />}
        />
      </Routes>
    </div>
  );
};

export default Lesson;
