import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ConfirmationDialog from "./ConfirmationDialog";
import LessonProgress from "./LessonProgress";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Zoom from "@mui/material/Zoom";
import Box from "@mui/material/Box";
import PrimerQuiz from "./PrimerQuiz";
import useSound from "use-sound";
import { calculateStars } from "./LessonScorecard";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import { motion, AnimatePresence } from "framer-motion";
import { shuffleArray } from "../../utils";
import LessonScorecard from "./LessonScorecard";
import { quizListComponent } from "../../AppContextData/Data";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

const renderItem = (type, props) =>
  ({
    QUIZ: <PrimerQuiz {...props} />,
  }[type]);

const LessonQuiz = (props) => {
  const { questId, lessonId } = useParams();
  const [overClose, setOverClose] = useState(false);
  const [open, setOpen] = useState(false);
  const [progressState, setProgressState] = useState(null);
  const [wrong, setWrong] = useState(0);

  const [playLevelCompleted] = useSound("/sounds/level_completed1.mp3", {
    volume: 0.1,
  });
  const [playLevelLost] = useSound("/sounds/level_lose.wav", {
    volume: 0.1,
  });
  const [playLevelWin] = useSound("/sounds/game_win5.wav", {
    volume: 0.1,
  });

  useEffect(() => {
    if (progressState === "error") {
      setWrong((wrong) => wrong + 1);
    }
  }, [progressState]);

  const groupIndex = props.context.topics[questId].findIndex((x) =>
    x.topics.some((y) => y.slug === lessonId)
  );

  const topicIndex = props.context.topics[questId][groupIndex].topics.findIndex(
    (x) => x.slug === lessonId
  );

  const currentTopic =
    props.context.topics[questId][groupIndex].topics[topicIndex];

  // const quizList = currentTopic.primers
  //   ? currentTopic.primers.filter((x) => x.type === "QUIZ")
  //   : [];

  console.log(quizListComponent);

  const quizList = quizListComponent[questId][lessonId] || [];

  const [primers, setPrimers] = useState(quizList);

  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();

  const updateStars = (stars) => {
    props.context.setTopics((topics) => {
      topics[questId][groupIndex].topics[topicIndex].stars = stars;
      return topics;
    });
  };

  const percentage = parseInt(
    ((primers.length - wrong) / primers.length) * 100
  );
  const stars = calculateStars(percentage);

  useEffect(() => {
    setProgressState(null);
    if (primers.length) {
      if (progress === primers.length) {
        updateStars(stars);
        if (stars === "0") {
          playLevelLost();
        } else if (stars === "5") {
          playLevelWin();
        } else {
          playLevelCompleted();
        }
      }
    }
  }, [progress]);

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const progressProps = {
    overClose,
    setOverClose,
    handleClickOpen,
    navigate,
    questId,
    progress,
    progressState,
    primers: quizList,
  };

  const dialogProps = {
    questId,
    handleClose,
    navigate,
    open,
    showPrompt,
    confirmNavigation,
    cancelNavigation,
  };

  React.useEffect(() => {
    const questName = props.context.quests.find(
      (x) => x.slug === questId
    ).title;
    document.title = `${currentTopic.title} Quiz - ${questName} | NEAR Protocol Primer`;
  }, []);

  if (!currentTopic.primers) {
    return (
      <div>
        <HideOnScroll {...props}>
          <AppBar>
            <LessonProgress {...progressProps} />
          </AppBar>
        </HideOnScroll>
        <div id="back-to-top-anchor" style={{ height: "6rem" }}></div>
        <h1>No Quizzes Found</h1>
        <ConfirmationDialog {...dialogProps} />
      </div>
    );
  } else {
    return (
      <div>
        <HideOnScroll {...props}>
          <AppBar>
            <LessonProgress {...progressProps} />
          </AppBar>
        </HideOnScroll>
        <div id="back-to-top-anchor" style={{ height: "6rem" }}></div>

        {primers
          .filter((_, idx) => idx === progress)
          .map((x, idx) => (
            <AnimatePresence key={progress} exitBeforeEnter>
              <motion.div
                key={progress}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                exit={{ opacity: 0 }}
              >
                <div>
                  {renderItem(x.type, {
                    name: props.context.name,
                    index: idx,
                    questId: questId,
                    lessonId: lessonId,
                    setProgressState: setProgressState,
                    showDialog: true,
                    primers: primers,
                    progress: progress,
                    setProgress: setProgress,
                    ...x.props,
                  })}
                  <div style={{ height: "400px" }}></div>
                </div>
              </motion.div>
            </AnimatePresence>
          ))}

        {progress === primers.length ? (
          <AnimatePresence key={progress} exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              exit={{ opacity: 0 }}
            >
              <LessonScorecard
                wrong={wrong}
                total={primers.length}
                percentage={percentage}
              />
            </motion.div>
          </AnimatePresence>
        ) : (
          ""
        )}

        {/* <LessonDialog {...ComponentProps} /> */}
        <ConfirmationDialog {...dialogProps} />
        <ScrollTop {...props}>
          <Fab color="primary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </div>
    );
  }
};

export default LessonQuiz;
