import {
  StyledTableRow,
  StyledTableCell,
} from "../../../../../components/Common/Table.jsx";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";

const BlockTimeTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "900px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell
              sx={{
                color: "floralwhite",
                textAlign: "center",
                minWidth: "100px",
              }}
              variant="body"
            >
              Data Structure
            </StyledTableCell>
            <StyledTableCell
              sx={{ color: "floralwhite", textAlign: "center" }}
              variant="body"
            >
              Description
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Vector
            </StyledTableCell>
            <StyledTableCell variant="body">
              An iterable implementation of <code>vector</code> that stores its
              content on the <code>trie</code>.
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Lookup Map
            </StyledTableCell>
            <StyledTableCell variant="body">
              An non-iterable implementation of a <code>map</code> that stores
              its content directly on the <code>trie</code>.
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              LookupSet
            </StyledTableCell>
            <StyledTableCell variant="body">
              An non-iterable implementation of a <code>set</code> that stores
              its content directly on the <code>trie</code>.
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              UnorderedMap
            </StyledTableCell>
            <StyledTableCell variant="body">
              An iterable implementation of a <code>map</code> that stores its
              content directly on the <code>trie</code>.
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              UnorderedSet
            </StyledTableCell>
            <StyledTableCell variant="body">
              An iterable implementation of a <code>set</code> that stores its
              content directly on the <code>trie</code>.
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlockTimeTable;
