import React, { useContext, useMemo } from "react";
import { AppContext } from "../../App";
import { createOptions, encouragingWordsGenerator } from "../../utils";
import { Button } from "@mui/material";
import useSound from "use-sound";
import { ExerciseBox } from "./ExerciseBox";
import Stack from "@mui/material/Stack";

const CustomCode = (props) => {
  const blanksContext = useContext(AppContext);
  const [playOff] = useSound("/sounds/pop.wav", { volume: 0.1 });
  const score = blanksContext.blanks.filter(
    (blk) => blk.id === blk.value
  ).length;

  const totalOptions = blanksContext.correctOptions.length;

  const flowsLength = Object.keys(
    blanksContext.flowMarkdown[blanksContext.selectedFlow]
  ).length;

  const lastFlow =
    blanksContext.currentFlowState ===
    `${blanksContext.selectedFlow}-${flowsLength}`;

  const encouragingWords = useMemo(() => encouragingWordsGenerator(), []);

  // const generateExercises =  blanksContext.optionDetails.map((opt) => ({
  //     id: opt.name,
  //     hint: opt.hint
  //   }))
  // }

  // console.log(blanksContext.optionDetails, blanksContext.blanks);

  const getExercises = blanksContext.optionDetails.map((opt) => ({
    id: String(opt.name),
    hint: opt.hint,
    filled:
      blanksContext.blanks.find((x) => x.id === opt.name).value === opt.name,
  }));

  // console.log(getExercises);

  return (
    <div>
      <div
        style={{
          paddingBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span className="Info-Span">{blanksContext.info}</span>
        <div style={{ height: "50px" }}>
          <Button
            variant="outlined"
            className="LessonBtn"
            size="small"
            onClick={() => {
              playOff();
              props.handleContext("reset", true);
            }}
          >
            Reset
          </Button>
        </div>
      </div>
      {props.children}
      <div style={{ padding: "20px", textAlign: "center" }}>
        {score !== totalOptions ? (
          <span className="Hint-Span token string">
            ✅ {score} correct out of {totalOptions}{" "}
          </span>
        ) : (
          ""
        )}{" "}
        <br />
        {!lastFlow && score === totalOptions ? (
          <span>🚀 {encouragingWords} ! </span>
        ) : (
          ""
        )}
        <br />
        {!lastFlow && score === totalOptions ? (
          <div style={{ height: "50px" }}>
            <Button
              variant="outlined"
              className="LessonBtn"
              size="small"
              style={{ marginTop: "20px" }}
              onClick={() => props.handleContext("increase", 1)}
            >
              Next
            </Button>
          </div>
        ) : (
          ""
        )}
        {lastFlow && score === totalOptions ? <div>🏆 Completed</div> : ""}
      </div>
      <div style={{ margin: "10px" }}>
        <Stack sx={{ width: "100%", marginBottom: "20px" }} spacing={2}>
          {getExercises.map((ex, idx) => (
            <ExerciseBox
              key={`${ex.id}-${idx}`}
              id={ex.id}
              hint={ex.hint}
              filled={ex.filled}
            />
          ))}
        </Stack>
      </div>
    </div>
  );
};

export default CustomCode;
