import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";
import Code from "./Code.mdx";

const MCQ = (props) => {
  const options = [
    "Full Access Key for primerlabs.near",
    "Function Call Key for primerlabs.near with 10 Ⓝ allowance",
    "Full Access Key for bensayeg.near",
    "Function Call Key for bensayeg.near with 10 Ⓝ allowance",
  ];
  const correctOption = "Full Access Key for primerlabs.near";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which <code>access_key</code> will you require to
          successfully perform the following <code>transaction</code> in the{" "}
          <code>command-line interface</code>?
          <Code />
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
