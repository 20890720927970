const terminalStyle = {
  background: "rgba(0, 0, 0, 0.87)",
  padding: "10px",
  color: "floralwhite",
  fontFamily: "Jetbrains Mono",
  margin: "10px",
};

const args1 = `{"name":"Sheriff"}`;

export const TerminalMessage = () => {
  return (
    <div>
      <div style={terminalStyle}>
        $ near call hola.primerlabs.testnet greet{" "}
        <span style={{ color: "salmon", fontWeight: "bold" }}>'{args1}'</span>{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          --accountId primerlabs.testnet
        </span>
      </div>
    </div>
  );
};

const args2 = `{"new_account_id": "<account-name>.testnet", "new_public_key": "ed25519:<data>"}`;
const args3 = `--deposit 0.00182 --accountId <account-with-funds>`;

export const TerminalMessage2 = () => {
  return (
    <div style={terminalStyle}>
      $ near call testnet create_account{" "}
      <span style={{ color: "salmon", fontWeight: "bold" }}>'{args2}'</span>{" "}
      <span style={{ color: "gold", fontWeight: "bold" }}>{args3}</span>
    </div>
  );
};
