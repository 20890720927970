export const helpMenuText = `
**Usage**: &&near&& [[<command>]] @@[options]@@

**Commands**:
  &&near&& [[create-account]] @@<accountId>@@                                                                       
        ^^create a new developer account (subaccount of the masterAccount, ex: app.alice.test)^^
  
  &&near&& [[state]] @@<accountId>@@                                                                                 
        ^^view account state^^
  
  &&near&& [[delete]] @@<accountId>@@ @@<beneficiaryId>@@                                                                
        ^^delete an account and transfer funds to beneficiary account.^^

  &&near&& [[keys]] @@<accountId>@@                                                                                  
        ^^view account public keys^^

  &&near&& [[get-free-money]]                                                                                  
        ^^Gives you free NEAR Ⓝ tokens^^      

  &&near&& [[tx-status]] @@<hash>@@                                                                                  
        ^^lookup transaction status by hash^^
 
  &&near&& [[call]] @@<contractName>@@ @@<methodName>@@ **[args]**                                                           
        ^^schedule smart contract call which can modify state^^

  &&near&& [[view]] @@<contractName>@@ @@<methodName>@@ **[args]**                                                           
        ^^make smart contract call which can view state^^

  &&near&& [[view-state]] @@<account-id>@@ **--finality** ^^optimisitc|final^^ **--utf8**                                                                                      
        ^^view contract storage state^^

  &&near&& [[send]] @@<sender>@@ @@<receiver>@@ @@<amount>@@                                                                 
        ^^send tokens to given receiver^^

  &&near&& [[login]]                                                                                             
        ^^logging in through NEAR protocol wallet^^

  &&near&& [[generate-key]] **[account-id]**                                                                         
        ^^generate key or show key from Ledger^^

  &&near&& [[add-key]] @@<account-id>@@ @@<access-key>@@                                                                 
        ^^Add an access key to given account^^

  &&near&& [[delete-key]] @@<account-id>@@ @@<access-key>@@                                                              
        ^^delete access key^^

**Options**:
      @@--help@@                     ^^Show help^^
      @@--masterAccount@@            ^^Master account used when creating new accounts^^
      @@--initialBalance@@           ^^Initial Balance used to  create new accounts^^
`;

export const downloadHelpText = `
**Usage**: &&download&& [[filename]] 

For instance if you want to download [[hello.wat]] file, you can do so by using the following command:
      &&download&& [[hello.wat]] 
`;
export const downloadErrorText = `**Encountered Following Errors**
      &&- Invalid command usage&&

**Usage**: 
      &&download&& [[filename]]

For more info type:
      &&near&& [[create-account]] @@--help@@ 
`;
