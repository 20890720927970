export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, in the previous lesson, we took a look at how a global decentralised ledger such as Bitcoin works.",
        "Let's recap what we know about Bitcoin with a quick quiz.",
      ],
      primerAvatar: "puzzle",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "BitcoinFillQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "At the heart of a global decentralised ledger, lies the idea of a group of anonymous distributed actors coming together and maintaining a ledger.",
        "However, one of the major problems of such a global distributed anonymous network is to ensure that all of them agree on the same state of ledger",
        "This is the problem of consensus and let's understand it a little bit better.",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Consensus",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "In case of conflicting blockchains, nodes need to collectively figure out the correct blockchain",
        "{{user}}, how do you think they do that?",
      ],
      primerAvatar: "question",
      textReply: {
        que: "How do nodes collectively figure out which is the correct blockchain?",
      },
      userReply: { reply: null, userAvatar: "Smirk" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "In case of a conflict, all nodes are designed replace their copy of the blockchain with the one with longest chain of blocks.",
        'And the process of switching the blockchain is called "Chain Reorganisation"',
        "Let's explore the idea bit further",
      ],
      primerAvatar: "workman-1",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "ProofOfWork",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "BitcoinMatchQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'The "longest chain rule" allows autonomously operating nodes to collectively agree on the correct blockchain',
        'However, the longest chain rule is infact more about the "work" done rather than "length"',
        "Let me explain in detail",
      ],
      primerAvatar: "workman-2",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Work",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "DifficultyFillQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "The longer the pattern infront of the hash, the more difficult it is to find a valid nonce",
        "More difficult it is to find a valid nonce, more time is taken to find a valid nonce",
        "Let's take a look at the idea of difficulty in more detail.",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Difficulty",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "DiffMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Since, the bitcoin ledger holds money,...",
        "...it is inevitable that malicious actors will try to take advantage of the system",
        "Let's take a look at how Proof of Work defends against such attacks",
      ],
      primerAvatar: "security-1",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DoubleSpending",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "DoubleMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "So far, we have obtained a good amount of understanding as to how the Bitcoin Decentralised Ledger functions.",
        "Let's take a look at some of the downsides of the Bitcoin and Proof of Work in general",
      ],
      primerAvatar: "oops",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Issues",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "In this section, we took a look at how Bitcoin uses Proof of Work to solve the problem of consensus",
        "In the next section, we will take another approach of solving the problem of consensus with much lower downsides",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "wave",
    },
  },
];
