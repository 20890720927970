import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AntTabs, AntTab } from "./Tabs";
{
  /* <span style={{ fontSize: "3rem" }}>{"\u201C"}</span>
 <span style={{ fontSize: "3rem" }}>{"\u201D"}</span> */
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography sx={{ color: "#47307f", display: "flex" }}>
            <span style={{ fontSize: "80px", margin: "0px", padding: "0px" }}>
              {"\u201C"}
            </span>
            <blockquote style={{ paddingTop: "10px", fontSize: "small" }}>
              {children}
            </blockquote>
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabPanelStyles = {
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  color: "#47307f",
};

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        border: "none",
        maxHeight: 224,
      }}
    >
      <AntTabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderBottom: "none",
          borderRight: 1,
          borderColor: "divider",
          minWidth: "250px",
        }}
      >
        <AntTab label="Privacy" {...a11yProps(0)} />
        <AntTab label="Bandwidth & Storage" {...a11yProps(1)} />
        <AntTab label="Incompetence" {...a11yProps(2)} />
        <AntTab label="Trust" {...a11yProps(3)} />
      </AntTabs>
      <TabPanel style={tabPanelStyles} value={value} index={0}>
        This method compromises the privacy of the document in two ways: a{" "}
        <b>
          third party could eavesdrop while the document is being transmitted
        </b>
        , and after transmission it is available indefinitely to the TSS itself.
        Thus the client has to worry not only about the{" "}
        <b>security of documents</b> it keeps under its direct control, but also
        about the security of its documents at the TSS.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={1}>
        Both the amount of time required to send a document for time-stamping
        and the amount of storage required at the TSS depend on the length of
        the document to be time-stamped. Thus the time and expense required to
        <b>time-stamp a large document might be prohibitive</b>.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={2}>
        The TSS copy of the document could be <b>corrupted in transmission</b>{" "}
        to the TSS, it could be <b>incorrectly time-stamped</b> when it arrives
        at the TSS, or it could become corrupted or lost altogether at any time
        while it is stored at the TSS. Any of these occurrences would invalidate
        the client's time-stamping claim.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={3}>
        The fundamental problem remains:{" "}
        <b>
          nothing in this scheme prevents the TSS from colluding with a client
        </b>{" "}
        in order to claim to have time-stamped a document for a date and time
        different from the actual one.
      </TabPanel>
    </Box>
  );
}
