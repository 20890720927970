import React from "react";
import { ReorderQuiz } from "../../../../../components/Quiz/ReorderQuiz";

const itemsList = [
  "Sender account issues a valid transaction",
  "Sender account signs the transaction request using their private key",
  "Sender account sends the valid transaction to a RPC Node",
  "RPC Node queue the transaction into a pool of transaction",
  "Validator verifies if the transaction request is valid",
  "Validator deducts the amount Ⓝ tokens from the sender account",
  "Validator credits amount Ⓝ to the receiver account",
  "The transaction details is added to a block in the blockchain",
];

const ReorderTemplate = (props) => (
  <ReorderQuiz
    itemsList={itemsList}
    header={
      <React.Fragment>
        Can you rearrange how an account transfer <b>NEAR Ⓝ tokens</b> to
        another account?
      </React.Fragment>
    }
    {...props}
  />
);

export default ReorderTemplate;
