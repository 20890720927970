import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "deploy smart contract using temporary account to TestNet",
    "creates a temporary developer account if not available",
    "Fund the developer account with test tokens",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, what is the use case of <code>dev-deploy</code>{" "}
            command?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
