import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AntTabs, AntTab } from "./Tabs";
{
  /* <span style={{ fontSize: "3rem" }}>{"\u201C"}</span>
 <span style={{ fontSize: "3rem" }}>{"\u201D"}</span> */
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography sx={{ color: "#47307f", display: "flex" }}>
            <span style={{ fontSize: "80px", margin: "0px", padding: "0px" }}>
              {"\u201C"}
            </span>
            <blockquote style={{ paddingTop: "10px", fontSize: "small" }}>
              {children}
            </blockquote>
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabPanelStyles = {
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  color: "#47307f",
};

// Getting Deposits, Giving Loans, Risk Underwriting, Letter of Credit, Bill of Exchange, Shipping large sums of money was dangerous in the middle ages.

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        display: "inline-block",
        bgcolor: "background.paper",
        border: "none",
      }}
    >
      <AntTabs
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderBottom: "none",
          borderRight: 1,
          borderColor: "divider",
          minWidth: "250px",
        }}
      >
        <AntTab label="Deposits" {...a11yProps(0)} />
        <AntTab label="Loans" {...a11yProps(1)} />
        <AntTab label="Underwriting" {...a11yProps(2)} />
        <AntTab label="Bill of Exchange" {...a11yProps(3)} />
        <AntTab label="Transfer" {...a11yProps(4)} />
        <AntTab label="Letter of Credit" {...a11yProps(5)} />
      </AntTabs>
      <TabPanel style={tabPanelStyles} value={value} index={0}>
        One of the first and most important function of a bank was to{" "}
        <b>deposit money</b> from people. Keeping large sums of money in their
        homes meant <b>risking damage, theft and inflation</b>.
        <br /> <br />
        <b>
          Banks provided the depositors an interest on their deposits while
          ensuring that their hard-earned money was safe.
        </b>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={1}>
        <b></b>Merchants
        <b /> in Europe, would often make <b>large advance payments</b> and then
        be left waiting for their money because customers were taking their time
        to settle the bill. <b>Merchants</b> needed <b>loans</b> or{" "}
        <b>credit</b> to continue their day to day operations. Banks had large
        deposits of money from various depositors whom they promised fixed
        returns. To ensure they keep their trust, they used to accurately assess
        the <b>financial health</b> of a merchant or trader which was now easier
        with the introduction of the <b>double-entry accounting ledger</b> and
        loaned them money with fair interest rates.
        <br />
        <br />
        Since, there could be many instances of merchants not being able to pay
        back and the <b>loan getting defaulted</b>, it was an important function
        of a bank to carefully decide whom to give loans.
        <br /> <br />
        <b>
          Therefore, banks used the deposited money for loans by assessing the
          financial health of the borrower and made money on the interest paid.
        </b>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={2}>
        The other main function of banks was <b>risks underwriting</b>. In the
        middle ages, transportation of goods used to take many days or even
        months. This often resulted in goods being stolen or damaged during the
        voyage resulting in severe losses. Banks offered insurance in such
        cases. <br />
        <br />
        Banks also offered <b>crop insurance</b> to farmers against{" "}
        <b>crop failure</b>, <b>insured equiments and inventories</b> of
        craftman, and insured traders{" "}
        <b>against any potential damage to their trades</b>.
        <br />
        <br />
        <b>
          Therefore, banks being a centralised trusted party gave rise of
          insurance as we know it today.
        </b>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={3}>
        We mentioned that <b>the Medici Bank</b> rose to prominence by{" "}
        <b>collecting tithes</b> to the <b>Catholic Church</b>. These{" "}
        <b>tithes</b> were deposited throughout different countries in{" "}
        <b>Europe</b> which had their own currencies. At the time,{" "}
        <b>Catholic Church</b> opposed strongly to the practice of <b>usury</b>.{" "}
        <b>Medici bank</b> bypassed the sanction by making money on{" "}
        <b>currency exchange arbitrage</b> using <b>bill of exchange</b>.
        <br />
        <br />
        For instance, if you were a merchant in 15th century Europe in an urgent
        need of <b>1000 florins</b>, rather than giving a direct line of credit,
        the <b>Medici Bank</b> will buy <b>55000 pence</b> at the price of{" "}
        <b>55 pence</b>
        for each <b>florin</b> from the merchant. The <b>Medici bank</b> would
        then pay the <b>1000 florins</b> immediately with the condition that
        merchant needed to deposit <b>55000 pence</b> in London branch of the
        bank after 90 days. The London branch would then find a customer in
        London desiring to engage in the reverse transaction that is someone who
        needed a loan in <b>pence</b> and is willing to pay back in{" "}
        <b>florins</b>. In the eyes of <b>Catholic Church</b>, this was not
        usury but the <b>currency exchange</b>, thus exempting the{" "}
        <b>Medici Bank</b> from eternal damnation.
        <br />
        <br />
        <b>
          Banks being a centralised trust party, facilitated currency exchange,
          made money on the arbitrage and, paved a path for global banking
          system to emerge.
        </b>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={4}>
        Shipping large sums of money was quite dangerous in the middle ages of
        Europe. Banks with their distributed network each being part of a{" "}
        <b>centralised ledger</b> facilitated transfer of funds from the Italy
        to London by just updating the ledger.
        <br />
        <br />
        <b>
          Banks acting as a trusted third-party with a centralised ledger made
          it easier and faster to transfer money while saving time and resources
          of the customers.{" "}
        </b>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={5}>
        A <b>letter of credit</b> was one of the most important financial
        mechanisms that allowed international trade to flourish in the 15th
        century. As caravans wheeled, and ships sailed around Europe
        distributing fine goods, the letter of credit became a necessity for
        travelling merchants.
        <br />
        <br />A <b>letter of credit</b> is an agreement in which the buyer's
        bank <b>guarantees</b> to pay the seller's bank at the time
        goods/services are delivered.
        <br />
        <br />
        <b>
          Therefore, banks functioned as a third-party escrows between two
          parties to ensure that the goods were successfully traded.
        </b>
      </TabPanel>
    </Box>
  );
}
