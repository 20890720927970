import React from "react";
import FillQuiz from "../../../../../components/Quiz/FillQuiz";

const FillQuizTemplate = (props) => {
  const options = [
    "epoch",
    "NEAR",
    "validator",
    "seat price",
    "stake",
    "producers",
    "attestations",
    "staked",
    "final",

    "randomly",
  ];

  return (
    <FillQuiz
      header={
        <React.Fragment>
          {" "}
          <b>{props.name}</b>, can you fill in the blanks about how does{" "}
          <b>NEAR Protocol</b> works?
        </React.Fragment>
      }
      send={props.send}
      options={options}
      num={10}
      {...props}
    >
      In every [epoch], a set of participants [stake] their [NEAR] tokens for a
      chance to be validator. To become a [validator], a participant has to
      stake greater than the [seat price]. Once, the validators for the epoch
      are chosen, the network [randomly] assigns them as block [producers] to
      produce blocks at certain height of the chain. Blocks produced by block
      producers are validated by other validators who sign [attestations]. If a
      block has 2/3 of the total [staked] NEAR amount, it is considered as
      [final].
    </FillQuiz>
  );
};

export default FillQuizTemplate;
