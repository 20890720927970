import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function QuestBreadcrumbs({ questName }) {
  const breadcrumbs = [
    <Link
      style={{ fontWeight: "bold" }}
      underline="hover"
      key="1"
      color="inherit"
      href="/"
    >
      NEAR Protocol Primer
    </Link>,
    <Typography style={{ fontWeight: "bold" }} key="3" color="text.primary">
      {questName}
    </Typography>,
  ];

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
