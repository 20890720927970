import { Route, Routes } from "react-router-dom";
import { useAppContext } from "../../AppContext";
import ExplorablePage from "./ExplorablePage";

const Explorable = (props) => {
  const context = useAppContext();
  return (
    <div>
      <Routes>
        <Route
          path=":explorableId"
          element={<ExplorablePage context={context} />}
        />
      </Routes>
    </div>
  );
};

export default Explorable;
