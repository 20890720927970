import React from "react";
import { Prompt, Env } from "./common";

const getMessages = (type, message) =>
  ({
    invalidAccountID: (
      <span style={{ color: "salmon", fontWeight: "bold" }}>
        Account ID seems invalid
      </span>
    ),
    invalidPublicKey: (
      <span style={{ color: "salmon", fontWeight: "bold" }}>
        Public Key seems invalid
      </span>
    ),
    NoAccountAccessKeyFound: (
      <span>
        Cannot sign transactions for account{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {message.accountId}
        </span>{" "}
        on network{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {message.network}
        </span>
        ,{" "}
        <span style={{ color: "salmon", fontWeight: "bold" }}>
          no matching key pair found
        </span>
      </span>
    ),
    NoMatchingAccessKeyFound: (
      <span>
        Cannot sign transactions for account{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {message.accountId}
        </span>{" "}
        on network{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {message.network}
        </span>
        ,{" "}
        <span style={{ color: "salmon", fontWeight: "bold" }}>
          no matching key pair found
        </span>
      </span>
    ),
    AlreadyExists: (
      <span>
        The public key{" "}
        <span style={{ color: "salmon", fontWeight: "bold" }}>
          {message.publicKey}
        </span>{" "}
        is already used for an existing access key
      </span>
    ),
    NewAccessKeyAdded: (
      <span>
        New access Key added to the{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {message.accountId}
        </span>{" "}
        on network{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {message.network}
        </span>
      </span>
    ),
    AccessKeyRemove: (
      <span>
        Successfully deleted access key of{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {message.accountId}
        </span>{" "}
        on{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {message.network}
        </span>
      </span>
    ),
    NoPublicKeyAssociated: (
      <span>
        Account{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {message.accountId}
        </span>{" "}
        tries to remove an access key that doesn't exist on the network{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {message.network}
        </span>
      </span>
    ),
  }[type]);

const removeMessage = ({
  accountId,
  testnet,
  publicKeyText,
  keyTypeFull,
  contractId,
  allowance,
  methods,
  message,
}) => {
  return (
    <div>
      <Prompt />
      {!testnet && <Env />} near delete-key{" "}
      {accountId.trim().length === 0 ? (
        <span style={{ color: "gold" }}>&lt;accountId&gt; </span>
      ) : (
        <span style={{ color: "gold", fontWeight: "bold" }}>{accountId}</span>
      )}{" "}
      {publicKeyText.trim().length === 0 ? (
        <span style={{ color: "lightblue" }}>&lt;public-key&gt; </span>
      ) : (
        <span style={{ color: "lightblue", fontWeight: "bold" }}>
          {publicKeyText}
        </span>
      )}
      <br />
      {message !== null && getMessages(message.type, message)}
    </div>
  );
};

export default removeMessage;
