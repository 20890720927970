import React from "react";
import Lottie from "lottie-react";

const LottieFile = ({ url, style, loop = true }) => {
  const [anim, setAnim] = React.useState(null);

  React.useEffect(() => {
    const assetUrl = `https://primer-assets-s3-limited.s3.amazonaws.com/banner_cards/${url}.json`;

    async function fetchData() {
      const response = await fetch(assetUrl);
      if (!response.ok) {
        setAnim("error");
      }
      const json = await response.json();
      setAnim(json);
    }
    fetchData();
  }, []);

  if (anim === null) {
    return <div>Loading...</div>;
  } else if (anim === "error") {
    return <div>Error loading animation</div>;
  }

  return <Lottie style={style} loop={loop} animationData={anim} />;
};

export default LottieFile;
