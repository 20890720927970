import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AntTabs, AntTab } from "./Tabs";
{
  /* <span style={{ fontSize: "3rem" }}>{"\u201C"}</span>
 <span style={{ fontSize: "3rem" }}>{"\u201D"}</span> */
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography sx={{ color: "#47307f", display: "flex" }}>
            <span style={{ fontSize: "80px", margin: "0px", padding: "0px" }}>
              {"\u201C"}
            </span>
            <blockquote style={{ paddingTop: "10px", fontSize: "small" }}>
              {children}
            </blockquote>
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabPanelStyles = {
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  color: "#47307f",
};

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        display: "inline-block",
        bgcolor: "background.paper",
        border: "none",
      }}
    >
      <AntTabs
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderBottom: "none",
          borderRight: 1,
          borderColor: "divider",
          minWidth: "250px",
        }}
      >
        <AntTab label="Money" {...a11yProps(0)} />
        <AntTab label="Identity" {...a11yProps(1)} />
        <AntTab label="Ownership" {...a11yProps(2)} />
      </AntTabs>
      <TabPanel style={tabPanelStyles} value={value} index={0}>
        When you keep money on a bank account, you are at the mercy of the bank
        to let you use your funds. The bank can be directed by
        <b> governments regulations to freeze your account</b> or the{" "}
        <b>money can be used by the banks at their own discretion</b> without
        informing you.
        <br /> <br />
        This is exactly what happened during{" "}
        <a
          href="https://en.wikipedia.org/wiki/Financial_crisis_of_2007%E2%80%932008"
          target="_blank"
          style={{ fontWeight: "bold" }}
        >
          2008's financial recession
        </a>{" "}
        where banks used stored customer money to invest in risky assets and
        ended up losing the money.
        <br />
        <br />
        When you store money on <b>NEAR Protocol</b>, in the form of the{" "}
        <b>NEAR tokens</b> or any other digital assets, it is guaranteed that
        the asset
        <ul>
          <li>is only accessible to the owner of the access key</li>
          <li>and the data cannot be mismanaged</li>
        </ul>
        There are obvious concerns of volatility associated with storing money
        in the NEAR tokens, however, there are other solutions such as{" "}
        <a
          href="https://en.wikipedia.org/wiki/Stablecoin"
          target="_blank"
          style={{ color: "salmon", fontWeight: "bold" }}
        >
          stable coins
        </a>{" "}
        to combat the same.
        <br />
        <br />
        <b>
          The point is your money is not in some centralised database rather it
          is distributed among thousands of independent actors in a system which
          is difficult to compromise.
        </b>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={1}>
        On the internet, the <b>identity of an individual</b> is important while
        interacting with the digital world.
        <br />
        <br />
        As you keep interacting with an identity in the digital world by
        providing value, in the form of any creative pursuits, using
        applications such as Twitter, Facebook, TikTok, your identity becomes
        valuable as well. <br />
        <br />
        <b>
          Your digital identity allows to accrue reputation which you can later
          leverage into for your own benefit.{" "}
        </b>
        Since, your identity is stored on a centralised database, the user is at
        the mercy of <b>centralised authority</b> to ensure that the identity{" "}
        <b>doesn't compromised or misused</b>. On a blockchain-based system, the{" "}
        <b>identity</b> is controlled by the user. Only, the user is responsible
        for keeping their identity safe which significantly reduces the attack
        vectors.
        <br />
        <br />
        Idenities can also be proven or revealed as per need to it. Let's say
        you wish to verify that you are in fact above 18 or belong to a certain
        country. Blockchain-based identity solutions enable you to prove these
        specific claims without revealing entire information of your
        Identification. Also, the
        <b> reputation</b> you accrue with the identity cannot be censored or
        corrupted. So, on a <b>blockchain-based social media</b>, governments
        cannot censor your freedom to speech or social media giants cannot
        snatch your hard-worked identity at the snap of a finger.
        <br />
        <br />
        <b>
          The value of identity might not be clear now, but all of us are moving
          into a future where identity will reign supreme and will open access
          to bigger opportunities based on the reputation of their digital
          identity.
        </b>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={2}>
        The ownership of a <b>digital asset</b> such as a{" "}
        <b>
          digital game, royalty agreement, a digital collectible or even
          ownership aggreement in a company,
        </b>{" "}
        is subjected to data stored on a centralised authority server.
        <br />
        <br />
        You can lose access to your digital asset such as a 'money', 'game', or
        digital artworks, if the data is hacked or the centralised authority
        gets insolvent.
        <br />
        <br />
        Let's take a concrete example of an <b>centralised exchange</b>, where
        you can trade and exchange tokens. Centralised exchanges usually provide
        a <b>custodial wallet</b> where users can put money to trade faster in
        the market. Since, the wallet is <b>custodial</b>
        in nature, you don't really own the money, even though it is rightfully
        yours.
        <br />
        <br />A centralised exchange can freeze withdrawals due to any
        circumstance, which can lead to financial loss. However, in a{" "}
        <b>decentralised exchange</b>, the exchange cannot prevent you from
        withdrawing your money.
        <br />
        <br />
        Similarly, any digital asset that you buy is recorded in an immutable
        ledger that proves that you are the rightful owner. The ledger also
        provides the history associated with the owned object to help fairly
        value the digital asset.
        <br />
        <br />
        <b>
          Therefore, the ownership can be verifiable proven and printed on a
          blockchain that is quite immune to corruption.
        </b>
      </TabPanel>
    </Box>
  );
}
