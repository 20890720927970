import React from "react";
import { AppContext } from "../../App";
import { usePopperTooltip } from "react-popper-tooltip";
import useSound from "use-sound";
import "react-popper-tooltip/dist/styles.css";

const Blank = React.forwardRef(({ id, blanks = [] }, ref) => {
  // console.log("Blank is ", id);
  const blanksContext = React.useContext(AppContext);

  const [shake, setShake] = React.useState(false);

  const [playSelect] = useSound("/sounds/pop_select.wav", { volume: 0.1 });
  const [playWrong] = useSound("/sounds/wrong.mp3", { volume: 0.1 });
  const [playCorrect] = useSound("/sounds/correct.mp3", { volume: 0.1 });
  const [playLevelCompleted] = useSound("/sounds/level_completed2.wav", {
    volume: 0.1,
  });

  const flowsLength = Object.keys(
    blanksContext.flowMarkdown[blanksContext.selectedFlow]
  ).length;

  const score = blanksContext.blanks.filter(
    (blk) => blk.id === blk.value
  ).length;
  const totalOptions = blanksContext.correctOptions.length;

  const lastFlow =
    blanksContext.currentFlowState ===
    `${blanksContext.selectedFlow}-${flowsLength}`;

  const findValue = (id) => {
    // console.log(blanksContext.blanks);
    const result = blanksContext.blanks.find((blk) => blk.id === id);
    if (!result) {
      throw Error(`Couldn't find option for ${id}`);
    }
    return result;
  };

  const currentlySelected = blanksContext.blankSelected === id;

  const hintExists = blanksContext.optionDetails.some(
    (opt) => opt.name === id && opt.hint
  );

  const successExist = blanksContext.optionDetails.some(
    (opt) => opt.name === id && opt.success
  );

  const optionIndex = (id) =>
    blanksContext.optionDetails.findIndex((opt) => opt.name === id);

  //   const hintMessage = hintExists ? blanksContext.optionDetails
  //   const successMessage = (id) => blanksContext.optionDetails.findIndex(opt => )
  const handleClickHandler = () => {
    blanksContext.handleContext("blankSelected", id);
    playSelect();
  };

  const animate = () => {
    // Button begins to shake
    setShake(true);

    // Buttons stops to shake after 0.5 seconds
    setTimeout(() => setShake(false), 500);
  };

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: "top",
  });

  React.useEffect(() => {
    const currentBlank = findValue(id);
    if (currentBlank.value && currentBlank.id !== currentBlank.value) {
      playWrong();
      animate();
    } else if (currentBlank.id === currentBlank.value) {
      if (lastFlow && score === totalOptions) {
        playLevelCompleted();
      } else {
        playCorrect();
      }
    }
  }, [findValue(id)]);

  const isActive = currentlySelected;

  let backgroundColor = "rgba(51, 170, 51, .05)";
  let opacity = 1;

  if (isActive) {
    backgroundColor = "rgb(223 132 184 / 20%)";
  }
  // console.log(findValue(id));
  return (
    <span onClick={handleClickHandler} ref={ref}>
      <span
        style={{
          backgroundColor,
          cursor: "pointer",
          opacity,
        }}
      >
        {findValue(id).value !== null ? (
          findValue(id).value === id ? (
            <span
              ref={successExist ? setTriggerRef : null}
              style={{
                borderBottom: "2px dashed",
              }}
            >
              {findValue(id).value}
            </span>
          ) : (
            <span
              ref={hintExists ? setTriggerRef : null}
              className={shake ? `shake shake-constant` : null}
              style={{
                fontWeight: "bold",
                color: "pink",
                display: "inline-block",
                borderBottom: "3px dotted #fb4934",
                borderBottomStyle: "dotted",
              }}
            >
              {findValue(id).value} <span style={{ color: "#fb4934" }}>✘</span>{" "}
              {""}
            </span>
          )
        ) : (
          <span
            style={{ color: currentlySelected ? "green" : "white" }}
            ref={hintExists ? setTriggerRef : null}
          >
            _______
          </span>
        )}
        {visible && (
          <div
            ref={setTooltipRef}
            {...getTooltipProps({ className: "tooltip-container" })}
          >
            {findValue(id).value !== null ? (
              findValue(id).value === id ? (
                successExist ? (
                  <span className="successMessage">
                    <span>✔ </span>
                    {blanksContext.optionDetails[optionIndex(id)].success}
                  </span>
                ) : (
                  "Success message doesn't exist"
                )
              ) : hintExists ? (
                <span className="hintMessage">
                  <span>✘</span>{" "}
                  {blanksContext.optionDetails[optionIndex(id)].hint}{" "}
                </span>
              ) : (
                "Hint doesn't exist"
              )
            ) : hintExists ? (
              <span>
                <span>ⓘ</span>{" "}
                {blanksContext.optionDetails[optionIndex(id)].hint}{" "}
              </span>
            ) : (
              "Hint doesn't exist"
            )}
            <div {...getArrowProps()} />
          </div>
        )}
      </span>
    </span>
  );
});

export default Blank;
