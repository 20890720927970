import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["90257", "91268", "91021517", "90268"];
  const correctOption = "90257";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      header={
        <React.Fragment>
          {props.name}, which of the following bucket will the number{" "}
          <b>902-811-6998</b> go into using <b>Luhn's Hashing Algorithm</b>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
