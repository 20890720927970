import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import Zoom from "@mui/material/Zoom";
import Box from "@mui/material/Box";
import { avatars, userAvatars } from "../Info/component/Avatar";

export const constructImageLinks = (arr, primer = true) => {
  if (primer) {
    return arr.map((x) => {
      let avatarValue = avatars.find((y) => y.text === x).value;
      return `https://assets.primerlabs.io/PrimerAvatar150/${avatarValue}`;
    });
  } else {
    return arr.map((x) => {
      let avatarValue;
      try {
        avatarValue = userAvatars.find((y) => y.text === x).value;
      } catch (e) {
        console.log(e, x);
      }
      return `https://assets.primerlabs.io/kawaii/${avatarValue}`;
    });
  }
};

export const traverse = (
  primers = [],
  accumulator = { user: [], primer: [] }
) => {
  const avatarObject = primers
    .filter((x) => x.type === "CHAT")
    .reduce((acc, curr) => {
      if (curr.reply === "TEXT") {
        return {
          primer: [...acc.primer, curr.props.primerAvatar],
          user: [...acc.user, curr.props.userReply.userAvatar],
        };
      } else if (curr.reply === "BUTTON") {
        if (curr.props.buttonOptions.length === 0) {
          return {
            primer: [...acc.primer, curr.props.primerAvatar],
            user: [...acc.user, curr.props.userReply.userAvatar],
          };
        } else {
          const result = curr.props.buttonOptions.reduce((acc1, curr1) => {
            if (curr1.primers) {
              const avatars = traverse(curr1.primers, acc);
              return {
                primer: [...acc1.primer, ...avatars.primer],
                user: [
                  ...acc1.user,
                  ...avatars.user,
                  curr1.userReply.userAvatar,
                ],
              };
            } else {
              return {
                ...acc1,
                user: [...acc1.user, curr1.userReply.userAvatar],
              };
            }
          }, acc);
          return {
            primer: [...acc.primer, curr.props.primerAvatar, ...result.primer],
            user: [
              ...acc.user,
              curr.props.userReply.userAvatar,
              ...result.user,
            ],
          };
        }
      } else {
        return acc;
      }
    }, accumulator);

  let final = {
    primer: new Set(avatarObject.primer.filter((x) => x !== null)),
    user: new Set(avatarObject.user.filter((x) => x !== null)),
  };

  return final;
};

export const HideOnScroll = (props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export const ScrollTop = (props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
};
