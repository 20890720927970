import React from "react";

const isBlankSelected = true;

function Option(props) {
  const [hover, setHover] = React.useState(false);

  const currentlyUsed = () =>
    props.blanks.some((blk) => blk.value === props.text);

  const handleClick = () => {
    props.setBlank(props.blankSelected, props.text);
  };

  return (
    <div
      onClick={handleClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        opacity: currentlyUsed() ? 0.5 : 1,
        display: "inline-block",
        padding: "10px",
        margin: "10px",
        fontWeight: hover ? "bolder" : "bold",
        borderBottom:
          isBlankSelected && hover
            ? "3px dashed #47307f"
            : "3px solid floralwhite",
        color: isBlankSelected || hover ? "#47307f" : "gray",
        cursor: "pointer",
        transition: "0.1s",
      }}
    >
      {props.text}
    </div>
  );
}

export default Option;

// <div
// style={{
//   opacity: isDragging ? 0.5 : currentlyUsed() ? 0.5 : 1,
//   fontSize: "1.1rem",
//   fontWeight: "normal",
//   padding: "10px",
//   margin: "10px",
//   cursor: "pointer",
// }}
// >

// const [{ isDragging }, drag] = useDrag(() => ({
//   type: ItemTypes.OPTION,
//   item: { name: props.text },
//   options: {
//     dropEffect: "copy",
//   },
//   end: (item, monitor) => {
//     const dropResult = monitor.getDropResult();
//     if (item && dropResult) {
//       props.setBlank(dropResult.name, item.name);
//     }
//   },
//   collect: (monitor) => ({
//     isDragging: monitor.isDragging(),
//     handlerId: monitor.getHandlerId(),
//   }),
// }));
