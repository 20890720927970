import React, { useRef, useEffect } from "react";
import { CssTextField } from "./styledComponents";
import { Button } from "@mui/material";
import anime from "animejs";
import TextProfanityDialog from "./TextProfanityDialog";
var Filter = require("bad-words");

const filter = new Filter();
filter.removeWords("god");
filter.addWords(...["suck", "sucks"]);

filter.clean = (string) => {
  try {
    const splitMap = string.split(filter.splitRegex).map((word) => {
      return filter.isProfane(word) ? `[${word}]` : word;
    });
    if (splitMap.length < 2) return string;
    return splitMap.join(filter.splitRegex.exec(string)[0]);
  } catch (e) {
    console.log(e);
    return string;
  }
};

const TextReply = ({ send, currentPrimer }) => {
  const [text, setText] = React.useState("");

  const [showProfanity, setShowProfanity] = React.useState(false);
  const [profaneText, setProfaneText] = React.useState(null);

  const replyTextArea = useRef();

  const animation = useRef(null);

  const handleSubmit = () => {
    if (filter.isProfane(text)) {
      const censoredText = filter.clean(text);
      setProfaneText(censoredText);
      setShowProfanity(true);
    } else {
      send({ type: "NEXT", reply: text });
    }
  };

  const continueSubmit = () => send({ type: "NEXT", reply: text });

  useEffect(() => {
    animation.current = anime.timeline({
      loop: false,
      autoplay: true,
    });

    animation.current.add({
      targets: replyTextArea.current,
      opacity: [0, 1],
      translateY: [25, 0],
      delay: 300,
      //   begin: () => {
      //     replyTextArea.current.scrollIntoView({
      //       behavior: "smooth",
      //     });
      //   },
      complete: () => send("TEXT"),
    });
  }, []);

  const handleKeyDown = (evt) => {
    if (evt.key === "Enter") {
      evt.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div
      ref={replyTextArea}
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <CssTextField
        value={text}
        onKeyDown={handleKeyDown}
        autoComplete="off"
        maxLength="150"
        onChange={(evt) => {
          setText(evt.target.value.slice(0, 150));
        }}
        autoFocus
        rows={2}
        multiline
        sx={{
          margin: "10px auto",
          width: "50%",
          minWidth: "400px",
          maxWidth: "600px",
        }}
        label={currentPrimer.props.textReply.que}
      />

      <div>
        <Button
          onClick={handleSubmit}
          disabled={text.trim().length < 3}
          sx={{ height: "3rem", fontSize: "1rem" }}
          variant="outlined"
        >
          Submit
        </Button>
      </div>
      <TextProfanityDialog
        open={showProfanity}
        handleClose={() => {
          setProfaneText(null);
          setShowProfanity(false);
        }}
        continueSubmit={continueSubmit}
        text={profaneText}
      />
    </div>
  );
};

export default TextReply;
