export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, in this quest, we will start creating a smart contract from scratch",
        'Let\'s first create a simple smart contract: "Counter" which lets users to increase and decrease a stored integer',
        "Let's take a look at how it works.",
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Counter",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "DeployMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'Hopefully, you have deployed the "counter" smart contract to your "counter" subaccount.',
        "Now, time for testing out our deployed smart contract.",
      ],
      primerAvatar: "laptop-1",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "CounterTest",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "StorageMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "When you deploy a smart contract to an account, you have to take in to account the size of the smart contract along with the gas cost involved in the operation ",
        'You might recall that this is called "storage staking"',
        "Now, let's take a look at how a smart contract works by breaking it down to understand it better",
      ],
      primerAvatar: "patient",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "StorageStaking",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ContractMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'Once, a "contract state" has been initialised, it can only be modified through methods',
        "To understand this, let's take a look at how a smart contract stores it's state",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "StateInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "DeployMatch",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Defining the default state doesn't initialized the state",
        "Once any method writes into the state, the state will be considered initialized",
        'Let\'s use the "initialization method" to intialize the contract state this time',
      ],
      primerAvatar: "laptop-1",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "StateInit",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        'We deployed the modified "counter" contract into "blank2.primerlabs.testnet"',
        "{{user}}, what do you think happens when we call a change method on the above deployed contract?",
      ],
      primerAvatar: "puzzle",

      textReply: {
        que: "What happens when you call the method on the above contract?",
      },
      userReply: { reply: null, userAvatar: "Shy" },
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "The above call results in an error saying: ",
        '"Smart contract panicked: Contract must be initialized"',
        "Now, why do you think that is the case?",
      ],
      primerAvatar: "question",
      textReply: { que: "Why do you think that is the case?" },
      userReply: { reply: null, userAvatar: "Happy" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'This is beacuse we made it mandatory for the contract to be initialized by passing the parameter "requireInit:true" to the "NearBindgen" decorator.',
        "Unless, the contract has been initialized, the no change methods on the smart contracts can be called",
        "Let's take a look how we can get around this.",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "StateInit2",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ContractMCQ2",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Previously, we took a detailed look at the how smart contracts and their methods work",
        "Let's take a look at overall structure of a smart contract to get a well-rounded idea.",
      ],
      primerAvatar: "search",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Anatomy",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "{{user}}, that brings us to the end of this lesson.",
        "In the next lesson, we will learn more about smart contracts in depth",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "wave",
    },
  },
];
