import React from "react";
import DashboardAppBar from "../Dashboard/DashboardAppBar";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import ShortPrimer, { contentTitle, contentMap } from "./ShortPrimer";

const GuidePage = (props) => {
  const { guideId } = useParams();
  const { context } = props;

  const guideExist = contentTitle.hasOwnProperty(guideId);

  React.useEffect(() => {
    if (guideExist) {
      document.title = `${contentTitle[guideId]} | NEAR Protocol Primer`;
    } else {
      document.title = `No Guide Found | NEAR Protocol Primer`;
    }
  }, []);

  if (!guideExist) {
    return (
      <React.Fragment>
        <DashboardAppBar context={context} />
        <div style={{ maxWidth: "900px", margin: "10px auto" }}>
          <h1>No Guide Found</h1>
        </div>
      </React.Fragment>
    );
  }

  if (guideExist) {
    const Component = contentMap[guideId];
    return (
      <React.Fragment>
        <DashboardAppBar context={context} />
        <div
          style={{ maxWidth: "900px", padding: "10px", margin: "10px auto" }}
        >
          {contentMap[guideId]}
        </div>
      </React.Fragment>
    );
  }
};

export default GuidePage;
