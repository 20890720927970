import ShortPrimer from "../ShortPrimers/ShortPrimer";

/* 
Terminal
CodeBlanks
CodeWalkthrough
DecoratorPrimer
*/

const Text = () => (
  <p>
    If you are not familiar with the concept of{" "}
    <span style={{ color: "salmon" }}>Decorators</span>, you can check out this
    short guide.
  </p>
);

export const DecoratorPrimer = () => (
  <ShortPrimer avatar="gentleman" text={Text} type="decorator" />
);

export const C = ({ children }) => (
  <span style={{ color: "salmon" }}>{children}</span>
);

export const B = ({ children }) => (
  <span style={{ color: "#454a75", fontWeight: "bold" }}>{children}</span>
);
