// Info
import POS from "./PrimerComponents/POS.mdx";
import Advantages from "./PrimerComponents/Advantages.mdx";
import NEAR from "./PrimerComponents/NEAR.mdx";
import NextLesson from "./PrimerComponents/NextLesson";
// Quiz
import POSMCQ from "./Quiz/POSMCQ";
import POSFillQuiz from "./Quiz/POSFillQuiz";

// Primers
import { start } from "./Primers/Start";

const primers = [...start];

const primerComponents = {
  POS: POS,
  POSMCQ: POSMCQ,
  Advantages: Advantages,
  POSFillQuiz: POSFillQuiz,
  NEAR: NEAR,
  NextLesson: NextLesson,
};

const imageComponents = [];

const quizList = ["POSMCQ", "POSFillQuiz"];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
