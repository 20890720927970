import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["Vector", "Lookup Map", "UnorderedMap", "Unordered Set"];
  const correctOption = "Unordered Set";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, let's say we wish to write a <b>smart contract</b>{" "}
            that three <b>change methods.</b> <br />
            <br />
            <div style={{ fontSize: "1rem" }}>
              We need to store the name of every <b>NEAR Protocol</b>{" "}
              <code>account</code> that has called the each of these methods in
              separate collection object. At the end the goal is find common{" "}
              <code>accounts</code> that called all three or at least two
              methods.
            </div>
            <br />
            Which of the following <b>SDK Collection</b> we can use to store the{" "}
            <code>account</code> names ?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
