import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["Miners", "Validators", "Cryptographers", "Near Security"];
  const correctOption = "Validators";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, since <b>NEAR Protocol</b> is a <b>Proof-of-Stake</b>{" "}
          blockchain, what are the participants who{" "}
          <b>secure and maintain the network</b> called?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
