import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";
import CodeComponent from "./CodeComponent.mdx";
import CodeComponent1 from "./CodeComponent1.mdx";

const MCQ = (props) => {
  const options = ["-3", "100", "0", "Cannot redeploy contract"];
  const correctOption = "-3";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, let's say our <code>Counter</code>{" "}
            <b>smart contract</b> has the following <b>current state</b> :
            <CodeComponent1 />
            We modify our <b>contract</b> to look as below:
            <div style={{ textAlign: "left" }}>
              <CodeComponent />
            </div>
            Once, we <b>compile</b> and <b>deploy</b> the{" "}
            <b>modified smart contract</b> to our account, what's the value of
            the <code>count</code> <b>state</b>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
