import React from "react";
import DashboardAppBar from "../../../../Dashboard/DashboardAppBar";
import { useAppContext } from "../../../../../AppContext";
import { Link } from "react-router-dom";
import LoveWall from "./LoveWallContainer";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const PostMessage = (props) => {
  const context = useAppContext();

  React.useEffect(() => {
    document.title = `Wall of Love | NEAR Protocol Primer`;
  }, []);
  return (
    <React.Fragment>
      <div style={{ margin: "auto", background: "darkslateblue" }}>
        <div
          style={{
            fontWeight: "bold",
            color: "floralwhite",
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <KeyboardBackspaceIcon />
          <Link style={{ color: "salmon", paddingLeft: "5px" }} to="/">
            Return Home
          </Link>
        </div>
        <h1
          style={{
            color: "floralwhite",
            textAlign: "center",
            fontFamily: "corben",
            marginTop: "0px",
          }}
        >
          Wall of Love
        </h1>
        <div>
          <LoveWall showAll={false} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PostMessage;
