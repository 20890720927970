import * as nearAPI from "near-api-js";

const { connect, keyStores, WalletConnection } = nearAPI;

const walletKeyStore = new keyStores.BrowserLocalStorageKeyStore(
  window.localStorage,
  "wall-near-api-js:keystore:"
);

export const connectionConfig = {
  networkId: "mainnet",
  keyStore: walletKeyStore, // first create a key store
  nodeUrl: "https://rpc.mainnet.near.org",
  walletUrl: "https://wallet.mainnet.near.org",
  helperUrl: "https://helper.mainnet.near.org",
  explorerUrl: "https://explorer.mainnet.near.org",
};

// export const connectionConfig = {
//   networkId: "testnet",
//   keyStore: walletKeyStore,
//   nodeUrl: "https://rpc.testnet.near.org",
//   walletUrl: "https://wallet.testnet.near.org",
//   helperUrl: "https://helper.testnet.near.org",
//   explorerUrl: "https://explorer.testnet.near.org",
// };

// // connect to NEAR
// export const nearConnection = await connect(connectionConfig);

// // create wallet connection
// export const walletConnection = new WalletConnection(
//   nearConnection,
//   "wall-of-love"
// );
