import React from "react";
import { Paper } from "@mui/material";

export const WalletPaper = () => (
  <React.Fragment>
    <Paper
      style={{
        background: "indigo",
        padding: "20px",
        margin: "20px auto",
        color: "floralwhite",
      }}
    >
      <div
        style={{
          margin: "0px 25px",
          textAlign: "center",
          lineHeight: "1.5rem",
        }}
      >
        The official hosted <b>NEAR Wallet</b> is{" "}
        <a
          style={{ color: "floralwhite" }}
          target="_blank"
          href="https://github.com/near/near-wallet"
        >
          open-source
        </a>{" "}
        and anyone can take a look at the code for the wallet's functionality.
        This wallet shouldn't be used as the only signer for high-value
        accounts. Make sure to use <b>hardware wallet</b> when substantial
        amount of <b>NEAR tokens</b> is involved.
      </div>
    </Paper>
  </React.Fragment>
);

export const GasPaper = () => (
  <Paper
    style={{
      background: "indigo",
      padding: "20px",
      margin: "auto",
      color: "floralwhite",
    }}
  >
    <div
      style={{
        margin: "0px 25px",
        textAlign: "center",
        lineHeight: "1.5rem",
        fontWeight: "bold",
      }}
    >
      Try to add a message on the{" "}
      <span style={{ color: "salmon" }}>counter</span> and then figure out how
      much it costs to add a message.
    </div>
  </Paper>
);
