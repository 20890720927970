import React from "react";
import { terminalStyle } from "./styles";
import generateMessage from "./TerminalMessages/generateMessage";
import addMessage from "./TerminalMessages/addMessage";
import removeMessage from "./TerminalMessages/removeMessage";

const getExplorableMessage = (explorable, props) =>
  ({
    GENERATE: generateMessage(props),
    ADD: addMessage(props),
    REMOVE: removeMessage(props),
  }[explorable]);

const Terminal = ({ explorable, ...props }) => {
  return (
    <div style={terminalStyle}>{getExplorableMessage(explorable, props)}</div>
  );
};

export default Terminal;
