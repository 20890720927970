import {
  StyledTableRow,
  StyledTableCell,
} from "../../../../../components/Common/Table.jsx";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";

const BlockTimeTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "900px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Operation
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              TGas
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              fees in milliⓃ
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              fees in Ⓝ
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>Create Account</code>
            </StyledTableCell>
            <StyledTableCell variant="body">0.42</StyledTableCell>
            <StyledTableCell variant="body">0.042 mⓃ</StyledTableCell>
            <StyledTableCell variant="body">0.000042 Ⓝ</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>Transfer Funds</code>
            </StyledTableCell>
            <StyledTableCell variant="body">0.45</StyledTableCell>
            <StyledTableCell variant="body">0.045 mⓃ</StyledTableCell>
            <StyledTableCell variant="body">0.000045 Ⓝ</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>Stake</code>
            </StyledTableCell>
            <StyledTableCell variant="body">0.50</StyledTableCell>
            <StyledTableCell variant="body">0.050 mⓃ</StyledTableCell>
            <StyledTableCell variant="body">0.000050 Ⓝ</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>Add Full Acccess Key</code>
            </StyledTableCell>
            <StyledTableCell variant="body">0.42</StyledTableCell>
            <StyledTableCell variant="body">0.042 mⓃ</StyledTableCell>
            <StyledTableCell variant="body">0.000042 Ⓝ</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>Delete Key</code>
            </StyledTableCell>
            <StyledTableCell variant="body">0.41</StyledTableCell>
            <StyledTableCell variant="body">0.041 mⓃ</StyledTableCell>
            <StyledTableCell variant="body">0.000041 Ⓝ</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlockTimeTable;
