import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "UnorderedMap is an iterable",
    "UnorderedMap are faster to work with",
    "UnorderedMap is easier to work with",
    "LookUpMaps is an iterable",
  ];
  const correctOption = "UnorderedMap is an iterable";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, we used the <code>UnorderedMap</code> collection to
            store the <code>reviewers</code> rating. What's the advantage of
            using <code>UnorderedMap</code> over <code>LookUpMap</code>{" "}
            collection object?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
