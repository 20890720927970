import { OutputFactory, Outputs } from "javascript-terminal";
import { CustomOutputFactory } from "../outputs";
import { arraysEqual, getURL, getTxId } from "./utils";

const helpText = `
**Usage**: &&near&& [[deploy]] **--accountId** @@accountId@@ **--wasmFile** @@wasmFile@@ **<options>**

    ^^Deploys a smart contract to a given accountId.^^

**Required Arguments**

    &&--accountId, --account_id, --contractName, --contract_name&&
        ^^Account name of contract  [string]^^

    &&--wasmFile, --wasm_file&&
        ^^Path to wasm file to deploy  [string] [default: "./out/main.wasm"]^^

**Optional Arguments**:
    @@--initFunction@@          ^^Initialization method ^^
    @@--initArgs@@              ^^Initialization arguments^^
    @@--initGas@@               ^^Gas for initialization call [number] [default: "1b48eb57e000"]^^
    @@--initDeposit@@           ^^Deposit in Ⓝ to send for initialization call  [string] [default: "0"]^^

**1. Minimal Example**
    
    &&near&& [[deploy]] --accountId example-contract.testnet --wasmFile out/example.wasm
        
**2. Initialize Example**

    &&near&& [[deploy]] **--accountId** @@example-contract.testnet@@ **--wasmFile** @@out/example.wasm@@ **--initFunction** @@new@@ **--initArgs** @@'{"owner_id": "example-contract.testnet", "total_supply": "10000000"}'@@
        

**Notes**:
    1. You will need a &&full access key for the account&& you are deploying the contract to. 
     
`;

const generateErrors = (errors) => {
  let mappedText = errors.reduce((acc, curr) => {
    return acc + `\n\t&&` + " * " + curr + `&&`;
  }, ``);
  return `**Encountered Following Errors**
${mappedText}

**Usage**: &&near&& [[deploy]] **--accountId** @@accountId@@ **--wasmFile** @@wasmFile@@ **<options>**

    For more info type:
        &&near&& [[deploy]] @@--help@@ 

`;
};

const getSuccessMessage = (res, accountId) => {
  const hash = getTxId(res);
  const message = `
    Transaction Id ${hash}
    To see the transaction in the transaction explorer, please open this url in your browser (((https://explorer.testnet.near.org/transactions/${hash})))

    Done deploying to [[${accountId}]]`;

  return message;
};

const wasmFileNotFound = `Specified Wasm file not found`;
const invalidCommandUsage = `Invalid Command Usage`;
const missingMaster = `Missing Master Account`;
const missingWasmFilePath = `Missing Wasm file path`;
const missingAccountID = `Missing Account ID`;
const invalidAccountId = `Invalid Account ID provided`;
const endWithTestnet = `Master account must end with testnet`;
const accEndWithTestnet = `Account Id must end with ".testnet"`;
const notLongEnough = `AccountId should be greater than 32 characters`;
const tooLong = `AccountId should be smaller than 64 characters`;
const invalidMasterAccount = `Invalid Master Account Id provided`;

const updateState = (type, message, setCustomState, setAcceptInput) => {
  if (type === "error") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(`&&${message}&&`)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  } else if (type === "success") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(message)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  }

  setAcceptInput(true);
};

export const deployCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  const { setAcceptInput, setCustomState } = stateChangeProps;
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(helpText),
    };
  } else {
    if (args.length === 1) {
      if (!options.accountId) {
        const errors = [missingAccountID];
        const errorText = generateErrors(errors);
        return {
          output: CustomOutputFactory.createColoredRecord(errorText),
        };
      } else if (!options.wasmFile) {
        const errors = [missingWasmFilePath];
        const errorText = generateErrors(errors);
        return {
          output: CustomOutputFactory.createColoredRecord(errorText),
        };
      } else {
        const accountId = options.accountId;
        const wasmFile = options.wasmFile;

        if (!getURL(wasmFile)) {
          const errors = [wasmFileNotFound];
          const errorText = generateErrors(errors);
          return {
            output: CustomOutputFactory.createColoredRecord(errorText),
          };
        }

        setAcceptInput(false);

        context
          .deployContract(accountId, wasmFile, {})
          .then((res) => {
            let type = res.type ? res.type : "success";
            const message = getTxId(res)
              ? getSuccessMessage(res, accountId)
              : JSON.stringify(res, null, 4);

            updateState(type, message, setCustomState, setAcceptInput);
          })
          .catch((e) => {
            let type = "error";
            let message = e.message;
            updateState(type, message, setCustomState, setAcceptInput);
          });

        return {
          output: CustomOutputFactory.createColoredRecord(
            `Starting deployment. Account id: [[${accountId}]], node: https://rpc.testnet.near.org, helper: https://helper.testnet.near.org, file: [[${wasmFile}]] ...`
          ),
        };
      }
    } else {
      const errors = [invalidCommandUsage];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    }
  }
};
