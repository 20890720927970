import React from "react";
import AccountKeysModal from "./AccountKeysModal";
import { HashTextField, CssTextField } from "../../../styledComponents";
import { Button } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import toast from "react-hot-toast";
import { checkAccountId, checkPublicKey } from "../../Utils/valid";

const AddKeyPair = ({
  accessKeys,
  setAccessKeys,
  changed,
  setChanged,
  useAccount,
  setUseAccount,
  useSeedphrase,
  setUseSeedphrase,
  accountId,
  setAccountId,
  seedphrase,
  setSeedphrase,
  testnet,
  setTestnet,
  message,
  setMessage,
  accountAccessKeys,
  setAccountAccessKeys,
  publicKeyText,
  setPublicKeyText,
  keyTypeFull,
  setKeyTypeFull,
  contractId,
  setContractId,
  allowance,
  setAllowance,
  methods,
  setMethods,
  handleReset,
}) => {
  const noAccountId = accountId.trim().length === 0;
  const noPublickey = publicKeyText.trim().length === 0;
  const noContract = contractId.trim().length === 0;

  const fullKeyDisabled = noAccountId || noPublickey;

  const functionKeyDisabled = fullKeyDisabled || noContract;

  const buttonDisabled = keyTypeFull ? fullKeyDisabled : functionKeyDisabled;

  const network = testnet ? "testnet" : "mainnet";

  const handleAddKey = () => {
    if (!checkAccountId(accountId)) {
      toast.error(`Account ID seems invalid`, {
        id: "invalidAccountID",
      });
      setMessage({ type: "invalidAccountID", accountId });
      return;
    }

    if (!checkPublicKey(publicKeyText)) {
      toast.error(`Public Key seems invalid`, {
        id: "invalidPublicKey",
      });
      setMessage({ type: "invalidPublicKey" });
      return;
    }

    // Check Account ID has a key in the access Keys

    const storedPublicKey = accessKeys.find(
      (x) => x.account_id === accountId && x.network === network
    );

    if (!storedPublicKey) {
      toast.error(
        `Cannot sign transactions for account ${accountId} on network ${network}, no matching key pair found`,
        {
          id: "NoAccountAccessKeyFound",
        }
      );
      setMessage({ type: "NoAccountAccessKeyFound", accountId, network });
      return;
    }

    const accountKeyOnChain = accountAccessKeys[network].map(
      (x) => x.public_key
    );

    const noMatchKey = accountKeyOnChain.some((x) =>
      x.includes(storedPublicKey.publicKey)
    );

    const alreadyExists = accountKeyOnChain.some((x) =>
      x.includes(publicKeyText)
    );

    if (!noMatchKey) {
      toast.error(
        `Cannot sign transactions for account ${accountId} on network ${network}, no matching key pair found`,
        {
          id: "NoMatchingAccessKeyFound",
        }
      );
      setMessage({ type: "NoMatchingAccessKeyFound", accountId, network });
      return;
    }

    if (alreadyExists) {
      toast.error(
        `The public key ${publicKeyText} is already used for an existing access key`,
        {
          id: "AlreadyExists",
          style: {
            wordBreak: "break-all",
          },
        }
      );
      setMessage({ type: "AlreadyExists", publicKey: publicKeyText });

      return;
    }

    if (keyTypeFull) {
      setAccountAccessKeys({
        ...accountAccessKeys,
        [network]: [
          ...accountAccessKeys[network],
          {
            access_key: {
              nonce: 92288354000004,
              permission: "FullAccess",
            },
            public_key:
              publicKeyText.length === 44
                ? `ed25519:${publicKeyText}`
                : publicKeyText,
          },
        ],
      });
      toast.success(
        `New access Key added to the ${accountId} on network ${network}`,
        {
          id: "NewAccessKeyAdded",
        }
      );
      setMessage({ type: "NewAccessKeyAdded", accountId, network });
      return;
    }

    // The access key has full permission and stored in the account Access Keys
    else if (!keyTypeFull) {
      if (!checkAccountId(contractId)) {
        toast.error(`Contract Account ID seems invalid`, {
          id: "invalidContractAccountID",
        });
        setMessage({ type: "invalidContractAccountID", accountId });
        return;
      }

      setAccountAccessKeys({
        ...accountAccessKeys,
        [network]: [
          ...accountAccessKeys[network],
          {
            access_key: {
              nonce: 92288371000000,
              permission: {
                FunctionCall: {
                  allowance:
                    allowance.length === 0
                      ? "250000000000000000000000"
                      : allowance,
                  method_names: methods.length > 0 ? methods.split(" ") : [],
                  receiver_id: contractId,
                },
              },
            },
            public_key:
              publicKeyText.length === 44
                ? `ed25519:${publicKeyText}`
                : publicKeyText,
          },
        ],
      });
      toast.success(
        `New access Key added to the ${accountId} on network ${network}`,
        {
          id: "NewAccessKeyAdded",
        }
      );
      setMessage({ type: "NewAccessKeyAdded", accountId, network });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "250px",
        margin: "10px auto",
      }}
    >
      <AccountKeysModal
        keys={accountAccessKeys}
        testnet={testnet}
        setTestnet={setTestnet}
      />
      <HashTextField
        label="Account ID"
        value={accountId}
        onChange={(evt) => {
          setAccountId(evt.target.value);
        }}
        style={{ margin: "10px" }}
      />
      <HashTextField
        label="Public Key"
        value={publicKeyText}
        onChange={(evt) => {
          setPublicKeyText(evt.target.value);
        }}
        style={{ margin: "10px" }}
      />
      <HashTextField
        disabled={keyTypeFull}
        label="Contract ID"
        value={contractId}
        onChange={(evt) => {
          setContractId(evt.target.value);
        }}
        style={{ margin: "10px" }}
      />
      <HashTextField
        disabled={keyTypeFull}
        label="Method Names"
        value={methods}
        onChange={(evt) => {
          setMethods(evt.target.value);
        }}
        style={{ margin: "10px" }}
      />
      <HashTextField
        type="number"
        disabled={keyTypeFull}
        label="Allowance"
        value={allowance}
        onChange={(evt) => {
          setAllowance(evt.target.value);
        }}
        style={{ margin: "10px" }}
      />
      <FormControlLabel
        style={{ margin: "5px auto" }}
        control={
          <Switch
            size="small"
            checked={keyTypeFull}
            onChange={(evt) => setKeyTypeFull(evt.target.checked)}
          />
        }
        label={
          <span
            style={{
              fontWeight: "bold",
              margin: "5px auto",
              fontSize: "12px",
            }}
          >
            {keyTypeFull
              ? "Add Full Access Key"
              : "Add Function Call Access Key"}
          </span>
        }
      />
      <FormControlLabel
        style={{ margin: "5px auto" }}
        control={
          <Switch
            size="small"
            checked={!testnet}
            onChange={(evt) => setTestnet(!evt.target.checked)}
          />
        }
        label={
          <span
            style={{
              fontWeight: "bold",
              margin: "5px auto",
              fontSize: "12px",
            }}
          >
            {testnet ? "NEAR Testnet" : "NEAR Mainnet"}
          </span>
        }
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "56px",
          margin: "5px auto",
        }}
      >
        <Button
          onClick={handleAddKey}
          disabled={buttonDisabled}
          variant="outlined"
          style={{ fontSize: "12px", width: "200px" }}
        >
          Add Access Key
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "36px",
          margin: "5px auto",
        }}
      >
        <Button
          onClick={handleReset}
          variant="outlined"
          style={{ fontSize: "12px" }}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default AddKeyPair;
