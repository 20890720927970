import { CustomOutputFactory } from "../outputs";
import { OptionParser, OutputFactory } from "javascript-terminal";
import { helpMenuText } from "../NEARTextContent/HelpMenuText";

import { createAccountCommand } from "./createAccount";
import { loginCommand } from "./loginCommand";
import { stateCommand } from "./stateCommand";
import { deleteCommand } from "./deleteCommand";
import { getkeysCommand } from "./getKeysCommand";
import { addKeyCommand } from "./addKeyCommand";
import { deleteKeyCommand } from "./deleteKeyCommand";
import { generateKeyCommand } from "./generateKeyCommand";
import { generateSeedphraseCommand } from "./generateSeedphraseCommand";
import { sendKeyCommand } from "./sendKeyCommand";
import { deployCommand } from "./deployCommand";
import { devDeployCommand } from "./devDeployCommand";
import { callCommand } from "./callCommand";
import { viewCommand } from "./viewCommand";
import { txStatusCommand } from "./txStatusCommand";
import { viewStateCommand } from "./viewStateCommand";
import { getFreeMoneyCommand } from "./getFreeMoneyCommand";

// Help Menu

const helpCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  return {
    output: CustomOutputFactory.createColoredRecord(helpMenuText),
  };
};

export const renderCommands = (arg) =>
  ({
    help: helpCommand,
    login: loginCommand,
    "create-account": createAccountCommand,
    state: stateCommand,
    delete: deleteCommand,
    keys: getkeysCommand,
    "generate-key": generateKeyCommand,
    "add-key": addKeyCommand,
    "delete-key": deleteKeyCommand,
    seedphrase: generateSeedphraseCommand,
    send: sendKeyCommand,
    deploy: deployCommand,
    "dev-deploy": devDeployCommand,
    call: callCommand,
    view: viewCommand,
    "tx-status": txStatusCommand,
    "view-state": viewStateCommand,
    "get-free-money": getFreeMoneyCommand,
  }[arg]);
