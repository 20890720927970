import React from "react";
import { HashTextField, CssTextField } from "../../../styledComponents";
import AccountKeysModal from "./AccountKeysModal";
import { Button } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import toast from "react-hot-toast";
import { checkAccountId, checkPublicKey } from "../../Utils/valid";

const RemoveKeyPair = ({
  accessKeys,
  setAccessKeys,
  changed,
  setChanged,
  useAccount,
  setUseAccount,
  useSeedphrase,
  setUseSeedphrase,
  accountId,
  setAccountId,
  seedphrase,
  setSeedphrase,
  testnet,
  setTestnet,
  message,
  setMessage,
  accountAccessKeys,
  setAccountAccessKeys,
  publicKeyText,
  setPublicKeyText,
  keyTypeFull,
  setKeyTypeFull,
  contractId,
  setContractId,
  allowance,
  setAllowance,
  methods,
  setMethods,
  handleReset,
}) => {
  const noAccountId = accountId.trim().length === 0;
  const noPublickey = publicKeyText.trim().length === 0;
  const buttonDisabled = noAccountId || noPublickey;

  const network = testnet ? "testnet" : "mainnet";

  const handleRemoveKey = () => {
    if (!checkAccountId(accountId)) {
      toast.error(`Account ID seems invalid`, {
        id: "invalidAccountID",
      });
      setMessage({ type: "invalidAccountID", accountId });
      return;
    }

    if (!checkPublicKey(publicKeyText)) {
      toast.error(`Public Key seems invalid`, {
        id: "invalidPublicKey",
      });
      setMessage({ type: "invalidPublicKey" });
      return;
    }

    //Check Account ID has a key in the access Keys
    const storedPublicKey = accessKeys.find(
      (x) => x.account_id === accountId && x.network === network
    );

    if (!storedPublicKey) {
      toast.error(
        `Cannot sign transactions for account ${accountId} on network ${network}, no matching key pair found`,
        {
          id: "NoAccountAccessKeyFound",
        }
      );
      setMessage({ type: "NoAccountAccessKeyFound", accountId, network });
      return;
    }

    const account_public_id = testnet
      ? "primerlabs.testnet"
      : "primerlabs.near";

    const publicKeyRemove = accountAccessKeys[network].find(
      (x) => accountId === account_public_id && x.public_key === publicKeyText
    );

    if (!publicKeyRemove) {
      toast.error(
        `Account ${accountId} tries to remove an access key that doesn't exist on the network ${network}`,
        {
          id: "NoPublicKeyAssociated",
        }
      );
      setMessage({ type: "NoPublicKeyAssociated", accountId, network });
      return;
    }

    const accountKeyOnChain = accountAccessKeys[network].map(
      (x) => x.public_key
    );

    const noMatchKey = accountKeyOnChain.some((x) =>
      x.includes(storedPublicKey.publicKey)
    );

    // const alreadyExists = accountKeyOnChain.some((x) =>
    //   x.includes(publicKeyText)
    // );

    if (!noMatchKey) {
      toast.error(
        `Cannot sign transactions for account ${accountId} on network ${network}, no matching key pair found`,
        {
          id: "NoMatchingAccessKeyFound",
        }
      );
      setMessage({ type: "NoMatchingAccessKeyFound", accountId, network });
      return;
    }

    // if (alreadyExists) {
    //   toast.error(
    //     `The public key ${publicKeyText} is already used for an existing access key`,
    //     {
    //       id: "AlreadyExists",
    //       style: {
    //         wordBreak: "break-all",
    //       },
    //     }
    //   );
    //   setMessage({ type: "AlreadyExists", publicKey: publicKeyText });

    //   return;
    // }

    if (1) {
      const newAccessKeys = accountAccessKeys[network].filter(
        (x) => x.accountId !== accountId && x.public_key !== publicKeyText
      );

      setAccountAccessKeys({
        ...accountAccessKeys,
        [network]: [...newAccessKeys],
      });

      toast.success(
        `Access Key removed from the ${accountId} on network ${network}`,
        {
          id: "AccessKeyRemove",
        }
      );
      setMessage({ type: "AccessKeyRemove", accountId, network });
      return;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "250px",
        margin: "10px auto",
      }}
    >
      <AccountKeysModal
        keys={accountAccessKeys}
        testnet={testnet}
        setTestnet={setTestnet}
      />

      <HashTextField
        label="Account ID"
        value={accountId}
        onChange={(evt) => {
          setAccountId(evt.target.value);
        }}
        style={{ margin: "10px" }}
      />
      <HashTextField
        label="Public Key"
        value={publicKeyText}
        onChange={(evt) => {
          setPublicKeyText(evt.target.value);
        }}
        style={{ margin: "10px" }}
      />
      <FormControlLabel
        style={{ margin: "5px auto" }}
        control={
          <Switch
            size="small"
            checked={!testnet}
            onChange={(evt) => setTestnet(!evt.target.checked)}
          />
        }
        label={
          <span
            style={{
              fontWeight: "bold",
              margin: "5px auto",
              fontSize: "12px",
            }}
          >
            {testnet ? "NEAR Testnet" : "NEAR Mainnet"}
          </span>
        }
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "56px",
          margin: "5px auto",
        }}
      >
        <Button
          onClick={handleRemoveKey}
          disabled={buttonDisabled}
          variant="outlined"
          style={{ fontSize: "12px", width: "200px" }}
        >
          Remove Access Key
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "36px",
          margin: "5px auto",
        }}
      >
        <Button
          onClick={handleReset}
          variant="outlined"
          style={{ fontSize: "12px" }}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default RemoveKeyPair;
