import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Public Key",
    "Account ID",
    "Allowance",
    "All of them are required arguments",
  ];
  const correctOption = "Allowance";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which of the following is not an required argument while
          adding an <code>function-call access key</code>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
