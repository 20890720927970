import { OutputFactory, Outputs } from "javascript-terminal";
import { CustomOutputFactory } from "../outputs";
import { arraysEqual, getURL, getTxId } from "./utils";
import { providers } from "near-api-js";

const helpText = `
**Usage**: &&near&& [[view]] @@<contractName>@@ @@<methodName>@@ **[args]** 

    ^^Makes a contract call which can only view state. (Call is free of charge)^^


**Required Arguments**

    &&<contractName>&&
        ^^Contract Name  [string]^^

    &&<methodName>&&
        ^^Method name to call [string]^^

     &&[args]&&
        ^^Arguments for the method [string]^^   


**Example:**

    &&near&& [[view]] @@guest-book.testnet@@ @@getMessages@@ @@'{}'@@ 
        

**Notes**:
    1. No account Id require since a call for viewing state of a contract is free of charge.
     
`;

const generateErrors = (errors) => {
  let mappedText = errors.reduce((acc, curr) => {
    return acc + `\n\t&&` + " * " + curr + `&&`;
  }, ``);
  return `**Encountered Following Errors**
${mappedText}

**Usage**: &&near&& [[view]] @@<contractName>@@ @@<methodName>@@ **[args]** 

    For more info type:
        &&near&& [[view]] @@--help@@ 

`;
};

const getErrorMessage = (res) => {
  const message = `&&${res.type}&&: An error occured.
  '${res.message}'
   `;
  return message;
};

const getSuccessMessage = (res) => {
  const message = `
[['${res}']]
 `;

  return message;
};

const wasmFileNotFound = `Specified Wasm file not found`;
const invalidCommandUsage = `Invalid Command Usage`;
const missingMaster = `Missing Master Account`;
const missingWasmFilePath = `Missing Wasm file path`;
const missingAccountID = `Missing Account ID`;
const invalidAccountId = `Invalid Account ID provided`;
const endWithTestnet = `Master account must end with testnet`;
const accEndWithTestnet = `Account Id must end with ".testnet"`;
const notLongEnough = `AccountId should be greater than 32 characters`;
const tooLong = `AccountId should be smaller than 64 characters`;
const invalidMasterAccount = `Invalid Master Account Id provided`;
const missingMethodName = `Missing Method name of the contract`;
const missingContractName = `Missing Contract name`;

const updateState = (type, message, setCustomState, setAcceptInput) => {
  if (type === "error") {
    setCustomState((prevState) => {
      const errorMessage = message.type
        ? getErrorMessage(message)
        : `&&${message}&&`;
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(`${errorMessage}`)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  } else if (type === "success") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(message)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  }

  setAcceptInput(true);
};

const getErrors = (args) => {
  switch (args.length) {
    case 0:
      return [missingContractName, missingMethodName];
    case 1:
      return [missingContractName, missingMethodName];
    case 2:
      return [missingMethodName];
  }
};

// Test

// near view test1.siddharthkanungo.testnet helloWorld

// near view test1.siddharthkanungo.testnet greet '{"names": "Siddharth"}'

export const viewCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  const { setAcceptInput, setCustomState } = stateChangeProps;
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(helpText),
    };
  } else {
    if (args.length < 3) {
      const errors = getErrors(args);
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if ([3, 4].includes(args.length)) {
      const [_, contractName, methodName, argString] = args;
      let targetArgs = "{}";
      if (argString && argString.trim().length !== 2) {
        const quoteRe = /(\x27)(.+?)(\x27)/gim;
        const quoteMatch = quoteRe.exec(argString);
        targetArgs = quoteMatch[2];
      }

      const commandOptions = {
        args: targetArgs,
        accountId: options.accountId || options.masterAccount || contractName,
      };

      setAcceptInput(false);
      context
        .callViewFunction(contractName, methodName, commandOptions)
        .then((res) => {
          let type = res.type ? res.type : "success";
          const message = getSuccessMessage(res);

          updateState(type, message, setCustomState, setAcceptInput);
        })
        .catch((e) => {
          let type = "error";
          updateState(type, e, setCustomState, setAcceptInput);
        });

      const message = `View call: [[${contractName}.${methodName}(${
        argString || ""
      })]]`;

      return {
        output: CustomOutputFactory.createColoredRecord(message),
      };
    } else {
      const errors = [invalidCommandUsage];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    }
  }
};
