import React from "react";
import { Paper, Box, Button } from "@mui/material";
import { sha256 } from "js-sha256";
import { CssTextField, HashTextField, StyledTooltip } from "./styledComponents";
import Slider from "@mui/material/Slider";
import LoadingButton from "@mui/lab/LoadingButton";
import { useWorker, WORKER_STATUS } from "@koale/useworker";
import { mineNonce } from "./workers";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import useSound from "use-sound";

const getNumberKey = (num) =>
  ({
    10: "a",
    11: "b",
    12: "c",
    13: "d",
    14: "e",
    15: "f",
  }[num]);

const convertHexaDecimal = (num) => {
  if (num < 10) {
    return `${num}`;
  } else {
    return getNumberKey(num);
  }
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      sx={{ color: "black", fontWeight: "bold" }}
      {...props}
    />
  );
});

const Block = () => {
  const [data, setData] = React.useState("Hi there!");
  const [block, setBlock] = React.useState(1);
  const [nonce, setNonce] = React.useState(452);
  const [error, setError] = React.useState(false);
  const [valid, setValid] = React.useState(false);
  const [diff, setDiff] = React.useState(4);
  const [digit, setDigit] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [playCorrect] = useSound("/sounds/correct.mp3", { volume: 0.1 });
  let blockhash = `block${block}nonce${nonce}data${data}hash`;
  const [mineWorker] = useWorker(mineNonce, {
    remoteDependencies: [
      "https://cdnjs.cloudflare.com/ajax/libs/js-sha256/0.9.0/sha256.min.js",
    ],
  });

  const checkValidity = (difficulty = 4) => {
    let pattern = convertHexaDecimal(digit).repeat(difficulty);
    return sha256(blockhash).substring(0, difficulty) === pattern;
  };

  React.useEffect(() => {
    if (checkValidity(diff)) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [block, nonce, data, diff, digit]);

  const runMining = async () => {
    setLoading(true);
    const result = await mineWorker(block, data, diff, digit);
    if (result !== -1) {
      setNonce(result);
      playCorrect();
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: "500px", margin: "30px auto" }}>
      <Paper
        elevation={3}
        sx={{
          padding: "30px",
          display: "flex",
          flexDirection: "column",
          background: valid ? "lightgreen" : "#ff7f5061",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <StyledTooltip
            title="Block Number which is usually an integer"
            placement="left"
          >
            <HashTextField
              type="number"
              label="Block"
              value={block}
              onChange={(evt) => setBlock(evt.target.value)}
            />
          </StyledTooltip>

          <StyledTooltip
            title="Nonce value which can be changed to find a valid block"
            placement="right"
          >
            <HashTextField
              label="Nonce"
              value={nonce}
              onChange={(evt) => setNonce(evt.target.value)}
            />
          </StyledTooltip>
        </Box>
        <br />
        <StyledTooltip title="Try changing the data field" placement="right">
          <CssTextField
            label="Data"
            multiline
            rows={4}
            value={data}
            onChange={(evt) => setData(evt.target.value)}
          />
        </StyledTooltip>
        <br />
        <StyledTooltip
          title="Hash value calculated from the block, nonce and the data"
          placement="right"
        >
          <HashTextField label="Hash Value" value={sha256(blockhash)} />
        </StyledTooltip>

        <span
          style={{
            margin: "10px auto",
            fontFamily: "Nunito",
            fontWeight: "bold",
            color: "#47307f",
            fontSize: "0.8rem",
          }}
        >
          Difficulty : {diff}
        </span>
        <StyledTooltip
          title={`Difficulty is ${diff}. Hash value should start with ${convertHexaDecimal(
            digit
          ).repeat(diff)} to be considered a valid block`}
          placement="right"
        >
          <Slider
            aria-label="Difficulty"
            value={diff}
            valueLabelDisplay="off"
            step={1}
            marks
            min={1}
            max={6}
            onChange={(evt) => setDiff(evt.target.value)}
          />
        </StyledTooltip>
        <span
          style={{
            margin: "10px auto",
            fontFamily: "Nunito",
            fontWeight: "bold",
            color: "#47307f",
            fontSize: "0.8rem",
          }}
        >
          Pattern : {convertHexaDecimal(digit).repeat(diff)}
        </span>
        <StyledTooltip
          title={`Digit selected is ${convertHexaDecimal(
            digit
          )}. Hash value should start with ${convertHexaDecimal(digit).repeat(
            diff
          )} to be considered a valid block`}
          placement="right"
        >
          <Slider
            aria-label="Digit"
            value={digit}
            valueLabelDisplay="off"
            step={1}
            marks
            min={0}
            max={15}
            onChange={(evt) => setDigit(evt.target.value)}
          />
        </StyledTooltip>
        <div style={{ padding: "10px", textAlign: "center" }}>
          <StyledTooltip
            title="Click to find the nonce for which the hash value is valid"
            placement="bottom"
          >
            <LoadingButton
              onClick={runMining}
              sx={{ height: "3rem" }}
              variant="outlined"
              loading={loading}
              loadingIndicator="Mining..."
            >
              {loading ? "Mining" : "Mine"}
            </LoadingButton>
          </StyledTooltip>
        </div>
      </Paper>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          sx={{
            width: "100%",
            color: "black",
            fontWeight: "bold",
            fontFamily: "Nunito",
          }}
        >
          Unable to find the nonce for a valid block. Try changing some data.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Block;
