import React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { StyledTableRow, StyledTableCell } from "../../../../Common/Table.jsx";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  IconButton,
} from "@mui/material";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const PUBLIC_KEY = "Public Key";
const SECRET_KEY = "Secret Key";
const ACCOUNT_ID = "Account ID";

const MAIN_ACCOUNT = "MAIN_ACCOUNT";
const SUB_ACCOUNT = "SUB_ACCOUNT";
const IMPLICIT_ACCOUNT = "IMPLICIT_ACCOUNT";
const DEFAULT = "DEFAULT";
const DEV = "DEV";

const getAccountType = (account, walletAccount) => {
  if (account === walletAccount) {
    return MAIN_ACCOUNT;
  } else if (account.endsWith(walletAccount)) {
    return SUB_ACCOUNT;
  } else if (account.length === 64) {
    return IMPLICIT_ACCOUNT;
  } else if (account.startsWith("dev")) {
    return DEV;
  } else {
    return DEFAULT;
  }
};

const getColor = (account, walletAccount) =>
  ({
    MAIN_ACCOUNT: "lightgreen",
    SUB_ACCOUNT: "wheat",
    IMPLICIT_ACCOUNT: "blanchedalmond",
    DEFAULT: "pink",
    DEV: "cornsilk",
  }[getAccountType(account, walletAccount)]);

const checkSubAcc = (account, masterAccount) => {
  return account.endsWith(masterAccount) && account !== masterAccount;
};

const AccessKeyTable = ({ keys = [], walletAccountId }) => {
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState(PUBLIC_KEY);

  const handleClick = (type) => {
    setType(type);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "900px",
        margin: "30px auto",
        maxHeight: 440,
      }}
    >
      <Table stickyHeader size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              NEAR Testnet
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody style={{ height: "300px", overFlowY: "a" }}>
          {keys.map((key, idx) => (
            <StyledTableRow
              key={`item-${key.id}-keyId`}
              sx={{ backgroundColor: getColor(key.accountId, walletAccountId) }}
            >
              <StyledTableCell variant="body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      fontSize: "smaller",
                      fontVariant: "all-small-caps",
                      letterSpacing: "2px",
                    }}
                  >
                    Account Id{" "}
                    <IconButton
                      onClick={() => {
                        handleClick(ACCOUNT_ID);
                        navigator.clipboard.writeText(key.accountId);
                      }}
                      size="small"
                    >
                      <ContentCopyIcon size="small" />
                    </IconButton>
                    <div style={{ display: "inline-block" }}>
                      {key.accountId === walletAccountId ? (
                        <Chip
                          sx={{ background: "chocolate" }}
                          style={{ marginRight: "5px" }}
                          size="small"
                          label="Logged In Account"
                          color="primary"
                        />
                      ) : (
                        ""
                      )}
                      {key.accountId.length === 64 ? (
                        <Chip
                          sx={{ background: "yellowgreen" }}
                          style={{ marginRight: "5px" }}
                          size="small"
                          label="implicit account"
                          color="primary"
                        />
                      ) : (
                        ""
                      )}
                      {checkSubAcc(key.accountId, walletAccountId) ? (
                        <Chip
                          sx={{ background: "salmon" }}
                          style={{ marginRight: "5px" }}
                          size="small"
                          label="sub-account"
                          color="primary"
                        />
                      ) : (
                        ""
                      )}
                      {key.accountId.startsWith("dev") &&
                      !key.accountId.endsWith(".testnet") ? (
                        <Chip
                          sx={{ background: "cadetblue" }}
                          style={{ marginRight: "5px" }}
                          size="small"
                          label="Dev Account"
                          color="primary"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </span>
                  <span style={{ fontSize: "small" }}>{key.accountId}</span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      fontSize: "smaller",
                      fontVariant: "all-small-caps",
                      letterSpacing: "2px",
                    }}
                  >
                    Public Key{" "}
                    <IconButton
                      onClick={() => {
                        handleClick(PUBLIC_KEY);
                        navigator.clipboard.writeText(key.publicKey);
                      }}
                      size="small"
                    >
                      <ContentCopyIcon size="small" />
                    </IconButton>
                  </span>
                  <span style={{ fontSize: "small" }}>{key.publicKey}</span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      fontSize: "smaller",
                      fontVariant: "all-small-caps",
                      letterSpacing: "2px",
                    }}
                  >
                    Secret Key{" "}
                    <IconButton
                      onClick={() => {
                        handleClick(SECRET_KEY);
                        navigator.clipboard.writeText(key.secretKey);
                      }}
                      size="small"
                    >
                      <ContentCopyIcon size="small" />
                    </IconButton>
                  </span>
                  <span style={{ fontSize: "small" }}>{key.secretKey}</span>
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%", fontWeight: "bolder" }}
        >
          {type} Copied to the clipboard
        </Alert>
      </Snackbar>
    </TableContainer>
  );
};

export default AccessKeyTable;
