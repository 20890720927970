import React from "react";
import { Paper } from "@mui/material";
import { PrettoSlider } from "../Canvas/StorageCanvas/Slider";

const priceRequest =
  "https://api.coingecko.com/api/v3/simple/price?ids=near&vs_currencies=usd";

const GasFeesExplorable = () => {
  const [gas, setGas] = React.useState(100);
  const [gasPrice, setGasPrice] = React.useState(0.2);
  const [calls, setCalls] = React.useState(50);
  const [fees, setFees] = React.useState(0);
  const [fetchPrice, setFetchPrice] = React.useState(null);

  React.useEffect(() => {
    setFees(calculateFees());
  }, [gas, gasPrice, calls]);

  const calculateFees = () => {
    return (gas * gasPrice * calls * 1000) / 1000;
  };

  React.useEffect(() => {
    const getNearPrice = async () => {
      const response = await fetch(priceRequest);
      const price = await response.json();
      setFetchPrice(price?.near?.usd);
    };

    getNearPrice();
  }, []);
  const developerFees = ((30 * fees) / 100).toFixed(2);
  return (
    <div style={{ margin: "20px auto" }}>
      <Paper style={{ background: "floralwhite", padding: "20px" }}>
        <h1 style={{ textAlign: "center", color: "indigo" }}>
          Smart Contract Reward Calculator
        </h1>
        <div style={{ margin: "20px auto", width: "400px" }}>
          <h4 style={{ textAlign: "center" }}>
            Gas Units used per call: {gas} TGas
          </h4>
          <PrettoSlider
            size="small"
            value={gas}
            valueLabelDisplay="auto"
            onChange={(evt) => {
              setGas(evt.target.value);
            }}
            step={1}
            min={1}
            max={300}
          />
        </div>
        <div style={{ margin: "20px auto", width: "400px" }}>
          <h4 style={{ textAlign: "center" }}>
            Gas Price per TGas: {gasPrice} milliⓃ
          </h4>
          <PrettoSlider
            size="small"
            value={gasPrice}
            valueLabelDisplay="auto"
            onChange={(evt) => {
              setGasPrice(evt.target.value);
            }}
            step={0.1}
            min={0.1}
            max={3}
          />
        </div>
        <div style={{ margin: "20px auto", width: "400px" }}>
          <h4 style={{ textAlign: "center" }}>
            Number of calls to Smart Contract: {calls}k
          </h4>
          <PrettoSlider
            size="small"
            value={calls}
            valueLabelDisplay="auto"
            onChange={(evt) => {
              setCalls(evt.target.value);
            }}
            step={10}
            min={10}
            max={5000}
          />
        </div>
        <div>
          <h3 style={{ textAlign: "center" }}>
            Transaction Fees Collected:{" "}
            <span style={{ color: "salmon" }}>{fees.toFixed(2)} Ⓝ </span>
          </h3>
          <h3 style={{ textAlign: "center" }}>
            Smart Contract Developer Reward:{" "}
            <span style={{ color: "indigo" }}>{developerFees} Ⓝ </span>
          </h3>
          {fetchPrice !== null && (
            <div>
              <h3 style={{ textAlign: "center" }}>
                Current NEAR price*:{" "}
                <span style={{ color: "indigo" }}>{fetchPrice} $</span>
              </h3>
              <h3 style={{ textAlign: "center" }}>
                Smart Contract Reward:{" "}
                <span style={{ color: "indigo" }}>
                  {(fetchPrice * developerFees).toFixed(2)} $
                </span>
              </h3>
              <span
                style={{
                  fontVariant: "all-small-caps",
                  letterSpacing: "2px",
                  fontSize: "smaller",
                }}
              >
                *Price fetched from{" "}
                <a
                  href="https://www.coingecko.com/en/coins/near"
                  target="_blank"
                >
                  coingecko
                </a>
              </span>
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default GasFeesExplorable;
