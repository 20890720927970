import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Verify authencity of the message's source",
    "Verify integrity of the message",
    "Verify the exact time of the sent message",
    "All of the above are use cases",
  ];
  const correctOption = "Verify the exact time of the sent message";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which of the following is <b>not</b> an use-case of{" "}
          <b>digital signatures</b>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
