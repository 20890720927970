import { CustomOutputFactory } from "../outputs";

// Login Command

const loginText = `[[Congratulations !!]] 

&&You have discovered a secret easter egg.&&

&&Redirecting to your reward now.&&.
`;

const loginHelpText = `
**Usage**: &&near&& [[get-free-money]]

    ^^Get Free Ⓝ NEAR tokens^^
`;
export const getFreeMoneyCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(loginHelpText),
    };
  }
  window.open("https://www.youtube.com/watch?v=dQw4w9WgXcQ", "_newtab");
  return {
    output: CustomOutputFactory.createColoredRecord(loginText),
  };
};
