import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AntTabs, AntTab } from "./Tabs";
{
  /* <span style={{ fontSize: "3rem" }}>{"\u201C"}</span>
 <span style={{ fontSize: "3rem" }}>{"\u201D"}</span> */
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography
            component={`div`}
            sx={{ color: "#47307f", display: "flex" }}
          >
            <span style={{ fontSize: "80px", margin: "0px", padding: "0px" }}>
              {"\u201C"}
            </span>
            <blockquote style={{ paddingTop: "10px", fontSize: "small" }}>
              {children}
            </blockquote>
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabPanelStyles = {
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  color: "#47307f",
};

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        border: "none",
        maxHeight: 424,
      }}
    >
      <AntTabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderBottom: "none",
          borderRight: 1,
          borderColor: "divider",
          minWidth: "250px",
        }}
      >
        <AntTab label="Settlement" {...a11yProps(0)} />
        <AntTab label="Maintenance" {...a11yProps(1)} />
        <AntTab label="Decentralised" {...a11yProps(2)} />
        <AntTab label="Account Names" {...a11yProps(3)} />
        <AntTab label="Account Access" {...a11yProps(4)} />
        <AntTab label="Consensus" {...a11yProps(5)} />
      </AntTabs>
      <TabPanel style={tabPanelStyles} value={value} index={0}>
        <b>
          How can we verify if a person has actually settled according to the
          transaction recorded in the ledger?
        </b>
        <br />
        <br />
        We solve this by introducing a <b>scarce digital asset</b> or{" "}
        <b>token</b> which can only be generated by the network and can only be
        utilised in the ledger itself.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={1}>
        <b>
          How to incentivise the participants maintaining infrastructure running
          the decentralised ledger to function honestly?
        </b>
        <br />
        <br />
        We can do this by <b>rewarding the maintainers</b> for their good
        behaviour and penalizing them for the bad behaviour.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={2}>
        <b>
          How can we design the system to be <b>distributed</b> and{" "}
          decentralised, so that the transactions can not be interrupted by
          malicious actors or any government?
        </b>
        <br />
        <br />
        We do this by creating a protocol by allowing anyone to run the{" "}
        <b>nodes</b> anonymously all over the world built on{" "}
        <b>peer-to-peer networking.</b>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={3}>
        <b>
          How to develop an <b>account creation mechanism</b> which can support
          the needs of <b>7 billion people</b> while also well-architectured
          enough to support the needs of <b>future generation</b>?
        </b>
        <br />
        <br />
        We allow everyone to generate their own account names by using{" "}
        <b>private/public keypairs.</b>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={4}>
        <b>
          {" "}
          How do we ensure that the only person who can use the funds in the
          accounts is <b>the account holder</b> and{" "}
          <b>no other entity can edit the account</b>, not even the people
          running the infrastructure?
        </b>
        <br />
        <br />
        Once again, <b>public key cryptography</b> allows only the owner for the
        account to use the funds associated with the account.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={5}>
        <b>
          How do we ensure that the{" "}
          <b>
            correct copy of ledger is unanimously agreed upon by the all the
            actors
          </b>
        </b>
        ?
        <br />
        <br />
        This is quite a difficult problem that we will deep dive into in the
        next section.
      </TabPanel>
    </Box>
  );
}
