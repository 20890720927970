import React, { Component, useCallback, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { shuffleArray } from "../../../utils";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (
  isDragging,
  draggableStyle,
  checking,
  pos,
  completed,
  skip
) => {
  return {
    // some basic styles to make the items look a bit nicer
    pointerEvents: skip || completed ? "none" : "inherit",
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    color: isDragging ? "white" : "#000000cc",
    // borderTopColor: checking || isDragging ? "transparent" : "gray",
    // borderLeftColor: checking || isDragging ? "transparent" : "gray",
    // borderRightColor: checking || isDragging ? "transparent" : "gray",
    borderBottom: "4px solid gray",
    textAlign: "center",
    padding: "15px",
    fontSize: "1rem",
    fontFamily: "Roboto",

    // change background colour if dragging
    background: isDragging
      ? "#47307fdb"
      : checking && pos
      ? "#58cb05"
      : checking && !pos
      ? "#F5CAC3"
      : "transparent",

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

const getListStyle = (isDraggingOver) => ({
  background: "transparent",
  padding: grid,
  borderRadius: "12px",
  padding: "20px",
});

function delay(fn, time = 300) {
  return new Promise((resolve) => {
    setTimeout(() => {
      fn();
      resolve();
    }, time);
  });
}

function noop() {}

const Reorder = ({
  items,
  setItems,
  checking,
  completed,
  skip,
  checkItems,
  setCompleted,
  playLevelCompleted,
}) => {
  let api;
  const useMyCoolSensor = (value) => {
    api = value;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setItems(reorder(items, result.source.index, result.destination.index));
  };

  React.useEffect(() => {
    if (skip) {
      moveItems();
    }
  }, [skip]);

  async function moveItems() {
    let copyItems = items;
    const itemLength = items.length;
    for (let i = 0; i < 6; i++) {
      const currentIndex = copyItems.findIndex((x) => `item-${i}` === x.id);
      if (Math.abs(currentIndex - i)) {
        await start(currentIndex, i, copyItems);
      }
      await copyItems.splice(i, 0, copyItems.splice(currentIndex, 1)[0]);
      if (checkItems(copyItems)) {
        setCompleted(true);
        playLevelCompleted();
        break;
      }
    }
  }

  async function start(curr, index, copyItems) {
    const preDrag = api.tryGetLock(copyItems[curr].id, noop);

    if (!preDrag) {
      console.warn("unable to start drag");
      return;
    }

    const actions = preDrag.snapLift();
    const { moveDown, moveUp, drop } = actions;

    for (let i = 0; i < Math.abs(curr - index); i++) {
      await delay(moveUp);
    }

    await delay(drop);
    await delay(noop);
    await delay(noop);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd} sensors={[useMyCoolSensor]}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index) => {
              return (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className="Reorder"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                        checking,
                        `item-${index}` === item.id,
                        completed,
                        skip
                      )}
                    >
                      {item.content}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Reorder;
