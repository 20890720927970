import React from "react";
import { Paper } from "@mui/material";
import anime from "animejs";
import { primerComponents } from "../../AppContextData/Data";

const PrimerQuiz = (props) => {
  let Component =
    primerComponents[props.questId][props.lessonId][props.content];

  return (
    <React.Fragment>
      <Component {...props} />
    </React.Fragment>
  );
};

export default PrimerQuiz;
