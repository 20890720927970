import React from "react";
import Typography from "@mui/material/Typography";
import useSound from "use-sound";
import { motion } from "framer-motion";
import { shuffleArray } from "../../utils";
import ValueOrder from "./components/MatchValueOrder";
import KeyOrder from "./components/MatchKeyOrder";
import MatchDialog from "./components/MatchDialog";
import MatchDialogPrimer from "./components/MatchDialogPrimer";
import { CenteredLayout } from "./layouts/Layout";

const getItems = (arr) =>
  arr.map((item, i) => ({ id: `item-${i}`, content: item }));

const MatchQuiz = ({
  large = false,
  progress,
  progressIndex,
  setProgress,
  setProgressIndex,
  matchOptions,
  setProgressState,
  send,
  currentlyActive,
  primers,
  success,
  fail,
  ...props
}) => {
  const [showDialog, setShowDialog] = React.useState(false || props.showDialog);

  const [keys, setKeys] = React.useState(
    currentlyActive || showDialog
      ? shuffleArray(getItems(Object.keys(matchOptions)))
      : getItems(Object.keys(matchOptions))
  );
  const [values, setValues] = React.useState(
    currentlyActive || showDialog
      ? shuffleArray(getItems(Object.values(matchOptions)))
      : getItems(Object.values(matchOptions))
  );

  const [order, setOrder] = React.useState({});
  const [kee, setKey] = React.useState(null);
  const [value, setValue] = React.useState(null);
  const [skip, setSkip] = React.useState(false);
  const [completed, setCompleted] = React.useState(
    currentlyActive || showDialog ? false : true
  );
  const [checking, setChecking] = React.useState(
    currentlyActive || showDialog ? false : true
  );
  const [busyKey, setBusyKey] = React.useState(false);
  const [busyValue, setBusyValue] = React.useState(false);
  const [playWrong] = useSound("/sounds/wrong.mp3", { volume: 0.1 });
  const [playLevelCompleted] = useSound("/sounds/level_completed2.wav", {
    volume: 0.1,
  });
  const [playCorrect] = useSound("/sounds/correct.mp3", { volume: 0.1 });

  React.useEffect(() => {
    if (!skip) {
      setValue(null);
    }

    if (Object.keys(order).length === Object.keys(matchOptions).length) {
      playLevelCompleted();
      setCompleted(true);
      if (!skip) {
        setProgressState("success");
      }
    }
  }, [order]);

  const handleSkip = () => {
    setKeys(Object.keys(props.matchOptions));
    setValues(Object.values(props.matchOptions));
    playWrong();
  };

  const DialogProps = {
    progress,
    progressIndex,
    setProgress,
    setProgressIndex,
    completed,
    checking,
    setChecking,
    skip,
    setSkip,
    playWrong,
    busyKey,
    busyValue,
    setProgressState,
    primers,
    success,
    fail,
  };

  const DialogPrimerProps = {
    progress,
    progressIndex,
    setProgress,
    setProgressIndex,
    completed,
    checking,
    setChecking,
    skip,
    setSkip,
    playWrong,
    busyKey,
    busyValue,
    setProgressState,
    send,
    success,
    fail,
  };

  const ReorderProps = {
    kee,
    keys,
    value,
    values,
    order,
    skip,
    setValues,
    setKey,
    setKeys,
    setOrder,
    setValue,
    matchOptions,
    playCorrect,
    playWrong,
    playLevelCompleted,
    setBusyKey,
    setBusyValue,
    busyKey,
    busyValue,
    completed,
    large,
  };

  return (
    <div>
      <CenteredLayout>
        <div style={{ padding: "20px" }}>
          <Typography
            variant="h4"
            component="div"
            style={{ textAlign: "center" }}
          >
            {props.header}
          </Typography>
          <br />
          <Typography variant="quiz" component="div">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "300px",
                  alignItems: "center",
                }}
              >
                <KeyOrder {...ReorderProps} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "400px",
                  alignItems: "center",
                }}
              >
                <ValueOrder {...ReorderProps} />
              </div>
            </div>
          </Typography>
        </div>
        <div>
          {currentlyActive && <MatchDialogPrimer {...DialogPrimerProps} />}
        </div>
      </CenteredLayout>

      {showDialog && (
        <motion.div
          animate={{ opacity: [0, 0, 0, 1] }}
          transition={{ duration: 1 }}
        >
          <MatchDialog {...DialogProps} />
        </motion.div>
      )}
    </div>
  );
};

export default MatchQuiz;
