import { Paper } from "@mui/material";

export const DiaryLedger = () => (
  <Paper
    style={{
      background: "indigo",
      padding: "20px",
      margin: "10px auto",
      color: "floralwhite",
    }}
  >
    <div
      style={{ margin: "0px 25px", textAlign: "left", lineHeight: "1.5rem" }}
    >
      <ul>
        <li>
          <b>Wednesday, September 12</b>
          <br />
          <p>
            In exchange for <b>three dozens of bananas</b>, which I provided
            today,
            <b> Hari Krishna (fisherman)</b> promised{" "}
            <b>2 kilos of Rohu fish</b> by the end of this month.
          </p>
        </li>
        <li>
          <b>Saturday, September 15</b>
          <br />
          <p>
            <b>Venkat Raman (farmer)</b> provided <b>three bags of rice</b>{" "}
            which he had promised last month for taking <b>5 liters of milk</b>.
          </p>
        </li>
      </ul>
    </div>
  </Paper>
);
