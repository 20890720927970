import { OutputFactory, Outputs } from "javascript-terminal";
import { CustomOutputFactory } from "../outputs";
import { arraysEqual, getURL, getTxId } from "./utils";

const helpText = `
**Usage**: &&near&& [[dev-deploy]] **--wasmFile** @@wasmFile@@ **<options>**

    ^^deploy your smart contract using temporary account (TestNet only)^^

**Required Arguments**

    &&--wasmFile, --wasm_file&&
        ^^Path to wasm file to deploy  [string] [default: "./out/main.wasm"]^^

**Optional Arguments**:
    @@--initFunction@@          ^^Initialization method ^^
    @@--initArgs@@              ^^Initialization arguments^^
    @@--initGas@@               ^^Gas for initialization call [number] [default: "1b48eb57e000"]^^
    @@--initDeposit@@           ^^Deposit in Ⓝ to send for initialization call  [string] [default: "0"]^^

**1. Minimal Example**
    
    &&near&& [[dev-deploy]] --wasmFile out/example.wasm
        
**2. Initialize Example**

    &&near&& [[dev-deploy]] **--wasmFile** @@out/example.wasm@@ **--initFunction** @@new@@ **--initArgs** @@'{"owner_id": "example-contract.testnet", "total_supply": "10000000"}'@@
        

**Notes**:
    1. You will need a &&full access key for the account&& you are deploying the contract to. 
     
`;

const generateErrors = (errors) => {
  let mappedText = errors.reduce((acc, curr) => {
    return acc + `\n\t&&` + " * " + curr + `&&`;
  }, ``);
  return `**Encountered Following Errors**
${mappedText}

**Usage**: &&near&& [[dev-deploy]] **--wasmFile** @@wasmFile@@ **<options>**

    For more info type:
        &&near&& [[dev-deploy]] @@--help@@ 

`;
};

const getSuccessMessage = (res, accountId, wasmFile, previous) => {
  const hash = getTxId(res);
  const previousCreatedAccount = previous
    ? `@@Found previously created dev-account@@`
    : "";
  const message = `${previousCreatedAccount}
  Account id: [[${accountId}]], node: (((https://rpc.testnet.near.org))), helper: (((https://helper.testnet.near.org))), file: [[${wasmFile}]]

    Transaction Id ${hash}
    To see the transaction in the transaction explorer, please open this url in your browser (((https://explorer.testnet.near.org/transactions/${hash})))

    Done deploying to [[${accountId}]]`;

  return message;
};

const wasmFileNotFound = `Specified Wasm file not found`;
const invalidCommandUsage = `Invalid Command Usage`;
const missingMaster = `Missing Master Account`;
const missingWasmFilePath = `Missing Wasm file path`;
const missingAccountID = `Missing Account ID`;
const invalidAccountId = `Invalid Account ID provided`;
const endWithTestnet = `Master account must end with testnet`;
const accEndWithTestnet = `Account Id must end with ".testnet"`;
const notLongEnough = `AccountId should be greater than 32 characters`;
const tooLong = `AccountId should be smaller than 64 characters`;
const invalidMasterAccount = `Invalid Master Account Id provided`;

const updateState = (type, message, setCustomState, setAcceptInput) => {
  if (type === "error") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(`&&${message}&&`)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  } else if (type === "success") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(message)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  }

  setAcceptInput(true);
};

export const devDeployCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  const { setAcceptInput, setCustomState } = stateChangeProps;
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(helpText),
    };
  } else {
    if (args.length === 1) {
      if (!options.wasmFile) {
        const errors = [missingWasmFilePath];
        const errorText = generateErrors(errors);
        return {
          output: CustomOutputFactory.createColoredRecord(errorText),
        };
      } else {
        const wasmFile = options.wasmFile;

        if (!getURL(wasmFile)) {
          const errors = [wasmFileNotFound];
          const errorText = generateErrors(errors);
          return {
            output: CustomOutputFactory.createColoredRecord(errorText),
          };
        }

        setAcceptInput(false);

        context
          .devDeployContract(wasmFile, { masterAccount: options.masterAccount })
          .then(({ response, accountId, previous }) => {
            let type = response.type ? response.type : "success";
            const message = getTxId(response)
              ? getSuccessMessage(response, accountId, wasmFile, previous)
              : JSON.stringify(response, null, 4);

            updateState(type, message, setCustomState, setAcceptInput);
          })
          .catch((e) => {
            let type = "error";
            let message = e.message;
            updateState(type, message, setCustomState, setAcceptInput);
          });

        return {
          output:
            CustomOutputFactory.createColoredRecord(`Starting deployment.`),
        };
      }
    } else {
      const errors = [invalidCommandUsage];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    }
  }
};
