import React, { useRef, useEffect } from "react";
import { Paper } from "@mui/material";
import anime from "animejs";
import { primerComponents } from "../../../AppContextData/Data";

const PrimerInfo = ({ content, send, ...props }) => {
  const animation = useRef(null);
  const infoContainer = useRef(null);
  let Component = primerComponents[props.questId][props.lessonId][content];
  const currentlyActive = props.contextProgress === props.index;
  useEffect(() => {
    if (currentlyActive) {
      animation.current = anime.timeline({
        loop: false,
        autoplay: true,
      });

      animation.current.add({
        targets: infoContainer.current,
        opacity: [0, 1],
        translateY: [25, 0],
        begin: () => {
          infoContainer.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        },
        complete: () => {
          send("NEXT");
        },
      });
    }
  }, []);

  return (
    <Paper ref={infoContainer} style={{ margin: "30px", padding: "30px" }}>
      <Component currentlyActive={currentlyActive} {...props} />
    </Paper>
  );
};

export default PrimerInfo;
