import React from "react";
import { PrettoSlider } from "../../Canvas/StorageCanvas/Slider";

const getText = (num) => {
  if (num === 1) {
    return "Fully Centralised";
  } else if (num > 1 && num < 20) {
    return "Somewhat Centralised";
  } else if (num >= 20 && num < 99) {
    return "Somewhat Decentralised";
  } else if (num >= 99 && num < 150) {
    return "Practically Decentralised";
  } else if (num >= 150) {
    return "Highly Decentralised";
  }
};

const Spectrum = () => {
  const [num, setNum] = React.useState(1);

  return (
    <div style={{ maxWidth: "600px", margin: "0 auto", textAlign: "center" }}>
      <h2 style={{ textAlign: "center", color: "indigo" }}>{getText(num)}</h2>
      <span style={{ fontWeight: "bold", textAlign: "center" }}>
        Number of actors to corrupt the underlying data :{" "}
        <span style={{ color: "indigo" }}>{num}</span>
      </span>
      <PrettoSlider
        size="small"
        value={num}
        onChange={(evt) => {
          setNum(evt.target.value);
        }}
        valueLabelDisplay="auto"
        step={1}
        min={1}
        max={300}
      />
    </div>
  );
};

export default Spectrum;

/*
    1. Fully Centralised 1
    2. Somewhat Centralised 1-20
    3. Somewhat Decentralised 20-99
    4. Practically Decentralised 100 - 149
    5. Highly Decentralised - 150 
*/
