import { remapQuiz } from "../../utils";

// BASICS OF NEAR PROTOCOL
import introduction from "./Basics/Introduction";
import decentralisedComputing from "./Basics/DecentralisedCloudComputing";
import nightshade from "./Basics/Nightshade";
import decentralisedApplications from "./Basics/DecentralisedApplications";

// Command Line Interface
import accountModel from "./CommandLine/AccountModel";
import accessKeys from "./CommandLine/AccessKeys";
import transactions from "./CommandLine/Transactions";
import accessKeysRotation from "./CommandLine/AccessKeysRotation";
import smartContracts from "./CommandLine/SmartContracts";
/*
    1. Account Model 
    2. Access Keys
    3. Transactions
    4. Access Keys Rotation
    6. Smart Contracts
    7. Gas Price ( Later )
*/

export const primerComponents = {
  introduction: introduction.primerComponents,
  "decentralised-cloud-computing": decentralisedComputing.primerComponents,
  nightshade: nightshade.primerComponents,
  "decentralised-applications": decentralisedApplications.primerComponents,
  "account-model": accountModel.primerComponents,
  "access-keys": accessKeys.primerComponents,
  transactions: transactions.primerComponents,
  "access-keys-rotation": accessKeysRotation.primerComponents,
  "smart-contracts": smartContracts.primerComponents,
};

export const quizList = {
  introduction: remapQuiz(introduction.quizList),
  "decentralised-cloud-computing": remapQuiz(decentralisedComputing.quizList),
  nightshade: remapQuiz(nightshade.quizList),
  "decentralised-applications": remapQuiz(decentralisedApplications.quizList),
  "account-model": remapQuiz(accountModel.quizList),
  "access-keys": remapQuiz(accessKeys.quizList),
  transactions: remapQuiz(transactions.quizList),
  "access-keys-rotation": remapQuiz(accessKeysRotation.quizList),
  "smart-contracts": remapQuiz(smartContracts.quizList),
};

export const imageComponents = {
  introduction: introduction.imageComponents,
  "decentralised-cloud-computing": decentralisedComputing.imageComponents,
  nightshade: nightshade.imageComponents,
  "decentralised-applications": decentralisedApplications.imageComponents,
  "account-model": accountModel.imageComponents,
  "access-keys": accessKeys.imageComponents,
  transactions: transactions.imageComponents,
  "access-keys-rotation": accessKeysRotation.imageComponents,
  "smart-contracts": smartContracts.imageComponents,
};

const main = {
  primerComponents,
  imageComponents,
  quizList,
};

export default main;

export const nearProtocol = [
  {
    group: 1,
    key: "basics",
    name: "Basics of NEAR Protocol",
    topicOrder: [[1], [2, 3]],
    background: "wheat",
    topics: [
      {
        id: 1,
        title: "Introduction",
        stars: "0",
        image: "near",
        slug: "introduction",
        progress: 0,
        wrong: 0,
        primers: introduction.primers,
      },
      {
        id: 2,
        title: "Decentralised Cloud-Computing",
        stars: "0",
        image: "near",
        slug: "decentralised-cloud-computing",
        progress: 0,
        wrong: 0,
        primers: decentralisedComputing.primers,
      },
      // {
      //   id: 3,
      //   title: "Nightshade",
      //   stars: "0",
      //   image: "near",
      //   slug: "nightshade",
      //   progress: 0,
      //   wrong: 0,
      //   primers: nightshade.primers,
      // },
      {
        id: 3,

        title: "Decentralised Applications",
        stars: "0",
        image: "near",
        slug: "decentralised-applications",
        progress: 0,
        wrong: 0,
        primers: decentralisedApplications.primers,
      },
    ],
  },
  {
    group: 2,
    key: "cli",
    name: "Command Line Interface",
    topicOrder: [
      [1, 2, 3],
      [4, 5],
    ],
    background: "darkslateblue",
    topics: [
      {
        id: 1,
        title: "Account model",
        stars: "0",
        image: "near",
        slug: "account-model",
        progress: 0,
        wrong: 0,
        primers: accountModel.primers,
      },
      {
        id: 2,
        title: "Access Keys",
        stars: "0",
        image: "near",
        slug: "access-keys",
        progress: 0,
        wrong: 0,
        primers: accessKeys.primers,
      },
      {
        id: 3,
        title: "Transactions",
        stars: "0",
        image: "near",
        slug: "transactions",
        progress: 0,
        wrong: 0,
        primers: transactions.primers,
      },
      {
        id: 4,
        title: "Access Keys Rotations",
        stars: "0",
        image: "near",
        progress: 0,
        wrong: 0,
        slug: "access-keys-rotation",
        primers: accessKeysRotation.primers,
      },

      {
        id: 5,
        title: "Smart Contracts",
        stars: "0",
        image: "near",
        progress: 0,
        wrong: 0,
        slug: "smart-contracts",
        primers: smartContracts.primers,
      },
    ],
  },
];
