import React from "react";
import { ReorderQuiz } from "../../../../../components/Quiz/ReorderQuiz";

const itemsList = [
  "NEAR CLI generates a keypair",
  "The newly generated keypair is stored in the near-credentials folder",
  "NEAR-CLI issues a transaction request to the add the newly generated  into your account",
  "You are directed to your wallet to authorize the transaction using an existing key",
  "Transaction request is succesful",
  "You can start using the NEAR-CLI to sign transactions on your behalf.",
];

const ReorderTemplate = (props) => (
  <ReorderQuiz
    itemsList={itemsList}
    header={
      <React.Fragment>
        Can you rearrange the process in which the <code>NEAR-CLI</code> allows
        you to login into your account while you use the <code>near login</code>{" "}
        command?
      </React.Fragment>
    }
    {...props}
  />
);

export default ReorderTemplate;
