import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Hash function returns different value from the same input",
    "A slight change in the original input results in slight change in the hash value",
    "It's easy to find two pieces of input for which the hash value are same",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which of the following is not a property of the
          <b> SHA-256</b> hash function?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
