import { OutputFactory, Outputs } from "javascript-terminal";
import { CustomOutputFactory } from "../outputs";
import { arraysEqual } from "./utils";

const helpText = `
**Usage**: &&near&& [[keys]] @@<accountId>@@

    ^^view account public keys^^
    
    For e.g., if you would like to see the access keys of an account &&nearprotocolprimer.testnet&&. You can do so by using following command: 
        
        &&near&& [[keys]] @@nearprotocolprimer.testnet@@


**Notes**:
    1. Login is &&not required&& to see the list of keys of an account 
     
`;

const generateKeyHelpText = `
**Usage**: &&near&& [[generate-key]] @@<accountId>@@

    ^^generate key or show key from Ledger^^
    
    For e.g., if you would like to see the access keys of an account &&nearprotocolprimer.testnet&&. You can do so by using following command: 
        
        &&near&& [[keys]] @@nearprotocolprimer.testnet@@


**Notes**:
    1. Login is &&not required&& to see the list of keys of an account 
    2. Show key from Ledger is available at [[near-cli]] not in this &&near-cli-emulator&&
     
`;
const addKeyHelpText = `
**Usage**: &&near&& [[add-key]] @@<accountId>@@ @@<access-key>@@

    ^^Add an access key to given account^^

There are two types of access-keys:
  - [[Full Access Key]]
  - [[Function Access Key]]

[[Full Access Key]]:  

  &&Arguments:&& [[accountId]] [[publicKey]]

  For e.g., if you would like to add a full access key to an **accountId** &&nearprotocolprimer.testnet&&. You can do so by first generating a keypair using [[near generate-key]] command. 
    
      &&near&& [[add-key]] @@nearprotocolprimer.testnet@@ **<Generated Public Key>**

[[Function Access Key]]:  

  &&Arguments:&& [[accountId]] [[publicKey]] @@--contract-id@@ **<options>**

  &&Options:&& @@--method-names@@ @@--allowance@@

      [[--contract-id]] ^^is the contract you are allowing methods to be called on^^

      [[--method-names]] ^^are optional and if omitted, all methods of the --contract-id can be called.^^

      [[--allowance]] ^^is the amount of Ⓝ the key is allowed to spend on gas fees only. If omitted then key will only be able to call view methods.^^

[[Note: Each transaction made with this key will have gas fees deducted from the initial allowance and once it runs out a new key must be issued.]]

  For e.g., if you would like to add a function access key to an **accountId** &&nearprotocolprimer.testnet&&. You can do so by first generating a keypair using [[near generate-key]] command.  
        
      &&near&& [[add-key]] @@nearprotocolprimer.testnet@@ **<Generated Public Key>** **--contract-id** [[example-contract.testnet]] **--method-names** example_method **--allowance** [[30000000000]]

  **Notes**:
      1. You must have the [[access-key]] that gives you the ability to add new keys for the given account-id 
     
`;
const deleteKeyHelpText = `
**Usage**: &&near&& [[keys]] @@<accountId>@@

    ^^view account public keys^^
    
    For e.g., if you would like to see the access keys of an account &&nearprotocolprimer.testnet&&. You can do so by using following command: 
        
        &&near&& [[keys]] @@nearprotocolprimer.testnet@@


**Notes**:
    1. You must have the access-key that gives you the ability to add new keys for the given account-id 
     
`;

const generateErrors = (errors) => {
  let mappedText = errors.reduce((acc, curr) => {
    return acc + `\n\t&&` + " * " + curr + `&&`;
  }, ``);
  return `**Encountered Following Errors**
${mappedText}

**Usage**: &&near&& [[keys]] @@<accountId>@@

    For more info type:
        &&near&& [[keys]] @@--help@@ 

`;
};

const missingMaster = `Missing Master Account`;
const missingAccountID = `Missing Account ID`;
const missingPublicKey = `Missing New Public Key`;
const invalidAccountId = `Invalid Account ID provided`;
const endWithTestnet = `Master account must end with testnet`;
const accEndWithTestnet = `Account Id must end with ".testnet"`;
const notLongEnough = `AccountId should be greater than 32 characters`;
const tooLong = `AccountId should be smaller than 64 characters`;
const invalidMasterAccount = `Invalid Master Account Id provided`;

const checkDescendant = (account, masterAccount) => {
  return arraysEqual(account.split(".").slice(1), masterAccount.split("."));
};

const checkForErrors = (accountId, options, loggedInAccount) => {
  let errors = [];

  const regexCheck =
    /^(([a-z\d]+[\-_])*[a-z\d]+\.)*([a-z\d]+[\-_])*[a-z\d]+$/im;

  if (!options.masterAccount) {
    errors.push(missingMaster);
    return errors;
  }

  if (!regexCheck.test(accountId)) {
    errors.push(invalidAccountId);
    return errors;
  }

  if (!regexCheck.test(options.masterAccount)) {
    errors.push(invalidMasterAccount);
    return errors;
  }

  if (!accountId.endsWith(".testnet")) {
    errors.push(accEndWithTestnet);
    return errors;
  }

  if (!options.masterAccount.endsWith(".testnet")) {
    errors.push(endWithTestnet);
    return errors;
  }

  if (!options.masterAccount.endsWith(loggedInAccount)) {
    const notAuthorised = `Not authorised to access the master account "${options.masterAccount}" Logged In account is ${loggedInAccount}`;
    errors.push(notAuthorised);
    return errors;
  }

  if (!checkDescendant(accountId, options.masterAccount)) {
    const notDescendent = `Provided accountId: ${accountId} is not a direct subaccount of the master account: ${options.masterAccount}`;
    errors.push(notDescendent);
    return errors;
  }

  if (accountId.length > 64) {
    errors.push(tooLong);
    return errors;
  }

  return errors;
};

const updateState = (type, message, setCustomState, setAcceptInput) => {
  if (type === "error") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(`&&${message}&&`)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  } else if (type === "success") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(message)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  }

  setAcceptInput(true);
};

export const getkeysCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(helpText),
    };
  } else {
    if (args.length === 1) {
      const errors = [missingAccountID];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else {
      const [_, accountId] = args;
      const { setAcceptInput, setCustomState } = stateChangeProps;

      setAcceptInput(false);

      context
        .getAccountKeys(accountId)
        .then((res) => {
          let type = "success";
          let indent = 4;
          let message = JSON.stringify(res, null, indent);
          updateState(type, message, setCustomState, setAcceptInput);
        })
        .catch((e) => {
          let type = "error";
          let message = e.message;
          updateState(type, message, setCustomState, setAcceptInput);
        });

      return {
        output: CustomOutputFactory.createColoredRecord(
          `Keys for account: [[${accountId}]] ...`
        ),
      };
    }
  }
};

export const generateKeyCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(helpText),
    };
  } else {
    if (args.length === 1) {
      const errors = [missingAccountID];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else {
      const [_, accountId] = args;
      const { setAcceptInput, setCustomState } = stateChangeProps;

      setAcceptInput(false);

      context
        .getAccountKeys(accountId)
        .then((res) => {
          let type = "success";
          let indent = 4;
          let message = JSON.stringify(res, null, indent);
          updateState(type, message, setCustomState, setAcceptInput);
        })
        .catch((e) => {
          let type = "error";
          let message = e.message;
          updateState(type, message, setCustomState, setAcceptInput);
        });

      return {
        output: CustomOutputFactory.createColoredRecord(
          `Keys for account: [[${accountId}]] ...`
        ),
      };
    }
  }
};

export const addKeyCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(addKeyHelpText),
    };
  } else {
    if (args.length === 1) {
      const errors = [missingAccountID, missingPublicKey];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else {
      const [_, accountId] = args;
      const { setAcceptInput, setCustomState } = stateChangeProps;

      setAcceptInput(false);

      context
        .getAccountKeys(accountId)
        .then((res) => {
          let type = "success";
          let indent = 4;
          let message = JSON.stringify(res, null, indent);
          updateState(type, message, setCustomState, setAcceptInput);
        })
        .catch((e) => {
          let type = "error";
          let message = e.message;
          updateState(type, message, setCustomState, setAcceptInput);
        });

      return {
        output: CustomOutputFactory.createColoredRecord(
          `Keys for account: [[${accountId}]] ...`
        ),
      };
    }
  }
};

export const deleteKeyCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(helpText),
    };
  } else {
    if (args.length === 1) {
      const errors = [missingAccountID];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else {
      const [_, accountId] = args;
      const { setAcceptInput, setCustomState } = stateChangeProps;

      setAcceptInput(false);

      context
        .getAccountKeys(accountId)
        .then((res) => {
          let type = "success";
          let indent = 4;
          let message = JSON.stringify(res, null, indent);
          updateState(type, message, setCustomState, setAcceptInput);
        })
        .catch((e) => {
          let type = "error";
          let message = e.message;
          updateState(type, message, setCustomState, setAcceptInput);
        });

      return {
        output: CustomOutputFactory.createColoredRecord(
          `Keys for account: [[${accountId}]] ...`
        ),
      };
    }
  }
};
