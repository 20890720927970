import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";
import TransferCode from "./TransferCode.mdx";

const MCQ = (props) => {
  const options = [
    "Insufficient balance of the contract account",
    "Call method is not payable Function",
    "Use of transfer action instead of send action ",
    "All of the above",
  ];
  const correctOption = "Call method is not payable Function";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, let's say we write a contract with where the user can
            pay to any account using our <b>smart contract</b>. The method{" "}
            <code>pay</code> is shown as below:
            <div style={{ textAlign: "left" }}>
              <TransferCode />
            </div>
            When any user tries to use the contract, it <b>fails</b>.{" "}
            <b>Can you figure out why?</b>
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
