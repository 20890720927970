import React from "react";
import Typography from "@mui/material/Typography";

export const CenteredLayout = ({ children }) => (
  <div
    className="Interaction"
    style={{
      maxWidth: "1000px",
      width: "100%",
      margin: "auto",
      height: "100%",
    }}
  >
    {children}
  </div>
);

export const TitleLayout = ({ header, subtitle, image }) => (
  <div>
    <div
      style={{
        height: "100vh",
        maxHeight: "600px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" component="div">
        {header}
      </Typography>
      <img src={image} height="200px" />
      <Typography variant="subtitle1" component="div">
        {subtitle}
      </Typography>
    </div>
  </div>
);

// export const LeftImageLayout = ({}) => (
//   <CenteredLayout>
//     <div style={{display:"flex", flexDirection:"row"}}>
//         <div>

//         </div>
//         <div>

//         </div>

//     </div>
//   </CenteredLayout>
// )

// export const RightImageLayout = ({}) => (

// )

// export const CenterImageLayout = ({ }) => (

// )
