// Primers
import { start } from "./Primers/Start";

// Infos
import AccountsInfo from "./PrimerComponents/AccountsInfo.mdx";
import AccountStateInfo from "./PrimerComponents/AccountStateInfo.mdx";
import WorldStateInfo from "./PrimerComponents/WorldStateInfo.mdx";
import SmartContractsInfo from "./PrimerComponents/SmartContractsInfo.mdx";
import StakingInfo from "./PrimerComponents/StakingInfo.mdx";
import GasInfo from "./PrimerComponents/GasInfo.mdx";

// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";

// Quiz
import TransactionMCQ from "./Quiz/TransactionMCQ";
import AccountReorderQuiz from "./Quiz/AccountReorderQuiz";
import ValidatorQuiz from "./Quiz/ValidatorQuiz.jsx";
import StakingQuiz from "./Quiz/StakingQuiz";
const primers = [...start];

const primerComponents = {
  AccountsInfo: AccountsInfo,
  AccountStateInfo: AccountStateInfo,
  SmartContractsInfo: SmartContractsInfo,
  StakingInfo: StakingInfo,
  TransactionMCQ: TransactionMCQ,
  WorldStateInfo: WorldStateInfo,
  AccountReorderQuiz: AccountReorderQuiz,
  ValidatorQuiz: ValidatorQuiz,
  StakingQuiz: StakingQuiz,
  GasInfo: GasInfo,
  NextLesson: NextLesson,
};

const imageComponents = [];

const quizList = [
  "TransactionMCQ",
  "AccountReorderQuiz",
  "ValidatorQuiz",
  "StakingQuiz",
];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
