import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Viewing the balance of an account",
    "Deploying a smart contract into the account",
    "An account receiving NEAR tokens",
    "Deleting an access key",
  ];
  const correctOption = "Viewing the balance of an account";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, which of the following operation <b>will not</b>{" "}
            trigger a state change in the system?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
