// Primers
import { start } from "./Primers/Start";

// Info
import CommunityCloudInfo from "./PrimerComponents/CommunityCloudInfo.mdx";
import DecentralisationInfo from "./PrimerComponents/DecentralisationInfo.mdx";
import DecentralisedApplicationsInfo from "./PrimerComponents/DecentralisedApplicationsInfo.mdx";
import SpotifyInfo from "./PrimerComponents/SpotifyInfo.mdx";
import WhyNEARInfo from "./PrimerComponents/WhyNEARInfo.mdx";
import HighValueAssets from "./PrimerComponents/HighValueAssets.mdx";
import DangersOfWeb from "./PrimerComponents/DangersOfWeb.mdx";
import Digitopia from "./PrimerComponents/Digitopia.mdx";
import Tangled from "./PrimerComponents/Tangled.mdx";
import Bitcoin from "./PrimerComponents/Bitcoin.mdx";
import Ledger from "./PrimerComponents/Ledger.mdx";

// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";

// Quiz
import BenefitMCQ from "./Quiz/BenefitMCQ";
import TraditionalWebMCQ from "./Quiz/TraditionalWebMCQ";
import DecentralisedSpotifyMCQ from "./Quiz/DecentralisedSpotifyMCQ";
import BitcoinMCQ from "./Quiz/BitcoinMCQ";

const primers = [...start];

const primerComponents = {
  CommunityCloudInfo: CommunityCloudInfo,
  DecentralisationInfo: DecentralisationInfo,
  DecentralisedApplicationsInfo: DecentralisedApplicationsInfo,
  BenefitMCQ: BenefitMCQ,
  TraditionalWebMCQ: TraditionalWebMCQ,
  DecentralisedSpotifyMCQ: DecentralisedSpotifyMCQ,
  WhyNEARInfo: WhyNEARInfo,
  SpotifyInfo: SpotifyInfo,
  HighValueAssets: HighValueAssets,
  DangersOfWeb: DangersOfWeb,
  Digitopia: Digitopia,
  Tangled: Tangled,
  Bitcoin: Bitcoin,
  BitcoinMCQ: BitcoinMCQ,
  Ledger: Ledger,
  NextLesson: NextLesson,
};

const imageComponents = [];

const quizList = [
  "BenefitMCQ",
  "TraditionalWebMCQ",
  "DecentralisedSpotifyMCQ",
  "BitcoinMCQ",
];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
