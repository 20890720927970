// Info

import TrustedThirdParty from "./PrimerComponents/TrustedThirdParty.mdx";
import ChainValidationInfo from "./PrimerComponents/ChainValidationInfo.mdx";
import TrustlessTSA from "./PrimerComponents/TrustlessTSA.mdx";
import CentralisedTSAInfo from "./PrimerComponents/CentralisedTSAInfo.mdx";
import DistributedTSAInfo from "./PrimerComponents/DistributedTSAInfo.mdx";
import SatoshiInfo from "./PrimerComponents/SatoshiInfo.mdx";
// Quizzes

import HashingMCQ from "./Quiz/HashingMCQ";
import HistoryMCQ from "./Quiz/HistoryMCQ";
import GlossaryMatchQuiz from "./Quiz/GlossaryMatchQuiz";

// Primers
import { start } from "./Primers/Start";

// Next Lesson

import NextLesson from "./PrimerComponents/NextLesson";

const primers = [...start];
// const primers = [...timeStampPrimers];

const primerComponents = {
  TrustedThirdParty: TrustedThirdParty,
  HashingMCQ: HashingMCQ,
  TrustlessTSA: TrustlessTSA,
  ChainValidationInfo: ChainValidationInfo,
  HistoryMCQ: HistoryMCQ,
  CentralisedTSAInfo: CentralisedTSAInfo,
  GlossaryMatchQuiz: GlossaryMatchQuiz,
  DistributedTSAInfo: DistributedTSAInfo,
  SatoshiInfo: SatoshiInfo,
  NextLesson: NextLesson,
};

const imageComponents = [];

const quizList = ["HashingMCQ", "HistoryMCQ", "GlossaryMatchQuiz"];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
