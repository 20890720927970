import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "User Data is controlled by the application developer",
    "Algorithm powering the application functions as a black-box",
    "Need for trust on the application developer",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which of the following is true for a{" "}
          <b>traditional web application</b>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
