export const avatars = [
  { text: "angry", value: "angry.png" },
  { text: "arrow", value: "arrow.png" },
  { text: "artist", value: "artist.png" },
  { text: "attention", value: "attention.png" },
  { text: "basketball", value: "basketball.png" },
  { text: "battery", value: "battery.png" },
  { text: "birthday", value: "birthday.png" },
  { text: "book", value: "book.png" },
  { text: "bored", value: "bored.png" },
  { text: "box", value: "box.png" },
  { text: "bubble", value: "bubble.png" },
  { text: "businessman", value: "businessman.png" },
  { text: "cable", value: "cable.png" },
  { text: "cart", value: "cart.png" },
  { text: "celebrate", value: "celebrate.png" },
  { text: "charging", value: "charging.png" },
  { text: "cleaner", value: "cleaner.png" },
  { text: "confused", value: "confused.png" },
  { text: "cook", value: "cook.png" },
  { text: "delivery-1", value: "delivery-1.png" },
  { text: "delivery-2", value: "delivery-2.png" },
  { text: "delivery-3", value: "delivery-3.png" },
  { text: "direct-attention", value: "direct-attention.png" },
  { text: "dj", value: "dj.png" },
  { text: "doctor", value: "doctor.png" },
  { text: "dollar", value: "dollar.png" },
  { text: "earth", value: "earth.png" },
  { text: "euro", value: "euro.png" },
  { text: "flying", value: "flying.png" },
  { text: "game", value: "game.png" },
  { text: "gears", value: "gears.png" },
  { text: "gentleman", value: "gentleman.png" },
  { text: "good-bye", value: "good-bye.png" },
  { text: "gun-1", value: "gun-1.png" },
  { text: "gun-2", value: "gun-2.png" },
  { text: "hello", value: "hello.png" },
  { text: "i-pad-1", value: "i-pad-1.png" },
  { text: "i-pad-2", value: "i-pad-2.png" },
  { text: "i-phone", value: "i-phone.png" },
  { text: "joystick", value: "joystick.png" },
  { text: "laptop-1", value: "laptop-1.png" },
  { text: "laptop-2", value: "laptop-2.png" },
  { text: "laptop-3", value: "laptop-3.png" },
  { text: "listen", value: "listen.png" },
  { text: "lost", value: "lost.png" },
  { text: "loudspeaker", value: "loudspeaker.png" },
  { text: "love-2", value: "love-2.png" },
  { text: "love", value: "love.png" },
  { text: "multimedia", value: "multimedia.png" },
  { text: "musician", value: "musician.png" },
  { text: "normal", value: "normal.png" },
  { text: "notepad", value: "notepad.png" },
  { text: "oops", value: "oops.png" },
  { text: "patient", value: "patient.png" },
  { text: "phone", value: "phone.png" },
  { text: "point", value: "point.png" },
  { text: "policeman", value: "policeman.png" },
  { text: "power-button", value: "power-button.png" },
  { text: "presentation-1", value: "presentation-1.png" },
  { text: "presentation-2", value: "presentation-2.png" },
  { text: "printer", value: "printer.png" },
  { text: "puzzle", value: "puzzle.png" },
  { text: "question", value: "question.png" },
  { text: "recycle", value: "recycle.png" },
  { text: "rocket", value: "rocket.png" },
  { text: "roll-eyes", value: "roll-eyes.png" },
  { text: "sad", value: "sad.png" },
  { text: "search", value: "search.png" },
  { text: "security-1", value: "security-1.png" },
  { text: "security-2", value: "security-2.png" },
  { text: "shape-10", value: "shape-10.png" },
  { text: "shape-11", value: "shape-11.png" },
  { text: "shape-12", value: "shape-12.png" },
  { text: "shape-1", value: "shape-1.png" },
  { text: "shape-2", value: "shape-2.png" },
  { text: "shape-3", value: "shape-3.png" },
  { text: "shape-4", value: "shape-4.png" },
  { text: "shape-5", value: "shape-5.png" },
  { text: "shape-6", value: "shape-6.png" },
  { text: "shape-7", value: "shape-7.png" },
  { text: "shape-8", value: "shape-8.png" },
  { text: "shape-9", value: "shape-9.png" },
  { text: "shocked", value: "shocked.png" },
  { text: "showcase", value: "showcase.png" },
  { text: "show", value: "show.png" },
  { text: "sign-1", value: "sign-1.png" },
  { text: "sign-2", value: "sign-2.png" },
  { text: "sign-3", value: "sign-3.png" },
  { text: "sign-4", value: "sign-4.png" },
  { text: "sign-5", value: "sign-5.png" },
  { text: "sign-6", value: "sign-6.png" },
  { text: "sign-7", value: "sign-7.png" },
  { text: "sign-8", value: "sign-8.png" },
  { text: "singer", value: "singer.png" },
  { text: "soccer", value: "soccer.png" },
  { text: "solar-panel", value: "solar-panel.png" },
  { text: "sorry", value: "sorry.png" },
  { text: "stop", value: "stop.png" },
  { text: "super-robot", value: "super-robot.png" },
  { text: "support", value: "support.png" },
  { text: "tennis-1", value: "tennis-1.png" },
  { text: "tennis-2", value: "tennis-2.png" },
  { text: "thumbs-up", value: "thumbs-up.png" },
  { text: "umbrella", value: "umbrella.png" },
  { text: "under-construction", value: "under-construction.png" },
  { text: "waiter", value: "waiter.png" },
  { text: "wave", value: "wave.png" },
  { text: "welcome", value: "welcome.png" },
  { text: "wi-fi", value: "wi-fi.png" },
  { text: "winner", value: "winner.png" },
  { text: "workman-1", value: "workman-1.png" },
  { text: "workman-2", value: "workman-2.png" },
];

export const userAvatars = [
  { text: "Anger", value: "Anger.svg" },
  { text: "Backwards", value: "Backwards.svg" },
  { text: "Blood from eyes", value: "Blood from eyes.svg" },
  { text: "Boring", value: "Boring.svg" },
  { text: "Cheeks", value: "Cheeks.svg" },
  { text: "Crying", value: "Crying.svg" },
  { text: "Cute", value: "Cute.svg" },
  { text: "Dreamer", value: "Dreamer.svg" },
  { text: "Happy", value: "Happy.svg" },
  { text: "Hungry", value: "Hungry.svg" },
  { text: "In love", value: "In love.svg" },
  { text: "Make a wish", value: "Make a wish.svg" },
  { text: "Pity", value: "Pity.svg" },
  { text: "Pooping", value: "Pooping.svg" },
  { text: "Sadness", value: "Sadness.svg" },
  { text: "Scream", value: "Scream.svg" },
  { text: "Severe", value: "Severe.svg" },
  { text: "Shy", value: "Shy.svg" },
  { text: "Sit-down", value: "Sit-down.svg" },
  { text: "Sleepy", value: "Sleepy.svg" },
  { text: "Smirk", value: "Smirk.svg" },
  { text: "Superstar", value: "Superstar.svg" },
  { text: "Whistling", value: "Whistling.svg" },
  { text: "Wink", value: "Wink.svg" },
];
