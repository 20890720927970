import { Paper, Button } from "@mui/material";
import tick from "../../Lottie/tick.json";
import fail from "../../Lottie/fail.json";
import Lottie from "lottie-react";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: fail,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Dialog = ({
  setProgressIndex,
  setProgress,
  progress,
  progressIndex,
  completed,
  setChecking,
  checking,
  playWrong,
  setSkip,
  skip,
  busyKey,
  busyValue,
  setProgressState,
  primers,
  ...props
}) => {
  const increaseProgress = () => {
    if (progress < primers.length) {
      setProgress(progress + 1);
    }
  };

  const showSkip = completed ? false : skip ? false : true;
  const showError = skip ? true : false;
  const showSuccess = skip ? false : completed ? true : false;
  const disabledSkip = busyKey || busyValue ? true : false;

  return (
    <Paper
      elevation={12}
      sx={{
        width: "100%",
        position: "fixed",
        bottom: "0",
        height: "140px",
        color: "white",
        fontFamily: "Nunito",
        fontWeight: "800",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: showError
          ? "#F5CAC3"
          : showSuccess
          ? "#58cb0599"
          : "floralwhite",
      }}
    >
      <div
        style={{
          maxWidth: "800px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          margin: "auto",
        }}
      >
        {showError && <ErrorMessage />}
        {showSuccess && <SuccessMessage />}
        {showSkip && (
          <Button
            onClick={() => {
              playWrong();
              setChecking(true);
              setSkip(true);
              setProgressState("error");
            }}
            disabled={disabledSkip}
            sx={{ margin: "20px" }}
            variant="outlined"
          >
            Skip
          </Button>
        )}

        {completed && (
          <Button
            onClick={increaseProgress}
            sx={{ margin: "20px" }}
            variant="contained"
            color={showError ? "error" : showSuccess ? "success" : "primary"}
          >
            Continue
          </Button>
        )}
      </div>
    </Paper>
  );
};

export default Dialog;

// {
//   /* <Lottie options={defaultOptions} height={75} width={75} />; */
// }

const ErrorMessage = () => (
  <div style={{ display: "flex", flexDirection: "row", height: "90px" }}>
    <Lottie animationData={fail} height={90} width={90} />
    <div style={{ margin: "0px", padding: "0px", color: "#47307f" }}>
      <h2 style={{ margin: "10px" }}>You failed to accomplish the mission</h2>
      <span style={{ margin: "10px" }}>Better luck next time</span>
    </div>
  </div>
);

const SuccessMessage = () => (
  <div style={{ display: "flex", flexDirection: "row", height: "90px" }}>
    <Lottie animationData={tick} height={90} width={90} />
    <div style={{ margin: "0px", padding: "0px", color: "#47307f" }}>
      <h2 style={{ margin: "10px" }}>Mission Accomplished</h2>
      <span style={{ margin: "10px" }}>Very well done</span>
    </div>
  </div>
);
