export const paperStyle = {
  display: "flex",
  flexDirection: "column",
  background: "floralwhite",
  borderRadius: "12px",
  padding: "12px",
  width: "300px",
};

export const terminalStyle = {
  background: "rgba(0, 0, 0, 0.87)",
  padding: "10px",
  color: "floralwhite",
  fontFamily: "Jetbrains Mono",
  margin: "10px",
};

export const promptStyle = {
  color: "#f8aeff",
};

export const envStyle = { fontWeight: "bold", color: "salmon" };
