import React, { useRef, useEffect } from "react";
import { Button, Paper } from "@mui/material";
import anime from "animejs";

const TextButton = ({ send, ...props }) => {
  const animation = useRef(null);
  const buttonContainer = useRef(null);

  useEffect(() => {
    const buttonRefs = buttonContainer.current.querySelectorAll(".UserButton");

    animation.current = anime.timeline({
      loop: false,
      autoplay: true,
    });

    animation.current.add({
      targets: buttonContainer.current,
      opacity: [0, 1],
      translateY: [25, 0],
      begin: () => {
        send("BUTTON");
        //   buttonContainer.current.scrollIntoView({
        //     behavior: "smooth",
        //   });
      },
    });

    animation.current.add({
      targets: buttonRefs,
      opacity: [0, 1],
      translateY: [25, 0],
      delay: function (el, i, l) {
        return i * 100;
      },
    });
  }, []);

  const { buttonOptions } = props.currentPrimer.props;

  const handleClick = (btn) => {
    send({
      type: "NEXT",
      btn: btn.text,
      reply: btn.userReply.reply,
      primers: btn.primers,
      userAvatar: btn.userReply.userAvatar,
    });
  };

  return (
    <Paper
      ref={buttonContainer}
      style={{
        textAlign: "center",
        background: "aliceblue",
        borderRadius: "12px",
        margin: "30px",
      }}
    >
      {buttonOptions.map((btn, idx) => (
        <Button
          onClick={() => handleClick(btn)}
          className="UserButton"
          key={`button-${idx}`}
          variant={"outlined"}
          style={{
            height: "3rem",
            fontSize: "1rem",
            margin: "30px",
            transition: "0.1s",
          }}
        >
          {btn.text}{" "}
        </Button>
      ))}
    </Paper>
  );
};

export default TextButton;
