import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Same transactions costs the same gas units",
    "Same transactions costs same Ⓝ tokens",
    "Ratio of Gas units to Ⓝ is fixed ",
    "Gas units have a fixed price in Ⓝ",
  ];
  const correctOption = "Same transactions costs the same gas units";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            We earlier mentioned that <b>gas costs</b> are <b>deterministic</b>.{" "}
            {props.name}, what do you think this actually means?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
