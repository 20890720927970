// Infos
import InstallInfo from "./PrimerComponents/InstallInfo.mdx";
import FolderInfo from "./PrimerComponents/FolderInfo.mdx";
import ContractInfo from "./PrimerComponents/ContractInfo.mdx";
import ContractClass from "./PrimerComponents/ContractClass.mdx";
import HelloNear from "./PrimerComponents/HelloNear.mdx";
import BuildInfo from "./PrimerComponents/BuildInfo.mdx";
import DeployingInfo from "./PrimerComponents/DeployingInfo.mdx";

// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";

// Quiz
import BuildMCQ from "./Quiz/BuildMCQ";
import ChargeMCQ from "./Quiz/ChargeMCQ";
import ContractMatch from "./Quiz/ContractMatch";
import ContractMCQ from "./Quiz/ContractMCQ";
import DevDeployMCQ from "./Quiz/DevDeployMCQ";

// Primers
import { start } from "./Primers/Start";

const primers = [...start];

const primerComponents = {
  InstallInfo: InstallInfo,
  FolderInfo: FolderInfo,
  NextLesson: NextLesson,
  BuildMCQ: BuildMCQ,
  ChargeMCQ: ChargeMCQ,
  ContractInfo: ContractInfo,
  ContractClass: ContractClass,
  ContractMatch: ContractMatch,
  HelloNear: HelloNear,
  ContractMCQ: ContractMCQ,
  BuildInfo: BuildInfo,
  DevDeployMCQ: DevDeployMCQ,
  DeployingInfo: DeployingInfo,
};

const imageComponents = [];

const quizList = [
  "ContractMatch",
  "ChargeMCQ",
  "BuildMCQ",
  "ContractMCQ",
  "DevDeployMCQ",
  "DeployMCQ",
];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
