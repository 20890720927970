import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "primerlabs.testnet account holder",
    "Validators executing the smart contract method",
    "View Methods are free",
    "The invoker of the method",
  ];
  const correctOption = "View Methods are free";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, invoking the <code>get_greeting</code> view method on{" "}
            <code>greetings.primerlabs.testnet</code> smart contract returns{" "}
            <code>Hello World</code> as response. <br />
            <br /> Who pays the charge for computational resource used to return
            this response?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
