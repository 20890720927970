import React, { useState, useEffect } from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { Button, ButtonGroup, TextField } from "@mui/material";
import toast from "react-hot-toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function FileSystemNavigator({
  keys,
  changed,
  setChanged,
  setAccessKeys,
}) {
  const testnetNodes = keys.filter((x) => x.network === "testnet");
  const mainnetNodes = keys.filter((x) => x.network === "mainnet");

  const updateChanged = () => {
    const prevChanged = [...changed];
    prevChanged.pop();
    setChanged(prevChanged);
  };

  const [selected, setSelected] = useState(null);
  const [edit, setEdit] = useState(false);
  const [editName, setEditName] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});

  React.useEffect(() => {
    const selectedAccountDetails =
      selected !== null
        ? keys.find(
            (x) =>
              x.account_id === selected.slice(0, -8) &&
              x.network === selected.slice(-7)
          )
        : {};
    setSelectedAccount(selectedAccountDetails);
  }, [selected]);

  const handleClose = () => {
    setSelected(null);
    setOpen(false);
  };
  const checkAlreadyExists = (id, network) => {
    return keys.some((x) => x.account_id === id && x.network === network);
  };
  const handleEditName = (selectedId) => {
    const selectedNetwork = selectedId.slice(-7);
    const id = selectedId.slice(0, -8);
    const alreadyExists = checkAlreadyExists(editName, selectedNetwork);
    if (!alreadyExists) {
      const newAccessKeys = keys.map((x) => {
        if (x.account_id === id) {
          return {
            ...x,
            account_id: editName,
          };
        } else {
          return x;
        }
      });
      setAccessKeys(newAccessKeys);
      // setSelected(`${editName}.json-${selectedNetwork}`);
      setEdit(false);
      handleClose();
      toast.success(
        `Access key file name modified on the network ${selectedNetwork}`,
        {
          id: "AccessKeyExistsName",
        }
      );
    } else {
      toast.error(
        `Access key file with the name ${editName}.json already exists on the network ${selectedNetwork}`,
        {
          id: "AccessKeyExists",
        }
      );
    }
  };

  useEffect(() => {
    let timer1 = setTimeout(() => {
      if (changed.length > 0) {
        updateChanged();
      }
    }, 2000);

    // this will clear Timeout
    // when component unmount like in willComponentUnmount
    // and show will not change to true
    return () => {
      clearTimeout(timer1);
    };
  }, [changed]);

  useEffect(() => {
    if (selected !== null) {
      setOpen(true);
    }
  }, [selected]);

  const handleClick = (id) => {
    setSelected(id);
    const selectedName = keys.find(
      (x) => x.account_id === id.slice(0, -8) && x.network === id.slice(-7)
    );

    if (selectedName) {
      setEditName(selectedName.account_id);
    }
  };

  return (
    <div>
      <TreeView
        expanded={["1", "2", "3"]}
        style={{
          marginLeft: "10px",
          overflowY: "auto",
          height: "400px",
          overflowX: "hidden",
        }}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        <TreeItem
          nodeId="1"
          label={<span style={{ fontWeight: "bold" }}>.near-credentials</span>}
        >
          <TreeItem
            nodeId="2"
            label={<span style={{ fontWeight: "bold" }}>testnet</span>}
          >
            <TransitionGroup>
              {testnetNodes.map((x) => (
                <Collapse key={`${x.account_id}-${x.network}`}>
                  <TreeItem
                    className={
                      changed.includes(`${x.account_id}-${x.network}`)
                        ? "animated-tree-item"
                        : ""
                    }
                    onClick={() => handleClick(`${x.account_id}-${x.network}`)}
                    key={`${x.account_id}-${x.network}`}
                    nodeId={`${x.account_id}-${x.network}`}
                    label={
                      <span>
                        {x.account_id.length < 20
                          ? x.account_id
                          : `${x.account_id.slice(0, 17)}...`}
                        .json
                      </span>
                    }
                  />
                </Collapse>
              ))}
            </TransitionGroup>
          </TreeItem>
          <TreeItem
            nodeId="3"
            label={<span style={{ fontWeight: "bold" }}>mainnet</span>}
          >
            <TransitionGroup>
              {mainnetNodes.map((x) => (
                <Collapse key={`${x.account_id}-mainnet`}>
                  <TreeItem
                    className={
                      changed.includes(`${x.account_id}-mainnet`)
                        ? "animated-tree-item"
                        : ""
                    }
                    onClick={() => handleClick(`${x.account_id}-mainnet`)}
                    key={`${x.account_id}-mainnet`}
                    nodeId={`${x.account_id}-mainnet`}
                    label={
                      <span>
                        {x.account_id.length < 20
                          ? x.account_id
                          : `${x.account_id.slice(0, 17)}...`}
                        .json
                      </span>
                    }
                  />
                </Collapse>
              ))}
            </TransitionGroup>
          </TreeItem>
        </TreeItem>
      </TreeView>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2
            style={{
              textAlign: "center",
              wordWrap: "break-word",
              fontWeight: "bold",
              color: "indigo",
            }}
          >
            {selectedAccount ? selectedAccount.account_id : ""}.json{" "}
            <IconButton color="primary" onClick={() => setEdit(true)}>
              <EditIcon />
            </IconButton>
          </h2>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: "bold", fontVariant: "all-small-caps" }}>
              Account ID
            </span>
            <br />
            <span
              style={{
                wordWrap: "break-word",
                fontWeight: "bold",
                fontSize: "small",
                color: "indigo",
              }}
            >
              {selectedAccount ? selectedAccount.account_id : ""}
            </span>
            <br />
            <span style={{ fontWeight: "bold", fontVariant: "all-small-caps" }}>
              Public Key
            </span>
            <br />
            <span
              style={{
                wordWrap: "break-word",
                fontWeight: "bold",
                fontSize: "small",
                color: "indigo",
              }}
            >
              {selectedAccount ? selectedAccount.publicKey : ""}
            </span>
            <br />
            <span style={{ fontWeight: "bold", fontVariant: "all-small-caps" }}>
              Private Key
            </span>
            <br />
            <span
              style={{
                wordWrap: "break-word",
                fontWeight: "bold",
                fontSize: "small",
                color: "indigo",
              }}
            >
              {selectedAccount ? selectedAccount.private_key : ""}
            </span>
            <br />
          </Typography>
          <Modal open={edit} onClose={() => setEdit(false)}>
            <Box sx={{ ...style, width: 350 }}>
              <h2 style={{ color: "#47307f", textAlign: "center" }}>
                Edit Key File Name
              </h2>
              <div style={{ textAlign: "center" }}>
                <TextField
                  style={{ marginBottom: "20px", width: "300px" }}
                  label="File Key Name"
                  variant="outlined"
                  value={editName}
                  onChange={(evt) => setEditName(evt.target.value)}
                />
              </div>
              <ButtonGroup
                style={{ display: "flex", justifyContent: "center" }}
                variant="outlined"
              >
                <Button
                  disabled={
                    editName.trim().length === 0 ||
                    editName === selectedAccount.account_id
                  }
                  style={{ fontSize: "12px" }}
                  onClick={(x) => handleEditName(selected)}
                  size="small"
                >
                  Change
                </Button>
                <Button
                  style={{ fontSize: "12px" }}
                  onClick={() => setEdit(false)}
                  size="small"
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </Box>
          </Modal>
        </Box>
      </Modal>
    </div>
  );
}
