export const initialAccountKeys = {
  testnet: [
    {
      access_key: {
        nonce: 92288354000004,
        permission: "FullAccess",
      },
      public_key: "ed25519:92dnJW2m5m2bUAgNnj8zYo25Qv6iqGWGFSwXdXo7dkT9",
    },
  ],
  mainnet: [
    {
      access_key: {
        nonce: 92288354000004,
        permission: "FullAccess",
      },
      public_key: "ed25519:BQ2sHTkH441cq4JAHC9YRZguH1RgjXCqBaee4xsnu5S9",
    },
  ],
};

export const keyRotationKeys = {
  testnet: [
    {
      access_key: {
        nonce: 92288354000004,
        permission: "FullAccess",
      },
      public_key: "ed25519:3X7fBScNcW46p9mRk1Pf3nCEdqUC3nJPaQy9Henpuq8K",
    },
  ],
  mainnet: [
    {
      access_key: {
        nonce: 92288354000004,
        permission: "FullAccess",
      },
      public_key: "ed25519:BQ2sHTkH441cq4JAHC9YRZguH1RgjXCqBaee4xsnu5S9",
    },
  ],
};

export const deleteExerciseAccountKeys = {
  testnet: [
    {
      access_key: {
        nonce: 92288354000004,
        permission: "FullAccess",
      },
      public_key: "ed25519:3X7fBScNcW46p9mRk1Pf3nCEdqUC3nJPaQy9Henpuq8K",
    },
    {
      access_key: {
        nonce: 92288354000004,
        permission: "FullAccess",
      },
      public_key: "ed25519:EpiESAMtdjdzBRygtPcE5icvCGr7JAB8PmVfcvHNjtou",
    },
    {
      access_key: {
        nonce: 92288354000004,
        permission: "FullAccess",
      },
      public_key: "ed25519:FAHdva18NEmSoTko2S346k44tmyFcf9PvrGWgLgwrHDt",
    },
  ],
  mainnet: [
    {
      access_key: {
        nonce: 92288354000004,
        permission: "FullAccess",
      },
      public_key: "ed25519:BQ2sHTkH441cq4JAHC9YRZguH1RgjXCqBaee4xsnu5S9",
    },
  ],
};

export const initialAccessKeys = [
  {
    network: "testnet",
    account_id: "primerlabs.testnet",
    private_key:
      "ed25519:2RuPEpQiHDBcR6fhFtwSP3fSJAdLFxQPtnbm9AygxgjdPufMaXTuyDAiqYhRJcCuvgwX289zUuaL7cB2JdcaTaMm",
    publicKey: "ed25519:3X7fBScNcW46p9mRk1Pf3nCEdqUC3nJPaQy9Henpuq8K",
  },
  {
    network: "mainnet",
    account_id: "primerlabs.near",
    publicKey: "ed25519:BQ2sHTkH441cq4JAHC9YRZguH1RgjXCqBaee4xsnu5S9",
    private_key:
      "ed25519:cetC5EDqZvzfD1p2XNSv4c6VCj84LbXwenXwMFVDer8KQxGSNAnXik2AdRxsVPcuEunQbbkQDx25mbGiDiZe6Ed",
  },
];
