import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Block hash value starting with two '0's ",
    "Block hash value starting with three '1's ",
    "Block hash value starting with one 'f'",
    "Block hash value ending with two 'c's",
  ];
  const correctOption = "Block hash value starting with one 'f'";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      header={
        <React.Fragment>
          {props.name}, which of the following <b>patterns</b> for defining a
          valid block is
          <b> most likely</b> to be found sooner while mining?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
