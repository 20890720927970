import React, { useState } from "react";
import { sha256 } from "js-sha256";
import { CenteredLayout } from "../../Quiz/layouts/Layout";
import { Paper, Button } from "@mui/material";
import { HashTextField, CssTextField } from "./styledComponents";

const { parseSeedPhrase, generateSeedPhrase } = require("near-seed-phrase");

// to create a seed phrase with its corresponding Keys
const { seedPhrase, publicKey, secretKey } = generateSeedPhrase();

// // To recover keys from the seed phrase
// const { publicKey, secretKey } = parseSeedPhrase(seedPhrase);

const initialKey = {
  seedPhrase: "",
  publicKey: "",
  secretKey: "",
};

const SeedPhrase = () => {
  const [keyPair, setKeyPair] = useState(initialKey);
  const [seedPhrase, setSeedPhrase] = useState("");
  const [keyPair2, setKeyPair2] = useState(initialKey);

  React.useEffect(() => {
    generateSeed();
  }, []);

  const generateSeed = () => {
    const seed = generateSeedPhrase();
    setKeyPair(seed);
    setKeyPair2(initialKey);
    setSeedPhrase(seed.seedPhrase);
  };

  const recoverSeed = () => {
    const seed = parseSeedPhrase(seedPhrase);
    setKeyPair2(seed);
  };

  return (
    <CenteredLayout>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "10px auto",
          justifyContent: "center",
        }}
      >
        <Paper
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            margin: "10px",
          }}
        >
          <HashTextField
            label="Public Key"
            value={keyPair.publicKey}
            style={{ margin: "10px" }}
          />
          <HashTextField
            label="Private Key"
            value={keyPair.secretKey}
            style={{ margin: "10px" }}
          />
          <CssTextField
            label="Seed Phrase"
            value={keyPair.seedPhrase}
            multiline
            style={{ margin: "10px" }}
            rows={5}
          />
          <Button
            style={{ height: "3rem", fontSize: "0.9rem", margin: "auto" }}
            variant="outlined"
            onClick={generateSeed}
          >
            Generate Access Key
          </Button>
        </Paper>
        <Paper
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            margin: "10px",
          }}
        >
          <CssTextField
            label="Seed Phrase"
            value={seedPhrase}
            multiline
            style={{ margin: "10px" }}
            rows={5}
            onChange={(evt) => setSeedPhrase(evt.target.value)}
          />
          <Button
            style={{ height: "3rem", fontSize: "0.9rem", margin: "auto" }}
            variant="outlined"
            onClick={recoverSeed}
          >
            Recover Access Key
          </Button>
          <HashTextField
            label="Public Key"
            value={keyPair2.publicKey}
            style={{ margin: "10px", marginTop: "20px" }}
          />
          <HashTextField
            label="Private Key"
            value={keyPair2.secretKey}
            style={{ margin: "10px" }}
          />
        </Paper>
      </div>
    </CenteredLayout>
  );
};

export const RecoverPhrase = () => {
  const [keyPair, setKeyPair] = useState({});

  React.useEffect(() => {
    generateSeed();
  }, []);

  const generateSeed = () => {
    const seed = generateSeedPhrase();
    setKeyPair(seed);
  };

  return (
    <CenteredLayout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "10px auto",
          justifyContent: "center",
        }}
      ></div>
    </CenteredLayout>
  );
};

export default SeedPhrase;
