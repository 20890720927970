import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
import Zoom from "@mui/material/Zoom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const style = {
  position: "absolute",
  top: "0%",
  width: "80%",
  height: "50vh",
  maxHeight: "600px",
  margin: "10px auto",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TransitionsModal = ({ children, fullscreen, setFullscreen }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (evt, reason) => {
    if (reason === "backdropClick") {
      setOpen(false);
    } else if (reason === "escapeKeyDown") {
      setOpen(false);
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (fullscreen) {
      setOpen(true);
    }
  }, [fullscreen]);

  useEffect(() => {
    if (!open) {
      setFullscreen(false);
    }
  }, [open]);

  return (
    <div>
      <Modal
        sx={{ margin: "30px" }}
        aria-labelledby="Modal-Open"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <React.Fragment>
          <IconButton
            onClick={() => setOpen(false)}
            style={{
              color: "white",
              position: "fixed",
              top: "10px",
              right: "10px",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Grow in={open}>
            <Box style={{ top: "0%", zIndex: 3000, padding: "30px" }}>
              {children}
            </Box>
          </Grow>
        </React.Fragment>
      </Modal>
    </div>
  );
};

export default TransitionsModal;
