import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Privacy & Security implications",
    "Storage & Bandwidth issues for storing large files",
    "Have to place absolute trust on the TSS",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, what are the drawbacks of using a{" "}
          <b>
            trusted time-stamping service(TSS)<sup>*</sup>
          </b>{" "}
          with
          <b> digital safety-deposity box</b>?
          <div style={{ fontSize: "small", margin: "10px" }}>
            * As designed according to the naive solution described by{" "}
            <b>Haber</b> and <b>Stornetta</b> in their paper
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
