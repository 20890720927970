import React from "react";
import AccessKeysExplorable from "../../AccessKeysExplorable";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Paper } from "@mui/material";
import { initialAccountKeys as accountkeys } from "../../AccessKeysExplorable/keysData";
import useSound from "use-sound";
import { AntTab, AntTabs } from "../../../../Blockchain/styledComponents";
import isEqual from "lodash.isequal";

const getExercise = (id) =>
  ({
    1: (
      <span>
        Generate a keypair for the <code>primerlabs.testnet</code> account for
        the full <code>access-key</code> that already exists on the{" "}
        <code>testnet</code> network. The seedphrase is{" "}
        <code>
          fold engage convince stool birth scale confirm swamp parrot venture
          cousin nasty
        </code>
      </span>
    ),
    2: (
      <span>
        Generate a keypair with the{" "}
        <code>
          seedphrase=pull empower chuckle muscle art recipe advance era donate
          peace liar salad
        </code>{" "}
        with <code>accountId=full_key</code>
      </span>
    ),
    3: (
      <span>
        Add generated <code>public key</code> from <code>full_key</code> keypair
        as a <code>full access_key</code> to the account{" "}
        <code>primerlabs.testnet</code>
      </span>
    ),
    4: (
      <span>
        Generate a keypair with the{" "}
        <code>
          muscle planet toast animal chase nose crouch success van duck reveal
          doctor
        </code>{" "}
        with <code>accountId=function_call_key</code>
      </span>
    ),
    5: (
      <span>
        Add this generated <code>public key</code> from the{" "}
        <code>function_call_key</code> keypair to the{" "}
        <code>primerlabs.testnet</code> account as a function call{" "}
        <code>access_key</code> for the contract deployed at{" "}
        <code>nearprotocolprimer.testnet</code> , with methods,{" "}
        <code>view_nft fetch_nft</code> and allowance of{" "}
        <code>250000000000000000000000</code>.
      </span>
    ),
  }[id]);

const ExerciseBox = ({ id, filled = false }) => (
  <Alert severity={filled ? "success" : "error"}>{getExercise(id)}</Alert>
);

const GenerateKeyPair = () => {
  const [completed, setCompleted] = React.useState({});
  const [played, setPlayed] = React.useState({});
  const [initialAccessKeys, setInitialAccessKeys] = React.useState([]);
  const [initialAccountKeys, setInitialAccountKeys] =
    React.useState(accountkeys);

  const [type, setType] = React.useState("GENERATE");

  const handleChangeTab = (event, newValue) => setType(newValue);

  const [playCorrect] = useSound("/sounds/correct.mp3", { volume: 0.1 });

  const explorableProps = {
    defaultAccessKeys: [],
    defaultAccountKeys: accountkeys,
    setInitialAccessKeys,
    setInitialAccountKeys,
  };

  React.useEffect(() => {
    // if (Object.values(completed).every((x) => x === true)) {
    //   playCorrect();
    // }

    if (
      Object.values(completed).filter((x) => x === true).length >
      Object.values(played).filter((x) => x === true).length
    ) {
      playCorrect();
    }
    setPlayed(completed);
  }, [completed]);

  React.useEffect(() => {
    // 1: Account primerlabs.testnet exists on the testnet
    const primerlabsTestnetExists = initialAccessKeys.some(
      (x) =>
        x.account_id === "primerlabs.testnet" &&
        x.network === "testnet" &&
        x.publicKey === "ed25519:92dnJW2m5m2bUAgNnj8zYo25Qv6iqGWGFSwXdXo7dkT9"
    );

    // 2: FullKey Generated
    const fullKeyGenerated = initialAccessKeys.some(
      (x) =>
        x.account_id === "full_key" &&
        x.network === "testnet" &&
        x.publicKey === "ed25519:FekLraEaH4dmmxbHoeZNAUPWddaGRiNyEraGccZjMKvx"
    );

    // 3: Full Key Added

    const fullKeyAdded = initialAccountKeys["testnet"].some(
      (x) =>
        x.public_key ===
          "ed25519:FekLraEaH4dmmxbHoeZNAUPWddaGRiNyEraGccZjMKvx" &&
        x.access_key.permission === "FullAccess"
    );

    // 4: Generate a FuncKey

    const funcKeyGenerated = initialAccessKeys.some(
      (x) =>
        x.account_id === "function_call_key" &&
        x.network === "testnet" &&
        x.publicKey === "ed25519:EpiESAMtdjdzBRygtPcE5icvCGr7JAB8PmVfcvHNjtou"
    );

    // 5. Add the funcKey to the account

    const funcKeyAdded = initialAccountKeys["testnet"].some(
      (x) =>
        x.public_key ===
          "ed25519:EpiESAMtdjdzBRygtPcE5icvCGr7JAB8PmVfcvHNjtou" &&
        isEqual(x.access_key.permission, {
          FunctionCall: {
            allowance: "250000000000000000000000",
            method_names: ["view_nft", "fetch_nft"],
            receiver_id: "nearprotocolprimer.testnet",
          },
        })
    );

    setCompleted({
      1: primerlabsTestnetExists,
      2: fullKeyGenerated,
      3: fullKeyAdded,
      4: funcKeyGenerated,
      5: funcKeyAdded,
    });
  }, [initialAccessKeys, initialAccountKeys]);

  return (
    <Paper elevation={4} style={{ padding: "20px", borderRadius: "10px" }}>
      <h2 style={{ textAlign: "center", color: "#47307f" }}>Exercises</h2>
      <Stack sx={{ width: "100%", marginBottom: "20px" }} spacing={2}>
        <ExerciseBox id={"1"} filled={completed[1]} />
        <ExerciseBox id={"2"} filled={completed[2]} />
        <ExerciseBox id={"3"} filled={completed[3]} />
        <ExerciseBox id={"4"} filled={completed[4]} />
        <ExerciseBox id={"5"} filled={completed[5]} />
      </Stack>
      <div style={{ marginBottom: "20px" }}>
        <AntTabs
          style={{ marginBottom: "30px" }}
          value={type}
          onChange={handleChangeTab}
          centered
        >
          <AntTab value={"GENERATE"} label="Generate Key Pair" />
          <AntTab value={"ADD"} label="Add Key Pair" />
        </AntTabs>
        <AccessKeysExplorable type={type} {...explorableProps} />
      </div>
    </Paper>
  );
};

export default GenerateKeyPair;
