import React, { useState } from "react";
import { sha256 } from "js-sha256";
import { CenteredLayout } from "../../Quiz/layouts/Layout";
import { Paper, Button } from "@mui/material";
import { HashTextField, CssTextField } from "./styledComponents";

var EC = require("elliptic").ec;

var ec = new EC("secp256k1");

// Generate keys
var keypair = ec.genKeyPair();

const generateKeyPair = () => {
  var keyPair = ec.genKeyPair();
  let prv = keyPair.getPrivate("hex");
  let pub = keyPair.getPublic("hex");

  return { prv, pub };
};

const Signature = () => {
  const [message1, setMessage1] = useState(
    "Sign message and then verify the signature"
  );
  const [message2, setMessage2] = useState(
    "Sign message and then verify the signature"
  );

  const [privKey, setPrivKey] = React.useState("");
  const [pubKey, setPubKey] = React.useState("");
  const [sign1, setSign1] = React.useState("");
  const [sign2, setSign2] = React.useState("");
  const [verified, setVerified] = React.useState(false);

  React.useEffect(() => {
    const { prv, pub } = generateKeyPair();
    setPrivKey(prv);
    setPubKey(pub);
  }, []);

  React.useEffect(() => {
    verify();
  }, [sign2, message2]);

  const handleChangePrivateKey = (evt) => {
    evt.preventDefault();
    setPrivKey(evt.target.value);
    let keys = ec.keyFromPrivate(sha256(evt.target.value));
    setPubKey(keys.getPublic("hex"));
  };

  const signMessage = () => {
    let sign = keypair.sign(sha256(message1));
    let sign1 = sign.r.toString("hex", 64) + sign.s.toString("hex", 64);
    let sign2 = sign.r.toString("hex", 64) + sign.s.toString("hex", 64);
    setSign1(sign1);
    setSign2(sign2);
  };

  const verify = () => {
    try {
      let signature = sign2;
      var m = signature.match(/([a-f\d]{64})/gi);

      signature = {
        r: m[0],
        s: m[1],
      };

      let check = keypair.verify(sha256(message2), signature);
      setVerified(check);
    } catch {
      setVerified(false);
    }
  };

  return (
    <CenteredLayout>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "10px auto",
          justifyContent: "center",
        }}
      >
        <Paper
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            margin: "10px",
          }}
        >
          <CssTextField
            label="Message"
            value={message1}
            multiline
            style={{ margin: "10px" }}
            rows={4}
            onChange={(evt) => setMessage1(evt.target.value)}
          />
          <HashTextField
            onChange={handleChangePrivateKey}
            label="Private Key"
            value={privKey}
            style={{ margin: "10px" }}
          />
          <div style={{ margin: "auto" }}>
            <Button
              style={{ height: "3rem", fontSize: "0.9rem", margin: "auto" }}
              variant="outlined"
              onClick={signMessage}
            >
              Sign Message
            </Button>
          </div>
          <HashTextField
            label="Signature"
            value={sign1}
            style={{ margin: "10px" }}
          />
        </Paper>

        <Paper
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            margin: "10px",
            background: verified ? "lightgreen" : "#ff7f5061",
          }}
        >
          <CssTextField
            label="Message"
            value={message2}
            multiline
            style={{ margin: "10px" }}
            onChange={(evt) => setMessage2(evt.target.value)}
            rows={4}
          />
          <HashTextField
            label="Public Key"
            value={pubKey}
            style={{ margin: "10px" }}
          />
          <HashTextField
            label="Signature"
            onChange={(evt) => setSign2(evt.target.value)}
            value={sign2}
            style={{ margin: "10px" }}
          />
          <div style={{ margin: "auto" }}>
            <Button
              style={{ height: "3rem", fontSize: "0.9rem", margin: "auto" }}
              variant="outlined"
              onClick={verify}
              disabled={sign2 === ""}
            >
              Verify Message
            </Button>
          </div>
        </Paper>
      </div>
    </CenteredLayout>
  );
};

export default Signature;
