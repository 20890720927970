import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#1890ff",
  },
});

export const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
    fontSize: "0.8rem",
    fontWeight: "bolder",
    maxWidth: 150,
  },
}));

export const CssTextField = styled(TextField)({
  "& label": {
    color: "#47307f",
    fontFamily: "Nunito",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  "& label.Mui-focused": {
    color: "#47307f",
    fontFamily: "Nunito",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#47307f",
  },
  "& .MuiOutlinedInput-root": {
    fontFamily: "Nunito",
    fontSize: "1.5rem",
    fontWeight: "bold",
    "& fieldset": {
      fontFamily: "Nunito",
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    "&:hover fieldset": {
      borderColor: "#7e6ea5",
      borderBottom: "4px solid #7e6ea5",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#47307f",
      borderBottom: "4px solid #47307f",
    },
  },
});

export const NormalTextField = styled(TextField)({
  "& label": {
    color: "#47307f",
    fontFamily: "Nunito",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  "& label.Mui-focused": {
    color: "#47307f",
    fontFamily: "Nunito",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#47307f",
  },
  "& .MuiOutlinedInput-root": {
    fontFamily: "Nunito",
    fontSize: "1rem",
    fontWeight: "bold",
    "& fieldset": {
      fontFamily: "Nunito",
      fontSize: "1rem",
      fontWeight: "bold",
    },
    "&:hover fieldset": {
      borderColor: "#7e6ea5",
      borderBottom: "4px solid #7e6ea5",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#47307f",
      borderBottom: "4px solid #47307f",
    },
  },
});

export const HashTextField = styled(TextField)({
  "& label": {
    color: "#47307f",
    fontFamily: "Nunito",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  "& label.Mui-focused": {
    color: "#47307f",
    fontFamily: "Nunito",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#47307f",
  },
  "& .MuiOutlinedInput-root": {
    fontFamily: "Nunito",
    fontSize: "1rem",
    fontWeight: "bold",

    "&:hover fieldset": {
      borderColor: "#7e6ea5",
      borderBottom: "4px solid #7e6ea5",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#47307f",
      borderBottom: "4px solid #47307f",
    },
  },
});

export const TxTextField = styled(TextField)({
  "& label": {
    color: "#47307f",
    fontFamily: "Nunito",
    fontSize: "0.8rem",
    fontWeight: "bold",
  },
  "& label.Mui-focused": {
    color: "#47307f",
    fontFamily: "Nunito",
    fontSize: "0.8rem",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#47307f",
  },
  "& .MuiOutlinedInput-root": {
    fontFamily: "Nunito",
    fontSize: "0.8rem",
    fontWeight: "bold",

    "&:hover fieldset": {
      borderColor: "#7e6ea5",
      borderBottom: "4px solid #7e6ea5",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#47307f",
      borderBottom: "4px solid #47307f",
    },
  },
});
