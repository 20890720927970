export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "To interact with the NEAR Protocol Platform, we will first need to create an account on the NEAR Protocol Platform",
        "Let's take a look at how accounts work in NEAR Protocol",
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "CreateAccount",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "StateQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'The command "near state <accountname>" outputs the current state of an account.',
        "It shows the amount of NEAR Tokens the account contains and if it has any contract deployed to it",
      ],
      primerAvatar: "showcase",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "AccountInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "SmartContractQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "NEAR Protocol is a account-based blockchain.",
        "To understand how to use NEAR Protocol, we need to understand it's account model in detail.",
        "Let's take a look a the account model next.",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "AccountsTypeInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "AccountQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Subaccounts can be created only by the immediate parent accounts",
        "Before, we take a look at how to create a subaccount...",
        "...let's look at another important part of the account: Access Keys",
      ],
      primerAvatar: "security-1",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "AccessKeysInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "AccessKeyQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "The public key is stored on the NEAR Protocol blockchain",
        "Since, NEAR Protocol is a public blockchain, anyone can query the public keys for a given account.",
        "Let's take a look at how we can check the public keys using the command-line interface",
      ],
      primerAvatar: "showcase",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "AccessKeysTypeInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "KeyQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "This brings us to the end of this topic.",
        'In the next section, we will take a deeper look at "access keys"',
        "See you in the next section, {{user}}",
      ],
      primerAvatar: "wave",
    },
  },
];
