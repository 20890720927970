import React from "react";
import { Paper } from "@mui/material";

export const NearThought = ({ block = false }) => (
  <Paper
    style={{
      background: "indigo",
      padding: "20px",
      color: "floralwhite",
      maxWidth: "400px",
    }}
  >
    <div
      style={{ margin: "0px 25px", textAlign: "center", lineHeight: "1.5rem" }}
    >
      The <b>NEAR Protocol</b> is a{" "}
      <b>De-centralised community-operated cloud-computing platform</b>, where
      anyone write and deploy <b>applications </b>
      which runs on the <b>computing and storage</b> provided by a several
      number of
      <b> participants</b>.
    </div>
  </Paper>
);

export const NearWalletTest = () => (
  <Paper
    style={{
      background: "indigo",
      padding: "20px",
      margin: "10px auto",
      color: "floralwhite",
    }}
  >
    <div
      style={{ margin: "0px 25px", textAlign: "justify", lineHeight: "1.5rem" }}
    >
      From this moment, I will assume that you have created a{" "}
      <b>testnet wallet account</b> and <b>signed in</b> with this course. If
      you are having trouble, you can check out these
      <a
        style={{ color: "floralwhite" }}
        href="https://docs.near.org/docs/develop/basics/create-account#creating-a-testnet-account"
        target="_blank"
      >
        {" "}
        docs for help
      </a>
      .
    </div>
  </Paper>
);

export const NearCli = () => (
  <Paper
    style={{
      background: "salmon",
      padding: "20px",
      margin: "10px auto",
      color: "black",
    }}
  >
    <div
      style={{
        margin: "0px 25px",
        textAlign: "justify",
        lineHeight: "1.5rem",
        fontWeight: "bold",
      }}
    >
      The <b>Command Line Interface</b> below has a subset of the capabilities
      of the actual{" "}
      <a
        style={{ color: "indigo" }}
        href="https://docs.near.org/tools/near-cli"
        target="_blank"
      >
        near-cli
      </a>
      . We have developed it to help you get up to speed with using{" "}
      <code>near-cli</code>. It can only interface with the <b>NEAR Protocol</b>{" "}
      <code>testnet</code> and requires <b>full-access permission</b> to{" "}
      <b>sign-in</b> using <code>testnet</code> wallet account.
    </div>
  </Paper>
);
