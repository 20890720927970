import React from "react";
import AliceCarousel from "react-alice-carousel";
import { sha256 } from "js-sha256";
import PlainBlock from "./DistributedPlainBlock";
import "react-alice-carousel/lib/alice-carousel.css";
import { AntTab, AntTabs } from "./styledComponents";

export const DistributedChainContext = React.createContext();

const peers = ["A", "B", "C", "D", "E"];

const responsive = {
  0: { items: 2 },
  568: { items: 2 },
  1024: { items: 3 },
};

const itemsValue = [
  {
    id: "1",
    blockNumber: 1,
    nonceValue: "54031",
    dataValue: "Hi There!",
    prev: null,
  },
  {
    id: "2",
    blockNumber: 2,
    nonceValue: "50288",
    dataValue: "This is an interactive course",
    prev: 1,
  },
  {
    id: "3",
    blockNumber: 3,
    nonceValue: "70023",
    dataValue: "Hope you like it",
    prev: 2,
  },
  {
    id: "4",
    blockNumber: 4,
    nonceValue: "53804",
    dataValue: "Because we had tons of fun creating it",
    prev: 3,
  },
  {
    id: "5",
    blockNumber: 5,
    nonceValue: "32265",
    dataValue: "So enjoy",
    prev: 4,
  },
];

const peerItemsValue = peers.reduce(
  (acc, curr) => ({ ...acc, [curr]: itemsValue }),
  {}
);

const calculateHash = (chain) => {
  let hash = chain.reduce((acc, block) => {
    const blockHash = `block${block.blockNumber}nonce${block.nonceValue}data${
      block.dataValue
    }prev${findPreviousHash(block.prev, chain)}hash`;
    acc[block.id] = sha256(blockHash);
    return acc;
  }, {});
  return hash;
};

const findPreviousHash = (prev, chain) => {
  if (prev === null) {
    return "0".repeat(64);
  } else {
    let prevBlock = chain.find((a) => parseInt(a.id) === prev);
    let prevBlockHashPrev = findPreviousHash(prevBlock.prev, chain);
    let prevBlockHash = `block${prevBlock.blockNumber}nonce${prevBlock.nonceValue}data${prevBlock.dataValue}prev${prevBlockHashPrev}hash`;
    let hash = sha256(prevBlockHash);
    return hash;
  }
};

const peerHashValue = peers.reduce(
  (acc, curr) => ({ ...acc, [curr]: calculateHash(itemsValue) }),
  {}
);

// const items = [
//   <PlainBlock />,
//   <PlainBlock />,
//   <PlainBlock />,
//   <PlainBlock />,
//   <PlainBlock />,
// ];

const generateBlocks = (chain) => {
  return chain.map((x, idx) => (
    <PlainBlock key={`item-${x.id}`} id={x.id} prev={x.prev} />
  ));
};

const DistributedBlockchain = () => {
  const [peerChain, setPeerChain] = React.useState(peerItemsValue);
  const [peer, setPeer] = React.useState(0);
  //   const [chain, setChain] = React.useState(peerChain[peers[peer]]);

  const [peerHash, setPeerHash] = React.useState(peerHashValue);
  //   const [hash, setHash] = React.useState(calculateHash(itemsValue));
  const [items] = React.useState(generateBlocks(itemsValue));

  //   const items = itemsValue.map((x, idx) => (
  //     <PlainBlock key={`item-${x.id}`} id={x.id} prev={x.prev} />
  //   ));

  React.useEffect(() => {
    // setHash(calculateHash(chain));
    setPeerHash((peerHash) => ({
      ...peerHash,
      [peers[peer]]: calculateHash(peerChain[peers[peer]]),
    }));
  }, [peerChain[peers[peer]]]);

  const chainValues = {
    peerChain,
    setPeerChain,
    peerHash,
    setPeerHash,
    peer,
    peers,
  };

  const handleChangeTab = (event, newValue) => setPeer(newValue);

  function a11yProps(index) {
    return {
      id: `peer-${index}`,
      "aria-controls": `peer-${index}`,
    };
  }

  return (
    <DistributedChainContext.Provider value={chainValues}>
      <AntTabs
        value={peer}
        onChange={handleChangeTab}
        aria-label="basic tabs example"
        centered
      >
        <AntTab label="Peer A" {...a11yProps("A")} />
        <AntTab label="Peer B" {...a11yProps("B")} />
        <AntTab label="Peer C" {...a11yProps("C")} />
        <AntTab label="Peer D" {...a11yProps("D")} />
        <AntTab label="Peer E" {...a11yProps("E")} />
      </AntTabs>
      <AliceCarousel mouseTracking items={items} responsive={responsive} />
    </DistributedChainContext.Provider>
  );
};

export default DistributedBlockchain;
