import { unified } from "unified";
import remarkParse from "remark-parse";
import remarkFront from "remark-frontmatter";
import remarkFrontParse from "remark-parse-frontmatter";
import remarkStringify from 'remark-stringify'


export const createOptions = (arr) =>
  arr.map((el) => ({ id: el, value: null }));

export const encouragingWordsGenerator = () => {
  const list = [
    "Well done",
    "Great job",
    "You surely are smart",
    "Awesome job",
    "Cool use of human brain",
    "Impressive work"
  ];
  return list[Math.floor(Math.random() * list.length)];
};

export const resizeMarkdown = (data) => {
  let finalObject = {}
  data.forEach(md => {
    unified()
      .use(remarkParse) // Parse Markdown to AST
      .use(remarkStringify)
      .use(remarkFront)
      .use(remarkFrontParse)
      .process(md)
      .then((file) => {
        if (file && file.data && file.data.frontmatter && file.data.frontmatter.title) {
          finalObject[file.data.frontmatter.title] = md
        }
      })
  } )

              return finalObject
}
