import React from "react";
import { Paper, Box, Button } from "@mui/material";

import { CssTextField, HashTextField, StyledTooltip } from "./styledComponents";
import Slider from "@mui/material/Slider";
import useSound from "use-sound";

const TARGET_MAX = 1000;

const Block = () => {
  const [diff, setDiff] = React.useState(2);
  const target = TARGET_MAX / diff;
  const prob = target / TARGET_MAX;
  return (
    <div style={{ maxWidth: "500px", margin: "30px auto" }}>
      <Paper
        elevation={3}
        sx={{
          padding: "30px",
          display: "flex",
          flexDirection: "column",
          background: "lightgreen",
        }}
      >
        <span
          style={{
            margin: "10px auto",
            fontFamily: "Nunito",
            fontWeight: "bold",
            color: "#47307f",
            fontSize: "0.8rem",
          }}
        >
          Maximum Target : 1000
        </span>
        <span
          style={{
            margin: "10px auto",
            fontFamily: "Nunito",
            fontWeight: "bold",
            color: "#47307f",
            fontSize: "0.8rem",
          }}
        >
          Target : {target.toFixed(2)}
        </span>
        <span
          style={{
            margin: "10px auto",
            fontFamily: "Nunito",
            fontWeight: "bold",
            color: "#47307f",
            fontSize: "0.8rem",
          }}
        >
          Difficulty : {diff}
        </span>
        <span
          style={{
            margin: "10px auto",
            fontFamily: "Nunito",
            fontWeight: "bold",
            color: "#47307f",
            fontSize: "0.8rem",
          }}
        >
          Probability : {prob.toFixed(4)}
        </span>
        <span
          style={{
            margin: "10px auto",
            fontFamily: "Nunito",
            fontWeight: "bold",
            color: "#47307f",
            fontSize: "0.8rem",
          }}
        >
          Time Taken : ~{diff} minutes
        </span>
        <StyledTooltip title={`Difficulty is ${diff}`} placement="right">
          <Slider
            aria-label="Target"
            value={diff}
            valueLabelDisplay="off"
            step={1}
            marks
            min={1}
            max={1000}
            onChange={(evt) => setDiff(evt.target.value)}
          />
        </StyledTooltip>
      </Paper>
    </div>
  );
};

export default Block;
