import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "storage costs of the smart contract",
    "gas involved in updating the account state",
    "network bandwidth cost",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, which of the following <b>costs</b> are involved in
            the deploying of our <b>smart contract</b>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
