import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";
import CodeComponent1 from "./CodeComponent1.mdx";

const MCQ = (props) => {
  const options = ["a_vector", "a_map", "an_array", "a_set"];
  const correctOption = "an_array";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, let's say we have the following contract code?
            <div style={{ textAlign: "left" }}>
              <CodeComponent1 />
            </div>
            Which of the following objects <b>will not be</b> stored directly in
            the <code>trie</code>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
