import React from "react";
import { ReorderQuiz } from "../../../../../components/Quiz/ReorderQuiz";

const itemsList = [
  "User sends a function call transaction request to RPC Node",
  "RPC Node puts the transaction request in a pool of transaction",
  "Transactions requested are queued after being validated to correct",
  "To execute a function call, validators spin up virtual machine",
  "Validator fetch the smart contract program and execute the requested method",
  "The virtual machine is shut down once the function gets executed",
  "User is sent the result of the transaction",
];

const ReorderTemplate = (props) => (
  <ReorderQuiz
    itemsList={itemsList}
    header={
      <React.Fragment>
        Can you rearrange how does the smart contract get executed on the{" "}
        <b>NEAR Protocol Blockchain</b>?
      </React.Fragment>
    }
    {...props}
  />
);

export default ReorderTemplate;
