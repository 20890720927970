import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AntTabs, AntTab } from "./Tabs";
{
  /* <span style={{ fontSize: "3rem" }}>{"\u201C"}</span>
 <span style={{ fontSize: "3rem" }}>{"\u201D"}</span> */
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography sx={{ color: "#47307f", display: "flex" }}>
            <span style={{ fontSize: "80px", margin: "0px", padding: "0px" }}>
              {"\u201C"}
            </span>
            <blockquote style={{ paddingTop: "10px", fontSize: "small" }}>
              {children}
            </blockquote>
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabPanelStyles = {
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  color: "#47307f",
};

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        border: "none",
        maxHeight: 424,
      }}
    >
      <AntTabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderBottom: "none",
          borderRight: 1,
          borderColor: "divider",
          minWidth: "250px",
        }}
      >
        <AntTab label="Wasteful Computations" {...a11yProps(0)} />
        <AntTab label="Energy Wastage" {...a11yProps(1)} />
        <AntTab label="High Prices" {...a11yProps(2)} />
        <AntTab label="Usability Issues" {...a11yProps(3)} />
        <AntTab label="Throughput" {...a11yProps(4)} />
        <AntTab label="Transaction Speed" {...a11yProps(5)} />
      </AntTabs>
      <TabPanel style={tabPanelStyles} value={value} index={0}>
        A large of group miners expend useful computational resources by
        generating large random numbers. This is a{" "}
        <b>wasteful usage of scarce computational resources</b> which could have
        been otherwise used productively.
        <br />
        <br />
        Miners also employ specialised circuits powered by{" "}
        <b>Graphical Process Units (GPUs)</b> which results in surging of their
        prices.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={1}>
        According to an estimate, Bitcoin Mining uses{" "}
        <b>110 Terawatt Hours per year</b> which is roughly <b>0.55%</b> of
        global electricity production. Most of electricity is generated by
        burning natural resources such coal resulting in higher carbon
        emissions.
        <br />
        <br />
        <b>Bitcoin</b> directly causes <b>large carbon emissions</b> which is
        harmful to our environment.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={2}>
        Since launching in 2009, price of a bitcoin as well it's the transaction
        fees have sky rocketed. Since, there are only limited amount of
        transactions that can be recorded in each block, users pay additional
        transaction fees to bribe the miners to include their transaction in the
        block.
        <br />
        <br />
        This drives the{" "}
        <b>price of transaction on Bitcoin to ridiculously high-level</b> and
        makes it unsuitable for transacting in our day to day lives.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={3}>
        An average person will find surrrounding the elements of using Bitcoin
        highly complicated. Although there have been tools such as wallets
        created to help with that, transactions using Bitcoin suffers from
        usability issues from the standpoint of a common person.
        <br />
        <br />
        Account names such as randomly generated hexadecimal number and an
        equally long private key are some of the flaws from{" "}
        <b>usability persepective.</b>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={4}>
        While financiall institutions such as Visa can process up to 24000
        transactions per second, Bitcoin can only process{" "}
        <b>7 transactions per second.</b>
        <br />
        <br />
        <b>
          Low throughput stands in the way for Bitcoin from becoming the world's
          digital currency.{" "}
        </b>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={5}>
        Transactions on Bitcoins takes an average of <b>10 minutes</b> to get
        confirmed. And the block is said to irreversible only if there are 6 or
        more blocks on top of it.
        <br />
        <br />
        <b>Low transaction speed is another concern in the usage of Bitcoin.</b>
      </TabPanel>
    </Box>
  );
}
