export const restPrimers = [
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "BitcoinReorder",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "Now this is a lot of information to process so far",
        "So we will take a break here and will continue learning about the bitcoin ledger in next lesson",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "wave",
    },
  },
];
