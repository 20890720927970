const { decode } = require("bs58");

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const variableDictionary = (match, dictionary) => {
  if (dictionary.hasOwnProperty(match)) {
    return dictionary[match];
  }
  return "<b>Undefined Variable</b>";
};

const replacer = (match, dictionary) => {
  return variableDictionary(match, dictionary);
};

export const replaceMessage = (str, dic) => {
  var n = str.replace(/\{\{([^}]+)\}\}/g, (match, variable) =>
    replacer(variable, dic)
  );
  return n;
};

export const implicitAccountId = (publicKey) => {
  return decode(publicKey.replace("ed25519:", "")).toString("hex");
};

export const remapQuiz = (arr) => {
  let list1 = arr.map((x) => ({
    type: "QUIZ",
    reply: null,
    props: {
      content: x,
    },
  }));
  return shuffleArray(list1);
};
