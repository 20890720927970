import { blockchain } from "./Blockchain";
import { nearProtocol } from "./NearProtocol";
import { smartContract } from "./SmartContract";
import { dapp } from "./Dapp";

export const topicData = {
  blockchain: blockchain,
  "near-protocol": nearProtocol,
  "smart-contracts": smartContract,
  dapp: dapp,
};
