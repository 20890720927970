import React from "react";
import Wrapper from "./NodeWrapper";
import { Box } from "@mui/material";
import Handle from "./Handle";
import { FULL_STORAGE, FUNCTION_STORAGE } from "./AccessKeyNode";

/*
    Account: 100 bytes
    Full Access Keys: 800 bytes
    Function Access Key: 133 bytes
*/

const storage_amount_per_byte = "10000000000000000000";

export default function AccountNode({ data }) {
  const {
    label = "",
    min = 0,
    max = 1,
    onChange = () => {},
    value = 0.5,
    near,
    storageUsed,
    funcKeys,
    fullKeys,
    contractSize,
    contract,
    contractDeployed,
  } = data;
  const finalContractSize = contractDeployed ? contractSize : 0;
  const totalStorage =
    100 +
    funcKeys * FUNCTION_STORAGE +
    fullKeys * FULL_STORAGE +
    finalContractSize * 1000 +
    storageUsed * 1000;
  const nearLocked = (totalStorage * storage_amount_per_byte) / 10 ** 24;
  const nearAvailable = near - nearLocked;
  return (
    <Wrapper label={label}>
      <Box>
        <div style={{ textAlign: "center" }}>
          <span style={{ fontWeight: "bold", fontSize: "12px" }}>
            Total Storage Used
          </span>{" "}
          <br />
          <span
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              margin: "4px auto",
              color: "#47307f",
            }}
          >
            {totalStorage} B
          </span>{" "}
          <br />
          <span style={{ fontWeight: "bold", fontSize: "12px" }}>
            NEAR Locked
          </span>{" "}
          <br />
          <span
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              margin: "4px auto",
              color: "#47307f",
            }}
          >
            {nearLocked.toPrecision(4)} Ⓝ
          </span>
          <br />
          <span style={{ fontWeight: "bold", fontSize: "12px" }}>
            NEAR Available
          </span>{" "}
          <br />
          <span
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              margin: "4px auto",
              color: "#47307f",
            }}
          >
            {nearAvailable.toPrecision(4)} Ⓝ
          </span>
        </div>
        <Handle type="target" position="left" id="account" />
        <Handle
          type="target"
          position="left"
          style={{ top: 60 }}
          id="accessKey"
        />
        <Handle type="target" position="bottom" id="contract" />
        <Handle type="target" id="storage" position="bottom" />
      </Box>
    </Wrapper>
  );
}
