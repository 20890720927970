import { userDefinesBlockchainPrimers } from "./UserDefinesBlockchain";

export const excitedPrimers = [
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "I am glad to hear that, {{user}}",
        "You will love what we have in store for you then",
        "But before we proceed, would you like to define what a blockchain is?",
      ],
      primerAvatar: "thumbs-up",
      textReply: { que: "What do you think is blockchain?" },
      userReply: { reply: null, userAvatar: "Cheeks" },
    },
  },
  ...userDefinesBlockchainPrimers,
];

const alrightPrimers = [
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "Awesome, {{user}}! ",
        "I promise too, that it will be well worth your time.",
        "Before we begin learning about Blockchains, why don't you go ahead and write what do you think is a blockchain?",
      ],
      primerAvatar: "thumbs-up",
      textReply: { que: "What do you think is blockchain?" },
      userReply: { reply: null, userAvatar: "Cheeks" },
    },
  },
  ...userDefinesBlockchainPrimers,
];

const finePrimers = [
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "Excellent, {{user}}. ",
        "It will be a wild ride so best buckle in.",
        "Before we proceed, why don't you try to describe what Blockchain is?",
      ],
      primerAvatar: "thumbs-up",

      textReply: { que: "What do you think is blockchain?" },
      userReply: { reply: null, userAvatar: "Cheeks" },
    },
  },
  ...userDefinesBlockchainPrimers,
];

const nopeEnthusiasticPrimers = [
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "In that case, {{user}}, I am sorry, I won't be able to help you.",
        "I won't be able to teach you about blockchain.",
        "Goodbye, {{user}}",
      ],
      primerAvatar: "roll-eyes",
    },
  },
];

const whateverButtonOptions = [
  {
    text: "Fine",
    userReply: {
      reply: [
        "Okay fine, Primer",
        "Yes, I will try to be excited to learn about blockchains",
      ],
      userAvatar: "Happy",
    },
    primers: finePrimers,
  },
  {
    text: "Nope",
    userReply: {
      reply: [
        "Nope, Primer",
        "I am not at all enthusiastic about learning blockchains, Primer.",
      ],
      userAvatar: "Sleepy",
    },
    primers: nopeEnthusiasticPrimers,
  },
];

const whateverPrimers = [
  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      chats: [
        "You know, {{user}}, a little bit of enthusiam will go a long way in helping you learn things.",
        "Someone rightly said, “Being enthusiastic is worth 25 IQ points”. ",
        "So, {{user}}, will you try to be a little bit enthusiastic about learning Blockchain as well?",
      ],
      buttonOptions: whateverButtonOptions,
      userReply: { reply: null, userAvatar: null },
      primerAvatar: "sorry",
    },
  },
];

const notExcitedbuttonOptions = [
  {
    text: "Alright, I promise",
    userReply: {
      reply: [
        "Alright, Primer.",
        "I promise I will try to stay on track.",
        "And will be excited to learn about blockchains as well.",
      ],
      userAvatar: "Cute",
    },
    primers: alrightPrimers,
  },
  {
    text: "Whatever",
    userReply: {
      reply: [
        "Whatever, Primer !",
        "I don't think you need to enthusiastic to learn blockchain. ",
      ],
      userAvatar: "Smirk",
    },
    primers: whateverPrimers,
  },
];

const notExcitedPrimers = [
  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      chats: [
        "Well, {{user}}, in that case, I will try my best to change your mind using some cool interactive examples.",
        "Just promise me that you will do your best to stay on track of the course.",
        "You will love what we have in store for you then, {{user}}.",
      ],

      buttonOptions: notExcitedbuttonOptions,
      userReply: { reply: null, userAvatar: null },
      primerAvatar: "sad",
    },
  },
];

const chat1buttonOptions = [
  {
    text: "Yep",
    userReply: {
      reply: [
        "Hi Primer",
        "Yes, I am pretty excited to learn about Blockchains",
      ],
      userAvatar: "Cute",
    },
    primers: excitedPrimers,
  },
  {
    text: "Nope",
    userReply: {
      reply: [
        "Hello Primer",
        "Ummm",
        "Not really excited to learn about blockchains",
      ],
      userAvatar: "Boring",
    },
    primers: notExcitedPrimers,
  },
];

// Chat-1
export const introductionPrimers = [
  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      chats: [
        "Hello {{user}} !",
        "Welcome to Introduction to Blockchain Quest.",
        "I hope you are excited to learn about blockchains.",
      ],
      buttonOptions: chat1buttonOptions,
      userReply: { reply: null, userAvatar: null },
      primerAvatar: "wave",
    },
  },
];
