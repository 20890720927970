import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["Will be Successful", "Will fail", "Cannot Say"];
  const correctOption = "Will fail";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, a <code>near</code> account having just the exact
          balance to cover for it's used <code>storage</code> sends a{" "}
          <code>transaction</code> request to add an <code>access key</code> to
          the account. What will be the outcome of the <code>transaction</code>{" "}
          request?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
