import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Getting new NEAR Tokens for their staked amount",
    "NEAR tokens getting locked for storage staking",
    "Gas fees are burned",
    "All of the above are incentives",
  ];
  const correctOption = "All of the above are incentives";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which of the following is not an incentive for{" "}
          <b>validators</b> for securing the <b>NEAR Protocol Blockchain</b>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
