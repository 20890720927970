import { Outlet, Route, Routes } from "react-router-dom";
import { useAppContext } from "../../AppContext";
import QuestPage from "./QuestPage";

const Quest = (props) => {
  const context = useAppContext();
  return (
    <div>
      <Routes>
        <Route path=":questId" element={<QuestPage context={context} />} />
      </Routes>
    </div>
  );
};

export default Quest;
