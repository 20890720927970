import React from "react";

import { Box, Paper } from "@mui/material";

export default function NodeWrapper({ label, children }) {
  return (
    <Paper
      style={{
        flexDirection: "column",
        height: "100%",
        border: "1px solid",
        borderColor: "gray",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow:
          "0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06)",
      }}
    >
      <Box
        className={`custom-drag-${label}`}
        style={{
          fontWeight: "normal",
          textTransform: "uppercase",
          fontFamily: "JetBrains Mono, monospace",
          fontSize: "14px",
          borderBottom: "1px solid",
          padding: "4px",
          borderColor: "gray",
          color: "darkgray",
          textAlign: "center",
        }}
      >
        {label}
      </Box>
      <Box style={{ position: "relative", padding: "20px" }}>{children}</Box>
    </Paper>
  );
}
