import {
  StyledTableRow,
  StyledTableCell,
} from "../../../../../components/Common/Table.jsx";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";

const BlockTimeTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "700px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Blockchain Name
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Approximate Block Time
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Bitcoin</StyledTableCell>
            <StyledTableCell variant="body">~ 10 minutes</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Ethereum</StyledTableCell>
            <StyledTableCell variant="body">~ 12-14 seconds</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Near</StyledTableCell>
            <StyledTableCell variant="body">~ 1 second</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const BlockDifficultyOverTime = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "700px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Bitcoin Block
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Addition Date
            </StyledTableCell>

            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Difficulty
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Block 1</StyledTableCell>
            <StyledTableCell variant="body">Eight</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Block 50000</StyledTableCell>
            <StyledTableCell variant="body">~ 12-14 seconds</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Near</StyledTableCell>
            <StyledTableCell variant="body">~ 1 second</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlockTimeTable;
