import * as nearAPI from "near-api-js";
import { implicitAccountId } from "./utils";
import { parseSeedPhrase } from "near-seed-phrase";

// creates keyStore using private key in local storage
// *** REQUIRES SignIn using walletConnection.requestSignIn() ***

const { connect, keyStores, WalletConnection, utils, KeyPair, Contract } =
  nearAPI;

const config = {
  networkId: "testnet",
  keyStore: new keyStores.BrowserLocalStorageKeyStore(
    window.localStorage,
    "cli-near-api-js:keystore:"
  ),
  nodeUrl: "https://rpc.testnet.near.org",
  walletUrl: "https://wallet.testnet.near.org",
  helperUrl: "https://helper.testnet.near.org",
  explorerUrl: "https://explorer.testnet.near.org",
};

const PENDING_ACCESS_KEY_PREFIX = "pending_key";

export const formatUtils = utils;
// connect to NEAR
export const near = await connect(config);

// create wallet connection
export const wallet = new WalletConnection(near, `cli`);
if (wallet.isSignedIn()) {
  // returns account Id as string
  const walletAccountId = wallet.getAccountId();
  // returns account object for transaction signing
  const walletAccountObj = wallet.account();
}

// async function createFullAccessKey(accountId) {
//   const keyPair = KeyPair.fromRandom("ed25519");
//   const publicKey = keyPair.publicKey.toString();
//   const near = await connect(config);
//   const account = await near.account(accountId);
//   await config.keyStore.setKey(config.networkId, publicKey, keyPair);
//   await account.addKey(publicKey);
// }

// redirects user to wallet to authorize your dApp
// this creates an access key that will be stored in the browser's local storage
// access key can then be used to connect to NEAR and sign transactions via keyStore

const loginFullAccess = async (options = {}) => {
  const currentUrl = new URL(window.location.href);
  const newUrl = new URL(wallet._walletBaseUrl + "/login/");
  newUrl.searchParams.set("success_url", options.successUrl || currentUrl.href);
  newUrl.searchParams.set("failure_url", options.failureUrl || currentUrl.href);

  const accessKey = KeyPair.fromRandom("ed25519");
  newUrl.searchParams.set("public_key", accessKey.getPublicKey().toString());
  await wallet._keyStore.setKey(
    wallet._networkId,
    PENDING_ACCESS_KEY_PREFIX + accessKey.getPublicKey(),
    accessKey
  );

  window.location.assign(newUrl.toString());
};

export const signIn = () => {
  // wallet.requestSignIn({
  //   contractId: "nearprotocolprimer.testnet",
  //   methodNames: ["createAccount", "create_account"],
  // });
  loginFullAccess();
};

export const mergeKeystore = async (nearKeys, nearObject) => {
  for (const key of nearKeys) {
    const keyPair = KeyPair.fromString(nearObject[key]);
    await wallet._keyStore.setKey(
      wallet._networkId,
      PENDING_ACCESS_KEY_PREFIX + keyPair.getPublicKey().toString(),
      nearObject[key]
    );
  }
  return "Completed Transfer";
};

// Create Account

export const createAccount = async (
  accountId,
  masterAccount,
  initialBalance
) => {
  const keyPair = await KeyPair.fromRandom("ed25519");
  const publicKey = keyPair.getPublicKey().toString();

  // Check to see if account already exists
  try {
    const account = await near.account(accountId);
    await account.state();
    return {
      type: "error",
      message: `Sorry, account '${accountId}' already exists`,
    };
  } catch (e) {
    if (!e.message.includes("does not exist while viewing")) {
      return {
        type: "error",
        message: e.message,
      };
    }
  }

  // near create-account app1.siddharthkanungo.testnet --masterAccount siddharthkanungo.testnet --initialBalance 0.1

  try {
    const creatorAccount = await near.account(masterAccount);
    const { secretKey } = keyPair;
    await config.keyStore.setKey("testnet", accountId, keyPair);
    const response = await creatorAccount.createAccount(
      accountId,
      publicKey,
      utils.format.parseNearAmount(initialBalance)
    );

    // const response = await creatorAccount.functionCall({
    //   contractId: "testnet",
    //   methodName: "create_account",
    //   args: {
    //     new_account_id: accountId,
    //     new_public_key: publicKey,
    //   },
    //   gas: "300000000000000",
    //   attachedDeposit: utils.format.parseNearAmount(initialBalance),
    // });

    const message = `
    Generated the secret key
    
    Saving key to near-api-js:keystore:[[${accountId}]]:testnet in the **browser's localstorage**
    Account [[${accountId}]] for network [["testnet"]] was created.
    `;
    return { type: "success", response, message };
  } catch (error) {
    if (error.type === "RetriesExceeded") {
      const error = `error`;
      const message = `Received a timeout when creating account,  please run:
      [[near state]] ${accountId}
to confirm creation. Keyfile for this account has been saved.
      `;

      return { error, message };
    } else {
      await near.connection.signer.keyStore.removeKey("testnet", accountId);
      throw error;
    }
  }
};

// Get Account State

export const getStateAccount = async (accountId) => {
  try {
    const account = await near.account(accountId);
    const response = await account.state();
    return {
      ...response,
      formattedAmount: nearAPI.utils.format.formatNearAmount(response.amount),
    };
  } catch (e) {
    if (e.type === "AccountDoesNotExist") {
      throw {
        message: `Account: ${accountId} doesn't exist on the testnet`,
      };
    }
    throw e;
  }
};

// Get Account keys

export const getAccountKeys = async (accountId) => {
  try {
    const account = await near.account(accountId);
    const response = await account.getAccessKeys();

    return response;
  } catch (e) {
    if (e.type === "AccountDoesNotExist") {
      throw {
        message: `Account: ${accountId} doesn't exist on the testnet`,
      };
    }
    throw e;
  }
};

// Delete Account

// near delete app.siddharthkanungo.testnet siddharthkanungo.testnet

export const deleteAccount = async (accountId, beneficiaryId) => {
  try {
    // Check if the accountID exists
    try {
      const account = await near.account(accountId);
      await account.state();
    } catch (e) {
      if (e.type === "AccountDoesNotExist") {
        throw {
          message: `Account: ${accountId} doesn't exist on the testnet`,
        };
      }
      if (!e.message.includes("does not exist while viewing")) {
        return {
          type: "error",
          message: e.message,
        };
      }
    }
    // Check if the beneficiaryID exists
    try {
      const account = await near.account(beneficiaryId);
      await account.state();
    } catch (e) {
      if (e.type === "AccountDoesNotExist") {
        throw {
          message: `Beneficiary Account: ${beneficiaryId} doesn't exist on the testnet`,
        };
      }
      if (!e.message.includes("does not exist while viewing")) {
        return {
          type: "error",
          message: e.message,
        };
      }
    }

    const account = await near.account(accountId);
    const response = await account.deleteAccount(beneficiaryId);
    return response;
  } catch (e) {
    if (e.type === "KeyNotFound") {
      throw {
        message: `Can not sign transactions for account ${accountId} on network testnet, no matching key pair found in the localstorage`,
      };
    }
    if (e.key !== "SignerDoesNotExist") {
      console.log(e);
      throw {
        type: "error",
        message: e.message,
      };
    }
  }
};

// GENERATE KEY COMMAND

export const generateKey = async (accountId = null, seedPhrase = null) => {
  try {
    if (accountId === null && seedPhrase !== null) {
      const phrase = seedPhrase.join(" ");
      const parseSeed = parseSeedPhrase(phrase);
      const { publicKey, secretKey } = parseSeed;
      const keyPair = KeyPair.fromString(secretKey);
      const account = accountId || implicitAccountId(publicKey);
      await config.keyStore.setKey(config.networkId, publicKey, keyPair);
      return { type: "success", account, publicKey };
    } else if (accountId !== null && seedPhrase === null) {
      const keyPair = KeyPair.fromRandom("ed25519");
      const publicKey = keyPair.publicKey.toString();
      const account = accountId;
      let prevAccount = null;
      const existingKey = await config.keyStore.getKey(
        config.networkId,
        account,
        keyPair
      );
      if (existingKey !== null) {
        prevAccount = existingKey.getPublicKey().toString();
      }

      await config.keyStore.setKey(config.networkId, account, keyPair);
      return { type: "success", account, publicKey, prevAccount };
    } else if (accountId !== null && seedPhrase !== null) {
      const phrase = seedPhrase.join(" ");
      const parseSeed = parseSeedPhrase(phrase);
      const { publicKey, secretKey } = parseSeed;
      const keyPair = KeyPair.fromString(secretKey);
      const account = accountId;
      let prevAccount = null;
      const existingKey = await config.keyStore.getKey(
        config.networkId,
        account,
        keyPair
      );
      if (existingKey !== null) {
        prevAccount = existingKey.getPublicKey().toString();
      }
      await config.keyStore.setKey(config.networkId, account, keyPair);
      return { type: "success", account, publicKey, prevAccount };
    } else {
      const keyPair = KeyPair.fromRandom("ed25519");
      const publicKey = keyPair.publicKey.toString();
      const account = accountId || implicitAccountId(publicKey);

      await config.keyStore.setKey(config.networkId, publicKey, keyPair);
      // const account = await near.account(accountId);
      // const response = await account.getAccessKeys();
      return { type: "success", account, publicKey };
    }
  } catch (e) {
    throw e;
  }
};

// ADD ACCESS KEY COMMAND

export const addAccessKey = async (
  accountId = null,
  accessKey = null,
  contractId = null,
  methodNames = [],
  allowance = null,
  fullAccess = true
) => {
  try {
    if (fullAccess) {
      const account = await near.account(accountId);
      const response = await account.addKey(accessKey);
      return response;
    } else {
      const account = await near.account(accountId);
      const response = await account.addKey(
        accessKey,
        contractId,
        methodNames,
        allowance
      );
      return response;
    }
  } catch (e) {
    throw e;
  }
};

// DELETE KEY COMMAND

export const deleteAccessKey = async (accountId = null, accessKey = null) => {
  try {
    const account = await near.account(accountId);
    const response = await account.deleteKey(accessKey);
    return response;
  } catch (e) {
    throw e;
  }
};

// SEND NEAR TOKENS COMMAND

export const sendNear = async (senderId, receiverId, amount) => {
  try {
    const account = await near.account(senderId);
    const amountInYocto = utils.format.parseNearAmount(amount);
    const response = await account.sendMoney(
      receiverId, // receiver account
      amountInYocto // amount in yoctoNEAR
    );
    return response;
  } catch (e) {
    throw e;
  }
};

// DEPLOY CONTRACT

export const deployContract = async (accountId, wasmFile, options) => {
  let data;
  let buf;
  try {
    // Downloading the wasm_file
    data = await fetch(`/wasm/${wasmFile}`);
    console.log(data);
    buf = await data.arrayBuffer();
  } catch (e) {
    throw e;
  }
  try {
    const account = await near.account(accountId);
    const response = await account.deployContract(new Uint8Array(buf));
    return response;
  } catch (e) {
    throw e;
  }
};

// CREATE DEV ACCOUNT

export const createDevAccount = async (masterAccount = null) => {
  let accountId;
  try {
    // create random number with at least 14 digits
    const randomNumber = Math.floor(
      Math.random() * (99999999999999 - 10000000000000) + 10000000000000
    );

    if (masterAccount !== null) {
      accountId = `dev-${Date.now()}.${masterAccount}`;
    } else {
      accountId = `dev-${Date.now()}-${randomNumber}`;
    }

    const keyPair = await KeyPair.fromRandom("ed25519");
    await near.accountCreator.createAccount(accountId, keyPair.publicKey);
    await config.keyStore.setKey(config.networkId, accountId, keyPair);
  } catch (e) {
    throw e;
  }

  return accountId;
};

// DEV-DEPLOY CONTRACT

export const devDeployContract = async (wasmFile, options) => {
  let accountId;
  let data;
  let buf;
  let previous = false;

  const devAccounts = Object.keys(localStorage).filter((x) =>
    x.includes("keystore:dev-")
  );

  if (devAccounts[0] && devAccounts[0].split(":").length === 4) {
    accountId = devAccounts[0].split(":")[2];
    previous = true;
  } else {
    try {
      // create random number with at least 14 digits
      const randomNumber = Math.floor(
        Math.random() * (99999999999999 - 10000000000000) + 10000000000000
      );

      if (options.masterAccount) {
        accountId = `dev-${Date.now()}.${options.masterAccount}`;
      } else {
        accountId = `dev-${Date.now()}-${randomNumber}`;
      }

      const keyPair = await KeyPair.fromRandom("ed25519");
      await near.accountCreator.createAccount(accountId, keyPair.publicKey);
      await config.keyStore.setKey(config.networkId, accountId, keyPair);
    } catch (e) {
      throw e;
    }
  }

  try {
    // Downloading the wasm_file
    data = await fetch(`/wasm/${wasmFile}`);
    buf = await data.arrayBuffer();
  } catch (e) {
    throw e;
  }
  try {
    console.log(accountId);
    const account = await near.account(accountId);
    const response = await account.deployContract(new Uint8Array(buf));
    return { response, accountId, previous };
  } catch (e) {
    throw e;
  }
};

// CALL CONTRACT

export const callContract = async (
  contractName = null,
  methodName = null,
  options = {}
) => {
  try {
    // console.log(JSON.parse(options.args));
    const account = await near.account(options.accountId);
    const parsedArgs = options.base64
      ? Buffer.from(options.args, "base64")
      : JSON.parse(options.args);

    const parsedDeposit =
      options.depositYocto != null
        ? options.depositYocto
        : utils.format.parseNearAmount(options.deposit);

    const functionCallOptions = {
      contractId: contractName,
      methodName: methodName,
      args: parsedArgs,
      gas: options.gas,
      attachedDeposit: parsedDeposit,
    };

    try {
      const response = await account.functionCall(functionCallOptions);
      return response;
    } catch (e) {
      // console.log(e);
      throw e;
    }
  } catch (e) {
    // console.log(e, "Something went wrong");
    throw e;
  }
};

// CALL VIEW FUNCTIOn

export const callViewFunction = async (
  contractName = null,
  methodName = null,
  options = {}
) => {
  try {
    const account = await near.account(options.accountId);
    const response = await account.viewFunction(
      contractName,
      methodName,
      JSON.parse(options.args || "{}")
    );
    return response;
  } catch (e) {
    throw e;
  }
};

// CALL VIEW-STATE FUNCTION

export const callViewStateFunction = async (
  contractName = null,
  methodName = null,
  options = {}
) => {
  try {
    const account = await near.account(contractName);

    const response = await account.viewState("", {
      block_id: options.blockId,
      finality: options.finality,
    });

    let state;
    if (options.utf8) {
      state = response.map(({ key, value }) => ({
        key: key.toString("utf-8"),
        value: value.toString("utf-8"),
      }));
    } else {
      state = response.map(({ key, value }) => ({
        key: key.toString("base64"),
        value: value.toString("base64"),
      }));
    }
    return state;
  } catch (e) {
    throw e;
  }
};

// GET TX-STATUS

export const getTxStatus = async (decodedHash = null, accountId = null) => {
  try {
    const status = await near.connection.provider.txStatus(
      decodedHash,
      accountId
    );
    return status;
  } catch (e) {
    throw e;
  }
};
