import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

export default function PopperHover({
  content,
  children,
  block = false,
  white = false,
  link = false,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const openLink = () => {
    window.open(link, "_newtab");
  };

  return (
    <React.Fragment>
      <Typography
        sx={{
          color: white ? "floralwhite" : "#47307f",
          fontWeight: "bold",
          textDecoration: "dashed underline 3px",
          textUnderlineOffset: "0.2rem",
          cursor: link ? "pointer" : "zoom-in",
        }}
        onClick={openLink}
        component={"span"}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {block ? (
          <React.Fragment>{content}</React.Fragment>
        ) : (
          <Typography
            sx={{
              p: 1,
              color: "floralwhite",
              fontWeight: "bold",
              background: "#47307f",
              padding: "15px",
              maxWidth: "400px",
              textAlign: "center",
            }}
          >
            {content}
          </Typography>
        )}
      </Popover>
    </React.Fragment>
  );
}
