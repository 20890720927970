import React from "react";
import TeX from "@matejmazur/react-katex";
import { Paper } from "@mui/material";
import { PrettoSlider } from "../../../../../components/Explorables/Common/Canvas/StorageCanvas/Slider";
import {
  factorial,
  pow,
  multiply,
  bignumber,
  subtract,
  fraction,
  divide,
  format,
} from "mathjs";

const FIXED = factorial(bignumber(365));

const Probability = () => {
  const [guests, setGuests] = React.useState(10);
  const [result, setResult] = React.useState(0);

  React.useEffect(() => {
    calculateProbability();
  }, [guests]);

  const calculateProbability = () => {
    const power = bignumber(pow(365, guests));
    const factor = factorial(bignumber(365 - guests));
    const denominator = multiply(power, factor);
    const numerator = subtract(denominator, FIXED);
    const result = fraction(divide(numerator, denominator));

    setResult(format(result, { fraction: "decimal" }));
  };

  return (
    <div style={{ textAlign: "center" }}>
      {" "}
      <h3>
        Number of Guests: <span style={{ color: "salmon" }}>{guests}</span>
      </h3>
      <h3>
        Probability of two people sharing the birthday:{" "}
        <span style={{ color: "salmon" }}>{result}</span>
      </h3>
      <PrettoSlider
        size="small"
        value={guests}
        valueLabelDisplay="auto"
        onChange={(evt) => {
          setGuests(evt.target.value);
        }}
        step={1}
        min={0}
        max={70}
      />
    </div>
  );
};

const firstEquation =
  "\\frac{365}{365}\\times\\frac{364}{365}\\times\\frac{363}{365}\\times...\\times\\frac{365-n+1}{365}=\\frac{365!}{365^{n}(365-n)!}";
const secondEquation = "1 - \\frac{365!}{365^{n}(365-n)!}";

export const Birthday = () => (
  <Paper
    style={{
      background: "indigo",
      padding: "20px",
      margin: "10px auto",
      color: "floralwhite",
    }}
  >
    {" "}
    <div
      style={{
        margin: "0px 25px",
        lineHeight: "1.5rem",
        fontWeight: "bold",
      }}
    >
      <h2 style={{ color: "salmon", textAlign: "center" }}>Birthday Problem</h2>
      Let's say you are in a room of <TeX math="n" /> people, none of whom were
      born on February 29th. What is the likelihood that two of them will share
      a birthday? <br />
      To do the calculation, consider each person in order. <br />
      <br />
      The first person might have a birthday on any of the 365 days of the year
      without conflict, so we have a 365/365 chance of no conflict. <br />
      <br />
      The second person has 364 days his birthday might be to avoid a conflict,
      so he has a 364/365 chance of no conflict. <br />
      <br />
      The third person has 363 days to choose from, if there is to be no
      conflict, and so on. <br />
      <br />
      We see that the chance of there not being a conflict is:
      <TeX block math={firstEquation} />
      So, the probability of there being a conflict is:
      <TeX block math={secondEquation} />
      We can simulate this by the following model:
      <Probability />
      So, we can see that for just 30 people, the probability of two people
      sharing the same birthday goes up to{" "}
      <span style={{ color: "salmon" }}>7 in 10</span>, which is fairly high.{" "}
      <br />
    </div>
  </Paper>
);
