import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Only WASM code can be executed on NEAR Protocol",
    "Easier to debug",
    "Saves money",
    "Javascript is difficult to work with",
  ];
  const correctOption = "Only WASM code can be executed on NEAR Protocol";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            Using the <code>build</code> command, we can compile the{" "}
            <b>smart contract</b> written in <code>javascript</code> to{" "}
            <code>wasm</code>. {props.name}, why do you think we do that?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
