import React from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "aliceblue",
    color: "aliceblue",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    fontWeight: "bold",
    fontFamily: "Nunito",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: "lightcoral",
  //   },
  //   "&:nth-of-type(even)": {
  //     backgroundColor: "aliceblue",
  //   },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const ProbabilityTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "800px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "aliceblue" }}>
            <StyledTableCell variant="body">Data</StyledTableCell>
            <StyledTableCell variant="body">Description</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Account ID</StyledTableCell>
            <StyledTableCell variant="body">
              Identifier of the account such as "primerlabs.near"
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Balance</StyledTableCell>
            <StyledTableCell variant="body">
              Available NEAR Tokens Ⓝ stored in the Account
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">
              Code of the Contract
            </StyledTableCell>
            <StyledTableCell variant="body">
              Hash of the Smart Contract Deployed
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Key-value storage</StyledTableCell>
            <StyledTableCell variant="body">
              Storage used for the contract
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Access Keys</StyledTableCell>
            <StyledTableCell variant="body">
              List of Access Keys for the account
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProbabilityTable;
