import { OutputFactory, Outputs, FileSystem } from "javascript-terminal";
import { CustomOutputFactory } from "../outputs";
import { implicitAccountId } from "../../../../../utils";
import { KeyPair } from "near-api-js";

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const generateKeyMapPair = (keys) => {
  const keyPair = KeyPair.fromRandom("ed25519");
  const reducedKeys = keys
    .reduce((acc, curr, index) => {
      const splitString = curr.split(":");
      const splitStringLength = splitString.length;
      if (splitStringLength === 4) {
        const keyPair = KeyPair.fromString(localStorage.getItem(curr));
        const publicKey = keyPair.getPublicKey().toString();
        return [
          ...acc,
          {
            id: index,
            keyId: curr,
            accountId: splitString[2],
            publicKey: publicKey,
            secretKey: localStorage.getItem(curr),
          },
        ];
      } else if (splitStringLength === 5) {
        const keyPair = KeyPair.fromString(localStorage.getItem(curr));
        const publicKey = keyPair.getPublicKey().toString();
        return [
          ...acc,
          {
            id: index,
            keyId: curr,
            accountId: implicitAccountId(publicKey),
            publicKey: `${splitString[2]}:${splitString[3]}`,
            secretKey: localStorage.getItem(curr),
          },
        ];
      } else {
        return [...acc, null];
      }
    }, [])
    .filter((y) => y !== null);

  return reducedKeys;
};

export const getTxId = (res) => {
  if (!res || !res.transaction) {
    return null;
  }

  return res.transaction.hash;
};

export const updateState = (type, message, setCustomState, setAcceptInput) => {
  if (type === "error") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(`&&${message}&&`)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  } else if (type === "success") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(message)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  }

  setAcceptInput(true);
};

const downloadContent = `File is too big to display. Use \`download [filename]\` command to download and view the contents
`;

const helloContent = `
export function helloWorld(): string {
  return 'hello world'
}

export function greet(name:string): string {
  return \`hello \${name}!\`
}
`;

export const customFileSystem = FileSystem.create({
  "/hello.wat": { content: downloadContent, canModify: false },
  "/hello.wasm": { content: downloadContent, canModify: false },
  "/hello.ts": { content: helloContent, canModify: false },
});

export const getURL = (fileName) =>
  ({
    "hello.wat":
      "https://nearprotocolprimer-wasmfiles.s3.amazonaws.com/hello.wat",
    "hello.wasm":
      "https://nearprotocolprimer-wasmfiles.s3.amazonaws.com/hello.wasm",
  }[fileName]);

export const optDef = {
  "-h, --help": "",
  "--masterAccount": "accountId",
  "--initialBalance": "100",
  "--contract-id": "contractId",
  "--method-names":
    "method_1 method_2 method_3 method_4 method_5 method_6 method_7 method_8",
  "--allowance": "0.25",
  "--seedPhrase":
    "word1 word2 word3 word4 word5 word6 word7 word8 word9 word10 word11 word 12",
  "--accountId, --account_id, --contractName, --contract_name": "accountId",
  "--wasmFile, --wasm_file": "./out/main.wasm",
  "--initGas": "1b48eb57e000",
  "--initDeposit": "0",
  "--initArgs": "string",
  "--initFunction": "string",
  "--gas": "string",
  "--deposit, --amount": "string",
  "--depositYocto": "string",
  "--finality": "string",
  "--blockId, --block-id": "string",
  "--utf8": "boolean",
};
