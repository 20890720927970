import React from "react";
import { Paper, TextField, InputAdornment, Button } from "@mui/material";
import * as nearAPI from "near-api-js";

const { generateSeedPhrase } = require("near-seed-phrase");

function toHexString(byteArray) {
  return Array.from(byteArray, function (byte) {
    return ("0" + (byte & 0xff).toString(16)).slice(-2);
  }).join("");
}

const StorageExplorable = React.memo(() => {
  const [publicKey, setPublicKey] = React.useState("");
  const [implicitID, setImplicitID] = React.useState("");

  React.useEffect(() => {
    const keypair = generateSeedPhrase();
    setPublicKey(keypair.publicKey);
  }, []);

  const generateRandom = () => {
    const keypair = generateSeedPhrase();
    setPublicKey(keypair.publicKey);
  };

  //   React.useEffect(() =>
  //     if (publicKey && publicKey !== "") {

  //         console.log(keyPair)
  //       const getImplicitAccount =
  //         publicKey !== ""
  //           ? nearAPI.utils.PublicKey.fromString(publicKey).data.hexSlice()
  //           : "";
  //       console.log(getImplicitAccount);
  //     }
  //   }, [publicKey]);

  React.useEffect(() => {
    getImplicit();
  }, [publicKey]);

  const getImplicit = () => {
    try {
      const key = publicKey.startsWith("ed25519:")
        ? publicKey.slice(8)
        : publicKey;

      const decodedKey = nearAPI.utils.PublicKey.fromString(key).data;

      setImplicitID(toHexString(decodedKey));
    } catch (e) {
      setImplicitID(e.message);
    }
  };

  return (
    <div>
      <Paper
        elevation={1}
        style={{
          background: "floralwhite",
          width: "600px",
          margin: "10px auto",
          borderRadius: "12px",
          textAlign: "center",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h1 style={{ color: "indigo" }}>Implicit Account ID</h1>
        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
          {implicitID}
        </span>
        <TextField
          onChange={(evt) => setPublicKey(evt.target.value)}
          value={publicKey}
          label="Public Key"
          style={{ margin: "10px auto", fontWeight: "bold", width: "100%" }}
        />

        <div>
          <Button
            onClick={generateRandom}
            style={{ fontSize: "16px", height: "34px", margin: "10px" }}
            variant="outlined"
          >
            Generate Random
          </Button>
        </div>
      </Paper>
    </div>
  );
});

export default StorageExplorable;
