import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";
const MCQ = (props) => {
  const options = [
    "Unused gas attached in a call is given to the validators",
    "NEAR tokens attached to a call that fails is lost",
    "Cross-contract call and its callback are executed same block",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, which of the following statement is <b>false</b>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
