export const timeStampPrimers = [
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "PriorityInfo",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      primerAvatar: "search",
      chats: [
        '{{user}}, as you can notice "time-stamping" documents were pretty useful in ascertaining priority in the case of researchers and inventors.',
        "Let's take a short quiz regarding time-stamping.",
      ],
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "TimeStampMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      primerAvatar: "normal",
      chats: [
        "Time-stamping a physical document can be useful in determining that the document existed in that particular time ",
        'Also, when a "time-stamped document" is tampered, there are usually telltale signs to imply so',
        "However, time-stamping digital documents doesn't usually leave such signs",
        "Let's take a look at how can we time-stamp digital documents",
      ],
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "TimeStampingAuthorityInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "TSSMCQ",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "TSSInfo",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "{{user}}, so far we have been learning a lot about time-stamping.",
        "...and learning a lot about Haber and Stornetta's paper",
        "But how do you think all of this relates to Blockchain?",
      ],
      primerAvatar: "puzzle",

      textReply: { que: "How does time-stamping relates to Blockchain?" },
      userReply: { reply: null, userAvatar: "Boring" },
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      primerAvatar: "wave",
      chats: [
        "Well, this particular paper happens to be the basis of creation of blockchain as we know it today.",
        "Stornetta and Haber created the world's first blockchain which has been running successfully since 1995.",
        "And in the next section, we will learn more about this blockchain in little bit more detail",
        "See you in the next section, {{user}}",
      ],
    },
  },
];
