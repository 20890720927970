import accountStatePrimer from "./AccountState";

const noPrimer = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Correct, {{user}}",
        "It's not possible to transfer `testnet` tokens to `mainet` account, because both of them are two separate network.",
        "Each network separately maintains different set of accounts and transactions can only occur in between accounts in same network",
        "Let's take a look at how validators maintain the state of an account",
      ],
      primerAvatar: "thumbs-up",
    },
  },
  ...accountStatePrimer,
];

const yesPrimer = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Hmmm.. wrong, {{user}}",
        "It's not possible to transfer `testnet` tokens to `mainet` account, because both of them are two separate network.",
        "Each network separately maintains different set of accounts and transactions can only occur in between accounts in same network",
        "Let's take a look at how validators maintain the state of an account",
      ],
      primerAvatar: "oops",
    },
  },
  ...accountStatePrimer,
];

const nearTokenOptions = [
  {
    text: "Yes",
    userReply: {
      reply: [
        "Yes, Primer",
        "I think we can transfer NEAR tokens from testnet environment to the mainnet environment",
      ],
      userAvatar: "Happy",
    },
    primers: yesPrimer,
  },
  {
    text: "No",
    userReply: {
      reply: [
        "Nope Primer !",
        "I don't think we can transfer testnet NEAR tokens to mainnet accounts",
      ],
      userAvatar: "Cute",
    },
    primers: noPrimer,
  },
];

export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, in the previous, we looked at three key elements of the NEAR Protocol Platform...",
        "...Accounts, Nodes and the NEAR Token",
        "Let's get into the details of the NEAR Protocol Platform starting with the accounts",
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "AccountsInfo",
    },
  },
  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      chats: [
        'Both the "testnet" and "mainnet" uses NEAR tokens',
        '{{user}}, can we transfer NEAR tokens from a "testnet account" to a "mainnet NEAR account"?',
      ],
      buttonOptions: nearTokenOptions,
      userReply: { reply: null, userAvatar: null },
      primerAvatar: "puzzle",
    },
  },
];
