import ShortPrimer from "../ShortPrimer";
import StorageExplorable from "../../../AppContextData/NearProtocol/CommandLine/Transactions/PrimerComponents/StorageExplorable";

const Text = () => (
  <p>
    You can check the methods available in the{" "}
    <span style={{ color: "indigo" }}>near-sdk-js</span> by checking out this{" "}
    <span style={{ color: "indigo" }}>short guide</span>
  </p>
);

export const EnvTable = () => (
  <ShortPrimer text={Text} type="environment-table" />
);

export const StorageExp = () => <StorageExplorable />;

const VectorText = () => (
  <p>
    You can check the methods available in the{" "}
    <span style={{ color: "indigo" }}>Vector Collections</span> in
    <span style={{ color: "indigo" }}> near-sdk-js </span> using
    <span style={{ color: "indigo" }}> short guide </span>
  </p>
);

export const VectorExplorable = () => (
  <ShortPrimer text={VectorText} type="vector-primer" />
);
