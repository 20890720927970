import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["set_greeting", "get_greeting"];
  const correctOption = "set_greeting";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, in the <code>HelloNEAR</code> contract which of the
            following is an instance of <b>change method?</b>
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
