import React from "react";
import MatchQuiz from "../../../../../components/Quiz/MatchQuiz";

const MatchTemplate = (props) => {
  const matchOptions = {
    Gas: "Measures the computational cost of a transaction",
    Signer: "Account that invokes the transaction",
    Hash: "Unique Identifier",
    "Transaction Fee": "Fees paid in NEAR for execution",
    Receiver: "Account receiving the transaction",
  };
  return (
    <MatchQuiz
      large={true}
      header={
        <React.Fragment>
          {props.name}, can you match the following terms and their usage from a
          transaction on <b>NEAR Protocol</b>?
        </React.Fragment>
      }
      matchOptions={matchOptions}
      {...props}
    />
  );
};

export default MatchTemplate;
