import { OutputFactory, Outputs } from "javascript-terminal";
import { CustomOutputFactory } from "../outputs";
import { arraysEqual, getTxId } from "./utils";

const helpText = `
**Usage**: &&near&& [[delete]] @@<accountId>@@ @@<beneficiaryId>@@ 

    ^^delete an account and transfer funds to beneficiary account.^^
    
    For e.g. let's say you want to delete a developer account [[test.nearprotocolprimer.testnet]] and transfer its account balance to &&primerlabs.testnet&&. You can do so creating by using following command: 
        
        &&near&& [[delete]] @@test.nearprotocolprimer.testnet@@ @@primerlabs.testnet@@

**Notes**:
    1. You should have &&access to the account&& you wish to delete

`;

const generateErrors = (errors) => {
  let mappedText = errors.reduce((acc, curr) => {
    return acc + `\n\t&&` + " * " + curr + `&&`;
  }, ``);
  return `**Encountered Following Errors**
${mappedText}

**Usage**: &&near&& [[delete]] @@<accountId>@@ @@<beneficiaryId>@@ 

For more info type:
&&near&& [[delete]] @@--help@@ 

`;
};

const getSuccessMessage = (res, accountId) => {
  const hash = getTxId(res);
  const message = `
    Transaction Id ${hash}
    To see the transaction in the transaction explorer, please open this url in your browser (((https://explorer.testnet.near.org/transactions/${hash})))
    Account [[${accountId}]] for network "testnet" was deleted.`;

  return message;
};

const missingMaster = `Missing Master Account`;
const missingAccountID = `Missing Account ID`;
const missingBeneficiaryID = `Missing Beneficiary ID`;
const invalidAccountId = `Invalid Account ID provided`;
const endWithTestnet = `Master account must end with testnet`;
const accEndWithTestnet = `Account Id must end with ".testnet"`;
const notLongEnough = `AccountId should be greater than 32 characters`;
const tooLong = `AccountId should be smaller than 64 characters`;
const invalidMasterAccount = `Invalid Master Account Id provided`;
const incorrectCommandUsage = `Incorrect command usage`;

const checkDescendant = (account, masterAccount) => {
  return arraysEqual(account.split(".").slice(1), masterAccount.split("."));
};

const checkForErrors = (accountId, options, loggedInAccount) => {
  let errors = [];

  const regexCheck =
    /^(([a-z\d]+[\-_])*[a-z\d]+\.)*([a-z\d]+[\-_])*[a-z\d]+$/im;

  if (!options.masterAccount) {
    errors.push(missingMaster);
    return errors;
  }

  if (!regexCheck.test(accountId)) {
    errors.push(invalidAccountId);
    return errors;
  }

  if (!regexCheck.test(options.masterAccount)) {
    errors.push(invalidMasterAccount);
    return errors;
  }

  if (!accountId.endsWith(".testnet")) {
    errors.push(accEndWithTestnet);
    return errors;
  }

  if (!options.masterAccount.endsWith(".testnet")) {
    errors.push(endWithTestnet);
    return errors;
  }

  if (!options.masterAccount.endsWith(loggedInAccount)) {
    const notAuthorised = `Not authorised to access the master account "${options.masterAccount}" Logged In account is ${loggedInAccount}`;
    errors.push(notAuthorised);
    return errors;
  }

  if (!checkDescendant(accountId, options.masterAccount)) {
    const notDescendent = `Provided accountId: ${accountId} is not a direct subaccount of the master account: ${options.masterAccount}`;
    errors.push(notDescendent);
    return errors;
  }

  if (accountId.length > 64) {
    errors.push(tooLong);
    return errors;
  }

  return errors;
};

const updateState = (type, message, setCustomState, setAcceptInput) => {
  if (type === "error") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(`&&${message}&&`)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  } else if (type === "success") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(message)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  }

  setAcceptInput(true);
};

export const deleteCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  const loggedInAccount = context.walletAccountId;
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(helpText),
    };
  } else {
    if (args.length === 1) {
      const errors = [missingAccountID, missingBeneficiaryID];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if (args.length === 3) {
      const [_, accountId, beneficiaryId] = args;

      const { setAcceptInput, setCustomState } = stateChangeProps;

      setAcceptInput(false);

      context
        .deleteAccount(accountId, beneficiaryId)
        .then((res) => {
          let type = res.type ? res.type : "success";
          const message = getTxId(res)
            ? getSuccessMessage(res, accountId)
            : JSON.stringify(res, null, 4);

          //   let indent = 4;
          //   let message = JSON.stringify(res, null, indent);
          updateState(type, message, setCustomState, setAcceptInput);
        })
        .catch((e) => {
          let type = "error";
          let message = e.message;
          updateState(type, message, setCustomState, setAcceptInput);
        });

      const nodeUrl = "https://rpc.testnet.near.org";
      const helperUrl = "https://helper.testnet.near.org";

      const deletingText = `Deleting account. Account id: [[${accountId}]], node: (((${nodeUrl}))), helper: (((${helperUrl}))), beneficiary: [[${beneficiaryId}]]`;

      return {
        output: CustomOutputFactory.createColoredRecord(deletingText),
      };

      //   const errorsList = checkForErrors(accountId, options, loggedInAccount);
      //   if (errorsList.length) {
      //     const errorText = generateErrors(errorsList);
      //     return {
      //       output: CustomOutputFactory.createColoredRecord(errorText),
      //     };
      //   } else {

      //   }
    } else {
      const errors = [incorrectCommandUsage];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    }
  }
};
