import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["0.1 NEAR", "1 NEAR", "10 NEAR", "100 NEAR"];
  const correctOption = "100 NEAR";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, in the <code>near create-account</code> command how many{" "}
          <b>NEAR</b> tokens will be transferred to the newly created account,
          if you skip the <code>--initialBalance</code> argument?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
