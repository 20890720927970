export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "In the lesson, let's build another frontend for a smart contract we earlier created.",
        'Our DApp will be based upon our "Book Ratings" smart contract',
        "Let's get started by outlining our decentralized application.",
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "BookRating",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "WalletMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Our usual steps for creating will be similar to our last Counter DApp",
        'We will initialise our wallet instance from our "Wallet" helper file and pass into our main component',
        "Let's take a look",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "BookRatingDapp",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "IndexFill",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'Now that our "App" component has the "wallet" object as a prop...',
        "... we can start interacting with the wallet to sign in and calling view methods on smart contract.",
        "Let's do that next",
      ],
      primerAvatar: "game",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "BookRatingDapp2",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "WalletSignInMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "Everytime a user logins into an application, a new access key is generated and stored in the browser as well as the user's account state on the blockchain",
        "Let's say an user doesn't enough NEAR tokens to cover for additional storage use",
        "{{user}}, what do you think will happen if they try to log in to our application?",
      ],
      primerAvatar: "question",
      textReply: {
        que: "What do you think will happen if an user with insufficient NEAR tries to log in?",
      },
      userReply: { reply: null, userAvatar: "Smirk" },
    },
  },

  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Since, an access key is needed to be added in the account state...",
        "... the transaction will fail due to insufficient balance and the user won't be able to login",
        "Fortunately, the access keys related data is quite miniscule and doesn't occupy much storage",
        'Next, let\'s continue building our DApp by writing a function to call the "add_rating" method',
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "BookRatingDapp3",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        'If we call a "view method" on the blockchain, it returns the result',
        'While calling a "call method" on the blockchain, returns the "transaction ID"',
        "{{user}}, why do you think that is the case?",
      ],
      primerAvatar: "puzzle",
      textReply: {
        que: "Why do you think that is the case?",
      },
      userReply: { reply: null, userAvatar: "Happy" },
    },
  },

  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Well, view methods are primarily used to read state from the blockchain",
        "While, the call methods are usually used to change state on the blockchain which can take a while to be successful or unsuccessful",
        "Therefore, the blockchain returns the transaction ID that we can use to check the status of our transaction",
        'Next, let\'s proceed to checkout our completed "Book Ratings DApp"',
      ],
      primerAvatar: "gentleman",
    },
  },

  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "BookRatingDapp4",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "Hope you have successfully created and deployed the Book Rating DAapp",
        "In the next lesson, we will take a look at creating a Donations DApp",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "wave",
    },
  },
];
