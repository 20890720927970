import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Name",
    "Address",
    "Government-issued ID Proof",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, what things are required to open a bank account in{" "}
          <b>traditional bank account</b>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
