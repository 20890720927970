import React from "react";
import {
  Paper,
  Typography,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { GenerateStars } from "../Card/CircularCard";
import useSound from "use-sound";
import ResetConfirmationDialog from "./ResetConfirmationDialog";
import ResetIcon from "@mui/icons-material/Undo";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "aliceblue",
    color: "aliceblue",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    fontWeight: "bold",
    fontFamily: "Nunito",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: "lightcoral",
  //   },
  //   "&:nth-of-type(even)": {
  //     backgroundColor: "aliceblue",
  //   },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const calculateStars = (pct = 0) => {
  if (pct === 0) {
    return "0";
  } else if (pct === 100) {
    return "5";
  } else if (pct > 80) {
    return "4";
  } else if (pct > 60) {
    return "3";
  } else if (pct > 30) {
    return "2";
  } else if (pct > 0) {
    return "1";
  } else {
    return "0";
  }
};

const renderMessage = (stars) =>
  ({
    0: "Sorry, no stars for you",
    1: "You were able to get only 1 star",
    2: "Two stars, way to go !",
    3: "Good job, 3 stars earned !",
    4: "Awesome, you got 4 stars !",
    5: "Congratulations, you got 5 stars !",
  }[stars]);

const LessonScorecard = ({
  context,
  questId,
  lessonId,
  isQuest,
  wrong,
  total,
  percentage = 0,
  showReset = false,
}) => {
  const correct = total - wrong;
  const stars = calculateStars(parseInt(percentage));

  const [openDialog, setOpenDialog] = React.useState(false);

  const dialogProps = {
    openDialog,
    setOpenDialog,
    context,
    questId,
    lessonId,
    isQuest,
  };

  return (
    <React.Fragment>
      {showReset && (
        <div
          style={{
            height: "5rem",
            display: "flex",
            textAlign: "center",
            maxWidth: "700px",
            margin: "30px auto",
            padding: "30px",
            fontFamily: "Nunito",
            borderRadius: "12px",
          }}
        >
          <h1 style={{ color: "#47307f" }}>Want to try again?</h1>
          <Button
            startIcon={<ResetIcon />}
            onClick={() => setOpenDialog(true)}
            sx={{ height: "3rem", margin: "auto" }}
            variant="outlined"
          >
            Restart Lesson
          </Button>
        </div>
      )}
      <Paper
        elevation={4}
        sx={{
          maxWidth: "700px",
          margin: "30px auto",
          padding: "30px",
          fontFamily: "Nunito",
          background: "indigo",
          borderRadius: "12px",
        }}
      >
        <Typography
          variant="h2"
          color="inherit"
          sx={{
            textAlign: "center",
            fontFamily: "Nunito",
            fontWeight: "bold",
            color: "floralwhite",
          }}
          component="div"
        >
          Score Card
        </Typography>
        <hr />
        <div
          style={{
            paddingBottom: "30px",
            textAlign: "center",
          }}
        >
          <GenerateStars stars={stars} />
        </div>
        <div style={{ textAlign: "center", color: "floralwhite" }}>
          <h1>{renderMessage(stars)}</h1>
        </div>
        <TableContainer
          component={Paper}
          elevation={4}
          sx={{
            maxWidth: "400px",
            margin: "30px auto",
          }}
        >
          <Table size="medium">
            <TableBody>
              <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
                <StyledTableCell variant="body">
                  Total Number of Quizzes
                </StyledTableCell>
                <StyledTableCell variant="body">{total}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow sx={{ backgroundColor: "lightgreen" }}>
                <StyledTableCell variant="body">
                  Correct Responses
                </StyledTableCell>
                <StyledTableCell variant="body">{correct}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow sx={{ backgroundColor: "lightcoral" }}>
                <StyledTableCell variant="body">
                  Wrong Responses
                </StyledTableCell>
                <StyledTableCell variant="body">{wrong}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
                <StyledTableCell variant="body">
                  Percentage Score
                </StyledTableCell>
                <StyledTableCell variant="body">
                  {isNaN(percentage) ? 0 : parseInt(percentage)}%
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <ResetConfirmationDialog {...dialogProps} />
    </React.Fragment>
  );
};

export default LessonScorecard;
