export const mineNonce = (block, data, difficulty = 4, digit = 0) => {
  const MAXNONCE = 10000000;

  const getNumberKey = (num) =>
    ({
      10: "a",
      11: "b",
      12: "c",
      13: "d",
      14: "e",
      15: "f",
    }[num]);

  const convertHexaDecimal = (num) => {
    if (num < 10) {
      return `${num}`;
    } else {
      return getNumberKey(num);
    }
  };

  let pattern = convertHexaDecimal(digit).repeat(difficulty);
  for (var nonce = 0; nonce <= MAXNONCE; nonce++) {
    let blockHash = `block${block}nonce${nonce}data${data}hash`;
    // eslint-disable-next-line no-undef
    if (sha256.sha256(blockHash).substring(0, difficulty) === pattern) {
      return nonce;
    }
  }
  return -1;
};

export const mineStealth = (
  block,
  data,
  nonceValue,
  difficulty = 4,
  digit = 0
) => {
  const MAXNONCE = 10000000;

  const getNumberKey = (num) =>
    ({
      10: "a",
      11: "b",
      12: "c",
      13: "d",
      14: "e",
      15: "f",
    }[num]);

  const convertHexaDecimal = (num) => {
    if (num < 10) {
      return `${num}`;
    } else {
      return getNumberKey(num);
    }
  };

  let pattern = convertHexaDecimal(digit).repeat(difficulty);
  for (var nonce = 0; nonce <= MAXNONCE; nonce++) {
    let blockHash = `block${block}nonce${nonceValue}data${data}${nonce}hash`;
    // eslint-disable-next-line no-undef
    if (sha256.sha256(blockHash).substring(0, difficulty) === pattern) {
      return `${data}${nonce}`;
    }
  }
  return -1;
};

export const chainTSAMineNonce = (
  block,
  data,
  timeStamp,
  prev,
  difficulty = 4
) => {
  const MAXNONCE = 10000000;
  let pattern = "0".repeat(difficulty);
  for (var nonce = 0; nonce <= MAXNONCE; nonce++) {
    //   let blockHash = `block${block}nonce${nonce}data${data}hash`;
    const blockHash = `block${block}nonce${nonce}timestamp${timeStamp}data${data}prev${prev}hash`;
    // eslint-disable-next-line no-undef
    if (sha256.sha256(blockHash).substring(0, difficulty) === pattern) {
      return nonce;
    }
  }
  return -1;
};

export const chainMineNonce = (block, data, prev, difficulty = 4) => {
  const MAXNONCE = 10000000;
  let pattern = "0".repeat(difficulty);
  for (var nonce = 0; nonce <= MAXNONCE; nonce++) {
    //   let blockHash = `block${block}nonce${nonce}data${data}hash`;
    const blockHash = `block${block}nonce${nonce}data${data}prev${prev}hash`;
    // eslint-disable-next-line no-undef
    if (sha256.sha256(blockHash).substring(0, difficulty) === pattern) {
      return nonce;
    }
  }
  return -1;
};

export const coinbaseMineNonce = (
  block,
  data,
  prev,
  coinbase,
  difficulty = 4
) => {
  const MAXNONCE = 10000000;
  let pattern = "0".repeat(difficulty);
  for (var nonce = 0; nonce <= MAXNONCE; nonce++) {
    //   let blockHash = `block${block}nonce${nonce}data${data}hash`;
    const blockHash = `block${block}nonce${nonce}coin${coinbase.amt}${coinbase.miner}data${data}prev${prev}hash`;
    // eslint-disable-next-line no-undef
    if (sha256.sha256(blockHash).substring(0, difficulty) === pattern) {
      return nonce;
    }
  }
  return -1;
};
