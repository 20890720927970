import React from "react";
import { HashTextField, CssTextField } from "../../../styledComponents";
import { Button } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AccountKeysModal from "./AccountKeysModal";
import { implicitAccountId } from "../../../../../utils";
import { initialAccessKeys } from "./keysData";

const { parseSeedPhrase, generateSeedPhrase } = require("near-seed-phrase");

const SEED_PATH = "m/44'/397'/0'";

const GenerateKeyPair = ({
  accessKeys,
  setAccessKeys,
  changed,
  setChanged,
  useAccount,
  setUseAccount,
  useSeedphrase,
  setUseSeedphrase,
  accountId,
  setAccountId,
  seedphrase,
  setSeedphrase,
  testnet,
  setTestnet,
  message,
  setMessage,
  accountAccessKeys,
  setAccountAccessKeys,
  handleReset,
}) => {
  // const handleReset = () => {
  //   setAccessKeys(initialAccessKeys);
  //   setMessage(null);
  //   setChanged([]);
  // };

  const generateKeyPairs = () => {
    let publicKey;
    let secretKey;
    if (useSeedphrase) {
      const keyPair = parseSeedPhrase(seedphrase, SEED_PATH);
      publicKey = keyPair.publicKey;
      secretKey = keyPair.secretKey;
    } else {
      const keyPair = generateSeedPhrase();
      publicKey = keyPair.publicKey;
      secretKey = keyPair.secretKey;
    }

    const newAccountId = useAccount ? accountId : implicitAccountId(publicKey);
    const network = testnet ? "testnet" : "mainnet";
    const accountExists = accessKeys.find(
      (x) => x.account_id === newAccountId && x.network === network
    );
    if (accountExists) {
      setMessage({
        type: "overwrite",
        account: newAccountId,
        publicKey: accountExists.publicKey,
      });
      if (!changed.includes(`${newAccountId}-${network}`)) {
        setChanged([`${newAccountId}-${network}`, ...changed]);
      }
    } else {
      setMessage({ type: "new", account: newAccountId, publicKey: publicKey });
      setAccessKeys([
        ...accessKeys,
        {
          network: network,
          publicKey: publicKey,
          private_key: secretKey,
          account_id: newAccountId,
        },
      ]);
    }
  };

  const noAccountId = useAccount && accountId.trim().length === 0;
  const noSeedPhrase = useSeedphrase && seedphrase.trim().length === 0;
  const buttonDisabled = noAccountId || noSeedPhrase;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "250px",
        margin: "10px auto",
      }}
    >
      <AccountKeysModal
        keys={accountAccessKeys}
        testnet={testnet}
        setTestnet={setTestnet}
      />
      <HashTextField
        disabled={!useAccount}
        label="Account ID"
        value={accountId}
        onChange={(evt) => {
          setAccountId(evt.target.value);
        }}
        style={{ margin: "10px" }}
      />
      <CssTextField
        disabled={!useSeedphrase}
        label="SeedPhrase"
        value={seedphrase}
        onChange={(evt) => {
          setSeedphrase(evt.target.value);
        }}
        style={{ margin: "10px" }}
        multiline
        rows={4}
      />

      <FormGroup
        style={{
          width: "250px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          style={{ margin: "5px auto" }}
          control={
            <Switch
              size="small"
              checked={useAccount}
              onChange={(evt) => setUseAccount(evt.target.checked)}
            />
          }
          label={
            <span
              style={{
                fontWeight: "bold",
                margin: "5px auto",
                fontSize: "12px",
              }}
            >
              Use Account ID
            </span>
          }
        />
        <FormControlLabel
          style={{ margin: "5px auto" }}
          control={
            <Switch
              size="small"
              checked={useSeedphrase}
              onChange={(evt) => setUseSeedphrase(evt.target.checked)}
            />
          }
          label={
            <span
              style={{
                fontWeight: "bold",
                margin: "5px auto",
                fontSize: "12px",
              }}
            >
              Seedphrase
            </span>
          }
        />
        <FormControlLabel
          style={{ margin: "5px auto" }}
          control={
            <Switch
              size="small"
              checked={!testnet}
              onChange={(evt) => setTestnet(!evt.target.checked)}
            />
          }
          label={
            <span
              style={{
                fontWeight: "bold",
                margin: "5px auto",
                fontSize: "12px",
              }}
            >
              {testnet ? "NEAR Test Network" : "NEAR Main Network"}
            </span>
          }
        />
      </FormGroup>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "56px",
          margin: "5px auto",
        }}
      >
        <Button
          disabled={buttonDisabled}
          onClick={generateKeyPairs}
          variant="outlined"
          style={{ fontSize: "12px", width: "200px" }}
        >
          Generate Key Pair
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "36px",
          margin: "5px auto",
        }}
      >
        <Button
          onClick={handleReset}
          variant="outlined"
          style={{ fontSize: "12px" }}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default GenerateKeyPair;
