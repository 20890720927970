import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const src = `https://assets.primerlabs.io/PrimerAvatar150/charging.png`;

const NoMatch = () => (
  <div style={{ background: "floralwhite", padding: "60px", height: "100vh" }}>
    <h1 style={{ textAlign: "center", fontFamily: "Corben", color: "indigo" }}>
      Page not found
    </h1>
    <div style={{ textAlign: "center" }}>
      <img style={{ height: "150px" }} src={src} />
      <p style={{ fontWeight: "bold" }}>
        Are you sure you are in the right place?
      </p>
      <Link style={{ textDecoration: "none" }} to={"/"}>
        <Button variant="contained" className="LessonBtn">
          Let's head Home, Buddy
        </Button>
      </Link>
    </div>
  </div>
);

export default NoMatch;
