import React from "react";
import MatchQuiz from "../../../../../components/Quiz/MatchQuiz";

const MatchTemplate = (props) => {
  const matchOptions = {
    centralised: "decision-making is perform under a single authority",
    "universal registry": "a type of blockchain to record timestamps",
    trustless:
      "can be verified to operate in your interests hence would not need your trust.",
    blockchain: "chain of blocks linked using hash values",
    "timestamping authority":
      "Independent witness providing the evidence required to show that the document is unchanged since it's creation",
    immutable: "unchanging over time or unable to be changed.",
  };
  return (
    <MatchQuiz
      large={true}
      header={
        <React.Fragment>
          {props.name}, can you match the following?
        </React.Fragment>
      }
      matchOptions={matchOptions}
      {...props}
    />
  );
};

export default MatchTemplate;
