import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["alice.near", "bob.near", "Cannot Say"];
  const correctOption = "alice.near";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, <code>alice.near</code> sends a call request to a{" "}
            <b>smart contract</b> deployed on <code>bob.near</code>. What will
            be the output of <code>near.predecessorAccountId()</code> during the
            method execution?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
