// Infos
import ActionsInfo from "./PrimerComponents/ActionsInfo.mdx";
import DonationsInfo from "./PrimerComponents/DonationsInfo.mdx";
import CreatingInfo from "./PrimerComponents/CreatingInfo.mdx";
import CrossContractInfo from "./PrimerComponents/CrossContractInfo.mdx";
import QueryCrossContract from "./PrimerComponents/QueryCrossContract.mdx";
import Summary from "./PrimerComponents/Summary.mdx";

// Quiz
import ActionsMCQ from "./Quiz/ActionsMCQ";
import TransferMCQ from "./Quiz/TransferMCQ";
import TransferMatch from "./Quiz/TransferMatch";
import CrossContractMCQ from "./Quiz/CrossContractMCQ";
import CrossContractMCQ2 from "./Quiz/CrossContractMCQ2";
// Primers
import { start } from "./Primers/Start";

const primers = [...start];

const primerComponents = {
  DonationsInfo: DonationsInfo,
  ActionsInfo: ActionsInfo,
  ActionsMCQ: ActionsMCQ,
  TransferMCQ: TransferMCQ,
  CreatingInfo: CreatingInfo,
  TransferMatch: TransferMatch,
  CrossContractInfo: CrossContractInfo,
  CrossContractMCQ: CrossContractMCQ,
  QueryCrossContract: QueryCrossContract,
  CrossContractMCQ2: CrossContractMCQ2,
  Summary: Summary,
};

const imageComponents = [];

const quizList = [
  "ActionsMCQ",
  "TransferMCQ",
  "TransferMatch",
  "CrossContractMCQ",
  "CrossContractMCQ2",
];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
