import { dataPrimers } from "./ChangeDataPrimers";

const changeBlockPrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Technically, we can change the block number to get the valid block. ",
        "But it will be a bad idea since we use the block number to identify the block.",
        "What we can do instead is change the data inside.",
      ],
      primerAvatar: "sorry",
    },
  },
  ...dataPrimers,
];

const changeDataPrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Correct, {{user}}.",
        "The block number helps identify the block, after all.",
        "We will change the data but divide it further to make it easy for us to do so.",
      ],
      primerAvatar: "thumbs-up",
    },
  },
  ...dataPrimers,
];

const chat1buttonOptions = [
  {
    text: "Changing Block Number",
    userReply: {
      reply: [
        "I believe ...",
        "...we should change the block number to get the hash value of a valid block",
      ],
      userAvatar: "Boring",
    },
    primers: changeBlockPrimers,
  },
  {
    text: "Changing Data",
    userReply: {
      reply: [
        "Well, I think...",
        "...we should change the data to get the hash value of a valid block",
      ],
      userAvatar: "Cute",
    },
    primers: changeDataPrimers,
  },
];

// Chat-1
export const aBlockPrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "In the previous lesson, we saw that we could create a fingerprint of every piece of data using hash values.",
        "Now, let's create a fingerprint of a `block`.",
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "FingerprintBlockInfo",
    },
  },
  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      chats: [
        "{{user}}, we can change the hash value by either changing the block number or the data contained.",
        "Which one should we change to get the required valid block?",
      ],
      buttonOptions: chat1buttonOptions,
      userReply: { reply: null, userAvatar: null },
      primerAvatar: "question",
    },
  },
];
