export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, in last lesson we started learning about smart contracts by creating simple smart contracts.",
        "Let's take a moment understanding at how the smart contracts store their contract state",
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DataStructures",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "SerializeMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "So far, we have dealt with simple smart contracts",
        'In order to create advanced smart contracts, we need to store state in collections or some form of "data structures"',
        "Before we start using them, let's first understand how the smart contracts are executed under the hood.",
      ],
      primerAvatar: "laptop-1",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Structures",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "SDKMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Both Vectors and Sets can be used to handle requirements described in the previous quiz",
        "However, Set-type objects are much more efficient in finding common elements than Vectors.  ",
        "Now, let's take a look at how we can start using these SDK Collections in our smart contracts.",
      ],
      primerAvatar: "patient",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "SDKContracts",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "TrieMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'A good rule of thumb while using collections objects in your smart contracts is to use "SDK Collections" rather than "native collections" objects in javascript',
        'The "near-sdk-js" also exposes several functions which gives access to the execution environment in a smart contract.',
        "Let's take a look at them next",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Environment",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "EnvMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Now that we have got a fair bit of idea about how we can write useful smart contracts...",
        '...let\'s write a fairly complex smart contract which we will call "Book Rating Smart Contract"',
      ],
      primerAvatar: "businessman",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "GuestBook",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "MapMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'Now that we have our "Book" model...',
        "...let's take a look at how we can use them in our Book Rating Smart Contract next.",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "RatingContract",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "UnorderedMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "We have partially completed the smart contract",
        'Let\'s next add the method "add_rating" into our contract',
      ],
      primerAvatar: "patient",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "RatingContract2",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "SignerMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "We have almost completed our smart contract",
        'Let\'s wrap it up by adding the method "get_book_detail" into our contract',
      ],
      primerAvatar: "charging",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "RatingContract3",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "We have finally written our first complex smart contract from scratch",
        "In the next section, we will learn about using actions and cross-contract calls in a smart contract",
        "See you in the next section, {{user}}",
      ],
      primerAvatar: "celebrate",
    },
  },
];

// {
//   type: "CHAT",
//   reply: "NEXT",
//   props: {
//     chats: ["To initialize the state of a contract means to "],
//     primerAvatar: "charging-1",
//   },
// },
