// Infos
import PizzaInfo from "./PrimerComponents/PizzaInfo";
import MoneyInfo from "./PrimerComponents/MoneyInfo.mdx";
import BlockInfo from "./PrimerComponents/BlockInfo.mdx";
import VisualiseInfo from "./PrimerComponents/VisualiseInfo.mdx";
import HashInfo from "./PrimerComponents/HashInfo.mdx";
import HashingDataInfo from "./PrimerComponents/HashingDataInfo.mdx";
import CompareHashInfo from "./PrimerComponents/CompareHashInfo.mdx";

// Quizzes
import UniqueMCQQuiz from "./PrimerComponents/UniqueMCQQuiz";
import HashingMCQQuiz from "./PrimerComponents/HashingMCQQuiz";
import Hash2MCQQuiz from "./PrimerComponents/Hash2MCQQuiz.jsx";

// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";

import { introductionPrimers } from "./Introduction";

const primerComponents = {
  PizzaInfo: PizzaInfo,
  MoneyInfo: MoneyInfo,
  BlockInfo: BlockInfo,
  VisualiseInfo: VisualiseInfo,
  UniqueMCQQuiz: UniqueMCQQuiz,
  HashInfo: HashInfo,
  HashingDataInfo: HashingDataInfo,
  HashingMCQQuiz: HashingMCQQuiz,
  CompareHashInfo: CompareHashInfo,
  Hash2MCQQuiz: Hash2MCQQuiz,
  NextLesson: NextLesson,
};

const quizList = ["Hash2MCQQuiz", "UniqueMCQQuiz", "HashingMCQQuiz"];

const imageComponents = [];

const primers = introductionPrimers;

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
