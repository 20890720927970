import {
  StyledTableRow,
  StyledTableCell,
} from "../../../../../components/Common/Table.jsx";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";

const BlockTimeTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "900px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Property
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              🐐 Goat
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              💰 Cash
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Durability</StyledTableCell>
            <StyledTableCell variant="body">
              ❌ Has short lifespan
            </StyledTableCell>
            <StyledTableCell variant="body">
              ✅ Has higher durability
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Portability</StyledTableCell>
            <StyledTableCell variant="body">
              ❌ Not easier to transport
            </StyledTableCell>
            <StyledTableCell variant="body">✅ Easily Portable</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Divisibility</StyledTableCell>
            <StyledTableCell variant="body">❌ Not divisible</StyledTableCell>
            <StyledTableCell variant="body">
              ✅ Has lower denominations available
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Uniformity</StyledTableCell>
            <StyledTableCell variant="body">
              ❌ Value varies in different regions
            </StyledTableCell>
            <StyledTableCell variant="body">
              ✅ Value remains uniform throughout a country
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Limited Supply</StyledTableCell>
            <StyledTableCell variant="body">
              ✅ Limited number available
            </StyledTableCell>
            <StyledTableCell variant="body">
              ✅ Scarce amount printed
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Acceptability</StyledTableCell>
            <StyledTableCell variant="body">
              ❌ Not accepted widely
            </StyledTableCell>
            <StyledTableCell variant="body">✅ Accepted widely</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const BlockDifficultyOverTime = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "700px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Bitcoin Block
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Addition Date
            </StyledTableCell>

            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Difficulty
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Block 1</StyledTableCell>
            <StyledTableCell variant="body">Eight</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Block 50000</StyledTableCell>
            <StyledTableCell variant="body">~ 12-14 seconds</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Near</StyledTableCell>
            <StyledTableCell variant="body">~ 1 second</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlockTimeTable;
