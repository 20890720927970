export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, in the last lesson, we learned to write our first fairly complex smart contracts using SDK collections",
        "We can also write smart contracts that can handle actions such transferring NEAR Ⓝ tokens or creating new accounts.",
        "Let's take a look at actions that are supported by smart contracts",
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "ActionsInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ActionsMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "While calling a change method on a smart contract, an account can attach NEAR Ⓝ tokens",
        'However, before we can attach tokens, we need to decorate the methods as "payableFunction"',
        "Let's take a look at them next.",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DonationsInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "TransferMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Transfer action is the most common action that you will use while writing a smart contract",
        "Next, let's take a look other common actions - Creating, Adding Keys and Deleting Accounts on NEAR Protocol Blockchain",
      ],
      primerAvatar: "patient",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "CreatingInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "TransferMatch",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "On NEAR Protocol, we can also write actions that call methods on other contracts",
        'Calling other contract\'s methods is called "cross-contract calls"',
        'And we can do this by using the "functionCall" action',
      ],
      primerAvatar: "phone",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "CrossContractInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "CrossContractMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'Previously, we created a simple "Counter" smart contract',
        "In order to understand cross-contract calls...",
        '...let\'s write a smart contract that calls methods on the "Counter" contract.',
      ],
      primerAvatar: "game",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "QueryCrossContract",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "CrossContractMCQ2",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, we have covered most of the concepts that will help you get started in writing smart contracts on NEAR Protocol",
        "Finally, let's look into additional info while writing smart contracts",
      ],
      primerAvatar: "notepad",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Summary",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "Congratulations, {{user}} for completing this section",
        "I am hopeful that this course has been useful to you and ",
        "That being said, I hope you put this into good use and create interesting smart contracts",
        "See you in some other lesson, {{user}}",
      ],
      primerAvatar: "celebrate",
    },
  },
];
