import React from "react";
import { Paper, Button } from "@mui/material";
import anime from "animejs";

const patterns = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const getNumberKey = (num) =>
  ({
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six",
    7: "seven",
    8: "eight",
    9: "nine",
    10: "ten",
  }[num]);

const Probability = () => {
  const chanceNumber = React.useRef();
  const animation = React.useRef(null);

  const [selected, setSelected] = React.useState(3);
  const [chanceValue, setChanceValue] = React.useState(0);

  const chances = Math.pow(16, selected);

  React.useEffect(() => {
    animation.current = anime.timeline({
      loop: false,
      autoplay: true,
    });

    const targetObject = {
      chances: chanceValue,
    };

    const newChance = Math.pow(16, selected);

    animation.current.add({
      targets: targetObject,
      chances: newChance,
      easing: "linear",
      round: 1,
      update: function () {
        chanceNumber.current.innerHTML = targetObject.chances;
      },
      complete: () => {
        setChanceValue(newChance);
      },
    });
  }, [selected]);

  return (
    <div>
      <div style={{ textAlign: "center", fontSize: "1.5rem" }}>
        Chances that{" "}
        <span style={{ color: "#47307f", fontWeight: "bold" }}>
          block's hash value
        </span>{" "}
        starts with{" "}
        <span style={{ color: "#47307f", fontWeight: "bold" }}>
          {getNumberKey(selected)}
        </span>{" "}
        <span style={{ fontWeight: "bold" }}>
          {selected === 1 ? "zero" : "zeroes"}
        </span>
        :
      </div>
      <div
        style={{ textAlign: "center", fontSize: "3rem", fontWeight: "bold" }}
      >
        <span style={{ color: "#47307f" }}> 1</span> in{" "}
        <span ref={chanceNumber} style={{ color: "#47307f" }}>
          {chances}
        </span>{" "}
      </div>
      <Paper
        style={{
          padding: "30px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {patterns.map((patt, idx) => (
          <Button
            onClick={() => setSelected(patt)}
            size="small"
            key={`item-${idx}`}
            variant={selected === patt ? "contained" : "outlined"}
            style={{ margin: "5px", fontSize: "small", height: "2rem" }}
          >
            {getNumberKey(patt)}
          </Button>
        ))}
      </Paper>
    </div>
  );
};

export default Probability;
