import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Transform the code into a valid NEAR contract.",
    "Expose public methods",
    "Serialize objects for internal storage",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, why do we use <code>NearBindgen</code> decorator while
            writing our <b>smart contract</b> in <code>javascript</code>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
