import React from "react";

const Anchor = ({ children, id }) => {
  const scrollToView = () => {
    document
      .getElementById(id)
      .scrollIntoView({ inline: "center", behavior: "smooth" });
  };

  return (
    <span
      onClick={scrollToView}
      style={{
        color: "#47307f",
        fontWeight: "bold",
        textDecoration: "solid underline 2px",
        textUnderlineOffset: "0.2rem",
        cursor: "pointer",
      }}
    >
      {children}
    </span>
  );
};

export default Anchor;
