import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AntTabs, AntTab } from "./Tabs";
{
  /* <span style={{ fontSize: "3rem" }}>{"\u201C"}</span>
 <span style={{ fontSize: "3rem" }}>{"\u201D"}</span> */
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography sx={{ color: "#47307f", display: "flex" }}>
            <span style={{ fontSize: "80px", margin: "0px", padding: "0px" }}>
              {"\u201C"}
            </span>
            <blockquote style={{ paddingTop: "10px", fontSize: "small" }}>
              {children}
            </blockquote>
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabPanelStyles = {
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  color: "#47307f",
};

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        display: "inline-block",
        bgcolor: "background.paper",
        border: "none",
      }}
    >
      <AntTabs
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderBottom: "none",
          borderRight: 1,
          borderColor: "divider",
          minWidth: "250px",
        }}
      >
        <AntTab label="Ownership" {...a11yProps(0)} />
        <AntTab label="Identity" {...a11yProps(1)} />
        <AntTab label="Status" {...a11yProps(2)} />
        <AntTab label="Authority" {...a11yProps(3)} />
      </AntTabs>
      <TabPanel style={tabPanelStyles} value={value} index={0}>
        When a transaction is recorded in a <b>ledger</b>, it also records{" "}
        <b>who owns the asset</b>. Therefore, <b>property registers</b> are also{" "}
        <b>ledgers</b> just as inventory lists.
        <br />
        <br />
        <b>Ledgers confirms ownership</b>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={1}>
        The <b>government register</b> records details of its citizens and
        enshrining their <b>legal identity</b>. Similarly, the company's
        register records the <b>identity of enterprises</b>.<br />
        <br />
        <b>Ledgers confirms identity</b>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={2}>
        Numerous government ledgers confirm status such as the{" "}
        <b>marriage register</b> but also the <b>electoral register</b> gives
        details of who is eligible to vote or not.
        <br />
        <br />
        <b>Ledgers confirms status</b>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={3}>
        Registers are also used <b>to identify rights</b> attached to particular
        people or actions.
        <br />
        <br />
        <b>Ledgers confirms authority</b>
      </TabPanel>
    </Box>
  );
}
