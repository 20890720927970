import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["~ 2 seconds", "~ 1 hour", "~ 10 minutes", "~ 10 seconds"];
  const correctOption = "~ 2 seconds";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, how much time does a transaction take to be confirmed on{" "}
          <b>NEAR Protocol</b>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
