import {
  StyledTableRow,
  StyledTableCell,
} from "../../../../../components/Common/Table.jsx";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";

const BlockTimeTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "800px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Option Name
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Required
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Description
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">
              <code>accountId</code>
            </StyledTableCell>
            <StyledTableCell variant="body">Yes</StyledTableCell>
            <StyledTableCell variant="body">
              Name of the new subaccount
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">
              <code>masterAccount</code>
            </StyledTableCell>
            <StyledTableCell variant="body">Yes</StyledTableCell>
            <StyledTableCell variant="body">
              Name of the account which has permission to create the
              corresponding <code>subaccount</code>
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">
              <code>initialBalance</code>
            </StyledTableCell>
            <StyledTableCell variant="body">No</StyledTableCell>
            <StyledTableCell variant="body">
              Amount of NEAR Tokens to transfer to the newly created account.
              Default is 100 NEAR
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const BlockDifficultyOverTime = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "700px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Bitcoin Block
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Addition Date
            </StyledTableCell>

            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Difficulty
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Block 1</StyledTableCell>
            <StyledTableCell variant="body">Eight</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Block 50000</StyledTableCell>
            <StyledTableCell variant="body">~ 12-14 seconds</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Near</StyledTableCell>
            <StyledTableCell variant="body">~ 1 second</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlockTimeTable;
