import React, { useState } from "react";
import { sha256 } from "js-sha256";
import { CenteredLayout } from "../../../../Quiz/layouts/Layout";
import { Paper, Button } from "@mui/material";
import { HashTextField, CssTextField } from "../../../styledComponents";
import keypair from "keypair";
import useSound from "use-sound";
import Alert from "@mui/material/Alert";
import { AntTab, AntTabs } from "../../../styledComponents";
import Stack from "@mui/material/Stack";
import Explorable from "./Explorable";
import eccrypto from "eccrypto";
import toast from "react-hot-toast";

const ExerciseBox = ({ id, filled = false }) => (
  <Alert severity={filled ? "success" : "error"}>{getExercise(id)}</Alert>
);

const Signature = () => {
  const [completed, setCompleted] = React.useState({});
  const [type, setType] = React.useState("GENERATE");
  const [played, setPlayed] = React.useState({});
  const [playCorrect] = useSound("/sounds/correct.mp3", { volume: 0.1 });
  const [playWrong] = useSound("/sounds/wrong.mp3", { volume: 0.1 });

  const [keyPair, setKeypair] = React.useState({
    privateKey: "",
    publicKey: "",
  });
  const [editPubKey, setEditPubKey] = React.useState("");
  const [editPrivKey, setEditPrivKey] = React.useState("");
  const [encyptedMessage, setEncryptedMessage] = React.useState({
    ciphertext: "",
  });
  const [decryptedMessage, setDecryptedMessage] = React.useState("");
  const [message, setMessage] = React.useState(
    "Let's test out public key encryption"
  );

  const handleChangeTab = (event, newValue) => setType(newValue);

  React.useEffect(() => {
    const completed = {
      1: keyPair.publicKey !== "" && keyPair.privateKey !== "",
      2: encyptedMessage.ciphertext !== "",
      3: decryptedMessage !== "",
    };
    setCompleted(completed);
  }, [keyPair, encyptedMessage, decryptedMessage]);

  React.useEffect(() => {
    if (
      Object.values(completed).filter((x) => x === true).length >
      Object.values(played).filter((x) => x === true).length
    ) {
      playCorrect();
    }
    setPlayed(completed);
  }, [completed]);

  const generateKeyPair = () => {
    var privateKey = eccrypto.generatePrivate();
    var publicKey = eccrypto.getPublic(privateKey).toString("hex");

    setKeypair({ privateKey, publicKey });
  };

  const encryptMessage = async () => {
    setEncryptedMessage({
      ciphertext: "",
    });
    const enc = new TextEncoder();
    const encrypted = eccrypto
      .encrypt(Buffer.from(editPubKey, "hex"), enc.encode(message))
      .then((message) => {
        setEncryptedMessage(message);
      })
      .catch((e) => {
        playWrong();
        toast.error(`Encountered Error: ${e.message}`, {
          id: "error",
        });
      });
  };

  const decryptMessage = async () => {
    setDecryptedMessage("");
    const decrypted = eccrypto
      .decrypt(Buffer.from(editPrivKey, "hex"), encyptedMessage)
      .then((message) => {
        setDecryptedMessage(message.toString());
      })
      .catch((e) => {
        playWrong();
        toast.error(`Encountered Error: ${e.message}`, {
          id: "error2",
        });
      });
  };

  const explorableOptions = {
    generateKeyPair,
    keyPair,
    editPubKey,
    setEditPubKey,
    editPrivKey,
    setEditPrivKey,
    encyptedMessage,
    setEncryptedMessage,
    encryptMessage,
    decryptedMessage,
    setDecryptedMessage,
    decryptMessage,
    message,
    setMessage,
  };

  return (
    <CenteredLayout>
      <Paper style={{ padding: "20px" }}>
        <h2 style={{ textAlign: "center", color: "#47307f" }}>Exercises</h2>
        <Stack sx={{ width: "100%", marginBottom: "20px" }} spacing={2}>
          <ExerciseBox id={"1"} filled={completed[1]} />
          <ExerciseBox id={"2"} filled={completed[2]} />
          <ExerciseBox id={"3"} filled={completed[3]} />
        </Stack>
      </Paper>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "10px auto",
          justifyContent: "center",
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <AntTabs
            style={{ marginBottom: "30px" }}
            value={type}
            onChange={handleChangeTab}
            centered
          >
            <AntTab
              value={"GENERATE"}
              label="Generate Key Pair"
              style={{ fontWeight: "bold" }}
            />
            <AntTab
              value={"ENCRYPT"}
              label="Encrypt Message"
              style={{ fontWeight: "bold" }}
            />
            <AntTab
              value={"DECRYPT"}
              label="Decrypt Message"
              style={{ fontWeight: "bold" }}
            />
          </AntTabs>
        </div>
        <Explorable type={type} {...explorableOptions} />
      </div>
    </CenteredLayout>
  );
};

export default Signature;

{
  /* <Paper
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            margin: "10px",
            background: verified ? "lightgreen" : "#ff7f5061",
          }}
        >
          <div>
            <h2 style={{ textAlign: "center", color: "indigo" }}>Bob</h2>
            <h4
              style={{ textAlign: "center", fontWeight: "bold", margin: "0px" }}
            >
              {" "}
              Public Key:
            </h4>
            <div
              style={{
                width: "300px",
                fontSize: "tiny",
                wordWrap: "anywhere",
                fontVariant: "all-small-caps",
                letterSpacing: "2px",
                textAlign: "center",
                margin: "auto",
                fontWeight: "bold",
              }}
            >
              04bfd340b73f70cd4af155742e0dd2c6080f3d9c41caea4a6aeb4a7d5b97f01b9f64577ed9176b8ec0b3a185b8b97bd333b23a739a6be001f59076e44a1eaa32d3{" "}
            </div>
          </div>
          <br />
          <CssTextField
            label="Message"
            value={message2}
            multiline
            style={{ margin: "10px" }}
            rows={4}
          />
          <HashTextField
            label="Public Key"
            value={pubKey}
            style={{ margin: "10px" }}
          />
          <HashTextField
            label="Signature"
            value={sign2}
            style={{ margin: "10px" }}
          />
          <div style={{ margin: "auto" }}>
            <Button
              style={{ height: "3rem", fontSize: "0.9rem", margin: "auto" }}
              variant="outlined"
              disabled={sign2 === ""}
            >
              Verify Message
            </Button>
          </div>
        </Paper> */
}

const getExercise = (id) =>
  ({
    1: (
      <span>
        Generate a <b>private</b> and <b>public</b> keypair
      </span>
    ),
    2: (
      <span>
        <b>Encrypt</b> the message using <b>Public Key</b>
      </span>
    ),
    3: (
      <span>
        <b>Decrypt</b> the encrypted message using the corresponding{" "}
        <b>Private Key</b>
      </span>
    ),
  }[id]);
