import { Paper } from "@mui/material";

export const Money = () => (
  <Paper
    style={{
      background: "indigo",
      padding: "20px",
      margin: "10px auto",
      color: "floralwhite",
    }}
  >
    <div
      style={{ margin: "0px 25px", textAlign: "left", lineHeight: "1.5rem" }}
    >
      <ol>
        <li style={{ fontWeight: "bold", color: "salmon" }}>Durable</li>
        <span>
          The money should be <b>durable</b> for a long period of time
        </span>

        <li style={{ fontWeight: "bold", color: "salmon" }}>Portable</li>
        <span>
          It should be easier to <b>transport or carry around</b>
        </span>
        <li style={{ fontWeight: "bold", color: "salmon" }}>Divisible</li>
        <span>
          Money should be <b>divisible into lower denominations</b> to
          facilitate low-value transactions
        </span>
        <li style={{ fontWeight: "bold", color: "salmon" }}>Limited Supply</li>
        <span>
          The money should be a <b>scarce asset</b> and not easily available.
        </span>
        <li style={{ fontWeight: "bold", color: "salmon" }}>Uniformity</li>
        <span>
          The <b>value of the money should be uniform</b> across large
          geographical land.
        </span>
        <li style={{ fontWeight: "bold", color: "salmon" }}>Acceptable</li>
        <span>
          Most importantly, there should be considerable avenues where it is
          <b> accepted as form of money</b>.
        </span>
      </ol>
    </div>
  </Paper>
);
