import React from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";

const newStyles = {
  diffContainer: {
    overflowX: "auto",
    display: "block",
    "& pre": { whiteSpace: "pre" },
  },
  diffRemoved: {
    whiteSpace: "no-wrap",
    "& pre": { whiteSpace: "pre" },
    maxWidth: 300,
  },
  diffAdded: {
    whiteSpace: "no-wrap",
    "& pre": { whiteSpace: "pre", wordBreak: "break-word" },
    maxWidth: 300,
  },
  line: {
    wordBreak: "break-word",
  },
  variables: {
    highlightBackground: "#fefed5",
    highlightGutterBackground: "#ffcd3c",
  },
};

const VisualiseBlock = ({ content1, content2 }) => {
  const [con1, setCon1] = React.useState(content1);
  const [con2, setCon2] = React.useState(content2);

  return (
    <div>
      <ReactDiffViewer
        oldValue={con1}
        styles={newStyles}
        newValue={con2}
        splitView={true}
        compareMethod={DiffMethod.CHARS}
        hideLineNumbers={true}
      />
    </div>
  );
};

export default VisualiseBlock;
