// Primers
import { start } from "./Primers/Start";

// Info
import AccessKeysInfo from "./PrimerComponents/AccessKeysInfo.mdx";
import NonceInfo from "./PrimerComponents/NonceInfo.mdx";
import Web3LoginInfo from "./PrimerComponents/Web3LoginInfo.mdx";
import WalletInfo from "./PrimerComponents/WalletInfo.mdx";
import GuestBookInfo from "./PrimerComponents/GuestBookInfo.mdx";

// Quiz
import NonceQuiz from "./Quiz/NonceQuiz";
import Nonce2Quiz from "./Quiz/Nonce2Quiz";
import LoginQuiz from "./Quiz/LoginQuiz";
import FullAccessQuiz from "./Quiz/FullAccessQuiz";

// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";

const primers = [...start];

const primerComponents = {
  AccessKeysInfo: AccessKeysInfo,
  NonceInfo: NonceInfo,
  WalletInfo: WalletInfo,
  GuestBookInfo: GuestBookInfo,
  NonceQuiz: NonceQuiz,
  Nonce2Quiz: Nonce2Quiz,
  LoginQuiz: LoginQuiz,
  Web3LoginInfo: Web3LoginInfo,
  FullAccessQuiz: FullAccessQuiz,
  NextLesson: NextLesson,
};

const imageComponents = [];

const quizList = ["NonceQuiz", "LoginQuiz", "FullAccessQuiz", "Nonce2Quiz"];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
