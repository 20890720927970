import React, { Suspense, useState, useEffect } from "react";

import * as nearAPI from "near-api-js";
import { connectionConfig, nearConnection, walletConnection } from "./near-api";
const LoveWallApp = React.lazy(() => import("./LoveWall.jsx"));

const CONTRACT_ADDRESS = "wall.primerlabs.near";

const LoveWallContainer = ({ showAll = false }) => {
  const [nearConn, setNearConn] = useState(null);
  const [walletConnection, setWalletConnection] = useState(null);
  const [contract, setContract] = useState(null);
  useEffect(() => {
    (async () => {
      const nearConnection = await nearAPI.connect(connectionConfig);
      const wallet = new nearAPI.WalletConnection(nearConnection, "wall");

      const contractObject = new nearAPI.Contract(
        wallet.account(),
        CONTRACT_ADDRESS,
        {
          viewMethods: ["get_last_five_transactions", "get_all_posts"],
          changeMethods: ["add_post"],
        }
      );
      setContract(contractObject);
      setWalletConnection(wallet);
      setNearConn(nearConnection);
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        {nearConn ? (
          <LoveWallApp
            showAll={showAll}
            contract={contract}
            walletConnection={walletConnection}
          />
        ) : (
          <div>Loading...</div>
        )}
      </Suspense>
    </div>
  );
};

export default LoveWallContainer;
