import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["$ USD", "¥ YEN", "€ Euros", "Ⓝ NEAR tokens"];
  const correctOption = "Ⓝ NEAR tokens";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            Accounts on <b>NEAR Protocol</b> are charged for each transaction by
            a <b>small transaction fees</b>. {props.name}, how are these paid?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
