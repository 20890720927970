import {
  StyledTableRow,
  StyledTableCell,
} from "../../../../../components/Common/Table.jsx";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";

const BlockTimeTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "900px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell
              sx={{ color: "floralwhite", textAlign: "center" }}
              variant="body"
            >
              Action Type
            </StyledTableCell>
            <StyledTableCell
              sx={{ color: "floralwhite", textAlign: "center" }}
              variant="body"
            >
              Description
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>Transfer</code>
            </StyledTableCell>
            <StyledTableCell variant="body">
              Move tokens between accounts
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>CreateAccount</code>
            </StyledTableCell>
            <StyledTableCell variant="body">Make a new account</StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>DeleteAccount</code>
            </StyledTableCell>
            <StyledTableCell variant="body">
              Delete an account (and transfer the balance to a beneficiary
              account)
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>AddKey</code>
            </StyledTableCell>
            <StyledTableCell variant="body">
              Add a key to an account (either <code>FullAccess</code> or{" "}
              <code>FunctionCall</code> access)
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>DeleteKey</code>
            </StyledTableCell>
            <StyledTableCell variant="body">
              Delete an existing key from an account
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>Stake</code>
            </StyledTableCell>
            <StyledTableCell variant="body">
              Express interest in becoming a validator at the next available
              opportunity
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>DeployContract</code>
            </StyledTableCell>
            <StyledTableCell variant="body">
              Deploy a smart contract into an account
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>FunctionCall</code>
            </StyledTableCell>
            <StyledTableCell variant="body">
              Invoke a method on a contract (and optionally attach a budget for
              compute and storage)
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlockTimeTable;
