import { StyledTableCell, StyledTableRow } from "../../../Common/Table.jsx";

import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";

const BlockTimeTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "900px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell
              sx={{
                color: "floralwhite",
                textAlign: "center",
                minWidth: "100px",
              }}
              variant="body"
            >
              Method
            </StyledTableCell>
            <StyledTableCell
              sx={{
                color: "floralwhite",
                textAlign: "center",
                minWidth: "100px",
              }}
              variant="body"
            >
              Usage
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Initialize a vector collection
              <br />
              <code>new Vector('unique_prefix')</code>
            </StyledTableCell>

            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>this.v = new Vector('vector1')</code>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Adds data to the collection.
              <br />
              <code>this.v.push()</code>
            </StyledTableCell>

            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>this.v.push('abc')</code>
              <br />
              <code style={{ color: "darkgray" }}>// Vector(1)['abc']</code>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell
              variant="body"
              style={{ textAlign: "center", maxWidth: "120px" }}
            >
              Batch insert or Extend the current collection with the passed in
              array of elements.
              <br />
              <code>this.v.extend([items..])</code>
            </StyledTableCell>

            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>this.v.extend(['xyz', '123'])</code>
              <br />
              <code style={{ color: "darkgray" }}>
                // Vector(3)['abc', 'xyz', '123']
              </code>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell
              variant="body"
              style={{ textAlign: "center", maxWidth: "120px" }}
            >
              Get the data stored at the provided index.
              <br />
              <code>
                this.v.get(<span style={{ color: "darkgray" }}>index</span>)
              </code>
            </StyledTableCell>

            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>this.v.get(2)</code> outputs <code>'123'</code>
              <br />
              <code style={{ color: "darkgray" }}>
                // Vector(3)['abc', 'xyz', '123']
              </code>
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell
              variant="body"
              style={{ textAlign: "center", maxWidth: "120px" }}
            >
              Removes and retrieves the element with the highest index i.e the
              last stored element.
              <br />
              <code>this.v.pop()</code>
            </StyledTableCell>

            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>this.v.pop()</code> outputs <code>'123'</code>
              <br />
              <code style={{ color: "darkgray" }}>
                // Vector(2)['abc', 'xyz']
              </code>
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell
              variant="body"
              style={{ textAlign: "center", maxWidth: "120px" }}
            >
              Removes and retrieves the element with the highest index i.e the
              last stored element.
              <br />
              <code>this.v.pop()</code>
            </StyledTableCell>

            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>this.v.pop()</code> outputs <code>'123'</code>
              <br />
              <code style={{ color: "darkgray" }}>
                // Vector(2)['abc', 'xyz']
              </code>
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell
              variant="body"
              style={{ textAlign: "center", maxWidth: "120px" }}
            >
              Replaces the data stored at the provided index with the provided
              data and returns the previously stored data.
              <br />
              <code>
                this.v.replace(
                <span style={{ color: "darkgray" }}>index, element</span>)
              </code>
            </StyledTableCell>

            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>this.v.replace(1, 'def')</code> outputs <code>'xyz'</code>
              <br />
              <code style={{ color: "darkgray" }}>
                // Vector(2)['abc', 'def']{" "}
              </code>
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell
              variant="body"
              style={{ textAlign: "center", maxWidth: "120px" }}
            >
              Removes an element from the vector and returns it in serialized
              form. The removed element is replaced by the last element of the
              vector.
              <br />
              <code>
                this.v.swapRemove(
                <span style={{ color: "darkgray" }}>index</span>)
              </code>
            </StyledTableCell>

            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Let's say we have the following vector <br />
              <code style={{ color: "indianred" }}>
                Vector(4)['a', 'b', 'c', 'd']
              </code>
              <br />
              <code>this.v.swapRemove(1)</code> will output <code>'b'</code>
              <br />
              <code style={{ color: "darkgray" }}>
                // Vector(3)['a', 'd', 'c']
              </code>
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell
              variant="body"
              style={{ textAlign: "center", maxWidth: "120px" }}
            >
              Checks whether the collection is empty.
              <br />
              <code>this.v.isEmpty()</code>
            </StyledTableCell>

            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Let's say we have the following vector <br />
              <code style={{ color: "indianred" }}>Vector(0)[]</code>
              <br />
              <code>this.v.isEmpty()</code> will output <code>true</code>
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell
              variant="body"
              style={{ textAlign: "center", maxWidth: "120px" }}
            >
              Remove all of the elements stored within the collection.
              <br />
              <code>this.v.clear()</code>
            </StyledTableCell>

            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Let's say we have the following vector <br />
              <code style={{ color: "indianred" }}>
                Vector(3)['a', 'b', 'c']
              </code>
              <br />
              After running <code>this.v.clear()</code> will result in <br />
              <code style={{ color: "darkgray" }}>// Vector(0)[]</code>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell
              variant="body"
              style={{ textAlign: "center", maxWidth: "120px" }}
            >
              Return a JavaScript array of the data stored within the
              collection.
              <br />
              <code>this.v.toArray()</code>
            </StyledTableCell>

            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>this.v.toArray()</code> will result in a Javascript Array
              <br />
              <code style={{ color: "darkgray" }}>// ['a', 'b', 'c']</code>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlockTimeTable;

{
  /* <StyledTableRow
sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
>
<StyledTableCell variant="body" style={{ textAlign: "center" }}>
  <code>push</code>
</StyledTableCell>
<StyledTableCell variant="body" style={{ textAlign: "center" }}>
  <code>this.v.push('abc')</code>
  <br />
  <code style={{ color: "darkgray" }}>// ['abc']</code>
</StyledTableCell>
<StyledTableCell variant="body" style={{ textAlign: "center" }}>
  Adds data to the collection.
</StyledTableCell>
</StyledTableRow>

<StyledTableRow
sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
>
<StyledTableCell variant="body" style={{ textAlign: "center" }}>
  <code>extend</code>
</StyledTableCell>
<StyledTableCell variant="body" style={{ textAlign: "center" }}>
  <code>this.v.extend(['d', 'e'])</code> <br />
  <code style={{ color: "darkgray" }}>// ['abc', 'd', 'e']</code>
</StyledTableCell>
<StyledTableCell variant="body" style={{ textAlign: "center" }}>
  Extends the current collection with the passed in array of
  elements.
</StyledTableCell>
</StyledTableRow>

<StyledTableRow
sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
>
<StyledTableCell variant="body" style={{ textAlign: "center" }}>
  <code>pop</code>
</StyledTableCell>
<StyledTableCell variant="body" style={{ textAlign: "center" }}>
  <code>this.v.pop()</code>
  <code style={{ color: "darkgray" }}>// 'abc'</code>
</StyledTableCell>
<StyledTableCell variant="body" style={{ textAlign: "center" }}>
  Removes and retrieves the element with the highest index i.e the
  last stored element.
</StyledTableCell>
</StyledTableRow> */
}
