import { OutputFactory, Outputs } from "javascript-terminal";
import { CustomOutputFactory } from "../outputs";
import { arraysEqual, getTxId } from "./utils";

const helpText = `
**Usage**: &&near&& [[send]] @@<senderId>@@ @@<receiverId>@@ @@<amount>@@ 

    ^^Sends NEAR tokens (Ⓝ) from one account to another.^^
    
    For e.g., if you would like to send [[x NEAR tokens (Ⓝ)]] from [[sender.testnet]] to [[receiver.testnet]], you can do so by using following command: 
        
        &&near&& [[send]] @@sender.testnet@@ @@receiver.testnet@@ @@near-amount@@ 


**Notes**:
    1. You will need a &&full access key&& for the sending account
     
`;

const generateErrors = (errors) => {
  let mappedText = errors.reduce((acc, curr) => {
    return acc + `\n\t&&` + " * " + curr + `&&`;
  }, ``);
  return `**Encountered Following Errors**
${mappedText}

**Usage**: &&near&& [[send]] @@<senderId>@@ @@<receiverId>@@ @@<amount>@@ 

    For more info type:
        &&near&& [[send]] @@<senderId>@@ @@<receiverId>@@ @@<amount>@@ @@--help@@ 

`;
};

const missingSenderId = `Missing Sender Account ID`;
const missingReceiverId = `Missing Receiver Account ID`;
const missingNearToken = `Missing NEAR Ⓝ Token Amount `;
const invalidCommandUsage = `Invalid Command Usage`;

const missingMaster = `Missing Master Account`;
const missingAccountID = `Missing Account ID`;
const invalidAccountId = `Invalid Account ID provided`;
const endWithTestnet = `Master account must end with testnet`;
const accEndWithTestnet = `Account Id must end with ".testnet"`;
const notLongEnough = `AccountId should be greater than 32 characters`;
const tooLong = `AccountId should be smaller than 64 characters`;
const invalidMasterAccount = `Invalid Master Account Id provided`;

const checkDescendant = (account, masterAccount) => {
  return arraysEqual(account.split(".").slice(1), masterAccount.split("."));
};

const getSuccessMessage = (res) => {
  const hash = getTxId(res);
  const message = `
        Transaction Id ${hash}
        To see the transaction in the transaction explorer, please open this url in your browser (((https://explorer.testnet.near.org/transactions/${hash})))`;

  return message;
};

const checkForErrors = (accountId, options, loggedInAccount) => {
  let errors = [];

  const regexCheck =
    /^(([a-z\d]+[\-_])*[a-z\d]+\.)*([a-z\d]+[\-_])*[a-z\d]+$/im;

  if (!options.masterAccount) {
    errors.push(missingMaster);
    return errors;
  }

  if (!regexCheck.test(accountId)) {
    errors.push(invalidAccountId);
    return errors;
  }

  if (!regexCheck.test(options.masterAccount)) {
    errors.push(invalidMasterAccount);
    return errors;
  }

  if (!accountId.endsWith(".testnet")) {
    errors.push(accEndWithTestnet);
    return errors;
  }

  if (!options.masterAccount.endsWith(".testnet")) {
    errors.push(endWithTestnet);
    return errors;
  }

  if (!options.masterAccount.endsWith(loggedInAccount)) {
    const notAuthorised = `Not authorised to access the master account "${options.masterAccount}" Logged In account is ${loggedInAccount}`;
    errors.push(notAuthorised);
    return errors;
  }

  if (!checkDescendant(accountId, options.masterAccount)) {
    const notDescendent = `Provided accountId: ${accountId} is not a direct subaccount of the master account: ${options.masterAccount}`;
    errors.push(notDescendent);
    return errors;
  }

  if (accountId.length > 64) {
    errors.push(tooLong);
    return errors;
  }

  return errors;
};

const updateState = (type, message, setCustomState, setAcceptInput) => {
  if (type === "error") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(`&&${message}&&`)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  } else if (type === "success") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(message)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  }

  setAcceptInput(true);
};

export const sendKeyCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(helpText),
    };
  } else {
    if (args.length === 1) {
      const errors = [missingSenderId, missingReceiverId, missingNearToken];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if (args.length === 2) {
      const errors = [missingReceiverId, missingNearToken];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if (args.length === 3) {
      const errors = [missingNearToken];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if (args.length === 4) {
      const [_, senderAccountId, receiverAccountId, amount] = args;
      const { setAcceptInput, setCustomState } = stateChangeProps;

      setAcceptInput(false);

      context
        .sendNear(senderAccountId, receiverAccountId, amount)
        .then((res) => {
          let type = res.type ? res.type : "success";
          const message = getTxId(res)
            ? getSuccessMessage(res)
            : JSON.stringify(res, null, 4);

          updateState(type, message, setCustomState, setAcceptInput);
        })
        .catch((e) => {
          let type = "error";
          let message = e.message;
          updateState(type, message, setCustomState, setAcceptInput);
        });

      return {
        output: CustomOutputFactory.createColoredRecord(
          `Sending [[${amount}]] NEAR to [[${receiverAccountId}]] from [[${senderAccountId}]]...`
        ),
      };
    } else {
      const errors = [invalidCommandUsage];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    }
  }
};
