// Infos

import GasRuntime from "./PrimerComponents/GasRuntime.mdx";
import SmartContracts from "./PrimerComponents/SmartContracts.mdx";
import Transactions from "./PrimerComponents/Transactions.mdx";
import Incentives from "./PrimerComponents/Incentives.mdx";
import ChangeInfo from "./PrimerComponents/ChangeInfo.mdx";
import Recap from "./PrimerComponents/Recap.mdx";

// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";

// Quiz
import ChargeMCQ from "./Quiz/ChargeMCQ";
import FeesMCQ from "./Quiz/FeesMCQ";
import FunctionMCQ from "./Quiz/FunctionMCQ";
import GasMCQ from "./Quiz/GasMCQ";

// Primers
import { start } from "./Primers/Start";

const primers = [...start];

const primerComponents = {
  Transactions: Transactions,
  GasRuntime: GasRuntime,
  NextLesson: NextLesson,
  SmartContracts: SmartContracts,
  ChargeMCQ: ChargeMCQ,
  FeesMCQ: FeesMCQ,
  Incentives: Incentives,
  FunctionMCQ: FunctionMCQ,
  ChangeInfo: ChangeInfo,
  GasMCQ: GasMCQ,
  Recap: Recap,
};

const imageComponents = [];

const quizList = ["ChargeMCQ", "FeesMCQ", "FunctionMCQ", "GasMCQ"];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
