import { createTheme } from "@mui/material/styles";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
    success: "#1caff6",
  },
  fontFamily: "Nunito",
  palette: {
    primary: {
      main: "#47307f",
      darker: "#053e85",
      success: "blue",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    primer: {
      main: "#1caff6",
      contrastText: "#fff",
      darker: "#64748B",
    },
    success: {
      main: "#58cb05",
      darker: "#377d05",
      contrastText: "#fffaf0",
    },
    error: {
      main: "#fa5655",
      darker: "#d32f2f",
      contrastText: "#fff",
    },
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "quiz" },
          style: {
            fontFamily: "Nunito",
            fontSize: "1.4rem",
            lineHeight: "2.5rem",
          },
        },
      ],
      styleOverrides: {
        root: {
          fontFamily: "Nunito",
          color: "#3c3c3c",
        },
        subtitle1: {
          fontSize: "1.4rem",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "response" },
          style: {
            color: "darkgray",
            border: "1px 1px 4px 1px solid darkgray",
          },
        },
        {
          props: { variant: "dashed", color: "secondary" },
          style: {
            border: `4px dashed red`,
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: "1.2rem",
          fontFamily: "Nunito",
          fontWeight: "800",
          borderRadius: "10px",
          borderBottom: "4px solid darkblue",
          transition: "0.3s",
          paddingLeft: "30px",
          paddingRight: "30px",
        },
        contained: {
          boxShadow: "none",
        },
      },
    },
    // MuiInputLabel: {
    //   styleOverrides: {
    //     root: {
    //       fontSize: "1.5rem",
    //       fontFamily: "Nunito",
    //       fontWeight: "700",
    //       letterSpacing: "1px",
    //     },
    //   },
    // },
    // Name of the component
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     // Name of the slot
    //     root: {
    //       // Some CSS
    //       fontSize: "2rem",
    //       fontWeight: 800,
    //       fontFamily: "Nunito",
    //     },
    //   },
    // },
  },
});

export default theme;
