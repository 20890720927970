import React from "react";
import { near } from "../../../../NearWallet";
import { Paper, TextField, InputAdornment } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const storage_amount_per_byte = "10000000000000000000";

const priceRequest =
  "https://api.coingecko.com/api/v3/simple/price?ids=near&vs_currencies=usd";

const StorageExplorable = React.memo(() => {
  const [storagePrice, setStoragePrice] = React.useState(
    storage_amount_per_byte
  );
  const [fetched, setFetched] = React.useState(false);
  const [unitValue, setUnitValue] = React.useState("KB");
  const [fetchPrice, setFetchPrice] = React.useState(null);
  const [value, setValue] = React.useState(100);

  const nearCalculator = () => {
    const unitPrice = parseInt(storagePrice) / 10 ** 24;
    const numberOfUnits =
      unitValue === "MB"
        ? value * 10 ** 6
        : unitValue === "KB"
        ? value * 1000
        : value;
    const totalNear = Math.abs(unitPrice * numberOfUnits).toFixed(6);
    const totalPrice =
      fetchPrice !== null ? Math.abs(totalNear * fetchPrice) : 0;
    return { totalNear, totalPrice };
  };

  React.useEffect(() => {
    const getStoragePrice = async () => {
      const response =
        await near.connection.provider.experimental_protocolConfig({
          sync_checkpoint: "earliest_available",
        });
      const current_storage_price =
        response.runtime_config?.storage_amount_per_byte;

      if (current_storage_price) {
        setStoragePrice(current_storage_price);
        setFetched(true);
      }
    };

    const getNearPrice = async () => {
      const response = await fetch(priceRequest);
      const price = await response.json();
      setFetchPrice(price?.near?.usd);
    };

    getStoragePrice();
    getNearPrice();
  }, []);

  const calc = nearCalculator();

  return (
    <div>
      <p style={{ textAlign: "center" }}>
        <b>Storage staking</b> is priced in an amount set by the network.
        Currently, the <b>storage price</b> is{" "}
        <code>{parseInt(storagePrice)}</code> <b>yoctoNear</b> per each{" "}
        <b>byte</b> of data or <code>{parseInt(storagePrice) / 10 ** 19}</code>{" "}
        <b>Near</b> per <b>100 KB</b>.{" "}
      </p>
      <p style={{ textAlign: "center" }}>
        You can use the calculator below to estimate how much <b>NEAR</b> tokens
        has to be locked according to your storage needs.
      </p>
      <Paper
        elevation={1}
        style={{
          background: "floralwhite",
          width: "400px",
          margin: "10px auto",
          borderRadius: "12px",
          textAlign: "center",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h1 style={{ color: "indigo" }}>{calc.totalNear} NEAR</h1>
        {fetchPrice !== null && (
          <h2 style={{ color: "indigo" }}>
            {" "}
            ~{calc.totalPrice} USD<sup>*</sup>
          </h2>
        )}

        <TextField
          label="Storage"
          id="outlined-start-adornment"
          sx={{ m: 1, width: "25ch" }}
          style={{ margin: "10px auto" }}
          type="number"
          value={value}
          onChange={(evt) => setValue(evt.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{unitValue}</InputAdornment>
            ),
          }}
        />

        <FormControl>
          <FormLabel id="storage unit">
            <b>Storage Unit</b>
          </FormLabel>
          <RadioGroup
            style={{ margin: "5px auto" }}
            row
            name="storage_unit"
            value={unitValue}
            onChange={(event) => setUnitValue(event.target.value)}
          >
            <FormControlLabel value="B" control={<Radio />} label="B" />
            <FormControlLabel value="KB" control={<Radio />} label="KB" />
            <FormControlLabel value="MB" control={<Radio />} label="MB" />
          </RadioGroup>
        </FormControl>
        {fetchPrice !== null && (
          <span
            style={{
              fontVariant: "all-small-caps",
              letterSpacing: "2px",
              fontSize: "smaller",
            }}
          >
            *Price fetched from{" "}
            <a href="https://www.coingecko.com/en/coins/near" target="_blank">
              coingecko
            </a>
          </span>
        )}
      </Paper>
    </div>
  );
});

export default StorageExplorable;
