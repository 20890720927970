import React from "react";
import { eccrypto } from "eccrypto";
import { CenteredLayout } from "../../../../Quiz/layouts/Layout";
import { Paper, Button } from "@mui/material";
import { HashTextField, NormalTextField } from "../../../styledComponents";

const Explorable = ({
  type,
  generateKeyPair,
  keyPair,
  setEditPubKey,
  editPubKey,
  encyptedMessage,
  setEncryptedMessage,
  encryptMessage,
  setEditPrivKey,
  editPrivKey,
  decryptMessage,
  decryptedMessage,
  message,
  setMessage,
}) => {
  const { privateKey, publicKey } = keyPair;
  const encryptedMessageText = encyptedMessage.ciphertext.toString("hex");
  if (type === "GENERATE") {
    return (
      <CenteredLayout>
        <Paper
          style={{
            maxWidth: "400px",
            margin: "30px auto",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <NormalTextField
            label="Private Key"
            value={privateKey.toString("hex")}
            style={{ margin: "10px" }}
            multiline
            rows={3}
          />
          <NormalTextField
            label="Public Key"
            value={publicKey}
            style={{ margin: "10px" }}
            multiline
            rows={5}
          />

          <div style={{ margin: "auto" }}>
            <Button
              onClick={generateKeyPair}
              variant="outlined"
              sx={{ height: "3rem", fontSize: "1rem", margin: "10px auto" }}
            >
              Generate Key Pair
            </Button>
          </div>
        </Paper>
      </CenteredLayout>
    );
  } else if (type === "ENCRYPT") {
    return (
      <CenteredLayout>
        <Paper
          style={{
            maxWidth: "400px",
            margin: "30px auto",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <NormalTextField
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={{ margin: "10px", fontSize: "small" }}
            multiline
            rows={3}
            readOnly
            maxLength={200}
          />
          <NormalTextField
            label="Public Key"
            value={editPubKey}
            onChange={(e) => setEditPubKey(e.target.value)}
            style={{ margin: "10px" }}
            multiline
            rows={5}
          />

          <div style={{ margin: "auto" }}>
            <Button
              onClick={encryptMessage}
              variant="outlined"
              sx={{ height: "3rem", fontSize: "1rem", margin: "10px auto" }}
            >
              Encrypt Message
            </Button>
          </div>
          <NormalTextField
            label="Encrypted Message"
            value={encryptedMessageText}
            style={{ margin: "10px" }}
            multiline
            rows={5}
          />
        </Paper>
      </CenteredLayout>
    );
  } else if (type === "DECRYPT") {
    return (
      <CenteredLayout>
        <Paper
          style={{
            maxWidth: "400px",
            margin: "30px auto",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <NormalTextField
            label="Encrypted Message"
            value={encryptedMessageText}
            style={{ margin: "10px" }}
            multiline
            rows={3}
          />

          <NormalTextField
            label="Private Key"
            value={editPrivKey}
            onChange={(e) => setEditPrivKey(e.target.value)}
            style={{ margin: "10px" }}
            multiline
            rows={5}
          />

          <div style={{ margin: "auto" }}>
            <Button
              onClick={decryptMessage}
              variant="outlined"
              sx={{ height: "3rem", fontSize: "1rem", margin: "10px auto" }}
            >
              Decrypt Message
            </Button>
          </div>
          <NormalTextField
            label="Decrypted Message"
            value={decryptedMessage}
            style={{ margin: "10px", fontSize: "small" }}
            multiline
            rows={5}
          />
        </Paper>
      </CenteredLayout>
    );
  }
};

export default Explorable;
