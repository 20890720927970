import KeyPair from "../../../../../components/Explorables/Blockchain/KeyPair";
import KeyPairBitcoin from "../../../../../components/Explorables/Blockchain/KeyPairBitcoin";
import Signature from "../../../../../components/Explorables/Blockchain/Signature";
import SignedTxBlockchain from "../../../../../components/Explorables/Blockchain/SignedTxBlockchain";
import Hash from "../../../../../components/Explorables/Blockchain/Hash";
import Caeser from "../../../../../components/Explorables/Blockchain/Caeser";
import MessagePassing from "../../../../../components/Explorables/Blockchain/MessagePassing";

import EncryptMessage from "../../../../../components/Explorables/Common/OneOff/EncryptMessage/EncryptMessage";
import DigitalSignature from "../../../../../components/Explorables/Common/OneOff/EncryptMessage/DigitalSignature";

export const KeyPairExplorable = () => <KeyPair />;
export const BitcoinExplorable = () => <KeyPairBitcoin />;
export const SignatureExplorable = () => <Signature />;
export const HashExplorable = () => (
  <Hash userData="Calvin you stinkhead. I told you not to read it." />
);

export const CaeserExplorable = () => (
  <Caeser userData="Calvin you stinkhead. I told you not to read it." />
);

export const DecryptCaeserExplorable = () => (
  <Caeser decrypted userData="Zai, omxhuz omzzaf dqmp ftq yqeemsq !" />
);

export const MessagePassingExplorable = () => <EncryptMessage />;

export const SignedTxBlockchainExplorable = () => <SignedTxBlockchain />;

export const DigitalSignatureExplorable = () => <DigitalSignature />;
