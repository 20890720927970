import ShortPrimer from "../../../../../components/ShortPrimers";

const Text = () => (
  <p>
    You can get help in the writing the{" "}
    <span style={{ color: "indigo" }}>counter smart contract</span> by checking
    out this <span style={{ color: "indigo" }}>short guide</span>
  </p>
);

const Text1 = () => (
  <p>
    We can recap what we know about{" "}
    <span style={{ color: "indigo" }}>storage staking</span> on{" "}
    <span style={{ color: "indigo" }}>NEAR Protocol</span> using our{" "}
    <span style={{ color: "indigo" }}>short guide</span>
  </p>
);

export const CounterContractPrimer = () => (
  <ShortPrimer text={Text} type="counterContract" />
);

export const StorageStakingPrimer = () => (
  <ShortPrimer avatar="attention" text={Text1} type="storagePrimer" />
);
