export const topicData = {
  blockchain: [
    {
      id: 1,
      title: "Introduction",
      color: "#47307f",
      stars: "1",
      image: "sha",
      percent: 0,
      slug: "introduction",
    },
    {
      id: 2,
      title: "Hashes",
      color: "#47307f",
      stars: "5",
      image: "sha",
      percent: 10,
      slug: "hashes",
    },
    {
      id: 3,
      title: "Keys & Signature",
      color: "#47307f",
      stars: "4",
      image: "blockchain",
      percent: 10,
      slug: "keys-signature",
    },
    {
      id: 4,
      title: "Chain of Blocks",
      color: "#58cb05",
      stars: "3",
      image: "blockchain",
      percent: 50,
      slug: "chains-of-blocks",
    },
    {
      id: 5,
      title: "Distributed Blockchain",
      color: "#58cb05",
      stars: "2",
      image: "blockchain",
      percent: 10,
      slug: "distributed-blockchain",
    },
    {
      id: 6,
      title: "Mining",
      color: "#58cb05",
      stars: "0",
      image: "blockchain",
      percent: 10,
      slug: "mining",
    },
    {
      id: 7,
      title: "Consensus Algorithm",
      color: "#58cb05",
      stars: "0",
      image: "blockchain",
      percent: 10,
      slug: "consensus",
    },
    {
      id: 8,
      title: "Proof of Stake",
      color: "#58cb05",
      stars: "0",
      image: "blockchain",
      percent: 10,
      slug: "proof-of-stake",
    },
  ],
};

export const topicOrder = {
  blockchain: [[1], [2, 3], [4, 5, 6], [7, 8]],
};

export const quests = ["blockchain", "near"];
