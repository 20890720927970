export const guestBookPrimers = [
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "GuestBookInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "Nonce2Quiz",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "We have to come to an end of this lesson.",
        "In the next lesson, we learn more about transactions on NEAR Protocol",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "wave",
    },
  },
];
