import React from "react";
import DashboardAppBar from "../Dashboard/DashboardAppBar";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { explorableData } from "./ExplorableData";

const ExplorablePage = (props) => {
  const { explorableId } = useParams();
  const { context } = props;

  const checkExplorable = explorableData.find((x) => x.slug === explorableId);

  React.useEffect(() => {
    if (checkExplorable) {
      document.title = `${checkExplorable.title} | NEAR Protocol Primer`;
    } else {
      document.title = `No Explorable Found | NEAR Protocol Primer`;
    }
  }, []);

  if (!checkExplorable) {
    return (
      <React.Fragment>
        <DashboardAppBar context={context} />
        <div style={{ maxWidth: "900px", margin: "10px auto" }}>
          <h1>No Explorable Found</h1>
        </div>
      </React.Fragment>
    );
  }

  if (checkExplorable) {
    const Component = checkExplorable.component;
    return (
      <React.Fragment>
        <DashboardAppBar context={context} />
        <div style={{ maxWidth: "900px", margin: "10px auto" }}>
          <div style={{ textAlign: "center" }}>
            <h1 style={{ textAlign: "center" }}>{checkExplorable.title}</h1>
          </div>
          <Component />
        </div>
      </React.Fragment>
    );
  }

  return <div>Hello World</div>;
};

export default ExplorablePage;
