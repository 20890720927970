import React from "react";
import Popover from "@mui/material/Popover";
import { guideDataPopover } from "../Explorables/ExplorableData";
const GuideCard = ({ guide }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleHover = (evt) => {
    setAnchorEl(evt.currentTarget);
  };

  const handleLeave = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className="link-underline link-underline-black"
      style={{
        margin: "10px",
      }}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      <a
        style={{
          fontWeight: "bold",
          fontSize: "1.5rem",
          fontVariant: "all-small-caps",
          letterSpacing: "1px",
          textDecoration: "none",
        }}
        href={`/guides/${guide[0]}`}
        target="_blank"
      >
        {guide[1]}
      </a>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        style={{
          marginTop: "10px",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div
          style={{
            padding: "10px",
            fontWeight: "bold",
            fontFamily: "Nunito",
            background: "floralwhite",
            color: "darkslateblue",
            textAlign: "center",
            maxWidth: "300px",
          }}
        >
          {guideDataPopover[guide[0]]}
        </div>
      </Popover>
    </div>
  );
};

export default GuideCard;
