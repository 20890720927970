import { OutputFactory, Outputs } from "javascript-terminal";
import { fullAccessKey } from "near-api-js/lib/transaction";
import { CustomOutputFactory } from "../outputs";
import { arraysEqual, getTxId } from "./utils";

const addKeyHelpText = `
**Usage**: &&near&& [[add-key]] @@<accountId>@@ @@<access-key>@@

    ^^Add an access key to given account^^

There are two types of access-keys:
  - [[Full Access Key]]
  - [[Function Access Key]]

[[Full Access Key]]:  

  &&Arguments:&& [[accountId]] [[publicKey]]

  For e.g., if you would like to add a full access key to an **accountId** &&nearprotocolprimer.testnet&&. You can do so by first generating a keypair using [[near generate-key]] command. Then running the following command: 
    
      &&near&& [[add-key]] @@nearprotocolprimer.testnet@@ **<Generated Public Key>**

[[Function Access Key]]:  

  &&Arguments:&& [[accountId]] [[publicKey]] @@--contract-id@@ **<options>**

  &&Options:&& @@--method-names@@ @@--allowance@@

      [[--contract-id]] ^^is the contract you are allowing methods to be called on^^

      [[--method-names]] ^^are optional and if omitted, all methods of the --contract-id can be called.^^

      [[--allowance]] ^^is the amount of Ⓝ the key is allowed to spend on gas fees only. If omitted then key will only be able to call view methods.^^

[[Note: Each transaction made with this key will have gas fees deducted from the initial allowance and once it runs out a new key must be issued.]]

  For e.g., if you would like to add a function access key to an **accountId** &&nearprotocolprimer.testnet&&. You can do so by first generating a keypair using [[near generate-key]] command. Then running the following command: 
        
      &&near&& [[add-key]] @@nearprotocolprimer.testnet@@ **<Generated Public Key>** **--contract-id** [[example-contract.testnet]] **--method-names** example_method **--allowance** [[30000000000]]

  **Notes**:
      1. You must have the [[access-key]] that gives you the ability to add new keys for the given account-id 
     
`;
const deleteKeyHelpText = `
**Usage**: &&near&& [[keys]] @@<accountId>@@

    ^^view account public keys^^
    
    For e.g., if you would like to see the access keys of an account &&nearprotocolprimer.testnet&&. You can do so by using following command: 
        
        &&near&& [[keys]] @@nearprotocolprimer.testnet@@


**Notes**:
    1. You will use an existing full access key for the account you would like to add a new key to. 
     
`;

const generateErrors = (errors) => {
  let mappedText = errors.reduce((acc, curr) => {
    return acc + `\n\t&&` + " * " + curr + `&&`;
  }, ``);
  return `**Encountered Following Errors**
${mappedText}

**Usage**: &&near&& [[add-key]] @@<accountId>@@ @@<access-key>@@

    For more info type:
        &&near&& [[add-key]] @@--help@@ 

`;
};

const incorrectCommandUsage = `Incorrect Command Usage`;
const missingMaster = `Missing Master Account`;
const missingAccountID = `Missing Account ID`;
const missingPublicKey = `Missing Access Key`;
const invalidAccountId = `Invalid Account ID provided`;
const endWithTestnet = `Master account must end with testnet`;
const accEndWithTestnet = `Account Id must end with ".testnet"`;
const notLongEnough = `AccountId should be greater than 32 characters`;
const tooLong = `AccountId should be smaller than 64 characters`;
const invalidMasterAccount = `Invalid Master Account Id provided`;

const getSuccessMessage = (res) => {
  const hash = getTxId(res);
  const message = `
      Transaction Id ${hash}
      To see the transaction in the transaction explorer, please open this url in your browser (((https://explorer.testnet.near.org/transactions/${hash})))`;

  return message;
};

const updateState = (type, message, setCustomState, setAcceptInput) => {
  if (type === "error") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(`&&${message}&&`)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  } else if (type === "success") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(message)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  }

  setAcceptInput(true);
};

export const addKeyCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(addKeyHelpText),
    };
  } else {
    if (args.length === 1) {
      const errors = [missingAccountID, missingPublicKey];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if (args.length === 2) {
      const errors = [missingPublicKey];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else {
      const [_, accountId, accessKey] = args;
      const { setAcceptInput, setCustomState } = stateChangeProps;
      if (Object.keys(options).length === 0) {
        // Adding Full Access Keys

        setAcceptInput(false);

        context
          .addAccessKey(accountId, accessKey)
          .then((res) => {
            let type = res.type ? res.type : "success";
            const message = getTxId(res)
              ? getSuccessMessage(res, accountId)
              : JSON.stringify(res, null, 4);

            updateState(type, message, setCustomState, setAcceptInput);
          })
          .catch((e) => {
            let type = "error";
            let message = e.message;
            updateState(type, message, setCustomState, setAcceptInput);
          });

        return {
          output: CustomOutputFactory.createColoredRecord(
            `Adding full access key = [[${accessKey}]] to [[${accountId}]] ...`
          ),
        };
      } else {
        const contractId = options.contractId || null;
        const methodNames = options.methodNames || [];
        const allowance = options.allowance || null;
        let fullAccess = false;
        // Adding Full Access Keys

        setAcceptInput(false);

        context
          .addAccessKey(
            accountId,
            accessKey,
            contractId,
            methodNames,
            allowance,
            fullAccess
          )
          .then((res) => {
            let type = res.type ? res.type : "success";
            const message = getTxId(res)
              ? getSuccessMessage(res, accountId)
              : JSON.stringify(res, null, 4);

            updateState(type, message, setCustomState, setAcceptInput);
          })
          .catch((e) => {
            let type = "error";
            let message = e.message;
            updateState(type, message, setCustomState, setAcceptInput);
          });

        return {
          output: CustomOutputFactory.createColoredRecord(
            `Adding function call access key = [[${accessKey}]] to [[${accountId}]] ...`
          ),
        };
      }
    }
  }
};

// Adding Function calls
// Takes random contractID [x]
// Test Method Names
// Test Allowance

// else {
//     const errors = [incorrectCommandUsage];
//     const errorText = generateErrors(errors);
//     return {
//       output: CustomOutputFactory.createColoredRecord(errorText),
//     };
//   }
