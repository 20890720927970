import React from "react";
import MatchQuiz from "../../../../../components/Quiz/MatchQuiz";

const MatchTemplate = (props) => {
  const matchOptions = {
    POP3: "Used to receive incoming E-Mails",
    SMTP: "Used to send E-mails",
    HTTP: "Used to transfer Hypertext between two computers",
    BitTorrent: "Used for peer-to-peer file sharing",
    FTP: "Used for transmitting files from one host to another",
  };
  return (
    <MatchQuiz
      large={true}
      header={
        <React.Fragment>
          {props.name}, can you match the protocols and their usage?
        </React.Fragment>
      }
      matchOptions={matchOptions}
      {...props}
    />
  );
};

export default MatchTemplate;
