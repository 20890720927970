import React from "react";
import MatchQuiz from "../../../../../components/Quiz/MatchQuiz";

const MatchTemplate = (props) => {
  const matchOptions = {
    Reorg: "Switching Blocks to the new longest chain",
    "Bitcoin Address": "used to send/receive bitcoins",
    "Bitcoin Node": "computer running bitcoin software",
    Miner: "responsible for block production",
    "Transaction Request": "Entry to be written in the bitcoin ledger",
  };
  return (
    <MatchQuiz
      large={true}
      header={
        <React.Fragment>
          {props.name}, can you match the following terms and their usage?
        </React.Fragment>
      }
      matchOptions={matchOptions}
      {...props}
    />
  );
};

export default MatchTemplate;
