import React from "react";
import { ReorderQuiz } from "../../../../../components/Quiz/ReorderQuiz";

const itemsList = [
  "User sends a transaction request to a node",
  "Node verifies the transaction request and then stores it in a pool",
  "Node gossips verified transaction request to it's peer",
  "Nodes feed the transaction pool to miners",
  "Miners independently create a block with transactions from the pool",
  "Miners compete against each other to find a valid nonce for their block",
  "The first miner to mine a valid block broadcasts it's block to a node",
  "All nodes update their copy of blockchain adding the new block",
];

const ReorderTemplate = (props) => (
  <ReorderQuiz
    itemsList={itemsList}
    header={
      <React.Fragment>
        Can you rearrange how transactions are added to the{" "}
        <b>Bitcoin Blockchain</b>?
      </React.Fragment>
    }
    {...props}
  />
);

export default ReorderTemplate;
