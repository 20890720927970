import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AntTabs, AntTab } from "./Tabs";
{
  /* <span style={{ fontSize: "3rem" }}>{"\u201C"}</span>
 <span style={{ fontSize: "3rem" }}>{"\u201D"}</span> */
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography sx={{ color: "#47307f", display: "flex" }}>
            <span style={{ fontSize: "80px", margin: "0px", padding: "0px" }}>
              {"\u201C"}
            </span>
            <blockquote style={{ paddingTop: "10px", fontSize: "small" }}>
              {children}
            </blockquote>
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabPanelStyles = {
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  color: "#47307f",
};

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        display: "inline-block",
        bgcolor: "background.paper",
        border: "none",
      }}
    >
      <AntTabs
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderBottom: "none",
          borderRight: 1,
          borderColor: "divider",
          minWidth: "250px",
        }}
      >
        <AntTab label="Asset Primitives" {...a11yProps(0)} />
        <AntTab label="Account Primitives" {...a11yProps(1)} />
        <AntTab label="Transaction Primitives" {...a11yProps(2)} />
        <AntTab label="Verification Primitives" {...a11yProps(3)} />
        <AntTab label="Combinatorial Primitives" {...a11yProps(4)} />
      </AntTabs>
      <TabPanel style={tabPanelStyles} value={value} index={0}>
        Assets are now digitally native, verifiably unique and fully
        programmable. This can be used to provide the benefits of digitization
        to existing assets or the creation of new categories entirely. For
        example, whereas money used to be a single one-dimensional commodity,
        now it is fully programmable — everything from the terms of its issuance
        to conditions of its use can be baked directly into the asset itself.
        Asset primitives include:
        <ul>
          <li>
            <b>Programmatic Ownership:</b> Each account has verifiable control
            of its money, its digital goods and its data (which can represent
            real-world things like identification) as well as the ability to
            programmatically determine (or split) that ownership.
          </li>
          <li>
            <b>Digital Uniqueness:</b> A digital asset can be 100% unique,
            representing anything from a specific fungible coin to a completely
            nonfungible token.
          </li>
          <li>
            <b>Programmable Assets:</b> Programmatic asset creation, evolution
            and destruction
          </li>
        </ul>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={1}>
        Every actor in the ecosystem, whether human, contract or device is
        treated as having a top-level account. Treating each of these as first
        class citizens of the platform allows for both old-world identity and
        new styles of interaction between autonomous or semi-autonomous actors.
        Account primitives include:
        <ul>
          <li>
            <b>Autonomous:</b> Everything on the chain gets assigned to an
            account. Accounts can represent a person, company, app or even a
            thing (eg a refrigerator). Accounts are each first-class citizens
            regardless.
          </li>
          <li>
            <b>Single Sign-on (SSO):</b> One account works across the whole
            world of apps and anything else that wants to tie into the chain.
          </li>
          <li>
            <b>Reputation/History:</b> Every account's transaction history gives
            it reputation which can be used across services.
          </li>
        </ul>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={2}>
        The provision of Asset and Account primitives in the same shared data
        pool makes it trivially simple to create seamless interactions between
        those elements in ways which are almost impossible outside of the
        digitally native medium. The most commonly cited use case involves
        permissionless peer-to-peer transfer of money but this applies more
        broadly to any kind of digital asset. Transaction primitives include:
        <ul>
          <li>
            <b>Direct:</b> Transfers can be made directly between accounts
            without requiring the code or permission of a specific application,
            allowing peer-to-peer marketplaces or transfers.
          </li>
          <li>
            <b>Instant:</b> Financial and digital-good transactions have
            finality in seconds and do not require long waiting periods for
            confirmation.
          </li>
          <li>
            <b>Micro:</b> Negligible fees make high frequency or small quantity
            transfers significantly more viable than current financial
            infrastructure allows.
          </li>
          <li>
            <b>Conditional:</b> A smart contract can easily add logic to
            transactions, for example to create conditional escrow or time-based
            releases of currency or data.
          </li>
        </ul>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={3}>
        The immutable public ledger used to store data on NEAR creates both a
        verifiable record of what has occurred in the past and a verifiable
        repository of the code which is being run behind particular
        applications. This can be used in a number of creative ways from the
        small (is a dice game actually using randomness?) to the large (creating
        audit trails for supply chains). Verification primitives include:
        <ul>
          <li>
            <b>Checkpointing:</b> Cryptographic timestamping means it is easy to
            store verifiable checkpoints of state at a particular time, allowing
            applications to verify the authenticity or occurence of previous
            activity.
          </li>
          <li>
            <b>Verify Process Integrity:</b> The code which runs apps deployed
            to the platform can be verified in a way that current server-side
            code cannot.
          </li>
        </ul>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={4}>
        While it isn't hard to examine the low-level primitives which are
        natively provided by the technology of the platform, perhaps the most
        exciting possibilities come from combining multiple primitives to create
        higher level primitives. While many of these will be discovered over
        time, some examples include:
        <ul>
          <li>
            <b>Permissionless Markets:</b> Most markets today require permission
            from someone in order to function, for example the provider of the
            marketplace where activity occurs. The combination of multiple
            low-level primitives disintermediates this control and allows
            permissionless markets to flourish in places where there was no room
            to operate previously. This requires the combination of:
            <ol>
              <li>
                A native medium of exchange (to transact in) and unit of account
                (to price in).{" "}
              </li>
              <li>Peer-to-peer/permissionless transfer of the asset</li>
              <li>
                A censorship-resistant marketplace application (which provides
                discoverability, matching and pricing)
              </li>
              <li>Verifiable ownership of an asset</li>
            </ol>
          </li>
          <li>
            <b>Derivative Assets:</b> While it's significant to provide
            censorship-resistant asset storage in the first place, combining
            multiple low-level primitives allows us to create an infinite
            variety of new assets which combine existing assets, transactions
            and logic to meet the risk management needs of anyone so inclined to
            use them. This requires the combination of:
            <ol>
              <li>Verifiable ownership of an asset</li>
              <li>Programmatic escrow of an asset</li>
              <li>Programmatic rights transfer</li>
            </ol>
          </li>
          <li>
            <b>Open State Components:</b> Any app has access (when granted) to
            the shared pool of state data, whether in regards to specific assets
            or users of the platform. This allows components to operate as
            microservices do in today's applications — performing specific
            functions that can be composed together to achieve larger business
            goals. Because they are public, competition will ensure that the
            best of these achieve usage. This requires the combination of:
            <ol>
              <li>A shared data pool</li>
              <li>User-sovereign data</li>
              <li>Verifiable process integrity</li>
            </ol>
          </li>
        </ul>
      </TabPanel>
    </Box>
  );
}
