import regexifyString from "regexify-string";

const format = (inputStr) =>
  regexifyString({
    pattern:
      /\*\*(.*?)\*\*|\[\[(.*?)\]\]|\@\@(.*?)\@\@|\&\&(.*?)\&\&|\^\^(.*?)\^\^|\(\(\((.*?)\)\)\)/gim,
    decorator: (match, index) => {
      const yellowRe = /\[\[(.*?)\]\]/gim;
      const boldRe = /\*\*(.*?)\*\*/gim;
      const blueRe = /\@\@(.*?)\@\@/gim;
      const redRe = /\&\&(.*?)\&\&/gim;
      const textRe = /\^\^(.*?)\^\^/gim;
      const urlRe = /\(\(\((.*?)\)\)\)/gim;
      const yellowMatch = yellowRe.exec(match);
      const boldMatch = boldRe.exec(match);
      const blueMatch = blueRe.exec(match);
      const redMatch = redRe.exec(match);
      const textMatch = textRe.exec(match);
      const urlMatch = urlRe.exec(match);
      if (yellowMatch) {
        const text = yellowMatch["1"];
        return (
          <span
            key={`yellow-${index}`}
            style={{ color: "gold", fontWeight: "bold" }}
          >
            {text}
          </span>
        );
      } else if (boldMatch) {
        const text = boldMatch["1"];
        return (
          <span key={`bold-${index}`} style={{ fontWeight: "bold" }}>
            {text}
          </span>
        );
      } else if (blueMatch) {
        const text = blueMatch["1"];
        return (
          <span
            key={`blue-${index}`}
            style={{ fontWeight: "bold", color: "#acffe7" }}
          >
            {text}
          </span>
        );
      } else if (redMatch) {
        const text = redMatch["1"];
        return (
          <span
            key={`red-${index}`}
            style={{ fontWeight: "bold", color: "coral" }}
          >
            {text}
          </span>
        );
      } else if (textMatch) {
        const text = textMatch["1"];
        return (
          <span
            key={`red-${index}`}
            style={{
              fontWeight: "bold",
              color: "blanchedalmond",
            }}
          >
            {text}
          </span>
        );
      } else if (urlMatch) {
        const text = urlMatch["1"];
        return (
          <a
            href={text}
            target="_"
            key={`url-${index}`}
            style={{
              fontWeight: "bold",
              color: "#c7bbd0",
              textDecoration: "solid underline 1px",
            }}
          >
            {text}
          </a>
        );
      }
    },
    input: inputStr,
  });

const formatString = (inputStr) => {
  return format(inputStr);
};

export default formatString;
