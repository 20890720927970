import React from "react";
import AliceCarousel from "react-alice-carousel";
import { sha256 } from "js-sha256";
import PlainBlock from "./TSAPlainBlock";
import "react-alice-carousel/lib/alice-carousel.css";

export const ChainContext = React.createContext();

const responsive = {
  0: { items: 2 },
  568: { items: 2 },
  1024: { items: 3 },
};

const itemsValue = [
  {
    id: "1",
    blockNumber: 1,
    timeStamp: "01 May 2009 05:00:00 GMT",
    nonceValue: "13666",
    dataValue:
      "e43b384e71189389ac898213cc7a22758a183ceb5b0c07c035d0b1dba5d1b804",
    prev: null,
  },
  {
    id: "2",
    blockNumber: 2,
    timeStamp: "03 May 2009 07:30:00 GMT",
    nonceValue: "198214",
    dataValue:
      "0d66faf848e58ba8bbdd764546fda476a437f37e559c5e78f9bde38eaeea5cec",
    prev: 1,
  },
  {
    id: "3",
    blockNumber: 3,
    timeStamp: "05 May 2009 15:00:00 GMT",
    nonceValue: "21293",
    dataValue:
      "be834415f6943a5c653d2b340e5bf98ac7965367524e7d3c2e403ce66ff8875b",
    prev: 2,
  },
  {
    id: "4",
    blockNumber: 4,
    timeStamp: "05 May 2009 20:05:00 GMT",
    nonceValue: "42971",
    dataValue:
      "ea3353ac9cfa804c98d34d1e9589a62db582010728f67956441974d6e1cb0bcd",
    prev: 3,
  },
  {
    id: "5",
    blockNumber: 5,
    timeStamp: "05 May 2009 21:05:00 GMT",
    nonceValue: "27819",
    dataValue:
      "259918379de1f94ea9e851901a90902a45438b7ea0643e818e5719b23a62dd4d",
    prev: 4,
  },
];

const calculateHash = (chain) => {
  let hash = chain.reduce((acc, block) => {
    const blockHash = `block${block.blockNumber}nonce${
      block.nonceValue
    }timestamp${block.timeStamp}data${block.dataValue}prev${findPreviousHash(
      block.prev,
      chain
    )}hash`;
    acc[block.id] = sha256(blockHash);
    return acc;
  }, {});
  return hash;
};

const findPreviousHash = (prev, chain) => {
  if (prev === null) {
    return "0".repeat(64);
  } else {
    let prevBlock = chain.find((a) => parseInt(a.id) === prev);
    let prevBlockHashPrev = findPreviousHash(prevBlock.prev, chain);
    let prevBlockHash = `block${prevBlock.blockNumber}nonce${prevBlock.nonceValue}timestamp${prevBlock.timeStamp}data${prevBlock.dataValue}prev${prevBlockHashPrev}hash`;
    let hash = sha256(prevBlockHash);
    return hash;
  }
};

// const items = [
//   <PlainBlock />,
//   <PlainBlock />,
//   <PlainBlock />,
//   <PlainBlock />,
//   <PlainBlock />,
// ];

const generateBlocks = (chain) => {
  return chain.map((x, idx) => (
    <PlainBlock key={`item-${x.id}`} id={x.id} prev={x.prev} />
  ));
};

const Blockchain = () => {
  const [chain, setChain] = React.useState(itemsValue);
  const [hash, setHash] = React.useState(calculateHash(itemsValue));
  const [key, setKey] = React.useState(0);
  const [slide, setSlide] = React.useState(0);
  const [items] = React.useState(generateBlocks(itemsValue));

  //   const items = itemsValue.map((x, idx) => (
  //     <PlainBlock key={`item-${x.id}`} id={x.id} prev={x.prev} />
  //   ));

  React.useEffect(() => {
    setHash(calculateHash(chain));
  }, [chain]);

  const onSlideChange = (e) => {
    console.log(e);
    setSlide(e.item);
  };
  const onInitialized = (e) => {
    console.log(`Start position(activeIndex) on init: ${e.item}. Event:`, e);
  };

  const chainValues = {
    chain,
    setChain,
    hash,
    setHash,
  };

  return (
    <ChainContext.Provider value={chainValues}>
      <AliceCarousel mouseTracking items={items} responsive={responsive} />
    </ChainContext.Provider>
  );
};

export default Blockchain;
