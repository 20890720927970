import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "u_map.set(key, value)",
    "u_map.push(value)",
    "u_map.set(value)",
    "u_map[key] = value",
  ];
  const correctOption = "u_map.set(key, value)";
  return (
    <MCQQuiz
      code={true}
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, which of the following is the the correct way to store
            data into an <code>UnorderedMap</code> collection object{" "}
            <code>u_map</code>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
