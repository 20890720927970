import React from "react";
import FillQuiz from "../../../../../components/Quiz/FillQuiz";

const FillQuizTemplate = (props) => {
  const options = [
    "private",
    "block",
    "pool",
    "blockchain",
    "public",
    "keypair",
    "bitcoins",
    "sign",
    "nodes",
    "address",
  ];

  return (
    <FillQuiz
      header={
        <React.Fragment>
          {" "}
          <b>{props.name}</b>, can you fill in the blanks about how does{" "}
          <b>Bitcoin</b> decentralised ledger works?
        </React.Fragment>
      }
      send={props.send}
      options={options}
      num={8}
      {...props}
    >
      In order to transact using Bitcoin Ledger, an user needs to generate their
      unique bitcoin [address] and keep the corresponding [private] key safe.
      Then, they can share the address to anyone and start receiving [bitcoins].
      If a user wants to send bitcoins to another user, they need to issue a
      transaction request and [sign] it using their private key. A signed
      transaction request is then sent to the [nodes] where they are added to a
      transaction [pool]. Once, the transaction is verified, the miner adds the
      request in a [block] and eventually the block gets appended to the main
      [blockchain].
    </FillQuiz>
  );
};

export default FillQuizTemplate;
