import { OutputFactory, Outputs } from "javascript-terminal";
import { CustomOutputFactory } from "../outputs";
import { arraysEqual } from "./utils";

const createAccountHelpText = `
**Usage**: &&near&& [[create-account]] @@<accountId>@@ **--masterAccount** @@<masterAccountId>@@ **--initialBalance** @@<amountInNEAR>@@

    ^^create a new developer account (subaccount of the masterAccount, ex: app.alice.test)^^
    
    For e.g. let's say your wallet account is &&primerlabs.testnet&& and you would like to make a new account at [[app.contracts.primerlabs.testnet]]. 

    You can do so by first creating the subaccount [[contracts.primerlabs.testnet]] by using following command. 
        
        &&near&& [[create-account]] @@contracts.primerlabs.testnet@@ **--masterAccount** @@primerlabs.testnet@@ **--iniitalBalance** @@1@@

    Followed by creation of [[app.contracts.primerlabs.testnet]] subaccount using the previously created subaccount as master account:
        
        &&near&& [[create-account]] @@app.contracts.primerlabs.testnet@@ **--masterAccount** @@contracts.primerlabs.testnet@@ **--iniitalBalance** @@0.1@@

**Notes**:
    1. Master account should have &&enough balance to send the requisite iniital balance&&
    2. The subaccount should be a &&direct predecessor of the master account&&
    3. You should have &&access to the master account&&
    4. The &&default initialBalance&& is &&100 NEAR&&
    5. The &&masterAccount&& is a &&required argument&&
    6. New &&account name&& should be a valid account name
     
`;

const generateErrors = (errors) => {
  let mappedText = errors.reduce((acc, curr) => {
    return acc + `\n\t&&` + " * " + curr + `&&`;
  }, ``);
  return `**Encountered Following Errors**
${mappedText}

**Usage**: 
&&near&& [[create-account]] @@<accountId>@@ **--masterAccount** @@<masterAccountId>@@ **--initialBalance** @@<amountInNEAR>@@

For more info type:
&&near&& [[create-account]] @@--help@@ 

`;
};

const missingMaster = `Missing Master Account`;
const missingAccountID = `Missing Account ID`;
const invalidAccountId = `Invalid Account ID provided`;
const endWithTestnet = `Master account must end with testnet`;
const accEndWithTestnet = `Account Id must end with ".testnet"`;
const notLongEnough = `AccountId should be greater than 32 characters`;
const tooLong = `AccountId should be smaller than 64 characters`;
const invalidMasterAccount = `Invalid Master Account Id provided`;

const checkDescendant = (account, masterAccount) => {
  return arraysEqual(account.split(".").slice(1), masterAccount.split("."));
};

const checkForErrors = (accountId, options, loggedInAccount) => {
  let errors = [];

  const regexCheck =
    /^(([a-z\d]+[\-_])*[a-z\d]+\.)*([a-z\d]+[\-_])*[a-z\d]+$/im;

  if (!options.masterAccount) {
    errors.push(missingMaster);
    return errors;
  }

  if (!regexCheck.test(accountId)) {
    errors.push(invalidAccountId);
    return errors;
  }

  if (!regexCheck.test(options.masterAccount)) {
    errors.push(invalidMasterAccount);
    return errors;
  }

  if (!accountId.endsWith(".testnet")) {
    errors.push(accEndWithTestnet);
    return errors;
  }

  if (!options.masterAccount.endsWith(".testnet")) {
    errors.push(endWithTestnet);
    return errors;
  }

  if (!options.masterAccount.endsWith(loggedInAccount)) {
    const notAuthorised = `Not authorised to access the master account "${options.masterAccount}". Logged In account is ${loggedInAccount}`;
    errors.push(notAuthorised);
    return errors;
  }

  if (!checkDescendant(accountId, options.masterAccount)) {
    const notDescendent = `Provided accountId: ${accountId} is not a direct subaccount of the master account: ${options.masterAccount}`;
    errors.push(notDescendent);
    return errors;
  }

  if (accountId.length > 64) {
    errors.push(tooLong);
    return errors;
  }

  return errors;
};

const updateState = (type, message, setCustomState, setAcceptInput) => {
  if (type === "error") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(`&&${message}&&`)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  } else if (type === "success") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(message)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  }

  setAcceptInput(true);
};

export const createAccountCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  const loggedInAccount = context.walletAccountId;
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(createAccountHelpText),
    };
  } else {
    if (args.length === 1) {
      const errors = [missingAccountID, missingMaster];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else {
      const [_, accountId] = args;

      const errorsList = checkForErrors(accountId, options, loggedInAccount);
      if (errorsList.length) {
        const errorText = generateErrors(errorsList);
        return {
          output: CustomOutputFactory.createColoredRecord(errorText),
        };
      } else {
        const { setAcceptInput, setCustomState } = stateChangeProps;

        setAcceptInput(false);

        const initialBalance = options.initialBalance
          ? options.initialBalance
          : "100";

        context
          .createAccount(accountId, options.masterAccount, initialBalance)
          .then((res) => {
            const { type, message, response } = res;
            console.log(response);
            updateState(type, message, setCustomState, setAcceptInput);
          })
          .catch((e) => {
            let type = "error";
            let message = e.message;
            updateState(type, message, setCustomState, setAcceptInput);
            console.log(e.message);
          });

        return {
          output: OutputFactory.makeTextOutput(
            `Creating dev account ${accountId} ...`
          ),
        };
      }
    }
  }
};
