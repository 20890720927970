import React, { useState, useEffect } from "react";
// To include the default styles
import "react-rangeslider/lib/index.css";
import Proccessor from "./useProcessor";
import CodeBlockOptions from "./CodeBlockOptions";
import { PrettoSlider } from "../../../Canvas/StorageCanvas/Slider";

export default function Code({
  selectedFlow,
  flows,
  currentFlowState,
  flowMarkdown,
  ...props
}) {
  // const currentFlow = flows.find((fl) => fl.id === selectedFlow);
  const currentFlow = selectedFlow;
  const currentState = currentFlowState;

  const options = { flowMarkdown, selectedFlow, ...props };
  if (currentFlow) {
    if (
      flowMarkdown &&
      flowMarkdown[selectedFlow] &&
      flowMarkdown[selectedFlow][currentState]
    ) {
      return <RenderCode currentState={currentState} {...options} />;
    } else {
      return <div>Loading Markdown ... </div>;
    }
  } else {
    return <div>No Matching Flow found</div>;
  }
}

const RenderCode = ({ currentState, flowMarkdown, selectedFlow, ...props }) => {
  const currentMarkdown = flowMarkdown[selectedFlow][currentState];
  const flowsLength = Object.keys(flowMarkdown[selectedFlow]).length;
  const regex = new RegExp(/([a-zA-Z]+)-(\d+)/, "g");
  const match = regex.exec(currentState);
  const currentStateIndex = match ? match[2] : 0;

  return (
    <div style={{ fontSize: "100%" }}>
      {Proccessor(currentMarkdown, props.handleContext)}

      <CodeBlockOptions />
      <PrettoSlider
        max={flowsLength}
        min={1}
        step={1}
        value={parseInt(currentStateIndex)}
        valueLabelDisplay="auto"
        onChange={(evt) => {
          props.handleFlowStateChange(evt.target.value);
        }}
      />
    </div>
  );
};
