import { DataObjectSharp } from "@mui/icons-material";
import React, { Component, useCallback, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useSound from "use-sound";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (
  isDragging,
  draggableStyle,
  isHover,
  isSelected,
  noKey,
  isCompleted,
  busyValue,
  large
) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    color: isCompleted
      ? isDragging
        ? "floralwhite"
        : "black"
      : isSelected
      ? "floralwhite"
      : isDragging
      ? "white"
      : isHover
      ? "#47307f"
      : "gray",
    opacity: isCompleted ? 1 : noKey ? 0.6 : 1,
    pointerEvents:
      noKey || isCompleted ? "none" : busyValue ? "none" : "inherit",
    margin: "10px",
    height: large ? "2.5rem" : "1.6rem",
    fontSize: large ? "0.7rem" : "0.8rem",
    border: "1px solid gray",
    borderRadius: "12px",

    borderBottom: isSelected
      ? "4px solid black"
      : isHover
      ? "4px solid #47307f"
      : "4px solid gray",
    textAlign: "center",
    padding: "10px",
    fontWeight: "bold",
    cursor: "pointer",
    width: "400px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "uppercase",

    // change background colour if dragging
    background: isCompleted
      ? isDragging
        ? "#47307f"
        : "#58cb05"
      : isSelected
      ? "#47307f"
      : isDragging
      ? "#47307f"
      : "transparent",

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

const getListStyle = (isDraggingOver) => ({
  padding: grid,
  display: "flex",
  flexDirection: "column",
});

function delay(fn, time = 300) {
  return new Promise((resolve) => {
    setTimeout(() => {
      fn();
      resolve();
    }, time);
  });
}

function noop() {}

const Reorder = ({
  kee,
  matchOptions,
  setOrder,
  order,
  setValues,
  values,
  playWrong,
  setValue,
  playCorrect,
  keys,
  value,
  skip,
  setBusyValue,
  busyValue,
  completed,
  large,
}) => {
  let api;
  const useMyCoolSensor = (value) => {
    api = value;
  };

  const [hovered, setHovered] = React.useState(null);
  const [wrong, setWrong] = React.useState(null);

  React.useEffect(() => {
    let timer1 = setTimeout(() => setWrong(null), 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, [wrong]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    setValues(reorder(values, result.source.index, result.destination.index));
  };

  async function start(pos, index) {
    setBusyValue(true);
    const preDrag = api.tryGetLock(values[index].id, noop);

    if (!preDrag) {
      console.warn("unable to start drag");
      return;
    }

    const actions = preDrag.snapLift();
    const { moveDown, moveUp, drop } = actions;

    for (var i = 0; i < Math.abs(pos); i++) {
      await delay(moveUp);
    }

    await delay(drop);
    await delay(noop);
    setBusyValue(false);
  }

  const handleClick = (index, itemId, itemContent) => {
    if (kee) {
      if (!checkValue(itemContent)) {
        setWrong(itemId);
        playWrong();
      } else {
        const pos = Object.keys(order).length;
        setValue(itemContent);
        if (Object.keys(order).length < values.length - 1) {
          playCorrect();
        }
        setOrder({ ...order, [kee]: itemContent });
        if (pos - index) {
          start(pos - index, index);
        }
      }
    }
  };

  const checkValue = (item) => {
    return matchOptions[kee] === item;
  };

  const orderValues = Object.values(order);

  const moveItems = async () => {
    let copyValues = values;
    let orderLength = Object.keys(order).length;
    for (let k = 0; k < Object.keys(copyValues).length; k++) {
      // check if the first key already exists

      const alreadyExists = Object.keys(order).includes(keys[k].content);
      if (!alreadyExists) {
        const currentIndex = copyValues.findIndex((v) => v.id === keys[k].id);

        let currKey = keys[k].content;

        setOrder((order) => {
          return {
            ...order,
            [currKey]: values[currentIndex].content,
          };
        });
        if (orderLength - currentIndex) {
          await start(orderLength - currentIndex, currentIndex);
          await copyValues.splice(k, 0, copyValues.splice(currentIndex, 1)[0]);
        }

        orderLength++;
      }
    }
  };

  React.useEffect(() => {
    if (skip) {
      moveItems();
    }
  }, [skip]);

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      enableDefaultSensors={false}
      sensors={[useMyCoolSensor]}
    >
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {values.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    onMouseEnter={() => setHovered(item.id)}
                    onMouseOut={() => setHovered(null)}
                    onClick={() => handleClick(index, item.id, item.content)}
                    className={
                      wrong === item.id
                        ? "Match shake-horizontal shake-constant"
                        : "Match"
                    }
                    variant="outlined"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                      hovered === item.id,
                      value === item.content,
                      kee === null,
                      orderValues.includes(item.content) || completed,
                      busyValue,
                      large
                    )}
                  >
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Reorder;
