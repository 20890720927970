// rehype-highlight-word.js
import { toHtml } from "hast-util-to-html";
import { unified } from "unified";
import rehypeParse from "rehype-parse";

const CALLOUT = /__(.*?)__/g;

export default function (code) {
  // console.log("Recieved", code);
  const html = toHtml(code);
  // console.log("HTML", html);
  const result = html.replace(
    CALLOUT,
    (_, text) => `<a class="highlight-word">${text}</a>`
  );
  // console.log("Replaced", result);
  const unist = unified()
    .use(rehypeParse, { emitParseErrors: true, fragment: true })
    .parse(result);
  // console.log("Final AST", unist.children[0]);
  return unist.children;
};
