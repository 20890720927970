import React from "react";
import { CenteredLayout } from "./layouts/Layout";
import MCQDialog from "./components/MCQDialog";
import MCQPrimerDialog from "./components/MCQPrimerDialog";
import { motion } from "framer-motion";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useSound from "use-sound";

const alphabets = ["A", "B", "C", "D"];

const getOptions = (arr) =>
  arr.map((x, idx) => ({ id: `item-${idx}`, content: x }));

const MCQQuiz = ({
  header,
  options,
  correctOption,
  progress,
  progressIndex,
  setProgress,
  setProgressIndex,
  setProgressState,
  send,
  currentlyActive,
  primers,
  small = false,
  code = false,
  ...props
}) => {
  const [showDialog] = React.useState(props.showDialog || false);
  const [choices, setChoices] = React.useState(getOptions(options));
  const [selected, setSelected] = React.useState(
    currentlyActive || showDialog ? null : correctOption
  );
  const [checking, setChecking] = React.useState(false);
  const [completed, setCompleted] = React.useState(
    currentlyActive || showDialog ? false : true
  );
  const [wrong, setWrong] = React.useState(false);

  const [showCompleted, setShowCompleted] = React.useState(
    !currentlyActive && !showDialog
  );

  const [playSelect] = useSound("/sounds/pop_select.wav", { volume: 0.1 });
  const [playWrong] = useSound("/sounds/wrong.mp3", { volume: 0.1 });
  const [playLevelCompleted] = useSound("/sounds/level_completed2.wav", {
    volume: 0.1,
  });
  const [playCorrect] = useSound("/sounds/correct.mp3", { volume: 0.1 });

  React.useEffect(() => {
    if (!currentlyActive && !showDialog) {
      setShowCompleted(true);
    }
  }, [currentlyActive]);

  const choiceProps = {
    selected,
    setSelected,
    playSelect,
    completed,
    correctOption,
  };

  React.useEffect(() => {
    if (checking) {
      const correctSelected =
        choices.find((x) => x.id === selected).content === correctOption;
      if (correctSelected) {
        playCorrect();
        setCompleted(true);
        setProgressState("success");
      } else {
        playWrong();
        setWrong(true);
        setCompleted(true);
        setProgressState("error");
      }
    }
  }, [checking]);

  const dialogProps = {
    progress,
    setProgress,
    setProgressState,
    selected,
    setChecking,
    wrong,
    completed,
    primers,
  };

  const dialogPrimerProps = {
    progress,
    progressIndex,
    setProgress,
    setProgressIndex,
    setProgressState,
    selected,
    setChecking,
    wrong,
    completed,
    send,
  };

  return (
    <React.Fragment>
      <CenteredLayout>
        <div style={{ padding: "20px" }}>
          <Typography
            variant="h4"
            component="div"
            style={{ textAlign: "center" }}
          >
            {header}
          </Typography>
          <br />
          <Typography variant="quiz" component="div">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {choices.slice(0, 4).map((x, idx) => (
                <Choice
                  key={x.id}
                  id={x.id}
                  content={x.content}
                  showCompleted={showCompleted}
                  small={small}
                  code={code}
                  {...choiceProps}
                >
                  {" "}
                  {alphabets[idx]}. {x.content}
                </Choice>
              ))}
            </div>
          </Typography>
        </div>
        {currentlyActive && <MCQPrimerDialog {...dialogPrimerProps} />}
      </CenteredLayout>

      {showDialog && (
        <motion.div
          animate={{ opacity: [0, 0, 0, 1] }}
          transition={{ duration: 1 }}
        >
          <MCQDialog {...dialogProps} />
        </motion.div>
      )}
    </React.Fragment>
  );
};

export default MCQQuiz;

const Choice = ({
  selected,
  setSelected,
  id,
  content,
  completed,
  correctOption,
  showCompleted,
  small,
  code,
  ...props
}) => {
  const [hover, setHover] = React.useState(false);
  const isSelected = selected === id;
  const isCorrect = completed && content === correctOption;
  const isWrong = completed && isSelected && !isCorrect;
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => {
        setSelected(id);
        props.playSelect();
      }}
      style={{
        pointerEvents: showCompleted || completed ? "none" : "inherit",
        textTransform: code ? "none" : "uppercase",
        padding: "8px 16px",
        color: isCorrect
          ? "white"
          : isSelected || hover
          ? "floralwhite"
          : "gray",
        border: "1px solid lightgray",
        background: isCorrect
          ? "#58cb05"
          : isWrong
          ? "#F5CAC3"
          : isSelected || hover
          ? "#46307f"
          : "transparent",
        borderBottom: "4px solid darkgray",
        borderRadius: "12px",
        margin: "12px",
        fontSize: small ? "0.8rem" : "1rem",
        textAlign: "center",
        cursor: "pointer",
        fontWeight: "bold",
        width: "100%",
        maxWidth: "500px",
        transition: "0.2s",
      }}
    >
      {code ? (
        <code style={{ color: isSelected || hover ? "floralwhite" : "indigo" }}>
          {props.children}
        </code>
      ) : (
        <span>{props.children}</span>
      )}
    </div>
  );
};
