// Info
import AccountHierarchyInfo from "./PrimerComponents/AccountHierarchyInfo.mdx";
import CreateAccount from "./PrimerComponents/CreateAccount.mdx";
import AccountInfo from "./PrimerComponents/AccountInfo.mdx";
import AccountsTypeInfo from "./PrimerComponents/AccountsTypeInfo.mdx";
import AccessKeysInfo from "./PrimerComponents/AccessKeysInfo.mdx";
import AccessKeysTypeInfo from "./PrimerComponents/AccessKeysTypeInfo.mdx";

import NextLesson from "./PrimerComponents/NextLesson";

// Quizzes
import StateQuiz from "./Quiz/StateQuiz";
import SmartContractQuiz from "./Quiz/SmartContractQuiz";
import AccountQuiz from "./Quiz/AccountQuiz";
import AccessKeyQuiz from "./Quiz/AccessKeyQuiz";
import KeyQuiz from "./Quiz/KeyQuiz";

// Primers
import { start } from "./Primers/Start";

const primers = [...start];

const primerComponents = {
  AccountHierarchyInfo: AccountHierarchyInfo,
  CreateAccount: CreateAccount,
  AccountInfo: AccountInfo,
  AccountsTypeInfo: AccountsTypeInfo,
  AccessKeysInfo: AccessKeysInfo,
  AccessKeysTypeInfo: AccessKeysTypeInfo,
  StateQuiz: StateQuiz,
  SmartContractQuiz: SmartContractQuiz,
  AccountQuiz: AccountQuiz,
  AccessKeyQuiz: AccessKeyQuiz,
  KeyQuiz: KeyQuiz,
  NextLesson: NextLesson,
};

const imageComponents = [];

const quizList = [
  "StateQuiz",
  "SmartContractQuiz",
  "AccountQuiz",
  "AccessKeyQuiz",
  "KeyQuiz",
];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
