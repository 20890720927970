import React from "react";
import { Paper } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

export const NearCliDownload = () => (
  <Paper
    style={{
      background: "indigo",
      padding: "20px",
      margin: "10px auto",
      color: "floralwhite",
    }}
  >
    <div
      style={{
        margin: "0px 25px",
        textAlign: "center",
        lineHeight: "1.5rem",
        fontWeight: "bold",
      }}
    >
      From this part onwards, it is assumed that you have downloaded and
      installed the latest version of{" "}
      <code style={{ color: "salmon" }}>NEAR-CLI</code> on your computer. In
      case you haven't, you can download the binary from{" "}
      <a target="_blank" href="https://github.com/near/near-cli">
        <code style={{ color: "salmon" }}>here</code>
      </a>
      .
    </div>
  </Paper>
);

export const StepsForAccessKeyRotation = () => (
  <Paper
    style={{ padding: "30px", background: "#47307f", color: "floralwhite" }}
  >
    <h2 style={{ textAlign: "center" }}>Access Key Rotation</h2>
    <ol style={{ fontWeight: "bold" }}>
      <li style={{ margin: "10px 0" }}>Generate a local keypair.</li>
      <li style={{ margin: "10px 0" }}>
        Add the newly generated as a full{" "}
        <code style={{ color: "salmon" }}>access_key</code> to the account using
        the command <code style={{ color: "salmon" }}>near add-key</code>
      </li>
      <li style={{ margin: "10px 0" }}>
        In the{" "}
        <code style={{ color: "salmon" }}>~/.near-credentials/testnet</code>{" "}
        folder, edit the name of the file contain to{" "}
        <code style={{ color: "salmon" }}>primerlabs.testnet</code> to some
        other name such as
        <code style={{ color: "salmon" }}> old-primerlabs.testnet</code>
      </li>
      <li style={{ margin: "10px 0" }}>
        Edit the name of the newly generated keypair as{" "}
        <code style={{ color: "salmon" }}>primerlabs.testnet</code>
      </li>
      <li style={{ margin: "10px 0" }}>
        Finally, delete the previous key on the blockchain using{" "}
        <code style={{ color: "salmon" }}>near delete-key</code>.
      </li>
    </ol>
  </Paper>
);

export const StepsForGettingImplicitAccountId = () => (
  <Paper
    style={{ padding: "30px", background: "#47307f", color: "floralwhite" }}
  >
    <h2 style={{ textAlign: "center" }}>
      Deriving Implicit Account ID from base58 Public Key
    </h2>
    <ol style={{ fontWeight: "bold" }}>
      <li style={{ margin: "10px 0" }}>
        Start the <code style={{ color: "salmon" }}>repl</code> by running the
        command <code style={{ color: "salmon" }}>near repl</code> on{" "}
        <code style={{ color: "salmon" }}>NEAR CLI</code>
      </li>
      <li style={{ margin: "10px 0" }}>
        Store your base58 public to a local constant such as: <br />{" "}
        <code style={{ color: "salmon" }}>
          cost pk58 = 'ed25519:BGCCDDHfysuuVnaNVtEhhqeT4k9Muyem3Kpgq2U1m9HX'
        </code>
      </li>
      <li style={{ margin: "10px 0" }}>
        Parse the public key and convert it to the hex in a line using: <br />
        <code style={{ color: "salmon" }}>
          nearAPI.utils.PublicKey.fromString(pk58).data.hexSlice()
        </code>
      </li>
      <li style={{ margin: "10px 0" }}>
        The output string is the account ID in hex (without{" "}
        <code style={{ color: "salmon" }}>'</code>):
        <br />
        <code style={{ color: "salmon" }}>
          '98793cd91a3f870fb126f66285808c7e094afcfc4eda8a970f6648cdf0dbd6de'
        </code>
      </li>
    </ol>
  </Paper>
);

const terminalStyle = {
  background: "rgba(0, 0, 0, 0.87)",
  padding: "10px",
  color: "floralwhite",
  fontFamily: "Jetbrains Mono",
  margin: "10px",
};

const commandString =
  '{"new_account_id": "<account-name>.testnet", "new_public_key": "ed25519:<data>"}';

const getCommandString = (testnet) =>
  testnet
    ? '{"new_account_id": "<account-name>.testnet", "new_public_key": "ed25519:<data>"}'
    : '{"new_account_id": "<account-name>.near", "new_public_key": "ed25519:<data>"}';

const optionString = "--deposit 0.00182 --accountId <account-with-funds>";

export const TerminalMessage = () => {
  const [testnet, setTestnet] = React.useState(true);
  return (
    <div>
      <FormControlLabel
        style={{ marginLeft: "1px" }}
        control={
          <Switch
            onChange={(evt) => setTestnet(evt.target.checked)}
            checked={testnet}
            label="testnet"
          />
        }
        label={
          testnet ? (
            <span style={{ fontWeight: "bold" }}>Testnet</span>
          ) : (
            <span style={{ fontWeight: "bold" }}>Mainnet</span>
          )
        }
      />
      <div style={terminalStyle}>
        ${" "}
        {testnet ? (
          ""
        ) : (
          <span style={{ color: "salmon", fontWeight: "bold" }}>
            NEAR ENV=mainnet
          </span>
        )}{" "}
        near call testnet create_account{" "}
        <span
          style={{ color: "cornflowerblue", fontWeight: "bold" }}
        >{`'${getCommandString(testnet)}'`}</span>{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {optionString}
        </span>
      </div>
    </div>
  );
};

const commandOptions = {
  new_account_id: "implicit_test.testnet",
  new_public_key: "ed25519:35XBYuZJMa9J3fQXT2T2LAfD762NJhvFLSLdXNpTVh4F",
};

const getCommandOptions = (testnet) =>
  testnet
    ? {
        new_account_id: "implicit_test.testnet",
        new_public_key: "ed25519:35XBYuZJMa9J3fQXT2T2LAfD762NJhvFLSLdXNpTVh4F",
      }
    : {
        new_account_id: "implicit_test.near",
        new_public_key: "ed25519:35XBYuZJMa9J3fQXT2T2LAfD762NJhvFLSLdXNpTVh4F",
      };

const commandOptionsNear = {
  new_account_id: "implicit_test.near",
  new_public_key: "ed25519:35XBYuZJMa9J3fQXT2T2LAfD762NJhvFLSLdXNpTVh4F",
};

const getOptions = (testnet) =>
  testnet
    ? "--deposit 0.00182 --accountId primerlabs.testnet"
    : "--deposit 0.00182 --accountId primerlabs.near";

export const TerminalMessage2 = () => {
  const [testnet, setTestnet] = React.useState(true);
  return (
    <div>
      <FormControlLabel
        style={{ marginLeft: "1px" }}
        control={
          <Switch
            onChange={(evt) => setTestnet(evt.target.checked)}
            checked={testnet}
            label="testnet"
          />
        }
        label={
          testnet ? (
            <span style={{ fontWeight: "bold" }}>Testnet</span>
          ) : (
            <span style={{ fontWeight: "bold" }}>Mainnet</span>
          )
        }
      />

      <div style={terminalStyle}>
        ${" "}
        {testnet ? (
          ""
        ) : (
          <span style={{ color: "salmon", fontWeight: "bold" }}>
            NEAR ENV=mainnet
          </span>
        )}{" "}
        near call {testnet ? "testnet" : "mainnet"} create_account{" "}
        <span style={{ wordBreak: "break-word" }}>{`'${JSON.stringify(
          getCommandOptions(testnet)
        )}'`}</span>{" "}
        <span style={{ color: "gold", fontWeight: "bold" }}>
          {getOptions(testnet)}
        </span>
      </div>
    </div>
  );
};
