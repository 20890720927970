// Infos
import DonationsInfo from "./PrimerComponents/DonationsInfo.mdx";
import DonationsInfo2 from "./PrimerComponents/DonationsInfo2.mdx";
import DonationsInfo3 from "./PrimerComponents/DonationsInfo3.mdx";
import DonationsInfo4 from "./PrimerComponents/DonationsInfo4.mdx";
import DonationsInfo5 from "./PrimerComponents/DonationsInfo5.mdx";

// Next Lesson

// Quiz
import WalletMCQ from "./Quiz/WalletMCQ";
import AccountMCQ from "./Quiz/AccountMCQ";
import FrontMatch from "./Quiz/FrontMatch";
import WalletSelectorMCQ from "./Quiz/WalletSelectorMCQ";

// Primers
import { start } from "./Primers/Start";

const primers = [...start];

const primerComponents = {
  DonationsInfo: DonationsInfo,
  DonationsInfo2: DonationsInfo2,
  DonationsInfo3: DonationsInfo3,
  DonationsInfo4: DonationsInfo4,
  DonationsInfo5: DonationsInfo5,
  WalletMCQ: WalletMCQ,
  AccountMCQ: AccountMCQ,
  FrontMatch: FrontMatch,
  WalletSelectorMCQ: WalletSelectorMCQ,
};

const imageComponents = [];

const quizList = ["WalletMCQ", "AccountMCQ", "FrontMatch", "WalletSelectorMCQ"];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
