import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { terminalStyle } from "./styles";
import ReactJson from "react-json-view";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Env } from "./TerminalMessages/common";
import { StyledTooltip } from "../../../styledComponents";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AccountKeysModal = ({ keys, testnet, setTestnet }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const stringJSON = JSON.stringify(keys, null, "\t");
  const network = testnet ? "testnet" : "mainnet";
  const accountCurrent = testnet ? "primerlabs.testnet" : "primerlabs.near";
  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <StyledTooltip
          title={`Account keys associated with the account on ${accountCurrent} on the NEAR ${network}`}
          placement="right"
          style={{ width: "500px" }}
        >
          <Button
            onClick={handleOpen}
            variant="outlined"
            style={{
              fontSize: "12px",
              width: "250px",
              height: "36px",
              margin: "10px",
            }}
          >
            Show Account Keys ({keys[network].length})
          </Button>
        </StyledTooltip>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2
            style={{
              textAlign: "center",
              wordWrap: "break-word",
              fontWeight: "bold",
              color: "indigo",
            }}
          >
            Account Keys for{" "}
            {testnet ? (
              <span>primerlabs.testnet</span>
            ) : (
              <span>primerlabs.near</span>
            )}
          </h2>
          <div style={{ textAlign: "center" }}>
            <FormControlLabel
              style={{ margin: "5px auto" }}
              control={
                <Switch
                  size="small"
                  checked={!testnet}
                  onChange={(evt) => setTestnet(!evt.target.checked)}
                />
              }
              label={
                <span
                  style={{
                    fontWeight: "bold",
                    margin: "5px auto",
                    fontSize: "12px",
                  }}
                >
                  {testnet ? "NEAR Testnet" : "NEAR Mainnet"}
                </span>
              }
            />
          </div>
          <div style={terminalStyle}>
            <span style={{ color: "#f8aeff" }}>$</span> {!testnet && <Env />}{" "}
            near keys
            <span style={{ fontWeight: "bold", color: "salmon" }}>
              {" "}
              {testnet ? "primerlabs.testnet" : "primerlabs.near"}
            </span>
            <br />
          </div>
          <ReactJson src={keys[testnet ? "testnet" : "mainnet"]} />
        </Box>
      </Modal>
    </React.Fragment>
  );
};
export default AccountKeysModal;

//   dangerouslySetInnerHTML={{ __html: stringJSON }}
