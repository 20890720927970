import React from "react";
import CircularCard from "../Card/CircularCard";
import { topicOrder, topicData, quests } from "./QuestTopicData";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { nanoid } from "nanoid";
import DashboardAppBar from "../Dashboard/DashboardAppBar";
import QuestBreadcrumbs from "./QuestBreadcrumbs";

const QuestPage = (props) => {
  const { questId } = useParams();
  const { context } = props;
  React.useEffect(() => {
    if (context.topics[questId]) {
      const questName = context.quests.find((x) => x.slug === questId).title;
      document.title = `${questName} | NEAR Protocol Primer`;
    } else {
      document.title = `No Quest Found | NEAR Protocol Primer`;
    }
  }, []);

  if (!context.topics[questId]) {
    return (
      <React.Fragment>
        <DashboardAppBar context={context} />
        <div style={{ maxWidth: "900px", margin: "10px auto" }}>
          <h1>No Quest Found</h1>
        </div>
      </React.Fragment>
    );
  }

  if (context.topics[questId]) {
    const topicGroups = context.topics[questId];
    const questName = context.quests.find((x) => x.slug === questId).title;

    return (
      <div>
        <DashboardAppBar context={context} />
        <div style={{ maxWidth: "900px", margin: "40px auto" }}>
          <QuestBreadcrumbs questName={questName} />
        </div>
        {topicGroups.map((x, idx) => {
          const order = x.topicOrder;
          const questTopics = x.topics;
          const orderedTopics = order.map((arr) =>
            arr.map((id) => questTopics.find((x) => x.id === id))
          );
          return (
            <fieldset
              key={`section-${x.group}`}
              style={{
                maxWidth: "900px",
                margin: "10px auto",
                border: `3px solid ${x.background}`,
                borderRadius: "12px",
                fontSize: "1rem",
                background: `${x.background}`,
              }}
            >
              <legend
                style={{
                  fontWeight: "bold",
                  background: "white",
                  padding: "10px 20px",
                  borderRadius: "12px",
                  fontSize: "x-large",
                  border: `3px solid ${x.background}`,
                }}
              >
                {`${x.name}`}
              </legend>
              {orderedTopics.map((arr, idx) => (
                <div key={nanoid(idx)}>
                  <div style={{ justifyContent: "center", display: "flex" }}>
                    {arr.map((top, index) => (
                      <CircularCard
                        key={nanoid(index)}
                        color="#47307f"
                        title={`${top.id}. ${top.title}`}
                        image={top.image}
                        stars={top.stars}
                        percent={top.percent}
                        slug={top.slug}
                        topic={top}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </fieldset>
          );
        })}
      </div>
    );
  }

  if (context.topics[questId]) {
    console.log(context.topics[questId]);
    const order = topicOrder[questId];
    const questTopic = topicData[questId];
    const orderedTopics = order.map((arr) =>
      arr.map((id) => questTopic.find((x) => x.id === id))
    );
    return (
      <div>
        {orderedTopics.map((arr, idx) => (
          <div key={nanoid(idx)}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              {arr.map((top, index) => (
                <CircularCard
                  key={nanoid(index)}
                  color={top.color}
                  title={top.title}
                  image={top.image}
                  stars={top.stars}
                  percent={top.percent}
                  slug={top.slug}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
};

export default QuestPage;

{
  /* <hr style={{ borderWidth: "1px", width: `${arr.length * 25}%` }} /> */
}
