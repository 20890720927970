import React from "react";
import { Paper, Button } from "@mui/material";
import { paperStyle } from "./styles";
import GenerateKeyPair from "./GenerateKeyPair";
import AddKeyPair from "./AddKeyPair";
import RemoveKeyPair from "./RemoveKeyPair";
import TreeView from "./TreeView";
import Terminal from "./Terminal";
import { initialAccessKeys, initialAccountKeys } from "./keysData";
import toast from "react-hot-toast";

/*
  1. Show Only Generate Key Pairs
  2. Show Add Key Pairs
  3. Show Remove Key Pairs
  4. Show Access Key Rotation
*/

const getExplorable = (explorable, props) =>
  ({
    GENERATE: <GenerateKeyPair {...props} />,
    ADD: <AddKeyPair {...props} />,
    REMOVE: <RemoveKeyPair {...props} />,
  }[explorable]);

const AccessKeys = ({
  type,
  defaultAccessKeys = initialAccessKeys,
  defaultAccountKeys = initialAccountKeys,
  setInitialAccessKeys = null,
  setInitialAccountKeys = null,
}) => {
  const [explorable, setExplorable] = React.useState(type);
  const [accessKeys, setAccessKeys] = React.useState(defaultAccessKeys);
  const [changed, setChanged] = React.useState([]);

  const [useAccount, setUseAccount] = React.useState(false);
  const [useSeedphrase, setUseSeedphrase] = React.useState(false);
  const [accountId, setAccountId] = React.useState("");
  const [seedphrase, setSeedphrase] = React.useState("");
  const [testnet, setTestnet] = React.useState(true);

  const [message, setMessage] = React.useState(null);
  const [accountAccessKeys, setAccountAccessKeys] =
    React.useState(defaultAccountKeys);

  const [publicKeyText, setPublicKeyText] = React.useState("");
  const [keyTypeFull, setKeyTypeFull] = React.useState(true);

  const [contractId, setContractId] = React.useState("");
  const [allowance, setAllowance] = React.useState("");
  const [methods, setMethods] = React.useState("");

  const handleReset = () => {
    setAccessKeys(defaultAccessKeys);
    setAccountAccessKeys(defaultAccountKeys);
    setMessage(null);
    setChanged([]);
    toast.success(`The explorable was restored to it's initial configuration`, {
      id: "Reset",
    });
  };

  React.useEffect(() => {
    setExplorable(type);
  }, [type]);

  React.useEffect(() => {
    if (setInitialAccessKeys !== null) {
      setInitialAccessKeys(accessKeys);
    }
  }, [accessKeys]);

  React.useEffect(() => {
    if (setInitialAccountKeys !== null) {
      setInitialAccountKeys(accountAccessKeys);
    }
  }, [accountAccessKeys]);

  const explorableProps = {
    handleReset,
    accessKeys,
    setAccessKeys,
    changed,
    setChanged,
    useAccount,
    setUseAccount,
    useSeedphrase,
    setUseSeedphrase,
    accountId,
    setAccountId,
    seedphrase,
    setSeedphrase,
    testnet,
    setTestnet,
    message,
    setMessage,
    accountAccessKeys,
    setAccountAccessKeys,
    explorable,
    publicKeyText,
    setPublicKeyText,
    keyTypeFull,
    setKeyTypeFull,
    contractId,
    setContractId,
    allowance,
    setAllowance,
    methods,
    setMethods,
  };

  React.useEffect(() => {
    if (message !== null) {
      setMessage(null);
    }
  }, [
    useAccount,
    useSeedphrase,
    seedphrase,
    testnet,
    accountId,
    publicKeyText,
    contractId,
  ]);

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <Paper style={paperStyle}>
          {getExplorable(explorable, explorableProps)}
        </Paper>
        <TreeView
          keys={accessKeys}
          changed={changed}
          setChanged={setChanged}
          setAccessKeys={setAccessKeys}
        />
      </div>
      <div>
        <Terminal {...explorableProps} />
      </div>
    </React.Fragment>
  );
};

export default AccessKeys;
