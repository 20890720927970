import React from "react";
import Popper from "@mui/material/Popper";
import Zoom from "@mui/material/Zoom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import { nanoid } from "nanoid";
import { Navigate } from "react-router-dom";
import { withRouter } from "../withRouter";

import "./CircularCard.css";

const calculatePct = (val, radius) => {
  const circumference = Math.PI * (radius * 2);
  const percentage = ((100 - val) / 100) * circumference;
  return percentage;
};

/* 
 Props: 
    1. color of the fill: #58cb05
    2. image svg: 
    3. stars : 0-5
    4. title of the topic
    5. Percent
*/

const calculatePercent = (progress = 0, primers = [1, 2, 3, 4, 5, 6]) =>
  parseInt((parseInt(progress) / primers.length) * 100);

class CircularCard extends React.Component {
  constructor(props) {
    super(props);
    this.svgRef = React.createRef();
    this.percentage = calculatePercent(
      props.topic.progress,
      props.topic.primers
    );
    this.state = {
      input: this.percentage,
      pct: calculatePct(this.percentage, 75),
      radius: 75,
      open: false,
      anchorEl: null,
      arrowRef: null,
      active: false,
    };
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: !this.state.open,
    });
  };

  handleArrowRef = (node) => {
    this.setState({
      arrowRef: node,
    });
  };

  handleClickAway = () => {
    this.setState({
      open: false,
    });
  };

  handleInputChange = (evt) => {
    if (evt.target.value < 101 && evt.target.value >= 0) {
      this.setState({
        input: evt.target.value,
        pct: calculatePct(evt.target.value, this.state.radius),
      });
    }
  };

  openLink = (url) => {
    console.log(url);
    window.open(url, "_blank")?.focus();
  };

  render() {
    const newWindow = this.props.newWindow ? true : false;
    const canBeOpen = this.state.open && Boolean(this.state.anchorEl);
    const id = canBeOpen ? "transition-popper" : undefined;
    const circumference = Math.PI * (this.state.radius * 2);
    const { title, image, stars, percent, slug } = this.props;
    const color = this.percentage === 100 ? "forestgreen" : "#47307f";
    // palevioletred, indianred,

    const constructUrl = `${window.location.protocol}//${window.location.host}/quest/${this.props.questName}/l/${slug}`;
    return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <Paper
          onClick={this.handleClick}
          onMouseOver={() => this.setState({ active: true })}
          onMouseOut={() => this.setState({ active: false })}
          elevation={this.state.active ? 14 : 4}
          style={{
            textAlign: "center",
            margin: "15px",
            cursor: "pointer",
            borderRadius: "10px",
            padding: "15px",
            background: "floralwhite",
            cursor: "pointer",
          }}
        >
          <div id="cont" data-pct={this.state.input}>
            <svg
              id="svg"
              width="210"
              height="210"
              viewport="0 0 100 100"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <image
                x="40"
                y="40"
                width="120"
                height="120"
                href={`/images/topics/${image}.png`}
                style={{
                  transition: "1s",
                  opacity: this.state.active ? 0.8 : 1,
                  transform: this.state.active
                    ? "rotateZ(10deg) rotateY(10deg)"
                    : "rotateZ(0deg) rotateY(0deg)",
                }}
              />

              <circle
                r={this.state.radius}
                cx="100"
                cy="100"
                fill="transparent"
                strokeDasharray={circumference}
                strokeDashoffset="0"
              ></circle>
              <circle
                id="bar"
                ref={this.svgRef}
                r={this.state.radius}
                cx="100"
                cy="100"
                fill="transparent"
                strokeDasharray={circumference}
                strokeDashoffset={this.state.pct}
                style={{
                  stroke: color,
                }}
              ></circle>
              <image
                x="145"
                y="70"
                width="60"
                height="60"
                href={`/images/star${stars}.svg`}
              />
            </svg>
          </div>
          <div
            style={{
              fontFamily: "Nunito",
              fontWeight: "800",
              fontSize: "1.2rem",
              maxWidth: "200px",
            }}
          >
            {title}
          </div>
          <Popper
            id={id}
            open={this.state.open}
            anchorEl={this.state.anchorEl}
            modifiers={[
              {
                name: "arrow",
                enabled: true,
                options: {
                  element: this.state.arrowRef,
                },
              },
              {
                name: "offset",
                options: {
                  offset: [0, 16],
                },
              },
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
            ]}
            transition
          >
            {({ TransitionProps }) => {
              return (
                <Zoom {...TransitionProps} unmountOnExit timeout={450}>
                  <Box
                    sx={{
                      border: 0,
                      p: 1,
                      bgcolor: color,
                      color: "white",
                      fontFamily: "Nunito",
                      fontWeight: "800",
                      borderRadius: "10px",
                      fontSize: "0.8rem",
                      width: "22rem",
                      height: "auto",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      transformOrigin: "10rem 0 0",
                    }}
                  >
                    <div style={{ marginBottom: "20px" }}>
                      <GenerateStars stars={stars} />
                    </div>
                    <Box
                      id="arrow"
                      ref={this.handleArrowRef}
                      data-popper-arrow
                      sx={{ background: color }}
                    ></Box>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        className="LessonBtn"
                        sx={{ fontSize: "0.8rem", margin: "10px" }}
                        variant="outlined"
                        startIcon={<span>🤔</span>}
                        onClick={() => {
                          newWindow
                            ? this.openLink(`${constructUrl}/quiz`)
                            : this.props.navigate(`l/${slug}/quiz`);
                        }}
                      >
                        Quiz
                      </Button>
                      <Button
                        className="LessonBtn"
                        sx={{
                          fontSize: "0.8rem",
                          margin: "10px",
                          paddingLeft: "15px",
                          paddingRight: "10px",
                        }}
                        variant="contained"
                        endIcon={<span>🚀</span>}
                        onClick={() => {
                          newWindow
                            ? this.openLink(constructUrl)
                            : this.props.navigate(`l/${slug}`);
                        }}
                      >
                        <span style={{ whiteSpace: "nowrap" }}>
                          {this.percentage === 0
                            ? "Start Lesson"
                            : this.percentage === 100
                            ? "Review Lesson"
                            : "Continue Lesson"}
                        </span>
                      </Button>
                    </div>
                  </Box>
                </Zoom>
              );
            }}
          </Popper>
        </Paper>
      </ClickAwayListener>
    );
  }
}

export default withRouter(CircularCard);

export const GenerateStars = ({ stars }) => {
  const noStars = 5 - stars;

  return (
    <svg
      id="svg"
      width="275"
      height="100"
      viewport="0 0 100 100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      {Array.from({ length: stars }, (_, i) => i + 1).map((star) => (
        <image
          key={nanoid(star)}
          x={5 + (star - 1) * 55}
          y="50"
          width="50"
          height="50"
          href={`/images/star.svg`}
        />
      ))}
      {Array.from({ length: noStars }, (_, i) => i + 1 + parseInt(stars)).map(
        (star) => (
          <image
            key={nanoid(star)}
            x={5 + (star - 1) * 55}
            y="50"
            width="50"
            height="50"
            style={{ opacity: "0.3" }}
            href={`/images/blankstars.svg`}
          />
        )
      )}
    </svg>
  );
};
