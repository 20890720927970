import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Decentralised Time-Stamping Server",
    "Decentralised Public Ledger",
    "Building an Immutable Database",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which of the following are some of the valid use-cases
          of using a <b>blockchain</b>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
