import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";
import Code from "./Code.mdx";

const MCQ = (props) => {
  const options = ["1", "2", "0", "4"];
  const correctOption = "2";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, we have the following <code>access_key</code> from a{" "}
          <code>testnet</code> wallet.
          <div style={{ textAlign: "left", fontSize: "smaller" }}>
            <Code />
          </div>
          Can you figure out how many times this <code>access_key</code> was
          used?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
