import React from "react";
import { Paper } from "@mui/material";

export const Thought1 = ({ block = false }) => (
  <React.Fragment>
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        background: "floralwhite",
        padding: "20px",
        color: "indigo",
        lineHeight: "2.5rem",
        fontWeight: "bolder",
        fontSize: block ? "smaller" : "inherit",
      }}
    >
      {block ? "" : <h2 style={{ textAlign: "center" }}>Food for Thought</h2>}
      <li>Why should a hash value start with two zeroes?</li>
      <li>Why zeroes? Why not ones or twos?</li>
      <li>Why only two zeroes? Why not three or four?</li>
    </Paper>
  </React.Fragment>
);
