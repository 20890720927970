import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";
import CodeComponent from "./CodeComponent.mdx";
import CodeComponent1 from "./CodeComponent1.mdx";

const MCQ = (props) => {
  const options = ["15", "1500", "30", "1515"];
  const correctOption = "1515";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, take a look at the following smart contract. <br />
            <div style={{ textAlign: "left" }}>
              <CodeComponent />
            </div>
            <br />
            <div style={{ fontSize: "1.3rem" }}>
              We have <b>deployed </b>the above smart contract onto an account,{" "}
              <b>initialized</b> it and called the <code>increase</code>{" "}
              <b>change method</b> thrice. All the transactions were executed
              successfully without errors.
            </div>
            <br />
            What's the final value of <code>count</code> in the{" "}
            <b>contract state</b>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
