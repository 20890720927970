import React from "react";
import MatchQuiz from "../../../../../components/Quiz/MatchQuiz";

const MatchTemplate = (props) => {
  const matchOptions = {
    difficulty: "measures how difficult it is to find a valid nonce",
    nonce: "changed to find an appropriate hash value",
    pattern: "Defines the rule of a valid block",
    "block number": "helps identify the block",
    SHA256: "Hash function",
    hexadecimal: "Numbering system with 16 base-digits",
  };
  return (
    <MatchQuiz
      header={
        <React.Fragment>
          {props.name}, can you match the following?
        </React.Fragment>
      }
      matchOptions={matchOptions}
      {...props}
    />
  );
};

export default MatchTemplate;
