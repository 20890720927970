import React, { useContext } from "react";

import { AppContext } from "../../../../AppContext";
import { getInitialState } from "./components/TerminalTexts";

const TerminalContext = React.createContext();

const TerminalWrapper = ({ component, ...props }) => {
  const context = useContext(AppContext);
  const intitalState = getInitialState(context);
  let Component = component;
  return (
    <TerminalContext.Provider value={getInitialState}>
      <Component state={intitalState} {...props} />
    </TerminalContext.Provider>
  );
};

export default TerminalWrapper;
