import React, { useContext } from "react";
import CodeOptions from "./CodeOption";
import { AppContext } from "../../App";

const CodeBlockOptions = (props) => {
  const blanksContext = useContext(AppContext);

  return (
    <div
      style={{
        padding: "20px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        fontWeight: "bold",
        margin: "0 auto 5px auto",
        background: "floralwhite",
        border: "3px solid  #47307f",
        borderRadius: "5px",
        color: "white",
        fontFamily: "Consolas, Monaco, 'Andale Mono', monospace",
      }}
    >
      {blanksContext.codeOptions.map((name, idx) => (
        <CodeOptions
          blankSelected={blanksContext.blankSelected}
          blanks={blanksContext.blanks}
          setBlank={blanksContext.handleBlanks}
          key={`${idx}-${name}`}
          text={name}
        />
      ))}
    </div>
  );
};

export default CodeBlockOptions;
