import { remapQuiz } from "../../utils";

// BLOCKCHAIN IS AN IMMUTABLE DATABASE

import introduction from "./Database/Introduction";
import aBlock from "./Database/A_Block";
import chainOfBlockchain from "./Database/Blockchain";
import trustlessTimestamps from "./Database/TrustlessTimestamps";

// LEDGER
import ledger from "./Ledger/Ledger";
import distributedLedger from "./Ledger/DistributedLedger";
import keysSignature from "./Ledger/KeysSignature";

// CONENSUS ALGORITHMS

import pow from "./Consensus/POW";
import pos from "./Consensus/POS";

export const primerComponents = {
  introduction: introduction.primerComponents,
  block: aBlock.primerComponents,
  blockchain: chainOfBlockchain.primerComponents,
  "trustless-timestamps": trustlessTimestamps.primerComponents,
  ledger: ledger.primerComponents,
  "decentralised-ledger": distributedLedger.primerComponents,
  "keys-signature": keysSignature.primerComponents,
  pow: pow.primerComponents,
  pos: pos.primerComponents,
};

export const quizList = {
  introduction: remapQuiz(introduction.quizList),
  block: remapQuiz(aBlock.quizList),
  blockchain: remapQuiz(chainOfBlockchain.quizList),
  "trustless-timestamps": remapQuiz(trustlessTimestamps.quizList),
  // "merkle-trees": remapQuiz(merkle.quizList),
  ledger: remapQuiz(ledger.quizList),
  "decentralised-ledger": remapQuiz(distributedLedger.quizList),
  "keys-signature": remapQuiz(keysSignature.quizList),
  pow: remapQuiz(pow.quizList),
  pos: remapQuiz(pos.quizList),
};

export const imageComponents = {
  introduction: introduction.imageComponents,
  block: aBlock.imageComponents,
  blockchain: chainOfBlockchain.imageComponents,
  "trustless-timestamps": trustlessTimestamps.imageComponents,
  // "merkle-trees": merkle.imageComponents,
  ledger: ledger.imageComponents,
  "decentralised-ledger": distributedLedger.imageComponents,
  "keys-signature": keysSignature.imageComponents,
  pow: pow.imageComponents,
  pos: pos.imageComponents,
};

const main = {
  primerComponents,
  imageComponents,
  quizList,
};

export default main;

export const blockchain = [
  {
    group: 1,
    key: "database",
    name: "Blockchain is an Immutable Database",
    topicOrder: [[1], [2, 3, 4]],
    background: "wheat",
    topics: [
      {
        id: 1,
        title: "Introduction",
        stars: "0",
        image: "blockchain",
        slug: "introduction",
        primers: introduction.primers,
        progress: 0,
        wrong: 0,
      },
      {
        id: 2,
        title: "Block",
        stars: "0",
        image: "blockchain",
        slug: "block",
        primers: aBlock.primers,
        progress: 0,
        wrong: 0,
      },
      {
        id: 3,
        title: "Chain of Blocks",
        stars: "0",
        image: "blockchain",
        percent: 50,
        progress: 0,
        wrong: 0,
        slug: "blockchain",
        primers: chainOfBlockchain.primers,
      },
      {
        id: 4,
        title: "Trustless Timestamps",
        color: "#47307f",
        stars: "0",
        image: "blockchain",
        progress: 0,
        wrong: 0,
        slug: "trustless-timestamps",
        primers: trustlessTimestamps.primers,
      },
    ],
  },
  {
    group: 2,
    key: "ledger",
    name: "Blockchain is a Ledger",
    topicOrder: [[1, 2, 3]],
    background: "lightcoral",
    topics: [
      {
        id: 1,
        title: "Ledger",
        stars: "0",
        image: "blockchain",
        progress: 0,
        wrong: 0,
        slug: "ledger",
        primers: ledger.primers,
      },
      {
        id: 2,
        title: "Decentralised Ledger",
        stars: "0",
        image: "blockchain",
        progress: 0,
        wrong: 0,
        slug: "decentralised-ledger",
        primers: distributedLedger.primers,
      },
      {
        id: 3,
        title: "Keys and Signature",
        stars: "0",
        image: "blockchain",
        progress: 0,
        wrong: 0,
        slug: "keys-signature",
        primers: keysSignature.primers,
      },
    ],
  },
  {
    group: 3,
    key: "consensus",
    name: "Consensus Algorithms",
    topicOrder: [[1, 2]],
    background: "lightpink",
    topics: [
      {
        id: 1,
        title: "Proof of Work",
        stars: "0",
        image: "blockchain",
        progress: 0,
        wrong: 0,
        slug: "pow",
        primers: pow.primers,
      },
      {
        id: 2,
        title: "Proof of Stake",
        stars: "0",
        image: "blockchain",
        progress: 0,
        wrong: 0,
        slug: "pos",
        primers: pos.primers,
      },
    ],
  },
  // {
  //   group: 4,
  //   key: "computer",
  //   name: "Blockchain is a Computer",
  //   topicOrder: [[1], [2, 3]],
  //   background: "darkslateblue",
  //   topics: [
  //     {
  //       id: 1,
  //       title: "World Computer",
  //       color: "#47307f",
  //       stars: "1",
  //       image: "blockchain",
  //       percent: 0,
  //       slug: "ledger",
  //     },
  //     {
  //       id: 2,
  //       title: "Self Executable Programs",
  //       color: "#47307f",
  //       stars: "4",
  //       image: "blockchain",
  //       percent: 10,
  //       slug: "keys-signature",
  //     },
  //     {
  //       id: 3,
  //       title: "Smart Contracts",
  //       color: "#47307f",
  //       stars: "5",
  //       image: "blockchain",
  //       percent: 10,
  //       slug: "distributed-ledger",
  //     },
  //   ],
  // },
  // {
  //   group: 5,
  //   key: "sidechains",
  //   name: "Forks & Sidechains",
  //   topicOrder: [[1, 2], [3]],
  //   background: "tomato",
  //   topics: [
  //     {
  //       id: 1,
  //       title: "Forks",
  //       color: "#47307f",
  //       stars: "1",
  //       image: "blockchain",
  //       percent: 0,
  //       slug: "ledger",
  //     },
  //     {
  //       id: 2,
  //       title: "Hard Fork & Soft Fork",
  //       color: "#47307f",
  //       stars: "4",
  //       image: "blockchain",
  //       percent: 10,
  //       slug: "keys-signature",
  //     },
  //     {
  //       id: 3,
  //       title: "Side Chains",
  //       color: "#47307f",
  //       stars: "5",
  //       image: "blockchain",
  //       percent: 10,
  //       slug: "distributed-ledger",
  //     },
  //   ],
  // },
  // {
  //   group: 6,
  //   key: "bonus",
  //   name: "Advanced Topics",
  //   topicOrder: [[1, 2, 3]],
  //   background: "lightpink",
  //   topics: [
  //     {
  //       id: 1,
  //       title: "Cryptographic Hashes",
  //       color: "#47307f",
  //       stars: "0",
  //       image: "blockchain",
  //       percent: 0,
  //       slug: "hashes",
  //       progress: 0,
  //       wrong: 0,
  //     },
  //     {
  //       id: 2,
  //       title: "Merkle Trees",
  //       stars: "0",
  //       image: "blockchain",
  //       percent: 10,
  //       slug: "merkle-trees",
  //       primers: merkle.primers,
  //       progress: 0,
  //       wrong: 0,
  //     },
  //     {
  //       id: 3,
  //       title: "Merkle Patricia Trie",
  //       stars: "0",
  //       image: "blockchain",
  //       percent: 10,
  //       slug: "merkle-trees",
  //       progress: 0,
  //       wrong: 0,
  //     },
  //   ],
  // },
  // {
  //   group: 7,
  //   key: "example",
  //   name: "Example",
  //   topicOrder: [[1]],
  //   background: "aquamarine",
  //   topics: [
  //     {
  //       id: 1,
  //       title: "Example",
  //       stars: "0",
  //       image: "blockchain",
  //       slug: "example",
  //       primers: examplePrimers,
  //       progress: 0,
  //       wrong: 0,
  //     },
  //   ],
  // },
];
