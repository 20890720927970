import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Creating bitcoin address requires giving identification proof",
    "Miners can tamper the transaction amount in a transaction request",
    "Public Key can be derived from the Bitcoin Address",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which of the following statement is <b>false</b>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
