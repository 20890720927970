import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "It is distributed to the validators",
    "It is taken by NEAR Foundation",
    "Most of the fees is burnt",
    "It is distributed to the holders of NEAR token",
  ];
  const correctOption = "Most of the fees is burnt";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, what do you think happens to the{" "}
            <b>transaction fees</b> charged to the users?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
