import React, {
  useState,
  useEffect,
  Fragment,
  createElement,
  useContext,
} from "react";
import { unified } from "unified";
import rehypeReact from "rehype-react";
import remarkRehype from "remark-rehype";
import remarkParse from "remark-parse";
import remarkFront from "remark-frontmatter";
import remarkFrontParse from "remark-parse-frontmatter";
import rehypePrism from "../rehypePrism";
import CustomCode from "./CustomCode";
import BlanksContainer from "./BlanksContainer";
import { createOptions } from "../../utils";

export default function useProcessor(text, handleContext) {
  const [Content, setContent] = useState(Fragment);

  useEffect(() => {
    unified()
      .use(remarkParse) // Parse Markdown to AST
      .use(remarkFront)
      .use(remarkFrontParse)
      .use(remarkRehype, { allowDangerousHtml: true }) // Parse Markdown to Rehype
      .use(rehypePrism, { showLineNumbers: true }) // Syntax Highlight the code block
      .use(rehypeReact, {
        createElement,
        Fragment,
        passNode: true,
        components: {
          p: (props) => <p className="RenderedMarkdown">{props.children}</p>,
          a: (props) => {
            // console.log("Fill Blank", props.children[0]);
            return <FillBlank>{props.children}</FillBlank>;
          },
          code: (props) => (
            <CustomCode handleContext={handleContext}>
              {props.children}
            </CustomCode>
          ),
          img: (props) => {
            return <img src={props.src} className="MdxImage" />;
          },
        },
      }) // Convert the HTML into React Node
      .process(text)
      .then((file) => {
        if (file && file.data && file.data.frontmatter) {
          // console.log(file.data.frontmatter)
          if (file.data.frontmatter.options) {
            handleContext(
              "blanks",
              createOptions(file.data.frontmatter.options)
            );
            handleContext("codeOptions", file.data.frontmatter.options);
          }
          if (file.data.frontmatter.info) {
            handleContext("info", file.data.frontmatter.info);
          }
          if (file.data.frontmatter.optionDetails) {
            handleContext("optionDetails", file.data.frontmatter.optionDetails);
          }
          if (file.data.frontmatter.correctOptions) {
            handleContext(
              "correctOptions",
              file.data.frontmatter.correctOptions
            );
            handleContext(
              "blankSelected",
              file.data.frontmatter.correctOptions[0]
            );
          }
        }

        setContent(file.result);
      });
  }, [text, handleContext]);
  return Content;
}

function FillBlank(props) {
  return <BlanksContainer {...props} />;
}
