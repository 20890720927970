export const start = [
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        '{{user}}, let\'s talk a bit about the concept of "money"',
        "What do you think is the primary purpose of money?",
      ],
      primerAvatar: "dollar",

      textReply: {
        que: "What do you think is the primary purpose of money?",
      },
      userReply: { reply: null, userAvatar: "Smirk" },
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        'The main purpose of money is to serve as a "medium of exchange" to facilitate transactions. ',
        'If you want to get something from another person, you can "buy" it by providing it\'s appropriate value in terms of money.',
        "{{user}}, how do you think humans used to function before the invention of money?",
      ],
      primerAvatar: "question",
      textReply: {
        que: "How did humans function before the invention of money?",
      },
      userReply: { reply: null, userAvatar: "Cute" },
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        'Without money as the medium of exchange, humans had to resort to direct exchange of goods also called "bartering".',
        "However, bartering turned out to be an inefficient way to facilitate transactions.",
        "{{user}}, can you think of any reasons as why bartering is inefficient?",
      ],
      primerAvatar: "puzzle",
      textReply: {
        que: "How did humans function before the invention of money?",
      },
      userReply: { reply: null, userAvatar: "Happy" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Bartering relies upon someone having something of equal value to offer in exchange for a coveted good. ",
        'In other words, the transaction can only happen if there is a "double coincidence of wants" between two parties.',
        "Let's understand what this means",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Bartering",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "Now, money as we know as of now, with currency wasn't developed untill fairly recently.",
        'Another medium of exchange used to faciliitate transactions in the absence of the traditional monetary system was using a "ledger"',
        "{{user}}, do you want to take a guess what a ledger is?",
      ],
      primerAvatar: "notepad",
      textReply: {
        que: "What is a ledger?",
      },
      userReply: { reply: null, userAvatar: "Sit-down" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "A ledger is book containing record of transactions.",
        "Let's understand how a ledger can help facilitate transactions.",
      ],
      primerAvatar: "patient",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Ledger",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "As you can see a centralised ledger are useful for storing transaction related money. ",
        "{{user}}, can you think of some other use cases of using ledgers as storing money?",
      ],
      primerAvatar: "question",
      textReply: {
        que: "Other use-cases of ledgers other than recording transactions?",
      },
      userReply: { reply: null, userAvatar: "Boring" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Ledgers record transactions between different parties.",
        "But they go way beyond the profession of accounting. Ledgers are everywhere, even though we might not refer to them as ledgers.",
        "Let's take a look at other use cases of a ledger.",
      ],
      primerAvatar: "showcase",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "TrustedLedger",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "LedgerMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "As you can see, ledgers are quite ubiquitous in their functionality.",
        'But for now, we will keep ourselves to the part related to the "financial aspect of ledgers" which is a type of ledger used for monetary transactions purpose"',
        "Let's start with how ledgers were used initially. ",
      ],
      primerAvatar: "patient",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "SingleEntryLedger",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "SingleEntryMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "In single-entry accounting, the bookkeeper had to read the description of each entry to decide whether to deduct or add the amount when calculating something as simple as monthly profit or loss. ",
        "This was a time-consuming and inefficient method to tally.",
        'The disadvantages of single-entry accounting was overcome by the introduction of "double-entry bookkeeping", which will take a look at next.',
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DoubleEntryLedger",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "DoubleLedgerMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Even though there were numerous advantages of a double-entry system based ledger ...",
        "... it is still difficult to assess the authenticity of a ledger without a trusted third party",
        "And this gave an opportunity for another type of institution to rise.... Banks",
      ],
      primerAvatar: "businessman",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Banks",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "BanksMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "As you can see, banks acting as a trusted third-party being a centralised ledger helped make tremendous improvements in the human history.",
        "Can you think of any disadvantage being the centralised ledger?",
      ],
      primerAvatar: "puzzle",
      textReply: {
        que: "Any disadvantage of banks being the trusted third party?",
      },
      userReply: { reply: null, userAvatar: "Cheeks" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'The problem with centralisation is that it often leads to creation of an organisation that functions "opaquely".',
        'And the problem with a trusted third-party is that you have to place "trust" in it. ',
        'There is no way to verify; hence "trust" is required',
        "And often, this trust is broken resulting in severe loss.",
      ],
      primerAvatar: "lost",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "FractionalReserveBanking",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "{{user}}, I understand this might be a bit overwhelming to comprehend all this new information till now.",
        "Having said that, what do you think about fractional reserve-banking?",
        "Should such system continue to exist?",
      ],
      primerAvatar: "dollar",
      textReply: {
        que: "What do you think about fractional reserve-banking?",
      },
      userReply: { reply: null, userAvatar: "Cheeks" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Truth be told, it doesn't really matter about what you think.",
        "The policies that affect billions of people are set by a very tiny minority in government and top banking officials.",
        "While, common person bears the brunt of such policies without having to do anything with it.",
      ],
      primerAvatar: "sorry",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Decentral",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "We have come to the end of this section",
        "In the next section, we will take a look at how decentralised ledger can be created and maintained without any trusted third-party.",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "wave",
    },
  },
];

/*
  1. Medici Banking created **centralised ledgers**
  2. Fractional Reserve Banking 
  3. Decentralised Ledgers
*/
