import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AntTabs, AntTab } from "./Tabs";
{
  /* <span style={{ fontSize: "3rem" }}>{"\u201C"}</span>
 <span style={{ fontSize: "3rem" }}>{"\u201D"}</span> */
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography sx={{ color: "#47307f", display: "flex" }}>
            <span style={{ fontSize: "80px", margin: "0px", padding: "0px" }}>
              {"\u201C"}
            </span>
            <blockquote style={{ paddingTop: "10px", fontSize: "small" }}>
              {children}
            </blockquote>
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabPanelStyles = {
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  color: "#47307f",
};

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        border: "none",
        maxHeight: 424,
      }}
    >
      <AntTabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderBottom: "none",
          borderRight: 1,
          borderColor: "divider",
          minWidth: "250px",
        }}
      >
        <AntTab label="Settlement" {...a11yProps(0)} />
        <AntTab label="Maintenance" {...a11yProps(1)} />
        <AntTab label="Decentralised" {...a11yProps(2)} />
        <AntTab label="Account Names" {...a11yProps(3)} />
        <AntTab label="Account Access" {...a11yProps(4)} />
        <AntTab label="Consensus" {...a11yProps(5)} />
      </AntTabs>
      <TabPanel style={tabPanelStyles} value={value} index={0}>
        In the smaller version of the <b>decentralised ledger</b>, participating
        people settled their dues by giving each other cash amount at the end of
        the month. <br />
        <br />
        In the <b>"Global Decentralised Ledger"</b>, how can we{" "}
        <b>verify if a person has actually settled</b> according to the
        transaction recorded in the ledger?
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={1}>
        Ideally, we would like to develop a system to allow anyone to be able to{" "}
        <b>record transactions</b> and, <b>maintain the ledger</b>. At the same
        time, being a global system, the <b>decentralised ledger</b> should not
        face <b>downtime or outage</b>.
        <br />
        <br />
        How to <b>incentivise the participants</b> maintaining infrastructure
        running the decentralised ledger to <b>function honestly?</b>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={2}>
        One of the dangers of centralisation payment infrastructure is that it
        can be easily closed down due to government intervention or other
        security hazards. To ensure that the global decentralised ledger
        continues to perform, it needs to be decentralised by participants all
        over the world.
        <br />
        <br />
        How can we design the system to be <b>distributed</b> and{" "}
        <b>decentralised</b>, so that the transactions can not be interrupted by
        malicious actors or any government?
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={3}>
        In our small-scale decentralised friends ledger, the names of the
        persons were the names of the participants. When we scale the system to
        global scale, we need to take into consideration that everyone should be
        able to create their own <b>unique account names</b>.
        <br />
        <br />
        How to develop an <b>account creation mechanism</b> which can support
        the needs of <b>7 billion people</b> while also well-architectured
        enough to support the needs of <b>future generation</b>?
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={4}>
        When a bank acts as <b>a centralised ledger</b>, it can manage accounts
        of their customers on their own whims. For instance, they can{" "}
        <b>remove funds automatically</b>, or <b>freeze assets</b> and{" "}
        <b>prohibit withdrawal due to various reasons</b>.
        <br />
        <br />
        How do we ensure that the only person who can use the funds in the
        accounts is <b>the account holder</b> and{" "}
        <b>no other entity can edit the account</b>, not even the people running
        the infrastructure?
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={5}>
        The transactions on the ledger needs of happen in a <b>distributed</b>{" "}
        and <b>decentralised</b> maintained by <b>anonymous actors</b>. This
        means that any transaction on the blockchain needs to broadcasted to the
        all the participants, who will update their corresponding blockchain.
        Sometimes, due to the many technical reasons, some participants will
        have a completely different set of ledger from another set of
        participants.
        <br />
        <br />
        How do we ensure that the{" "}
        <b>
          correct copy of ledger is unanimously agreed upon by the all the
          actors
        </b>
        ?
      </TabPanel>
    </Box>
  );
}
