import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["180 TGas", "150 TGas", "190 TGas", "210 TGas"];
  const correctOption = "210 TGas";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      code={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, we have a <b>method</b> on a <b>smart contract</b>{" "}
            that doesn't change <b>account state</b>. However, it requires
            considerable amount of <code>TGas</code> to successfully execute.
            <br />
            Which of the following <code>gas</code> requirement will result in
            failure if called as <code>view</code> method?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
