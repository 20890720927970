import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Citizenship Register",
    "Marriage Register",
    "Property Register",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which of the following is document is a{" "}
          <b>type of ledger</b>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
