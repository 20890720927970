import React from "react";
import { sha256 } from "js-sha256";
import { HashTextField, CssTextField } from "./styledComponents";
import { Paper } from "@mui/material";

const Hash = () => {
  const [data1, setData1] = React.useState(
    "Hi There! Change this text to see the hash value below change."
  );
  const [data2, setData2] = React.useState(
    "Hi There! Change this text to see the hash value below change."
  );

  const [same, setSame] = React.useState(false);

  React.useEffect(() => {
    if (sha256(data1) === sha256(data2)) {
      setSame(true);
    } else {
      if (same) {
        setSame(false);
      }
    }
  }, [data1, data2]);

  return (
    <React.Fragment>
      <div
        style={{
          margin: "30px auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            margin: "10px",
            background: same ? "lightgreen" : "salmon",
          }}
        >
          <CssTextField
            label="Data 1"
            multiline
            rows={4}
            value={data1}
            onChange={(evt) => setData1(evt.target.value)}
          />
          <br />
          <HashTextField label="Hash Value 1" value={sha256(data1)} />
        </Paper>
        <Paper
          elevation={3}
          sx={{
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            margin: "10px",
            background: same ? "lightgreen" : "salmon",
          }}
        >
          <CssTextField
            label="Data 2"
            multiline
            rows={4}
            value={data2}
            onChange={(evt) => setData2(evt.target.value)}
          />
          <br />
          <HashTextField label="Hash Value 2" value={sha256(data2)} />
        </Paper>
      </div>
      <h1
        style={{
          textAlign: "center",
          color: same ? "indigo" : "lightcoral",
        }}
      >
        {same
          ? "Data 1 and Data 2 are same"
          : "Data 1 and Data 2 are different"}
      </h1>
    </React.Fragment>
  );
};

export default Hash;
