import React from "react";
import MatchQuiz from "../../../../../components/Quiz/MatchQuiz";

const MatchTemplate = (props) => {
  const matchOptions = {
    "view-state": "Returns contract state",
    call: "contract call that can invoke change or view methods",
    view: "contract call that can only invoke a view method ",
    deploy: "deploys smart contract to NEAR Blockchain",
    "dev-deploy": "creates a dev account and deploys contract",
  };
  return (
    <MatchQuiz
      large={true}
      header={
        <React.Fragment>
          {props.name}, can you match the following <b>commands</b> and their{" "}
          <b>usage</b> on <b>NEAR CLI</b>?
        </React.Fragment>
      }
      matchOptions={matchOptions}
      {...props}
    />
  );
};

export default MatchTemplate;
