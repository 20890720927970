import { blockPrimers } from "./Block";

const pizzaPrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      primerAvatar: "cook",
      chats: [
        "I guess you like Pizza a little too much, {{user}}",
        "But no {{user}}, a blockchain is a not a fast-food chain restaurant",
        "However, there is an interesting tidbit related to blockchain and pizza.",
      ],
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "PizzaInfo",
    },
  },
  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      chats: [
        "Now enough about Pizzas",
        "Let's continue our earlier discussion about blockchains for now.",
        "{{user}}, what do the blockchains contain?",
      ],
      buttonOptions: [],
      userReply: { reply: null, userAvatar: null },
      primerAvatar: "puzzle",
    },
  },
];

const moneyPrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      primerAvatar: "dollar",
      chats: [
        "Well, {{user}}, you are not wrong actually.",
        "Some blockchains do hold 'money' or rather the information about it.",
        "But this paints an incorrect picture about blockchains.",
      ],
      userReply: {
        reply: null,
        userAvatar: null,
      },
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "MoneyInfo",
    },
  },

  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      primerAvatar: "patient",
      chats: [
        "Now once again, let's come back to the question.",
        "{{user}}, what do you think the blockchain hold?",
      ],
      userReply: {
        reply: null,
        userAvatar: null,
      },
      buttonOptions: [],
    },
  },
];

const buttonOptions = [
  {
    text: "A Pizza",
    userReply: {
      reply: [
        "Pizzzza !",
        "I love Pizza",
        "Maybe that's why I would love see a blockchain containing Pizzas",
      ],
      userAvatar: "Hungry",
    },
    primers: pizzaPrimers,
  },
  {
    text: "Money",
    userReply: {
      reply: ["Ummm", "Blockchain holds money?"],
      userAvatar: "Smirk",
    },
    primers: moneyPrimers,
  },
  {
    text: "Data",
    userReply: {
      reply: ["Blockchains hold digital data", "....and information"],
      userAvatar: "Cheeks",
    },
    primers: blockPrimers,
  },
];

export const userDefinesBlockchainPrimers = [
  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      chats: [
        "Well, let's start off saying that a blockchain is simply a chain of 'special blocks'",
        "Now the question is what do you think these 'special blocks' contain?",
      ],
      buttonOptions: buttonOptions,
      userReply: { reply: null, userAvatar: null },
      primerAvatar: "book",
    },
  },
];
