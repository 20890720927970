import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Address Collision",
    "Theft of funds",
    "Losing/Forgetting Private key",
  ];
  const correctOption = "Losing/Forgetting Private key";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, what's the biggest <b>security concern</b> of using{" "}
          <b>Bitcoin Decentralised Ledger </b> ?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
