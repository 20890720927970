export const LEVEL_0 = () => (
  <div>
    You have heard about <b>blockchains</b> in passing but never got around to
    understand deeply. You know it's something to do with <b>money</b> and{" "}
    <b>NFTs</b>. You know at least someone from work, or your family or one of
    friends who has lost money in crypto. Maybe it's all hype and no substance.
    Maybe it has something truly incredible to offer.
    <br />
    <br />
    <b>
      So, you have decided to take a look around to what this is all about and
      decide for yourself
    </b>
  </div>
);

export const LEVEL_1 = () => (
  <div>
    You know about <b>blockchains</b> and have dabbled around various other
    blockchains such as <b>Ethereum</b>. You have heard about{" "}
    <b>NEAR Protocol</b> but have no idea how it's better than it's alternative.
    <br />
    <br />
    <b>
      So, you have decided to jump in and understand how NEAR Protocol works and
      how you can build applications on top of it.
    </b>
  </div>
);

export const LEVEL_2 = () => (
  <div>
    You are already familiar with the world of <b>blockchains</b> and have
    createrd a couple of <b>smart contracts</b>. However, the usability of other
    blockchains have left a bad taste in your mouth. Moreover, you don't want to
    learn a new language just to write <b>smart contracts</b>.
    <br />
    <br />
    <b>
      So, you have decided to take a look at NEAR Protocol to understand how you
      can write smart contracts using a langauge you are already familiar with
      and love: <code>javascript</code>
    </b>
  </div>
);

export const LEVEL_3 = () => (
  <div>
    You don't read documentations. You don't even download a <code>build</code>{" "}
    file. You compile from the source. Seriously, you don't even need the{" "}
    <b>NEAR Protocol Primer</b>. You can always figure things out on your own.
    However, I guarantee, we can help you learn <code>NEAR Protocol</code> in a
    much more faster and interactive way.
    <br />
    <br />
    <b>
      So, you have decided to take our word and check out how to write smart
      contracts on NEAR Protocol.
    </b>
  </div>
);

export const LEVEL_4 = () => (
  <div>
    You are an experienced NEAR Protocol <b>blockchain developer</b> and heard
    about a new course doing round. The <b>open source</b> libraries has been
    always enough for you. But you want to take a peek at the new course to
    check out if its actually useful so that you could refer to it when a newbie
    comes to you for help.
    <br />
    <br />
    <b>
      So, you decided to check it out to see if these guys actually know what
      they are talking about.
    </b>
  </div>
);

export const CourseInformation = ({ level = 0 }) => (
  <div>
    <h3>What we offer</h3>
    <div style={{ display: "flex", flexDirection: "wrap" }}>
      <div
        style={{
          height: "150px",
          margin: "20px",
          width: "250px",
          border: "2px solid indigo",
        }}
      >
        <b>Introduction to Blockchain</b>
      </div>
      <div
        style={{
          height: "150px",
          margin: "20px",
          width: "250px",
          border: "2px solid indigo",
        }}
      >
        <b>Introduction to Blockchain</b>
      </div>
      <div
        style={{
          height: "150px",
          margin: "20px",
          width: "250px",
          border: "2px solid indigo",
        }}
      >
        <b>Introduction to Blockchain</b>
      </div>
      <div
        style={{
          height: "150px",
          margin: "20px",
          width: "250px",
          border: "2px solid indigo",
        }}
      >
        <b>Introduction to Blockchain</b>
      </div>
    </div>
  </div>
);

export const QUEST_1 = () => (
  <div>
    <ul className="doubleUL" style={{ fontWeight: "bold" }}>
      <li>What is Blockchain</li>
      <li>How does a blockchain work?</li>
      <li>Time-stamping Authority</li>
      <li>Decentralised Ledger</li>
      <li>Use of cryptography</li>
      <li>Digital Signatures</li>
      <li>Bitcoin Blockchain</li>
      <li>Distributed Consensus</li>
      <li>Proof of Work</li>
      <li>Proof of Stake</li>
    </ul>
  </div>
);

export const QUEST_2 = () => (
  <div>
    <ul className="doubleUL" style={{ fontWeight: "bold" }}>
      <li>What is NEAR Protocol?</li>
      <li>Decentralised Cloud-computing</li>
      <li>Accounts Model</li>
      <li>Transactions</li>
      <li>Command Line Interface</li>
      <li>Access Keys</li>
      <li>Account State</li>
      <li>Storage Staking</li>
    </ul>
  </div>
);

export const QUEST_3 = () => (
  <div>
    <ul className="doubleUL" style={{ fontWeight: "bold" }}>
      <li>What is a Smart Contract?</li>
      <li>Writing our first smart contract</li>
      <li>
        Overview of <code>near-sdk-js</code>
      </li>
      <li>Book Ratings Smart Contract</li>
      <li>Donations Smart Contract</li>
      <li>Cross Contract Call</li>
    </ul>
  </div>
);

export const QUEST_4 = () => (
  <div>
    <ul className="doubleUL" style={{ fontWeight: "bold" }}>
      <li>Building Front-end for contracts</li>
      <li>Book Ratings DApp</li>
      <li>Donations DApp</li>
      <li>
        Overview of <code>near-api-js</code>
      </li>
    </ul>
  </div>
);
