// Informations
import FriendsLedger from "./PrimerComponents/FriendsLedger.mdx";
import Problems from "./PrimerComponents/Problems.mdx";
import Bitcoin from "./PrimerComponents/Bitcoin.mdx";
import BitcoinDesign from "./PrimerComponents/BitcoinDesign.mdx";
import BitcoinSendInfo from "./PrimerComponents/BitcoinSendInfo.mdx";

// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";
// Quizzes
import LedgerMCQ from "./Quiz/LedgerMCQ";
import GoatMCQ from "./Quiz/GoatMCQ";
import BitcoinMCQ from "./Quiz/BitcoinMCQ";
import BitcoinReorder from "./Quiz/BitcoinReorder";

// Primers
import { start } from "./Primers/Start";
import { restPrimers } from "./Primers/RestPrimers";

const primers = [...start];

const primerComponents = {
  FriendsLedger: FriendsLedger,
  LedgerMCQ: LedgerMCQ,
  Problems: Problems,
  Bitcoin: Bitcoin,
  BitcoinDesign: BitcoinDesign,
  GoatMCQ: GoatMCQ,
  BitcoinMCQ: BitcoinMCQ,
  BitcoinSendInfo: BitcoinSendInfo,
  BitcoinReorder: BitcoinReorder,
  NextLesson: NextLesson,
};

const imageComponents = [];

const quizList = ["LedgerMCQ", "GoatMCQ", "BitcoinMCQ", "BitcoinReorder"];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
