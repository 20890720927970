import React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { Paper, Button } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={600} />;
});

const ConfirmationDialog = ({
  openDialog,
  setOpenDialog,
  context,
  questId,
  lessonId,
  isQuest,
}) => (
  <Dialog
    className="BottomDialog"
    fullWidth={true}
    open={openDialog}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => setOpenDialog(false)}
    aria-describedby="alert-dialog-slide-description"
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "10px",
        maxWidth: "800px",
        width: "100%",
        margin: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "space-around",
        }}
      >
        <h2 style={{ marginBottom: "0px" }}>
          Sure you want to reset topic progress?
        </h2>
        <p style={{ color: "darkgray" }}>
          You will lose all your progress made in this topic so far !
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Button
          sx={{ margin: "10px" }}
          variant="outlined"
          onClick={() => {
            setOpenDialog(false);
          }}
        >
          Stay
        </Button>
        <Button
          sx={{ margin: "10px" }}
          variant="contained"
          onClick={() => {
            setOpenDialog(false);

            context.resetTopicProgress(isQuest, questId, lessonId);
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  </Dialog>
);

export default ConfirmationDialog;
