import CodeBlanks from "../../../../../components/Explorables/Common/CodeBlanks/App";
import StatelessTerminal from "../../../../../components/Explorables/Common/Terminal";
import { Paper } from "@mui/material";

const command =
  'near view greetings.primerlabs.testnet say_hi \'{"name":"Ben"}\'';

export const ViewMethodsEnd = () => (
  <div>
    <Paper
      style={{
        background: "indigo",
        padding: "20px",
        margin: "10px auto",
        color: "floralwhite",
      }}
    >
      <div
        style={{
          margin: "0px 25px",
          lineHeight: "1.5rem",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        Try to call <span style={{ color: "salmon" }}>say_hi</span> view method
        with your <span style={{ color: "salmon" }}>name</span> as argument and
        <span style={{ color: "salmon" }}> without any arguments</span>.
      </div>
    </Paper>
    <StatelessTerminal inputStr={command} />
    <br />
    Time for a quick quiz.
  </div>
);
