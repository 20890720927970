// Infos

import Info1 from "./PrimerComponents/Info1.mdx";
import NonceInfo from "./PrimerComponents/NonceInfo.mdx";
import DataInfo from "./PrimerComponents/DataInfo.mdx";
import AutoValidDataInfo from "./PrimerComponents/AutoValidDataInfo.mdx";
import PrevHashInfo from "./PrimerComponents/PrevHashInfo.mdx";

import CalculusInfo from "./PrimerComponents/CalculusInfo.mdx";
import PriorityInfo from "./PrimerComponents/PriorityInfo.mdx";
import TimeStampingAuthorityInfo from "./PrimerComponents/TimeStampingAuthorityInfo.mdx";
import TSSInfo from "./PrimerComponents/TSSInfo.mdx";
// Quiz

import ValidBlockMCQ from "./Quiz/ValidBlockMCQ";
import TimeStampMCQ from "./Quiz/TimeStampMCQ";
import TSSMCQ from "./Quiz/TSSMCQ";

// Primers
import { start } from "./Primers/Start";

// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";

const primers = [...start];
// const primers = [...timeStampPrimers];

const primerComponents = {
  Info1: Info1,
  NonceInfo: NonceInfo,
  DataInfo: DataInfo,
  AutoValidDataInfo: AutoValidDataInfo,
  PrevHashInfo: PrevHashInfo,
  ValidBlockMCQ: ValidBlockMCQ,
  CalculusInfo: CalculusInfo,
  PriorityInfo: PriorityInfo,
  TimeStampingAuthorityInfo: TimeStampingAuthorityInfo,
  TimeStampMCQ: TimeStampMCQ,
  TSSMCQ: TSSMCQ,
  TSSInfo: TSSInfo,
  NextLesson: NextLesson,
};

const imageComponents = [];

const quizList = ["ValidBlockMCQ", "TimeStampMCQ", "TSSMCQ"];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
