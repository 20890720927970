// Infos

import WhyNearInfo from "./PrimerComponents/WhyNearInfo.mdx";
import ProtocolInfo from "./PrimerComponents/ProtocolInfo.mdx";
import BittorrentInfo from "./PrimerComponents/BittorrentInfo.mdx";
import CloudCommputingInfo from "./PrimerComponents/CloudCommputingInfo.mdx";
import ChallengesInfo from "./PrimerComponents/ChallengesInfo.mdx";
import NearInfo from "./PrimerComponents/NearInfo.mdx";
import BlockchainInfo from "./PrimerComponents/BlockchainInfo.mdx";

// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";

// Quiz
import SMTPMCQ from "./Quiz/SMTPMCQ";
import ProtocolMatchQuiz from "./Quiz/ProtocolMatchQuiz";
import ValidatorsQuiz from "./Quiz/ValidatorsQuiz";
import BlockchainQuiz from "./Quiz/BlockchainQuiz";
import AccountStateQuiz from "./Quiz/AccountStateQuiz";
// Primers
import { start } from "./Primers/Start";

const primers = [...start];

const primerComponents = {
  WhyNearInfo: WhyNearInfo,
  ProtocolInfo: ProtocolInfo,
  BittorrentInfo: BittorrentInfo,
  CloudCommputingInfo: CloudCommputingInfo,
  ChallengesInfo: ChallengesInfo,
  NearInfo: NearInfo,
  BlockchainInfo: BlockchainInfo,
  NextLesson: NextLesson,
  SMTPMCQ: SMTPMCQ,
  ProtocolMatchQuiz: ProtocolMatchQuiz,
  ValidatorsQuiz: ValidatorsQuiz,
  ProtocolMatchQuiz: ProtocolMatchQuiz,
  BlockchainQuiz: BlockchainQuiz,
  AccountStateQuiz: AccountStateQuiz,
};

const imageComponents = [];

const quizList = [
  "SMTPMCQ",
  "ProtocolMatchQuiz",
  "BlockchainQuiz",
  "ValidatorsQuiz",
  "AccountStateQuiz",
];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
