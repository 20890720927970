import React, { Component, useCallback, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (
  isDragging,
  draggableStyle,
  isHover,
  isSelected,
  isCompleted,
  busyKey,
  skip,
  large
) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    color: isCompleted
      ? isDragging
        ? "floralwhite"
        : "black"
      : isSelected
      ? "floralwhite"
      : isDragging
      ? "white"
      : isHover
      ? "#47307f"
      : "gray",
    pointerEvents: skip || isCompleted ? "none" : busyKey ? "none" : "inherit",
    // opacity: isCompleted ? "0.6" : "1",
    margin: "10px",
    height: large ? "2.5rem" : "1.6rem",
    fontSize: "1rem",
    border: "1px solid gray",
    borderRadius: "12px",
    borderBottom: isSelected
      ? "4px solid black"
      : isHover
      ? "4px solid #47307f"
      : "4px solid gray",
    textAlign: "center",
    padding: "10px",
    fontWeight: "bold",
    cursor: "pointer",
    width: "240px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "uppercase",

    // change background colour if dragging
    background: isCompleted
      ? isDragging
        ? "#47307f"
        : "#58cb05"
      : isSelected
      ? "#47307f"
      : isDragging
      ? "#47307f"
      : "transparent",

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

const getListStyle = (isDraggingOver) => ({
  padding: grid,
  display: "flex",
  flexDirection: "column",
});

function delay(fn, time = 300) {
  return new Promise((resolve) => {
    setTimeout(() => {
      fn();
      resolve();
    }, time);
  });
}

function noop() {}

const Reorder = ({
  kee,
  keys,
  setKey,
  setKeys,
  order,
  skip,
  setBusyKey,
  busyKey,
  completed,
  large,
}) => {
  let api;
  const useMyCoolSensor = (value) => {
    api = value;
  };

  const [hovered, setHovered] = React.useState(null);

  React.useEffect(() => {
    if (!skip) {
      if (Object.keys(order).length) {
        const keyIndex = keys.findIndex((x) => x.content === kee);
        const pos = Object.keys(order).length;
        if (pos - keyIndex - 1) {
          start(pos - keyIndex - 1, keyIndex);
        }
        setKey(null);
      }
    }
  }, [order]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    setKeys(reorder(keys, result.source.index, result.destination.index));
  };

  async function start(pos, index) {
    setBusyKey(true);
    const preDrag = api.tryGetLock(keys[index].id, noop);

    if (!preDrag) {
      console.warn("unable to start drag");
      return;
    }

    const actions = preDrag.snapLift();
    const { moveDown, moveUp, drop } = actions;

    for (var i = 0; i < Math.abs(pos); i++) {
      await delay(moveUp);
    }

    await delay(drop);
    setBusyKey(false);
  }

  const orderKeys = Object.keys(order);

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      enableDefaultSensors={false}
      sensors={[useMyCoolSensor]}
    >
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {keys.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    onMouseEnter={() => setHovered(item.id)}
                    onMouseOut={() => setHovered(null)}
                    onClick={() =>
                      kee === item.id ? setKey(null) : setKey(item.content)
                    }
                    className="Match"
                    variant="outlined"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                      hovered === item.id,
                      kee === item.content,
                      orderKeys.includes(item.content) || completed,
                      busyKey,
                      skip,
                      large
                    )}
                  >
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Reorder;
