import { Paper } from "@mui/material";
import ShortPrimer from "../../../../../components/ShortPrimers";

const Text = () => (
  <p>
    We can recap how <span style={{ color: "indigo" }}>smart contracts</span>{" "}
    are executed by the{" "}
    <span style={{ color: "indigo" }}>NEAR Protocol Validators</span> by
    checking out this <span style={{ color: "indigo" }}>short guide</span>
  </p>
);

const Text2 = () => (
  <p>
    In case you are not familiar with how <code>classes</code> work in{" "}
    <code>Javascript</code>, we have written a small guide for the same..
  </p>
);

const Text3 = () => (
  <p>
    If you are not familiar with the concept of{" "}
    <span style={{ color: "indigo" }}>Decorators</span>, you can check out this
    short guide.
  </p>
);

export const ContractPrimer = () => (
  <ShortPrimer text={Text} type="smartContract" />
);

export const ClassPrimer = () => (
  <ShortPrimer text={Text2} type="class" avatar="attention" />
);

export const DecoratorPrimer = () => (
  <ShortPrimer text={Text3} type="decorator" avatar="umbrella" />
);

export const SDKLinks = () => (
  <ul>
    <li>
      <a href="https://github.com/near/near-sdk-js" target="_blank">
        <code>near-sdk-js</code>
      </a>{" "}
      to help write smart contracts in <code>Javascript</code>
    </li>
    <li>
      <a href="https://github.com/near/near-sdk-rs" target="_blank">
        <code>near-sdk-rs</code>
      </a>{" "}
      to help write smart contracts in <code>Rust</code>
    </li>
    <li>
      <a href="https://github.com/near/near-sdk-as" target="_blank">
        <code>near-sdk-as</code>
      </a>{" "}
      to help write smart contracts in <code>Assemblyscript</code>
    </li>
  </ul>
);

export const InstallInfo = () => (
  <Paper
    style={{
      background: "indigo",
      padding: "20px",
      margin: "10px auto",
      color: "floralwhite",
    }}
  >
    <div
      style={{
        margin: "0px 25px",
        lineHeight: "1.5rem",
        textAlign: "center",
        fontWeight: "bold",
      }}
    >
      Most recent method to set up your development environment can be found on
      the{" "}
      <a
        style={{ color: "salmon" }}
        href="https://docs.near.org/develop/quickstart-guide"
        target="_blank"
      >
        official website
      </a>
    </div>
  </Paper>
);
