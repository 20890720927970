import React from "react";
import AccessKeys from "../../../../../components/Explorables/Common/OneOff/AccessKeys/AccessKeys";

import AddKeys from "../../../../../components/Explorables/Common/OneOff/AccessKeys/AddKeys";

import AccessKeysExplorable from "../../../../../components/Explorables/Common/OneOff/AccessKeysExplorable";

import SeedPhrase from "../../../../../components/Explorables/Blockchain/SeedPhrase";

import GenerateKeypairs from "../../../../../components/Explorables/Common/OneOff/AccessExercises/GenerateKeypairs/GenerateKeypairs";

import AddingKeys from "../../../../../components/Explorables/Common/OneOff/AccessExercises/AddingKeys/AddingKeys";

import DeletingKeys from "../../../../../components/Explorables/Common/OneOff/AccessExercises/DeletingKeys/DeletingKeys";

import AccessKeyRotation from "../../../../../components/Explorables/Common/OneOff/AccessExercises/AccessKeyRotation/AccessKeyRotation";

import ImplicitExplorable from "../../../../../components/Explorables/Common/OneOff/ImplicitExplorable";
import Alert from "@mui/material/Alert";

export const ExerciseBox = ({ children }) => (
  <Alert severity="info">{children}</Alert>
);

export {
  AddKeys,
  AccessKeysExplorable,
  SeedPhrase,
  GenerateKeypairs,
  AddingKeys,
  DeletingKeys,
  AccessKeyRotation,
  ImplicitExplorable,
};

export default AccessKeys;
