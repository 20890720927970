import ShortPrimer from "../../../../../components/ShortPrimers";

const Text = () => (
  <p>
    You can get recap{" "}
    <span style={{ color: "indigo" }}>transactions and actions</span> on{" "}
    <span style={{ color: "indigo" }}>NEAR Protocol</span> by checking out this{" "}
    <span style={{ color: "indigo" }}>short guide</span>
  </p>
);

export const TransactionsPrimer = () => (
  <ShortPrimer type="transactions-primer" text={Text} />
);
