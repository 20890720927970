import React from "react";
import { sha256 } from "js-sha256";
import { Paper, Button } from "@mui/material";
import { HashTextField } from "./styledComponents";
import { CenteredLayout } from "../../Quiz/layouts/Layout";
var EC = require("elliptic").ec;

var ec = new EC("secp256k1");

const generateKeyPair = () => {
  var keyPair = ec.genKeyPair();
  let prv = keyPair.getPrivate("hex");
  let pub = keyPair.getPublic("hex");

  return { prv, pub };
};

const KeyPair = () => {
  const [privKey, setPrivKey] = React.useState("");
  const [pubKey, setPubKey] = React.useState("");

  React.useEffect(() => {
    const { prv, pub } = generateKeyPair();
    setPrivKey(prv);
    setPubKey(pub);
  }, []);

  const generate = () => {
    const { prv, pub } = generateKeyPair();
    setPrivKey(prv);
    setPubKey(pub);
  };

  const handleChangePrivateKey = (evt) => {
    evt.preventDefault();
    setPrivKey(evt.target.value);
    let keys = ec.keyFromPrivate(sha256(evt.target.value));
    setPubKey(keys.getPublic("hex"));
  };

  return (
    <CenteredLayout>
      <Paper
        style={{
          maxWidth: "400px",
          margin: "30px auto",
          padding: "30px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HashTextField
          onChange={handleChangePrivateKey}
          label="Private Key"
          value={privKey}
          style={{ margin: "10px" }}
        />
        <HashTextField
          label="Public Key"
          value={pubKey}
          style={{ margin: "10px" }}
        />

        <div style={{ margin: "auto" }}>
          <Button
            onClick={generate}
            variant="outlined"
            sx={{ height: "3rem", fontSize: "1rem", margin: "10px auto" }}
          >
            Generate Key Pair
          </Button>
        </div>
      </Paper>
    </CenteredLayout>
  );
};

export default KeyPair;
