import React from "react";
import { Paper, Box, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CasinoIcon from "@mui/icons-material/Casino";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import anime from "animejs";
import useSound from "use-sound";

const myObject = (num) => ({
  number: num,
});

const SixteenSidedDice = () => {
  const [rolls, setRolls] = React.useState(["9"]);
  const [rolling, setRolling] = React.useState(false);
  const [lastRolled, setLastRolled] = React.useState("9");
  const [playRollDice] = useSound("/sounds/diceRoll.wav", { volume: 0.5 });

  const dice = React.useRef();
  const animation = React.useRef(null);

  const reset = () => {
    setLastRolled("9");
    setRolls(["9"]);
  };

  React.useEffect(() => {
    if (rolling) {
      const timer = setTimeout(() => setRolling(false), 1000);
      playRollDice();
      animation.current = anime.timeline({
        loop: false,
        autoplay: true,
      });

      const randomNumber = Math.floor(Math.random() * 17);
      const finalDiceIndex = (randomNumber + 100) % 16;

      const targetObject = myObject(randomNumber);

      animation.current.add({
        targets: targetObject,
        number: 100 + randomNumber,
        easing: "linear",
        round: 1,
        update: function () {
          const diceIndex = targetObject.number % 16;
          dice.current.innerHTML = hexaDecimals[diceIndex];
        },
        complete: () => {
          setRolls((rolls) => [...rolls, hexaDecimals[finalDiceIndex]]);
          setLastRolled(hexaDecimals[finalDiceIndex]);
        },
      });

      return () => clearTimeout(timer);
    }
  }, [rolling]);

  return (
    <div style={{ padding: "30px" }}>
      <h2
        style={{
          textAlign: "center",
          fontFamily: "Roboto",
          fontSize: "2rem",
          fontWeight: "lighter",
        }}
      >
        Roll a 16-Sided Dice
      </h2>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          ref={dice}
          style={{
            background: rolling ? "lightsalmon" : "aliceblue",
            height: "90px",
            width: "90px",
            color: "#47307f",
            fontWeight: "bold",
            fontSize: "3rem",
            borderRadius: "90px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {lastRolled}
        </div>
        <div style={{ display: "flex" }}>
          <LoadingButton
            onClick={() => setRolling(true)}
            loading={rolling}
            loadingPosition="start"
            startIcon={<CasinoIcon />}
            style={{ height: "3rem", margin: "10px" }}
            variant="outlined"
          >
            {rolling ? "Rolling" : "Roll"}
          </LoadingButton>
          <Button
            disabled={rolling}
            onClick={() => reset()}
            startIcon={<RestartAltIcon />}
            style={{ height: "3rem", margin: "10px" }}
            variant="outlined"
          >
            Reset
          </Button>
        </div>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {rolls.map((roll, idx) => (
          <LastDigit
            last={idx === rolls.length - 1}
            num={roll}
            key={`roll-${idx}`}
          />
        ))}
      </div>
    </div>
  );
};

export default SixteenSidedDice;

const LastDigit = ({ num = "f", last }) => (
  <div
    style={{
      background: last ? "lightsalmon" : "#47307f",
      height: "30px",
      width: "30px",
      color: "white",
      fontWeight: "bold",
      borderRadius: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "5px",
    }}
  >
    {num}
  </div>
);

const hexaDecimals = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
];
