import { Route, Routes } from "react-router-dom";
import { useAppContext } from "../../AppContext";
import GuidePage from "./GuidePage";

const Guide = (props) => {
  const context = useAppContext();
  return (
    <div>
      <Routes>
        <Route path=":guideId" element={<GuidePage context={context} />} />
      </Routes>
    </div>
  );
};

export default Guide;
