import React from "react";
import AccessKeysExplorable from "../../AccessKeysExplorable";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Paper } from "@mui/material";
import {
  deleteExerciseAccountKeys as accountkeys,
  initialAccessKeys as accessKeys,
} from "../../AccessKeysExplorable/keysData";
import useSound from "use-sound";
import { AntTab, AntTabs } from "../../../../Blockchain/styledComponents";
import isEqual from "lodash.isequal";

const getExercise = (id) =>
  ({
    1: (
      <span>
        Remove the <code>access_key</code> with the <code>public_key</code>{" "}
        <code>ed25519:3X7fBScNcW46p9mRk1Pf3nCEdqUC3nJPaQy9Henpuq8K</code>
      </span>
    ),
    2: (
      <span>
        Remove the <code>access_key</code> with the <code>public_key</code>{" "}
        <code>ed25519:EpiESAMtdjdzBRygtPcE5icvCGr7JAB8PmVfcvHNjtou</code>
      </span>
    ),
    3: (
      <span>
        Remove the <code>access_key</code> with the <code>public_key</code>{" "}
        <code>ed25519:FAHdva18NEmSoTko2S346k44tmyFcf9PvrGWgLgwrHDt</code>
      </span>
    ),
  }[id]);

const ExerciseBox = ({ id, filled = false }) => (
  <Alert severity={filled ? "success" : "error"}>{getExercise(id)}</Alert>
);

const GenerateKeyPair = () => {
  const [completed, setCompleted] = React.useState({});
  const [played, setPlayed] = React.useState({});
  const [initialAccessKeys, setInitialAccessKeys] = React.useState(accessKeys);
  const [initialAccountKeys, setInitialAccountKeys] =
    React.useState(accountkeys);

  const [playCorrect] = useSound("/sounds/correct.mp3", { volume: 0.1 });

  const explorableProps = {
    defaultAccessKeys: accessKeys,
    defaultAccountKeys: accountkeys,
    setInitialAccessKeys,
    setInitialAccountKeys,
  };

  React.useEffect(() => {
    if (
      Object.values(completed).filter((x) => x === true).length >
      Object.values(played).filter((x) => x === true).length
    ) {
      playCorrect();
    }
    setPlayed(completed);
  }, [completed]);

  React.useEffect(() => {
    // 1: Access key  ed25519:FAHdva18NEmSoTko2S346k44tmyFcf9PvrGWgLgwrHDt deleted
    const thirdKeyDeleted =
      initialAccountKeys["testnet"].filter(
        (x) =>
          x.public_key ===
          "ed25519:FAHdva18NEmSoTko2S346k44tmyFcf9PvrGWgLgwrHDt"
      ).length === 0;

    // 2: Access Key ed25519:EpiESAMtdjdzBRygtPcE5icvCGr7JAB8PmVfcvHNjtou deleted

    const secondKeyDeleted =
      initialAccountKeys["testnet"].filter(
        (x) =>
          x.public_key ===
          "ed25519:EpiESAMtdjdzBRygtPcE5icvCGr7JAB8PmVfcvHNjtou"
      ).length === 0;

    /// 3: Access Key ed25519:3X7fBScNcW46p9mRk1Pf3nCEdqUC3nJPaQy9Henpuq8K
    const firstKeyDeleted =
      initialAccountKeys["testnet"].filter(
        (x) =>
          x.public_key ===
          "ed25519:3X7fBScNcW46p9mRk1Pf3nCEdqUC3nJPaQy9Henpuq8K"
      ).length === 0;

    setCompleted({
      1: firstKeyDeleted,
      2: secondKeyDeleted,
      3: thirdKeyDeleted,
    });
  }, [initialAccessKeys, initialAccountKeys]);

  return (
    <Paper elevation={4} style={{ padding: "20px", borderRadius: "10px" }}>
      <h2 style={{ textAlign: "center", color: "#47307f" }}>Exercises</h2>

      <p>
        The account on <code>primerlabs.testnet</code> has a number of keys that
        are compromised.
      </p>
      <Stack sx={{ width: "100%", marginBottom: "20px" }} spacing={2}>
        <ExerciseBox id={"1"} filled={completed[1]} />
        <ExerciseBox id={"2"} filled={completed[2]} />
        <ExerciseBox id={"3"} filled={completed[3]} />
        <Alert severity={"info"}>
          {" "}
          Remove the access key stored in the{" "}
          <code>~./near-credentials/testnet</code> folder at the very last.
          Otherwise, you will not be able to delete the other access keys.
        </Alert>
      </Stack>
      <div style={{ marginBottom: "20px" }}>
        <AccessKeysExplorable type="REMOVE" {...explorableProps} />
      </div>
    </Paper>
  );
};

export default GenerateKeyPair;
