import React from "react";
import MatchQuiz from "../../../../../components/Quiz/MatchQuiz";

const MatchTemplate = (props) => {
  const matchOptions = {
    "Function Call Access Key":
      "Allows contracts to spend Ⓝ to cover for gas fees on your behalf",
    "Implicit Accounts":
      "allows you to reserve an account ID before it's created on chain",
    "Full Access Key": "Gives Complete Control of an account",
    "Named Accounts": "Accounts having human-readable address",
    Allowance:
      "Maximum Ⓝ tokens that can be spent by contract in a function-call Access Key",
  };
  return (
    <MatchQuiz
      large={true}
      header={
        <React.Fragment>
          {props.name}, can you match the following terms and their usage?
        </React.Fragment>
      }
      matchOptions={matchOptions}
      {...props}
    />
  );
};

export default MatchTemplate;
