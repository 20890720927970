import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "BigInt(10000000)",
    'BigInt("")',
    "1000n",
    "9007199254740991",
  ];
  const correctOption = "9007199254740991";
  return (
    <MCQQuiz
      code={true}
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, which of the following is not a type of{" "}
            <code>BigInt</code> in <code>javascript</code>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
