import React, { useEffect, useRef, useLayoutEffect } from "react";
import { Paper, Typography, Button, Avatar } from "@mui/material";
import { avatars, userAvatars } from "./Avatar";
import { useAnime } from "../../../hooks/useAnime";
import anime from "animejs";
import { CssTextField } from "./styledComponents";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";
import UserReply from "./UserReply";
import { replaceMessage } from "../../../utils";

const PrimerChat = ({
  avatar = "attention",
  chats,
  state = "one",
  send,
  ...props
}) => {
  //   const replyMachine = createMachine({
  //     id: "reply",
  //     initial: state,
  //     states: {
  //       one: {
  //         on: {
  //           NEXT: "two",
  //         },
  //       },
  //       two: {
  //         on: {
  //           NEXT: "three",
  //           PREV: "one",
  //         },
  //       },
  //       three: {
  //         type: "final",
  //       },
  //     },
  //   });
  const avatarText = avatars.find((x) => x.text === avatar);
  const src = `https://assets.primerlabs.io/PrimerAvatar150/${avatarText.value}`;
  const currentlyActive = props.contextProgress === props.index;

  const primerAvatar = useRef();
  const primerLabel = useRef();
  const primerMessageCard = useRef();
  const animation = useRef(null);
  const replyTextArea = useRef(null);

  //   const [current, send] = useMachine(replyMachine);

  useEffect(() => {
    if (currentlyActive) {
      const primerMessageCards =
        primerMessageCard.current.querySelectorAll(".PrimerMessage");

      if (state !== "three") {
        animation.current = anime.timeline({
          loop: false,
          autoplay: true,
        });

        animation.current.add({
          targets: [primerAvatar.current, primerLabel.current],
          scale: [0, 1],
          opacity: [0, 1],
          begin: () => {
            // primerMessageCard.current.scrollIntoView({
            //   behavior: "smooth",
            //   block: "center",
            // });
            setTimeout(() => {
              primerMessageCard.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }, 100);
          },
        });

        animation.current.add({
          targets: primerMessageCards,
          opacity: [0, 1],
          translateY: [25, 0],

          delay: function (el, i, l) {
            return i * 300;
          },
          complete: () => {
            send("NEXT");
          },
        });
      }
    }
  }, []);

  //   const showUserReply = current.matches("two") || current.matches("three");

  const { reply, userAvatar } = props.userReply || {
    reply: null,
    userAvatar: "Boring",
  };
  const showReply = reply !== null;

  return (
    <React.Fragment>
      <Paper
        style={{
          margin: "30px",
          padding: "30px",
          display: "flex",
          flexDirection: "row",
          background: "floralwhite",
          width: "fit-content",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "150px",
            textAlign: "center",
            padding: "10px",
            borderRadius: "12px",
          }}
        >
          <div style={{ padding: "10px" }}>
            <img
              ref={primerAvatar}
              alt={avatar}
              src={src}
              style={{ height: "130px" }}
            />
          </div>
          <div
            ref={primerLabel}
            style={{
              background: "#47307f",
              color: "floralwhite",
              borderRadius: "4px",
              margin: "0px 10px",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                verticalAlign: "center",
              }}
            >
              Primer
            </span>
          </div>
        </div>
        <div
          ref={primerMessageCard}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {chats.map((x, id) => (
            <Paper
              className="PrimerMessage"
              key={`chatid-${id}`}
              elevation={3}
              sx={{
                padding: "10px",
                margin: "10px",
                width: "fit-content",
                background: "antiquewhite",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", fontFamily: "Roboto" }}
              >
                {replaceMessage(x, { user: props.name })}
              </Typography>
            </Paper>
          ))}
        </div>
      </Paper>
      {showReply && (
        <UserReply
          currentlyActive={currentlyActive}
          chats={reply}
          send={send}
          name={props.name}
          avatar={userAvatar}
        />
      )}
    </React.Fragment>
  );
};

export default PrimerChat;

// {current.matches("one") && (
//     <div
//       ref={replyTextArea}
//       style={{
//         textAlign: "center",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//       }}
//     >
//       <CssTextField
//         value={text}
//         onKeyDown={handleKeyDown}
//         autoComplete="off"
//         onChange={(evt) => setText(evt.target.value)}
//         autoFocus
//         rows={2}
//         multiline
//         sx={{
//           margin: "10px auto",
//           width: "50%",
//           minWidth: "400px",
//           maxWidth: "500px",
//         }}
//         label="What's a blockchain?"
//       />

//       <div>
//         <Button
//           onClick={handleSubmit}
//           disabled={text.trim().length < 3}
//           sx={{ height: "3rem", fontSize: "1rem" }}
//           variant="outlined"
//         >
//           Submit
//         </Button>
//       </div>
//     </div>
//   )}

//   {showUserReply && <UserReply chats={[text]} send={send} />}
