import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Any change in the chain is noticeable right away",
    "Makes it impossible to alter the chain",
    "Keeps the privacy of the content secure",
    "All of the above",
  ];
  const correctOption = "Any change in the chain is noticeable right away";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, what's the primary use of <b>linking block's hashes </b>
          together forming a chain?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
