import { remapQuiz } from "../../utils";

// Smart Contracts using Javascript
import introduction from "./Dapp/Introduction";
import ratings from "./Dapp/BookRatings";
import donations from "./Dapp/Donations";

export const primerComponents = {
  "smart-contract-frontend": introduction.primerComponents,
  "book-ratings-dapp": ratings.primerComponents,
  "donations-app": donations.primerComponents,
};

export const quizList = {
  "smart-contract-frontend": remapQuiz(introduction.quizList),
  "book-ratings-dapp": remapQuiz(ratings.quizList),
  "donations-app": remapQuiz(donations.quizList),
};

export const imageComponents = {
  "smart-contract-frontend": introduction.imageComponents,
  "book-ratings-dapp": ratings.imageComponents,
  "donations-app": donations.imageComponents,
};

const main = {
  primerComponents,
  imageComponents,
  quizList,
};

export default main;

export const dapp = [
  {
    group: 1,
    key: "dapp",
    name: "Decentralised Application",
    topicOrder: [[1], [2, 3]],
    background: "wheat",
    topics: [
      {
        id: 1,
        title: "Smart Contract Frontend",
        stars: "0",
        image: "dapp1",
        slug: "smart-contract-frontend",
        progress: 0,
        wrong: 0,
        primers: introduction.primers,
      },
      {
        id: 2,
        title: "Book Ratings DApp",
        stars: "0",
        image: "dapp1",
        slug: "book-ratings-dapp",
        progress: 0,
        wrong: 0,
        primers: ratings.primers,
      },

      {
        id: 3,
        title: "Donations DApp",
        stars: "0",
        image: "dapp1",
        slug: "donations-app",
        progress: 0,
        wrong: 0,
        primers: donations.primers,
      },
    ],
  },
];
