import React from "react";

import {
  Paper,
  Typography,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "aliceblue",
    color: "aliceblue",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    fontWeight: "bold",
    fontFamily: "Nunito",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: "lightcoral",
  //   },
  //   "&:nth-of-type(even)": {
  //     backgroundColor: "aliceblue",
  //   },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

// export const sampleTable = () => (
//   <TableContainer
//     component={Paper}
//     elevation={4}
//     sx={{
//       maxWidth: "400px",
//       margin: "30px auto",
//     }}
//   >
//     <Table size="medium">
//       <TableHead>
//         <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
//           <StyledTableCell variant="body">
//             Total Number of Quizzes
//           </StyledTableCell>
//           <StyledTableCell variant="body">{total}</StyledTableCell>
//         </StyledTableRow>
//       </TableHead>
//       <TableBody>
//         <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
//           <StyledTableCell variant="body">
//             Total Number of Quizzes
//           </StyledTableCell>
//           <StyledTableCell variant="body">{total}</StyledTableCell>
//         </StyledTableRow>
//         <StyledTableRow sx={{ backgroundColor: "lightgreen" }}>
//           <StyledTableCell variant="body">Correct Responses</StyledTableCell>
//           <StyledTableCell variant="body">{correct}</StyledTableCell>
//         </StyledTableRow>
//         <StyledTableRow sx={{ backgroundColor: "lightcoral" }}>
//           <StyledTableCell variant="body">Wrong Responses</StyledTableCell>
//           <StyledTableCell variant="body">{wrong}</StyledTableCell>
//         </StyledTableRow>
//         <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
//           <StyledTableCell variant="body">Percentage Score</StyledTableCell>
//           <StyledTableCell variant="body">
//             {isNaN(percentage) ? 0 : parseInt(percentage)}%
//           </StyledTableCell>
//         </StyledTableRow>
//       </TableBody>
//     </Table>
//   </TableContainer>
// );
