export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        '{{user}}, in the previous lesson, we stumbled upon a problem of creating a time-stamp service in the absence of "generally trusted third-parties"',
        "Let's reframe our problem statment to understand it better",
      ],
      primerAvatar: "wave",
    },
  },
];
