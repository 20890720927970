import {
  StyledTableRow,
  StyledTableCell,
} from "../../../../../components/Common/Table.jsx";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";

const BlockTimeTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "900px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell
              sx={{ color: "floralwhite", textAlign: "center" }}
              variant="body"
            >
              Proof of Work
            </StyledTableCell>
            <StyledTableCell
              sx={{ color: "floralwhite", textAlign: "center" }}
              variant="body"
            >
              Proof of Stake
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              Mining a new block depends on how much computational work is done
              by miner
            </StyledTableCell>
            <StyledTableCell variant="body">
              Block production depends on how large a stake a validator holds{" "}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              First miner to find a valid block is awarded coinbase reward
            </StyledTableCell>
            <StyledTableCell variant="body">
              Validators in epoch are awarded Epoch Rewards according to their
              stake amount
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              Specialized and Expensive servers are required to profitably mine
              blocks
            </StyledTableCell>
            <StyledTableCell variant="body">
              Standard servers are enough for validating blocks
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              Initial investment to buy hardware
            </StyledTableCell>
            <StyledTableCell variant="body">
              Initial investment to buy stake and build reputation.
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              Actors would need to have greater than 51% of computation power to
              add malicious block.
            </StyledTableCell>
            <StyledTableCell variant="body">
              Actors would need to own 51% of all staked currency on network,
              which is practically impossible.
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const BlockDifficultyOverTime = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "700px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Bitcoin Block
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Addition Date
            </StyledTableCell>

            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Difficulty
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Block 1</StyledTableCell>
            <StyledTableCell variant="body">Eight</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Block 50000</StyledTableCell>
            <StyledTableCell variant="body">~ 12-14 seconds</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Near</StyledTableCell>
            <StyledTableCell variant="body">~ 1 second</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlockTimeTable;
