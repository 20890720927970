import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ConfirmationDialog from "./ConfirmationDialog";
import LessonProgress from "./LessonProgress";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { primerComponents } from "../../AppContextData/Data";
import AppBar from "@mui/material/AppBar";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import LessonScorecard, { calculateStars } from "./LessonScorecard";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import Interaction from "../Info/Interaction";
import useSound from "use-sound";
import { CenteredLayout } from "../Quiz/layouts/Layout";
import { imageComponents } from "../../AppContextData/Data";
import {
  traverse,
  HideOnScroll,
  ScrollTop,
  constructImageLinks,
} from "./LessonUtils";

const LessonPage = (props) => {
  const { questId, lessonId } = useParams();
  const isQuest = props.quest;
  const { context } = props;
  const [preloadSrcList, setPreloadSrcList] = useState([]);

  const [overClose, setOverClose] = useState(false);
  const [open, setOpen] = useState(false);
  const [progressState, setProgressState] = useState(null);
  const [playLevelCompleted] = useSound("/sounds/level_completed1.mp3", {
    volume: 0.1,
  });
  const [playLevelLost] = useSound("/sounds/level_lose.wav", {
    volume: 0.1,
  });
  const [playLevelWin] = useSound("/sounds/game_win5.wav", {
    volume: 0.1,
  });

  const navigate = useNavigate();

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dialogProps = {
    questId,
    handleClose,
    navigate,
    open,
    showPrompt,
    confirmNavigation,
    cancelNavigation,
  };

  const groupIndex = context.topics[questId].findIndex((x) =>
    x.topics.some((y) => y.slug === lessonId)
  );
  const topicIndex = context.topics[questId][groupIndex].topics.findIndex(
    (x) => x.slug === lessonId
  );
  const topic = context.topics[questId][groupIndex].topics[topicIndex];

  // Run only once
  React.useEffect(() => {
    const avatarObject = traverse(topic.primers);
    // Construct Image Links
    const primerImageLinks = constructImageLinks(
      Array.from(avatarObject.primer)
    );
    const userImageLinks = constructImageLinks(
      Array.from(avatarObject.user),
      false
    );
    // Fetch from the image Components

    const preloadedImages = imageComponents[questId][lessonId] || [];
    const imgList = [
      ...preloadedImages,
      ...primerImageLinks,
      ...userImageLinks,
    ];
    setPreloadSrcList(imgList);
  }, []);

  const wrong = topic.wrong;

  const { progress, primers } = topic;

  useEffect(() => {
    if (progressState === "error") {
      updateWrong();
    }
    if (primers) {
      if (progress === primers.length) {
        updateStars(stars);
        if (stars === "0") {
          playLevelLost();
        } else if (stars === "5") {
          playLevelWin();
        } else {
          playLevelCompleted();
        }
      }
    }

    setProgressState(null);
  }, [progress]);

  const updateWrong = () => {
    context.setTopics((topics) => {
      topics[questId][groupIndex].topics[topicIndex].wrong++;
      return topics;
    });
  };

  const quizzes = primers ? primers.filter((x) => x.type === "QUIZ") : [];

  const percentage = parseInt(
    ((quizzes.length - wrong) / quizzes.length) * 100
  );

  const stars = calculateStars(percentage);

  const updateStars = (stars) => {
    context.setTopics((topics) => {
      topics[questId][groupIndex].topics[topicIndex].stars = stars;
      return topics;
    });
  };

  const progressProps = {
    overClose,
    setOverClose,
    handleClickOpen,
    navigate,
    questId,
    progressState,
    progress,
    primers,
    context,
    lessonId,
    isQuest,
  };

  const scoreboardProps = {
    context,
    questId,
    lessonId,
    isQuest,
  };

  let Component = primerComponents
    ? primerComponents[questId]
      ? primerComponents[questId][lessonId]
        ? primerComponents[questId][lessonId]["NextLesson"]
        : false
      : false
    : false;

  if (!topic.primers) {
    return (
      <div>
        <HideOnScroll {...props}>
          <AppBar>
            <LessonProgress {...progressProps} />
          </AppBar>
        </HideOnScroll>

        <div id="back-to-top-anchor" style={{ height: "6rem" }}></div>
        <CenteredLayout>
          <h1>No Primers Found</h1>
        </CenteredLayout>

        <ConfirmationDialog {...dialogProps} />
      </div>
    );
  } else {
    return (
      <div>
        <HideOnScroll {...props}>
          <AppBar>
            <LessonProgress showReset={true} {...progressProps} />
          </AppBar>
        </HideOnScroll>
        <div id="back-to-top-anchor" style={{ height: "6rem" }}></div>

        <Interaction
          topic={topic}
          questId={questId}
          lessonId={lessonId}
          groupIndex={groupIndex}
          topicIndex={topicIndex}
          setProgressState={setProgressState}
          preloadSrcList={preloadSrcList}
          {...props}
        />

        {Component && progress === primers.length ? (
          <Component context={context} />
        ) : (
          ""
        )}

        {progress === primers.length ? (
          <LessonScorecard
            showReset={true}
            wrong={wrong}
            total={quizzes.length}
            percentage={percentage}
            {...scoreboardProps}
          />
        ) : (
          ""
        )}

        <div style={{ height: "400px" }}></div>
        {/* <LessonDialog {...ComponentProps} /> */}
        <ConfirmationDialog {...dialogProps} />
        <ScrollTop {...props}>
          <Fab color="primary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </div>
    );
  }
};

export default LessonPage;
