// Primers
import { start } from "./Primers/Start";

// Info
import CreatingAccountsInfo from "./PrimerComponents/CreatingAccountsInfo.mdx";
import AccountNamesInfo from "./PrimerComponents/AccountNamesInfo.mdx";
import SubAccountInfo from "./PrimerComponents/SubAccountInfo.mdx";
import StateStakingInfo from "./PrimerComponents/StateStakingInfo.mdx";
import EthStorageInfo from "./PrimerComponents/EthStorageInfo.mdx";
import TransferInfo from "./PrimerComponents/TransferInfo.mdx";
import DeleteInfo from "./PrimerComponents/DeleteInfo.mdx";

import NextLesson from "./PrimerComponents/NextLesson";

// Quiz
import CreateAccountQuiz from "./Quiz/CreateAccountQuiz";
import ValidAccountQuiz from "./Quiz/ValidAccountQuiz";
import AccountBalanceQuiz from "./Quiz/AccountBalanceQuiz";
import SendQuiz from "./Quiz/SendQuiz";

// Next Lesson

const primers = [...start];

const primerComponents = {
  CreatingAccountsInfo: CreatingAccountsInfo,
  AccountNamesInfo: AccountNamesInfo,
  SubAccountInfo: SubAccountInfo,
  StateStakingInfo: StateStakingInfo,
  EthStorageInfo: EthStorageInfo,
  TransferInfo: TransferInfo,
  DeleteInfo: DeleteInfo,
  CreateAccountQuiz: CreateAccountQuiz,
  ValidAccountQuiz: ValidAccountQuiz,
  AccountBalanceQuiz: AccountBalanceQuiz,
  SendQuiz: SendQuiz,
  NextLesson: NextLesson,
};

const imageComponents = [];

const quizList = [
  "CreateAccountQuiz",
  "ValidAccountQuiz",
  "AccountBalanceQuiz",
  "SendQuiz",
];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
