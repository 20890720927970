import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["code_hash", "amount", "block_hash", "All of the above"];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which of the following exists in the output of the
          command <code>near state accountname</code>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
