// Primers
import { start } from "./Primers/Start";

// Info
import SmartContractsInfo from "./PrimerComponents/SmartContractsInfo.mdx";
import DevAccountsInfo from "./PrimerComponents/DevAccountsInfo.mdx";
import SmartContractsExecuteInfo from "./PrimerComponents/SmartContractsExecuteInfo.mdx";
import ViewMethodsInfo from "./PrimerComponents/ViewMethodsInfo.mdx";
import CallMethodInfo from "./PrimerComponents/CallMethodInfo.mdx";

// Quiz
import ReadableMCQ from "./Quiz/ReadableMCQ";
import ContractReorder from "./Quiz/ContractReorder";
import ViewMethodMCQ from "./Quiz/ViewMethodMCQ";
import MethodsMCQ from "./Quiz/MethodsMCQ";

// Next Lesson

const primers = [...start];

const primerComponents = {
  SmartContractsInfo: SmartContractsInfo,
  ViewMethodsInfo: ViewMethodsInfo,
  CallMethodInfo: CallMethodInfo,
  SmartContractsExecuteInfo: SmartContractsExecuteInfo,
  DevAccountsInfo: DevAccountsInfo,
  ReadableMCQ: ReadableMCQ,
  ContractReorder: ContractReorder,
  ViewMethodMCQ: ViewMethodMCQ,
  MethodsMCQ: MethodsMCQ,
};

const imageComponents = [];

const quizList = [
  "MethodsMCQ",
  "ReadableMCQ",
  "ViewMethodMCQ",
  "ContractReorder",
];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
