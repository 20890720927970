import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Post office Protocol (POP)",
    "Simple mail transport Protocol (SMTP)",
    "File Transfer Protocol (FTP)",
    "Hyper Text Transfer Protocol Secure (HTTPS)",
  ];
  const correctOption = "Post office Protocol (POP)";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, can you guess which of the following protocols are used
          for receiving incoming E-mails?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
