import React, { Suspense } from "react";

const SandpackComponent = React.lazy(() => import("./SandpackComponent"));

const Serializer = () => {
  return (
    <div style={{}}>
      <Suspense fallback={<div>Loading...</div>}>
        <SandpackComponent />
      </Suspense>
    </div>
  );
};

export default Serializer;
