import React from "react";
import MatchQuiz from "../../../../../components/Quiz/MatchQuiz";

const MatchTemplate = (props) => {
  const matchOptions = {
    VM: "Virtual Machine",
    WebAssembly: "Low Level Programming Langauge",
    "NEAR-SDK-JS": "Tools for building NEAR smart contracts in JavaScript",
    "Change Methods": "Methods that modify account state",
    "View Methods": "Methods that don't modify account state",
  };

  return (
    <MatchQuiz
      large={true}
      header={
        <React.Fragment>
          {props.name}, can you match the following terms and their usage
          related to <b>smart contract</b> in <b>NEAR Protocol</b>?
        </React.Fragment>
      }
      matchOptions={matchOptions}
      {...props}
    />
  );
};

export default MatchTemplate;
