import React from "react";
import { ReorderQuiz } from "../../../../../components/Quiz/ReorderQuiz";

const itemsList = [
  'User creates an account on the web application with a "username" and "password"',
  "The web application stores the login details in a database",
  'To login, user types the "username" and "password"',
  "Application checks if the login details are same as stored in the database",
  "If the login details matches, the user is allowed to log in",
];

const ReorderTemplate = (props) => (
  <ReorderQuiz
    itemsList={itemsList}
    header={
      <React.Fragment>
        Can you reorder the following jumbled steps of{" "}
        <b>how login works in traditional Web2?</b>{" "}
      </React.Fragment>
    }
    {...props}
  />
);

export default ReorderTemplate;
