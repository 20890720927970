import { CustomOutputFactory } from "../outputs";

// Login Command

const loginText = `Attempting to login to &&NEAR Testnet Wallet&&.

&&If you are prompted to leave this page, click on leave, connect your test wallet if you already have an account.&&

[[In case you do not have a near test wallet, create a test wallet and then proceed to connect your test wallet.]]

**You will be automatically redirected to this page once you have logged in your test wallet**
`;

const loginHelpText = `
**Usage**: &&near&& [[login]]

    ^^logging in through NEAR protocol wallet^^
`;
export const loginCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(loginHelpText),
    };
  }
  context.signIn();
  return {
    output: CustomOutputFactory.createColoredRecord(loginText),
  };
};
