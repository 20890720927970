export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, in the previous topic, we learned about access keys and accounts",
        "In this lesson, we will start issuing transactions to the NEAR Protocol Blockchain",
        "Let's start by creating accounts using Command Line Interface",
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "CreatingAccountsInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "CreateAccountQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "While creating new accounts, there are certain rules that you have to keep in mind",
        "Let's take a look at them",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "AccountNamesInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ValidAccountQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "In NEAR, only immediate parent accounts can create the subaccount",
        "Let's understand what this means",
      ],
      primerAvatar: "book",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "SubAccountInfo",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "{{user}}, so far while creating accounts we transfer some intial NEAR Tokens",
        "If we skip the argument, then the account is transferred 100 NEAR tokens",
        'What do you think happens, when we explicitly pass 0 NEAR tokens as "--initialBalance" argument?',
      ],
      primerAvatar: "question",
      textReply: {
        que: "What happens if we pass 0 NEAR tokens as initial balance?",
      },
      userReply: { reply: null, userAvatar: "Cheeks" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "We cannot create accounts with 0 balance and it would result in error",
        "In NEAR Protocol, each account has to maintain a certain minumum amount of NEAR Tokens to cover for the storage it uses",
        "Let's understand in detail how each account pays for the storage it uses",
      ],
      primerAvatar: "book",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "StateStakingInfo",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "{{user}}, can you think of any reason as to why the storage staking mechanism might be useful?",
      ],
      primerAvatar: "puzzle",
      textReply: {
        que: "Can you think of any reason as to why the storage staking mechanism might be useful?",
      },
      userReply: { reply: null, userAvatar: "Happy" },
    },
  },

  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "EthStorageInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "AccountBalanceQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'Since the account doesn\'t have any available balance to cover for the additional storage used by the "access_key" the request will fail',
        "To perform the transaction, the account will need to be transferred some additional NEAR tokens.",
        "Let's take a look at how we can transfer tokens next",
      ],
      primerAvatar: "patient",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "TransferInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "SendQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'As "Function Call Access Key" don\'t allow to transfer funds on behalf the account...',
        "...you will require a full-access key to transfer from the account",
        "Next, let's checkout another type of transaction that allows us to delete accounts",
      ],
      primerAvatar: "cleaner",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DeleteInfo",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "In this lesson, we saw three types of transactions you can do using your NEAR Account with a full access key.",
        "Creating accounts, Transferring funds to the accounts and deleting accounts.",
        "In the next lesson, we will take a look at some additional types of transactions.",
        "See you in the next lesson, {{user}}.",
      ],
      primerAvatar: "gears",
    },
  },
];
