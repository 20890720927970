import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import ConfirmationDialog from "./ConfirmationDialog";
import PrimerAlert from "../Common/Alert";

const DashboardAppBar = ({ context }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openSignOutDialog, setOpenSignOutDialog] = React.useState(false);
  const [showSignOutAlert, setShowSignOutAlert] = React.useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSetting = (evt, setting) => {
    if (setting === "Reset Course Progress") {
      setOpenDialog(true);
    } else if (setting === "Sign in with NEAR Wallet") {
      context.signIn();
    } else if (setting === "Sign out of NEAR Wallet") {
      setOpenSignOutDialog(true);
      // context.handleSignOut();
    } else if (setting === "Show Account Details") {
    }
  };

  const settings = context.walletAccountId
    ? ["Reset Course Progress", "Sign out of NEAR Wallet"]
    : ["Reset Course Progress", "Sign in with NEAR Wallet"];

  const dialogProps = {
    openDialog,
    setOpenDialog,
    context,
    setAnchorElUser,
  };

  const signOutDiaglogProps = {
    openDialog: openSignOutDialog,
    setOpenDialog: setOpenSignOutDialog,
    context,
    setAnchorElUser,
    headerText: "Are you sure you want to sign out of your near test wallet?",
    bodyText: "You will have to sign in once again to resume",
    actionButton: "Sign out",
    action: "SIGNOUT",
    setShowSignOutAlert,
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar sx={{ paddingTop: "20px", padding: "20px" }}>
          <Avatar
            variant="square"
            src="/images/nearLogo.svg"
            sx={{
              color: "floralwhite",
              display: { xs: "none", md: "flex" },
              mr: 1,
              margin: "10px",
            }}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            NEAR PROTOCOL PRIMER
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>
          <Avatar
            variant="square"
            src="/images/nearLogo.svg"
            sx={{
              display: { xs: "flex", md: "none" },
              mr: 1,
              margin: "10px",
            }}
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            NEAR PROTOCOL PRIMER
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

          <Box sx={{ flexGrow: 0, display: "flex", flexDirection: "column" }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Typography
                  variant="h6"
                  noWrap
                  sx={{
                    fontWeight: 700,
                    color: "floralwhite",
                    textDecoration: "none",
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  {" "}
                  {context.name}
                </Typography>

                <Avatar
                  alt={context.name}
                  src="https://assets.primerlabs.io/kawaii/Boring.svg"
                />
              </IconButton>
            </Tooltip>
            <Typography
              noWrap
              sx={{
                fontWeight: 700,
                color: "floralwhite",
                textDecoration: "none",
                display: { xs: "none", md: "flex" },
                margin: "auto",
              }}
            >
              {context.walletAccountId}
            </Typography>

            {context.accountBalance === null ? (
              ""
            ) : (
              <Balance balance={context.accountBalance} />
            )}

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={(evt) => handleSetting(evt, setting)}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <ConfirmationDialog {...dialogProps} />
      <ConfirmationDialog {...signOutDiaglogProps} />
      {showSignOutAlert && (
        <PrimerAlert
          hide={setShowSignOutAlert}
          severity="success"
          message="Successfully signed out of NEAR Test Wallet"
        />
      )}
    </AppBar>
  );
};

export const Balance = ({ balance }) => {
  const resizedBalance = parseFloat(
    balance.available.split(",").join("")
  ).toFixed(2);
  return (
    <Typography
      noWrap
      sx={{
        fontWeight: 700,
        color: "floralwhite",
        textDecoration: "none",
        display: { xs: "none", md: "flex" },
        textAlign: "center",
        fontVariant: "all-small-caps",
        margin: "auto",
        letterSpacing: "2px",
      }}
    >
      Available Balance : {resizedBalance}{" "}
      <img
        style={{ display: "inline-block", margin: "3px 4px" }}
        src={`/images/nearToken.svg`}
        height="20px"
      />
    </Typography>
  );
};

export default DashboardAppBar;
