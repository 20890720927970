import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["primer.", "_near", "hello.a", "All of the above"];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which of the following is an <b>invalid</b>{" "}
          <code>accountId</code> on <b>NEAR Protocol?</b>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
