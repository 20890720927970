import React from "react";
import { Paper, Button } from "@mui/material";
import { HashTextField, CssTextField } from "../../../styledComponents";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TreeView from "./TreeView";
import { implicitAccountId } from "../../../../../utils";
import AccountKeysModal from "./AccountKeysModal";
import { initialAccountKeys, initialAccessKeys } from "./keysData";

const SEED_PATH = "m/44'/397'/0'";
const { parseSeedPhrase, generateSeedPhrase } = require("near-seed-phrase");

const AccessKeys = () => {
  const [accessKeys, setAccessKeys] = React.useState(initialAccessKeys);
  const [useAccount, setUseAccount] = React.useState(false);
  const [useSeedphrase, setUseSeedphrase] = React.useState(false);
  const [accountId, setAccountId] = React.useState("");
  const [seedphrase, setSeedphrase] = React.useState("");
  const [testnet, setTestnet] = React.useState(true);
  const [changed, setChanged] = React.useState([]);
  const [message, setMessage] = React.useState(null);
  const [accountAccessKeys, setAccountAccessKeys] =
    React.useState(initialAccountKeys);

  React.useEffect(() => {
    if (message !== null) {
      setMessage(null);
    }
  }, [useAccount, useSeedphrase, seedphrase, testnet, accountId]);

  const handleReset = () => {
    setAccessKeys(initialAccessKeys);
    setMessage(null);
    setChanged([]);
  };

  const generateKeyPairs = () => {
    let publicKey;
    let secretKey;
    if (useSeedphrase) {
      const keyPair = parseSeedPhrase(seedphrase, SEED_PATH);
      publicKey = keyPair.publicKey;
      secretKey = keyPair.secretKey;
    } else {
      const keyPair = generateSeedPhrase();
      publicKey = keyPair.publicKey;
      secretKey = keyPair.secretKey;
    }

    const newAccountId = useAccount ? accountId : implicitAccountId(publicKey);
    const network = testnet ? "testnet" : "mainnet";
    const accountExists = accessKeys.find(
      (x) => x.account_id === newAccountId && x.network === network
    );
    if (accountExists) {
      setMessage({
        type: "overwrite",
        account: newAccountId,
        publicKey: accountExists.publicKey,
      });
      if (!changed.includes(`${newAccountId}-${network}`)) {
        setChanged([`${newAccountId}-${network}`, ...changed]);
      }
    } else {
      setMessage({ type: "new", account: newAccountId, publicKey: publicKey });
      setAccessKeys([
        ...accessKeys,
        {
          network: network,
          publicKey: publicKey,
          private_key: secretKey,
          account_id: newAccountId,
        },
      ]);
    }
  };

  const noAccountId = useAccount && accountId.trim().length === 0;
  const noSeedPhrase = useSeedphrase && seedphrase.trim().length === 0;
  const buttonDisabled = noAccountId || noSeedPhrase;

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Paper
          style={{
            display: "flex",
            flexDirection: "column",
            background: "floralwhite",
            borderRadius: "12px",
            padding: "12px",
            width: "300px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "250px",
              margin: "10px auto",
            }}
          >
            <AccountKeysModal keys={accountAccessKeys} />
            <HashTextField
              disabled={!useAccount}
              label="Account ID"
              value={accountId}
              onChange={(evt) => {
                setAccountId(evt.target.value);
              }}
              style={{ margin: "10px" }}
            />
            <CssTextField
              disabled={!useSeedphrase}
              label="SeedPhrase"
              value={seedphrase}
              onChange={(evt) => {
                setSeedphrase(evt.target.value);
              }}
              style={{ margin: "10px" }}
              multiline
              rows={4}
            />

            <FormGroup
              style={{
                width: "250px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                style={{ margin: "5px auto" }}
                control={
                  <Switch
                    size="small"
                    checked={useAccount}
                    onChange={(evt) => setUseAccount(evt.target.checked)}
                  />
                }
                label={
                  <span
                    style={{
                      fontWeight: "bold",
                      margin: "5px auto",
                      fontSize: "12px",
                    }}
                  >
                    Use Account ID
                  </span>
                }
              />
              <FormControlLabel
                style={{ margin: "5px auto" }}
                control={
                  <Switch
                    size="small"
                    checked={useSeedphrase}
                    onChange={(evt) => setUseSeedphrase(evt.target.checked)}
                  />
                }
                label={
                  <span
                    style={{
                      fontWeight: "bold",
                      margin: "5px auto",
                      fontSize: "12px",
                    }}
                  >
                    Seedphrase
                  </span>
                }
              />
              <FormControlLabel
                style={{ margin: "5px auto" }}
                control={
                  <Switch
                    size="small"
                    checked={!testnet}
                    onChange={(evt) => setTestnet(!evt.target.checked)}
                  />
                }
                label={
                  <span
                    style={{
                      fontWeight: "bold",
                      margin: "5px auto",
                      fontSize: "12px",
                    }}
                  >
                    {testnet ? "NEAR Test Network" : "NEAR Main Network"}
                  </span>
                }
              />
            </FormGroup>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "56px",
                margin: "5px auto",
              }}
            >
              <Button
                disabled={buttonDisabled}
                onClick={generateKeyPairs}
                variant="outlined"
                style={{ fontSize: "12px", width: "200px" }}
              >
                Generate Key Pair
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "36px",
                margin: "5px auto",
              }}
            >
              <Button
                onClick={handleReset}
                variant="outlined"
                style={{ fontSize: "12px" }}
              >
                Reset
              </Button>
            </div>
          </div>
        </Paper>
        <TreeView keys={accessKeys} changed={changed} setChanged={setChanged} />
      </div>
      <div
        style={{
          background: "rgba(0, 0, 0, 0.87)",
          padding: "10px",
          color: "floralwhite",
          fontFamily: "Jetbrains Mono",
          margin: "10px",
        }}
      >
        <span style={{ color: "#f8aeff" }}>$</span>{" "}
        {!testnet ? (
          <span style={{ fontWeight: "bold", color: "salmon" }}>
            NEAR_ENV=mainnet
          </span>
        ) : (
          ""
        )}{" "}
        near generate-key{" "}
        {useAccount ? (
          accountId.trim().length === 0 ? (
            <span style={{ color: "gold" }}>&lt;accountId&gt; </span>
          ) : (
            <span style={{ color: "gold", fontWeight: "bold" }}>
              {accountId}
            </span>
          )
        ) : (
          ""
        )}
        {useSeedphrase ? (
          <span style={{ color: "salmon" }}> --seedPhrase </span>
        ) : (
          ""
        )}
        {useSeedphrase ? (
          seedphrase.trim().length === 0 ? (
            <span style={{ color: "gold" }}> &lt;seedphrase&gt; </span>
          ) : (
            <span style={{ color: "gold", fontWeight: "bold" }}>
              {seedphrase}
            </span>
          )
        ) : (
          ""
        )}{" "}
        <br />
        {message !== null ? (
          message.type !== "overwrite" ? (
            <span>
              {" "}
              Key pair with{" "}
              <span style={{ color: "gold", fontWeight: "bold" }}>
                {message.publicKey}
              </span>{" "}
              public key for an account "
              <span style={{ color: "gold", fontWeight: "bold" }}>
                {message.account}
              </span>
              "
            </span>
          ) : (
            <span>
              {" "}
              Account has existing key pair with{" "}
              <span style={{ color: "gold", fontWeight: "bold" }}>
                {message.publicKey}
              </span>{" "}
              public key
            </span>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AccessKeys;
