import React from "react";
import { Paper } from "@mui/material";
import anime from "animejs";
import { primerComponents } from "../../../AppContextData/Data";

const PrimerQuiz = (props) => {
  let Component =
    primerComponents[props.questId][props.lessonId][props.content];
  const animation = React.useRef(null);
  const quizContainer = React.useRef(null);

  const currentlyActive = props.contextProgress === props.index;
  React.useEffect(() => {
    if (currentlyActive) {
      animation.current = anime.timeline({
        loop: false,
        autoplay: true,
      });

      animation.current.add({
        targets: quizContainer.current,
        opacity: [0, 1],
        begin: () => {
          props.send("NEXT");
          setTimeout(() => {
            quizContainer.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }, 100);
        },
      });
    }
  }, []);
  return (
    <Paper ref={quizContainer} style={{ padding: "30px", margin: "30px" }}>
      <Component currentlyActive={currentlyActive} {...props} />
    </Paper>
  );
};

export default PrimerQuiz;
