export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "In this lesson, we will go in depth in how you can write and deploy a smart contract on the NEAR Protocol Platform",
        "Before we proceed, we need to setup our development environment",
        "Let's take a look at how to setup your development environment for writing smart contracts",
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "InstallInfo",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Once, the project is created, you will notice that a lot of files and folders have been created for you",
        "Let's understand overall files and folder structure and their function next.",
      ],
      primerAvatar: "laptop-1",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "FolderInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "BuildMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "NEAR Protocol can only execute code written in Web Assembly",
        "We use Javascript because it's easier to work with Javascript than Web Assembly",
        "Let's understand this by first taking a look at the contract already generated for us",
      ],
      primerAvatar: "gears",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "ContractInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ChargeMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "To write a smart contract, that can be executed on the NEAR Protocol Platform...",
        "... it first requires to compiled to Webassembly",
        "Let's take a look at this process in much more detail",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "ContractClass",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ContractMatch",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'Now, that we have covered how "near-sdk-js" helps in writing smart contracts...',
        '...let\'s circle back to the autogenerated "HelloNEAR" smart contract we saw earlier',
      ],
      primerAvatar: "laptop-1",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "HelloNear",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ContractMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Now, that we have got some idea about how smart contracts are wrtten in Javascript",
        "Time to build the smart contract and deploy it the NEAR Network",
      ],
      primerAvatar: "laptop-3",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "BuildInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "DevDeployMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'The "dev-deploy" command creates a "developer account" and funds it with "NEAR Test Tokens"',
        'Then, it stores the newly generated credentials in the "neardev" folder',
        "Let's look at this in detail.",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DeployingInfo",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "{{user}}, that brings us to the end of this lesson.",
        "In the next lesson, we start learning about how to write a smart contract from scratch.",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "wave",
    },
  },
];
