import { Paper } from "@mui/material";

export const NEAR = () => (
  <Paper
    style={{
      background: "floralwhite",
      padding: "20px",
      margin: "10px auto",
      color: "indigo",
    }}
  >
    <div
      style={{ margin: "0px 25px", lineHeight: "1.5rem", fontWeight: "bold" }}
    >
      <h2 style={{ textAlign: "center" }}>Why use NEAR?</h2>
      <h3>Usability</h3>
      <ul>
        <li>
          NEAR uses human-readable accounts such as <code>alice.near</code>
        </li>
        <li>
          Leverages a web-based wallet, no need for users to install programs or
          browser extensions.
        </li>
        <li>
          Possess a simple yet rich system of Access Keys to handle account
          permissions.
        </li>
      </ul>
      <h3>Fast and Environment Friendly</h3>
      <ul>
        <li>
          Transactions are incredibly fast (~1s transactions) and cheap (~1¢ in
          fees).
        </li>
        <li>
          The network is{" "}
          <a
            target="_blank"
            href="https://near.org/blog/near-climate-neutral-product/"
          >
            certified carbon-neutral
          </a>
          .
        </li>
        <li>
          NEAR consumes in a year the same energy{" "}
          <a href="bitcoin consumes in 3 minutes" target="_blank">
            bitcoin consumes in 3 minutes
          </a>
          .
        </li>
      </ul>

      <h3>Great Developer Experience</h3>
      <ul>
        <li>Enables making smart contracts using Javascript or Rust.</li>
        <li>
          Simple onboarding, thanks to its complete documentation and a
          multitude of examples.
        </li>
        <li>30% of gas fees goes to the developers.</li>
        <li>
          Project funding available through{" "}
          <a href="http://near.org/grants" target="_blank">
            NEAR Grants Program.
          </a>
        </li>
      </ul>
      <h3>A Technical Marvel</h3>
      <ul>
        <li>
          Scales infinitely and resists short-term usage spikes thanks to its{" "}
          {"  "}
          <a
            href="https://near.org/blog/near-launches-nightshade-sharding-paving-the-way-for-mass-adoption"
            target="_blank"
          >
            sharding
          </a>
          .
        </li>
        <li>
          Interoperable with Ethereum using{" "}
          <a href="https://rainbowbridge.app/transfer" target="_blank">
            Rainbow Bridge
          </a>{" "}
        </li>
        <li>
          EVM compatible with{" "}
          <a href="http://www.aurora.dev/" target="_blank">
            Project Aurora
          </a>
        </li>
      </ul>
    </div>
  </Paper>
);
