import { OutputFactory, Outputs } from "javascript-terminal";
import { CustomOutputFactory } from "../outputs";
import { arraysEqual, getURL, getTxId } from "./utils";
import { providers } from "near-api-js";

// near view-state <account-id> [prefix]
const helpText = `
**Usage**: &&near&& [[view-state]] @@<account-id>@@ **--finality** ^^optimistic|final^^ **--utf8**                                                        

    ^^View contract storage state. (Call is free of charge)^^


**Required Arguments**

    &&<account-id>&&
        ^^Account Id  [string]^^

    **Must provide either --finality or --blockId**

    &&--finality&&
        ^^[choices: "optimistic", "final"] [string]^^
   
    &&--blockId&&
        ^^The block number OR the block hash (base58-encoded).  [string]^^

**Optional Argument**

    &&--utf8&&
        ^^Decode keys and values as UTF-8 strings  [boolean] [default: false]^^

    
**Example:**

    &&near&& [[view-state]] @@donations.primerlabs.testnet@@ **--finality** optimistic **--utf8**
        

**Notes**:
    1. No account Id require since a call for viewing state of a contract is free of charge.
     
`;

const generateErrors = (errors) => {
  let mappedText = errors.reduce((acc, curr) => {
    return acc + `\n\t&&` + " * " + curr + `&&`;
  }, ``);
  return `**Encountered Following Errors**
${mappedText}

**Usage**: &&near&& [[view-state]] @@<account-id>@@ **--finality** ^^optimistic|final^^ **--utf8** 

    For more info type:
        &&near&& [[view-state]] @@--help@@ 

`;
};

const getErrorMessage = (res) => {
  const message = `&&${res.type}&&: An error occured.
  '${res.message}'
   `;
  return message;
};

const getSuccessMessage = (res) => {
  const message = `
[['${JSON.stringify(res)}']]
 `;

  return message;
};

const wasmFileNotFound = `Specified Wasm file not found`;
const invalidCommandUsage = `Invalid Command Usage`;
const missingMaster = `Missing Master Account`;
const missingWasmFilePath = `Missing Wasm file path`;
const missingAccountID = `Missing the Account ID`;
const missingOptions = "Provide either --finality or --blockId not both";
const invalidAccountId = `Invalid Account ID provided`;
const endWithTestnet = `Master account must end with testnet`;
const accEndWithTestnet = `Account Id must end with ".testnet"`;
const notLongEnough = `AccountId should be greater than 32 characters`;
const tooLong = `AccountId should be smaller than 64 characters`;
const invalidMasterAccount = `Invalid Master Account Id provided`;
const missingMethodName = `Missing Method name of the contract`;
const missingContractName = `Missing Contract name`;

const updateState = (type, message, setCustomState, setAcceptInput) => {
  if (type === "error") {
    setCustomState((prevState) => {
      const errorMessage = message.type
        ? getErrorMessage(message)
        : `&&${message}&&`;
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(`${errorMessage}`)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  } else if (type === "success") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(message)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  }

  setAcceptInput(true);
};

const getErrors = (args, opts = {}) => {
  return [missingAccountID];
};

// Test

// near view test1.siddharthkanungo.testnet helloWorld

// near view test1.siddharthkanungo.testnet greet '{"names": "Siddharth"}'

const formatBlockId = (blockId) => {
  if (blockId && !isNaN(blockId)) {
    return Number(blockId);
  }
  return blockId;
};

export const viewStateCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  const { setAcceptInput, setCustomState } = stateChangeProps;
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(helpText),
    };
  } else {
    if (args.length < 1) {
      const errors = [missingAccountID];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if ("finality" in options && options.finality === undefined) {
      const errors = ['argument provided for "finality" cannot be empty'];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if ("blockId" in options && options.blockId === undefined) {
      const errors = ['argument provided for "blockId" cannot be empty'];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if (!(options.finality || options.blockId)) {
      const errors = [missingOptions];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if (options.finality && options.blockId) {
      const errors = [missingOptions];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if (
      "finality" in options &&
      !["final", "optimistic"].includes(options.finality)
    ) {
      const errors = [
        `"${options.finality}" is an invalid argument for the option finality. Valid options: "optimistic" or "final"`,
      ];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if (
      [2].includes(args.length) &&
      (options.hasOwnProperty("finality") || options.hasOwnProperty("blockId"))
    ) {
      const [_, contractName] = args;

      // let targetArgs = "{}";
      // if (argString && argString.trim().length !== 2) {
      //   const quoteRe = /(\x27)(.+?)(\x27)/gim;
      //   const quoteMatch = quoteRe.exec(argString);
      //   targetArgs = quoteMatch[2];
      // }

      const commandOptions = {
        blockId: formatBlockId(options.blockId),
        finality: options.finality,
        utf8: "utf8" in options,
      };

      // Call providing prefix, blockQuery
      setAcceptInput(false);

      context
        .callViewStateFunction(contractName, null, commandOptions)
        .then((res) => {
          let type = res.type ? res.type : "success";
          const message = getSuccessMessage(res);
          updateState(type, message, setCustomState, setAcceptInput);
        })
        .catch((e) => {
          let type = "error";
          updateState(type, e, setCustomState, setAcceptInput);
        });

      const message = `View-State call: [[${contractName}]]`;

      return {
        output: CustomOutputFactory.createColoredRecord(message),
      };
    } else {
      const errors = [invalidCommandUsage];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    }
  }
};
