import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["nonce", "method_names", "public_key", "All of the above"];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which of the following <b>term</b> shows up in the
          result of the <code>near keys primerlabs.testnet</code>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
