import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";
const MCQ = (props) => {
  const options = [
    "Callback method will only execute if the call succeeds",
    "Callback must be on the same contract that initiates the call",
    "Cannot attach NEAR tokens to a cross-contract call",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, which of the following statement is <b>false</b>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
