export const start = [
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "A community-operated cloud like NEAR is neither inexpensive nor fast relative to existing compute and storage solutions.",
        "This begs us the question, why do we need them in the first place.",
        "{{user}}, can you take a guess of any use-cases of such using such a cloud-platform?",
      ],
      primerAvatar: "search",

      textReply: {
        que: "What's the use case of a community-operated cloud platform?",
      },
      userReply: { reply: null, userAvatar: "Cheeks" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "A community-operated cloud computing platform such as NEAR Protocol leverages redundancy in both computation and storage. ",
        "Let's understand this on the most basic level by examining how these networks function.",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "CommunityCloudInfo",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "Before, diving into the benefits, {{user}}, would you like to define what decentralisation means?",
      ],
      primerAvatar: "question",

      textReply: { que: "What does decentralisation mean?" },
      userReply: { reply: null, userAvatar: "Cute" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'Earlier, we defined decentralisation as opposed to "centralisation" where the control of an entity/system is directly under a single entity.',
        'However, we can better understand "decentralisation" as a measure of how many actors need to be corrupted to compromise a system.',
        "Let's understand this better",
      ],
      primerAvatar: "patient",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DecentralisationInfo",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        'There are many "digital assets" online that are high value in nature.',
        "Can you think of any such assets?",
      ],
      primerAvatar: "puzzle",

      textReply: {
        que: "Name any high value digital asset?",
      },
      userReply: { reply: null, userAvatar: "Happy" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'One of the most important digital asset online is ofcourse "money"',
        "Your account balance with a bank is stored somewhere on centralised server susceptible to theft and mismanagment",
        "Apart from money, there are also other high value assets which we will look up next",
      ],
      primerAvatar: "dollar",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "HighValueAssets",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "BenefitMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "A significant benefit of using the NEAR Protocol Cloud Platform is the ability to build decentralised applications. ",
        'Before learning about "decentralised applications", let\'s take a quiz to understand how traditional web applications work. ',
      ],
      primerAvatar: "laptop-1",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "TraditionalWebMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "As you can see in traditional web applications, users have to put a lot of trust in the application developer; but how exactly this is a bad?",
        "To understand this, let's take a step back and look at the internet in a entirely different perspective...",
        '...from the perspective of "Digitopia"',
      ],
      primerAvatar: "thumbs-up",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Digitopia",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "Even though it seems truly like a digital utopia, things are always not great in Digitopia.",
        "One of the main reasons is that Digitopia is intricately tangled with Phystopia.",
        "{{user}}, can you think of a reason why this is so?",
      ],
      primerAvatar: "sad",

      textReply: {
        que: "How is Digitopia tangeled with Phystopia?",
      },
      userReply: { reply: null, userAvatar: "Shy" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "There are many reasons how Digitopia was entangled with Phystopia",
        "And there are many problems associated with Digitopia in it's current state",
        "Let's take some time to look at some of these reasons and it's consequences",
      ],
      primerAvatar: "search",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Tangled",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "One of the arguments against privacy is that 'if you have nothing to hide, then why are you afraid?'",
        "{{user}}, what do you think about this argument? ",
      ],
      primerAvatar: "puzzle",

      textReply: {
        que: "What do you think about this argument?",
      },
      userReply: { reply: null, userAvatar: "Cute" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Saying that you don't care about privacy because you have nothing to hide... ",
        "... is similar to saying you don't care about the right to free speech because you nothing to say",
        "Moreover, according to cypherpunks, right to digital privacy isn't only limited to exchange of information rather any form of transaction including money.",
        "Let's take a look at it next",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Bitcoin",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "BitcoinMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Bitcoin paved the way for rise for various cryptocurrencies.",
        "It was first cryptocurrency which demonstrated how to create a system where transactions can occur without a middleman",
        "But it's impact was far wider than it's original intentions.",
      ],
      primerAvatar: "dollar",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Ledger",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "Starting next section, we will deep dive into understanding NEAR Protocol in detail using it's command line application.",
        "Thank you for taking time to learn so far with me, {{user}}",
        "I hope to see you in the next section.",
      ],
      primerAvatar: "wave",
    },
  },
];
