import React from "react";
import DashboardAppBar from "../../../../Dashboard/DashboardAppBar";
import { useAppContext } from "../../../../../AppContext";
import LoveWall from "./LoveWallContainer";
const WallOfLove = (props) => {
  const context = useAppContext();

  React.useEffect(() => {
    document.title = `Wall of Love | NEAR Protocol Primer`;
  }, []);

  return (
    <React.Fragment>
      <DashboardAppBar context={context} />
      <div style={{ margin: "10px auto" }}>
        <h1
          style={{
            color: "darkslateblue",
            textAlign: "center",
            fontFamily: "corben",
          }}
        >
          Wall of Love
        </h1>
        <div>
          <LoveWall showAll={true} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default WallOfLove;
