// Infos
import Hello from "./PrimerComponents/Hello.mdx";
import StateInfo from "./PrimerComponents/StateInfo.mdx";
import ViewMethods from "./PrimerComponents/ViewMethods.mdx";
import CallMethods from "./PrimerComponents/CallMethods.mdx";
import ChangeMethod from "./PrimerComponents/ChangeMethod.mdx";
import GasRuntime from "./PrimerComponents/GasRuntime.mdx";

// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";
// Quiz
import ChargeMCQ from "./Quiz/ChargeMCQ";
import StateMCQ from "./Quiz/StateMCQ";
import ViewCallMCQ from "./Quiz/ViewCallMCQ";
import ChangeMCQ from "./Quiz/ChangeMCQ";
import TrxMatch from "./Quiz/TrxMatch";

// Primers
import { start } from "./Primers/Start";

const primers = [...start];

const primerComponents = {
  Hello: Hello,
  ChargeMCQ: ChargeMCQ,
  StateInfo: StateInfo,
  StateMCQ: StateMCQ,
  ViewMethods: ViewMethods,
  ViewCallMCQ: ViewCallMCQ,
  CallMethods: CallMethods,
  ChangeMCQ: ChangeMCQ,
  ChangeMethod: ChangeMethod,
  TrxMatch: TrxMatch,
  GasRuntime: GasRuntime,
  NextLesson: NextLesson,
};

const imageComponents = [];

const quizList = [
  "ChargeMCQ",
  "StateMCQ",
  "ViewCallMCQ",
  "ChangeMCQ",
  "TrxMatch",
];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
