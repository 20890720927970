import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Methods that change account state",
    "Methods that don't change account state",
    "Both of them take equal time",
  ];
  const correctOption = "Methods that don't change account state";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            <b>Smart contract</b> can have methods that modify the{" "}
            <b>state of an account</b>. While, there are methods that don't
            change the state of an account.
            <br />
            <br />
            {props.name}, which <b>type of method</b> are usually executed
            faster?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
