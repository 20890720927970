import { generateSeedPhrase, parseSeedPhrase } from "near-seed-phrase";
import { OutputFactory, Outputs } from "javascript-terminal";
import { CustomOutputFactory } from "../outputs";
import { arraysEqual } from "./utils";

const helpText = `
**Usage**: &&near&& [[seedphrase]] @@<command>@@

    ^^generates random seed phrase^^

**Commands**:
    &&near&& [[seedphrase]] @@generate@@                                                                       
        ^^generates a random 12-words seedphrase along with public and private keys^^
  
    &&near&& [[seedphrase]] @@parse@@ **<seedphrase>**                                                                              
        ^^parse the provided 12-words seedphrase and generates the public and private keys^^

**Notes**:
    1. Login is &&not required&& to see the state of an account 
     
`;

const generateErrors = (errors) => {
  let mappedText = errors.reduce((acc, curr) => {
    return acc + `\n\t&&` + " * " + curr + `&&`;
  }, ``);
  return `**Encountered Following Errors**
${mappedText}

**Usage**: &&near&& [[seedphrase]] @@<command>@@

    ^^generates random seed phrase^^

**Commands**:
    &&near&& [[seedphrase]] @@generate@@                                                                       
        ^^generates a random 12-words seedphrase along with public and private keys^^
  
    &&near&& [[seedphrase]] @@parse@@ **<seedphrase>**                                                                              
        ^^parse the provided 12-words seedphrase and generates the public and private keys^^

For more info type:
    &&near&& [[seedphrase]] @@--help@@ 

`;
};

const insufficientArgs = `Insufficient number of arguments provided`;
const missingCommand = `Invalid command`;
const missingMaster = `Missing Master Account`;
const missingAccountID = `Missing Account ID`;
const invalidAccountId = `Invalid Account ID provided`;
const endWithTestnet = `Master account must end with testnet`;
const accEndWithTestnet = `Account Id must end with ".testnet"`;
const notLongEnough = `AccountId should be greater than 32 characters`;
const tooLong = `AccountId should be smaller than 64 characters`;
const invalidMasterAccount = `Invalid Master Account Id provided`;

const checkDescendant = (account, masterAccount) => {
  return arraysEqual(account.split(".").slice(1), masterAccount.split("."));
};

const checkForErrors = (accountId, options, loggedInAccount) => {
  let errors = [];

  const regexCheck =
    /^(([a-z\d]+[\-_])*[a-z\d]+\.)*([a-z\d]+[\-_])*[a-z\d]+$/im;

  if (!options.masterAccount) {
    errors.push(missingMaster);
    return errors;
  }

  if (!regexCheck.test(accountId)) {
    errors.push(invalidAccountId);
    return errors;
  }

  if (!regexCheck.test(options.masterAccount)) {
    errors.push(invalidMasterAccount);
    return errors;
  }

  if (!accountId.endsWith(".testnet")) {
    errors.push(accEndWithTestnet);
    return errors;
  }

  if (!options.masterAccount.endsWith(".testnet")) {
    errors.push(endWithTestnet);
    return errors;
  }

  if (!options.masterAccount.endsWith(loggedInAccount)) {
    const notAuthorised = `Not authorised to access the master account "${options.masterAccount}" Logged In account is ${loggedInAccount}`;
    errors.push(notAuthorised);
    return errors;
  }

  if (!checkDescendant(accountId, options.masterAccount)) {
    const notDescendent = `Provided accountId: ${accountId} is not a direct subaccount of the master account: ${options.masterAccount}`;
    errors.push(notDescendent);
    return errors;
  }

  if (accountId.length > 64) {
    errors.push(tooLong);
    return errors;
  }

  return errors;
};

const updateState = (type, message, setCustomState, setAcceptInput) => {
  if (type === "error") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(`&&${message}&&`)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  } else if (type === "success") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(message)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  }

  setAcceptInput(true);
};

const SEED_PATH = "m/44'/397'/0'";

export const generateSeedphraseCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(helpText),
    };
  } else {
    if (args.length === 1) {
      const errors = [missingCommand];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else {
      const [_, command, ...seed] = args;
      const { setAcceptInput, setCustomState } = stateChangeProps;
      if (command === "generate" && seed.length === 0) {
        const gen = generateSeedPhrase();
        const { publicKey, secretKey, seedPhrase } = gen;
        const text = `The following random seedphrase was generated:
        [[${seedPhrase}]]

[[Public Key]]: ${publicKey}
[[Secret Key]]: ${secretKey}
        `;
        return {
          output: CustomOutputFactory.createColoredRecord(text),
        };
      } else if (command === "parse" && seed.length === 12) {
        const phrase = seed.join(" ");
        const parseSeed = parseSeedPhrase(phrase, SEED_PATH);
        const { publicKey, secretKey, seedPhrase } = parseSeed;
        const text = `The following keypair are associated with the seedphrase:
[[Public Key]]: ${publicKey}
[[Secret Key]]: ${secretKey}

[[Provided Seedphrase]]: [[${seedPhrase}]]

        `;
        return {
          output: CustomOutputFactory.createColoredRecord(text),
        };
      } else {
        const errors = [insufficientArgs];
        const errorText = generateErrors(errors);
        return {
          output: CustomOutputFactory.createColoredRecord(errorText),
        };
      }
    }
  }
};

// setAcceptInput(false);

// context
//   .getStateAccount(accountId)
//   .then((res) => {
//     let type = "success";
//     let indent = 4;
//     let message = JSON.stringify(res, null, indent);
//     updateState(type, message, setCustomState, setAcceptInput);
//   })
//   .catch((e) => {
//     let type = "error";
//     let message = e.message;
//     updateState(type, message, setCustomState, setAcceptInput);
//   });
