import React from "react";
import { Paper } from "@mui/material";

export const Thought1 = ({ block = false }) => (
  <React.Fragment>
    <Paper
      style={{
        fontWeight: "bolder",
        background: "lightgreen",
        padding: "20px",
        maxWidth: "400px",
        lineHeight: "1.5rem",
        fontSize: "small",
      }}
    >
      Our goal in creating a blockchain is to ensure that the data inside each
      block that are added to the chain{" "}
      <span style={{ color: "#47307f", textDecoration: "underline" }}>
        cannot be easily tampered with
      </span>{" "}
      and in case they are somehow tampered, it should{" "}
      <span style={{ color: "#47307f", textDecoration: "underline" }}>
        clearly noticable
      </span>
      .
    </Paper>
  </React.Fragment>
);
