// Primers
import { start } from "./Primers/Start";

const primers = [...start];

const primerComponents = {};

const imageComponents = [];

const quizList = [];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
