export const intialEdges = [
  {
    id: "account->calculator",
    source: "account",
    target: "calculator",
    targetHandle: "account",
    // type: "custom",
    // data: { text: "100 bytes" },
    style: {
      stroke: "#A3ADB8",
      strokeWidth: 1.5,
    },
  },
  {
    id: "contract->calculator",
    source: "contract",
    target: "calculator",
    targetHandle: "contract",
    style: {
      stroke: "#A3ADB8",
      strokeWidth: 1.5,
    },
    animated: true,
  },
  {
    id: "accessKey->calculator",
    source: "accessKey",
    target: "calculator",
    targetHandle: "accessKey",
    style: {
      stroke: "#A3ADB8",
      strokeWidth: 1.5,
    },
    animated: true,
  },
  {
    id: "storage->calculator",
    source: "storage",
    target: "calculator",
    targetHandle: "storage",
    style: {
      stroke: "#A3ADB8",
      strokeWidth: 1.5,
    },
    animated: true,
  },
];
