import React from "react";
import { sha256 } from "js-sha256";
import { HashTextField, CssTextField } from "./styledComponents";
import { Paper } from "@mui/material";

const Hash = ({ num = 1, content = "Some data" }) => {
  const [data, setData] = React.useState(content);

  return (
    <div style={{ maxWidth: "500px", margin: "30px 10px" }}>
      <Paper
        elevation={3}
        sx={{ padding: "30px", display: "flex", flexDirection: "column" }}
      >
        <CssTextField
          label="Data"
          multiline
          helperText={`Block-${num}`}
          rows={4}
          value={data}
          onChange={(evt) => setData(evt.target.value)}
        />
      </Paper>
    </div>
  );
};

export default Hash;
