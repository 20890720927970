export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, in the last lesson, we looked at executing a transaction that alters the state of the account",
        'To do so, an account has to pay the computational cost incurred which is measured in "Gas Units"',
        'To understand, how "Gas units" in detail let\'s first recap what we know about "transactions"',
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Transactions",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ChargeMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "The transaction fees charged by the network is paid using the native Ⓝ Near Tokens",
        "Next, let's understand how gas fees are calculated",
      ],
      primerAvatar: "dollar",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "GasRuntime",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "FunctionMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "It's difficult to calculate beforehand how much computational effort is going to be used in the execution of a function",
        "Therefore, gas units to execute a smart contract change method differ",
        "Let's understand this better by learning NEAR Protocol executes transactions",
      ],
      primerAvatar: "gears",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "ChangeInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "FeesMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'Most of the transaction fees collected by the NEAR Protocol Network is "burnt" or destroyed',
        "Burning a token means taking it out of the circulating supply",
        "This also indirectly increases the value of the token held by the validators",
        "Let's understand why this is so.",
      ],
      primerAvatar: "recycle",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Incentives",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "GasMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "We have covered a great deal about transactions and gas in this lesson",
        "Since, gas is probably one of the most important topic while developing applications on NEAR Protocol...",
        "...let's recap what we have learnt so far",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Recap",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "This brings us to the end of this lesson",
        "In the next lesson, we will start working on creating our first contract",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "wave",
    },
  },
];
