import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { RoughNotation } from "react-rough-notation";
import regexifyString from "regexify-string";

const src = `https://assets.primerlabs.io/PrimerAvatar150/policeman.png`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// open={showProfanity}
// handleClose={() => {
//   setProfaneText(null);
//   setShowProfanity(false);
// }}
// continueSubmit={continueSubmit}
// text={profaneText}

export default function TextProfanityDialog({
  open,
  handleClose,
  continueSubmit,
  text,
}) {
  const modifiedChildren = regexifyString({
    pattern: /\[(.*?)\]/gim,
    decorator: (match, index) => {
      const re = /\[(.*?)\]/gim;
      const badWord = re.exec(match)["1"];
      return (
        <RoughNotation
          key={`item-${index}`}
          animationDelay={1000}
          color="coral"
          type="circle"
          show={open}
        >
          {badWord}
        </RoughNotation>
      );
    },
    input: text,
  });

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ fontSize: "2rem", textAlign: "center" }}>
          {"Profanity 🤬  Detected"}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <div style={{ margin: "auto", padding: "20px" }}>
              <img src={src} style={{ height: "200px" }} />
            </div>
            <DialogContentText
              as="div"
              id="alert-dialog-slide-description"
              style={{ padding: "20px", textAlign: "center" }}
            >
              <span>
                I have detected some profanity in your following reply.
              </span>
              <br />
              <br />
              <blockquote
                style={{
                  borderLeft: "3px solid indigo",
                  color: "indigo",
                  paddingLeft: "15px",
                  textAlign: "left",
                }}
              >
                {modifiedChildren}
              </blockquote>
              <br />
              <br />
              <span>
                Now, if you believe this is quite incorrect you can either edit
                your response or continue anway
              </span>
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            marginBottom: "20px",
          }}
        >
          <Button
            sx={{ height: "3rem", fontSize: "0.8rem" }}
            variant="outlined"
            onClick={handleClose}
          >
            Edit Response
          </Button>
          <Button
            sx={{ height: "3rem", fontSize: "0.8rem" }}
            variant="contained"
            onClick={() => {
              continueSubmit();
              handleClose();
            }}
          >
            Continue Anyway
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
