import React from "react";
import AccessKeysExplorable from "../../AccessKeysExplorable";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Paper } from "@mui/material";
import {
  keyRotationKeys as accountkeys,
  initialAccessKeys as accessKeys,
} from "../../AccessKeysExplorable/keysData";
import useSound from "use-sound";
import { AntTab, AntTabs } from "../../../../Blockchain/styledComponents";

const getExercise = (id) =>
  ({
    1: (
      <span>
        Generate a local keypair with the{" "}
        <code>
          seedphrase=crop deposit travel swift circle escape notice ability all
          monitor whale genre
        </code>{" "}
        on the <code>testnet</code> network
      </span>
    ),
    2: (
      <span>
        Add the above generated <code>access_key</code> to the{" "}
        <code>primerlabs.testnet</code> account as a{" "}
        <code>full access_key</code>
      </span>
    ),
    3: (
      <span>
        Edit the name of the file <code>primerlabs.testnet.json</code> to{" "}
        <code>old-primerlabs.testnet.json</code>
      </span>
    ),
    4: (
      <span>
        Edit the name of the newly generated file to{" "}
        <code>primerlabs.testnet.json</code>
      </span>
    ),
    5: (
      <span>
        Delete the previous <code>public_key</code> stored in the{" "}
        <code>old-primerlabs.testnet.json</code>
      </span>
    ),
  }[id]);

const ExerciseBox = ({ id, filled = false }) => (
  <Alert severity={filled ? "success" : "error"}>{getExercise(id)}</Alert>
);

const GenerateKeyPair = () => {
  const [completed, setCompleted] = React.useState({});
  const [played, setPlayed] = React.useState({});
  const [initialAccessKeys, setInitialAccessKeys] = React.useState(accessKeys);
  const [initialAccountKeys, setInitialAccountKeys] =
    React.useState(accountkeys);

  const [type, setType] = React.useState("GENERATE");

  const handleChangeTab = (event, newValue) => setType(newValue);

  const [playCorrect] = useSound("/sounds/correct.mp3", { volume: 0.1 });

  const explorableProps = {
    defaultAccessKeys: accessKeys,
    defaultAccountKeys: accountkeys,
    setInitialAccessKeys,
    setInitialAccountKeys,
  };

  React.useEffect(() => {
    // if (Object.values(completed).every((x) => x === true)) {
    //   playCorrect();
    // }

    if (
      Object.values(completed).filter((x) => x === true).length >
      Object.values(played).filter((x) => x === true).length
    ) {
      playCorrect();
    }
    setPlayed(completed);
  }, [completed]);

  React.useEffect(() => {
    // 1: Local Keypair generated
    const localKeyPairGenerated = initialAccessKeys.some(
      (x) =>
        x.network === "testnet" &&
        x.publicKey === "ed25519:HCjFC4VdPPHAHh7W5LjEqfjvWUDtLivXmkKDUpcdtnhr"
    );

    // 2: Add the Key Generated

    const keyAdded = initialAccountKeys["testnet"].some(
      (x) =>
        x.public_key ===
          "ed25519:HCjFC4VdPPHAHh7W5LjEqfjvWUDtLivXmkKDUpcdtnhr" &&
        x.access_key.permission === "FullAccess"
    );

    // 3: Edit the name of the file primerlabs.testnet.json to

    const changePreviousFileName = initialAccessKeys.find(
      (x) =>
        x.publicKey ===
          "ed25519:3X7fBScNcW46p9mRk1Pf3nCEdqUC3nJPaQy9Henpuq8K" &&
        x.account_id !== "primerlabs.testnet"
    );

    // 4: Edit the name of the newly generated file to primerlabs.testnet.json

    const changeNewFile = initialAccessKeys.find(
      (x) =>
        x.publicKey ===
          "ed25519:HCjFC4VdPPHAHh7W5LjEqfjvWUDtLivXmkKDUpcdtnhr" &&
        x.account_id === "primerlabs.testnet"
    );

    // 5: Delete previous public_key

    const deletePreviousKey =
      initialAccountKeys["testnet"].filter(
        (x) =>
          x.public_key ===
          "ed25519:3X7fBScNcW46p9mRk1Pf3nCEdqUC3nJPaQy9Henpuq8K"
      ).length === 0;

    setCompleted({
      1: localKeyPairGenerated,
      2: keyAdded,
      3: changePreviousFileName,
      4: changeNewFile,
      5: deletePreviousKey,
    });
  }, [initialAccessKeys, initialAccountKeys]);

  return (
    <Paper elevation={4} style={{ padding: "20px", borderRadius: "10px" }}>
      <h2 style={{ textAlign: "center", color: "#47307f" }}>Exercises</h2>
      <Stack sx={{ width: "100%", marginBottom: "20px" }} spacing={2}>
        <ExerciseBox id={"1"} filled={completed[1]} />
        <ExerciseBox id={"2"} filled={completed[2]} />
        <ExerciseBox id={"3"} filled={completed[3]} />
        <ExerciseBox id={"4"} filled={completed[4]} />
        <ExerciseBox id={"5"} filled={completed[5]} />
      </Stack>
      <div style={{ marginBottom: "20px" }}>
        <AntTabs
          style={{ marginBottom: "30px" }}
          value={type}
          onChange={handleChangeTab}
          centered
        >
          <AntTab
            value={"GENERATE"}
            label="Generate Key Pair"
            style={{ fontWeight: "bold" }}
          />
          <AntTab
            value={"ADD"}
            label="Add Key Pair"
            style={{ fontWeight: "bold" }}
          />
          <AntTab
            value={"REMOVE"}
            label="Delete Key Pair"
            style={{ fontWeight: "bold" }}
          />
        </AntTabs>
        <AccessKeysExplorable type={type} {...explorableProps} />
      </div>
    </Paper>
  );
};

export default GenerateKeyPair;
