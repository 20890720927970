import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "A highly-available database to store songs",
    "A front end interface for users to select and play songs",
    "A mechanism for artist to get paid for each stream",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, for our <b>decentralised spotify</b> which of the
          following component would be required?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
