export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "In this lesson, we will create a front end for another smart contract",
        "Let's take a look at the details of the smart contract",
      ],
      primerAvatar: "normal",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DonationsInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "WalletMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Since, blockchain-based applications involve money, we need to take special care to ensure that our users don't incur any financial loss",
        "Let's continue to build our \"Donations DApp\" by first desigining it's mockup",
      ],
      primerAvatar: "dollar",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DonationsInfo2",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "AccountMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'To query the account balance of an account, we can use "near-api-js" to call the blockchain',
        'For our "Donations DApp", the process of instantiating the "wallet" object will be pretty similar to our previous "Book Rating DApp" ',
        'This time, we will also instantiate a "nearConnection" object to use the "near-api-js" directly.',
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DonationsInfo3",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "FrontMatch",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'Now that we have successfully instantiated both the  "wallet" and "nearConnetion" object... ',
        "...let's start using them to write the core logic of our application ",
      ],
      primerAvatar: "gentleman",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DonationsInfo4",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "WalletSelectorMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Unlike our previous DApp, we are notifying the users by fetching the recent donation, once they successfully sign off the transaction",
        "You can get a better idea by checking out the Donation DApp below.",
      ],
      primerAvatar: "love",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DonationsInfo5",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "And with this we have come to the end of this quest on Creating Decentralised Applciations.",
        "Hopefully, making DApps on NEAR Protocol has become little bit easier for you now.",
        "Congratulations {{user}} for the completing this quest and good luck in your future blockchain adventures",
      ],
      primerAvatar: "celebrate",
    },
  },
];
