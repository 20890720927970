import React, { useState } from "react";
import { sha256 } from "js-sha256";
import { CenteredLayout } from "../../../../Quiz/layouts/Layout";
import { Paper, Button } from "@mui/material";
import { HashTextField, CssTextField } from "../../../styledComponents";
import keypair from "keypair";
import useSound from "use-sound";
import Alert from "@mui/material/Alert";
import { AntTab, AntTabs } from "../../../styledComponents";
import Stack from "@mui/material/Stack";
import Explorable from "./SignatureExplorable";
import eccrypto from "eccrypto";
import toast from "react-hot-toast";

const ExerciseBox = ({ id, filled = false }) => (
  <Alert severity={filled ? "success" : "error"}>{getExercise(id)}</Alert>
);

const Signature = () => {
  const [completed, setCompleted] = React.useState({});
  const [type, setType] = React.useState("GENERATE");
  const [played, setPlayed] = React.useState({});
  const [playCorrect] = useSound("/sounds/correct.mp3", { volume: 0.1 });
  const [playWrong] = useSound("/sounds/wrong.mp3", { volume: 0.1 });

  const [keyPair, setKeypair] = React.useState({
    alice: {
      privateKey: "",
      publicKey: "",
    },
    bob: {
      privateKey: "",
      publicKey: "",
    },
  });
  const [editPubKey, setEditPubKey] = React.useState("");
  const [editPrivKey, setEditPrivKey] = React.useState("");
  const [signPrivKey, setSignPrivKey] = React.useState("");
  const [signPubKey, setSignPubKey] = React.useState("");
  const [encyptedMessage, setEncryptedMessage] = React.useState({
    ciphertext: "",
  });
  const [decryptedMessage, setDecryptedMessage] = React.useState("");
  const [message, setMessage] = React.useState(
    "Hi Bob, This message is for your eyes only. Regards Alice"
  );

  const [signature, setSignature] = React.useState("");

  const [messageVerified, setMessageVerified] = React.useState(false);

  const handleChangeTab = (event, newValue) => setType(newValue);

  React.useEffect(() => {
    const completed = {
      1: keyPair.alice.publicKey !== "" && keyPair.bob.publicKey !== "",
      2: encyptedMessage.ciphertext !== "",
      3: signature !== "",
      4: decryptedMessage !== "",
      5: messageVerified,
    };
    setCompleted(completed);
  }, [keyPair, encyptedMessage, decryptedMessage, messageVerified, signature]);

  React.useEffect(() => {
    if (
      Object.values(completed).filter((x) => x === true).length >
      Object.values(played).filter((x) => x === true).length
    ) {
      playCorrect();
    }
    setPlayed(completed);
  }, [completed]);

  const generateKeyPair = () => {
    var privateKey1 = eccrypto.generatePrivate();
    var publicKey1 = eccrypto.getPublic(privateKey1).toString("hex");
    var privateKey2 = eccrypto.generatePrivate();
    var publicKey2 = eccrypto.getPublic(privateKey2).toString("hex");

    setKeypair({
      alice: {
        privateKey: privateKey1,
        publicKey: publicKey1,
      },
      bob: {
        privateKey: privateKey2,
        publicKey: publicKey2,
      },
    });
  };

  const encryptMessage = async () => {
    if (editPubKey !== keyPair.bob.publicKey) {
      playWrong();
      toast.error(`Encode the message using Bob's public Key`, {
        id: "bob",
      });
    } else {
      setEncryptedMessage({
        ciphertext: "",
      });
      const enc = new TextEncoder();
      const encrypted = eccrypto
        .encrypt(Buffer.from(editPubKey, "hex"), enc.encode(message))
        .then((message) => {
          setEncryptedMessage(message);
        })
        .catch((e) => {
          playWrong();
          toast.error(`Encountered Error: ${e.message}`, {
            id: "error",
          });
        });
    }
  };

  const decryptMessage = async () => {
    setDecryptedMessage("");
    const decrypted = eccrypto
      .decrypt(Buffer.from(editPrivKey, "hex"), encyptedMessage)
      .then((message) => {
        setDecryptedMessage(message.toString());
      })
      .catch((e) => {
        playWrong();
        toast.error(`Encountered Error: ${e.message}`, {
          id: "error2",
        });
      });
  };

  const signMessage = () => {
    if (signPrivKey !== keyPair.alice.privateKey.toString("hex")) {
      playWrong();
      toast.error(`Sign the message using Alice's Private Key`, {
        id: "alice",
      });
    } else {
      eccrypto
        .sign(Buffer.from(signPrivKey, "hex"), sha256.digest(message))
        .then(function (sig) {
          setSignature(sig.toString("hex"));
        })
        .catch((e) => {
          playWrong();
          toast.error(`Encountered Error: ${e.message}`, {
            id: "error3",
          });
        });
    }
  };

  const verifyMessage = () => {
    eccrypto
      .verify(
        Buffer.from(signPubKey, "hex"),
        sha256.digest(decryptedMessage),
        signature
      )
      .then(function (sig) {
        setMessageVerified(true);
      })
      .catch((e) => {
        setMessageVerified(false);
        playWrong();
        toast.error(`Encountered Error: ${e.message}`, {
          id: "error3",
        });
      });
  };

  const explorableOptions = {
    generateKeyPair,
    keyPair,
    editPubKey,
    setEditPubKey,
    editPrivKey,
    setEditPrivKey,
    encyptedMessage,
    setEncryptedMessage,
    encryptMessage,
    decryptedMessage,
    setDecryptedMessage,
    decryptMessage,
    message,
    setMessage,
    signPrivKey,
    setSignPrivKey,
    signPubKey,
    setSignPubKey,
    signMessage,
    signature,
    verifyMessage,
    setDecryptedMessage,
    messageVerified,
  };

  return (
    <CenteredLayout>
      <Paper style={{ padding: "20px" }}>
        <h2 style={{ textAlign: "center", color: "#47307f" }}>Exercises</h2>
        <Stack sx={{ width: "100%", marginBottom: "20px" }} spacing={2}>
          <ExerciseBox id={"1"} filled={completed[1]} />
          <ExerciseBox id={"2"} filled={completed[2]} />
          <ExerciseBox id={"3"} filled={completed[3]} />
          <ExerciseBox id={"4"} filled={completed[4]} />
          <ExerciseBox id={"5"} filled={completed[5]} />
        </Stack>
      </Paper>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "10px auto",
          justifyContent: "center",
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <AntTabs
            style={{ marginBottom: "30px" }}
            value={type}
            onChange={handleChangeTab}
            centered
          >
            <AntTab
              value={"GENERATE"}
              label="Generate Key Pair"
              style={{ fontWeight: "bold" }}
            />
            <AntTab
              value={"ENCRYPT"}
              label="Encrypt Message"
              style={{ fontWeight: "bold" }}
            />
            <AntTab
              value={"SIGN"}
              label="Sign Message"
              style={{ fontWeight: "bold" }}
            />
            <AntTab
              value={"DECRYPT"}
              label="Decrypt Message"
              style={{ fontWeight: "bold" }}
            />
            <AntTab
              value={"VERIFY"}
              label="Verify Message"
              style={{ fontWeight: "bold" }}
            />
          </AntTabs>
        </div>
        <Explorable type={type} {...explorableOptions} />
      </div>
    </CenteredLayout>
  );
};

export default Signature;

const getExercise = (id) =>
  ({
    1: (
      <span>
        Generate a <b>private</b> and <b>public</b> keypair for both{" "}
        <b>Alice</b> and <b>Bob</b>
      </span>
    ),
    2: (
      <span>
        <b>Encrypt</b> the message using <b>Bob's Public Key</b>
      </span>
    ),
    3: (
      <span>
        <b>Sign</b> the message hash using the <b>Alice's Private Key</b>
      </span>
    ),
    4: (
      <span>
        <b>Decrypt</b> the encrypted message using the corresponding{" "}
        <b>Private Key</b>
      </span>
    ),
    5: (
      <span>
        <b>Verify</b> that the source of the message came from <b>Alice</b>{" "}
        using her <b>public key</b>
      </span>
    ),
  }[id]);
