import React from "react";
import { Paper, Box, Button, StepContext } from "@mui/material";
import { sha256 } from "js-sha256";
import {
  CssTextField,
  HashTextField,
  StyledTooltip,
  TxTextField,
} from "./styledComponents";
import LoadingButton from "@mui/lab/LoadingButton";
import { useWorker, WORKER_STATUS } from "@koale/useworker";
import { coinbaseMineNonce } from "./workers";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { SignedTxContext, calculateTransaction } from "./SignedTxBlockchain";
import InputAdornment from "@mui/material/InputAdornment";

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      sx={{ color: "black", fontWeight: "bold" }}
      {...props}
    />
  );
});

const Block = React.memo(({ prev, id }) => {
  //   const [data, setData] = React.useState("Hi there!");
  //   const [block, setBlock] = React.useState(1);
  //   const [nonce, setNonce] = React.useState(452);

  const { peerChain, peerHash, setPeerChain, peers, peer } =
    React.useContext(SignedTxContext);

  // const chain = peerChain[peers[peer]];
  // const hash = peerHash[peers[peer]];
  const coinbase = peerChain[peers[peer]].find((x) => x.id === id).coinbase;

  const blockNumber = peerChain[peers[peer]].find(
    (x) => x.id === id
  ).blockNumber;
  const nonceValue = peerChain[peers[peer]].find((x) => x.id === id).nonceValue;
  // const dataValue = peerChain[peers[peer]].find((X) => X.id === id).dataValue;

  const txValue = peerChain[peers[peer]].find((x) => x.id === id).tx;

  const [error, setError] = React.useState(false);
  const [valid, setValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  let blockhash = `block${blockNumber}nonce${nonceValue}coin${coinbase.amt}${
    coinbase.miner
  }data${calculateTransaction(txValue)}prev${
    prev ? peerHash[peers[peer]][prev] : "0".repeat(64)
  }hash`;
  //   let blockhash = `block${blockNumber}nonce${nonceValue}data${dataValue}hash`;
  const [mineWorker] = useWorker(coinbaseMineNonce, {
    remoteDependencies: [
      "https://cdnjs.cloudflare.com/ajax/libs/js-sha256/0.9.0/sha256.min.js",
    ],
  });

  const checkValidity = (difficulty = 4) => {
    let pattern = "0".repeat(difficulty);
    return sha256(blockhash).substring(0, difficulty) === pattern;
  };

  const setBlock = (val) => {
    setPeerChain((peerChain) => {
      const peerIndex = peers[peer];
      return {
        ...peerChain,
        [peerIndex]: peerChain[peerIndex].map((x) =>
          x.id === id ? { ...x, blockNumber: parseInt(val) } : x
        ),
      };
    });
  };

  const setCoinbase = (val, type) => {
    setPeerChain((peerChain) => {
      const peerIndex = peers[peer];
      return {
        ...peerChain,
        [peerIndex]: peerChain[peerIndex].map((x) =>
          x.id === id ? { ...x, coinbase: { ...x.coinbase, [type]: val } } : x
        ),
      };
    });
  };

  const setNonce = (val) => {
    setPeerChain((peerChain) => {
      const peerIndex = peers[peer];
      return {
        ...peerChain,
        [peerIndex]: peerChain[peerIndex].map((x) =>
          x.id === id ? { ...x, nonceValue: val } : x
        ),
      };
    });
  };

  const setTx = (val, tid, type) => {
    setPeerChain((peerChain) => {
      const peerIndex = peers[peer];
      return {
        ...peerChain,
        [peerIndex]: peerChain[peerIndex].map((x) => {
          if (x.id === id) {
            return {
              ...x,
              tx: x.tx.map((t) => (t.id === tid ? { ...t, [type]: val } : t)),
            };
          } else {
            return x;
          }
        }),
      };
    });
  };

  React.useEffect(() => {
    if (checkValidity()) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [blockNumber, nonceValue, txValue, peerHash[peers[peer]]]);

  const runMining = async () => {
    setLoading(true);
    const prevHash = prev ? peerHash[peers[peer]][prev] : "0".repeat(64);
    const result = await mineWorker(
      blockNumber,
      calculateTransaction(txValue),
      prevHash,
      coinbase,
      4
    );
    if (result !== -1) {
      setNonce(result);
      setLoading(false);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <div style={{ maxWidth: "500px", margin: "30px auto" }}>
      <Paper
        elevation={3}
        sx={{
          padding: "30px",
          display: "flex",
          flexDirection: "column",
          background: valid ? "lightgreen" : "#ff7f5061",
          margin: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <HashTextField
            type="number"
            label="Block"
            value={blockNumber}
            onChange={(evt) => setBlock(evt.target.value)}
          />

          <HashTextField
            label="Nonce"
            value={nonceValue}
            onChange={(evt) => setNonce(evt.target.value)}
          />
        </Box>

        <span
          style={{
            margin: "5px auto",
            fontFamily: "Nunito",
            fontWeight: "bold",
            color: "#47307f",
            fontSize: "0.8rem",
          }}
        >
          Coinbase Transaction
        </span>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TxTextField
            label="Amount"
            type="number"
            value={coinbase.amt}
            onChange={(evt) => setCoinbase(evt.target.value, "amt")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₿</InputAdornment>
              ),
            }}
          />
          <TxTextField
            label="Miner"
            value={coinbase.miner}
            onChange={(evt) => setCoinbase(evt.target.value, "miner")}
          />
        </Box>

        <span
          style={{
            margin: "5px auto",
            fontFamily: "Nunito",
            fontWeight: "bold",
            color: "#47307f",
            fontSize: "0.8rem",
          }}
        >
          Transactions
        </span>

        {txValue.map((t, idx) => (
          <div
            key={t.id}
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid gray",
              justifyContent: "space-around",
              alignItems: "space-between",
              margin: "5px auto",
              borderRadius: "12px",
              padding: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                margin: "5px auto",
                alignItems: "center",
                "& > :not(style)": { m: 1 },
              }}
            >
              <TxTextField
                type="number"
                value={t.amt}
                label="Amount"
                onChange={(evt) => setTx(evt.target.value, t.id, "amt")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₿</InputAdornment>
                  ),
                }}
              />

              <TxTextField
                label="Signature"
                value={t.sig}
                onChange={(evt) => setTx(evt.target.value, t.id, "sig")}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                margin: "5px auto",
                alignItems: "center",
                "& > :not(style)": { m: 1 },
              }}
            >
              <TxTextField
                label="Sender"
                value={t.from}
                onChange={(evt) => setTx(evt.target.value, t.id, "from")}
              />
              <TxTextField
                label="Receiver"
                value={t.to}
                onChange={(evt) => setTx(evt.target.value, t.id, "to")}
              />
            </Box>
          </div>
        ))}
        <br />

        <HashTextField
          label="Previous Hash Value"
          value={prev ? peerHash[peers[peer]][prev] : "0".repeat(64)}
        />
        <br />
        <HashTextField label="Hash Value" value={peerHash[peers[peer]][id]} />
        <div style={{ padding: "10px", textAlign: "center" }}>
          <LoadingButton
            onClick={runMining}
            sx={{ height: "3rem" }}
            variant="outlined"
            loading={loading}
            loadingIndicator="Mining..."
          >
            Mine
          </LoadingButton>
        </div>
      </Paper>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          sx={{
            width: "100%",
            color: "black",
            fontWeight: "bold",
            fontFamily: "Nunito",
          }}
        >
          Unable to find the nonce for a valid block. Try changing some data.
        </Alert>
      </Snackbar>
    </div>
  );
});

export default Block;
