import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AntTabs, AntTab } from "./Tabs";
{
  /* <span style={{ fontSize: "3rem" }}>{"\u201C"}</span>
 <span style={{ fontSize: "3rem" }}>{"\u201D"}</span> */
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography
            component="div"
            sx={{ color: "#47307f", display: "flex" }}
          >
            <span style={{ fontSize: "80px", margin: "0px", padding: "0px" }}>
              {"\u201C"}
            </span>
            <blockquote style={{ paddingTop: "10px", fontSize: "small" }}>
              {children}
            </blockquote>
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabPanelStyles = {
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  color: "#47307f",
};

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        border: "none",
        maxHeight: 450,
      }}
    >
      <AntTabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderBottom: "none",
          borderRight: 1,
          borderColor: "divider",
          minWidth: "250px",
        }}
      >
        <AntTab label="Incentives" {...a11yProps(0)} />
        {/* <AntTab label="Uptime" {...a11yProps(1)} /> */}
        <AntTab label="Data Integrity" {...a11yProps(2)} />
        <AntTab label="Scalability" {...a11yProps(3)} />
        <AntTab label="Technology" {...a11yProps(4)} />
        <AntTab label="Governance" {...a11yProps(5)} />
        {/* <AntTab label="Trust" {...a11yProps(6)} /> */}
      </AntTabs>
      {/* <TabPanel style={tabPanelStyles} value={value} index={6}>
        In a decentralised cloud-computing infrastruture, any one can offer to
        become to one of the participants in the infrastructure anonymously.
        Since, the infrastructure is permissionless, any malicious operator can
        choose to participate and disrupt the platform's functioning. In this
        case, how do we ensure that these anonymous participants{" "}
        <b>trust each other and co-operate to securely maintain the platform</b>
        ?
      </TabPanel> */}
      <TabPanel style={tabPanelStyles} value={value} index={0}>
        How to ensure that the participants of the platform are properly{" "}
        <b>incentivised</b> to correctly run the platform? Since, any
        participants can join and leave at will, what happens when participants
        <b> leave abruptly</b>. Or worse, what if participants{" "}
        <b>attempt to tamper the applications</b> running on the platform? What{" "}
        <b>incentivises</b> them to correctly perform their part?
      </TabPanel>
      {/* <TabPanel style={tabPanelStyles} value={value} index={1}>
        An important part of the being a cloud-computing platform is to provide{" "}
        <b>uptime gurraantee</b>, which refers to the amount of time, an
        application hosted on the platform is accessible to end users. How can a
        decentralised cloud-computing platform ensure{" "}
        <b>24/7 uptime gurraantee</b>?
      </TabPanel> */}
      <TabPanel style={tabPanelStyles} value={value} index={1}>
        How can the platform ensure that the <b>data and code</b> hosted on its
        platform, have not been <b>modified or deleted</b> by an unauthorised
        third-party?
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={2}>
        In a decentralised cloud-computing network,{" "}
        <b>the number of participants is a bottle-neck</b> for the maximum
        number of traffic can an application hosted on the platform handle. This
        means that platform has to ensure that a large number of participants
        can join the platform offering their compute and storage power quite
        effortlessly and are properly incentivised to do so. How can the
        platform ensure that cloud-computing infrastructure can{" "}
        <b>scale to accomodate higher traffic requirements</b> of some of the
        applications?
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={3}>
        For a cloud-computing infrastructure to be functional, there are several
        technical challenges to be solved.{" "}
        <ul>
          <li>
            How do the participants communicate with each other and know{" "}
            <b>which code is to be executed</b> and{" "}
            <b>who will be responsbile for executing it</b>?
          </li>
          <li>
            How do developers <b>deploy their applciations</b> into the the
            platform?
          </li>
          <li>
            How to provide a <b>predicable code execution</b> on a platform
            where all the participants have various different computing device?
          </li>
        </ul>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={4}>
        Since a protocol is just a specification, who will be responsible for
        <b> updating the protocol specification</b> according to the future
        requirements. And finally, if there are critical vulnerabilities are
        detected, who would be responsible for updating the protocol and the
        <b> underlying implementation</b>?
      </TabPanel>
    </Box>
  );
}
