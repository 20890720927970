import {
  StyledTableRow,
  StyledTableCell,
} from "../../../../../components/Common/Table.jsx";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";

const BlockTimeTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "900px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Action
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Command
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Description
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">Build Contract</StyledTableCell>
            <StyledTableCell variant="body">
              <code>npm run build</code>
            </StyledTableCell>

            <StyledTableCell variant="body">
              Compiles the contract into <code>wasm</code> and stores it in the{" "}
              <code>contract/build/hello_near.wasm</code>{" "}
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">Deploy Contract</StyledTableCell>
            <StyledTableCell variant="body">
              <code>npm run deploy</code>
            </StyledTableCell>

            <StyledTableCell variant="body">
              Deploys the compiled smart contract to NEAR Protocol using a{" "}
              <code>developer account</code>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlockTimeTable;
