import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AntTabs, AntTab } from "./Tabs";
{
  /* <span style={{ fontSize: "3rem" }}>{"\u201C"}</span>
 <span style={{ fontSize: "3rem" }}>{"\u201D"}</span> */
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography sx={{ color: "#47307f", display: "flex" }}>
            <span style={{ fontSize: "80px", margin: "0px", padding: "0px" }}>
              {"\u201C"}
            </span>
            <blockquote style={{ paddingTop: "10px", fontSize: "small" }}>
              {children}
            </blockquote>
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabPanelStyles = {
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  color: "#47307f",
};

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        display: "inline-block",
        bgcolor: "background.paper",
        border: "none",
      }}
    >
      <AntTabs
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderBottom: "none",
          borderRight: 1,
          borderColor: "divider",
          minWidth: "250px",
        }}
      >
        <AntTab label="Heavy reliance on Normality" {...a11yProps(0)} />
        <AntTab label="Higher Risk of Bank Runs" {...a11yProps(1)} />
        <AntTab label="Record of Historical Failures" {...a11yProps(2)} />
      </AntTabs>
      <TabPanel style={tabPanelStyles} value={value} index={0}>
        The financial system goes through many ups and downs, but{" "}
        <b>fractional banking</b> works best in times of{" "}
        <b>relative normalcy</b>. During a financial crisis,{" "}
        <b>a bank's balance sheet can end up totally disrupted</b> in a way that
        would have been <b>unpredictable</b> at an earlier time.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={1}>
        <b>Bank Run</b>: A <b> bank run </b>occurs when a large number of
        customers of a bank or other financial institution{" "}
        <b>withdraw their deposits simultaneously</b> over concerns of the
        bank's solvency.
        <br />
        <br />
        If everyone demands their deposit money back at the same time, the bank
        will swiftly <b>run out of excess reserves</b> and can result in{" "}
        <b>bank failure.</b>
        It's easier to avoid such an outcome if banks keep all the money someone
        deposits safely in a vault rather than lending most of it out.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={2}>
        There has been many instancs of <b>bank runs</b> in recent history.{" "}
        <b>The Great Depression</b>, in particular, stands as a cautionary tale
        about the perils of <b>unregulated fractional reserve banking</b>.
        <br />
        <br />
        After the stock exchange crash of 1929,{" "}
        <b>
          floods of people tried to withdraw all their money from banks at once
        </b>
        . This rendered multiple depository institutions completely insolvent,
        as they only had a small amount of reserve money on hand.
      </TabPanel>
    </Box>
  );
}
