export const start = [
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "Previously, we learnt that several independent actors running bitcoin nodes come together and collectively maintain the global decentralised ledger...",
        "...that forms of the backbone of the Bitcoin Network",
        "{{user}}, what incentives do you think actors have for running a Bitcoin Node?",
      ],
      primerAvatar: "question",
      textReply: {
        que: "What incentives do you think actors have for running a Bitcoin Node?",
      },
      userReply: { reply: null, userAvatar: "Smirk" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'The main motivation for the actors running the "nodes" is get a chance at winning the "coinbase reward"',
        "This means the partipating actors are driven to get a financial reward for the work",
        "Let's explore this idea bit further",
      ],
      primerAvatar: "dollar",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "NodesInfo",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "As you can see the miners run Bitcoin Nodes mostly due to the incentive of making money.",
        "Since miners are the ones maintaining the network, what do you think prevents them or any malicious actors from stealing bitcoins from an users account",
      ],
      primerAvatar: "puzzle",
      textReply: {
        que: "What prevents miners from stealing bitcoin?",
      },
      userReply: { reply: null, userAvatar: "Happy" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "The short answer is they can't act maliciously even if they want to.",
        'While the long answer is that every transaction from an account is "cryptographically secured" and the funds can only be accessed by the account owner.',
        "To understand this deeply, let's first take a look at how accounts work in a traditional bank.",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "BankMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Opening a traditional bank account requires a lot of information from the applicant.",
        "And even after providing all these information, it' upto the bank's discretion to allow a person to open a bank account.",
        "However, our global decentralised ledger allows just about anyone to create an account and start interacting immediately.",
        "How? Let's take a look, next.",
      ],
      primerAvatar: "patient",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "PublicInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "CaeserMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "The issue with encoding an message in this way, is you have to provide the necessary key to the receiver for them to decode the message.",
        "With the rise of internet, message encrpytion techniques evolved and became more standardized ",
        'And one of the important techniques that came out was the "public-key cryptography"',
      ],
      primerAvatar: "security-2",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "PublicKeyEncription",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "KeyMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Using Bob's Public Key, Alice can send him a encrypted message that only he can open",
        "Now, the question arises, how can Bob be sure that the message indeed came from Alice",
        "To understand that, we will take a look at Digital Signatures next.",
      ],
      primerAvatar: "support",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DigitalSignatures",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "SignaturesMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'At this point, you might be wondering about how "signatures" and "public keys" relate to creating accounts on the Bitcoin network',
        'Well, the answer is we can use the "public-keys" as accounts on the Bitcoin Network',
        "Let's understand that this in more detail",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "BitcoinTrx",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "BitcoinAccQuiz",
    },
  },

  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Since, creating a bitcoin address is quick and easy and anyone can do it",
        "At th same time, everybody generating their own private keypairs seems like a recipe for disaster.",
        "Therefore, next we will take a look at the understand the security aspect of the Bitcoin Addresses.",
      ],
      primerAvatar: "oops",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "WalletInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "WalletQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Earlier, we listed out a lot of problems regarding how a global decentralised ledger works",
        "Now, let's recap what we have learnt so far and revisit the problems to see how Bitcoin addresses them",
      ],
      primerAvatar: "normal",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "GlobalLedger",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "We have come to the end of this section on the using Blockchain as a ledger",
        "In the next section, we will focus more on the problems of Consensus",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "wave",
    },
  },
];

/*
  - Bitcoin Address [ ]
  - Wallet and Security [ ]
  - Bitcoin Recap [ ]
 */
