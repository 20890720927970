import { Typography } from "@mui/material";
import { RoughNotation } from "react-rough-notation";

const PizzaInfo = () => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div>
        <h1>Bitcoin Pizza Day</h1>
        On May 22 2010, a man named <b>Laszlo Hanyecz</b> posted the following{" "}
        <a href="https://bitcointalk.org/index.php?topic=137.0" target="_blank">
          message on the bitcointalk forum
        </a>
        :
        <div style={{ display: "flex" }}>
          <div
            style={{
              fontFamily: "Nunito",
              fontVariant: "all-small-caps",
              borderLeft: "3px solid #47307f",
              margin: "30px 15px",
              padding: "15px 30px",
            }}
          >
            I'll pay 10,000 bitcoins for a couple of pizzas.. like maybe 2 large
            ones so I have some left over for the next day. I like having left
            over pizza to nibble on later. You can make the pizza yourself and
            bring it to my house or order it for me from a delivery place, but
            what I'm aiming for is getting
            <RoughNotation
              animationDelay={1000}
              type="highlight"
              color="#ffac00"
              show
              style={{ whiteSpace: "nowrap" }}
            >
              <b>food delivered in exchange for bitcoins</b>
            </RoughNotation>
            where I don't have to order or prepare it myself, kind of like
            ordering a 'breakfast platter' at a hotel or something, they just
            bring you something to eat and you're happy!
            <br />
            ...
            <br />
            <br />
            If you're interested please let me know and we can work out a deal.
            <br />
            <br />
            Thanks, Laszlo
          </div>
        </div>
        <div>
          <div style={{ float: "left", margin: "20px" }}>
            <img
              style={{ height: "200px", margin: "auto" }}
              src="/images/topics/blockchain/pizzaBitcoin.svg"
            />
          </div>
          <div>
            <p>
              Eventually, someone took up on his offer and <b>Laszlo Hanyecz</b>{" "}
              happily traded <b>10,000 bitcoins</b> for <b>2 large pizzas</b>.
              <br />
              <br />
              Only nine months after the purchase, Bitcoin levelled with the
              U.S. dollar, making the two pizzas worth <b>$10,000.</b>
              <br />
              <br />
              And after a couple of years, the value of those 10,000 bitcoins
              were well worth more than <b>100 Million US Dollars.</b>
            </p>
            <p>
              Now, the event was interesting because of the most expensive pizza
              someone bought in the history of the world, in the hindsight of
              course. But the point, I would like to bring to your attention, is
              that it was the very first time, a{" "}
              <b>blockchain-based cryptocurrency</b> was traded for a real-world
              transaction.
            </p>
            <p>
              Henceforth, that day onwards, every year, <b>May 22nd</b> is
              celebrated as the <b>Bitcoin Pizza Day</b> by the crypto
              community.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PizzaInfo;
