import { OutputFactory, Outputs } from "javascript-terminal";
import { CustomOutputFactory } from "../outputs";
import { arraysEqual, getURL, getTxId } from "./utils";
import { utils, providers } from "near-api-js";

const helpText = `
**Usage**: &&near&& [[call]] @@<contractName>@@ @@<methodName>@@ **[args]** @@--accountId@@ **<accountId>** @@<options>@@                                                           

    ^^schedule smart contract call which can modify state^^


**Required Arguments**

    &&<contractName>&&
        ^^Account name of contract  [string]^^

    &&<methodName>&&
        ^^Method name to call [string]^^

     &&[args]&&
        ^^Arguments for the method [string]^^   

**Optional Arguments**:
    @@--gas@@                    ^^Max amount of gas this call can use (in gas units)^^
    @@--deposit, --amount@@      ^^Number of tokens to attach (in NEAR Ⓝ) to a function call^^
    @@--depositYocto@@           ^^Number of tokens to attach (in yoctoNEAR) to a function call^^
    @@--base64@@                 ^^Treat arguments as base64-encoded^^

**Example:**

    &&near&& [[call]] @@guest-book.testnet@@ @@addMessage@@ @@'{"text": "Aloha"}'@@ **--account-id** @@example-acct.testnet@@
        

**Notes**:
    1. Contract calls require a transaction fee (gas) so you will need an access key for the &&--accountId&& that will be charged.
    2. There are two ways to deal with methods that require empty [[{ args }]]. Either send [[{"field": null}]] or simply omit this field and pass in nothing: [[{}]]
     
`;

const generateErrors = (errors) => {
  let mappedText = errors.reduce((acc, curr) => {
    return acc + `\n\t&&` + " * " + curr + `&&`;
  }, ``);
  return `**Encountered Following Errors**
${mappedText}

**Usage**: &&near&& [[call]] @@<contractName>@@ @@<methodName>@@ **[args]** @@--accountId@@ **<accountId>** @@<options>@@    

    For more info type:
        &&near&& [[call]] @@--help@@ 

`;
};

const getSuccessMessage = (res, lastResponse) => {
  const hash = getTxId(res);
  const message = `
    Transaction Id ${hash}
    To see the transaction in the transaction explorer, please open this url in your browser (((https://explorer.testnet.near.org/transactions/${hash})))

[['${lastResponse}']]
    `;

  return message;
};

const wasmFileNotFound = `Specified Wasm file not found`;
const invalidCommandUsage = `Invalid Command Usage`;
const missingMaster = `Missing Master Account`;
const missingWasmFilePath = `Missing Wasm file path`;
const missingAccountID = `Missing Account ID`;
const invalidAccountId = `Invalid Account ID provided`;
const endWithTestnet = `Master account must end with testnet`;
const accEndWithTestnet = `Account Id must end with ".testnet"`;
const notLongEnough = `AccountId should be greater than 32 characters`;
const tooLong = `AccountId should be smaller than 64 characters`;
const invalidMasterAccount = `Invalid Master Account Id provided`;
const missingMethodName = `Missing Method name of the contract`;
const missingContractName = `Missing Contract name`;

const updateState = (type, message, setCustomState, setAcceptInput) => {
  if (type === "error") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(`&&${message}&&`)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  } else if (type === "success") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(message)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  }

  setAcceptInput(true);
};

const getErrors = (args) => {
  switch (args.length) {
    case 0:
      return [missingContractName, missingMethodName];
    case 1:
      return [missingContractName, missingMethodName];
    case 2:
      return [missingMethodName];
  }
};

export const callCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  const { setAcceptInput, setCustomState } = stateChangeProps;
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(helpText),
    };
  } else {
    if (args.length < 3) {
      const errorsList = getErrors(args);

      const errors = options.accountId
        ? [...errorsList]
        : [...errorsList, missingAccountID];

      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if ([3, 4].includes(args.length) && !options.accountId) {
      const errors = [missingAccountID];

      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    } else if ([3, 4, 5, 6, 7, 8].includes(args.length) && options.accountId) {
      // Call Function JS

      // Sample code: near call test1.siddharthkanungo.testnet helloWorld --accountId siddharthkanungo.testnet

      // near call test1.siddharthkanungo.testnet greet '{"name" : "Siddharth", "age" : 12}' --accountId siddharthkanungo.testnet

      // near call test1.siddharthkanungo.testnet greet '{"names":"Siddharth"}' --accountId siddharthkanungo.testnet

      const accountId = options.accountId;
      const [_, contractName, methodName, argString] = args;
      let targetArgs = "{}";

      if (argString) {
        const quoteRe = /(\x27)(.+?)(\x27)/gim;
        const quoteMatch = quoteRe.exec(argString);

        targetArgs = quoteMatch[2];
      }

      const commandOptions = {
        args: targetArgs,
        accountId: options.accountId,
        gas: options.gas || 300000000000000,
        deposit: options.deposit || options.amount || "0",
        depositYocto: options.depositYocto || null,
        base64: options.base64 || false,
      };

      setAcceptInput(false);

      context
        .callContract(contractName, methodName, commandOptions)
        .then((res) => {
          let type = res.type ? res.type : "success";
          const lastResponse = providers.getTransactionLastResult(res);
          const message = getTxId(res)
            ? getSuccessMessage(res, lastResponse)
            : JSON.stringify(res, null, 4);

          updateState(type, message, setCustomState, setAcceptInput);
        })
        .catch((e) => {
          let type = "error";
          let message = e.message;
          updateState(type, message, setCustomState, setAcceptInput);
        });

      const message =
        `Scheduling a call: ${contractName}.${methodName}(${
          options.args || ""
        })` +
        (options.deposit && options.deposit != "0"
          ? ` with attached ${utils.format.formatNearAmount(
              options.deposit
            )} NEAR`
          : "");

      return {
        output: CustomOutputFactory.createColoredRecord(message),
      };
    } else {
      const errors = [invalidCommandUsage];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    }
  }
};
