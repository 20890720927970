import { hashPrimers } from "./HashPrimers";
// Blockchain is a Database - Chain of Blocks

const dataPrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      primerAvatar: "oops",
      chats: [
        "{{user}}, technically, keeping previous block's data should work just fine as well",
        "However, this solution produces a lot of redundancy",
        "Let me show you why.",
      ],
      userReply: {
        reply: null,
        userAvatar: null,
      },
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DataInfo",
    },
  },

  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      primerAvatar: "patient",
      chats: [
        "Now once again, let's come back to the question.",
        "{{user}}, which of the following should be added in a block so that it's hash is dependent on it's previous block?",
      ],
      userReply: {
        reply: null,
        userAvatar: null,
      },
      buttonOptions: [],
    },
  },
];

const noncePrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      primerAvatar: "sorry",
      chats: [
        "Wrong, {{user}}.",
        "There is no utility of a nonce other than guessing a valid block",
        "Let's explore the idea for a bit anyway",
      ],
      userReply: {
        reply: null,
        userAvatar: null,
      },
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "NonceInfo",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "{{user}}, let's think for a second.",
        "Can you think of an obvious flaw of the system?",
      ],
      primerAvatar: "puzzle",

      textReply: { que: "Can you think of an obvious flaw of the system?" },
      userReply: { reply: null, userAvatar: "Shy" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      primerAvatar: "game",
      chats: [
        "Well, to understand the drawback of the system...,",
        "...we have to recall why needed the `nonce` in the first place",
      ],
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "AutoValidDataInfo",
    },
  },
  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      primerAvatar: "attention",
      chats: [
        "I hope, now you have understood why linking blocks with `nonces` is a bad idea.",
        "Now once again, let's come back to our original question.",
        "{{user}}, which of the following should be added in a block so that it's hash is dependent on it's previous block?",
      ],
      userReply: {
        reply: null,
        userAvatar: null,
      },
      buttonOptions: [],
    },
  },
];

const blockPrimers = [
  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      primerAvatar: "sad",
      chats: [
        "Nope, {{user}}. We use the block number simply to identify a block and it wouldn't help us detect any changes in the previous block's data ",
        "Now once again, let's come back to the question.",
        "{{user}}, which of the following should be added in a block so that it's hash is dependent on it's previous block?",
      ],
      userReply: {
        reply: null,
        userAvatar: null,
      },
      buttonOptions: [],
    },
  },
];

const button1Options = [
  {
    text: "Previous block's data",
    userReply: {
      reply: [
        "I believe ...",
        "...we should add the previous block's data...",
        "...to make a block dependent on it's previous block",
      ],
      userAvatar: "Cheeks",
    },
    primers: dataPrimers,
  },
  {
    text: "Previous block's nonce",
    userReply: {
      reply: [
        "Well, I think...",
        "...we should add the previous block's nonce...",
        "...to make a block dependent on it's previous block",
      ],
      userAvatar: "Cute",
    },
    primers: noncePrimers,
  },
  {
    text: "Previous block's number",
    userReply: {
      reply: [
        "I believe ...",
        "...we should add the previous block's number...",
        "...to make a block dependent on it's previous block",
      ],
      userAvatar: "Boring",
    },
    primers: blockPrimers,
  },
  {
    text: "Previous block's hash",
    userReply: {
      reply: [
        "I believe ...",
        "...we should add the previous block's number...",
        "...to make a block dependent on it's previous block",
      ],
      userAvatar: "Happy",
    },
    primers: hashPrimers,
  },
];

export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, in the last lesson, we learned how to store data inside a single block.",
        "You might be wondering what's the purpose of putting data inside a block.",
        "So, I would like to state one of the main purpose of a blockchain.",
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Info1",
    },
  },
  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      chats: [
        "{{user}}, let me simplify that question.",
        "Which of the following should be added in a block so that it's hash is dependent on it's previous block?",
      ],
      buttonOptions: button1Options,
      userReply: { reply: null, userAvatar: null },
      primerAvatar: "question",
    },
  },
];
