import blockchain from "./Blockchain";
import nearProtocol from "./NearProtocol";
import smartContract from "./SmartContract";
import dapp from "./Dapp";

export const primerComponents = {
  blockchain: blockchain.primerComponents,
  "near-protocol": nearProtocol.primerComponents,
  "smart-contracts": smartContract.primerComponents,
  dapp: dapp.primerComponents,
};

export const quizListComponent = {
  blockchain: blockchain.quizList,
  "near-protocol": nearProtocol.quizList,
  "smart-contracts": smartContract.quizList,
  dapp: dapp.quizList,
};

export const imageComponents = {
  blockchain: blockchain.imageComponents,
  "near-protocol": nearProtocol.imageComponents,
  "smart-contracts": smartContract.imageComponents,
  dapp: dapp.imageComponents,
};
