import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";

import { StyledTableCell, StyledTableRow } from "../../Common/Table.jsx";

const BlockTimeTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "900px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell
              sx={{
                color: "floralwhite",
                textAlign: "center",
                minWidth: "100px",
              }}
              variant="body"
            >
              Variable Name
            </StyledTableCell>
            <StyledTableCell
              sx={{ color: "floralwhite", textAlign: "center" }}
              variant="body"
            >
              SDK Variable
            </StyledTableCell>
            <StyledTableCell
              sx={{ color: "floralwhite", textAlign: "center" }}
              variant="body"
            >
              Description
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Predecessor
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>near.predecessorAccountId()</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Account ID that called this method
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Current Account
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>near.currentAccountId()</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Account ID of this smart contract
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Signer
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>near.signerAccountId()</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Account ID that signed the transaction leading to this execution
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Attached Deposit
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>near.attachedDeposit()</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Amount in NEAR attached to the call by the predecessor
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Account Balance
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>near.accountBalance()</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Balance of this smart contract (including Attached Deposit)
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Prepaid Gas
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>near.prepaidGas()</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Amount of gas available for execution
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Timestamp
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>near.blockTimestamp()</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Current timestamp (number of non-leap-nanoseconds since January 1,
              1970 0:00:00 UTC)
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Current Epoch
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>near.epochHeight()</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Current epoch in the blockchain
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Block Index
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>near.blockIndex()</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Current block index (a.k.a. block height)
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Storage Used
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>near.storageUsage()</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Current storage used by this smart contract
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Used Gas
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>near.usedGas()</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Amount of gas used for execution
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Signer Public Key
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>near.signerAccountPk()</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Sender Public Key
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Account Locked Balance
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>near.accountLockedBalance()</code>
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Balance of this smart contract that is locked
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlockTimeTable;
