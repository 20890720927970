export const ledgerData = [
  {
    id: "1",
    blockNumber: 1,
    nonceValue: 119460,
    prev: null,
    tx: [
      {
        amt: 86.96,
        from: "Alice",
        to: "Bob",
        id: "6f4d3382-d0eb-4b0f-a8d3-6fa8179083cc",
      },
      {
        amt: 58.2,
        from: "Cavill",
        to: "Bob",
        id: "b1b04b1d-aabb-42ac-a0aa-3b81b5393734",
      },
      {
        amt: 80.26,
        from: "David",
        to: "Elise",
        id: "15a72746-c95e-4971-8916-7938b27a0cdb",
      },
      {
        amt: 77.85,
        from: "David",
        to: "Bob",
        id: "69142395-f926-4614-9b71-24f5c3d0e46f",
      },
    ],
  },
  {
    id: "2",
    blockNumber: 2,
    nonceValue: 51593,
    prev: 1,
    tx: [
      {
        amt: 81.08,
        from: "Alice",
        to: "Cavill",
        id: "13989d13-a132-459c-ac08-8d42dcd91132",
      },
      {
        amt: 15.62,
        from: "David",
        to: "Elise",
        id: "2be55716-c556-4e1d-9799-f137d915e6c5",
      },
      {
        amt: 56.64,
        from: "Bob",
        to: "Cavill",
        id: "60f5f930-104e-40cb-8fe1-d762874da34d",
      },
    ],
  },
  {
    id: "3",
    blockNumber: 3,
    nonceValue: 97720,
    prev: 2,
    tx: [
      {
        amt: 86.51,
        from: "David",
        to: "Alice",
        id: "1ca4db2c-84a5-4778-85c9-eda9bf40e2cf",
      },
      {
        amt: 86.17,
        from: "David",
        to: "Bob",
        id: "1c585c0d-2ae4-44d4-8370-fb2c003b871e",
      },
      {
        amt: 67.81,
        from: "David",
        to: "Elise",
        id: "6b9724d2-97af-4ea4-a6a2-574ad8b64426",
      },
    ],
  },
  {
    id: "4",
    blockNumber: 4,
    nonceValue: 47292,
    prev: 3,
    tx: [
      {
        amt: 82.46,
        from: "Elise",
        to: "Alice",
        id: "02dc83eb-2b59-47ff-92b4-f6046bf5bc21",
      },
      {
        amt: 95.93,
        from: "Alice",
        to: "David",
        id: "93ef1d10-3409-430b-a434-2f2e85618a29",
      },
      {
        amt: 28.58,
        from: "Bob",
        to: "Cavill",
        id: "27eb6d95-e897-43c9-bf94-3eff2b2bf631",
      },
      {
        amt: 58.02,
        from: "Elise",
        to: "Bob",
        id: "e1758f0a-bc28-4cb4-8cbe-21556a689ef0",
      },
    ],
  },
  {
    id: "5",
    blockNumber: 5,
    nonceValue: 133606,
    prev: 4,
    tx: [
      {
        amt: 12.32,
        from: "Bob",
        to: "Alice",
        id: "59393bcb-a629-4f54-ad15-48597c7ba90f",
      },
      {
        amt: 41.99,
        from: "Cavill",
        to: "David",
        id: "881cf2ae-3806-4690-a107-482061e1e11c",
      },
      {
        amt: 33.66,
        from: "Elise",
        to: "David",
        id: "b39aa060-81f5-40b1-a1e6-110f3127ad4f",
      },
      {
        amt: 96.1,
        from: "Alice",
        to: "Elise",
        id: "7383ad94-e302-4b1f-971d-5d51b21a93e0",
      },
    ],
  },
];

export const coinbaseData = [
  {
    id: "1",
    blockNumber: 1,
    nonceValue: 10249,
    data: "Hi There!",
    prev: null,
    coinbase: {
      amt: "1000",
      miner: "Ben",
    },
    tx: [],
  },
  {
    id: "2",
    blockNumber: 2,
    nonceValue: 202253,
    data: "Welcome to the blockchain primer",
    prev: 1,
    coinbase: {
      amt: "1000",
      miner: "Sheriff",
    },
    tx: [
      {
        amt: 81.08,
        from: "Ben",
        to: "Selestina",
        id: "13989d13-a132-459c-ac08-8d42dcd91132",
      },
      {
        amt: 15.62,
        from: "Ben",
        to: "Alfred",
        id: "2be55716-c556-4e1d-9799-f137d915e6c5",
      },
      {
        amt: 56.64,
        from: "Ben",
        to: "Tibold",
        id: "60f5f930-104e-40cb-8fe1-d762874da34d",
      },
    ],
  },
  {
    id: "3",
    blockNumber: 3,
    nonceValue: 10530,
    data: "This is an interactive course",
    prev: 2,
    coinbase: {
      amt: "1000",
      miner: "Ben",
    },
    tx: [
      {
        amt: 86.51,
        from: "Sheriff",
        to: "Booth",
        id: "1ca4db2c-84a5-4778-85c9-eda9bf40e2cf",
      },
      {
        amt: 86.17,
        from: "Ben",
        to: "Spencer",
        id: "1c585c0d-2ae4-44d4-8370-fb2c003b871e",
      },
      {
        amt: 67.81,
        from: "Ben",
        to: "Benedicto",
        id: "6b9724d2-97af-4ea4-a6a2-574ad8b64426",
      },
    ],
  },
  {
    id: "4",
    blockNumber: 4,
    nonceValue: 86183,
    data: "I had fun creating this course",
    prev: 3,
    coinbase: {
      amt: "100",
      miner: "Sashi",
    },
    tx: [
      {
        amt: 82.46,
        from: "Ben",
        to: "Grantley",
        id: "02dc83eb-2b59-47ff-92b4-f6046bf5bc21",
      },
      {
        amt: 95.93,
        from: "Sheriff",
        to: "Aguste",
        id: "93ef1d10-3409-430b-a434-2f2e85618a29",
      },
      {
        amt: 28.58,
        from: "Spencer",
        to: "Wandie",
        id: "27eb6d95-e897-43c9-bf94-3eff2b2bf631",
      },
      {
        amt: 58.02,
        from: "Benedicto",
        to: "Marcy",
        id: "e1758f0a-bc28-4cb4-8cbe-21556a689ef0",
      },
    ],
  },
  {
    id: "5",
    blockNumber: 5,
    nonceValue: 58991,
    data: "So hope you enjoy it as well",
    prev: 4,
    coinbase: {
      amt: "1000",
      miner: "Angela",
    },
    tx: [
      {
        amt: 12.32,
        from: "Ben",
        to: "Anjela",
        id: "59393bcb-a629-4f54-ad15-48597c7ba90f",
      },
      {
        amt: 41.99,
        from: "Sheriff",
        to: "Andres",
        id: "881cf2ae-3806-4690-a107-482061e1e11c",
      },
      {
        amt: 33.66,
        from: "Aguste",
        to: "Con",
        id: "b39aa060-81f5-40b1-a1e6-110f3127ad4f",
      },
      {
        amt: 96.1,
        from: "Ben",
        to: "Wrennie",
        id: "7383ad94-e302-4b1f-971d-5d51b21a93e0",
      },
    ],
  },
];

export const signedTxData = [
  {
    id: "1",
    blockNumber: 1,
    nonceValue: 54805,
    data: "Hi There!",
    prev: null,
    coinbase: {
      amt: "1000",
      miner: "13mGFPC3wsUKV9taVK9Aqn5kt7PFUcTnvd",
    },
    tx: [],
  },
  {
    id: "2",
    blockNumber: 2,
    nonceValue: 96518,
    data: "Welcome to the blockchain primer",
    prev: 1,
    coinbase: {
      amt: "1000",
      miner: "18yxp3ir9Wq9VHoM3FJ1ksEc7SGaX6Z2Nq",
    },
    tx: [
      {
        amt: 81.08,
        from: "13mGFPC3wsUKV9taVK9Aqn5kt7PFUcTnvd",
        to: "1HBucXpLW98ptxArGDD1KA1svp99wCLZ8Y",
        id: "13989d13-a132-459c-ac08-8d42dcd91132",
        sig: "2e9f45a55d16a7d96ab661875f16a3d3ec5effc0d9daa693f6ce54eca8b6c15c92e102379dbb2caa5da2c2a5db0c3a51764aaec95d112ca561a22d376e4e434d",
      },
      {
        amt: 15.62,
        from: "13mGFPC3wsUKV9taVK9Aqn5kt7PFUcTnvd",
        to: "1N58xqrEGBrUz3musucCDYaiuCjMWRxTPr",
        id: "2be55716-c556-4e1d-9799-f137d915e6c5",
        sig: "edsigtYNdxzquyrU7SPW35QKH26AVLyo38GfTELzmstfEL5PaEWM8YzRnCrTJUqmZFYeoFQp327R6TzCn5gotxfhPgazYsDH7ot",
      },
      {
        amt: 56.64,
        from: "13mGFPC3wsUKV9taVK9Aqn5kt7PFUcTnvd",
        to: "19harPrh4RDGBveYWeoZNiZy3LWeeCo89f",
        id: "60f5f930-104e-40cb-8fe1-d762874da34d",
        sig: "edsigu2dysx72fPWZx7q8hFWMzJ494FnMQk8MewYQ2AuMabEDhhXq1ALqV4xC4TobX66NEfybihpj4N4K6ezipYe9pN8duAFG6r",
      },
    ],
  },
  {
    id: "3",
    blockNumber: 3,
    nonceValue: 71358,
    data: "This is an interactive course",
    prev: 2,
    coinbase: {
      amt: "1000",
      miner: "13mGFPC3wsUKV9taVK9Aqn5kt7PFUcTnvd",
    },
    tx: [
      {
        amt: 86.51,
        from: "18yxp3ir9Wq9VHoM3FJ1ksEc7SGaX6Z2Nq",
        to: "18u2qpicb9yqgYsXfPJHubyRUAjvZM2raJ",
        id: "1ca4db2c-84a5-4778-85c9-eda9bf40e2cf",
        sig: "edsigtzr7abqXGkLg1iWsnqAEmiZ2TbrfuPpQ4TZMM2WRoTHQPYREzdsPRhnQBovpG4cTdupCVog3rFUYa3dPFJa7PYw2GJB11g",
      },
      {
        amt: 86.17,
        from: "13mGFPC3wsUKV9taVK9Aqn5kt7PFUcTnvd",
        to: "1N6GYgQEup8nhucFeQ1KhXD7nhSdg4mTSi",
        id: "1c585c0d-2ae4-44d4-8370-fb2c003b871e",
        sig: "edsigtvvLKtpq22BeBMzUQPMo2nkKbKj2adJxpdRvnGFk3B7WyAquFdV2ivqjNXyo4Ymj72qDtynVAJVV2ZXbifko28Gxu9toTX",
      },
      {
        amt: 67.81,
        from: "13mGFPC3wsUKV9taVK9Aqn5kt7PFUcTnvd",
        to: "1FgmXZr2v3bZfNAoWLmJSZ4xJN76tLVinj",
        id: "6b9724d2-97af-4ea4-a6a2-574ad8b64426",
        sig: "edsigttdhifwuUm3XegJopBbG54Z34stZY14giQNbezAnDJ2nYDYZcA9N155u2pyxE81c4TuhYdjUqvMTmUikYJsJd8m1r4UBrf",
      },
    ],
  },
  {
    id: "4",
    blockNumber: 4,
    nonceValue: 20833,
    data: "I had fun creating this course",
    prev: 3,
    coinbase: {
      amt: "100",
      miner: "1uz1JEo9fKtXfPJXvR9DjcUopRaVtutSA",
    },
    tx: [
      {
        amt: 82.46,
        from: "13mGFPC3wsUKV9taVK9Aqn5kt7PFUcTnvd",
        to: "1EM1MdrhTR421fC6CRxZWU2ubqRcgAgHaN",
        id: "02dc83eb-2b59-47ff-92b4-f6046bf5bc21",
        sig: "edsigthZdvU93bEYffCD3G7Dn7ZDUEA3Xhk2y4i3MRGrmg5YfgbzaQaMWp1Gfza64K5CHDEY1cvkJ5BhZJgM9b4a8dgKPpVZixZ",
      },
      {
        amt: 95.93,
        from: "18yxp3ir9Wq9VHoM3FJ1ksEc7SGaX6Z2Nq",
        to: "1Nmn2nXJYTBro8w1PvQxxYrGRXjDysPyE3",
        id: "93ef1d10-3409-430b-a434-2f2e85618a29",
        sig: "edsigtrBgCcJVsBshunLyjJo21uXsuXHBAhQixj4CzdScjgc32WuZYF1B9cGhdX8tqrku1jRp1jVW4rsRqQKkV4oEB5LL2ptMqz",
      },
      {
        amt: 28.58,
        from: "1N6GYgQEup8nhucFeQ1KhXD7nhSdg4mTSi",
        to: "183p4TJkqiDP4Ymi9niPoj8YWdxXH1Dr23",
        id: "27eb6d95-e897-43c9-bf94-3eff2b2bf631",
        sig: "edsigtuaCeFmpCddPyAwhJbqeXkrXDcZsjnRnHaAVvrpEX9xJnYfXAiuTxiLB66BCRCvFzFCNoCMPrRoNKkTRZCrRQ19w4G9mvS",
      },
      {
        amt: 58.02,
        from: "1FgmXZr2v3bZfNAoWLmJSZ4xJN76tLVinj",
        to: "15ExyoWGzN6V6fZVb6fyss97fgisrB7DZ1",
        id: "e1758f0a-bc28-4cb4-8cbe-21556a689ef0",
        sig: "edsigu6LdDZS5HN1AkVX7PHFBnMCHcAK2hXAEhkvxTTfj9r8oWTgPMujFEK9Dt3DXBQfNhqa1wGnbc3adxoRqdodjRXAv5YiLf6",
      },
    ],
  },
  {
    id: "5",
    blockNumber: 5,
    nonceValue: 30882,
    data: "So hope you enjoy it as well",
    prev: 4,
    coinbase: {
      amt: "1000",
      miner: "1ES6Qv8qAn37MoEJD6kHxB7vxrcrghBPjL",
    },
    tx: [
      {
        amt: 12.32,
        from: "13mGFPC3wsUKV9taVK9Aqn5kt7PFUcTnvd",
        to: "1PRZYQGJt7FgqtHWuM9QwLm8ZzXXcK7S3r",
        id: "59393bcb-a629-4f54-ad15-48597c7ba90f",
        sig: "edsigtYQpnetKYGTNTfcz86mptuLFgY3SysH8bYDvUZohDsVrXqsEJA2UfLADQDP8hidjktzRiDTJFWK4Jovt5BYpE7NpY8wJ5o",
      },
      {
        amt: 41.99,
        from: "18yxp3ir9Wq9VHoM3FJ1ksEc7SGaX6Z2Nq",
        to: "1Hy7rmJT72UEHQrkt4pC6fuxPt6QB9BJF1",
        id: "881cf2ae-3806-4690-a107-482061e1e11c",
        sig: "edsigu5jAbQUcNCxt2HPvzRNnBP4HwjaMQ95rhYn3Fcqcz2gJHs45mMN7M17HuGHMBnfAgv9TmrWQJYnYQdiSiMc9FTncFYFWHW",
      },
      {
        amt: 33.66,
        from: "1Nmn2nXJYTBro8w1PvQxxYrGRXjDysPyE3",
        to: "1FCui2EwFbaWmxRRnYFgE2fpwvQQPX2RNs",
        id: "b39aa060-81f5-40b1-a1e6-110f3127ad4f",
        sig: "edsigtsuJhpfdTJu5UZuJKa4TgKGFHw9ej48cqowBc9MJ5whDS6ZZ7uN5AGM3Es1Cq3X3BZ2T6ML2zGJ4Ymp2XF6kJ3oe7aUtqW",
      },
      {
        amt: 96.1,
        from: "13mGFPC3wsUKV9taVK9Aqn5kt7PFUcTnvd",
        to: "1Mpy1BK6jagkxRfcdFuAmr5hvqDcVFUjFH",
        id: "7383ad94-e302-4b1f-971d-5d51b21a93e0",
        sig: "edsigtrXpoGWcSJUbpeupv1zEuZuSETYhHPMj8BExM2TkfFXLQER2sDeifWeBmB1Dk7LRJug4MtyT9dNqyo47EbaDJ9JzHHWWwQ",
      },
    ],
  },
];
