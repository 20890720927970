import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Account can receive NEAR tokens",
    "Account can add new access keys",
    "Account can deploy smart contract",
    "Account can be deleted by the parent account",
  ];
  const correctOption = "Account can receive NEAR tokens";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, if an account doesn't have any <code>access keys</code>{" "}
          on the blockchain, then which of the following operations can still be
          performed? ?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
