import { nonceOnwardsPrimers } from "./NonceOnwards";
const tryAgainPrimers = [
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "Sure, {{user}}.",
        "Can you find a nonce for which the block's hash value starts with two zeroes?",
      ],
      primerAvatar: "thumbs-up",

      textReply: { que: "What is the correct Nonce?" },
      userReply: { reply: null, userAvatar: "Cheeks" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "A nonce for which, the hash value of the block starts with two zeroes, is 151",
        "You might be wondering if there is an easier process to find the appropriate nonce other than trial and error.",
        "And the answer is No. Not really.",
      ],
      primerAvatar: "attention",
    },
  },
  ...nonceOnwardsPrimers,
];

export const correctNoncePrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Excellent, {{user}}. Yes, for nonce 151, the block's hash starts with two zeroes ",
        "You might be wondering if there is an easier process to find the appropriate nonce other than trial and error.",
        "And the answer is No. Not really.",
      ],
      primerAvatar: "showcase",
    },
  },
  ...nonceOnwardsPrimers,
];

export const absoluteCorrectNoncePrimers = [
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "In that case, {{user}}, I am disappointed in you.",
        "You are not taking this seriously. Therefore, I won't be able to help you anymore.",
        "Goodbye, {{user}}",
      ],
      primerAvatar: "roll-eyes",
    },
  },
];

export const somethingWrongPrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Sorry for the trouble, {{user}}, maybe there is an issue in application.",
        "Anyways, for nonce 151, you get a valid block whose hash value starts with two zeroes",
        "You might be wondering if there is an easier process to find the appropriate nonce other than trial and error.",
        "And the answer is No. Not really.",
      ],
      primerAvatar: "under-construction",
    },
  },
  ...nonceOnwardsPrimers,
];

export const wasGuessingPrimersButtonOptions = [
  {
    text: "Okay",
    userReply: {
      reply: ["Okay Primer", "Let me try once more"],
      userAvatar: "Cheeks",
    },
    primers: tryAgainPrimers,
  },
  {
    text: "Something is wrong",
    userReply: {
      reply: [
        "Sorry Primer!",
        "There is something wrong with the application",
        "Cannot check the nonce",
      ],
      userAvatar: "Blood from eyes",
    },
    primers: somethingWrongPrimers,
  },
  {
    text: "I won't",
    userReply: {
      reply: ["I won't be trying anymore, Primer"],
      userAvatar: "Smirk",
    },
    primers: absoluteCorrectNoncePrimers,
  },
];

export const wasGuessingPrimers = [
  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      chats: [
        "No Problem, {{user}}.",
        "Try finding the nonce one more time.",
        "This time maybe try changing the nonce 150 onwards?",
      ],
      buttonOptions: wasGuessingPrimersButtonOptions,
      userReply: { reply: null, userAvatar: null },
      primerAvatar: "patient",
    },
  },
];

export const incorrectNoncePrimersbuttonOptions = [
  {
    text: "Absolutely Sure",
    userReply: {
      reply: ["I am 100% sure, Primer"],
      userAvatar: "Boring",
    },
    primers: absoluteCorrectNoncePrimers,
  },
  {
    text: "Sorry, was guessing",
    userReply: {
      reply: ["Sorry Primer !", "I was indeed guessing."],
      userAvatar: "Sadness",
    },
    primers: wasGuessingPrimers,
  },
];

export const incorrectNoncePrimers = [
  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      chats: [
        "Hmmm..",
        "Are you sure this is the correct nonce, {{user}}?",
        "Or are you guessing?",
      ],
      buttonOptions: incorrectNoncePrimersbuttonOptions,
      userReply: { reply: null, userAvatar: null },
      primerAvatar: "sad",
    },
  },
];

export const correctNoncebuttonOptions = [
  {
    text: "151",
    userReply: {
      reply: [
        "It's 151",
        "For nonce 151, the hash value starts with two zeroes.",
      ],
      userAvatar: "Cute",
    },
    primers: correctNoncePrimers,
  },
  {
    text: "145",
    userReply: {
      reply: [
        "It's 145",
        "For nonce 145, the hash value starts with two zeroes.",
      ],
      userAvatar: "Smirk",
    },
    primers: incorrectNoncePrimers,
  },
  {
    text: "140",
    userReply: {
      reply: [
        "It's 140",
        "For nonce 140, the hash value starts with two zeroes.",
      ],
      userAvatar: "Smirk",
    },
    primers: incorrectNoncePrimers,
  },
  {
    text: "175",
    userReply: {
      reply: [
        "It's 175",
        "For nonce 175, the hash value starts with two zeroes.",
      ],
      userAvatar: "Smirk",
    },
    primers: incorrectNoncePrimers,
  },
  {
    text: "210",
    userReply: {
      reply: [
        "It's 210",
        "For nonce 210, the hash value starts with two zeroes.",
      ],
      userAvatar: "Smirk",
    },
    primers: incorrectNoncePrimers,
  },
];

const foundNoncePrimers = [
  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      chats: [
        "Excellent, {{user}}. ",
        "Which of the following is the correct nonce for a valid block?",
      ],
      buttonOptions: correctNoncebuttonOptions,
      userReply: { reply: null, userAvatar: null },
      primerAvatar: "thumbs-up",
    },
  },
];

export const chat2buttonOptions = [
  {
    text: "Found the Nonce",
    userReply: {
      reply: [
        "I got it, Primer !",
        "I got the correct nonce for which the hash value starts with two zeroes.",
      ],
      userAvatar: "Happy",
    },
    primers: foundNoncePrimers,
  },
  {
    text: "Wasn't able to find the Nonce",
    userReply: {
      reply: ["Ummmm..", "I couldn't get the nonce, Primer "],
      userAvatar: "Sadness",
    },
    primers: wasGuessingPrimers,
  },
];

export const dataPrimers = [
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "ChangingDataInfo",
    },
  },
  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      chats: [
        "{{user}}, I really want you to try changing the nonce...",
        "...and find the correct nonce by yourself.",
        "{{user}}, let me know if you were able to find the correct nonce.",
      ],
      buttonOptions: chat2buttonOptions,
      userReply: { reply: null, userAvatar: null },
      primerAvatar: "puzzle",
    },
  },
];
