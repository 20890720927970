import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Paper } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

// Short Primers
import ContractExecution from "./ContractExecution.mdx";
import ClassPrimer from "./ClassPrimer.mdx";
import DecoratorPrimer from "./DecoratorPrimer.mdx";
import CounterContract from "./Primers/CounterContract.mdx";
import StoragePrimer from "./Primers/StoragePrimer.mdx";
import SerializationPrimer from "./Primers/SerializationPrimer.mdx";
import EnvironmentTablePrimer from "./Primers/EnvironmentTablePrimer.mdx";
import EnvironmentPrimer from "./Primers/EnvironmentPrimer.mdx";
import CollectionsPrimer from "./Primers/CollectionsPrimer.mdx";
import VectorPrimer from "./Primers/Collections/VectorPrimer.mdx";
import LookupMapPrimer from "./Primers/Collections/LookupMapPrimer.mdx";
import LookupSetPrimer from "./Primers/Collections/LookupSetPrimer.mdx";
import UnorderedSetPrimer from "./Primers/Collections/UnorderedSetPrimer.mdx";
import UnorderedMapPrimer from "./Primers/Collections/UnorderedMapPrimer.mdx";
import TransactionsPrimer from "./Primers/TransactionsPrimer.mdx";
import WalletPrimer from "./Primers/WalletPrimer.mdx";

import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import IconButton from "@mui/material/IconButton";

const NoContent = () => <div>No Content Found</div>;

/* ADD BOTH in `contentMap` and `contentTitle` */

export const contentMap = {
  smartContract: <ContractExecution />,
  blank: <NoContent />,
  decorator: <DecoratorPrimer />,
  class: <ClassPrimer />,
  counterContract: <CounterContract />,
  storagePrimer: <StoragePrimer />,
  serialization: <SerializationPrimer />,
  "environment-table": <EnvironmentTablePrimer />,
  "execution-environment": <EnvironmentPrimer />,
  "sdk-collections": <CollectionsPrimer />,
  "vector-primer": <VectorPrimer />,
  "lookupmap-primer": <LookupMapPrimer />,
  "lookupset-primer": <LookupSetPrimer />,
  "unorderedmap-primer": <UnorderedMapPrimer />,
  "unorderedset-primer": <UnorderedSetPrimer />,
  "transactions-primer": <TransactionsPrimer />,
  "wallet-primer": <WalletPrimer />,
};

export const contentTitle = {
  smartContract: "Smart Contract Execution Guide",
  decorator: "Decorator in Javascript Guide",
  class: "Classes in Javascript Guide",
  counterContract: "Counter Smart Contract Walkthrough",
  storagePrimer: "A Guide to Storage Staking",
  serialization: "A Guide to Serialization and Deserialization in Javascript",
  "environment-table": "Environment Methods available in the JS-SDK",
  "execution-environment": "A Guide on using Execution Environment Methods",
  "sdk-collections": "A Guide to SDK Collections in near-sdk-js",
  "vector-primer":
    "A Guide on using Vector Collections Object from near-sdk-js",
  "lookupmap-primer":
    "A Guide on using LookupMap Collections Object from near-sdk-js",
  "lookupset-primer":
    "A Guide on using LookupSet Collections Object from near-sdk-js",
  "unorderedmap-primer":
    "A Guide on using UnorderedMap Collections Object from near-sdk-js",
  "unorderedset-primer":
    "A Guide on using UnorderedSet Collections Object from near-sdk-js",
  "transactions-primer": "A Guide on Transactions and Actions",
  "wallet-primer": "Types of Wallet",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "800px",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  maxHeight: "750px",
  overflowY: "auto",
};

const InitialText = () => <p>No Text Added</p>;

const ShortPrimer = ({
  modal = true,
  avatar = "showcase",
  type = "blank",
  text = InitialText,
  ...rest
}) => {
  const src = `https://assets.primerlabs.io/PrimerAvatar150/${avatar}.png`;

  const addProps = { src, type, text, ...rest };

  if (modal) {
    return <ModalPrimer {...addProps} />;
  }
  return <div>Hello World</div>;
};

export default ShortPrimer;

const ModalPrimer = ({ src, type, text }) => {
  const [open, setOpen] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let Component = text;

  return (
    <div>
      <Paper
        style={{
          cursor: "pointer",
          margin: "20px auto",
          padding: "40px",
          background: open || hover ? "slateblue" : "cornflowerblue",
          color: "white",
          transition: "0.5s",
        }}
        onClick={handleOpen}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div>
          <div
            style={{
              margin: "auto",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <img src={src} style={{ height: "100px", padding: "10px" }} />
            <div
              style={{
                padding: "10px",
                textAlign: "center",
                margin: "10px auto",
                fontWeight: "bold",
                fontSize: "large",
              }}
            >
              <Component />
            </div>
          </div>
        </div>
      </Paper>
      <Modal open={open} onClose={handleClose}>
        <React.Fragment>
          <div>
            <IconButton
              onClick={() => setOpen(false)}
              style={{
                color: "white",
                position: "fixed",
                top: "10px",
                right: "10px",
              }}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              component={RouterLink}
              to={`/guides/${type}`}
              target="_blank"
              style={{
                color: "white",
                position: "fixed",
                top: "10px",
                right: "50px",
              }}
            >
              <OpenInNewIcon />
            </IconButton>
          </div>

          <Box className="BoxOutline" sx={style}>
            {contentMap[type]}
          </Box>
        </React.Fragment>
      </Modal>
    </div>
  );
};
