// Primers
import { start } from "./Primers/Start";

// Informations
import Bartering from "./PrimerComponents/Bartering.mdx";
import Ledger from "./PrimerComponents/Ledger.mdx";
import TrustedLedger from "./PrimerComponents/TrustedLedger.mdx";
import SingleEntryLedger from "./PrimerComponents/SingleEntryLedger.mdx";
import DoubleEntryLedger from "./PrimerComponents/DoubleEntryLedger.mdx";
import Banks from "./PrimerComponents/Banks.mdx";
import FractionalReserveBanking from "./PrimerComponents/FractionalReserveBanking.mdx";
import Decentral from "./PrimerComponents/Decentral.mdx";

// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";

// Quiz
import LedgerMCQ from "./Quiz/LedgerMCQ";
import SingleEntryMCQ from "./Quiz/SingleEntryMCQ";
import DoubleLedgerMCQ from "./Quiz/DoubleLedgerMCQ";
import BanksMCQ from "./Quiz/BanksMCQ";

const primers = [...start];

const primerComponents = {
  Bartering: Bartering,
  Ledger: Ledger,
  TrustedLedger: TrustedLedger,
  SingleEntryLedger: SingleEntryLedger,
  LedgerMCQ: LedgerMCQ,
  SingleEntryMCQ: SingleEntryMCQ,
  DoubleEntryLedger: DoubleEntryLedger,
  DoubleLedgerMCQ: DoubleLedgerMCQ,
  Banks: Banks,
  FractionalReserveBanking: FractionalReserveBanking,
  BanksMCQ: BanksMCQ,
  Decentral: Decentral,
  NextLesson: NextLesson,
};

const imageComponents = [];

const quizList = ["LedgerMCQ", "SingleEntryMCQ", "DoubleLedgerMCQ", "BanksMCQ"];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
