export const checkAccountId = (accountId) => {
  const accountRegex =
    /^(([a-z\d]+[\-_])*[a-z\d]+\.)*([a-z\d]+[\-_])*[a-z\d]+$/;
  if (accountId.length < 2) {
    return false;
  } else if (accountId.length > 64) {
    return false;
  } else if (accountRegex.exec(accountId) === null) {
    return false;
  } else {
    return true;
  }
};

export const checkPublicKey = (publicKey) => {
  if (/\s/g.test(publicKey)) {
    return false;
  } else if (![44, 52].includes(publicKey.length)) {
    return false;
  }
  return true;
};
