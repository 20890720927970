import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "DApps are resistant to censorship",
    "DApps are resitant to tampering",
    "DApps can offer increased security",
    "DApps offer less transparency than traditional applications",
  ];
  const correctOption =
    "DApps offer less transparency than traditional applications";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, which of the following is not a <b>feature</b> of{" "}
            <b>decentralised applications (DApp)</b>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
