import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AntTabs, AntTab } from "./Tabs";
{
  /* <span style={{ fontSize: "3rem" }}>{"\u201C"}</span>
 <span style={{ fontSize: "3rem" }}>{"\u201D"}</span> */
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography sx={{ color: "#47307f", display: "flex" }}>
            <span style={{ fontSize: "80px", margin: "0px", padding: "0px" }}>
              {"\u201C"}
            </span>
            <blockquote style={{ paddingTop: "10px", fontSize: "small" }}>
              {children}
            </blockquote>
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabPanelStyles = {
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  color: "#47307f",
};

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        border: "none",
        maxHeight: 300,
      }}
    >
      <AntTabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderBottom: "none",
          borderRight: 1,
          borderColor: "divider",
          minWidth: "250px",
        }}
      >
        <AntTab label="Privacy" {...a11yProps(0)} />
        <AntTab label="Gate-Keeping" {...a11yProps(1)} />
        <AntTab label="Incompetence" {...a11yProps(2)} />
        <AntTab label="Fraud" {...a11yProps(3)} />
        <AntTab label="Trust" {...a11yProps(4)} />
      </AntTabs>
      <TabPanel style={tabPanelStyles} value={value} index={0}>
        This method compromises the privacy of the transaction in two ways: the{" "}
        <b>trusted third-party has complete transaction details</b>, and after a
        transaction, it is available indefinitely to the trusted third party.
        The third-party could either voluntarily <b>disclose the details</b> to
        anyone or involuntarily disclose them in case it gets{" "}
        <b>hacked or compromised</b>.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={1}>
        The <b>trusted third-party can gate-keep</b> with whom you can transact
        online. Your transactions are subject to the trusted third-party's rules
        and the country's regulations.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={2}>
        You have to <b>trust</b> that the <b>trusted third-party</b> has secured
        networking facilities and no one else can eavesdrop or hack the
        transaction or <b>gain control of your account</b>.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={3}>
        Most online trusted third parties offer <b>buyer protection</b> or{" "}
        <b>charge-backs</b>. For instance, even after receiving goods from a
        seller, the buyer can issue a <b>transaction reversal</b>, and the
        trusted third party has to comply. This creates the possibility of
        several fraudulent transactions.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={4}>
        When you use a <b>trusted third party</b> to <b>store your money</b>,
        you have to <b>trust</b> the third party has indeed kept your funds safe
        and secured. <b>Nothing prevents</b> the third party from using your
        funds stored with it for different purposes than you originally
        intended. In many cases, the trusted-third party can even{" "}
        <b>refuse access to your own money</b> and <b>freeze your account</b>.
      </TabPanel>
    </Box>
  );
}
