import {
  StyledTableRow,
  StyledTableCell,
} from "../../../../../components/Common/Table.jsx";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";

const BlockTimeTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "900px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell
              sx={{ color: "floralwhite", textAlign: "center" }}
              variant="body"
            >
              Term
            </StyledTableCell>
            <StyledTableCell
              sx={{ color: "floralwhite", textAlign: "center" }}
              variant="body"
            >
              Description
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>SIGNED BY</code>
            </StyledTableCell>
            <StyledTableCell variant="body">
              Account that signed and sent the transaction
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>RECEIVER</code>
            </StyledTableCell>
            <StyledTableCell variant="body">
              Account receiving the transaction
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>TRANSACTION FEE</code>
            </StyledTableCell>
            <StyledTableCell variant="body">
              Total fee paid in NEAR to execute this transaction.
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>DEPOSIT VALUE</code>
            </StyledTableCell>
            <StyledTableCell variant="body">
              Sum of all NEAR tokens transferred from the Signing account to the
              Receiver account.
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>GAS USED</code>
            </StyledTableCell>
            <StyledTableCell variant="body">
              Units of gas required to execute this transaction
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>ATTACHED GAS</code>
            </StyledTableCell>
            <StyledTableCell variant="body">
              Units of gas attached to the transaction
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>HASH</code>
            </StyledTableCell>
            <StyledTableCell variant="body">
              Unique identifier (hash) of this transaction.
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">
              <code>BLOCK HASH</code>
            </StyledTableCell>
            <StyledTableCell variant="body">
              Unique identifier (hash) of the block this transaction was
              included in.
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlockTimeTable;
