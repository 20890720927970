import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AntTabs, AntTab } from "./Tabs";
{
  /* <span style={{ fontSize: "3rem" }}>{"\u201C"}</span>
 <span style={{ fontSize: "3rem" }}>{"\u201D"}</span> */
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography
            component="div"
            sx={{ color: "#47307f", display: "flex" }}
          >
            <span style={{ fontSize: "80px", margin: "0px", padding: "0px" }}>
              {"\u201C"}
            </span>
            <blockquote style={{ paddingTop: "10px", fontSize: "small" }}>
              {children}
            </blockquote>
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabPanelStyles = {
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  color: "#47307f",
};

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        border: "none",
        maxHeight: 450,
      }}
    >
      <AntTabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderBottom: "none",
          borderRight: 1,
          borderColor: "divider",
          minWidth: "250px",
        }}
      >
        <AntTab label="Top-Level Accounts" {...a11yProps(0)} />
        <AntTab label="Sub-Accounts" {...a11yProps(2)} />
        <AntTab label="Implicit Accounts" {...a11yProps(3)} />
        <AntTab label="Developer Accounts" {...a11yProps(4)} />
      </AntTabs>
      <TabPanel style={tabPanelStyles} value={value} index={0}>
        Account names which do not have a dot (<code>.</code>) in them are
        called <b>top-level account names</b>.
        <b>Top-level account names (TLAs)</b> are similar to <code>'com'</code>
        or <code>'org'</code> in domain names. They are very valuable as they
        provide a root of trust and discoverability for companies, applications,
        and users. Therefore, to allow for fair access, top-level account names{" "}
        <b>shorter than 32</b> will be auctioned off.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={1}>
        <b>NEAR</b> follow a similar naming pattern to that of website domains
        with similar rules. Accounts can create as many <b>subaccounts</b> as
        they wish, and only the <b>parent account</b> can create a{" "}
        <b>subaccount</b>. For example: <code>example.near</code> can create{" "}
        <code>subaccount1.example.near</code>, but <code>example.near</code>{" "}
        cannot create <code>sub.subaccount.example.near</code>.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={2}>
        <b>Implicit accounts</b> work similarly to Bitcoin/Ethereum accounts:
        they allow you to reserve an <b>account ID</b> before it's created by
        generating a <b>ED25519 key-pair</b> locally. This key-pair has a public
        key that maps to <b>64 character hex</b> representation which becomes
        the account ID.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={3}>
        <b>Dev accounts</b> are made automatically by tools like{" "}
        <code>near-cli</code> and the wallet to help you automate testing and
        deploying contracts. Since re-deploying a contract on an account **DOES
        NOT** create new state, you often want to deploy rapidly to fresh
        accounts when testing.
        <b>Dev accounts</b> are very useful for automating your testing.
      </TabPanel>
    </Box>
  );
}
