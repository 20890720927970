export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, in the last lesson, we learned about keys and implicit accounts",
        "In this lesson, we will take a look at how to deploy and call smart contracts",
        "Let's start with smart contracts on NEAR Protocol",
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "SmartContractsInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ReadableMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'There is an additional command specifically for the "testnet" environment, which is used for testing and deploying smart contracts.',
        "Let's check it out next. ",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "DevAccountsInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "MethodsMCQ",
    },
  },

  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Once, you we have deployed the functions on the NEAR Protocol Blockchain, we can check if the contract is correctly deployed by calling the argument.",
        "Next, let's look at how to call and execute methods deployed on a smart contract. ",
      ],
      primerAvatar: "support",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "SmartContractsExecuteInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ContractReorder",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "The NEAR Command Line Interface provides two commands to execute deployed smart contract calls.",
        "Let's start with the one that is used to view smart contract methods.",
      ],
      primerAvatar: "cable",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "ViewMethodsInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ViewMethodMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "View methods upto 200TGas are free of cost",
        'If the method requires more gas, then NEAR CLI also offers a second command "near call" which is used to call methods that require gas to execute.',
        "These methods usually change the state of the blockchain",
        "Let's take a look at the command",
      ],
      primerAvatar: "patient",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "CallMethodInfo",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "{{user}}, this brings us to the end of this lesson on deploying and executing smart contracts.",
        "This also brings us to the end of the section on Command Line Interface as well",
        "I truly hope this section has made it easy for you to start interacting with the NEAR Protocol",
        "See you in another lesson, {{user}}",
      ],
      primerAvatar: "good-bye",
    },
  },
];
