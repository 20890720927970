import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Function Call Access Key allows transfer of upto 0.25 Ⓝ on behalf of user",
    "Function Call Access Key allows spending of gas upto 0.25 Ⓝ on behalf of user",
    "In a DApp, users are redirected to their wallet to sign off any transfer transactions",
    "All of above",
  ];
  const correctOption =
    "Function Call Access Key allows transfer of upto 0.25 Ⓝ on behalf of user";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, which of the following statements is <b>false</b>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
