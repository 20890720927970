import React from "react";
import Lottie from "lottie-react";

export const AcccountLottie = ({ url = "Account" }) => {
  const lottieRef = React.useRef();

  const [anim, setAnim] = React.useState(null);

  React.useEffect(() => {
    const assetUrl = `https://primer-assets-s3-limited.s3.amazonaws.com/banner_cards/${url}.json`;

    async function fetchData() {
      const response = await fetch(assetUrl);
      if (!response.ok) {
        setAnim("error");
      }
      const json = await response.json();
      setAnim(json);
    }
    fetchData();
  }, []);

  if (anim === null) {
    return <div>Loading...</div>;
  } else if (anim === "error") {
    return <div>Error loading animation</div>;
  }

  return (
    <div
      onMouseEnter={() => lottieRef.current.pause()}
      onMouseLeave={() => lottieRef.current.play()}
      style={{
        height: "200px",
        width: "100%",
        cursor: "pause",
      }}
    >
      <Lottie
        style={{
          width: "100%",
          margin: "auto",
          float: "right",
          borderRadius: "100px",
        }}
        lottieRef={lottieRef}
        loop={true}
        animationData={anim}
      />
    </div>
  );
};
