import React, { useContext } from "react";
import {
  ReactTerminalStateless,
  ReactThemes,
  ReactOutputRenderers,
} from "react-terminal-component";

import {
  EmulatorState,
  OutputFactory,
  CommandMapping,
  EnvironmentVariables,
  FileSystem,
  History,
  OutputType,
  Outputs,
  defaultCommandMapping,
  OptionParser,
} from "javascript-terminal";
import { AppContext } from "../../../../AppContext";
import formatString from "./formatString";
// import ReactOutputRenderers from "output";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import KeyIcon from "@mui/icons-material/Key";
import IconButton from "@mui/material/IconButton";
import "./ReactTerminal.css";
import Modal from "./components/Modal";
import { helpMenuContent } from "./NEARTextContent/HelpMenu";
import PopperHover from "../../../Common/PopperHover";
import { Paper, Box } from "@mui/material";
import { COLORED_TYPE } from "./constants";
import { ColoredOutput, WrappedOutput } from "./TerminalOutputs";
import commands from "./commands";
import { CustomOutputFactory } from "./outputs";
import {
  helpMenuText,
  downloadErrorText,
  downloadHelpText,
} from "./NEARTextContent/HelpMenuText";
import { renderCommands } from "./commands/renderCommands";
import { generateSeedPhrase } from "near-seed-phrase";
import AccessKeyTable from "./components/AccessTable";
import { generateKeyMapPair } from "./commands/utils";
import hello from "./wasmFiles/hello";
import {
  updateState,
  customFileSystem,
  getURL,
  optDef,
} from "./commands/utils";

const Terminal = React.memo(({ state, ...props }) => {
  const initialState = EmulatorState.create({
    fs: customFileSystem,
    commandMapping: CommandMapping.create({
      ...defaultCommandMapping,
    }),
  });
  const { welcomeText, walletContent } = state;
  const context = useContext(AppContext);

  const [inputStr, setInputStr] = React.useState(
    props.inputStr ? props.inputStr : ""
  );
  const [acceptInput, setAcceptInput] = React.useState(true);
  const [emulatorState, setCustomState] = React.useState(initialState);
  const [fullscreen, setFullscreen] = React.useState(false);
  const [showKeys, setShowKeys] = React.useState(false);
  const loggedIn = context.walletAccountId !== null;

  const stateChangeProps = { setAcceptInput, setCustomState };

  const ShowAccessKeys = ({ walletAccountId }) => {
    const keys = Object.keys(localStorage).filter((key) =>
      key.startsWith("cli-near-api-js")
    );

    const keyMap = generateKeyMapPair(keys);

    return (
      <Paper
        sx={{
          maxWidth: "900px",
          width: "80%",
          margin: "50px auto",
          padding: "30px",
          background: "darkslateblue",
        }}
      >
        <h2 style={{ textAlign: "center", color: "floralwhite" }}>
          Access Keys
        </h2>
        <h3 style={{ textAlign: "center", color: "gray" }}>
          Stored in the browser's local storage
        </h3>

        {keyMap.length === 0 ? (
          <div
            style={{
              background: "floralwhite",
              padding: "30px",
              fontWeight: "bold",
            }}
          >
            No access keys found in the browser's localstorage
          </div>
        ) : (
          <AccessKeyTable keys={keyMap} walletAccountId={walletAccountId} />
        )}
      </Paper>
    );
  };

  const checkStringInput = (str) => {
    const quoteRe = /(\x27)(.+?)(\x27)/gim;
    const quoteMatch = quoteRe.exec(str);
    if (quoteMatch) {
      if (/\s/.test(quoteMatch[2])) {
        const changeString = quoteMatch[2].trim().replace(/\s+/g, "");
        const replaceString = str.replace(quoteMatch[2], changeString);
        setInputStr(replaceString);
      } else {
        setInputStr(str);
      }
    } else {
      setInputStr(str);
    }
  };

  React.useEffect(() => {
    setCustomState((prevState) => {
      const defaultOutputs = prevState.getOutputs();
      const welcomeOutputs =
        CustomOutputFactory.createColoredRecord(welcomeText);
      const newOutputs = Outputs.addRecord(defaultOutputs, welcomeOutputs);
      const stateWithRecords = prevState.setOutputs(newOutputs);

      const newState = stateWithRecords.setCommandMapping(
        CommandMapping.create({
          ...defaultCommandMapping,
          near: {
            function: (state, commandOptions) => {
              const { parseOptions } = OptionParser;
              const { options, argv } = parseOptions(commandOptions, optDef);
              if (argv.length === 0) {
                return {
                  output: CustomOutputFactory.createColoredRecord(helpMenuText),
                };
              } else if (renderCommands(argv[0]) && argv.length === 1) {
                // console.log(context);
                const commandFunction = renderCommands(argv[0], options);
                return commandFunction(context, options, argv, {
                  ...stateChangeProps,
                });
              } else {
                const commandFunction = renderCommands(argv[0]);

                if (commandFunction) {
                  return commandFunction(context, options, argv, {
                    ...stateChangeProps,
                  });
                } else {
                  return {
                    output: CustomOutputFactory.createColoredRecord(
                      `No such command found. Try [[near help]] to learn about available commands`
                    ),
                  };
                }
              }
            },
            optDef: optDef,
          },

          download: {
            function: (state, commandOptions) => {
              const { parseOptions } = OptionParser;
              const { options, argv } = parseOptions(commandOptions, optDef);
              if (options.help) {
                return {
                  output:
                    CustomOutputFactory.createColoredRecord(downloadHelpText),
                };
              } else if (argv.length === 0) {
                return {
                  output:
                    CustomOutputFactory.createColoredRecord(downloadHelpText),
                };
              } else if (argv.length === 1) {
                // Do the actual downloading
                if (getURL(argv[0])) {
                  window.open(getURL(argv[0]), "_blank").focus();
                  return {
                    output: CustomOutputFactory.createColoredRecord(
                      `Downloading ${argv[0]} file ...`
                    ),
                  };
                } else {
                  return {
                    output: CustomOutputFactory.createColoredRecord(
                      `No file named &&${argv[0]}&& found. Type [[ls]] to see currently available files `
                    ),
                  };
                }
              } else {
                // show wrong command usage
                return {
                  output:
                    CustomOutputFactory.createColoredRecord(downloadErrorText),
                };
              }
            },
            optDef: optDef,
          },

          print: {
            function: (state, opts) => {
              setAcceptInput(false);
              const input = opts.join(" ");

              setTimeout(() => {
                const oldState = state;
                const oldOutputs = state.getOutputs();
                const newOutputs = Outputs.addRecord(
                  oldOutputs,
                  OutputFactory.makeTextOutput(input)
                );

                const newState = oldState.setOutputs(newOutputs);
                setAcceptInput(true);
                setCustomState(newState);
              }, 3000);

              return {
                output: OutputFactory.makeTextOutput("waiting ..."),
              };
            },
            optDef: {},
          },
        })
      );
      return newState;
    });
  }, []);

  const handleSignIn = () => {
    context.signIn();
  };

  return (
    <div>
      <div style={{ padding: "10px", textAlign: "center" }}>
        {loggedIn ? (
          ""
        ) : (
          <React.Fragment>
            <span
              onClick={() => handleSignIn()}
              style={{
                background: "#47307f",
                color: "white",
                fontWeight: "bold",
                padding: "5px 10px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Sign in
            </span>{" "}
            to{" "}
            <a
              style={{ cursor: "pointer", textDecoration: "none" }}
              href={"https://wallet.testnet.near.org/"}
              target="_blank"
            >
              <PopperHover content={walletContent}>
                Near Test Wallet
              </PopperHover>
            </a>{" "}
            account or type{" "}
            <code
              style={{
                fontFamily: "monospace",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              near login
            </code>{" "}
            in the console
          </React.Fragment>
        )}
      </div>

      <div>
        <Modal fullscreen={showKeys} setFullscreen={setShowKeys}>
          <ShowAccessKeys walletAccountId={context.walletAccountId} />
        </Modal>

        <Modal fullscreen={fullscreen} setFullscreen={setFullscreen}>
          <ReactTerminalStateless
            autoFocus={false}
            acceptInput={acceptInput}
            emulatorState={emulatorState}
            errorStr={
              "No such command found. Type `help` to get a get a list of available commands"
            }
            inputStr={inputStr}
            onInputChange={(input) => checkStringInput(input)}
            onStateChange={(state) => {
              setCustomState(state);
              setInputStr("");
            }}
            outputRenderers={{
              ...ReactOutputRenderers,
              [COLORED_TYPE]: ColoredOutput,
              [OutputType.TEXT_OUTPUT_TYPE]: WrappedOutput,
            }}
            theme={{
              ...ReactThemes.dye,
              fontWeight: "bold",
              overflowX: "false",
              height: "70vh",
              width: "100%",
            }}
          />
        </Modal>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <span
              style={{
                fontSize: "smaller",
                fontVariant: "all-small-caps",
                letterSpacing: "2px",
                margin: "2px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loggedIn ? context.walletAccountId : ""}
            </span>
          </div>
          <div style={{ display: "flex" }}>
            <IconButton onClick={() => setShowKeys(true)} aria-label="keys">
              <KeyIcon />
            </IconButton>
            <span
              onClick={() => setFullscreen(true)}
              style={{
                fontSize: "smaller",
                fontVariant: "all-small-caps",
                letterSpacing: "2px",
                margin: "2px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              Fullscreen
            </span>
            {!fullscreen && (
              <IconButton
                onClick={() => setFullscreen(true)}
                aria-label="fullscreen"
              >
                <FullscreenIcon />
              </IconButton>
            )}
            {fullscreen && (
              <IconButton onClick={() => setFullscreen(false)}>
                <FullscreenExitIcon />
              </IconButton>
            )}
          </div>
        </div>

        <ReactTerminalStateless
          autoFocus={false}
          acceptInput={fullscreen ? false : acceptInput}
          emulatorState={emulatorState}
          inputStr={inputStr}
          onInputChange={(input) => checkStringInput(input)}
          onStateChange={(state) => {
            setCustomState(state);
            setInputStr("");
          }}
          outputRenderers={{
            ...ReactOutputRenderers,
            [COLORED_TYPE]: ColoredOutput,
            [OutputType.TEXT_OUTPUT_TYPE]: WrappedOutput,
          }}
          theme={{
            ...ReactThemes.dye,
            fontWeight: "bold",
            overflowX: "false",
          }}
        />
      </div>
    </div>
  );
});

export default Terminal;
