import {
  StyledTableRow,
  StyledTableCell,
} from "../../../../../components/Common/Table.jsx";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";

const BlockTimeTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "900px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Person
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Block 1
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Block 2
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Block 3
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Block 4
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Block 5
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">Ben</StyledTableCell>
            <StyledTableCell sx={{ color: "indigo" }} variant="body">
              1000 ₿
            </StyledTableCell>
            <StyledTableCell variant="body">846.66 ₿</StyledTableCell>
            <StyledTableCell sx={{ color: "indigo" }} variant="body">
              1692.68 ₿
            </StyledTableCell>
            <StyledTableCell variant="body">1610.22 ₿</StyledTableCell>
            <StyledTableCell variant="body">1501.8 ₿</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">Sheriff</StyledTableCell>
            <StyledTableCell variant="body">0 ₿</StyledTableCell>
            <StyledTableCell sx={{ color: "indigo" }} variant="body">
              1000 ₿
            </StyledTableCell>
            <StyledTableCell variant="body">913.49 ₿</StyledTableCell>
            <StyledTableCell variant="body">817.56 ₿</StyledTableCell>
            <StyledTableCell variant="body">775.57 ₿</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">Adam</StyledTableCell>
            <StyledTableCell variant="body">0 ₿</StyledTableCell>
            <StyledTableCell variant="body">81.08 ₿</StyledTableCell>
            <StyledTableCell variant="body">167.59 ₿</StyledTableCell>
            <StyledTableCell variant="body">250.05 ₿</StyledTableCell>
            <StyledTableCell variant="body">379.81 ₿</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">Peter</StyledTableCell>
            <StyledTableCell variant="body">0 ₿</StyledTableCell>
            <StyledTableCell variant="body">56.64 ₿</StyledTableCell>
            <StyledTableCell variant="body">124.45 ₿</StyledTableCell>
            <StyledTableCell variant="body">95.01 ₿</StyledTableCell>
            <StyledTableCell sx={{ color: "indigo" }} variant="body">
              1073.67 ₿
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body">Sashi</StyledTableCell>
            <StyledTableCell variant="body">0 ₿</StyledTableCell>
            <StyledTableCell variant="body">15.62 ₿</StyledTableCell>
            <StyledTableCell variant="body">101.79 ₿</StyledTableCell>
            <StyledTableCell sx={{ color: "indigo" }} variant="body">
              1227.16 ₿
            </StyledTableCell>
            <StyledTableCell variant="body">1269.15 ₿</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{
              backgroundColor: "indigo",
              fontColor: "floralwhite",
              fontSize: "small",
            }}
          >
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Sum
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              1000 ₿
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              2000 ₿
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              3000 ₿
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              4000 ₿
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              5000 ₿
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{
              backgroundColor: "indigo",
              fontColor: "floralwhite",
              fontSize: "small",
            }}
          >
            <StyledTableCell sx={{ color: "salmon" }} variant="body">
              Total BTC (₿) in Circulation
            </StyledTableCell>
            <StyledTableCell sx={{ color: "salmon" }} variant="body">
              1000 ₿
            </StyledTableCell>
            <StyledTableCell sx={{ color: "salmon" }} variant="body">
              2000 ₿
            </StyledTableCell>
            <StyledTableCell sx={{ color: "salmon" }} variant="body">
              3000 ₿
            </StyledTableCell>
            <StyledTableCell sx={{ color: "salmon" }} variant="body">
              4000 ₿
            </StyledTableCell>
            <StyledTableCell sx={{ color: "salmon" }} variant="body">
              5000 ₿
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const BlockDifficultyOverTime = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "700px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Bitcoin Block
            </StyledTableCell>
            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Addition Date
            </StyledTableCell>

            <StyledTableCell sx={{ color: "floralwhite" }} variant="body">
              Difficulty
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Block 1</StyledTableCell>
            <StyledTableCell variant="body">Eight</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Block 50000</StyledTableCell>
            <StyledTableCell variant="body">~ 12-14 seconds</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ backgroundColor: "floralwhite" }}>
            <StyledTableCell variant="body">Near</StyledTableCell>
            <StyledTableCell variant="body">~ 1 second</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlockTimeTable;
