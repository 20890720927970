import React, { useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import { CenteredLayout } from "./layouts/Layout";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import useSound from "use-sound";
import Zoom from "@mui/material/Zoom";
import { styled } from "@mui/material/styles";
import regexifyString from "regexify-string";
import FillDialog from "./components/FillDialog";
import FillDialogPrimer from "./components/FillDialogPrimer";
import { motion } from "framer-motion";
import anime from "animejs";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
    fontSize: "0.7rem",
    fontWeight: "bolder",
  },
}));

export const FillQuiz = ({
  setProgressIndex,
  setProgress,
  progress,
  progressIndex,
  setProgressState,
  num,
  send,
  currentlyActive,
  primers,
  ...props
}) => {
  const [showDialog, setShowDialog] = React.useState(false || props.showDialog);
  const blanksOptions = Array.from({ length: num }, (_, i) => i + 1).reduce(
    (acc, curr) => ({ ...acc, [curr]: null }),
    {}
  );

  const [blank, setBlank] = React.useState(1);
  const [optionValue, setOptionValue] = React.useState(blanksOptions);
  const [wrong, setWrong] = React.useState(blanksOptions);
  const [completed, setCompleted] = React.useState(false);
  const [skip, setSkip] = React.useState(false);

  const [showCompleted, setShowCompleted] = React.useState(
    !currentlyActive && !props.showDialog
  );

  const [playActive] = useSound("/sounds/pop.mp3", { volume: 0.1 });
  const [playOff] = useSound("/sounds/popout.wav", { volume: 0.1 });
  const [playSelect] = useSound("/sounds/pop_select.wav", { volume: 0.1 });
  const [playWrong] = useSound("/sounds/wrong.mp3", { volume: 0.1 });
  const [playLevelCompleted] = useSound("/sounds/level_completed2.wav", {
    volume: 0.1,
  });
  const [playCorrect] = useSound("/sounds/correct.mp3", { volume: 0.1 });

  const blankProps = {
    setBlank,
    blank,
    optionValue,
    setOptionValue,
    playOff,
    playSelect,
    wrong,
    setWrong,
    playWrong,
    playLevelCompleted,
    playCorrect,
    setCompleted,
    skip,
    setSkip,
    setProgressState,
    num,
    showDialog,
  };

  const responseProps = {
    blank,
    optionValue,
    setOptionValue,
    playActive,
    playOff,
    playWrong,
    playLevelCompleted,
    playCorrect,
    wrong,
    setWrong,
    completed,
  };

  // PRevious : /\[(.*?)\]/gim

  const modifiedChildren = regexifyString({
    pattern: /\[(.*?)\]|\((.*?)\)|\*\*(.*?)\*\*/gim,
    decorator: (match, index) => {
      const re1 = /\[(.*?)\]/gim;
      const re2 = /\((.*?)\)/gim;
      const re3 = /\*\*(.*?)\*\*/gim;

      const match_blank = re1.exec(match);
      const match_code = re2.exec(match);
      const match_bold = re3.exec(match);

      if (match_blank) {
        const answer = match_blank[1];
        const split_result = answer.split("|");
        if (split_result.length === 1) {
          return (
            <Blank
              key={index + 1}
              showCompleted={showCompleted}
              id={index + 1}
              answer={answer}
              {...blankProps}
            />
          );
        } else if (split_result.length === 2) {
          return (
            <Blank
              key={index + 1}
              showCompleted={showCompleted}
              id={split_result[1]}
              answer={split_result[0]}
              {...blankProps}
            />
          );
        }
      } else if (match_code) {
        return <code key={index + 1}>{match_code[1]}</code>;
      } else if (match_bold) {
        return <b key={index + 1}>{match_bold[1]}</b>;
      }
    },
    input: props.children,
  });

  const renderMarkdown = regexifyString({
    pattern: /\((.*?)\)|\*\*(.*?)\*\*/gim,
    decorator: (match, index) => {
      const re2 = /\((.*?)\)/gim;
      const re3 = /\*\*(.*?)\*\*/gim;

      const match_code = re2.exec(match);
      const match_bold = re3.exec(match);
    },
    input: props.children,
  });

  const DialogProps = {
    completed,
    skip,
    setSkip,
    setProgressIndex,
    setProgress,
    progress,
    progressIndex,
    setProgressState,
    playWrong,
    setCompleted,
    primers,
  };

  const DialogPrimerProps = {
    completed,
    skip,
    setSkip,
    playWrong,
    setCompleted,
    setProgressState,
    send,
  };

  const animation = useRef(null);
  const buttonContainer = useRef(null);

  React.useEffect(() => {
    if (!currentlyActive && !props.showDialog) {
      setShowCompleted(true);
    }
  }, [currentlyActive]);

  useEffect(() => {
    if (currentlyActive) {
      const buttonRefs =
        buttonContainer.current.querySelectorAll(".ButtonOption");

      animation.current = anime.timeline({
        loop: false,
        autoplay: true,
      });

      animation.current.add({
        targets: buttonContainer.current,
        opacity: [0, 1],
        translateY: [25, 0],
        // begin: () => {
        //   send("NEXT");
        // },
      });

      animation.current.add({
        targets: buttonRefs,
        opacity: [0, 1],
        translateY: [25, 0],

        delay: function (el, i, l) {
          return i * 50;
        },
        complete: () => send("QUIZ"),
      });
    }
  }, []);

  const showButtons = currentlyActive || showDialog;
  return (
    <React.Fragment>
      <CenteredLayout>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            padding: "20px",
          }}
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h4" component="div">
              {props.header}
            </Typography>
            <br />
            <Typography variant="quiz" component="div">
              {modifiedChildren}
            </Typography>
          </div>
        </div>
        {showButtons && (
          <div
            ref={buttonContainer}
            style={{
              border: blank ? "3px dashed #47307f" : "3px dashed gray",
              borderRadius: "15px",
              justifyContent: "center",
              display: "flex",
              flexWrap: "wrap",
              padding: "30px",
              margin: "30px",
            }}
          >
            {props.options.map((opt, idx) => (
              <ResponseButton option={opt} key={idx} {...responseProps} />
            ))}
          </div>
        )}
      </CenteredLayout>
      {!showDialog && currentlyActive && (
        <FillDialogPrimer {...DialogPrimerProps} />
      )}
      {showDialog && (
        <motion.div
          animate={{ opacity: [0, 0, 0, 1] }}
          transition={{ duration: 1 }}
        >
          <FillDialog {...DialogProps} />
        </motion.div>
      )}
    </React.Fragment>
  );
};

const ResponseButton = ({
  option,
  blank,
  optionValue,
  setOptionValue,
  playActive,
  playOff,
  playWrong,
  playLevelCompleted,
  playCorrect,
  wrong,
  completed,
}) => {
  const [shake, setShake] = React.useState(false);
  const optionSelected = Object.values(optionValue).includes(option);
  const optionKey = Object.keys(optionValue).find(
    (k) => optionValue[k] === option
  );

  useEffect(() => {
    if (shake) {
      let timer2 = setTimeout(() => setShake(false), 500);
      return () => {
        clearTimeout(timer2);
      };
    }
  }, [shake]);

  useEffect(() => {
    if (wrong[optionKey] && optionKey == blank) {
      setShake(true);
    }
  }, [wrong]);

  const handleSelect = () => {
    if (blank) {
      if (optionSelected) {
        const optionKey = Object.keys(optionValue).find(
          (k) => optionValue[k] === option
        );
        playOff();
        setOptionValue({
          ...optionValue,
          [optionKey]: null,
        });
      } else {
        setOptionValue({
          ...optionValue,
          [blank]: option,
        });
      }
    }
  };

  const isWrong = wrong[optionKey];

  return (
    <BootstrapTooltip
      TransitionComponent={Zoom}
      title={optionSelected ? "Click to Remove" : "Click to Select"}
      arrow
    >
      <Button
        className={
          shake
            ? "shake-constant shake-horizontal ButtonOption"
            : "ButtonOption"
        }
        onClick={handleSelect}
        variant={optionSelected ? "contained" : "outlined"}
        style={{
          pointerEvents:
            isWrong === false ? "none" : completed ? "none" : "inherit",
          margin: "10px",
          fontSize: "0.8rem",
          color: blank ? (optionSelected ? "floralwhite" : "#47307f") : "gray",
          backgroundColor: isWrong
            ? "coral"
            : optionSelected
            ? "#58cb05"
            : "unset",
          borderColor: blank ? "gray" : "gray",
          transition: "0.1s",
        }}
      >
        {" "}
        {option}{" "}
      </Button>
    </BootstrapTooltip>
  );
};

export default FillQuiz;

const Blank = ({
  id,
  setBlank,
  blank,
  optionValue,
  setOptionValue,
  playOff,
  playSelect,
  answer,
  playWrong,
  playLevelCompleted,
  playCorrect,
  wrong,
  setWrong,
  setCompleted,
  setProgressState,
  num,
  skip,
  showDialog,
  showCompleted,
}) => {
  const [hover, setHover] = React.useState(false);
  const [shake, setShake] = React.useState(false);
  const isBlankSelected = id === blank;
  const value = optionValue[id];

  const handleClick = () => {
    if (value === null) {
      playSelect();
      setBlank(id);
    } else {
      const isWrong = optionValue[id] !== answer;
      if (isWrong) {
        playOff();
        setOptionValue({
          ...optionValue,
          [id]: null,
        });
      }
    }
  };

  useEffect(() => {
    if (shake) {
      let timer1 = setTimeout(() => setShake(false), 500);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [shake]);

  useEffect(() => {
    if (skip) {
      setOptionValue((option) => ({
        ...option,
        [id]: answer,
      }));
    }
  }, [skip]);

  useEffect(() => {
    if (showCompleted) {
      setOptionValue((option) => ({
        ...option,
        [id]: answer,
      }));
    }
  }, [showCompleted]);

  useEffect(() => {
    if (optionValue[id]) {
      const isWrong = optionValue[id] !== answer;
      if (isWrong) {
        playWrong();
        setWrong({ ...wrong, [id]: true });
        setShake(true);
      } else {
        const finalCorrect =
          Object.values(wrong).filter((x) => x === false).length ===
          Object.values(optionValue).length - 1;
        setWrong((wrong) => ({ ...wrong, [id]: false }));
        if (!skip) {
          if (finalCorrect) {
            playLevelCompleted();
            setCompleted(true);
            setProgressState("success");
          } else {
            playCorrect();
          }
        }
      }
    }
  }, [optionValue[id]]);

  if (value === null) {
    return (
      <BootstrapTooltip
        TransitionComponent={Zoom}
        title={isBlankSelected ? "Selected" : "Click to Select"}
        arrow
      >
        <div
          onClick={handleClick}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{
            display: "inline-block",
            borderBottom:
              isBlankSelected || hover
                ? "3px dashed #47307f"
                : "3px dashed gray",
            color: isBlankSelected || hover ? "#47307f" : "gray",
            cursor: "pointer",
            transition: "0.5s",
          }}
        >
          &emsp;&emsp;&emsp;<b>{id}</b>&emsp;&emsp;&emsp;
        </div>
      </BootstrapTooltip>
    );
  } else {
    const isWrong = wrong[id];
    return (
      <BootstrapTooltip
        TransitionComponent={Zoom}
        title={isWrong ? "Click to Remove" : "Correct"}
        arrow
      >
        <div
          className={shake ? "shake-constant shake-horizontal" : ""}
          onClick={handleClick}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{
            display: "inline-block",
            borderBottom: hover
              ? "3px dashed #47307f"
              : isWrong === null
              ? "3px dashed gray"
              : isWrong
              ? "3px dashed coral"
              : "3px solid #58cb05",
            color: hover
              ? "#47307f"
              : isWrong === null
              ? "gray"
              : isWrong
              ? "coral"
              : "#58cb05",
            cursor: "pointer",
            transition: "0.5s",
          }}
        >
          &emsp;&emsp;<b> {value}</b>&emsp;&emsp;
        </div>
      </BootstrapTooltip>
    );
  }
};

/*

Layouts 
0. With Information and Image
    a. Image on top
    b. Image on left/right
1. With no information and image 
    a. Information on above
    b. No Information
2. With Image
    a. Image on top
    b. Image on right
    c. Image on left
3. With Information and no Image 
*/
