export const start = [
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "{{user}}, in the previous quest, we learned to write and deploy smart contracts on the NEAR Protocol Blockchain",
        "And we interacted with the deployed smart contracts using the command line tool such as 'NEAR-CLI\"",
        '{{user}}, what do you think is the disadvantage of using "NEAR-CLI" for interacting with the smart contracts?',
      ],
      primerAvatar: "wave",
      textReply: {
        que: "What's the disadvantage of using command-line for interacting with the smart contracts?",
      },
      userReply: { reply: null, userAvatar: "Sleepy" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Working with command line is not very user-friendly",
        "In this quest, we will take a look at building a frontend application for our deployed smart contracts so anyone can use our smart contracts",
        "Let's take a look at how we can build a frontend for our deployed smart contracts",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "ApiInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "WalletMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "To interact with NEAR Protocol Blockchain from the front end...",
        '...we will make use of two libraries - "NEAR-API-JS" and "Wallet Selector"',
        "Let's take a look at how we can do that next",
      ],
      primerAvatar: "point",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "CounterInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "WalletSelectorMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'Now, that we have defined our helper "Wallet" class...',
        '..let\'s start writing a front end for our previously created "Counter" smart contract using the "Wallet" helper class',
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "CounterContractInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "ViewMethodMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'By default, we can call "view" methods without any cost for methods which uses upto 200 TGas',
        'If a method has higher gas requirement, we will need to call the method using "call" method',
        "Let's continue working on our Counter Dapp",
      ],
      primerAvatar: "game",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "CounterContractInfo2",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "We have successfully written a front end for our Counter Smart Contract.",
        "In the next lesson, we will take a look at creating building a frontend for Book's Rating Smart Contract",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "wave",
    },
  },
];
