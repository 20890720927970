import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../components/Common/Table.jsx";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
} from "@mui/material";

const BlockTimeTable = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        maxWidth: "900px",
        margin: "30px auto",
      }}
    >
      <Table size="medium">
        <TableHead>
          <StyledTableRow sx={{ backgroundColor: "#47307f" }}>
            <StyledTableCell
              sx={{
                color: "floralwhite",
                textAlign: "center",
                minWidth: "100px",
              }}
              variant="body"
            >
              Name
            </StyledTableCell>
            <StyledTableCell
              sx={{
                color: "floralwhite",
                textAlign: "center",
                minWidth: "100px",
              }}
              variant="body"
            >
              Account
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              World Health Organisation
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>who.donations.primerlabs.testnet</code>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              UNICEF
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>unicef.donations.primerlabs.testnet</code>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{ backgroundColor: "floralwhite", fontSize: "small" }}
          >
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              Doctors without Borders
            </StyledTableCell>
            <StyledTableCell variant="body" style={{ textAlign: "center" }}>
              <code>dwb.donations.primerlabs.testnet</code>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlockTimeTable;
