import React from "react";
import CircularCard from "../../../../../components/Card/CircularCard.jsx";

const NextLesson = ({ context }) => {
  const topic = context.topics["blockchain"][1].topics[1];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        maxWidth: "700px",
        margin: "30px auto",
        padding: "30px",
        fontFamily: "Nunito",
        borderRadius: "12px",
      }}
    >
      <h1 style={{ color: "#47307f" }}>Next Lesson</h1>
      <div style={{ maxWidth: "300px", margin: "auto" }}>
        <CircularCard
          newWindow={true}
          topic={topic}
          questName="blockchain"
          color={topic.color}
          title={topic.title}
          image={topic.image}
          stars={topic.stars}
          percent={topic.percent}
          slug={topic.slug}
        />
      </div>
    </div>
  );
};

export default NextLesson;
