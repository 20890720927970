import React, { Suspense } from "react";

const BookRatingDApp = React.lazy(() => import("./BookRatingDapp.jsx"));

const CounterContainer = () => {
  return (
    <div style={{}}>
      <Suspense fallback={<div>Loading...</div>}>
        <BookRatingDApp />
      </Suspense>
    </div>
  );
};

export default CounterContainer;
