import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AntTabs, AntTab } from "./Tabs";
{
  /* <span style={{ fontSize: "3rem" }}>{"\u201C"}</span>
 <span style={{ fontSize: "3rem" }}>{"\u201D"}</span> */
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography sx={{ color: "#47307f", display: "flex" }}>
            <span style={{ fontSize: "80px", margin: "0px", padding: "0px" }}>
              {"\u201C"}
            </span>
            <blockquote style={{ paddingTop: "10px", fontSize: "small" }}>
              {children}
            </blockquote>
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabPanelStyles = {
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  color: "#47307f",
};

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        display: "inline-block",
        bgcolor: "background.paper",
        border: "none",
      }}
    >
      <AntTabs
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderBottom: "none",
          borderRight: 1,
          borderColor: "divider",
          minWidth: "250px",
        }}
      >
        <AntTab label="Poltitics" {...a11yProps(0)} />
        <AntTab label="Monopoly" {...a11yProps(1)} />
        <AntTab label="Ownership" {...a11yProps(2)} />
        <AntTab label="Money" {...a11yProps(2)} />
      </AntTabs>
      <TabPanel style={tabPanelStyles} value={value} index={0}>
        First of all, the <b>Digitopian world</b> was supported on{" "}
        <b>a pleothera of physical servers</b>. These servers were directly
        under a <b>Phystopian business entity</b> based out of{" "}
        <b>a Phystopian country</b>. This allowed for Phystopian politics to
        seep in to the <b>Digitopian world.</b>
        <br />
        <br />
        The implication of such tanglement has various implications such as:
        <ul>
          <li>
            Countries would often <b>prohibit companies to work with users</b>{" "}
            from a country which didn't had favorable relations with them
          </li>
          <li>
            Companies could be pressurised to <b>hand over your activity</b> in
            Digitopia to the government
          </li>
          <li>
            Digitopian services could be manipulated to foster <b>propaganda</b>{" "}
            by rival countries
          </li>
          <li>
            Outspoken users about the government could be <b>censored</b>
          </li>
        </ul>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={1}>
        Many <b>Digitopian companies</b> became almost{" "}
        <b>all-powerful monopolistic faceless entity</b> in certain areas which
        enabled them to create their own rules and regulations without the fear
        of losing out on users. The users were dependent on them. Creation of
        monopoly is detrimental to healthy innovation and fosters dependency on
        the companies. We can understand the effects of monopoly through the
        following examples:
        <ul>
          <li>
            Let's take an example of a <b>taxi riding app</b> that innovated on
            booking taxis. In the earlier years of it's inception, it offered
            serious discounts due to <b>VC-fueled investment</b> which led to
            many local-taxi services going out of business. After a while, when
            the company was the de-facto player in the taxi business so much so
            that people were depedent on them, the app{" "}
            <b>could arbitrarily set the price on it's own whims</b> leaving
            people with no real alternatives.
          </li>
          <br />
          <li>
            A <b>social media company</b>, which became the primary voice of the
            people. However, the platform was used by{" "}
            <b>malicious actors for spreading misinformation</b> that could
            affect the lives of millions of people.
          </li>
          <br />
          <li>
            A monopolistic <b>video-streaming</b> company that could offer
            recommedation based on what someone watched previously, could
            continually push video recommedations that could present polarizing
            viewpoints. Most events have two sides of the story. The
            video-streaming could take advantage of it's monopolistic nature and
            <b>
              {" "}
              tweak the algorithm to present overwhelming evidence of only one
              side of picture
            </b>{" "}
            while hiding away the other one.
          </li>
          <br />
          <li>
            Or a <b>search engine</b> that would show you results not on basis
            of what you queried rather <b>what it believes you should know</b>.
          </li>
          <br />
          <li>
            A monopolistic <b>e-commerce</b> company can also replicate
            similar-offering product and place them higher in their listings to
            hurt a business that is dependent on them.{" "}
          </li>
        </ul>
        <b>
          Monopolstic digital companies posed serious threats to the idea of
          freedom that Digitopia represented
        </b>{" "}
        These companies have the power to <b>gatekeep</b>, <b>censor</b> or{" "}
        <b>outright defame individuals</b> without the fear of any consequence.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={2}>
        Even though many people are led to believe that things on the internet
        are kind of permanent, there is another side to this picture as well.
        Anything that you create, build or contribute on Digitopia, is only
        available until the goodwill of the company. You don't <b>"own"</b>{" "}
        anything.
        <br />
        <br />
        This is because <b>traditional web applications</b> store data in their
        own isolated servers. The company could go out of business, could
        outright ban or remove the user and delete the data without notifying
        them. <br />
        <br />
        Another concern with being dependent on <b>
          centralised applications
        </b>{" "}
        is <b>loss of privacy</b>. Any message, picture or video you store or
        exchange with your friends can be accessed by the application
        developers. Since, the application works as a black-box, you cannot
        never be a 100% sure if anyone else is eavesdropping on your
        conversation. <br />
        <br />
        <b>
          In summary, your data could be spied on, lost, corrupted, hacked or
          even intentionally deleted and there is little you can do to retrieve
          it.
        </b>
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={3}>
        Last but not the least, <b>Digitopia</b> didn't have their own form of
        money. <b>Digitopia</b> is highly dependent on the{" "}
        <b>Phystopian Money</b> to facilitate transactions.
        <br />
        <br />A <b>Digitopian</b> wanting to sell on <b>Digitopia</b> required
        to open a bank account with a <b>Phystopian bank</b>. Only then, the
        person could receive the money. But how about people who are present in
        areas where no such payment facility is available.
        <br />
        <br />A <b>Phystopian bank</b> is highly subjected{" "}
        <b>to the laws of the land and could freeze any transaction</b> it
        deemed unlawful. In fact, one of the major powers of any government is
        that it can direct the banks to <b>freeze assets</b> of any individual
        it deems a hazard. Diffident political opponents often have their assets
        seized by malicious governments. So, even if the rest of the world wants
        to help, they would be helpless due to this depedence on{" "}
        <b>Phystopian Monetary system</b>.
        <br />
        <br />
        <b>
          Can a world truly be free, if you cannot freely transact with another
          party?
        </b>
      </TabPanel>
    </Box>
  );
}
