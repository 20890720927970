import React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { Paper, Button } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={600} />;
});

const ConfirmationDialog = ({
  openDialog,
  setOpenDialog,
  context,
  setAnchorElUser,
  headerText = "Are you sure you want to reset progress?",
  bodyText = "You will lose all your progress made so far !",
  actionButton = "Reset",
  action = "RESET",
  ...props
}) => (
  <Dialog
    className="BottomDialog"
    fullWidth={true}
    open={openDialog}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => {
      setAnchorElUser(null);
      setOpenDialog(false);
    }}
    aria-describedby="alert-dialog-slide-description"
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "10px",
        maxWidth: "800px",
        width: "100%",
        margin: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "space-around",
        }}
      >
        <h2 style={{ marginBottom: "0px" }}>{headerText}</h2>
        <p style={{ color: "darkgray" }}>{bodyText}</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Button
          sx={{ margin: "10px" }}
          variant="outlined"
          onClick={() => {
            setOpenDialog(false);
            setAnchorElUser(null);
          }}
        >
          Stay
        </Button>
        <Button
          sx={{ margin: "10px", whiteSpace: "pre" }}
          variant="contained"
          onClick={() => {
            setOpenDialog(false);
            if (action == "RESET") {
              setAnchorElUser(null);
              context.resetProgress();
            } else if (action == "SIGNOUT") {
              setAnchorElUser(null);
              context.handleSignOut();
              props.setShowSignOutAlert(true);
            }
          }}
        >
          {actionButton}
        </Button>
      </div>
    </div>
  </Dialog>
);

export default ConfirmationDialog;
