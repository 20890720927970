import { guestBookPrimers } from "./GuestBook";

const yesPrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Incorrect, {{user}}",
        "Function Call Access Key cannot be used to transfer funds",
        'Let\'s understand the use of "Function Call Access Key" with an example.',
      ],
      primerAvatar: "sad",
    },
  },
  ...guestBookPrimers,
];

const nopePrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Correct, {{user}}",
        "Function Call Access Key cannot be used to transfer funds",
        'Let\'s understand the use of "Function Call Access Key" with an example.',
      ],
      primerAvatar: "thumbs-up",
    },
  },
  ...guestBookPrimers,
];

export const buttonOptions = [
  {
    text: "Nope",
    userReply: {
      reply: [
        "Nope, Primer",
        "I don't think function call access key can be used to transfer funds",
      ],
      userAvatar: "Happy",
    },
    primers: nopePrimers,
  },
  {
    text: "Yep",
    userReply: {
      reply: [
        "Yes, Primer",
        "Function call access key can be used to transfer funds",
      ],
      userAvatar: "Smirk",
    },
    primers: yesPrimers,
  },
];
