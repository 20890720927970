import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "It's free of cost",
    "Depends on the size of the smart contract",
    "Depends on the current block's gas price",
    "Depends on the gas required to finish execution",
  ];
  const correctOption = "Depends on the gas required to finish execution";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, what are the charges to call the <code>view</code>{" "}
          method?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
