import { OutputFactory, Outputs } from "javascript-terminal";
import { CustomOutputFactory } from "../outputs";
import { arraysEqual } from "./utils";

const helpText = `There are multiple ways you can use &&near&& [[generate-key]] command. 

**1. Creating implicit accounts.**

**Usage**: &&near&& [[generate-key]]

    ^^Creates implcit accounts and stores the generated credentials in the localstorage^^

**2. Creating an implicit account using a seedphrase**  

**Usage**: &&near&& [[generate-key]] @@--seedPhrase@@ **<12 word seedphrase>**

    ^^Creates implcit accounts using a seedphrase and stores the generated credentials in the localstorage. You can use the seedphrase to recover the account later^^

**3. Creating a keypair for an account**

**Usage**: &&near&& [[generate-key]] @@<accountId>@@

    ^^Creates a new keypair for an account and stores the generated credentials. If any keypair exists in the localstorage for the same accountId, it's overwritten^^

**4. Creating a keypair for an account using a seedphrase**

**Usage**: &&near&& [[generate-key]] @@<accountId>@@ @@--seedPhrase@@ **<12 word seedphrase>**

    ^^Creates a new keypair for an account using a seedphrase and stores the generated credentials. If any keypair exists in the localstorage for the same accountId, it's overwritten^^


**Notes**:
    1. If there is an already existing keypair with the same &&accountId&&, it is overwritten with the generated one.  
     
`;

const generateErrors = (errors) => {
  let mappedText = errors.reduce((acc, curr) => {
    return acc + `\n\t&&` + " * " + curr + `&&`;
  }, ``);
  return `**Encountered Following Errors**
${mappedText}

**Usage**: &&near&& [[generate-key]]

    For more info type:
      &&near&& [[generate-key]] @@--help@@ 

`;
};

const incorrectCommandUsage = "Incorrect command usage";
const missingMaster = `Missing Master Account`;
const missingAccountID = `Missing Account ID`;
const invalidAccountId = `Invalid Account ID provided`;
const endWithTestnet = `Master account must end with testnet`;
const accEndWithTestnet = `Account Id must end with ".testnet"`;
const notLongEnough = `AccountId should be greater than 32 characters`;
const tooLong = `AccountId should be smaller than 64 characters`;
const invalidMasterAccount = `Invalid Master Account Id provided`;

const checkDescendant = (account, masterAccount) => {
  return arraysEqual(account.split(".").slice(1), masterAccount.split("."));
};

const checkForErrors = (accountId, options, loggedInAccount) => {
  let errors = [];

  const regexCheck =
    /^(([a-z\d]+[\-_])*[a-z\d]+\.)*([a-z\d]+[\-_])*[a-z\d]+$/im;

  if (!options.masterAccount) {
    errors.push(missingMaster);
    return errors;
  }

  if (!regexCheck.test(accountId)) {
    errors.push(invalidAccountId);
    return errors;
  }

  if (!regexCheck.test(options.masterAccount)) {
    errors.push(invalidMasterAccount);
    return errors;
  }

  if (!accountId.endsWith(".testnet")) {
    errors.push(accEndWithTestnet);
    return errors;
  }

  if (!options.masterAccount.endsWith(".testnet")) {
    errors.push(endWithTestnet);
    return errors;
  }

  if (!options.masterAccount.endsWith(loggedInAccount)) {
    const notAuthorised = `Not authorised to access the master account "${options.masterAccount}" Logged In account is ${loggedInAccount}`;
    errors.push(notAuthorised);
    return errors;
  }

  if (!checkDescendant(accountId, options.masterAccount)) {
    const notDescendent = `Provided accountId: ${accountId} is not a direct subaccount of the master account: ${options.masterAccount}`;
    errors.push(notDescendent);
    return errors;
  }

  if (accountId.length > 64) {
    errors.push(tooLong);
    return errors;
  }

  return errors;
};

const updateState = (type, message, setCustomState, setAcceptInput) => {
  if (type === "error") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(`&&${message}&&`)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  } else if (type === "success") {
    setCustomState((prevState) => {
      const oldOutputs = prevState.getOutputs();
      const newOutputs = Outputs.addRecord(
        oldOutputs,
        CustomOutputFactory.createColoredRecord(message)
      );
      const newState = prevState.setOutputs(newOutputs);
      return newState;
    });
  }

  setAcceptInput(true);
};

export const generateKeyCommand = (
  context,
  options = {},
  args = {},
  stateChangeProps = {}
) => {
  if (options.help) {
    return {
      output: CustomOutputFactory.createColoredRecord(helpText),
    };
  } else {
    if (args.length === 1) {
      const { setAcceptInput, setCustomState } = stateChangeProps;

      if (options.seedPhrase) {
        // Generate Implicit Account with Seedphrase
        context
          .generateKey(null, options.seedPhrase)
          .then((res) => {
            let type = "success";
            let message = `Key pair with [[${res.publicKey}]] public key for an account [[${res.account}]] created and stored in the browser localstorage`;
            updateState(type, message, setCustomState, setAcceptInput);
          })
          .catch((e) => {
            let type = "error";
            let message = e.message;
            updateState(type, message, setCustomState, setAcceptInput);
          });

        return {
          output: CustomOutputFactory.createColoredRecord(
            `Creating keypair for an implicit account using the provided seedphrase...`
          ),
        };
      } else {
        // Generate Implicit Account without seedphrase
        context
          .generateKey()
          .then((res) => {
            let type = "success";
            let message = `Key pair with [[${res.publicKey}]] public key for an account [[${res.account}]] created and stored in the browser localstorage`;
            updateState(type, message, setCustomState, setAcceptInput);
          })
          .catch((e) => {
            let type = "error";
            let message = e.message;
            updateState(type, message, setCustomState, setAcceptInput);
          });

        return {
          output: CustomOutputFactory.createColoredRecord(
            `Creating keypair for an implicit account...`
          ),
        };
      }
    } else if (args.length === 2) {
      const [_, accountId] = args;
      const { setAcceptInput, setCustomState } = stateChangeProps;
      setAcceptInput(false);
      if (options.seedPhrase) {
        // Generate an Account with seedphrase
        context
          .generateKey(accountId, options.seedPhrase)
          .then((res) => {
            let type = "success";
            let message = `Key pair with [[${res.publicKey}]] public key for an account [[${res.account}]] created and stored in the browser localstorage`;
            if (res.prevAccount !== null) {
              message = `
Note: Previous stored [[Public Key]]:&&${res.prevAccount}&& has been overwritten. 

Key pair with [[${res.publicKey}]] public key for an account [[${res.account}]] created and stored in the browser localstorage
              `;
            }

            updateState(type, message, setCustomState, setAcceptInput);
          })
          .catch((e) => {
            let type = "error";
            let message = e.message;
            updateState(type, message, setCustomState, setAcceptInput);
          });

        return {
          output: CustomOutputFactory.createColoredRecord(
            `Generating a new keypair for account [[${accountId}]]...`
          ),
        };
      } else {
        // Generate an Account without seedphrase
        context
          .generateKey(accountId)
          .then((res) => {
            let type = "success";
            let message = `Key pair with [[${res.publicKey}]] public key for an account [[${res.account}]] created and stored in the browser localstorage`;
            if (res.prevAccount !== null) {
              message = `
Note: Previous stored [[Public Key]]:&&${res.prevAccount}&& has been overwritten. 

Key pair with [[${res.publicKey}]] public key for an account [[${res.account}]] created and stored in the browser localstorage
              `;
            }
            updateState(type, message, setCustomState, setAcceptInput);
          })
          .catch((e) => {
            let type = "error";
            let message = e.message;
            updateState(type, message, setCustomState, setAcceptInput);
          });

        return {
          output: CustomOutputFactory.createColoredRecord(
            `Generating a new keypair for account [[${accountId}]]...`
          ),
        };
      }
    } else {
      const errors = [incorrectCommandUsage];
      const errorText = generateErrors(errors);
      return {
        output: CustomOutputFactory.createColoredRecord(errorText),
      };
    }
  }
};
