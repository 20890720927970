import React, { useRef, useEffect } from "react";
import { Button, Paper } from "@mui/material";
import anime from "animejs";

const TextNext = ({ send, ...props }) => {
  const animation = useRef(null);
  const buttonContainer = useRef(null);

  useEffect(() => {
    const buttonRefs = buttonContainer.current.querySelectorAll(".UserButton");

    animation.current = anime.timeline({
      loop: false,
      autoplay: true,
    });

    animation.current.add({
      targets: buttonContainer.current,
      opacity: [0, 1],
      translateY: [25, 0],
      begin: () => {
        send("CONTINUE");
        //   buttonContainer.current.scrollIntoView({
        //     behavior: "smooth",
        //   });
      },
    });

    animation.current.add({
      targets: buttonRefs,
      opacity: [0, 1],
      translateY: [25, 0],
      delay: function (el, i, l) {
        return i * 100;
      },
    });
  }, []);

  const handleClick = () => {
    send({
      type: "NEXT",
    });
  };

  return (
    <Paper
      ref={buttonContainer}
      style={{
        textAlign: "center",
        background: "aliceblue",
        borderRadius: "12px",
        margin: "30px",
      }}
    >
      <Button
        className="UserButton"
        variant="outlined"
        onClick={handleClick}
        style={{
          height: "3rem",
          fontSize: "1rem",
          margin: "30px",
          transition: "0.1s",
        }}
      >
        Continue
      </Button>
    </Paper>
  );
};

export default TextNext;
