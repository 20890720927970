import React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { Paper, Button } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={600} />;
});

const LessonDialog = ({
  handleClose,
  showPrompt,
  confirmNavigation,
  cancelNavigation,
}) => (
  <Dialog
    className="BottomDialog"
    fullWidth={true}
    open={showPrompt}
    TransitionComponent={Transition}
    keepMounted
    onClose={cancelNavigation}
    aria-describedby="alert-dialog-slide-description"
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "10px",
        maxWidth: "800px",
        width: "100%",
        margin: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "space-around",
        }}
      >
        <h2 style={{ marginBottom: "0px" }}>Are you sure you want to quit?</h2>
        <p style={{ color: "darkgray" }}>
          You are just little far from completing the lesson
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Button
          sx={{ margin: "10px" }}
          variant="outlined"
          onClick={cancelNavigation}
        >
          Stay
        </Button>
        <Button
          sx={{ margin: "10px" }}
          variant="contained"
          onClick={confirmNavigation}
        >
          Quit
        </Button>
      </div>
    </div>
  </Dialog>
);

export default LessonDialog;
