import React from "react";
import Wrapper from "./NodeWrapper";
import { Box } from "@mui/material";
import Handle from "./Handle";
// import Slider from "@mui/material/Slider";
import { PrettoSlider } from "./Slider";
import { checkNear } from "./Canvas";
import toast from "react-hot-toast";

export default function AccountNode({ data }) {
  const {
    label = "",
    min = 0,
    max = 1,
    onChange = () => {},
    value = 5,
    calculatorData,
  } = data;

  const handleChange = (value) => {
    const {
      contractDeployed,
      contractSize,
      funcKeys,
      fullKeys,
      storageUsed,
      storage_amount_per_byte,
    } = calculatorData;

    let checkNearAmt = checkNear(
      contractDeployed,
      contractSize,
      funcKeys,
      fullKeys,
      storageUsed,
      value,
      storage_amount_per_byte
    );
    if (checkNearAmt.nearAvailable > 0) {
      onChange(value);
    } else {
      toast.error(
        `Cannot decrease further as there are not enough NEAR Tokens Ⓝ to cover for ${checkNearAmt.totalStorage} B of data`,
        {
          id: "near",
        }
      );
    }
  };

  return (
    <Wrapper label={label}>
      <Handle type="source" position="right" />
      <Box>
        <div style={{ textAlign: "center" }}>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              margin: "4px auto",
              color: "#47307f",
            }}
          >
            100 B
          </span>
        </div>
        <PrettoSlider
          size="small"
          value={value}
          valueLabelDisplay="auto"
          onChange={(evt) => {
            handleChange(evt.target.value);
          }}
          step={1}
          min={min}
          max={max}
        />
        <div style={{ textAlign: "center" }}>
          <span style={{ fontWeight: "bold", fontSize: "12px" }}>
            NEAR Balance : {value} Ⓝ
          </span>

          <br />
          <span
            style={{ fontWeight: "bold", fontSize: "12px", margin: "4px auto" }}
          >
            primerlabs.near
          </span>
        </div>
        <br />
      </Box>
    </Wrapper>
  );
}
