// Infos
import Hello from "./PrimerComponents/Hello.mdx";
import Counter from "./PrimerComponents/Counter.mdx";
import CounterTest from "./PrimerComponents/CounterTest.mdx";
import StorageStaking from "./PrimerComponents/StorageStaking.mdx";
import StateInfo from "./PrimerComponents/StateInfo.mdx";
import StateInit from "./PrimerComponents/StateInit.mdx";
import StateInit2 from "./PrimerComponents/StateInit2.mdx";
import Anatomy from "./PrimerComponents/Anatomy.mdx";
// Next Lesson
import NextLesson from "./PrimerComponents/NextLesson";

// Quiz
import DeployMCQ from "./Quiz/DeployMCQ";
import StorageMCQ from "./Quiz/StorageMCQ";
import ContractMCQ from "./Quiz/ContractMCQ";
import DeployMatch from "./Quiz/DeployMatch";
import ContractMCQ2 from "./Quiz/ContractMCQ2";

// Primers
import { start } from "./Primers/Start";

const primers = [...start];

const primerComponents = {
  Hello: Hello,
  Counter: Counter,
  NextLesson: NextLesson,
  DeployMCQ: DeployMCQ,
  CounterTest: CounterTest,
  StorageMCQ: StorageMCQ,
  StorageStaking: StorageStaking,
  ContractMCQ: ContractMCQ,
  StateInfo: StateInfo,
  DeployMatch: DeployMatch,
  StateInit: StateInit,
  StateInit2: StateInit2,
  ContractMCQ2: ContractMCQ2,
  Anatomy: Anatomy,
};

const imageComponents = [];

const quizList = [
  "DeployMCQ",
  "StorageMCQ",
  "ContractMCQ",
  "DeployMatch",
  "ContractMCQ2",
];

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
