import React from "react";
import AccessKeysExplorable from "../../AccessKeysExplorable";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Paper } from "@mui/material";
import { initialAccountKeys as accountkeys } from "../../AccessKeysExplorable/keysData";
import useSound from "use-sound";

const checkImplicit = (h) => {
  let regExp = /^([0-9a-f]{64})$/i;
  return regExp.test(h);
};

const getExercise = (id) =>
  ({
    1: (
      <span>
        Create a keypair with no <code>accountId</code> on the{" "}
        <code>testnet</code> network
      </span>
    ),
    2: (
      <span>
        Create a keypair with the <code>accountId=primerlabs.near</code> on the{" "}
        <code>mainnet</code> network
      </span>
    ),
    3: (
      <span>
        Create a keypair with the <code>accountId=primerlabs.testnet</code>{" "}
        using the{" "}
        <code>
          seedphrase=slow butter deliver toward erode order lion monitor believe
          remember amount almost
        </code>{" "}
        on the <code>testnet</code> network
      </span>
    ),
  }[id]);

const ExerciseBox = ({ id, filled = false }) => (
  <Alert severity={filled ? "success" : "error"}>{getExercise(id)}</Alert>
);

const GenerateKeyPair = () => {
  const [completed, setCompleted] = React.useState({});
  const [played, setPlayed] = React.useState({});
  const [initialAccessKeys, setInitialAccessKeys] = React.useState([]);
  const [initialAccountKeys, setInitialAccountKeys] =
    React.useState(accountkeys);

  const [playCorrect] = useSound("/sounds/correct.mp3", { volume: 0.1 });

  const explorableProps = {
    defaultAccessKeys: [],
    defaultAccountKeys: initialAccountKeys,
    setInitialAccessKeys,
    setInitialAccountKeys,
  };

  React.useEffect(() => {
    // if (Object.values(completed).every((x) => x === true)) {
    //   playCorrect();
    // }

    if (
      Object.values(completed).filter((x) => x === true).length >
      Object.values(played).filter((x) => x === true).length
    ) {
      playCorrect();
    }
    setPlayed(completed);
  }, [completed]);

  React.useEffect(() => {
    // 1: Check Implicit Account ID on the testnet
    const implicitExists = initialAccessKeys.some(
      (x) => checkImplicit(x.account_id) && x.network === "testnet"
    );

    // 2: Account: primerlabs.near exists on the mainnet
    const primerlabsNearExists = initialAccessKeys.some(
      (x) => x.account_id === "primerlabs.near" && x.network === "mainnet"
    );

    // 3: Account primerlabs.testnet exists on the testnet
    const primerlabsTestnetExists = initialAccessKeys.some(
      (x) =>
        x.account_id === "primerlabs.testnet" &&
        x.network === "testnet" &&
        x.publicKey === "ed25519:5rav8NrrVeJBVZrsDoLCBdZ9AWWmKUexwXAWkTY9xvDn"
    );

    setCompleted({
      1: implicitExists,
      2: primerlabsNearExists,
      3: primerlabsTestnetExists,
    });
  }, [initialAccessKeys]);

  return (
    <Paper elevation={4} style={{ padding: "20px", borderRadius: "10px" }}>
      <h2 style={{ textAlign: "center", color: "#47307f" }}>Exercises</h2>
      <Stack sx={{ width: "100%", marginBottom: "20px" }} spacing={2}>
        <ExerciseBox id={"1"} filled={completed[1]} />
        <ExerciseBox id={"2"} filled={completed[2]} />
        <ExerciseBox id={"3"} filled={completed[3]} />
      </Stack>
      <AccessKeysExplorable type="GENERATE" {...explorableProps} />
    </Paper>
  );
};

export default GenerateKeyPair;
