import React from "react";
import { sha256 } from "js-sha256";
import { HashTextField, CssTextField } from "./styledComponents";
import { Paper } from "@mui/material";
import { PrettoSlider } from "../Common/Canvas/StorageCanvas/Slider";
import { encrypt, decrypt } from "caesar-shift";

const Hash = ({ userData = null, decrypted = null }) => {
  const [data, setData] = React.useState(
    userData
      ? userData
      : "Hi There! Change this text to see the hash value below change."
  );
  const [shift, setShift] = React.useState(1);

  if (decrypted) {
    return (
      <div style={{ maxWidth: "500px", margin: "30px auto" }}>
        <Paper
          elevation={3}
          sx={{ padding: "30px", display: "flex", flexDirection: "column" }}
        >
          <CssTextField
            label="Coded Message"
            multiline
            rows={4}
            value={data}
            onChange={(evt) => setData(evt.target.value)}
          />
          <br />
          <h4 style={{ textAlign: "center", margin: "2px" }}>
            Caeser's Shift: {shift}
          </h4>
          <PrettoSlider
            size="small"
            value={shift}
            valueLabelDisplay="auto"
            onChange={(evt) => {
              setShift(evt.target.value);
            }}
            step={1}
            min={0}
            max={25}
          />
          <br />
          <CssTextField
            label="Message"
            multiline
            rows={4}
            value={decrypt(shift, data)}
          />
        </Paper>
      </div>
    );
  } else {
    return (
      <div style={{ maxWidth: "500px", margin: "30px auto" }}>
        <Paper
          elevation={3}
          sx={{ padding: "30px", display: "flex", flexDirection: "column" }}
        >
          <CssTextField
            label="Message"
            multiline
            rows={4}
            value={data}
            onChange={(evt) => setData(evt.target.value)}
          />
          <br />
          <h4 style={{ textAlign: "center", margin: "2px" }}>
            Caeser's Shift: {shift}
          </h4>
          <PrettoSlider
            size="small"
            value={shift}
            valueLabelDisplay="auto"
            onChange={(evt) => {
              setShift(evt.target.value);
            }}
            step={1}
            min={0}
            max={25}
          />
          <br />
          <CssTextField
            label="Coded Message"
            multiline
            rows={4}
            value={encrypt(shift, data)}
          />
        </Paper>
      </div>
    );
  }
};

export default Hash;
