import { restPrimers } from "./RestPrimers";

const yesPrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Correct, {{user}}",
        "For a node, it is a waste of computational resource to mine an already mined block.",
        "So, the node adds the block to it's already stored blockchain and moves onto compete for the next block.",
        "Now, Let's take a quick quiz to cement your understanding",
      ],
      primerAvatar: "thumbs-up",
    },
  },
  ...restPrimers,
];

const noPrimers = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Incorrect, {{user}}",
        "For a node, it is a waste of computational resource to mine an already mined block.",
        "So, the node adds the block to it's already stored blockchain and moves onto compete for the next block.",
        "Now, Let's take a quick quiz to cement your understanding",
      ],
      primerAvatar: "oops",
    },
  },
  ...restPrimers,
];

const buttonOptions = [
  {
    text: "Yes",
    userReply: {
      reply: [
        "Yes Primer.",
        "Once, a node receives a valid node it stops mining",
      ],
      userAvatar: "Smirk",
    },
    primers: yesPrimers,
  },
  {
    text: "Nope",
    userReply: {
      reply: ["Ummm...Nope Primer", "I don't think so", "It still continues"],
      userAvatar: "Cheeks",
    },
    primers: noPrimers,
  },
];

export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'To understand how we can create "decentralised ledger", let\'s start with a small example.',
        "Let's take an example of a group of people who live together and maintain of a ledger of who owes how much to whom.",
      ],
      primerAvatar: "notepad",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "FriendsLedger",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "LedgerMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        "The decentralised friends ledger is a pretty good for a small group of people who live with each other.",
        "However, there are too many problems associated with it to be able to scale it up to the whole world.",
        "{{user}}, can you think of any problem for scaling it up for the world?",
      ],
      primerAvatar: "puzzle",
      textReply: {
        que: "Any problem for scaling this ledger up for the world?",
      },
      userReply: { reply: null, userAvatar: "Smirk" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'There are several problems associated with creating a "global decentralised ledger."',
        "Let's take a look at some of them.",
      ],
      primerAvatar: "earth",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Problems",
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        'Let\'s start with the problem of "settlement".',
        "{{user}}, how do you think we can ensure that when someone records a transaction for a transfer, the transaction actually occurs?",
      ],
      primerAvatar: "question",
      textReply: {
        que: "How can we ensure a transaction is settled?",
      },
      userReply: { reply: null, userAvatar: "Happy" },
    },
  },
  {
    type: "CHAT",
    reply: "TEXT",
    props: {
      chats: [
        'The best way to ensure that the settlement to occur is to introduce a form of "money" or "token" or "credit" into the ledger itself.',
        'And to ensure, all the the tokens are accounted for, the only place the token can be used is this "decentralised ledger".',
        "{{user}}, which token do you think Satoshi Nakamoto introduced in their global decentralised ledger?",
      ],
      primerAvatar: "dollar",
      textReply: {
        que: "Which token was introduced by Satoshi Nakamoto?",
      },
      userReply: { reply: null, userAvatar: "Cute" },
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'The token which was introduced by Satoshi in his global decentralised ledger was called "Bitcoin"',
        'Before taking a look at how we can use "Bitcoin" as a form of money, let\'s take a look at the concept of money itself.',
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Bitcoin",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "GoatMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'Fundamentally we require a "scarce asset" to be used a form of money',
        'In the digital world, where everything can be forged or duplicated it"s difficult to create a digitally scarce asset.',
        'Let\'s take a look at how Satoshi designed Bitcoin to be a "scarce digital asset"',
      ],
      primerAvatar: "patient",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "BitcoinDesign",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "BitcoinMCQ",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "So, every block creates Bitcoins that can then be used to transfer around.",
        "Now, let's learn more about the Bitcoin infrastructure in detail",
      ],
      primerAvatar: "thumbs-up",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "BitcoinSendInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "BitcoinReorder",
    },
  },
  {
    type: "CHAT",
    reply: "NONE",
    props: {
      chats: [
        "Now this is a lot of information to process so far",
        "So we will take a break here and will continue learning about the bitcoin ledger in next lesson",
        "See you in the next lesson, {{user}}",
      ],
      primerAvatar: "wave",
    },
  },
];

// {
//   type: "CHAT",
//   reply: "BUTTON",
//   props: {
//     primerAvatar: "question",
//     chats: [
//       "Let me ask you a question",
//       "{{user}}, if a node receives a valid mined block, do you think it stops mining.",
//     ],
//     userReply: {
//       reply: null,
//       userAvatar: null,
//     },
//     buttonOptions: buttonOptions,
//   },
// },
