import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";
import Code from "./Code.mdx";

const MCQ = (props) => {
  const options = [
    "Yes, account has a smart contract",
    "No, account doesn't have a smart contract",
    "Cannot say",
  ];
  const correctOption = "Yes, account has a smart contract";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, we have the following account state.
          <div style={{ textAlign: "left", fontSize: "smaller" }}>
            <Code />
          </div>
          Does the account have any <b>smart contract</b> deployed into the
          account?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
