import ShortPrimer from "../../../../../components/ShortPrimers";

const Text = () => (
  <p>
    You can get an overview of different types of{" "}
    <span style={{ color: "indigo" }}>wallet</span> available on{" "}
    <span style={{ color: "indigo" }}>NEAR Protocol Ecosysyem</span> by checking
    out this <span style={{ color: "indigo" }}>short guide</span>
  </p>
);

export const WalletPrimer = () => (
  <ShortPrimer type="wallet-primer" text={Text} />
);

export const LibraryInfo = () => (
  <div>
    In order to use{" "}
    <a target="_blank" href="https://github.com/near/wallet-selector">
      <code>Wallet Selector</code>
    </a>{" "}
    and the{" "}
    <a target="_blank" href="https://github.com/near/near-api-js">
      <code>NEAR API JS</code>
    </a>{" "}
    you will need to first add them to your project. The{" "}
    <code>wallet-selector</code> has
    <b> multiple wallet packages</b> to select from that you can see on their
    <a
      target="_blank"
      href="https://github.com/near/wallet-selector#installation-and-usage"
    >
      <code>website.</code>
    </a>
  </div>
);

// export const SandFrame = () => (
//   <iframe
//     src="https://codesandbox.io/embed/counter-contract-application-with-blockchain-brrsym?autoresize=1&fontsize=14&hidenavigation=1&hidedevtools=1&theme=dark&view=preview"
//     style={{
//       width: "100%",
//       height: "500px",
//       border: "0",
//       borderRadius: "4px",
//       overflow: "hidden",
//     }}
//     title="Counter-Contract Application with Blockchain"
//     sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-cross-origin  allow-scripts"
//   ></iframe>
// );
