import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Calculating profit or loss was tedious",
    "Does Not Reflect True Financial Position",
    "Prone to Frauds And Errors",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which of the following is a disadvantage of using the{" "}
          <b>single-entry accounting</b>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
