import MediaCard from "../Card/MediaCard";
import InteractiveCard from "../Card/InteractiveCard";
import DAppCard from "../Card/DAppCard";
import { useAppContext } from "../../AppContext";
import Popover from "@mui/material/Popover";
import GuideCard from "../Card/GuideCard";
import {
  explorableData,
  dappData,
  guideData,
  guideDataPopover,
} from "../Explorables/ExplorableData";
import LoveWallContainer from "../Explorables/Common/OneOff/DappSandbox/LoveWallContainer";

{
  /* <img src="https://assets.primerlabs.io/PrimerAvatar150/welcome.png" /> */
}

const QuestList = () => {
  const context = useAppContext();
  const { quests } = context;

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          background: "floralwhite",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            fontFamily: "Corben",
            fontSize: "36px",
            letterSpacing: "2px",
            color: "rgb(71, 48, 127)",
          }}
        >
          Quests
        </h1>
        <div style={{ display: "flex", maxWidth: "800px", margin: "auto" }}>
          {" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontSize: "34px",
                fontVariant: "all-small-caps",
                color: "rgb(71, 48, 127)",
                fontWeight: "bold",
                marginTop: "0px",
              }}
            >
              Take the{" "}
              <span
                style={{
                  fontFamily: "monospace",
                  color: "salmon",
                  fontWeight: "bold",
                  fontSize: "2.5rem",
                }}
              >
                first step
              </span>{" "}
              into the world of {"   "}
              <span
                style={{
                  fontFamily: "monospace",
                  color: "salmon",
                  fontWeight: "bold",
                  fontSize: "2.5rem",
                }}
              >
                NEAR PROTOCOL blockchain
              </span>
            </p>
          </div>
        </div>

        <div className="QuestCards" style={{ marginBottom: "60px" }}>
          {quests.map((course, idx) => (
            <MediaCard key={idx} index={idx} {...course} />
          ))}
        </div>
      </div>
      <div
        style={{
          backgroundColor: "darkslateblue",
          padding: "20px",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            fontFamily: "Corben",
            fontSize: "36px",
            letterSpacing: "2px",
            color: "salmon",
            marginBottom: "0px",
          }}
        >
          Short Guides
        </h1>
        <p
          style={{
            textAlign: "center",
            fontSize: "24px",
            fontVariant: "all-small-caps",
            color: "salmon",
            fontWeight: "bold",
            letterSpacing: "2px",
            marginTop: "0px",
          }}
        >
          Quick and short guides on various important topics
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
            alignItems: "center",
            maxWidth: "700px",
            margin: "auto",
          }}
        >
          {Object.entries(guideData).map((guide, idx) => (
            <GuideCard key={idx} guide={guide} />
          ))}
        </div>
      </div>
      <div
        style={{
          backgroundColor: "peachpuff",
          padding: "20px",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            fontFamily: "Corben",
            fontSize: "36px",
            letterSpacing: "2px",
            color: "darkslateblue",
            marginBottom: "0px",
          }}
        >
          Decentralised Applications
        </h1>
        <p
          style={{
            textAlign: "center",
            fontSize: "24px",
            fontVariant: "all-small-caps",
            color: "darkslateblue",
            fontWeight: "bold",
            letterSpacing: "2px",
            marginTop: "0px",
          }}
        >
          Check out the Decentralised Applications we will build in the course.
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {dappData.map((course, idx) => (
            <DAppCard key={idx} {...course} />
          ))}
        </div>
      </div>

      <div
        style={{
          backgroundColor: "aliceblue",
          paddingTop: "20px",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            fontFamily: "Corben",
            fontSize: "36px",
            letterSpacing: "2px",
            color: "darkslateblue",
            marginTop: "0px",
          }}
        >
          Interactive Explorables
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {explorableData.map((course, idx) => (
            <InteractiveCard key={idx} {...course} />
          ))}
        </div>
      </div>

      <div
        style={{
          padding: "20px",
          background: "slateblue",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            fontFamily: "Corben",
            fontSize: "36px",
            letterSpacing: "2px",
            color: "floralwhite",
          }}
        >
          Wall of <span style={{ color: "pink" }}>Love</span>
        </h1>
        <div
          style={{
            display: "flex",
            maxWidth: "800px",
            margin: "auto",
          }}
        >
          <div>
            <img src="https://assets.primerlabs.io/PrimerAvatar150/love-2.png" />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontSize: "24px",
                fontVariant: "all-small-caps",
                color: "floralwhite",
                fontWeight: "bold",
                letterSpacing: "2px",
                margin: "10px",
              }}
            >
              If you truly enjoyed the <u style={{ color: "pink" }}>course</u>{" "}
              do consider writing a{" "}
              <u style={{ color: "pink" }}>small message</u> on our{" "}
              <u style={{ color: "pink" }}> 💞 wall of love</u>
            </p>
          </div>
        </div>
        <LoveWallContainer />
      </div>

      <div
        style={{
          padding: "20px",
          background: "floralwhite",
        }}
      >
        <h2
          style={{ fontFamily: "Corben", color: "indigo", textAlign: "center" }}
        >
          Crafted with ❤️ by{" "}
          <a
            href="https://studio.primerlabs.io"
            target={"_blank"}
            style={{ color: "salmon" }}
          >
            Primerlabs Studio
          </a>
        </h2>
        <div
          style={{ color: "indigo", textAlign: "center", fontWeight: "bold" }}
        >
          NEAR Protocol Primer is supported by grant from{" "}
          <a target="_blank" href="https://near.org/">
            NEAR Foundation
          </a>
        </div>
      </div>
    </div>
  );
};

export default QuestList;

// background: "slateblue",
// borderRadius: "25px",
