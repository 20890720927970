import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Ensuring valid arguments are passed to the Function Call",
    "Ensuring that users are redirected to genuine wallet",
    "Ensuring that users don't accidentally lose funds",
    "All of the above",
  ];
  const correctOption = "All of the above";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, when will build the <b>frontend</b> for a{" "}
            <b>smart contract</b> which of following things we should take care
            of?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
