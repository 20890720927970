import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "To ensure block production at approx 10 mins",
    "For fun and profit",
    "To secure the network",
    "To incentivise miners",
  ];
  const correctOption = "To ensure block production at approx 10 mins";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, why does the network adjust <b>difficulty parameter</b>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
