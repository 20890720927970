// Infos
import FingerprintBlockInfo from "./PrimerComponents/FingerprintBlockInfo.mdx";
import ChangingDataInfo from "./PrimerComponents/ChangingDataInfo.mdx";
import NonceOnwardsInfo from "./PrimerComponents/NonceOnwardsInfo.mdx";
import DifficultyInfo from "./PrimerComponents/DifficultyInfo.mdx";
import WhyDifficulty from "./PrimerComponents/WhyDifficulty.mdx";
// Quizzes

import DifficultyQuiz from "./PrimerComponents/DifficultyQuiz";
import GlossaryMatchQuiz from "./PrimerComponents/GlossaryMatchQuiz";
// Next Lesson

import NextLesson from "./PrimerComponents/NextLesson";

import { aBlockPrimers } from "./ABlock";

const primerComponents = {
  FingerprintBlockInfo: FingerprintBlockInfo,
  ChangingDataInfo: ChangingDataInfo,
  NonceOnwardsInfo: NonceOnwardsInfo,
  DifficultyInfo: DifficultyInfo,
  WhyDifficulty: WhyDifficulty,
  DifficultyQuiz: DifficultyQuiz,
  GlossaryMatchQuiz: GlossaryMatchQuiz,
  NextLesson: NextLesson,
};

const quizList = ["DifficultyQuiz", "GlossaryMatchQuiz"];

const imageComponents = [];

const primers = aBlockPrimers;

const main = {
  primers,
  primerComponents,
  imageComponents,
  quizList,
};

export default main;
