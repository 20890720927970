import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = ["increase", "decrease", "getCount", "All of the above"];
  const correctOption = "getCount";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, which of the following methods in our{" "}
            <code>Counter</code> <b>smart contract</b> can be invoked without
            any <b>NEAR Protocol</b> <code>account</code>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
