import * as React from "react";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
/*

Props:
    image-name
    name of the topic
    number of topics
    background-colour
*/

export default function MediaCard(props) {
  const [active, setActive] = React.useState(false);

  return (
    <div>
      <Card
        as={Link}
        to={`/dapp/${props.slug}`}
        raised={active ? true : false}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          textAlign: "center",
          verticalAlign: "center",
          height: "150px",
          width: "150px",
          borderRadius: "12px",
          margin: "10px",
          backgroundColor: "floralwhite",
          transition: "0.5s",
          filter: active ? "brightness(95%)" : "brightness(100%)",
          cursor: "pointer",
          textDecoration: "none",
        }}
      >
        <div
          component="div"
          style={{
            transform: active ? "scale(0.8)" : "scale(0.6)",
            transition: "1s",
            display: "block",
            fontSize: "36px",
            margin: "5px auto",
            fontWeight: "800",
            fontFamily: "Nunito",
            textAlign: "center",
            verticalAlign: "center",
            width: "200px",
            color: "darkslateblue",
            fontVariant: "all-small-caps",
            letterSpacing: "2px",
          }}
        >
          {props.card_title}
        </div>
      </Card>
    </div>
  );
}
