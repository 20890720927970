import { buttonOptions } from "./Access";

export const start = [
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "{{user}}, in the previous topic, we learned about access keys.",
        'There are two types of "access keys" in NEAR Protocol',
        "Let's take a look at each of them",
      ],
      primerAvatar: "wave",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "AccessKeysInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "NonceQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'The "nonce" field on the access key has two functions',
        'First, to state in which "block" the access key was created.',
        "Second, to state how many times the particular access key was used.",
      ],
      primerAvatar: "security-2",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "NonceInfo",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "FullAccessQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        'If any of your "full access keys" is compromised, then it might lead to you losing the account access',
        'Compromising a "full access key" means that its corresponding "private key" or "passphrase" is revealed to a third-party',
        'Therefore, take utmost care to store your "full access keys" and "passphrase" in a safe place',
      ],
      primerAvatar: "security-1",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "WalletInfo",
    },
  },

  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "Once we have imported an account on a NEAR Wallet... ",
        "... we can start using it to login to other Web3 application",
        "Before that, let's first take a quick quiz regarding how login works in traditional web application",
      ],
      primerAvatar: "puzzle",
    },
  },
  {
    type: "QUIZ",
    reply: null,
    props: {
      content: "LoginQuiz",
    },
  },
  {
    type: "CHAT",
    reply: "NEXT",
    props: {
      chats: [
        "In traditional web application, the login details are stored in a remote centralised database",
        "However, in a Web3 applications, the login flow is completely different and more user-centric",
        "Let me explain what I mean",
      ],
      primerAvatar: "attention",
    },
  },
  {
    type: "INFO",
    reply: "NEXT",
    props: {
      content: "Web3LoginInfo",
    },
  },
  {
    type: "CHAT",
    reply: "BUTTON",
    props: {
      chats: [
        "A WEB3 application with a function call access key is granted permission to allowed to spend upto 0.25N",
        "{{user}}, does this mean that the WEB3 application can transfer 0.25N from your account to it's own account?",
      ],
      buttonOptions: buttonOptions,
      userReply: { reply: null, userAvatar: null },
      primerAvatar: "book",
    },
  },
];
