import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "Establish the document existed at that point of time",
    "Establish someone is the rightful owner of the document",
    "Establish the authenticity of the document",
    "All of the above",
  ];
  const correctOption = "Establish the document existed at that point of time";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          {props.name}, which of the following are the valid use cases of{" "}
          <b>time-stamping a document</b>?
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
