import * as React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import Popover from "@mui/material/Popover";
/*

Props:
    image-name
    name of the topic
    number of topics
    background-colour
*/

export default function MediaCard(props) {
  const [active, setActive] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleHover = (evt) => {
    setAnchorEl(evt.currentTarget);
    setActive(true);
  };

  const handleLeave = () => {
    setActive(false);
    setAnchorEl(null);
  };

  return (
    <div>
      <Card
        className="InteractiveCard"
        as={Link}
        to={`/explorable/${props.slug}`}
        raised={active ? true : false}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          textAlign: "center",
          verticalAlign: "center",
          height: "150px",
          width: "250px",
          borderRadius: "12px",
          background: "darkslateblue",
          margin: "20px",
          transition: "0.5s",
          filter: active ? "brightness(95%)" : "brightness(100%)",
          cursor: "pointer",
          textDecoration: "none",
        }}
      >
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          style={{
            transform: active ? "scale(0.8)" : "scale(0.6)",
            transition: "1s",
            display: "block",
            fontSize: "30px",
            margin: "10px auto",
            color: "floralwhite",
          }}
          sx={{
            fontWeight: "800",
            fontFamily: "Nunito",
            textAlign: "center",
            verticalAlign: "center",
            width: "125px",
          }}
        >
          {props.title}
        </Typography>
      </Card>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        style={{
          marginTop: "10px",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div
          style={{
            padding: "10px",
            fontWeight: "bold",
            fontFamily: "Nunito",
            background: "floralwhite",
            color: "darkslateblue",
            textAlign: "center",
            maxWidth: "300px",
          }}
        >
          {props.description ? props.description : "Nothing"}
        </div>
      </Popover>
    </div>
  );
}
