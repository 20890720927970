import React from "react";
import Paper from "@mui/material/Paper";
import { QUEST_1, QUEST_2, QUEST_3, QUEST_4 } from "./CharacterStory";
import { Link } from "react-router-dom";
const quest_map = [
  {
    level: [0],
    title: "Introduction to Blockchain Quest",
    body: <QUEST_1 />,
    slug: "/quest/blockchain",
  },
  {
    level: [0, 10, 20, 40],
    title: "The NEAR Protocol Quest",
    body: <QUEST_2 />,
    slug: "/quest/near-protocol",
  },
  {
    level: [0, 10, 20, 30, 40],
    title: "Writing Smart Contracts Quest",
    body: <QUEST_3 />,
    slug: "/quest/smart-contracts",
  },
  {
    level: [0, 10],
    title: "Creating Decentralised Applications Quest",
    body: <QUEST_4 />,
    slug: "/quest/dapp",
  },
];

const openLink = (url) => window.open(url, "_blank")?.focus();

const CourseInformation = ({ level = 0 }) => {
  return (
    <div>
      {quest_map
        .filter((quest) => quest.level.includes(level))
        .map((quest, idx) => (
          <Paper
            onClick={() => openLink(quest.slug)}
            elevation={4}
            key={idx}
            style={{
              padding: "20px",
              margin: "20px",
              background: "indigo",
              cursor: "pointer",
            }}
          >
            <h4
              style={{
                color: "salmon",
                textAlign: "center",
                fontVariant: "all-small-caps",
                letterSpacing: "2px",
                fontSize: "2rem",
                margin: "5px auto",
              }}
            >
              {quest.title}
            </h4>
            <div
              style={{
                border: "2px solid indigo",
                background: "indigo",
                color: "floralwhite",
                borderRadius: "5px",
              }}
            >
              {quest.body}
            </div>
          </Paper>
        ))}
    </div>
  );
};

export default CourseInformation;
