import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AntTabs, AntTab } from "./Tabs";
{
  /* <span style={{ fontSize: "3rem" }}>{"\u201C"}</span>
 <span style={{ fontSize: "3rem" }}>{"\u201D"}</span> */
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography sx={{ color: "#47307f", display: "flex" }}>
            <span style={{ fontSize: "80px", margin: "0px", padding: "0px" }}>
              {"\u201C"}
            </span>
            <blockquote style={{ paddingTop: "10px", fontSize: "small" }}>
              {children}
            </blockquote>
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabPanelStyles = {
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  color: "#47307f",
};

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        display: "inline-block",
        bgcolor: "background.paper",
        border: "none",
      }}
    >
      <AntTabs
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderBottom: "none",
          borderRight: 1,
          borderColor: "divider",
          minWidth: "250px",
        }}
      >
        <AntTab label="Expanded Money Supply" {...a11yProps(0)} />
        <AntTab label="Central Banks Backing" {...a11yProps(1)} />
        <AntTab label="Interest Gains" {...a11yProps(2)} />
      </AntTabs>
      <TabPanel style={tabPanelStyles} value={value} index={0}>
        One of the greatest benefit of the{" "}
        <b>fractional reserve banking system</b> is its ability to act as a{" "}
        <b>money multiplier</b>. By turning <b>deposits</b> into <b>loans</b>{" "}
        and keeping only a small amount of reserve cash on hand, banks can
        <b>continuously lend out funds to help further grow the economy</b>.
        This in turn allows a greater number of people to then{" "}
        <b>deposit more money in banks, shoring up the system further</b>.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={1}>
        <b>Fractional reserve banking</b> has come to be a part of a{" "}
        <b> wider monetary policy</b>
        set by central banks. For example, in the <b>United States</b>, the{" "}
        <b>Federal Reserve Bank</b> (also called as <b>the Fed</b>){" "}
        <b>sets interest rates</b> and
        <b> reserve requirements</b> and ensures banks{" "}
        <b>
          it will act as a lender of last resort to keep this system running.
        </b>
        <br />
        <br />
        This sort of guidance and reassurance{" "}
        <b>from the entity issuing all banknotes</b> in the first place helps
        keep the fractional approach on track even through difficult economic
        times.
      </TabPanel>
      <TabPanel style={tabPanelStyles} value={value} index={2}>
        Bank money accrues interest, whereas simply storing your funds at home
        can leave you at the mercy of <b>inevitably increasing inflation</b>. As
        a result, savers can often get a better return on their own personal
        savings accounts by allowing banks to utilize their money for{" "}
        <b>fractional reserve lending</b>.
      </TabPanel>
    </Box>
  );
}
