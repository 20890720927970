import React from "react";
import MCQQuiz from "../../../../../components/Quiz/MCQQuiz";

const MCQ = (props) => {
  const options = [
    "By fetching the recent donations on the contract",
    "By checking the status of the transaction ID",
    "We are not notifying the user in any way",
  ];
  const correctOption = "By fetching the recent donations on the contract";
  return (
    <MCQQuiz
      options={options}
      correctOption={correctOption}
      small={true}
      header={
        <React.Fragment>
          <div style={{ fontSize: "smaller" }}>
            {props.name}, in the our <code>Donations DApp</code>, how are we
            notifying the users if the transactions is <b>successful</b>?
          </div>
        </React.Fragment>
      }
      {...props}
    />
  );
};

export default MCQ;
